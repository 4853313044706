import { StyleSheet } from 'react-native';

export const AppPostcodeStyle = StyleSheet.create({
  header: {
    backgroundColor: '#F7F7F7',
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    // height: 50,
  },
  headerTxt: {
    fontWeight: '700',
    color: '#000000',
    paddingHorizontal: 15,
    paddingVertical: 16,
  },
});
