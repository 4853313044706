import { CrossIcon, WarningIcon } from '@assets';
import { AppButton, AppModal } from '@components';
import { ForceUpdateStyles } from '@components/AppForceUpdate/ForceUpdate.style';
import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import { BottomTab } from '@routes/BottomTab';
import { useMainStack } from '@routes/MainStack/MainStack.hook';
import { navigate } from '@routes/navigationUtils';
import {
  BOTTOM_TAB,
  DIGIT,
  LIST_QUIZ_SCREEN,
  PROFILE_EDIT_SCREEN,
  SCAN_QR_SCREEN,
  WEB_VIEW_SCREEN_FULLSCREEN,
  MENU_WEB_VIEW_SCREEN,
  SURVEY_LIST_SCREEN,
} from '@routes/route';
import { HOME_SCREEN, Screens } from '@routes/route';
import {
  Digit,
  Notifications,
  NotificationSetting,
  ProfileEdit,
  ScanQR,
  SurveyListScreen,
  TimelineScreen,
  WebView,
  WebViewFullScreen,
  PostingLocation,
} from '@screens';
import { Home, Login } from '@screens';
import { SuccessfulScreen } from '@screens/auth/Successful';
import { CDMFormScreen } from '@screens/main/CDM/form';
import { CDSFormScreen } from '@screens/main/CDS/form';
import { CDSSubFormScreen } from '@screens/main/CDS/subForm';
import { DailyItemScreen } from '@screens/main/D2D/dailyItem';
import { D2DFormScreen } from '@screens/main/D2D/form';
import { OverDueScreen } from '@screens/main/D2D/overDue';
import { D2DOverviewScreen } from '@screens/main/D2D/overview';
import { HowToScreen } from '@screens/main/HowTo/form';
import { RFIDScreen } from '@screens/main/RFID/form';
import { SearchAddressScreen } from '@screens/main/SearchAddress';
import { SearchPostcodeScreen } from '@screens/main/SearchPostcode';
import { ProfileFormScreen } from '@screens/main/profile/ProfileForm';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ListQuizScreen } from '@screens/main/quiz/ListQuiz';
import { QuizCompletedScreen } from '@screens/main/quiz/QuizCompleted';
import { QuizDetailScreen } from '@screens/main/quiz/QuizDetail';
import { QuizFailedScreen } from '@screens/main/quiz/QuizFailed';
import { QuizSuccessfulScreen } from '@screens/main/quiz/QuizSuccessful';
import { ReportScreen } from '@screens/main/report/Report';
import { ReportBundleDetailScreen } from '@screens/main/report/bundle/detail';
import { ReportBundleFormScreen } from '@screens/main/report/bundle/form';
import { ReportICodeDetailScreen } from '@screens/main/report/iCode/detail';
import { ReportICodeFormScreen } from '@screens/main/report/iCode/form';
import { ReportPackageDetailScreen } from '@screens/main/report/package/detail';
import { ReportPackageFormScreen } from '@screens/main/report/package/form';
import { RewardScreen } from '@screens/main/reward/Reward';
import { TicketScreen } from '@screens/main/ticket/Ticket';
import { TicketDetailScreen } from '@screens/main/ticket/TicketDetail';
import { TransponderFormScreen } from '@screens/main/transponder/form';
import { UnsubscribeScreen } from '@screens/main/unsub/Unsubscribe';
import { UnsubscribeSuccessfulScreen } from '@screens/main/unsub/UnsubscribeSuccessful';
import theme, { globalStyle } from '@theme';
import { Font, FontSize } from '@theme/size';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';
import { AppText } from '../../components/AppText/AppText';
import {UnsureDateListScreen} from "@screens/main/survey/UnsureDateList";
import {DetailScreen} from "@screens/main/CDS/detail";
import {BundleTimelineScreen} from "@screens/main/Timeline/bundle";
import {PackageTimelineScreen} from "@screens/main/Timeline/package";
import {ScanCodeScreen} from "@screens/auth/ScanCode/pickerScan/ScanCode.view";
import {InputScanCodeScreen} from "@screens/auth/ScanCode/inputScan/InputScanCode.view";
import {OverDueGroupScreen} from "@screens/main/D2D/overDue/group";
import {AppReviewForm} from "@components/AppReviewForm";
import {ReportICodeReviewScreen} from "@screens/main/report/iCode/review";
import {ReimbursementScreen} from "@screens/main/reimbursement";
import {VerifyMapScreen} from "@screens/main/VerifyMap";

const MainStack = createStackNavigator();

export const MainStackScreen: FC = () => {
  // eslint-disable-next-line no-empty-pattern
  const { showSkillModal, setShowSkillModal, isFirsTime } = useMainStack({});
  const { t } = useTranslation();
  return (
    <>
      <MainStack.Navigator initialRouteName={BOTTOM_TAB}>
        <MainStack.Screen
          name={BOTTOM_TAB}
          component={BottomTab}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={DIGIT}
          component={Digit}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.POSTING_LOCATION}
          component={PostingLocation}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={SCAN_QR_SCREEN}
          component={ScanQR}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
          <MainStack.Screen
              name={Screens.SCAN_CODE_SCREEN}
              component={ScanCodeScreen}
              options={{
                  headerShown: false,
                  ...TransitionPresets.ModalFadeTransition,
              }}
          />
          <MainStack.Screen
              name={Screens.INPUT_SCAN_CODE_SCREEN}
              component={InputScanCodeScreen}
              options={{
                  headerShown: false,
                  ...TransitionPresets.ModalFadeTransition,
              }}
          />
        <MainStack.Screen
          name={PROFILE_EDIT_SCREEN}
          component={ProfileEdit}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.QUIZ_DETAIL_SCREEN}
          component={QuizDetailScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.QUIZ_SUCCESSFUL_SCREEN}
          component={QuizSuccessfulScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.QUIZ_FAILED_SCREEN}
          component={QuizFailedScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.QUIZ_COMPLETED_SCREEN}
          component={QuizCompletedScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.PROFILE_FORM_SCREEN}
          component={ProfileFormScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.SEARCH_POSTCODE_SCREEN}
          component={SearchPostcodeScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.SEARCH_ADDRESS_SCREEN}
          component={SearchAddressScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.REPORT_SCREEN}
          component={ReportScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={WEB_VIEW_SCREEN_FULLSCREEN}
          component={WebViewFullScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.TICKET_SCREEN}
          component={TicketScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.UNSUBSCRIBE_SCREEN}
          component={UnsubscribeScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.UNSUBSCRIBE_SUCCESSFUL_SCREEN}
          component={UnsubscribeSuccessfulScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.SUCCESSFUL_SCREEN}
          component={SuccessfulScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.ModalFadeTransition,
          }}
        />
        <MainStack.Screen
          name={Screens.TICKET_DETAIL_SCREEN}
          component={TicketDetailScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.REPORT_PACKAGE_FORM_SCREEN}
          component={ReportPackageFormScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.REPORT_ICODE_FORM_SCREEN}
          component={ReportICodeFormScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.REPORT_BUNDLE_FORM_SCREEN}
          component={ReportBundleFormScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.REPORT_BUNDLE_DETAIL_SCREEN}
          component={ReportBundleDetailScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.REPORT_PACKAGE_DETAIL_SCREEN}
          component={ReportPackageDetailScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.REPORT_ICODE_DETAIL_SCREEN}
          component={ReportICodeDetailScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={SURVEY_LIST_SCREEN}
          component={SurveyListScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.CDS_FORM_SCREEN}
          component={CDSFormScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
          <MainStack.Screen
              name={Screens.CDS_DETAIL_SCREEN}
              component={DetailScreen}
              options={{
                  headerShown: false,
                  ...TransitionPresets.SlideFromRightIOS,
              }}
          />
        <MainStack.Screen
          name={Screens.CDS_SUBFORM_SCREEN}
          component={CDSSubFormScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
          <MainStack.Screen
              name={Screens.UNSURE_DATE_LIST_SCREEN}
              component={UnsureDateListScreen}
              options={{
                  headerShown: false,
                  ...TransitionPresets.SlideFromRightIOS,
              }}
          />
        <MainStack.Screen
          name={Screens.NOTIFICATION_SETTING}
          component={NotificationSetting}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.D2D_OVERVIEW}
          component={D2DOverviewScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.D2D_OVER_DUE}
          // component={OverDueScreen}
          component={OverDueGroupScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.D2D_OVER_DUE_GROUP}
          component={OverDueGroupScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.D2D_FORM}
          component={D2DFormScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.D2D_DAILY_ITEM}
          component={DailyItemScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.REWARD}
          component={RewardScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.NOTIFICATIONS}
          component={Notifications}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.RFID_FORM}
          component={RFIDScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.TRANSPONDER_FROM_SCREEN}
          component={TransponderFormScreen}
          options={{
              headerShown: false,
              ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.CDM_FORM_SCREEN}
          component={CDMFormScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.HOW_TO_FORM}
          component={HowToScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
        <MainStack.Screen
          name={Screens.TIMELINE}
          component={TimelineScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        />
          <MainStack.Screen
              name={Screens.BUNDLE_TIMELINE}
              component={BundleTimelineScreen}
              options={{
                  headerShown: false,
                  ...TransitionPresets.SlideFromRightIOS,
              }}
          />
          <MainStack.Screen
              name={Screens.PACKAGE_TIMELINE}
              component={PackageTimelineScreen}
              options={{
                  headerShown: false,
                  ...TransitionPresets.SlideFromRightIOS,
              }}
          />
          <MainStack.Screen
              name={Screens.ICODE_FORM_REVIEW_SCREEN}
              component={ReportICodeReviewScreen}
              options={{
                  headerShown: false,
                  ...TransitionPresets.SlideFromRightIOS,
              }}
          />
          <MainStack.Screen
              name={Screens.REIMBURSEMENT_SCREEN}
              component={ReimbursementScreen}
              options={{
                  headerShown: false,
                  ...TransitionPresets.SlideFromRightIOS,
              }}
          />
          <MainStack.Screen
              name={Screens.VERIFY_MAP_SCREEN}
              component={VerifyMapScreen}
              options={{
                  headerShown: false,
                  ...TransitionPresets.SlideFromRightIOS,
              }}
          />
      </MainStack.Navigator>
      {/*<AppModal*/}
      {/*  // onDismiss={handleHideModal}*/}
      {/*  // closeOnTouchOutside*/}
      {/*  visible={showSkillModal}*/}
      {/*  style={[{ padding: theme.spacing.md }]}*/}
      {/*  containerStyle={[{ borderRadius: 8 }]}*/}
      {/*  // contentContainerStyle={{ maxWidth: '92%' }}*/}
      {/*>*/}
      {/*  <TouchableOpacity*/}
      {/*    onPress={() => setShowSkillModal(false)}*/}
      {/*    style={{*/}
      {/*      position: 'absolute',*/}
      {/*      top: 12,*/}
      {/*      right: 12,*/}
      {/*      backgroundColor: '#c9c9c9',*/}
      {/*      zIndex: 1,*/}
      {/*      padding: 6,*/}
      {/*      borderRadius: 1000,*/}
      {/*    }}>*/}
      {/*    <CrossIcon color="white" />*/}
      {/*  </TouchableOpacity>*/}
      {/*  <View style={{ marginTop: 32, marginBottom: 12 }}>*/}
      {/*    <View style={[globalStyle.directionColumm]}>*/}
      {/*      <View style={[globalStyle.center]}>*/}
      {/*        <WarningIcon width={45} height={45} />*/}
      {/*      </View>*/}
      {/*      <View style={[globalStyle.center, { paddingVertical: theme.spacing.md }]}>*/}
      {/*        <AppText style={[Font.Bold_700, globalStyle.center]}>{t('QUIZ_MODAL_TITLE')}</AppText>*/}
      {/*        <AppText*/}
      {/*          style={[*/}
      {/*            {*/}
      {/*              fontSize: FontSize.Font15,*/}
      {/*              paddingVertical: theme.spacing.sm,*/}
      {/*              textAlign: 'center',*/}
      {/*              color: '#666666',*/}
      {/*              maxWidth: '85%',*/}
      {/*            },*/}
      {/*          ]}>*/}
      {/*          {t('QUIZ_MODAL_DESCRIPTION')}*/}
      {/*        </AppText>*/}
      {/*      </View>*/}
      {/*    </View>*/}
      {/*    <View style={[ForceUpdateStyles.row, { margin: theme.spacing.md }]}>*/}
      {/*      <AppButton*/}
      {/*        // style={ForceUpdateStyles.navigationButton}*/}
      {/*        onPress={() => {*/}
      {/*          setShowSkillModal(false);*/}
      {/*          navigate(LIST_QUIZ_SCREEN);*/}
      {/*        }}*/}
      {/*        text={t('TEST_YOUR_KNOWLEDGE')}*/}
      {/*      />*/}
      {/*    </View>*/}
      {/*  </View>*/}
      {/*</AppModal>*/}
    </>
  );
};
