import _ from 'lodash';

export const isUrl = url => {
  if (_.isEmpty(url)) {
    return false;
  }
  if (_.startsWith(url, 'http')) {
    return true;
  }
  return false;
};

export const isSvgUrl = url => {
  const is_url = isUrl(url);
  if (is_url) {
    if (_.endsWith(url, '.svg')) {
      // console.log('isSvgUrl>>>', url);
      return true;
    }
  }
  return false;
};
