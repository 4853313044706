import { getProfileCreator } from '@redux/user/selectors';
import { navigate } from '@routes/navigationUtils';
import {PROFILE_EDIT_SCREEN, Screens} from '@routes/route';
import { useSelector } from 'react-redux';

export const useLogic = () => {
  const getProfileData = useSelector(getProfileCreator)?.message?.data;

  const handleGoToProfileEdit = () => {
    navigate(PROFILE_EDIT_SCREEN);
    // navigate(Screens.TICKET_DETAIL_SCREEN, { data: { _id: '6317119c7b4726093194638c' } });

  };
  return { handleGoToProfileEdit, getProfileData };
};
