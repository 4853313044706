import { CrossIcon, LogoIcon, MessageCheckIcon, WarningIcon } from '@assets';
import {AppButton, AppEmptyView, AppKAView, AppModal, AppModalV2, AppSafeAreaView, AppText} from '@components';
import { ForceUpdateStyles } from '@components/AppForceUpdate/ForceUpdate.style';
import { AppConfirmModal } from '@components/AppModal/AppConfirmModal';
import GenericHeader from '@components/GenericHeader';
import { goBack, navigate, navigatePush } from '@routes/navigationUtils';
import { MENU_WEB_VIEW_SCREEN, Screens } from '@routes/route';
import { OverDueStyle as styles } from '@screens/main/D2D/overDue/OverDue.style';
import { FlexImageIcon } from '@screens/main/Menu/Components';
import { AppLoadingMore } from '@screens/main/SearchPostcode';
import { AppNoteView } from '@screens/main/quiz/QuizFailed/components/AppNote.view';
import { HistoryItemView } from '@screens/main/reward/HistoryPoints';
import { HistoryPointsStyle } from '@screens/main/reward/HistoryPoints/HistoryPoints.style';
import { RewardStyle } from '@screens/main/reward/Reward/Reward.style';
import { ListTicketStyle } from '@screens/main/ticket/ListTicket/ListTicket.style';
import { TicketItemStyles } from '@screens/main/ticket/ListTicket/components/TicketItem.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font, FontSize, Spacing } from '@theme/size';
import { DATE_FORMAT, formatDateV1 } from '@utils/common/MomentUtils';
import { getContentTitle } from '@utils/transform/form';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Text, FlatList, TouchableOpacity, Linking, ScrollView, RefreshControl, Platform } from 'react-native';
import FastImage from 'react-native-fast-image';
import { useVoucher } from './Voucher.hook';
import { VoucherStyle } from './Voucher.style';
import _ from 'lodash'

export const VoucherItemView = props => {
  const { t } = useTranslation();
  const data = props.data;

  // console.log('TicketItemView>>>>', props)

  return (
    <View>
      <TouchableOpacity
        activeOpacity={1}
        style={[
          // styles.fill,
          globalStyle.directionRow,
          VoucherStyle.container,
          // { marginBottom: props.isLast ? Dimens.Margin.Normal : 0 },
        ]}
        onPress={() => {
          props.onPress(data);
        }}>
        <View style={[globalStyle.directionRow, globalStyle.center, VoucherStyle.leftContainer]}>
          {/*<AppText style={[TicketItemStyles.textTitle, globalStyle.flex1]}>*/}
          {/*  {`${data?.comment}`}*/}
          {/*  /!*<View style={[TicketItemStyles.viewLine]} />*!/*/}
          {/*  /!*<AppText style={[TicketItemStyles.textSecondTitle]}>{`  ${data?.title}`}</AppText>*!/*/}
          {/*</AppText>*/}
          {/*<View style={[HistoryPointsStyle.point]}>*/}
          {/*  <AppText style={[HistoryPointsStyle.textPoint]}>*/}
          {/*    /!*{getTextPriority(priority)}*!/*/}
          {/*    {data?.points} points*/}
          {/*  </AppText>*/}
          {/*</View>*/}
          {/*<FastImage*/}
          {/*  item={item}
          {/*  // style={{ width: 200, height: 200 }}*/}
          {/*  style={VoucherStyle.image}*/}
          {/*  source={{ uri: data.image }}*/}
          {/*  resizeMode={'cover'}*/}
          {/*/>*/}
          <FlexImageIcon
            icon={data?.image}
            // style={{
            //     width: Spacing.width16,
            // }}
            style={Platform.OS === 'web' ? VoucherStyle.image_web : VoucherStyle.image}
          />
        </View>
        <View style={[VoucherStyle.leftTriangle]} />
        <View style={[VoucherStyle.rightTriangle]} />
        <View style={[globalStyle.directionRow, { alignSelf: 'center' }, VoucherStyle.rightContainer]}>
          <View style={[globalStyle.directionColumm]}>
            <AppText style={[TicketItemStyles.textTitle, globalStyle.flex1, { fontSize: FontSize.Font14 }]}>
              {`${data?.name}`}
              {/*<View style={[TicketItemStyles.viewLine]} />*/}
              {/*<AppText style={[TicketItemStyles.textSecondTitle]}>{`  ${data?.title}`}</AppText>*/}
            </AppText>
            <AppText style={[TicketItemStyles.textNormal, { fontSize: FontSize.Font13 }]}>{data?.expiry}</AppText>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export const VoucherItemViewV2 = props => {
  const { t } = useTranslation();
  const data = props.data;
  console.log('VoucherItemViewV2>>>data>>>', data)
    if(_.isEmpty(data?.image)){
        return <></>
    }
  return (
    <View style={{ width: '50%' }}>
      <TouchableOpacity
        activeOpacity={1}
        style={[globalStyle.directionRow, VoucherStyle.container]}
        onPress={() => {
          props.onPress(data);
        }}>
        {/*<View style={[globalStyle.directionRow, globalStyle.center, globalStyle.flex1, VoucherStyle.imageContainer]}>*/}
        <View style={[globalStyle.center, VoucherStyle.imageContainer]}>
          <FlexImageIcon
            icon={data?.image}
            // style={{
            //     width: Spacing.width16,
            // }}
            style={Platform.OS === 'web' ? VoucherStyle.image_web : VoucherStyle.image}
          />
        </View>

        {/*</View>*/}
        {/*<View style={[VoucherStyle.leftTriangle]} />*/}
        {/*<View style={[VoucherStyle.rightTriangle]} />*/}
        {/*<View style={[globalStyle.directionRow, { alignSelf: 'center' }, VoucherStyle.rightContainer]}>*/}
        {/*  <View style={[globalStyle.directionColumm]}>*/}
        {/*    <AppText style={[TicketItemStyles.textTitle, globalStyle.flex1, { fontSize: FontSize.Font14 }]}>*/}
        {/*      {`${data?.name}`}*/}
        {/*      /!*<View style={[TicketItemStyles.viewLine]} />*!/*/}
        {/*      /!*<AppText style={[TicketItemStyles.textSecondTitle]}>{`  ${data?.title}`}</AppText>*!/*/}
        {/*    </AppText>*/}
        {/*    <AppText style={[TicketItemStyles.textNormal, { fontSize: FontSize.Font13 }]}>{data?.expiry}</AppText>*/}
        {/*  </View>*/}
        {/*</View>*/}
      </TouchableOpacity>
    </View>
  );
};

export const VoucherScreen = props => {
  const { t } = useTranslation();
  const {
    data,
    bonusInfo,
    onItemPress,
    onClickHref,
    onRedeemPress,
    showConfirmModal,
    onYesConfirm,
    onNoConfirm,
    showSuccessModal,
    setShowSuccessModal,
    refreshing,
    onRefresh,
  } = useVoucher(props);
  console.log('VoucherScreen>>>data>>>', data);
  // const renderFooterLoading = () => {
  //   return <AppLoadingMore loadingMore={loadingMore} />;
  // };

  const renderEmpty = (props) => {
    return <AppEmptyView emptyText={props?.emptyText} />;
  };
  const renderItem = ({ item, index }) => {
    return (
      <VoucherItemViewV2
        data={item}
        isLast={index === data.length - 1}
        isSelected={false}
        // colors={getListStatusData?.colors}
        // dictStatus={getListStatusData?.value}
        onPress={() => onItemPress(item)}
      />
    );
  };

  return (
    <View
      style={[
        globalStyle.directionColumm,
        globalStyle.flex1,
        VoucherStyle.rootView,
        { backgroundColor: palette.WHITE },
      ]}>
      {/*<TicketFilterView*/}
      {/*    selectedStatus={selectedStatus}*/}
      {/*    onFilterPress={onFilterPress}*/}
      {/*    modalizeRef={modalizeRef}*/}
      {/*    listStatus={listStatus}*/}
      {/*    onItemStatusPress={onItemStatusPress}*/}
      {/*/>*/}

      {/*<View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>*/}
      <FlatList
        numColumns={2}
        columnWrapperStyle={{
          flex: 1,
          // justifyContent: 'space-around',
          // marginTop: -theme.spacing.sm,
          // backgroundColor: 'red',
          // padding:4
        }}
        ListHeaderComponent={
          <View style={[{ margin: theme.spacing.md }]}>
            <View style={[globalStyle.directionRow, { alignItems: 'baseline' }]}>
              <AppText style={[HistoryPointsStyle.textHeader]}>{bonusInfo?.total}</AppText>
              <AppText style={[]}> {t('POINTS')}</AppText>
            </View>
            <AppText style={[HistoryPointsStyle.textDescription]}>{bonusInfo?.note}</AppText>
            {!!bonusInfo?.description && (
              <AppText style={[HistoryPointsStyle.textDescription2]}>{bonusInfo?.description}</AppText>
            )}
            <AppNoteView
              onClickHref={x => {
                // console.log('AppNoteView>>>', x);
                // Linking.openURL(x);
                onClickHref(x);
              }}
              style={[
                VoucherStyle.noteView,
                // { marginHorizontal: theme.spacing.md }
              ]}
              title={bonusInfo?.content}
              titleStyle={{ fontSize: FontSize.Font14 }}
            />
          </View>
        }
        // contentContainerStyle={{ paddingTop: 10 }}
        horizontal={false}
        style={[VoucherStyle.listView]}
        // data={[...data, ...data, ...data, ...data]}
        // data={[...data, data[0]]}
        data={data}
        renderItem={renderItem}
        onRefresh={() => onRefresh()}
        refreshing={refreshing}
        // ListEmptyComponent={renderEmpty}
        keyExtractor={(item, index) => index}
        // ListFooterComponent={renderFooterLoading}
      />
      {/*</View>*/}

      {/*<ScrollView*/}
      {/*  // refreshControl={}*/}
      {/*  refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>*/}
      {/*  <View>*/}
      {/*    {data?.map((item, index) => {*/}
      {/*      return renderItem({ item, index });*/}
      {/*    })}*/}
      {/*  </View>*/}
      {/*</ScrollView>*/}

      <View style={[{ marginHorizontal: theme.spacing.md, marginVertical: theme.spacing.md }]}>
        <AppButton
          disabled={!bonusInfo?.canRedeem}
          style={{ backgroundColor: bonusInfo?.canRedeem ? palette.ROYAL_MAIL_RED : palette.RECESS_GREY, opacity: 1 }}
          text={t('VOUCHER_REDEEM')}
          onPress={() => {
            onRedeemPress();
          }}
        />
      </View>
      <AppConfirmModal
        onYesModalPress={onYesConfirm}
        onNoModalPress={onNoConfirm}
        showModal={showConfirmModal}
        textContent={bonusInfo?.message}
      />
      <AppModal
        onBackdropPress={() => {
          setShowSuccessModal(false);
        }}
        closeOnTouchOutside
        visible={showSuccessModal}
        style={[{ padding: theme.spacing.md }]}
        containerStyle={[{ borderRadius: 8 }]}
        // contentContainerStyle={{ maxWidth: '92%' }}
      >
        <View>
          <TouchableOpacity
            onPress={() => setShowSuccessModal(false)}
            style={{
              position: 'absolute',
              top: 6,
              right: 6,
              backgroundColor: '#c9c9c9',
              zIndex: 1,
              padding: 6,
              borderRadius: 1000,
            }}>
            <CrossIcon color="white" />
          </TouchableOpacity>

          <View style={[globalStyle.directionColumm, { marginTop: 24 }]}>
            <View style={[globalStyle.center]}>
              <WarningIcon width={45} height={45} color={palette.GREEN_ACTIVE} />
            </View>
            <View style={[globalStyle.center, { paddingVertical: theme.spacing.md }]}>
              <AppText style={[Font.Bold_700, globalStyle.center, { marginBottom: theme.spacing.lg }]}>
                {t('VOUCHER_REDEEM_SUCCESSFUL')}
              </AppText>
              {/*<AppText*/}
              {/*  style={[*/}
              {/*    {*/}
              {/*      fontSize: FontSize.Font15,*/}
              {/*      paddingVertical: theme.spacing.sm,*/}
              {/*      textAlign: 'center',*/}
              {/*      color: '#666666',*/}
              {/*      maxWidth: '85%',*/}
              {/*    },*/}
              {/*  ]}>*/}
              {/*  /!*{point}*!/*/}
              {/*  {t('UNSUBSCRIBE_MODAL_CONTENT')}*/}
              {/*  /!*{t('UNSUBSCRIBE_MODAL_CONTENT').replace('{{point}}', point)}*!/*/}

              {/*</AppText>*/}
            </View>
          </View>
        </View>
      </AppModal>
    </View>
  );
};
