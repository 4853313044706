import { getLogoutRequest } from '@redux/auth/authSlice';
import { hideModalDeeplink } from '@redux/common/commonSlice';
import { useDispatch } from 'react-redux';

export const useDeeplinkModal = props => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(hideModalDeeplink());
    dispatch(
      getLogoutRequest(
        {},
        res => {
          //TODO: remove token, user
        },
        error => {},
      ),
    );
  };
  const handleHideModal = () => {
    dispatch(hideModalDeeplink());
  };
  return { handleLogout, handleHideModal };
};
