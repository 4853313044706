import {
  hideNoInternetModal,
} from '@redux/common/commonSlice';
import {
  noInternetModalVisible,
} from '@redux/common/selectors';
import { navigate } from '@routes/navigationUtils';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

export const useNoInternetModal = props => {
  const dispatch = useDispatch();
  const noInternetConnectionModalVisible = useSelector(noInternetModalVisible);

  // const data = useSelector()
  const handlePressButton = () => {
    noInternetConnectionModalVisible && dispatch(hideNoInternetModal());
    //TODO: open setting
  };
  const handleHideModal = () => {
    noInternetConnectionModalVisible && dispatch(hideNoInternetModal());
  };
  return { handlePressButton, handleHideModal };
};
