import { LogoIcon, MarkerDashboardIcon } from '@assets';
import {AppEmptyView, AppKAView, AppSafeAreaView, AppText, MapColor} from '@components';
import GenericHeader from '@components/GenericHeader';
import { SVGIcons } from '@constant/appSetting';
import { Config } from '@constant/config';
// import { CheckStatusModal } from '@screens/main/Dashboard/Components/CheckStatusModal/CheckStatusModal.view';
import DashboardHeader from '@screens/main/Dashboard/Components/DashboardHeader';
import { AppHtmlView } from '@screens/main/quiz/QuizFailed/components/AppNote.view';
import theme, { globalStyle } from '@theme';
import { palette } from '@theme/colors';
import { Font, FontSize, Spacing } from '@theme/size';
import { numberWithDots, withLoading } from '@utils';
import { AppSetting } from '@utils/common/setting';
import { StringHelper } from '@utils/common/string';
import { IconSvgLocal } from '@utils/common/svg';
import _ from 'lodash';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, ScrollView, View, RefreshControl, TouchableWithoutFeedback, TouchableOpacity, Platform } from 'react-native';
import { backgroundColor } from 'react-native-calendars/src/style';
import { FlexImageIcon } from '../Menu/Components';
import { DashboardItem } from './Components';
import { useLogic } from './Dashboard.hook';
import { styles } from './Dashboard.style';
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {Constant} from "@constant";
import {CheckStatusModal} from "@screens/main/Dashboard/Components/CheckStatusModal/CheckStatusModal.view";

const Content = ({ dashboard, onPress, fakeLoading, notificationItem }) => {
  return (
    <View style={{marginTop:theme.spacing.md}}>
      {dashboard?.map((item, index) => {
        if (item.type !== 'point')
          return <DashboardItem key={index} data={item} isLast={index === dashboard?.length - 1} onPress={onPress} notificationItem={notificationItem} dashboard={dashboard}/>;
      })}
    </View>
  );
};
const ContentWithLoading = withLoading(Content);

const NotificationContent = ({ onPress, data }) => {
  // if (!data?.show) return null;
    console.log('NotificationContent>>>', data)
  return (
    <TouchableWithoutFeedback
      onPress={() => {
        // if (data?.type === 'list') navigate(LIST_QUIZ_SCREEN);
        if (onPress) {
          onPress(data);
        }
      }}>
      <View style={[globalStyle.directionRow, { paddingLeft: 24 }]}>
        {/*<FlexImageIcon*/}
        {/*  icon={data?.icon}*/}
        {/*  style={[*/}
        {/*    styles.iconDashboard,*/}
        {/*    {*/}
        {/*      transform: [{ scale: 0.8 }],*/}
        {/*      // backgroundColor: 'white', color: 'white'*/}
        {/*    },*/}
        {/*  ]}*/}
        {/*/>*/}
          <FontAwesome5 name={'bell'} color={AppSetting.state?.colors?.DASHBOARD_MAP} size={20} solid />
          <View style={[styles.notificationDashboardView, globalStyle.directionRow]}>
          <View
            style={[
              {
                width: 20,
                height: 20,
                borderRadius: 1000,
                borderWidth: 2,
                borderColor: AppSetting.state?.colors?.ROYAL_MAIL_RED,
                alignItems: 'center',
                justifyContent: 'center',
                top: -15,
                left: Platform.OS === 'web' ? 21 : 4,
                backgroundColor: AppSetting.state?.colors?.WHITE,

                // transform: [{ scale: 0.8 }],
              },
            ]}>
            <AppText
              allowFontScaling={false}
              style={{ ...Font.Bold_700, fontSize: FontSize.Font10, color: AppSetting.state?.colors?.ROYAL_MAIL_RED }}>
              {data?.data?.length > 99 ? 99 : data?.data?.length ?? 0}
            </AppText>
          </View>
          {/*<AppText numberOfLines={1} style={{ flex: 1, marginLeft: -8 }}>*/}
          {/*  {StringHelper.removeHtmlTag(data?.data?.[0]?.message)}*/}
          {/*</AppText>*/}
        </View>

        {/*<AppHtmlView numberOfLines={1} style={{ flex: 1, marginLeft: -8 }} text={data?.data?.[0]?.message}/>*/}
      </View>
    </TouchableWithoutFeedback>
  );
};

const Dashboard = () => {
  const {
    dashboard,
    onItemPress,
    handleRefresh,
    refresh,
    point,
    // loading,
    // fakeLoading,
    bonusInfo,
    handleMarkerPress,
    handleNavigateToReward,
    dashboardLoading,
    settingsDashboard,
    checkStatusOfDashboard,
    onPressStatus,
    show,
    setShow,
    // checkStatusOfDashboard,
    // onPressStatus,
    // show,
    // setShow,
    notificationItem,
  } = useLogic();
  // const point = dashboard?.find(item => item?.type === 'point')?.point;
  const { t } = useTranslation();
  // const notificationItem = dashboard?.find(item => item?.type === 'notification') ?? {
  //   internalId: Constant.dashboard.notification,
  //   show: true,
  // };
  console.log('bonusInfo>>>', bonusInfo);
  const statusDashboard = () => {
    console.log('statusDashboard>>>');
    return (
      <TouchableOpacity
        style={{
          ...styles.statusDashboardView,
          backgroundColor: checkStatusOfDashboard?.data?.color,
          // backgroundColor: checkStatusOfDashboard?.data?.color,
        }}
        onPress={onPressStatus}
      />
    );
  };
  const rightButton = () => {
    return (
      <View
        style={[
          globalStyle.directionRow,
          // globalStyle.flex0,
          {
            flex: Platform.OS === 'web' ? undefined : 0,
            // backgroundColor: 'yellow',
            // width: '100%',
          },
        ]}>
        {/*<View style={{ flex: 1, backgroundColor: 'white' }} />*/}
        
        {/*{statusDashboard()}*/}
        {!!settingsDashboard?.mapIcon?.show && (
          <TouchableOpacity
            style={
              [
                // styles.markerDashboardView,
                // backgroundColor: checkStatusOfDashboard?.data?.color,
              ]
            }
            activeOpacity={1}
            onPress={handleMarkerPress}>
            {/*<MarkerDashboardIcon color={AppSetting.state?.colors?.DASHBOARD_MAP} height={20} width={20} />*/}
             <FontAwesome5 name={'map-marker-alt'} color={AppSetting.state?.colors?.DASHBOARD_MAP} size={20} solid />
          </TouchableOpacity>
        )}
        {checkStatusOfDashboard?.data?.color && statusDashboard()}
        {/*<NotificationContent onPress={() => onItemPress(notificationItem)} data={notificationItem} />*/}

          <NotificationContent onPress={() => onItemPress(notificationItem)} data={notificationItem} />
      </View>
    );
  };

  const leftHeader = () => {
    return (
      <>
        <IconSvgLocal imageKey={SVGIcons.LogoBigIcon} color={'white'} opacity={1} width={32} height={32} />
        <AppText
          onPress={handleNavigateToReward}
          style={{
            ...styles.point,
            color: `${AppSetting.state?.colors?.DASHBOARD_TEXT}`,
          }}>
          {numberWithDots(bonusInfo?.total)}
        </AppText>
        <AppText
          style={{
            ...styles.textPoint,
            // flex:0,
            marginLeft: 4,
            color: `${AppSetting.state?.colors?.DASHBOARD_TEXT}`,
          }}>
          {bonusInfo?.total > 1 ? t('POINTS_TEXT') : t('POINT_TEXT')}
        </AppText>
        <AppText />
      </>
    );
  };
  return (
    <>
      <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
      <AppSafeAreaView
        areaStyle={{
          ...styles.areaStyle,
          backgroundColor: AppSetting.state?.colors?.DASHBOARD_BONUS_BANNER,
        }}
        edges={['left', 'right']}>
        <DashboardHeader
          // LeftAction={<LogoIcon logoColor={AppSetting.state?.colors?.DASHBOARD_IMAGE} textColor={AppSetting.state?.colors?.DASHBOARD_IMAGE} textColor2={AppSetting.state?.colors?.DASHBOARD_IMAGE} />}
          LeftAction={leftHeader()}
          style={{
            ...styles.header,
            backgroundColor: AppSetting.state?.colors?.ROYAL_MAIL_RED,
          }}
          // RightAction={statusDashboard()}
          RightAction={rightButton()}
        />
        <AppKAView style={styles.kaView}>
          {/* <View style={styles.fakePadding}> */}
          <ScrollView
            style={[
              styles.container,
              // !notificationItem || !notificationItem?.show ? { paddingTop: Spacing.width16 } : {},
            ]}
            contentContainerStyle={{ flexGrow: 1 }}
            refreshControl={
              <RefreshControl
                refreshing={refresh}
                onRefresh={handleRefresh}
                tintColor={palette.LOADING_COLOR}
                colors={[palette.LOADING_COLOR]}
                titleColor={palette.LOADING_COLOR}
              />
            }>
            {!dashboardLoading && _.isEmpty(dashboard?.filter(x => x.show)) ? (
              <AppEmptyView />
            ) : (
              <ContentWithLoading dashboard={dashboard} loading={dashboardLoading} onPress={onItemPress} notificationItem={notificationItem}/>
            )}
          </ScrollView>
          {/* </View> */}
        </AppKAView>
          <CheckStatusModal show={show} setShow={setShow} data={checkStatusOfDashboard?.data} />
      </AppSafeAreaView>
    </>
  );
};

export { Dashboard };
