import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppButton, AppKAView, AppSafeAreaView, AppText } from '@components';
import { AppSuccessScreen } from '@components/AppSuccessScreen';
import { FormItemView } from '@components/Form';
import GenericHeader from '@components/GenericHeader';
import { UPLOAD_FILE } from '@constant';
import { FORM } from '@constant/model/RegisterForm';
import { CommonActions, useNavigation } from '@react-navigation/native';
import { goBack, reset } from '@routes/navigationUtils';
import { BOTTOM_TAB, DASHBOARD_TAB, Screens } from '@routes/route';
import { Dashboard } from '@screens/main/Dashboard';
import { useReportBundleDetail } from '@screens/main/report/bundle/detail/BundelDetail.hook';
import { useContainerBundle } from '@screens/main/report/bundle/detail/container/container.hook';
import { BundleAddressView } from '@screens/main/report/bundle/form/components/BundleAddress.view';
import { TopBundleView } from '@screens/main/report/bundle/form/components/TopBundle.view';
import { ReportSuccessfulView } from '@screens/main/report/package/form/components/ReportSuccessful.view';
import { TopPackageView } from '@screens/main/report/package/form/components/TopPackage.view';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font, Spacing } from '@theme/size';
import { useLocation } from '@utils/hooks';
import { FormTransform } from '@utils/transform/form';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Text, Platform } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import {RenderHelper} from "@utils/form/render";

export const ContainerBundle = props => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const {
    formik,
    summary,
    isSuccess,
    userLocation,
    setUserLocation,
    point,
    headerTitle,
    subFormRef,
    messageInfo,
    layoutInfo,
    setLayoutInfo,
    scrollViewRef,
    scrollToY,
  } = useContainerBundle(props);
  const subForm = FormTransform.getSubFormDispatch(props.formData?.attributes);

    const refLayoutInfo = useRef(null)
    useEffect(() => {
        refLayoutInfo.current = {}
    },[])

  console.log('ReportBundleDetailScreen>>>', subForm, props.formData?.attributes, props);
  const renderForm = () => {
    return (
      <>
        <KeyboardAwareScrollView
          extraHeight={150}
          style={[
            {
              backgroundColor: palette.WHITE,
              borderTopRightRadius: theme.spacing.lg,
              borderTopLeftRadius: theme.spacing.lg,
            },
          ]}
          innerRef={ref => {
            if (ref) {
              scrollViewRef.current = ref;
            }
          }}
          keyboardShouldPersistTaps="handled">
          <TopBundleView data={summary} />
          <View style={[globalStyle.directionColumm, RegisterFormStyles.paddingSpace]}>
            {props.formData?.attributes?.map((item, index) => {
              return (
                <FormItemView
                    key={`${item?.internalId}_${index}`}
                    style={[{ zIndex: Platform.OS === 'web' ? 'unset' : undefined}]}
                  index={index}
                  item={item}
                  formik={formik}
                  isBoldTitle={true}
                  checkCondition={true}
                  extraData={{
                    location: userLocation,
                    subForm: subForm,
                    defaultValue: {
                      ...formik?.values,
                      ...props.data?.data?.reportDetail,
                    },
                  }}
                  formData={props.formData?.attributes}
                  folderUpload={UPLOAD_FILE.folderUpload.bundle}
                  subFormRef={subFormRef}
                  onLayout={e => {
                      RenderHelper.onLayout(e, item, layoutInfo, setLayoutInfo, refLayoutInfo)
                  }}
                  scrollViewRef={scrollViewRef}
                />
              );
            })}
          </View>
        </KeyboardAwareScrollView>
        <View
          style={[
            RegisterFormStyles.paddingSpace,
            { marginVertical: theme.spacing.sm, marginBottom: Platform.OS === 'web' ? '20px' : Spacing.height25 },
          ]}>
          <AppButton
            onPress={async () => {
              // formik.handleSubmit()
              await FormTransform.submitAndScrollWithSubForm(
                formik,
                props.formData?.attributes,
                layoutInfo,
                scrollToY,
                subFormRef,
                FORM.FORM_ELEMENT_NAME.dispatch_point,
              );
            }}
            text={t('Report')}
          />
        </View>
      </>
    );
  };
  return (
    <>
      <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
      <AppSafeAreaView areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>
        <GenericHeader
          // title={t('REPORT_BUNDLE_DISPATCH')}
          title={headerTitle}
          style={[{ backgroundColor: '#EDEDED' }]}
        />
        <View style={[globalStyle.directionColumm, RegisterFormStyles.containerView]}>
          {isSuccess ? (
            <AppSuccessScreen
              alert={messageInfo?.alert}
              icon={messageInfo?.icon}
              description={messageInfo?.description}
            />
          ) : (
            renderForm()
          )}
        </View>
      </AppSafeAreaView>
    </>
  );
};

ContainerBundle.propTypes = {
  data: PropTypes.any.isRequired,
  formData: PropTypes.any.isRequired,
};

ContainerBundle.defaultProps = {};
