import {DefaultData, SVGIcons} from "@constant/appSetting";
import React from 'react';

import {
    ActiveCheckIcon,
    ActiveRadioIcon, AnswerIcon, ArrowBorderIcon, ArrowLeftIcon, ArrowRightIcon, AwaitingIcon,
    BackIcon,
    CalendarIcon,
    CheckIcon,
    ChevronDownIcon,
    ChevronPickerIcon,
    ChevronRightIcon, CirclePlusIcon, ClearIcon,
    ClockIcon,
    CrossIcon,
    EyeActiveIcon,
    EyeInactiveIcon, FailedIcon,
    FAQIcon, FilterIcon,
    FrameIcon,
    HomeIcon, ImageIcon, LogoBigIcon,
    LogoIcon,
    MailIcon,
    MarkerDashboardIcon,
    MarkerQRIcon,
    MarkerQRIcon2,
    MenuIcon,
    MessageCheckIcon,
    NoAvatarIcon,
    NumPadIcon, OverdueIcon,
    PadLockIcon, PassedIcon, PencilIcon,
    QRIcon,
    QRIcon2,
    RadioIcon, SentIcon,
    TaskIcon,
    ThankYouIcon,
    TickIcon,
    UserIcon,
    WarningIcon
} from "@assets";
import {AppSetting} from "@utils/common/setting";
import _ from 'lodash';
import {FlexImageIcon} from "@screens/main/Menu/Components";
// import {FlexImageIcon} from "@screens/main/PostingLocation/Components";


export const IconSvgLocal = (props) => {
    const imageKey = props?.imageKey
    switch (imageKey){
        case SVGIcons.MailIcon: return <MailIcon {...props}/>;
        case SVGIcons.EyeActiveIcon: return <EyeActiveIcon {...props}/>;
        case SVGIcons.MarkerDashboardIcon: return <MarkerDashboardIcon {...props}/>;
        case SVGIcons.PadLockIcon: return <PadLockIcon {...props}/>;
        case SVGIcons.EyeInactiveIcon: return <EyeInactiveIcon {...props}/>;
        case SVGIcons.NumPadIcon: return <NumPadIcon {...props}/>;
        case SVGIcons.QRIcon: return <QRIcon {...props}/>;
        case SVGIcons.FrameIcon: return <FrameIcon {...props}/>;
        case SVGIcons.RadioIcon: return <RadioIcon {...props}/>;
        case SVGIcons.ActiveRadioIcon: return <ActiveRadioIcon {...props}/>;
        case SVGIcons.CheckIcon: return <CheckIcon {...props}/>;
        case SVGIcons.ActiveCheckIcon: return <ActiveCheckIcon {...props}/>;
        case SVGIcons.ChevronDownIcon: return <ChevronDownIcon {...props}/>;
        case SVGIcons.CalendarIcon: return <CalendarIcon {...props}/>;
        case SVGIcons.ClockIcon: return <ClockIcon {...props}/>;
        case SVGIcons.TickIcon: return <TickIcon {...props}/>;
        case SVGIcons.CrossIcon: return <CrossIcon {...props}/>;
        case SVGIcons.MarkerQRIcon2: return <MarkerQRIcon2 {...props}/>;
        case SVGIcons.ChevronPickerIcon: return <ChevronPickerIcon {...props}/>;
        case SVGIcons.UserIcon: return <UserIcon {...props}/>;
        case SVGIcons.MarkerQRIcon: return <MarkerQRIcon {...props}/>;
        case SVGIcons.LogoIcon: return <LogoIcon {...props}/>;
        case SVGIcons.MessageCheckIcon: return <MessageCheckIcon {...props}/>;
        case SVGIcons.ThankYouIcon: return <ThankYouIcon {...props}/>;
        case SVGIcons.WarningIcon: return <WarningIcon {...props}/>;
        case SVGIcons.QRIcon2: return <QRIcon2 {...props}/>;
        case SVGIcons.HomeIcon: return <HomeIcon {...props}/>;
        case SVGIcons.TaskIcon: return <TaskIcon {...props}/>;
        case SVGIcons.FAQIcon: return <FAQIcon {...props}/>;
        case SVGIcons.MenuIcon: return <MenuIcon {...props}/>;
        case SVGIcons.BackIcon: return <BackIcon {...props}/>;
        case SVGIcons.NoAvatarIcon: return <NoAvatarIcon {...props}/>;
        case SVGIcons.ChevronRightIcon: return <ChevronRightIcon {...props}/>;
        case SVGIcons.PencilIcon: return <PencilIcon {...props}/>;
        case SVGIcons.PassedIcon: return <PassedIcon {...props}/>;
        case SVGIcons.FailedIcon: return <FailedIcon {...props}/>;
        case SVGIcons.ArrowRightIcon: return <ArrowRightIcon {...props}/>;
        case SVGIcons.ArrowLeftIcon: return <ArrowLeftIcon {...props}/>;
        case SVGIcons.ArrowBorderIcon: return <ArrowBorderIcon {...props}/>;
        case SVGIcons.ClearIcon: return <ClearIcon {...props}/>;
        case SVGIcons.FilterIcon: return <FilterIcon {...props}/>;
        case SVGIcons.ImageIcon: return <ImageIcon {...props}/>;
        case SVGIcons.CirclePlusIcon: return <CirclePlusIcon {...props}/>;
        case SVGIcons.SentIcon: return <SentIcon {...props}/>;
        case SVGIcons.LogoBigIcon: return <LogoBigIcon {...props}/>;
        case SVGIcons.OverdueIcon: return <OverdueIcon {...props}/>;
        case SVGIcons.AnswerIcon: return <AnswerIcon {...props}/>;
        case SVGIcons.AwaitingIcon: return <AwaitingIcon {...props}/>;
    }
    return <></>
}

export const IconSvg = (props) => {
    const icons = AppSetting.state.icons
    // const keys = Object.keys(icons)
    // if(Object.hasOwnProperty())
    console.log('IconSvg>>>', icons)
    const imageKey = props.imageKey
    if(_.isEmpty(icons)){
        return <IconSvgLocal {...props}/>
    }

    if(icons.hasOwnProperty(imageKey)){
        const data = icons[imageKey]
        if(_.isEmpty(data)){
            return <IconSvgLocal {...props}/>
        }

        const link = data?.link
        if(_.isEmpty(link)){
            return <IconSvgLocal {...props}/>
        }

        return <FlexImageIcon icon={link} {...props}/>
    }
    return <IconSvgLocal {...props}/>

}
