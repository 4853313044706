import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppKAView, AppSafeAreaView, AppText } from '@components';
import GenericHeader from '@components/GenericHeader';
import { goBack, popToTop } from '@routes/navigationUtils';
import { ReportStyle } from '@screens/main/report/Report/Report.style';
import { RewardTab } from '@screens/main/reward/Tab';
import { TicketTab } from '@screens/main/ticket/Tab';
import { useTicket } from '@screens/main/ticket/Ticket/Ticket.hook';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font } from '@theme/size';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Text } from 'react-native';
import { Host } from 'react-native-portalize';
import { useReward } from './Reward.hook';
import { RewardStyle } from './Reward.style';

export const RewardScreen = props => {
  const { t } = useTranslation();
  const { initialRouteName, initialParams } = useReward(props);
  return (
    <>
      <Host>
        <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
        <AppSafeAreaView areaStyle={[{ backgroundColor: '#EDEDED' }]}>
          <GenericHeader onBackClicked={popToTop} title={t('REWARD_HEADER')} style={[{ backgroundColor: '#EDEDED' }]} />
          <View style={[globalStyle.directionColumm, globalStyle.flex1, ReportStyle.containerView]}>
            <RewardTab initialRouteName={initialRouteName} initialParams={initialParams} />
          </View>
        </AppSafeAreaView>
      </Host>
    </>
  );
};
