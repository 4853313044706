import { Config } from '@constant/config';
import { LoginMockData } from '@constant/model/mock/loginMockData';
import { useRoute } from '@react-navigation/native';
import { getForgotFormRequest } from '@redux/auth/authSlice';
import { showLoading, hideLoading } from '@redux/global/globalSlice';
import {navigate, popToTop} from '@routes/navigationUtils';
import {LOGIN_SCREEN, FORGOT_CHANGE_PASS, Screens} from '@routes/route';
import api from '@services/api-client';
import { forgotPassword } from '@services/auth';
import { showErrorMsg } from '@utils/api';
import { useFormik } from 'formik';
import { t } from 'i18next';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  username: Yup.string().required(t('EMAIL_IS_REQUIRED')).email(t('EMAIL_IS_INVALID_FORMAT')),
});

export const useLogic = () => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      username: Config.IsDebug ? LoginMockData.username : '',
    },
    onSubmit: values => {
      handleSend(values);
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });
  const error = useRoute().params?.error || '';

  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    console.log('error123', error);
    if (error) showErrorMsg(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleLoginPress = () => {
    navigate(LOGIN_SCREEN);
  };

  const handleSend = async values => {
    const { username } = values;
    dispatch(showLoading());
    try {
      const formData = new FormData();
      formData.append('email', username.toLowerCase());
      const res = await forgotPassword(formData);
      await dispatch(getForgotFormRequest({ id: 6 }));
      console.log({ res });
      if (res.status) {
        // setModalShow(true);
        // setTimeout(() => {
        //   setModalShow(false);
        //   navigate(LOGIN_SCREEN);
        // }, 5000);
        navigate(Screens.SUCCESSFUL_SCREEN, {
          data: res?.message,
          header: {
            // title: headerTitle,
            backClick: () => {
              navigate(LOGIN_SCREEN);
            },
          },
        });
      } else {
        throw res.message;
      }
    } catch (error) {
      showErrorMsg(error);
    } finally {
      dispatch(hideLoading());
    }
  };
  return { handleLoginPress, handleSend, modalShow, formik };
};
