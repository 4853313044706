import { Constant } from '@constant';
import { createTicketService } from '@services/ticket';
// @ts-ignore
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { createTicketFailed, createTicketSuccess } from './createTicketSlice';
import {getListDashboardDetailSaga} from "@redux/app/sagas";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* createTicketSaga({ payload, meta, error }): Generator<
  | CallEffect
  | PutEffect<{
      payload: any;
      type: string;
    }>,
  void
> {
  try {
    const res = yield call(createTicketService, payload);
    console.log('createTicketSaga>>>>', res);
    if (res?.status) {
      yield call(getListDashboardDetailSaga, { internalIds: res?.triggerActions ?? [] });

      yield put(createTicketSuccess(res));
      if (meta) {
        yield call(meta, res);
      }
    } else {
      // @ts-ignore
      yield put(createTicketFailed(res));
      if (error) {
        yield call(error, res);
      }
    }
  } catch (err) {
    // @ts-ignore
    yield put(createTicketFailed(err));
    if (error) {
      yield call(error, err);
    }
  }
}
