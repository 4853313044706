import { Config } from '@constant/config';
import { ReportBundleMockData } from '@constant/model/mock/report/bundle';
import { ReportPackageMockData } from '@constant/model/mock/report/package';
import { useRoute } from '@react-navigation/native';
import { reportModal as reportModalSelector } from '@redux/app/selectors';
import { getReportPackageFormRequest } from '@redux/form/getReportPackageFormSlice';
import { getReportPackageFormCreator } from '@redux/form/selectors';
import { submitPackageRequest } from '@redux/package/submitPackageSlice';
import {navigate, popToTop} from '@routes/navigationUtils';
import { ReportModalTransform } from '@utils';
import { checkFormValidateExpression, getInitialFormValue, getYupStructure } from '@utils/form';
import { mappingForm, mappingStep } from '@utils/transform/form';
import { useFormik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import {useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { lazy } from 'yup';
import * as Yup from 'yup';
import {SuccessMessageMock} from "@constant/model/mock/successMessage";
import {Screens} from "@routes/route";
import {FormikHelper} from "@utils/hooks/formik";
import {hideLoading, showLoading} from "@redux/global/globalSlice";

export const useReportPackageForm = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const paramsData = useRoute().params?.data;
  const summary = {
    id: paramsData?.id,
    total: paramsData?.payload?.data?.count_bundles,
  };
  const formModal = useSelector(reportModalSelector);
  const headerTitle = ReportModalTransform.getReportTitle(paramsData?.reportType, formModal);
  const getReportPackageFormData = useSelector(getReportPackageFormCreator)?.data;
  // const rawData = mappingStep(ReportPackageMockData.form);
  // const rawData = mappingStep(!_.isEmpty(getReportPackageFormData) ? getReportPackageFormData[0] : null);
  const [data, setData] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [point, setPoint] = useState(0);
  const [messageInfo, setMessageInfo] = useState({});
  const scrollViewRef = useRef(null);
  const [layoutInfo, setLayoutInfo] = useState({});
  const [disabled, setDisabled] = useState(false);

  // const lo = Config.IsMockServer ? ReportBundleMockData.userLocation : {};
  // const [userLocation, setUserLocation] = useState(lo);
  const getReportPackageFormApi = () => {
    const payload = { number_of_planned_items: summary.total };
    // dispatch(getReportPackageFormRequest(payload));
    dispatch(showLoading());
    dispatch(
        getReportPackageFormRequest(
            payload,
            res => {
              dispatch(hideLoading());
            },
            err => {
              dispatch(hideLoading());
            },
        ),
    );
  };
  const submitPackageApi = payload => {
    /*
  params:
  {
      "Token": "{{token}}",
      "Accept": "application/json"
  }
  body:
  {
      "receipt_date": "2022-09-08",
      "correct_amount_items": true,
      "deviations":null
  }
   */
    if (disabled) {
      return;
    }
    setDisabled(true);
    dispatch(
      submitPackageRequest(
        payload,
        rs => {
          handleSuccess(rs);
          setDisabled(false);
        },
        err => {
          setDisabled(false);
        },
      ),
    );
  };

  const formik = FormikHelper.useFormikWrapper({
    initialValues: getInitialFormValue(data, {}),
    enableReinitialize: true,
    // validationSchema: Yup.object().shape(getYupStructure(rawData, t)),
    validationSchema: () =>
      lazy(values => {
        // console.log('lazy>>>values>>>', values);
        const ys = getYupStructure(data, t, 'attributes', values);
        console.log('getYupStructure>>>', ys, formik);
        return Yup.object().shape(ys);
      }),
    initialErrors: false,
    onSubmit: (values, { setErrors, resetForm }) => {
      console.log('onSubmit>>>', values, formik.errors, data);
      const rs = checkFormValidateExpression(data?.attributes, values, setErrors);
      if (!_.isEmpty(rs)) {
        return;
      }
      handleSubmit(values);
      //TODO: validate here
      // updateUserApi(values, isLastStep);
      // onSavePress();
    },
  }, data?.attributes);

  const handleSuccess = rs => {
    //TODO: reload api dashboard here
    setPoint(rs?.data?.point ?? 0);
    // setIsSuccess(true);
    // setMessageInfo(rs?.message)

    navigate(Screens.SUCCESSFUL_SCREEN, {
      data: rs?.message,
      header: {
        title: headerTitle,
        backClick: () => {
          popToTop();
        },
      },
    });

    // setTimeout(() => {
    //   popToTop();
    // }, 5000);
  };

  const handleSubmit = values => {
    const payload = {
      ...values,
      id: paramsData.id,
    };

    const mock = Config.IsMockServer;
    if (mock) {
      handleSuccess();
    } else {
      submitPackageApi(payload);
    }
  };

  console.log('useReportPackageForm>>>', formik, data, getReportPackageFormData);
  useEffect(() => {
    getReportPackageFormApi();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      dispatch(hideLoading())
    }, 3000)
  }, [])

  const scrollToY = y => {
    // scrollViewRef.current.scroll
    scrollViewRef.current?.scrollTo({
      x: 0,
      y: y,
      animated: true,
    });
  };

  useEffect(() => {
    const x = _.cloneDeep(getReportPackageFormData);
    const rd = mappingStep(!_.isEmpty(x) ? x[0] : null);
    setData(rd);
  }, [getReportPackageFormData]);
  return { formik, data, summary, isSuccess, point, headerTitle,
    scrollToY,
    scrollViewRef,
    layoutInfo, setLayoutInfo,
    messageInfo,
    disabled,
  };
};
