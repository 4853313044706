import { UPLOAD_FILE } from '@constant';
import { Config } from '@constant/config';
import { FORM } from '@constant/model/RegisterForm';
import { ReportBundleMockData } from '@constant/model/mock/report/bundle';
import { ReportICodeMockData } from '@constant/model/mock/report/iCode';
import { SuccessMessageMock } from '@constant/model/mock/successMessage';
import { useNavigation, useRoute } from '@react-navigation/native';
import {mapSettingSelector, reportModal as reportModalSelector} from '@redux/app/selectors';
import { submitBundleRequest } from '@redux/bundle/submitBundleSlice';
import { uploadFileRequest } from '@redux/file/uploadFileSlice';
import { Device} from "@theme/device";
import { getReportBundleFormRequest } from '@redux/form/getReportBundleFormSlice';
import { getReportBundleFormCreator } from '@redux/form/selectors';
import {goBack, navigate, navigationRef, popToTop} from '@routes/navigationUtils';
import { Screens } from '@routes/route';
import { BundleAddressContainerModalStyle } from '@screens/main/report/bundle/form/components/BundleAddressContainerModal.style';
import { SubForm } from '@screens/main/report/bundle/form/components/DispatchPoint.hook';
import { ReportModalTransform } from '@utils';
import { checkFormValidateExpression, getInitialFormValue, getYupStructure } from '@utils/form';
import {LocationType, useLocation} from '@utils/hooks';
import { FormTransform, mappingStep } from '@utils/transform/form';
import { rejects } from 'assert';
import { useFormik } from 'formik';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { lazy } from 'yup';
import * as Yup from 'yup';
import {NavigateEvent, NavigationHelper} from "@utils/common/navigation";
import {FormikHelper} from "@utils/hooks/formik";
import {hideLoading, showLoading} from "@redux/global/globalSlice";
import {getMapSettingRequest} from "@redux/app/appSlice";
import {useWatchLocation} from "@utils/hooks/location/watch";
import {MapHelper} from "@utils/common/map";

export const useReportBundleForm = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // const { lat, long, loading, handleGetLocation, locationType } = useLocation(props);
  const { lat, long, loading, requestLocation, clearListenLocation, locationType } = useWatchLocation(props);

  const scrollViewRef = useRef(null);
  const [layoutInfo, setLayoutInfo] = useState({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const paramsData = useRoute().params?.data;
  const formModal = useSelector(reportModalSelector);
  const headerTitle = ReportModalTransform.getReportTitle(paramsData?.reportType, formModal);
  const summary = {
    id: paramsData.id,
    total: paramsData?.payload?.data?.count_testitems,
    date: paramsData?.payload?.data?.target_dispatch_date,
  };
  const getReportBundleFormData = Config.IsDebug
    ? ReportBundleMockData.form
    : useSelector(getReportBundleFormCreator)?.data;
  const mapSettings = useSelector(mapSettingSelector);

  // const rawData = mappingStep(ReportBundleMockData.form);
  // const rawData = mappingStep(!_.isEmpty(getReportBundleFormData) ? getReportBundleFormData[0] : null);
  const [data, setData] = useState(null);
  const lo = Config.IsMockServer ? ReportBundleMockData.userLocation : {};
  const [userLocation, setUserLocation] = useState(lo);
  const [isSuccess, setIsSuccess] = useState(false);
  const [point, setPoint] = useState(0);
  const [messageInfo, setMessageInfo] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const subFormRef = useRef();
  const getReportBundleFormApi = () => {
    const payload = {
      number_of_planned_items: summary?.total,
      bundle_id: summary?.id,
    };
    // dispatch(getReportBundleFormRequest(payload));
    dispatch(getMapSettingRequest());
    dispatch(showLoading());
    dispatch(
        getReportBundleFormRequest(
            payload,
            res => {
              dispatch(hideLoading());
            },
            err => {
              dispatch(hideLoading());
            },
        ),
    );
  };

  const formik = FormikHelper.useFormikWrapper({
    initialValues: {
      ...getInitialFormValue(data, {}),
      posting_location_id: null,
      postbox_image_url: [],
      collection_times_image_url: [],
      confirm_collection_time: '',
      // posting_datetime: '',
      // dispatch_point: '',
      // confirm_count_items: '',
      // dispatch_point: null,
    },
    enableReinitialize: true,
    // validationSchema: Yup.object().shape(getYupStructure(rawData, t)),
    validationSchema: () =>
      lazy(vl => {
        const values = _.cloneDeep(formik.values);
        console.log('lazy>>>values>>>', vl, values);
        const ys = getYupStructure(data, t, 'attributes', values);
        console.log('getYupStructure>>>', ys, formik);
        return Yup.object().shape(ys);
      }),
    initialErrors: false,
    onSubmit: async (values, { setErrors, resetForm }) => {
      setFormValues(null)
      console.log('onSubmit>>>', values, formik.errors, data);

      const rs = checkFormValidateExpression(data?.attributes, values, setErrors);
      console.log('validateForm>>>init', subFormRef?.current);

      //TODO: should check before submit validate in parent form
      if (subFormRef?.current != null) {
        const subForm =
          data?.attributes?.find(x => x.internalId === FORM.FORM_ELEMENT_NAME.dispatch_point)?.subForms ?? [];
        // const subForm = SubForm

        console.log('validateForm>>>before>>>', subFormRef?.current);
        // subFormRef?.current?.handleSubmit
        const errSubForm = await FormTransform.validateForm(subFormRef?.current, subForm, true);
        console.log('errSubForm>>>', errSubForm);
        if (!_.isEmpty(errSubForm)) {
          return;
        }

        values = {
          ...subFormRef?.current?.values,
          ...values,
        };
      }

      console.log('validateForm>>>parent', rs);

      if (!_.isEmpty(rs)) {
        formik.setErrors(_.merge(formik.errors, rs));
        return;
      }
        //TODO: should show popup submit here.

        if (subFormRef?.current == null) {
          // setFormValues(values);
          handleSubmit(values);
        } else {
          setFormValues(values);
          const isVerifyGood = MapHelper.isVerifyGood(
            subFormRef?.current?.values[FORM.FORM_ELEMENT_NAME.address_selected_extra],
            userLocation,
            mapSettings?.verifySetting?.radius,
            locationType,
          );
          const isAllowGps = locationType === LocationType.gps;

          const isShowModal = MapHelper.isShowModalWhenSubmit(isAllowGps, isVerifyGood);
          if (isShowModal) {
            setShowConfirmModal(true);
          }
          else{
            handleSubmit(values);
          }
        }
    },
  }, data?.attributes);

  const uploadFileApi = attachments => {
    return new Promise((resolve, rejects) => {
      if (!_.isEmpty(attachments)) {
        const formData = new FormData();
        formData.append('folder', 'bundle');
        attachments.forEach(file => {
          if (!_.isEmpty(file)) {
            formData.append('files[]', {
              ...file,
              name: file?.fileName,
            });
          }
        });
        dispatch(
          uploadFileRequest(
            formData,
            rs => {
              // onSuccess(rs?.data);
              resolve(rs?.data);
            },
            err => {
              resolve([]);
            },
          ),
        );
      } else {
        resolve([]);
      }
    });
  };

  const uploadAllFileApi = async values => {
    const payload = _.cloneDeep(values);
    const atts = FormTransform.getImagesFormik(values, formik, data?.attributes);
    // const attachments = values.attachments;
    if (_.isEmpty(atts)) {
      return payload;
    }

    for (const internalId in atts) {
      // const result = await uploadFileApi(atts[internalId]);
      const result = await FormTransform.uploadFileApi(atts[internalId], dispatch, 'bundle');
      payload[internalId] = result?.map(x => x?.response);
    }
    return payload;
  };

  const submitBundleApi = payload => {
    if (disabled) {
      return;
    }
    setDisabled(true);
    // setTimeout(() => {
    //   setDisabled(false)
    // }, 1000)
    // return;
    dispatch(
      submitBundleRequest(
        payload,
        rs => {
          handleSuccess(rs);
          setDisabled(false);
        },
        err => {
          setDisabled(false);
        },
      ),
    );
  };

  const handleSuccess = rs => {
    //TODO: reload api dashboard here
    setPoint(rs?.data?.point ?? 0);
    navigate(Screens.SUCCESSFUL_SCREEN, {
      data: rs?.message,
      header: {
        title: headerTitle,
        backClick: () => {
          clearListenLocation()
          popToTop();
        },
      },
    });
    // setIsSuccess(true);
    // setMessageInfo(rs?.message);
    // setTimeout(() => {
    //   popToTop();
    // }, 5000);
  };

  const handleSubmit = async values => {
    // let payload = await uploadAllFileApi(values);
    let payload = await FormTransform.uploadFileFormikFDBApi(
      values,
      data?.attributes,
      formik,
      dispatch,
      UPLOAD_FILE.folderUpload.bundle,
    );
    payload.id = paramsData?.id;
    payload.deviceType = Device.deviceType;
    payload.location_type = locationType

    if (!_.isEmpty(userLocation) && !!userLocation?.longitude) {
      payload.user_location = Object.values(userLocation);
    } else {
      payload.user_location = null;
    }
    console.log('handleSubmit>>>payload>>', values, payload, userLocation, !!userLocation?.longitude);
    // return;
    const mock = Config.IsMockServer;
    if (mock) {
      handleSuccess();
    } else {
      submitBundleApi(payload);
    }
  };

  const scrollToY = y => {
    // scrollViewRef.current.scroll
    scrollViewRef.current?.scrollTo({
      x: 0,
      y: y,
      animated: true,
    });
  };

  const onBackPress = () => {
    // isSuccess ? () => popToTop() : goBack
    clearListenLocation()
    goBack()
  }

  const onYesConfirm = () => {
    //TODO: Submit form
    setShowConfirmModal(false);
    handleSubmit(formValues)
  };

  const onNoConfirm = () => {
    //TODO: "No" - redirect to map view
    setShowConfirmModal(false);
    navigate(Screens.VERIFY_MAP_SCREEN, {
      data: {
        location: userLocation,
        mapSettings: mapSettings,
        address_selected_extra: subFormRef?.current?.values[FORM.FORM_ELEMENT_NAME.address_selected_extra],
      },
    });
    // setShowSuccessModal(true)
  };

  console.log('useReportPackageForm>>>', formik);
  console.log('useReportPackageForm>>>mapSettings', mapSettings);

  useEffect(() => {
    if (Config.IsDebug) {
      return;
    }
    getReportBundleFormApi();
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(hideLoading())
    }, 3000)
  }, [])

  useEffect(() => {
    const unsubscribe = props.navigation?.addListener(NavigateEvent.transitionEnd, e => {
      // Do something
      console.log('transitionEnd>>>', navigationRef.current?.getState());
      setTimeout(() => {
        const currentScreen = NavigationHelper.getCurrentScreen(props?.navigation)
        console.log('currentScreen>>>', currentScreen);
        //TEMPFIX: delay 100ms to waitiing transitionEnd when swipe
        // system bug: duplicate transitionEnd when swipe to back on ios
        if (currentScreen === Screens.REPORT_BUNDLE_FORM_SCREEN) {
          // handleGetLocation()
          clearListenLocation();
          requestLocation();
        } else {
          clearListenLocation();
        }
      }, 100)
    });
    return unsubscribe;
  }, [props.navigation]);

  useEffect(() => {
    const x = _.cloneDeep(getReportBundleFormData);
    const rd = mappingStep(!_.isEmpty(x) ? x[0] : null);
    setData(rd);
    console.log('getReportBundleFormData>>>', rd);
  }, [getReportBundleFormData]);

  useEffect(() => {
    setUserLocation({
      latitude: lat,
      longitude: long,
    });
    console.log('location>>>', lat, long);
  }, [lat, long]);
  return {
    formik,
    data,
    summary,
    isSuccess,
    userLocation,
    setUserLocation,
    point,
    headerTitle,
    subFormRef,
    scrollToY,
    scrollViewRef,
    layoutInfo,
    setLayoutInfo,
    messageInfo,
    disabled,
    mapSettings,
    clearListenLocation,
    onBackPress,
    locationType,
    showConfirmModal,
    onYesConfirm,
    onNoConfirm,
  };
};
