import type { RootState } from '@redux/reducers';
import { createSelector } from '@reduxjs/toolkit';
import { getErrorMessage } from '@utils/api';

export const validateUserSelector = (state: RootState) => state.validateUser;
export const validateUserCreator = createSelector(validateUserSelector, state => state.payload);
export const validateUserLoading = createSelector(validateUserSelector, state => state.loading);
export const validateUserError = createSelector(validateUserSelector, state => state.error);
export const validateUserErrorMsg = createSelector(validateUserError, state => getErrorMessage(state));

export const getSettingsSelector = (state: RootState) => state.getSettings;
export const getSettingsCreator = createSelector(getSettingsSelector, state => state.payload);
export const getSettingsLoading = createSelector(getSettingsSelector, state => state.loading);
export const getSettingsError = createSelector(getSettingsSelector, state => state.error);
export const getSettingsErrorMsg = createSelector(getSettingsError, state => getErrorMessage(state));
export const getSettingsDashboard = createSelector(getSettingsSelector, state => state.payload?.data?.dashboard);
export const getSettingsNotification = createSelector(getSettingsSelector, state => state.payload?.data?.notification);

export const getUserSelector = (state: RootState) => state.getUser;
export const getUserCreator = createSelector(getUserSelector, state => state.payload);
export const getUserLoading = createSelector(getUserSelector, state => state.loading);
export const getUserError = createSelector(getUserSelector, state => state.error);
export const getUserErrorMsg = createSelector(getUserError, state => getErrorMessage(state));

export const updateUserSelector = (state: RootState) => state.updateUser;
export const updateUserCreator = createSelector(updateUserSelector, state => state.payload);
export const updateUserLoading = createSelector(updateUserSelector, state => state.loading);
export const updateUserError = createSelector(updateUserSelector, state => state.error);
export const updateUserErrorMsg = createSelector(updateUserError, state => getErrorMessage(state));

export const activeUserSelector = (state: RootState) => state.activeUser;
export const activeUserCreator = createSelector(activeUserSelector, state => state.payload);
export const activeUserLoading = createSelector(activeUserSelector, state => state.loading);
export const activeUserError = createSelector(activeUserSelector, state => state.error);
export const activeUserErrorMsg = createSelector(activeUserError, state => getErrorMessage(state));

export const loginSelector = (state: RootState) => state.login;
export const loginCreator = createSelector(loginSelector, state => state.payload);
export const loginLoading = createSelector(loginSelector, state => state.loading);
export const loginError = createSelector(loginSelector, state => state.error);
export const loginErrorMsg = createSelector(loginError, state => getErrorMessage(state));

export const logoutSelector = (state: RootState) => state.logout;
export const logoutCreator = createSelector(logoutSelector, state => state.payload);
export const logoutLoading = createSelector(logoutSelector, state => state.loading);
export const logoutError = createSelector(logoutSelector, state => state.error);
export const logoutErrorMsg = createSelector(logoutError, state => getErrorMessage(state));

export const forgotPasswordSelector = (state: RootState) => state.forgotPassword;
export const forgotPasswordCreator = createSelector(forgotPasswordSelector, state => state.payload);
export const forgotPasswordLoading = createSelector(forgotPasswordSelector, state => state.loading);
export const forgotPasswordError = createSelector(forgotPasswordSelector, state => state.error);
export const forgotPasswordErrorMsg = createSelector(forgotPasswordError, state => getErrorMessage(state));

export const resetPasswordSelector = (state: RootState) => state.resetPassword;
export const resetPasswordCreator = createSelector(resetPasswordSelector, state => state.payload);
export const resetPasswordLoading = createSelector(resetPasswordSelector, state => state.loading);
export const resetPasswordError = createSelector(resetPasswordSelector, state => state.error);
export const resetPasswordErrorMsg = createSelector(resetPasswordError, state => getErrorMessage(state));

export const getProfileSelector = (state: RootState) => state.getProfile;
export const getProfileCreator = createSelector(getProfileSelector, state => state.payload);
export const getProfileLoading = createSelector(getProfileSelector, state => state.loading);
export const getProfileError = createSelector(getProfileSelector, state => state.error);
export const getProfileErrorMsg = createSelector(getProfileError, state => getErrorMessage(state));

export const updateProfileSelector = (state: RootState) => state.updateProfile;
export const updateProfileCreator = createSelector(updateProfileSelector, state => state.payload);
export const updateProfileLoading = createSelector(updateProfileSelector, state => state.loading);
export const updateProfileError = createSelector(updateProfileSelector, state => state.error);
export const updateProfileErrorMsg = createSelector(updateProfileError, state => getErrorMessage(state));

export const unlockSkillSelector = (state: RootState) => state.unlockSkill;
export const unlockSkillCreator = createSelector(unlockSkillSelector, state => state.payload);
export const unlockSkillLoading = createSelector(unlockSkillSelector, state => state.loading);
export const unlockSkillError = createSelector(unlockSkillSelector, state => state.error);
export const unlockSkillErrorMsg = createSelector(unlockSkillError, state => getErrorMessage(state));

export const getBonusPointListSelector = (state: RootState) => state.getBonusPointList;
export const getBonusPointListCreator = createSelector(getBonusPointListSelector, state => state.payload);
export const getBonusPointListLoading = createSelector(getBonusPointListSelector, state => state.loading);
export const getBonusPointListError = createSelector(getBonusPointListSelector, state => state.error);
export const getBonusPointListErrorMsg = createSelector(getBonusPointListError, state => getErrorMessage(state));
export const getBonusPointListRefreshToken = createSelector(getBonusPointListSelector, state => state.refreshToken);

export const listVoucherSelector = (state: RootState) => state.listVoucher;
export const listVoucherCreator = createSelector(listVoucherSelector, state => state.payload);
export const listVoucherLoading = createSelector(listVoucherSelector, state => state.loading);
export const listVoucherError = createSelector(listVoucherSelector, state => state.error);
export const listVoucherErrorMsg = createSelector(listVoucherError, state => getErrorMessage(state));

export const redeemVoucherSelector = (state: RootState) => state.redeemVoucher;
export const redeemVoucherCreator = createSelector(redeemVoucherSelector, state => state.payload);
export const redeemVoucherLoading = createSelector(redeemVoucherSelector, state => state.loading);
export const redeemVoucherError = createSelector(redeemVoucherSelector, state => state.error);
export const redeemVoucherErrorMsg = createSelector(redeemVoucherError, state => getErrorMessage(state));

export const requestChangeEmailSelector = (state: RootState) => state.requestChangeEmail;
export const requestChangeEmailCreator = createSelector(requestChangeEmailSelector, state => state.payload);
export const requestChangeEmailLoading = createSelector(requestChangeEmailSelector, state => state.loading);
export const requestChangeEmailError = createSelector(requestChangeEmailSelector, state => state.error);
export const requestChangeEmailErrorMsg = createSelector(requestChangeEmailError, state => getErrorMessage(state));

export const changeEmailSelector = (state: RootState) => state.changeEmail;
export const changeEmailCreator = createSelector(changeEmailSelector, state => state.payload);
export const changeEmailLoading = createSelector(changeEmailSelector, state => state.loading);
export const changeEmailError = createSelector(changeEmailSelector, state => state.error);
export const changeEmailErrorMsg = createSelector(changeEmailError, state => getErrorMessage(state));

export const checkUnsubscribeConditionsSelector = (state: RootState) => state.checkUnsubscribeConditions;
export const checkUnsubscribeConditionsCreator = createSelector(checkUnsubscribeConditionsSelector, state => state.payload);
export const checkUnsubscribeConditionsLoading = createSelector(checkUnsubscribeConditionsSelector, state => state.loading);
export const checkUnsubscribeConditionsError = createSelector(checkUnsubscribeConditionsSelector, state => state.error);
export const checkUnsubscribeConditionsErrorMsg = createSelector(checkUnsubscribeConditionsError, state => getErrorMessage(state));

export const acceptUserCdmSelector = (state: RootState) => state.acceptUserCdm;
export const acceptUserCdmCreator = createSelector(acceptUserCdmSelector, state => state.payload);
export const acceptUserCdmLoading = createSelector(acceptUserCdmSelector, state => state.loading);
export const acceptUserCdmError = createSelector(acceptUserCdmSelector, state => state.error);
export const acceptUserCdmErrorMsg = createSelector(acceptUserCdmError, state => getErrorMessage(state));

