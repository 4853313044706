import { LogoIcon, MessageCheckIcon, ThankYouIcon } from '@assets';
import { AppButton, AppKAView, AppSafeAreaView, AppText } from '@components';
import { popToTop } from '@routes/navigationUtils';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font, FontSize, Spacing } from '@theme/size';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text } from 'react-native';
import { useCDSSuccessful } from './CDSSuccessful.hook';
import { ReportSuccessfulStyle } from './CDSSuccessful.style';

export const CDSSuccessfulView = props => {
  const { t } = useTranslation();
  const { desc1, desc2, desc3, point, note, desc4, desc5 } = useCDSSuccessful(props);
  return (
    <View style={[globalStyle.flex1, globalStyle.alignCenter, globalStyle.justifyCenter]}>
      <View
        style={[
          globalStyle.alignCenter,
          globalStyle.justifyCenter,
          globalStyle.alignSelfCenter,
          globalStyle.directionColumm,
          globalStyle.flex1,
        ]}>
        {/*<AppText style={[Font.Bold_700, { textAlign: 'center', margin: theme.spacing.sm, fontSize: FontSize.Font15 }]}>*/}
        {/*  {title}*/}
        {/*</AppText>*/}

        <MessageCheckIcon color={palette.GREEN_ACTIVE} />

        <AppText
          style={[
            {
              textAlign: 'center',
              marginHorizontal: theme.spacing.lg,
              fontSize: FontSize.Font15,
              color: '#666666',
              marginTop: 10,
            },
          ]}>
          {/* {desc1} */}
          <AppText style={[Font.Bold_700, { lineHeight: 40 }]}>
            {desc4}
            {/* {' '} */}
            {/* {point} {desc2}{' '} */}
          </AppText>
          {/* {`\n${desc5}`} */}
          {/* {desc3} */}
        </AppText>
      </View>
      {/* <View
        style={[RegisterFormStyles.paddingSpace, { marginVertical: theme.spacing.sm, marginBottom: Spacing.height25 }]}>
        <AppButton
          onPress={() => {
            popToTop();
          }}
          style={[{ paddingHorizontal: theme.spacing.md }]}
          text={t('BACK_TO_DASHBOARD')}
        />
      </View> */}
      {/*<AppText*/}
      {/*  style={[*/}
      {/*    {*/}
      {/*      textAlign: 'center',*/}
      {/*      marginHorizontal: theme.spacing.sm,*/}
      {/*      fontSize: FontSize.Font15,*/}
      {/*      color: '#666666',*/}
      {/*      marginBottom: Spacing.height25,*/}
      {/*    },*/}
      {/*  ]}>*/}
      {/*  {note}*/}
      {/*</AppText>*/}
    </View>
  );
};

CDSSuccessfulView.propTypes = {
  point: PropTypes.any,
};

CDSSuccessfulView.defaultProps = {};
