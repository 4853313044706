import {PERMISSION_MODEL, PERMISSION_TYPE} from '@constant';
import { PermissionUtil } from '@utils/common/permission';
import DeviceInfo from 'react-native-device-info';
import { RESULTS } from 'react-native-permissions';

export const LocationHelper = {
  checkLocationEnable: async () => {
    const enable = await DeviceInfo.isLocationEnabled();
    if (!enable) {
      PermissionUtil.showPermissionModal(PERMISSION_MODEL.LOCATION);
      return false;
    }
    const grant = await PermissionUtil.locationPermission(PERMISSION_TYPE.CHECK);
    if ([RESULTS.GRANTED, RESULTS.LIMITED].includes(grant)) {
      return true;
    }
    PermissionUtil.showPermissionModal(PERMISSION_MODEL.LOCATION);
    return false
  },
};
