import {createAction, createSlice} from '@reduxjs/toolkit';

export interface IgetListPostingLocationState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IgetListPostingLocationState = {
  loading: false,
  payload: null,
  error: null,
};

export const getListPostingLocationRequest = createAction(
    'getListPostingLocation/getListPostingLocationRequest',
    (payload, onSuccess, onFailed) => {
      console.log('prepareAction>>>', payload, onSuccess);
      return {
        payload: payload,
        meta: onSuccess,
        error: onFailed,
      };
    },
);

const getListPostingLocationSlice = createSlice({
  name: 'getListPostingLocation',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // getListPostingLocationRequest(state, action) {
    //   state.loading = true;
    //   // @ts-ignore
    //   state.payload = null;
    //   state.error = null;
    // },
    getListPostingLocationSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    getListPostingLocationFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getListPostingLocationSuccess, getListPostingLocationFailed } = getListPostingLocationSlice.actions;

export default getListPostingLocationSlice.reducer;
