import { useRoute } from '@react-navigation/native';
import { mapSettingSelector } from '@redux/app/selectors';
import { hideLoading } from '@redux/global/globalSlice';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import Geolocation from 'react-native-geolocation-service';
import { useDispatch, useSelector } from 'react-redux';
import {MapHelper} from "@utils/common/map";

export const useVerifyMap = props => {
  const dispatch = useDispatch();
  const mapViewRef = useRef(null);
  // const dataParams = props.
  const dataParams = useRoute()?.params?.data;

  const [lat, setLat] = useState(dataParams?.location?.latitude);
  const [long, setLong] = useState(dataParams?.location?.longitude);
  const [region, setRegion] = useState(undefined);

  const mapSettings = useSelector(mapSettingSelector);
  // const mapSettings = useSelector(mapSettingSelector);
  const [selectedTab, setSelectedTab] = useState(mapSettings?.postTypes?.[0]);

  useEffect(() => {
    if (_.isNil(dataParams)) {
      return;
    }
    setRegion({
      longitudeDelta: 0.005,
      latitude: Number(dataParams?.address_selected_extra?.latitude ?? 0),
      longitude: Number(dataParams?.address_selected_extra?.longitude ?? 0),
      latitudeDelta: 0.005,
    });

    if (Platform.OS === 'web') {
      mapViewRef.current?.animateCamera({
        center: {
          latitude: Number(dataParams?.address_selected_extra?.latitude ?? 0),
          longitude: Number(dataParams?.address_selected_extra?.longitude ?? 0),
        },
      });
    }
  }, [dataParams]);

  useEffect(() => {
    MapHelper.showBottomMapModal();
    setTimeout(() => {
      dispatch(hideLoading());
    }, 3000);
  });
  return {
    mapSettings,
    mapViewRef,
    lat,
    setLat,
    long,
    setLong,
    region,
    dataParams,
    selectedTab
  };
};
