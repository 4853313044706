import api from '@services/api-client';
import { stringify } from 'qs';
import withQuery from 'with-query';

export const submitRFIDService = async payload => {
  console.log('submitRFIDService>>>', payload)
  const response = await api.put(`/rfid.json`, payload)
  console.log('submitRFIDService response>>>', response)
  return response?.data
}

