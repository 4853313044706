import { Constant } from '@constant';
import { getListDashboardDetailSaga } from '@redux/app/sagas';
import { submitRFIDService } from '@services/rfid';
// @ts-ignore
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { all, call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { submitRFIDFailed, submitRFIDSuccess } from './submitRFIDSlice';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* submitRFIDSaga({ payload, meta, error }): Generator<
  | CallEffect
  | PutEffect<{
      payload: any;
      type: string;
    }>,
  void
> {
  try {
    const res = yield call(submitRFIDService, payload);
    console.log('submitRFIDSaga>>>>', res);
    if (res?.status) {
      // yield all(
      //   res.data.map(item => {
      //     return call(getListDashboardDetailSaga, { internalId: item?.internalId });
      //   }),
      // );
      yield call(getListDashboardDetailSaga, { internalIds: res?.triggerActions ?? [] });
      yield put(submitRFIDSuccess(res));
      if (meta) {
        yield call(meta, res);
      }
    } else {
      // @ts-ignore
      yield put(submitRFIDFailed(res));
      if (error) {
        yield call(error, res);
      }
    }
  } catch (err) {
    // @ts-ignore
    yield put(submitRFIDFailed(err));
    if (error) {
      yield call(error, err);
    }
  }
}
