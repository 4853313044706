import { CrossIcon, MarkerQRIcon } from '@assets';
import { AppButton, AppSafeAreaView, AppText } from '@components';
import { HowToButton } from '@screens';
import { globalStyle, WEB_WIDTH } from '@theme';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, TouchableHighlight, TouchableOpacity, View } from 'react-native';
import { backgroundColor } from 'react-native-calendars/src/style';
import QRCodeScanner from 'react-native-qrcode-scanner';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { styles } from './InputScanCode.style';
import {useInputScanCode} from "@screens/auth/ScanCode/inputScan/InputScanCode.hook";

const InputScanCodeScreen = props => {
  const { handleReadQR, handleClosePress, resultText } = useInputScanCode(props);
  const { t } = useTranslation();
  const [focus, setFocus] = useState({
    width: 1,
    height: 1,
    x: 1,
    y: 1,
  });

  const renderMarker = () => {
    console.log('renderMarker>>>>');
    // return <AppText style={[styles.textScanTop]} >test test test test test test test test test test test test test test test  </AppText>
    return (
      <View style={styles.rectangleContainer}>
        <View style={styles.topOverlay}>
          <View style={[styles.topScanText]}>
            <AppText style={[styles.textScanTop]}>{t('PLEASE_SCAN_CODES')}</AppText>
          </View>
        </View>

        <View style={{ flexDirection: 'row', position: Platform.OS === 'web' ? 'relative' : undefined }}>
          <View style={styles.leftAndRightOverlay} />
          <View style={styles.rectangle}>
            <View>
              <MarkerQRIcon width={Device.isLargeDevice ? 300 : 280} height={Device.isLargeDevice ? 300 : 280} />
            </View>
            {!!resultText && (
              <View style={[styles.bottomScanText]}>
                <AppText style={[styles.textScanBottom]}>{resultText}</AppText>
              </View>
            )}
          </View>

          <View style={styles.leftAndRightOverlay} />
        </View>

        <View style={styles.bottomOverlay} />
      </View>
    );
  };

  const renderMarkerForWebV2 = () => {
    console.log('renderMarkerForWebV2>>>', Device);

    return (
      <View style={[{ flexDirection: 'column', width: WEB_WIDTH, height: WEB_WIDTH }]}>
        <View style={[{ backgroundColor: '#00000050', height: WEB_WIDTH * 0.2 }]}></View>
        <View style={[{ flexDirection: 'row', flex: 1, zIndex: 100 }]}>
          <View style={[{ backgroundColor: '#00000050', width: WEB_WIDTH * 0.2 }]}></View>
          <View
            style={[
              { backgroundColor: 'transparent', flex: 1, alignItems: 'center', justifyContent: 'center', zIndex: 100 },
            ]}>
            <View>
              <MarkerQRIcon width={WEB_WIDTH * 0.65} height={WEB_WIDTH * 0.65} opacity={1} />
            </View>
            <View style={[styles.topScanTextWeb]}>
              <AppText style={[styles.textScanTop]}>{t('PLEASE_SCAN_CODES')}</AppText>
            </View>
            {!!resultText && (
              <View style={[styles.bottomScanTextWeb]}>
                <AppText style={[styles.textScanBottom]}>{resultText}</AppText>
              </View>
            )}
          </View>
          <View style={[{ backgroundColor: '#00000050', width: WEB_WIDTH * 0.2 }]}></View>
        </View>

        <View style={[{ backgroundColor: '#00000050', height: WEB_WIDTH * 0.2 }]}></View>
      </View>
    );
  };

  return (
    <AppSafeAreaView>
      <View style={styles.container}>
        <View style={styles.form}>
          <TouchableOpacity onPress={handleClosePress} style={styles.closeBtn}>
            <CrossIcon />
          </TouchableOpacity>
          <QRCodeScanner
            cameraProps={{
              cameraViewDimensions: {
                width: Device.width,
                height: Device.height,
              },
              autoFocusPointOfInterest: { x: focus.x / focus.width, y: focus.y / focus.height },
            }}
            vibrate={false}
            reactivate
            reactivateTimeout={2000}
            cameraStyle={styles.camera}
            onRead={handleReadQR}
            showMarker
            customMarker={renderMarker()}
          />
          {Platform.OS === 'web' && (
            <View style={[{ position: 'absolute', width: Device.width, height: Device.height }, globalStyle.center]}>
              {renderMarkerForWebV2()}
            </View>
          )}
        </View>
      </View>
    </AppSafeAreaView>
  );
};

export { InputScanCodeScreen };
