// export const PostcodeModal:
import { AddressContainerView } from '@screens/main/SearchAddress/components/AddressContainer.view';
import { useSearchPostcode } from '@screens/main/SearchPostcode/SearchPostcode.hook';
import { AppPostcode } from '@screens/main/SearchPostcode/components/AppPostcode.view';
import { usePostcodeContainer } from '@screens/main/SearchPostcode/components/usePostcodeContainer';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

export const PostcodeContainerModal = props => {
  console.log("PostcodeContainerModal>>>", props)
  const { t } = useTranslation();
  const {
    onSearch,
    data,
    onRefresh,
    isRefreshing,
    loadingMore,
    loading,
    onLoadMore,
    onTextChanged,
    searchText,
    onItemPress,
  } = usePostcodeContainer(props);
  return (
    <AddressContainerView
      data={data}
      loadingMore={loadingMore}
      onTextChanged={onTextChanged}
      header={props.header}
      isRefreshing={isRefreshing}
      loading={loading}
      onLoadMore={onLoadMore}
      emptyText={loading ? t('SEARCHING') : t('NO_POSTCODE_FOUND')}
      onRefresh={onRefresh}
      onSearch={onSearch}
      searchText={searchText}
      // descriptionText={t('POSTCODE_DESCRIPTION')}
      onItemPress={onItemPress}
      dispatch ={props.dispatch}
      isFullScreen={false}
      isUpperCase={true}
    />
    // <View />
  );
};

PostcodeContainerModal.propTypes = {
  searchText: PropTypes.string,
  onSelect: PropTypes.func,
  dispatch: PropTypes.any.isRequired,
  header: PropTypes.string,

};

PostcodeContainerModal.defaultProps = {};
