import type { RootState } from '@redux/reducers';
import { createSelector } from '@reduxjs/toolkit';
import { getErrorMessage } from '@utils/api';
import {BundleTransform} from "@utils/transform/bundle";

export const submitBundleSelector = (state: RootState) => state.submitBundle;
export const submitBundleCreator = createSelector(submitBundleSelector, state => state.payload);
export const submitBundleLoading = createSelector(submitBundleSelector, state => state.loading);
export const submitBundleError = createSelector(submitBundleSelector, state => state.error);
export const submitBundleErrorMsg = createSelector(submitBundleError, state => getErrorMessage(state));

export const getListPostingLocationSelector = (state: RootState) => state.getListPostingLocation;
export const getListPostingLocationCreator = createSelector(getListPostingLocationSelector, state => state.payload);
export const getListPostingLocationLoading = createSelector(getListPostingLocationSelector, state => state.loading);
export const getListPostingLocationError = createSelector(getListPostingLocationSelector, state => state.error);
export const getListPostingLocationErrorMsg = createSelector(getListPostingLocationError, state => getErrorMessage(state));
export const getListPostingLocationStandard = createSelector(getListPostingLocationCreator, state => BundleTransform.mappingAddress(state?.data));

export const getListAddressSelector = (state: RootState) => state.getListAddress;
export const getListAddressCreator = createSelector(getListAddressSelector, state => state.payload);
export const getListAddressLoading = createSelector(getListAddressSelector, state => state.loading);
export const getListAddressError = createSelector(getListAddressSelector, state => state.error);
export const getListAddressErrorMsg = createSelector(getListAddressError, state => getErrorMessage(state));

