import { Config } from '@constant/config';
import { ReportBundleMockData } from '@constant/model/mock/report/bundle';
import { useRoute } from '@react-navigation/native';
import { reportModal as reportModalSelector } from '@redux/app/selectors';
import { getD2DFormRequest } from '@redux/form/getD2DFormSlice';
import { getICodeFormRequest } from '@redux/form/getICodeFormSlice';
import { getICodeFormCreator } from '@redux/form/selectors';
import {
  getD2DFormCreator,
  getD2DFormSelector,
  getD2DFormError,
  getD2DFormErrorMsg,
  getD2DFormLoading,
} from '@redux/form/selectors';
import { submitICodeRequest } from '@redux/iCode/submitICodeSlice';
import { saveFeedbackCDSRequest } from '@redux/survey/saveFeedbackCDSSlice';
import { saveFeedbackD2DRequest } from '@redux/survey/saveFeedbackD2DSlice';
import { ReportModalTransform } from '@utils';
import { checkFormValidateExpression, getInitialFormValue, getYupStructure } from '@utils/form';
import { useLocation } from '@utils/hooks';
import { D2DTransform } from '@utils/transform/d2d';
import { mappingStep } from '@utils/transform/form';
import { useFormik } from 'formik';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { lazy } from 'yup';
import * as Yup from 'yup';
import {SuccessMessageMock} from "@constant/model/mock/successMessage";
import {navigate, popToTop} from "@routes/navigationUtils";
import {Screens} from "@routes/route";
import {FormikHelper} from "@utils/hooks/formik";
import {hideLoading} from "@redux/global/globalSlice";

export const useD2DForm = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const { lat, long, loading } = useLocation(props);
  const scrollViewRef = useRef(null);

  const paramsData = useRoute().params?.data;
  const extraData = useRoute().params?.extraData;
  const summary = {
    id: paramsData.id,
  };
  const headerTitle = paramsData?.name;
  const getD2DFormData = useSelector(getD2DFormCreator)?.data;
  const [data, setData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [messageInfo, setMessageInfo] = useState({});

  const [layoutInfo, setLayoutInfo] = useState({});
  const hasDescArr = ['overdue_items', 'due_items'];
  const hasDesc = hasDescArr.includes(paramsData?.key);
  const isEmptyData = _.isNil(extraData?.s_id)
  console.log('useD2DForm>>>', isEmptyData)

  const getD2DFormApi = () => {
    if(_.isNil(extraData?.s_id)){
      return
    }
    const payload = {
      // ...paramsData
      key: extraData?.key ?? paramsData?.key,
      s_id: extraData?.s_id,
    };
    dispatch(getD2DFormRequest(payload));
  };

  useEffect(() => {

    getD2DFormApi();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      dispatch(hideLoading())
    }, 3000)
  }, [])

  const formik = FormikHelper.useFormikWrapper({
    initialValues: getInitialFormValue(data, {}),
    enableReinitialize: true,
    // validationSchema: Yup.object().shape(getYupStructure(rawData, t)),
    validationSchema: () =>
      lazy(values => {
        // console.log('lazy>>>values>>>', values);
        const ys = getYupStructure(data, t, 'attributes', values);
        console.log('getYupStructure>>>', ys, formik, values);
        return Yup.object().shape(ys);
      }),
    initialErrors: false,

    onSubmit: (values, { setErrors, resetForm }) => {
      console.log('onSubmit>>>', values, formik.errors, data);
      const rs = checkFormValidateExpression(data?.attributes, values, setErrors);
      console.log('onSubmit>>>checkFormValidateExpression>>>', rs);
      if (!_.isEmpty(rs)) {
        return;
      }
      handleSubmit(values);
    },
  }, data?.attributes);

  const saveFeedbackD2DApi = async values => {
    const payload = await D2DTransform.getD2DSubmitRequest(values, {
      ...paramsData,
      ...extraData,
    });
    console.log('getD2DSubmitRequest>>>', payload);
    dispatch(
      saveFeedbackD2DRequest(
        payload,
        rs => {
          handleSuccess(rs);
        },
        err => {},
      ),
    );
  };

  const scrollToY = y => {
    // scrollViewRef.current.scroll
    scrollViewRef.current?.scrollTo({
      x: 0,
      y: y,
      animated: true,
    });
  };

  const handleSuccess = rs => {
    //TODO: reload api dashboard here
    // setPoint(rs?.data?.point ?? 0);
    // setIsSuccess(true);
    navigate(Screens.SUCCESSFUL_SCREEN, {
      data: rs?.message,
      header: {
        title: headerTitle,
        backClick: () => {
          popToTop();
        },
      },
    });
    // setMessageInfo(rs?.message)
    // setTimeout(() => {
    //   popToTop();
    // }, 5000);
  };

  const handleSubmit = values => {
    let payload = {
      ...values,
      id: paramsData.id,
    };
    // if (!_.isEmpty(userLocation) && !!userLocation?.longitude) {
    //   payload.user_location = Object.values(userLocation);
    // } else {
    //   payload.user_location = null;
    // }

    const mock = Config.IsMockServer;
    if (mock) {
      handleSuccess();
    } else {
      saveFeedbackD2DApi(payload);
    }
  };

  console.log('useReportPackageForm>>>', formik);
  // useEffect(() => {
  //     getICodeFormApi();
  // }, [dispatch]);

  useEffect(() => {
    const x = _.cloneDeep(getD2DFormData);
    const rd = mappingStep(!_.isEmpty(x) ? x[0] : null);
    setData(rd);
  }, [getD2DFormData]);

  return {
    formik,
    data,
    summary,
    isSuccess,
    headerTitle,
    layoutInfo,
    setLayoutInfo,
    scrollToY,
    scrollViewRef,
    hasDesc,
    messageInfo,
    isEmptyData
  };
};
