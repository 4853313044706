import { AnswerIcon, ArrowBorderIcon, AwaitingIcon, ChevronDownIcon, OverdueIcon, PencilIcon } from '@assets';
import { AppText } from '@components';
import { Constant, SURVEY_STATUS } from '@constant';
import theme, { globalStyle } from '@theme';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import {Font, FontSize, Spacing} from '@theme/size';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TouchableOpacity, Platform, useWindowDimensions } from 'react-native';
import Collapsible from 'react-native-collapsible';
import FontAwesome5, {FA5Style} from "react-native-vector-icons/FontAwesome5";
import {AppHtmlView} from "@screens/main/quiz/QuizFailed/components/AppNote.view";
import {CommentStyle} from "@screens/main/ticket/TicketDetail/components/Comment.style";

export const MapColor = {
  'today' : {
  },
  'answered' : {
    color: '#589F28'
  },
  'awaiting_data' : {
  },
  'unsure_date' : {
  },
  'overdue' : {
    color: '#DA202A'
  },
  'unanswered' : {
    color: '#FF7A00'
  },
}
export const AppAccordion = React.memo(props => {
  const { data, onDayPress, isLast, isExpand, setIsExpand } = props;
  console.log('AppAccordion>>>', props)
  const scaleFont = useWindowDimensions().fontScale;

  const [headerLines, setHeaderLines] = useState(2);

  const { t } = useTranslation();

  useEffect(() => {
    setIsExpand(data.isCurrent ? data?.week : '');
  }, []);

  const handleCollapsed = () => {
    setIsExpand(prev => (data?.week === prev ? '' : data?.week));
  };

  const StatusIcon = ({ status }) => {
    switch (status) {
      case SURVEY_STATUS.Today:
      case SURVEY_STATUS.Awaiting_data:
        return <AwaitingIcon />;
      case SURVEY_STATUS.Answered:
        return <AnswerIcon />;
      case SURVEY_STATUS.Overdue:
        return <OverdueIcon />;
      case SURVEY_STATUS.UnAnswered:
        return <FontAwesome5 name={'times-circle'} color={MapColor[status]?.color} size={11} solid />
      default:
        return null;
    }
  };

  const onTextLayout = useCallback(e => {
    console.log({ 'e.nativeEvent.lines.length': e.nativeEvent.lines.length });
    setHeaderLines(e.nativeEvent.lines.length);
    // setShowMore(e.nativeEvent.lines.length > NUM_OF_LINES);
  }, []);

  return (
    <View style={[styles.container, isLast && { marginBottom: 60 }]}>

      <TouchableOpacity
        activeOpacity={0.7}
        onPress={handleCollapsed}
        style={[styles.accordion, !(isExpand === data?.week) ? {} : styles.isExpanded,
          {
            // height: 42 * headerLines
            // minHeight: 42*2
          }]}>

        <View style={[globalStyle.directionColumm, globalStyle.flex1, {paddingVertical: theme.spacing.md, paddingLeft: theme.spacing.md}]}>
          <AppText style={{ ...Font.Bold_700, fontSize: FontSize.Font15 }}>Week {data?.week}</AppText>
          <AppText
              // onTextLayout={onTextLayout}
              numberOfLines={1}
              style={[{ fontSize: FontSize.Font13, width: '100%', marginTop:2}]}>
            {data?.date}
          </AppText>
          {!!data?.alert && <AppHtmlView text={data?.alert} style={{fontSize: FontSize.Font13, marginTop:2}}/>}
        </View>

        <ChevronDownIcon style={{ transform: isExpand === data?.week ? [{ rotate: '180deg' }] : [] }} />
      </TouchableOpacity>
      <View
          style={[
            {
              top: -8,
              left: 30,
              zIndex: 100,
              transform: [{ rotate: '180deg' }],
              paddingRight: !(isExpand === data?.week) ? 16 : 0,
              // alignItems: 'flex-start',
              alignSelf: 'flex-start',
            },
          ]}>
        {isExpand === data?.week && (
            <ArrowBorderIcon height={14} width={16} strokeWidth={1} fillColor="#E3E3E3" strokeColor="#E3E3E3" />
        )}
      </View>
      <Collapsible style={{ width: '100%' }} collapsed={!(isExpand === data?.week)}>
        {data?.days?.map((day, index) => {
          const idBold = day?.status_key === SURVEY_STATUS.Today;
          const color = MapColor[day?.status_key]?.color
          // const isAnswer = day?.status_key === SURVEY_STATUS.Answered;
          // const isWaiting = day?.status_key === SURVEY_STATUS.Awaiting_data;
          // const isOverdue = day?.status_key === SURVEY_STATUS.Overdue;
          if (day?.status === SURVEY_STATUS.Unsure_date)
            return (
              <TouchableOpacity
                key={index}
                activeOpacity={0.7}
                onPress={() => onDayPress(day)}
                style={[styles.accordionItem, { backgroundColor: '#98C9F5', flexDirection: 'row', }]}>
                <View style={[globalStyle.directionColumm, {flex:1}]}>
                  <AppText style={[styles.title, { ...Font.Bold_700}]}>
                    {t('UNSURE_DATE')}
                  </AppText>
                  <AppText style={[styles.title, {fontSize: FontSize.Font13, marginTop:2}]}>
                    ({day?.total} {day?.total > 1 ? t('REPORTS') : t('REPORT')})
                  </AppText>
                </View>

              </TouchableOpacity>
            );
          else
            return (
              <TouchableOpacity
                activeOpacity={0.7}
                onPress={() => onDayPress(day)}
                style={[
                  // styles.accordionItem,
                  {
                    flexDirection: 'row',
                    backgroundColor: '#F5F5F5',
                    alignItems: 'center',
                    marginTop: index > 0 ? theme.spacing.md : 0,
                  },
                ]}>
                <View
                  style={[
                    styles.accordionItemChild,
                    // scaleFont > 1.2 && {
                    //   flexWrap: 'wrap',
                    //   flexDirection: 'column',
                    // },
                  ]}>
                  {/*<AppText style={[styles.title, { width: scaleFont > 1.2 ? undefined : 40 }]}>{day?.day}</AppText>*/}
                  {/*<AppText style={[styles.date, scaleFont > 1.2 && { marginLeft: 0 }]}>{`-  ${day?.date}`}</AppText>*/}
                  <View style={[globalStyle.directionColumm]}>
                    <AppText style={[styles.title]}>{day?.day} - {day?.date}</AppText>

                    <AppText
                        style={[
                          styles.title,
                          idBold ? { ...Font.Bold_700 } : {},
                          // isAnswer && { color: '#589F28' },
                          // isOverdue && { color: '#DA202A' },
                            !!color &&  { color: color },
                          {fontSize: FontSize.Font13}
                        ]}>
                      {day?.status} <StatusIcon status={day?.status_key} />
                    </AppText>
                  </View>

                </View>

                {Platform.OS === 'web' ? <View style={[styles.editViewWeb]}>
                  <PencilIcon color={!!day?.editId ? '#666666' : '#66666638'} />
                </View> :
                    // <View style={[styles.editContainer]}>
                  <View style={[styles.editView]}>
                    <PencilIcon color={!!day?.editId ? '#666666' : '#66666638'} />
                  </View>
                // </View>
                }
              </TouchableOpacity>
            );
        })}
      </Collapsible>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    width: '100%',
    borderRadius: 3,
    alignItems: Platform.OS === 'web' ? '' : 'center',
    // backgroundColor: '#E3E3E3',
    marginBottom: 10,
  },
  editView: {
    // backgroundColor: 'white',
    borderRadius: 3,
    margin: 2,
    // flex: 1,
    maxHeight: 40,
    height: '100%',
    minWidth:40,
    // aspectRatio: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  editViewWeb: {
    // backgroundColor: 'white',
    borderRadius: 3,
    margin: 2,
    marginLeft:theme.spacing.sm,
    // flex: 1,
    maxHeight: 40,
    height: '100%',
    // maxWidth:40,
    aspectRatio: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  editContainer: {
    marginLeft: theme.spacing.sm,
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',

  },
  accordionWrap: {
    borderRadius: 3,
    width: '100%',
  },
  isExpanded: {
    // backgroundColor: '#F9ECEC',
  },
  accordion: {
    width: '100%',
    marginVertical: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlignVertical: 'center',
    // height: 42, //42
    paddingRight: Device.isAndroid ? 12 : 15,
    borderRadius: 3,
    backgroundColor: '#E3E3E3',
  },
  accordionItem: {
    flexDirection: 'row',
    backgroundColor: '#FFDB80',
    width: '100%',
    paddingVertical: 12,
    paddingLeft: 15,
    // width: 100,
    borderRadius: 3,
    marginTop: 15,
  },
  accordionItemChild: {
    flexDirection: 'row',
    // flexWrap: 'wrap',
    // width: '100%',
    flex: 1,
    paddingVertical: 12,
    paddingLeft: 15,
    // width: 100,
    borderRadius: 3,
    // marginTop: 15,
  },
  date: {
    flex: 1,
    marginLeft: Spacing.width35,
  },
  content: {
    paddingHorizontal: 16,
  },
  contentText: {
    paddingVertical: 15,
  },
});
