import { Constant } from '@constant';
import { palette } from '@theme/colors';
import { Font, FontSize, Spacing } from '@theme/size';
import { StyleSheet, Platform } from 'react-native';

export const styles = StyleSheet.create({
  areaStyle: { backgroundColor: '#EDEDED', flex: 1 },
  container: {
    paddingHorizontal: Spacing.width15,
    flex: 1,
    borderTopRightRadius: Spacing.width20,
    borderTopLeftRadius: Spacing.width20,
    backgroundColor: palette.WHITE,
    alignItems: 'center',
  },
  wrapper: {
    justifyContent: 'center',
    flex: 1,
  },
  qrCode: {
    // width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: Spacing.height35,
  },
  qrCodeText: {
    // flex: 1,
    color: '#666666',
    // marginRight: Spacing.width8,
    fontSize: FontSize.Font15,
    textAlign: 'center',
  },
  button: {
    marginBottom: Platform.OS === 'web' ? '20px' : Spacing.height25,
  },
});
