import { createAction, createSlice } from '@reduxjs/toolkit';
import {FormGroupHelper} from "@utils/form/group";
import {getLogoutSuccess} from "@redux/auth/authSlice";

export interface IgetProfileState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IgetProfileState = {
  loading: false,
  payload: null,
  error: null,
};

export const getProfileRequest = createAction('getProfile/getProfileRequest', (payload, onSuccess, onFailed) => {
  console.log('prepareAction>>>', payload, onSuccess);
  return {
    payload: payload,
    meta: onSuccess,
    error: onFailed,
  };
});

const getProfileSlice = createSlice({
  name: 'getProfile',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // getProfile(state, action) {
    //   state.loading = true;
    //   // @ts-ignore
    //   // state.payload = null;
    //   state.error = null;
    // },
    getProfileSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      // const data = action.payload?.message?.data
      // state.payload.message.data = FormGroupHelper.getTestGroupData(data)
      state.error = null;
    },
    getProfileFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getLogoutSuccess, (state, action) => {
      // action is inferred correctly here if using TS
      console.log('getLogoutSuccess>>addCase>>>', state)
      return initialState
    });
  },
});

export const { getProfileSuccess, getProfileFailed } = getProfileSlice.actions;

export default getProfileSlice.reducer;
