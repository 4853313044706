import { createAction, createSlice } from '@reduxjs/toolkit';

export interface IupdateProfileState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IupdateProfileState = {
  loading: false,
  payload: null,
  error: null,
};

export const updateProfileRequest = createAction(
  'updateProfile/updateProfileRequest',
  (payload, onSuccess, onFailed) => {
    console.log('prepareAction>>>', payload, onSuccess);
    return {
      payload: payload,
      meta: onSuccess,
      error: onFailed,
    };
  },
);

const updateProfileSlice = createSlice({
  name: 'updateProfile',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // updateProfileRequest(state, action) {
    //   state.loading = true;
    //   // @ts-ignore
    //   state.payload = null;
    //   state.error = null;
    // },
    updateProfileSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    updateProfileFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  // updateProfileRequest,
  updateProfileSuccess,
  updateProfileFailed,
} = updateProfileSlice.actions;

export default updateProfileSlice.reducer;
