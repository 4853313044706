import { createAction, createSlice } from '@reduxjs/toolkit';

export interface IunsubscribeUserState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IunsubscribeUserState = {
  loading: false,
  payload: null,
  error: null,
};
export const unsubscribeUserRequest = createAction(
  'unsubscribeUser/unsubscribeUserRequest',
  (payload, onSuccess, onFailed) => {
    console.log('prepareAction>>>', payload, onSuccess);
    return {
      payload: payload,
      meta: onSuccess,
      error: onFailed,
    };
  },
);

const unsubscribeUserSlice = createSlice({
  name: 'unsubscribeUser',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // unsubscribeUserRequest(state, action) {
    //   state.loading = true;
    //   // @ts-ignore
    //   state.payload = null;
    //   state.error = null;
    // },
    unsubscribeUserSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    unsubscribeUserFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { unsubscribeUserSuccess, unsubscribeUserFailed } = unsubscribeUserSlice.actions;

export default unsubscribeUserSlice.reducer;
