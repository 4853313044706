import { getHolidayFormService } from '@services/form'

// @ts-ignore
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { getHolidayFormFailed, getHolidayFormSuccess } from './getHolidayFormSlice';
import { Constant } from "@constant";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* getHolidayFormSaga({ payload }): Generator<
  | CallEffect
  | PutEffect<{
      payload: any;
      type: string;
    }>,
  void
> {
  try {
    const res = yield call(getHolidayFormService, payload);
    console.log('getHolidayFormSaga>>>>', res);
    if (res?.status) {
      yield put(getHolidayFormSuccess(res));
    } else {
      // @ts-ignore
      yield put(getHolidayFormFailed(res));
    }
  } catch (err) {
    // @ts-ignore
    yield put(getHolidayFormFailed(err));
  }
}
