import {useDispatch, useSelector} from 'react-redux'
import {useEffect, useRef} from 'react'
import {navigate} from "@routes/navigationUtils";
import {Screens} from "@routes/route";
import {useRoute} from "@react-navigation/native";
import {getListUnsureDateCreator} from "@redux/survey/selectors";
import {getListUnsureDateRequest} from "@redux/survey/getListUnsureDateSlice";

export const useUnsureDateList = navigation => {
    const dispatch = useDispatch()
    const refData = useRef()
    const paramData = useRoute().params?.data

    if(!!paramData){
        refData.current = paramData
    }

    const data = refData.current
    const listUnsured = useSelector(getListUnsureDateCreator)
    const listData = listUnsured?.data ?? []

    console.log('useUnsureDateList>>>', data, listData, listUnsured)
    const onAddNew = () => {
        navigate(Screens.CDS_FORM_SCREEN, { data: data });
    }

    const onEdit = item => {
        console.log('onEdit>>>', item, data)
        if(!item?.clickable){
            return
        }
        navigate(Screens.CDS_DETAIL_SCREEN, { data: {
            ...data,
            ...item,
            } });

    }
    const getListUnsureDateApi = () => {
        /*
      params:
      {
          "week": "2023-12",
          "spectos-apikey": "{{spectos-apikey}}",
          "Token": "c2706a43bb0513200738c3dd2470305f2e637a4cd3b6734ab105eebd1fa36e9c"
      }
      body:
      {}
       */
        const payload = {
            'week': data?.week
        }
        dispatch(getListUnsureDateRequest(payload))
    }

    useEffect(() => {
        getListUnsureDateApi()
    }, [paramData])


    useEffect(() => {
    }, [dispatch])
    return { onAddNew, onEdit, listData, listUnsured}
}
