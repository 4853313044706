import { createSlice } from '@reduxjs/toolkit';

export interface IgetHolidayFormState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IgetHolidayFormState = {
  loading: false,
  payload: null,
  error: null,
};

const getHolidayFormSlice = createSlice({
  name: 'getHolidayForm',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getHolidayFormRequest(state, action) {
      state.loading = true;
      // @ts-ignore
      state.payload = null;
      state.error = null;
    },
    getHolidayFormSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    getHolidayFormFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getHolidayFormRequest, getHolidayFormSuccess, getHolidayFormFailed } = getHolidayFormSlice.actions;

export default getHolidayFormSlice.reducer;
