import { FORM } from '@constant/model/RegisterForm';
import {
  checkFormValidateExpression,
  getInitialFormValue,
  getInitialFormValueOrigin,
  getYupStructure,
  getYupStructureOrigin,
} from '@utils/form';
import { checkEvalCondition, mappingStep, mappingStepOrigin } from '@utils/transform/form';
import { useFormik } from 'formik';
import _ from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { lazy } from 'yup';
import * as Yup from 'yup';

export const SubForm = [
  {
    internalId: 'nearest_address',
    type: 'nearest_address',
    editable: true,
    required: false,
    titlePopup: 'Select address',
    descriptionPopup: 'Not in the list? Tap <a href="nearest_address"> here</a>.',
    title: '',
    condition: {
      react: 'return !!{{enable_nearest_address}}',
    },
  },
  {
    internalId: 'search_postcode',
    type: 'bundle_postcode',
    editable: true,
    titlePopup: 'Select postcode or postbox ID',
    placeHolder: 'Enter postcode or postbox ID',
    descriptionPopup: 'Not in the list? Tap <a> here</a> to search by postcode.',
    required: true,
    minSearch: 3,
    errors: {
      required: 'This field is required',
      validation: 'Please enter a valid postcode or postbox ID',
      minSearch: 'Please enter at least 3 characters',
    },
    condition: {
      react: 'return nearest_address === "";',
    },
    validation: {
      type: 'javascript',
      javascript: 'return (!!list_post_position && list_post_position.length>0) || (!!reset_bundle_address);',
    },
    title: '',
  },
  {
    name: '<span style="color: #999999;">The posting point has to be in your postcode area. The first 1 or 2 letters of the postbox number will indicate the postcode area (e.g. AB10 1).</span>',
    type: 'note',
    popup: false,
    title:
      '<span style="color: #999999;">The posting point has to be in your postcode area. The first 1 or 2 letters of the postbox number will indicate the postcode area (e.g. AB10 1).</span>',
  },
  {
    internalId: 'list_post_position',
    name: 'list_post_position',
    type: 'list_post_position',
    popup: false,
  },
  {
    internalId: 'search_address',
    type: 'bundle_address',
    editable: true,
    titlePopup: 'Select address',
    descriptionPopup: 'Not in the list? Tap <a href="search_address">here</a>.',
    required: true,
    errors: {
      required: 'This field is required',
    },
    condition: {
      react: 'return !nearest_address && !!search_postcode && !!list_post_position && list_post_position.length>0;',
    },
    title: '',
  },
  {
    internalId: 'emptying_time',
    type: 'emptying_time',
    editable: false,
    condition: {
      react: 'return !!nearest_address || !!search_address;',
    },
    title: '',
  },
];
export const useDispatchPoint = props => {
  const dispatch = useDispatch();
  const t = useTranslation();
  const data = mappingStepOrigin(props.subForm ?? []);
  // const data = mappingStepOrigin(SubForm);
  const control = props?.formData?.find(x => x.type === FORM.FORM_TYPE.dispatch_point)
  const hideSubForm = control?.hideSubForm

  const isHide = checkEvalCondition(hideSubForm?.react, props?.formik?.values)
  const formik = useFormik({
    initialValues: getInitialFormValueOrigin(data, {
      ...props?.defaultValue
    }),
    // enableReinitialize: true,
    // validationSchema: Yup.object().shape(getYupStructure(rawData, t)),
    validationSchema: () =>
      lazy(values => {
        // console.log('lazy>>>values>>>', values);
        const ys = getYupStructureOrigin(data, t, false, values);
        console.log('getYupStructure>>>useDispatchPoint>>>', ys, formik, values);
        return Yup.object().shape(ys);
      }),
    initialErrors: false,
    onSubmit: (values, { setErrors, resetForm }) => {
      console.log('onSubmit>>>111<>>>', values, formik.errors, data);
      const rs = checkFormValidateExpression(data?.attributes, values, setErrors);
      if (!_.isEmpty(rs)) {
        return;
      }
      // handleSubmit(values)
      //TODO: validate here
      // updateUserApi(values, isLastStep);
      // onSavePress();
    },
  });
  // const formik = props.formik;
  if (props.formikRef) {
    //TODO: apply hideSubForm
    // if(props.selected !== FORM.FORM_ELEMENT_NAME.collection){
    if(!isHide){
      props.formikRef.current = formik;
    }
    else{
      props.formikRef.current = null;
    }
  }
  console.log('useDispatchPoint>>>', props, formik);

  useEffect(() => {}, [dispatch]);
  return { formik, data, isHide };
};
