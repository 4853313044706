import { Constant } from '@constant';
import theme from '@theme';
import { FontSize } from '@theme/size';
import {Platform, StyleSheet} from 'react-native';

export const SearchAddressStyle = StyleSheet.create({
  descriptionView: {
    color: '#333333',
    textAlign: 'center',
    // marginBottom: theme.spacing.md,
  },
  searchContainer: {
    borderWidth: 1,
    borderColor: '#C6C6C6',
    borderRadius: 4,
    marginHorizontal: theme.spacing.md,
    marginTop: theme.spacing.md,

    alignItems: 'center',
    textAlignVertical: 'center',
    justifyContent: 'space-between',
  },
  searchView: {
    // borderWidth: 1,
    // borderColor: '#C6C6C6',
    fontSize: FontSize.Font15,
    fontWeight: '400',
    paddingLeft: theme.spacing.sm,
    flex: 1,
    color: '#333333',
    minHeight: Constant.MAIN_HEIGHT,
    ...Platform.select({
      web:{
        outlineStyle: 'none'
      }
    })
    // borderRadius: 4,
  },
  listView: {
    marginVertical: 6,
    paddingHorizontal: 16,
    flex: 1,
    // overflow: "hidden"
    // padding: theme.spacing.md,
    // height: 55 * 6,
    // flexGrow: 0,
    // paddingLeft: Dimens.Margin.Normal,
    // paddingRight: Dimens.Margin.Normal,
  },
});
