import { ClearIcon, LogoIcon, MessageCheckIcon } from '@assets';
import { AppEmptyView, AppKAView, AppKAViewV2, AppSafeAreaView, AppText } from '@components';
import GenericHeader from '@components/GenericHeader';
import { ADDRESS_TYPE } from '@constant';
import { goBack } from '@routes/navigationUtils';
import { SearchAddressStyle } from '@screens/main/SearchAddress/SearchAddress.style';
import { AddressBundleItem, AddressItem, AppLoadingMore } from '@screens/main/SearchPostcode';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import { AppHtmlRadioStyle } from '@screens/register/RegisterForm/components/AppHtmlRadio.style';
import theme, {globalStyle, spacing, WEB_WIDTH} from '@theme';
import { palette } from '@theme/colors';
import {deviceWidth, Font} from '@theme/size';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {View, Text, StatusBar, TextInput, TouchableOpacity, FlatList, Platform} from 'react-native';
import { Portal } from 'react-native-portalize';
import RenderHtml from 'react-native-render-html';
import { useAddressContainer } from './AddressContainer.hook';
import { AddressContainerStyle } from './AddressContainer.style';
import {Tooltip} from "react-native-elements";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";

export const AddressContainerView = props => {
  const { t } = useTranslation();
  const {} = useAddressContainer(props);
  const {
    onSearch,
    data,
    onRefresh,
    isRefreshing,
    loadingMore,
    loading,
    onLoadMore,
    onTextChanged,
    searchText,
    descriptionText,
    headerText,
    onItemPress,
    onItemFavouritePress,
    summary,
  } = props;
  const widthScreen = Platform.OS === 'web' ? WEB_WIDTH : deviceWidth;
  const renderItem = ({ item, index }) => {
    switch (props.type) {
      case ADDRESS_TYPE.normal:
        return (
          <AddressItem
            data={item}
            isLast={index === data.length - 1}
            isSelected={false}
            onPress={() => onItemPress(item)}
          />
        );
      case ADDRESS_TYPE.bundle:
      case ADDRESS_TYPE.nearest:
        return (
          <AddressBundleItem
            data={item}
            isLast={index === data.length - 1}
            isSelected={false}
            onPress={() => onItemPress(item)}
            onFavouritePress={() => onItemFavouritePress(item)}
          />
        );
      default:
        return (
          <AddressItem
            data={item}
            isLast={index === data.length - 1}
            isSelected={false}
            onPress={() => onItemPress(item)}
          />
        );
    }
  };

  const renderFooterLoading = () => {
    return <AppLoadingMore loadingMore={loadingMore} />;
  };

  const renderEmpty = () => {
    return <AppEmptyView emptyText={props.emptyText} />;
  };

  const renderMainContent = () => {
    const styles = props.isFullScreen
      ? [globalStyle.directionColumm, globalStyle.flex1, RegisterFormStyles.containerView]
      : [globalStyle.directionColumm, { height: '100%' }];
    return (
      <View style={styles}>
        {!!descriptionText && (
          <RenderHtml
            defaultTextProps={{ allowFontScaling: true }}
            source={{ html: descriptionText }}
            baseStyle={AddressContainerStyle.textNormal}
            renderersProps={{
              a: {
                onPress: (evt, href, htmlAttribs, target) => {
                  console.log('a>>>press>>>', href);
                  if (props.onClickHref) {
                    props.onClickHref(href);
                  }
                },
              },
            }}
          />
        )}
        <Tooltip
          withPointer={false}
          width={widthScreen}
          containerStyle={{
            left: 0,
            borderColor: '#C6C6C6',
            borderWidth: 1,
            backgroundColor: '#FFFFFF',
            marginTop: theme.spacing.md,
            marginLeft: 32 + (deviceWidth - widthScreen) / 2,
            width: widthScreen - 32 * 2,
            height: Platform.OS === 'web' ? undefined : 150,
            // elevation:10,
            shadowColor: '#000000',
            shadowOpacity: 0.6,
            shadowRadius: 3,
            shadowOffset: {
              height: 1,
              width: 1,
            },
          }}
          popover={
            <AppText>
              {t('BUNDLE_FAVOURITE_DESCRIPTION')}
            </AppText>
          }
        >
          <View style={[{
                marginHorizontal: theme.spacing.md,
            // borderColor: '#C6C6C6',
            // borderWidth: 1,
            // backgroundColor: '#FFFFFF',
            // marginTop: theme.spacing.md,
            // // marginLeft: -34,
            // width: deviceWidth - 32 * 2,
            // height: 150,
            // // elevation:10,
            // shadowColor: '#000000',
            // shadowOpacity: 0.6,
            // shadowRadius: 3,
            // shadowOffset: {
            //   height: 1,
            //   width: 1,
            // },
          }]}>
            <AppText>{t('BUNDLE_FAVOURITE_TITLE_1')} <FontAwesome5 name={'star'} size={24}/> {t('BUNDLE_FAVOURITE_TITLE_2')}</AppText>
          </View>

        </Tooltip>
        {props.showSearch && (
          <View style={[globalStyle.directionRow, SearchAddressStyle.searchContainer]}>
            <TextInput
              value={searchText}
              style={[SearchAddressStyle.searchView]}
              onChangeText={txt => onTextChanged(txt)}
              placeholder={props?.placeHolder}
              allowFontScaling={true}
              autoCapitalize={props.isUpperCase ? 'characters' : ''}
            />
            {loading && <AppLoadingMore loadingMore={loading} />}
            <TouchableOpacity
              onPress={() => {
                onTextChanged('');
              }}
              style={{ marginRight: 12 }}>
              {<ClearIcon />}
            </TouchableOpacity>
          </View>
        )}
        <FlatList
          style={[SearchAddressStyle.listView]}
          // contentContainerStyle={[SearchAddressStyle.listView]}
          data={data}
          renderItem={renderItem}
          onRefresh={() => onRefresh()}
          refreshing={isRefreshing}
          ListEmptyComponent={renderEmpty}
          // onEndReached={!loading ? onLoadMore : null}
          onEndReachedThreshold={0.5}
          keyExtractor={(item, index) => index}
          ListFooterComponent={renderFooterLoading}
        />
      </View>
    );
  };

  return props.isFullScreen ? (
    <>
      <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
      <AppSafeAreaView areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>
        <GenericHeader onBackClicked={goBack} title={headerText} style={[{ backgroundColor: '#EDEDED' }]} />
        {renderMainContent()}
      </AppSafeAreaView>
    </>
  ) : (
    <AppSafeAreaView>
      <AppKAViewV2 areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>{renderMainContent()}</AppKAViewV2>
    </AppSafeAreaView>
  );
};

AddressContainerView.propTypes = {
  onSearch: PropTypes.func,
  data: PropTypes.array,
  onRefresh: PropTypes.func,
  isRefreshing: PropTypes.bool,
  loadingMore: PropTypes.bool,
  loading: PropTypes.bool,
  onLoadMore: PropTypes.func,
  onTextChanged: PropTypes.func,
  searchText: PropTypes.string,
  headerText: PropTypes.string,
  descriptionText: PropTypes.string,
  emptyText: PropTypes.string,
  onItemPress: PropTypes.func,
  dispatch: PropTypes.any,
  isFullScreen: PropTypes.bool,
  postCode: PropTypes.string,
  onClickHref: PropTypes.func,
  type: PropTypes.string,
  showSearch: PropTypes.bool,
  placeHolder: PropTypes.string,
  isUpperCase: PropTypes.bool,
  // onSelect:
};

AddressContainerView.defaultProps = {
  emptyText: 'No Data',
  isFullScreen: true,
  type: ADDRESS_TYPE.normal,
  showSearch: true,
  placeHolder: '',
  isUpperCase: false,
};
