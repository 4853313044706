import { AppButton, AppSafeAreaView, AppText } from '@components';
import { useAppReviewForm } from '@components/AppReviewForm/hook';
import { AppSuccessScreen } from '@components/AppSuccessScreen';
import { FormItemView } from '@components/Form';
import { FormItemReview } from '@components/FormItemReview';
import GenericHeader from '@components/GenericHeader';
import { UPLOAD_FILE } from '@constant';
import { goBack, popToTop } from '@routes/navigationUtils';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import theme, { globalStyle } from '@theme';
import {Font, FontSize, Spacing} from '@theme/size';
import { AppSetting } from '@utils/common/setting';
import { RenderHelper } from '@utils/form/render';
import { FormTransform, getContentTitle } from '@utils/transform/form';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

export const AppReviewForm = props => {
  const { isSuccess, title, banner, folderUpload } = props;
  const { data, values } = useAppReviewForm(props);
  const attributes = data?.attributes ?? [];
  console.log('AppReviewForm>>>attributes>>>', attributes);

  const { t } = useTranslation();
  const renderForm = () => {
    // return <View />;
    return (
      <>
        <KeyboardAwareScrollView
          // innerRef={ref => {
          //     if (ref) {
          //         scrollViewRef.current = ref;
          //     }
          // }}
          style={[]}
          contentContainerStyle={{ flexGrow: 1 }}
          showsVerticalScrollIndicator={false}
          keyboardShouldPersistTaps="handled">
          <AppText
            style={[
              globalStyle.alignCenter,
              globalStyle.justifyCenter,
              RegisterFormStyles.paddingSpace,
              RegisterFormStyles.descriptionText,
              { marginVertical: banner.description ? theme.spacing.md : 0, ...Font.Bold_700 },
            ]}>
            {banner.description}
          </AppText>
          <View style={[{ borderWidth: 1, borderColor: '#C6C6C6', marginHorizontal: theme.spacing.md }]} />
          <View
            style={[
              globalStyle.directionColumm,
              RegisterFormStyles.paddingSpace,
              globalStyle.flex1,
              // ...props.itemStyle,
              { marginVertical: theme.spacing.sm },
            ]}>
            {attributes?.map((item, index) => {
              return (
                <FormItemReview
                  key={`${item?.internalId}_${index}`}
                  index={index}
                  item={item}
                  // isBoldTitle={true}
                  checkCondition={true}
                  values={values}
                  style={[{ marginBottom: theme.spacing.sm }]}
                  formData={attributes}
                  // //
                  // subFormRef={props.subFormRef}
                  // scrollViewRef={props.scrollViewRef}
                  folderUpload={folderUpload}
                />
              );
            })}
          </View>
          <View
            style={[
              RegisterFormStyles.paddingSpace,
              { marginVertical: theme.spacing.sm, marginBottom: Spacing.height25 },
            ]}>
            <AppButton
              text={t('REPORT')}
              onPress={() => {
                // await FormTransform.submitAndScroll(formik, attributes, layoutInfo, scrollToY);
                if (props.onSubmitPress) props.onSubmitPress();
              }}
            />
          </View>
        </KeyboardAwareScrollView>
      </>
    );
  };

  return (
    <AppSafeAreaView areaStyle={[{ backgroundColor: AppSetting.state?.colors?.BACKGROUND_GREY }]}>
      <GenericHeader
        onBackClicked={() => {
          if (props.onBackClicked) {
            props.onBackClicked();
          } else {
            if (isSuccess) {
              popToTop();
            } else {
              goBack();
            }
          }
        }}
        title={getContentTitle(title)}
        style={[{ backgroundColor: AppSetting.state?.colors?.BACKGROUND_GREY }]}
      />
      <View style={[globalStyle.directionColumm, globalStyle.flex1, RegisterFormStyles.containerView]}>
        {renderForm()}
      </View>
    </AppSafeAreaView>
  );
};

AppReviewForm.propTypes = {
  formik: PropTypes.any,
  isSuccess: PropTypes.bool,
  title: PropTypes.string,
  onBackClicked: PropTypes.func,
  itemStyle: PropTypes.array,
  banner: PropTypes.any,
  folderUpload: PropTypes.string,
  onSubmitPress: PropTypes.func,
};

AppReviewForm.defaultProps = {
  itemStyle: [],
  folderUpload: '',
};
