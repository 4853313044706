import { submitBundleSaga } from '@redux/bundle/submitBundleSaga';
import { submitBundleRequest } from '@redux/bundle/submitBundleSlice';
import { getListPostingLocationSaga } from '@redux/bundle/getListPostingLocationSaga';
import { getListPostingLocationRequest } from '@redux/bundle/getListPostingLocationSlice';
import { getListAddressSaga } from '@redux/bundle/getListAddressSaga';
import { getListAddressRequest } from '@redux/bundle/getListAddressSlice';
import { ForkEffect, takeLatest } from 'redux-saga/effects';

function* bundleSagas(): Generator<ForkEffect<never>, void> {
  yield takeLatest(submitBundleRequest.type, submitBundleSaga);
  yield takeLatest(getListPostingLocationRequest.type, getListPostingLocationSaga);
  yield takeLatest(getListAddressRequest.type, getListAddressSaga);
}

export default bundleSagas;
