import { deleteHolidayRequest } from '@redux/holiday/deleteHolidaySlice';
import { getListHolidaysRequest } from '@redux/holiday/getListHolidaysSlice';
import {
  getListHolidaysCreator,
  getListHolidaysSelector,
  getListHolidaysError,
  getListHolidaysErrorMsg,
  getListHolidaysLoading,
  getListHolidaysStandard,
} from '@redux/holiday/selectors';
import { unsubscribeUserRequest } from '@redux/user/unsubscribeUserSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useListReport = navigation => {
  const dispatch = useDispatch();
  const [currentScreen, setCurrentScreen] = useState(0);
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [processingReset, setProcessingReset] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const onNoModalPress = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  const onYesModalPress = () => {
    deleteHolidayApi(selectedItem?.id);
    setShowModal(false);

  };
  // const holidays = useSelector(state => state.accounts.holidays);
  // const user = useSelector(state => state.accounts.user);
  const getListHolidaysData = useSelector(getListHolidaysStandard);
  // const getListHolidaysWithLoading = useSelector(getListHolidaysLoading)
  const user = {};
  const holidays = getListHolidaysData;
  const onButtonSavePressed = () => {};
  const onButtonResetPressed = () => {};
  const onDeleteItemPressed = item => {
    // deleteHolidayApi(item?.id);
    setSelectedItem(item)
    setShowModal(true)
  };
  const onRefresh = () => {
    getListHolidaysApi();
  };
  const showHistories = true;
  useEffect(() => {}, [dispatch]);
  const getListHolidaysApi = () => {
    /*
  payload_info
   */
    const payload = {};
    dispatch(getListHolidaysRequest(payload));
  };

  const deleteHolidayApi = id => {
    const payload = { id };
    dispatch(
      deleteHolidayRequest(
        payload,
        rs => {
          getListHolidaysApi();
        },
        err => {},
      ),
    );
  };

  useEffect(() => {
    getListHolidaysApi();
  }, []);
  return {
    currentScreen,
    loading,
    processing,
    startDate,
    endDate,
    onButtonSavePressed,
    onButtonResetPressed,
    onDeleteItemPressed,
    setEndDate,
    setStartDate,
    holidays,
    onRefresh,
    showHistories,
    processingReset,
    user,
    showModal,
    onNoModalPress,
    onYesModalPress,
  };
};
