import { createSlice } from '@reduxjs/toolkit';

export interface IgetOverviewD2DState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IgetOverviewD2DState = {
  loading: false,
  payload: null,
  error: null,
};

const getOverviewD2DSlice = createSlice({
  name: 'getOverviewD2D',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getOverviewD2DRequest(state, action) {
      state.loading = true;
      // @ts-ignore
      state.payload = null;
      state.error = null;
    },
    getOverviewD2DSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    getOverviewD2DFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getOverviewD2DRequest, getOverviewD2DSuccess, getOverviewD2DFailed } = getOverviewD2DSlice.actions;

export default getOverviewD2DSlice.reducer;
