import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { Font, FontSize, Spacing } from '@theme/size';
import { StyleSheet, Platform } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: Platform.OS === 'web' ? '' : 'center',
    backgroundColor: palette.WHITE,
    borderColor: '#DFDFDF',
    borderWidth: 1,
    borderRadius: Spacing.width8,
    // padding: 1,
    // minHeight: Spacing.height72,
    flex: 1,
    marginBottom: Platform.OS === 'web' ? '' : Spacing.height15,
  },
  content: {
    flexDirection: 'row',
    alignItems: Platform.OS === 'web' ? '' : 'center',
    backgroundColor: palette.WHITE,
    borderColor: '#DFDFDF',
    borderWidth: 1,
    borderRadius: Spacing.width8,
    marginHorizontal: '17px',
    marginBottom: '17px',
  },

  left: {
    margin: 1,
    zIndex: 1,
  },
  leftContainer: {
    backgroundColor: '#e7e7e7',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: Spacing.width8,
    borderBottomLeftRadius: Spacing.width8,
    paddingHorizontal: Spacing.width18,
    height: '100%',
    marginVertical: Platform.OS === 'web' ? '' : 10,
  },
  iconDashboard: {
    width: Spacing.width24,
    height: Spacing.width24,
  },

  right: {
    backgroundColor: '#f5f5f5',
    flex: 1,
    justifyContent: 'center',
    height: '100%',
    borderTopRightRadius: Spacing.width8,
    borderBottomRightRadius: Spacing.width8,
  },
  nameWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: Platform.OS === 'web' ? undefined : Spacing.height40,
    paddingHorizontal: Spacing.width14,
  },
  name: {
    ...Font.Bold_400,
    fontSize: FontSize.Font15,
    color: '#333333',
    // marginBottom: Platform.OS === 'web' ? '' : Spacing.height4,
    flex: 1,
    width: '100%',
  },
  count: {
    ...Font.Bold_700,
    // flex: 1,
    marginLeft: Spacing.width8,
  },
  desc: {
    ...Font.Bold_700,
    fontSize: FontSize.Font20,
    color: '#333333',
    paddingHorizontal: Spacing.width14,
    paddingVertical: Spacing.width6,
  },
  descHtml: {
    fontSize: FontSize.Font15,
    color: '#333333',
    paddingHorizontal: Spacing.width14,
    paddingVertical: Spacing.width6,
  },
  date: {
    ...Font.Bold_700,
    fontSize: FontSize.Font15,
    color: '#333333',
    paddingVertical: Spacing.width6,
  },
  list: {
    marginTop: Platform.OS === 'web' ? '' : Spacing.height6,
    flex: 1,
    paddingBottom: Spacing.width6,
  },
  itemWrap: {
    flexDirection: 'row',
    // alignItems: 'center',
    marginTop: Spacing.height4,
    flex: 1,
    flexWrap: 'wrap',
    paddingHorizontal: Spacing.width14,
  },
  item: {
    paddingLeft: Spacing.width9,
    marginTop: Device.isIos ? 2.5 : 0,
  },
});
