import {ChevronPickerIcon, LongArrowIcon, QRIcon} from '@assets';
import { AppModal, AppText, AppSingleSelection } from '@components';
import { useAppPicker } from '@components/AppPicker/AppPicker.hook';
import { Constant } from '@constant';
import { AppHtmlView } from '@screens/main/quiz/QuizFailed/components/AppNote.view';
import { UnsubscribeStyle } from '@screens/main/unsub/Unsubscribe/Unsubscribe.style';
import theme, { globalStyle } from '@theme';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { FontSize } from '@theme/size';
import { checkRadioId } from '@utils/form';
import { FormTransform } from '@utils/transform/form';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {KeyboardAvoidingView, Platform, ScrollView, StyleSheet, TextInput, TouchableOpacity, View} from 'react-native';
import RenderHtml from 'react-native-render-html';
import _ from 'lodash'
import {KeyboardHelper, ValueType} from "@utils/common/keyboard";
import {StringHelper} from "@utils/common/string";

const VERTICAL_HEIGHT = 15;
const CONTENT_SIZE = 275.3333435058594;
const ITEM_HEIGHT = 44;
const HEADER_HEIGHT = 50;

/**
 * - Data must be object and include ID and CONTENT
 * - Selected must be ID
 * - Selected is also default data
 */
export const AppPicker = props => {
  const {
    selected,
    error,
    style,
    title,
    isRequired,
    onSelect,
    header,
    data,
    styleTitle,
    disabled,
    isBoldTitle,
    hint = '',
    placeHolder = '',
    onLayout = null,
    scanId = null,
    onQRPress,
    canEnter = false,
    onApplyText = null,
  } = props;
  // const cloneData = [...data, ...data]
  // const cloneData = [data[0], data[1]]
  // const cloneData = [...data]

  // console.log('AppPicker>>>', props)

  const [visible, setVisible] = useState(false);
  const [text, setText] = useState('')
  // const {onQRPress} = useAppPicker(props)

  let scrollViewRef = useRef(null);
  let textInputRef = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    // console.log('useEffect>>>', selected, props)
    if (!scrollViewRef.current || !visible) return;

    setTimeout(() => {
      scrollViewRef.current?.scrollTo({
        x: 0,
        y:
          findIndex(selected) * (ITEM_HEIGHT + VERTICAL_HEIGHT) +
          VERTICAL_HEIGHT * 2 -
          (CONTENT_SIZE + ITEM_HEIGHT - (header ? HEADER_HEIGHT : 0)) / 2,
        animated: true,
      });
    });

    if(!!canEnter){
      const index = findIndex(selected)
      // console.log('index>>>', index, _.isNil(index), selected)
      if(_.isNil(index)||index < 0) {
        const textSelected = selected?.toString()
        setText(textSelected)
        if(!_.isEmpty(textSelected)){
          setTimeout(() => {
            textInputRef?.current?.focus()
          }, 100)
        }
      }
      else  {
        setText('')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const handleOpenModal = () => {
    if (data && data.length > 0) setVisible(true);
  };

  const handleBackdropPress = () => {
    setVisible(false);
  };

  const findIndex = id => {
    return data?.findIndex(i => i.id === id);
  };

  const getHeight = () => {
    if(canEnter){
      return data?.length>=6? (Constant.MAIN_HEIGHT + 16) *7: (Constant.MAIN_HEIGHT +16)  * (data?.length + 1)
    }
    return data?.length>=6? (Constant.MAIN_HEIGHT + 16) *6: (Constant.MAIN_HEIGHT +16) * data?.length
  }

  const getValue = () => {
    if(_.isEmpty(data)){
      return null
    }
    const vl = data[findIndex(selected)]?.content
    if(!_.isNil(vl))
    {
      return vl
    }
    if(!!canEnter){
      return selected
    }
    return null
  }

  const keyboardType = KeyboardHelper.getKeyboardTypeOfPicker(data)
  const valueType = KeyboardHelper.getValueTypeOfPicker(data)

  // const value = data ? data[findIndex(selected)]?.content ?? canEnter ? selected : null : null;
  // console.log("AppPicker>>>value>>>", value, selected, findIndex(selected), data[findIndex(selected)]?.content)

  return (
    <>
      <View
        style={[styles.container, style]}
        onLayout={e => {
          if (onLayout) {
            onLayout(e);
          }
        }}>
        <AppText style={[styles.textTitle, styleTitle, isBoldTitle ? { fontWeight: '700' } : {}]}>
          {isRequired && <AppText style={styles.isRequired}>* </AppText>}
          {title}
        </AppText>
        <View style={[globalStyle.directionRow, globalStyle.center]}>
          <TouchableOpacity
            disabled={disabled}
            onPress={handleOpenModal}
            activeOpacity={0.7}
            style={[globalStyle.flex1, styles.viewSelect, { opacity: disabled ? 0.5 : 1 }]}>
            {/* {mode === 'date' ? <CalendarIcon /> : <ClockIcon />} */}
            <AppText
              numberOfLines={1}
              style={[styles.textSelect, checkRadioId(selected) ? styles.textAfterSelect : {}]}>
              {checkRadioId(selected) ? getValue() : FormTransform.getPlaceHolder(placeHolder)}
            </AppText>
            <ChevronPickerIcon />
          </TouchableOpacity>
          {!!scanId && (
            <TouchableOpacity
              style={[styles.viewSelect, { marginLeft: theme.spacing.sm }]}
              onPress={() => {
                if (onQRPress) {
                  onQRPress();
                }
              }}>
              <QRIcon />
            </TouchableOpacity>
          )}
        </View>
        {/*{!!hint && <AppText style={styles.hint}>{hint}</AppText>}*/}
        {/*{!!hint && (*/}
        {/*  <RenderHtml*/}
        {/*    source={{ html: hint }}*/}
        {/*    baseStyle={styles.hint}*/}
        {/*    tagsStyles={{*/}
        {/*      img: {*/}
        {/*        resizeMode: 'cover',*/}
        {/*        borderRadius: 4,*/}
        {/*        // borderColor: palette.BACKGROUND_GREY,*/}
        {/*        // borderWidth:1,*/}
        {/*        width: Device.width - theme.spacing.md * 2,*/}
        {/*      },*/}
        {/*    }}*/}
        {/*  />*/}
        {/*)}*/}
        {!!hint && <AppHtmlView text={hint} style={styles.hint} isBoldTitle={false} />}

        {!!error && <AppText style={styles.error}>{error}</AppText>}
      </View>
      <AppModal
          visible={visible}
          supportKeyboard={!!canEnter}
          onBackdropPress={handleBackdropPress}
          containerStyle={[{backgroundColor: 'white'}]}
      >
        {!!header && (
          <View
            style={styles.header}
            onLayout={e => {
              console.log(e.nativeEvent.layout.height);
            }}>
            <AppText style={styles.headerTxt}>{header}</AppText>
          </View>
        )}
        {/*<KeyboardAvoidingView*/}
        {/*    keyboardVerticalOffset={250}*/}
        {/*    behavior={Platform.OS === 'ios' ? 'position' : 'height'}*/}
        {/*>*/}


        <ScrollView
          ref={_ref => {
            if (_ref) {
              scrollViewRef.current = _ref;
            }
          }}
          style={[styles.modalContent, {height: getHeight(), marginBottom: !!canEnter ? 0 : theme.spacing.md}]}>
          <AppSingleSelection
              style={{ paddingTop: 0 }}
              selected={selected}
              data={data}
              onSelect={item => {
                onSelect(item);
                handleBackdropPress();
              }}
              isRadio={false}
          />
        </ScrollView>
        {!!canEnter && <View
              style={[
                styles.boxInput,
              ]}>
            <TextInput
                ref={_ref => {
                  if (_ref) {
                    textInputRef.current = _ref;
                  }
                }}
                keyboardType={keyboardType}
                style={[styles.input]}
                value={text}
                onChangeText={text => {
                  //NOTE: must uppercase
                  // onTextChanged(newText);
                  // if (props.onChangeText) {
                  //   props.onChangeText(newText);
                  // }
                  // const type = KeyboardHelper.getKeyboardTypeOfPicker(data)
                  if([ValueType.bigint,ValueType.number].includes(valueType)){
                    const newText = StringHelper.getFloatNumber(text)
                    setText(newText)
                  }
                  else{
                    setText(text)
                  }
                }}
                placeholderTextColor={'#999999'}
            />
            {/*{loading && <AppLoadingMore loadingMore={loading} />}*/}

            <View style={{ margin: 2 }}>
              <TouchableOpacity
                  style={[
                    {
                      borderRadius: 4,
                      backgroundColor: '#D9D9D9',
                      // height: '100%',
                      height: Platform.OS === 'web' ? '48px' : '100%',
                      aspectRatio: 1,
                      // margin:2,
                    },
                    globalStyle.center,
                  ]}
                  onPress={() => {
                    // console.log('onApplyText>>>', props)
                    if(onApplyText){
                      onApplyText(text)
                    }
                    handleBackdropPress();
                  }}
              >
                {/*<FontAwesome5 name="long-arrow-right" size={15} color={'white'} />*/}
                <LongArrowIcon/>
              </TouchableOpacity>
            </View>
          </View>}
        {/*</KeyboardAvoidingView>*/}

      </AppModal>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  textTitle: {
    fontWeight: '400',

    marginBottom: 10,
    color: '#333333',
  },
  viewSelect: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#C6C6C6',
    marginHorizontal: 0,
    minHeight: Constant.MAIN_HEIGHT, //42
    paddingHorizontal: 12,
    borderRadius: 4,
  },
  isRequired: {
    color: '#FF5757',
  },
  textAfterSelect: {
    fontWeight: '400',

    color: '#333333',
    // marginLeft: 12,
    // textTransform: 'capitalize',
  },
  textSelect: {
    flex: 1,
    fontWeight: '400',

    color: '#999999',
    // marginLeft: 12,
    // textTransform: 'capitalize',
  },
  modalContent: {
    paddingHorizontal: 16,
    // marginBottom: 16,
    backgroundColor: 'white'
  },
  header: {
    backgroundColor: '#F7F7F7',
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    minHeight: 50,
    height: 'auto',
  },
  headerTxt: {
    fontWeight: '700',

    color: '#000000',
    paddingHorizontal: 15,
    paddingVertical: 16,
  },
  viewHint: {
    marginHorizontal: 15,
    marginRight: 10,
  },
  hint: {
    color: '#999999',
    marginTop: 7,
    fontSize: FontSize.Font15,
    // marginLeft: 15,
  },
  error: {
    marginTop: 7,

    color: palette.ROYAL_MAIL_RED,
    fontWeight: '400',
  },
  boxInput: {
    // marginTop: theme.spacing.md,
    margin: theme.spacing.md,
    flex: 1,
    flexDirection: 'row',
    // width: '100%',
    alignItems: 'center',
    textAlignVertical: 'center',
    justifyContent: 'space-between',
    minHeight: Constant.MAIN_HEIGHT, //42
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#D5D5D5',
  },
  input: {
    // height: '100%',
    height: Platform.OS === 'web' ? '48px' : '100%',
    flex: 1,
    fontSize: FontSize.Font15,
    color: '#333333',
    fontWeight: '400',
    paddingLeft: 10,
    // textTransform: 'uppercase',
    ...Platform.select({
      web:{
        outlineStyle: 'none'
      }
    })
  },
});
