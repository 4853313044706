import { ForceUpdateStyles } from '@components/AppForceUpdate/ForceUpdate.style';
import { Device } from '@theme/device';
import { Platform } from 'react-native';
import React from 'react';
import AwesomeAlert from 'react-native-awesome-alerts';

export const AppAlert = React.memo(props => {
  const { customView, show, contentContainerStyle, closeOnTouchOutside, onDismiss, is7Percent, isCalendar, isSuccess } =
    props;

  return (
    <AwesomeAlert
      show={show}
      // animatedValue={1}
      showProgress={false}
      closeOnTouchOutside={closeOnTouchOutside}
      onDismiss={onDismiss}
      closeOnHardwareBackPress={false}
      showCancelButton={true}
      showConfirmButton={false}
      // cancelButtonColor={Colors.red}
      onCancelPressed={() => {
        // setShowAlert(false);
      }}
      alertContainerStyle={[ForceUpdateStyles.alertContainerStyle]}
      contentContainerStyle={[
        ForceUpdateStyles.contentContainerStyle,
        contentContainerStyle,
        isCalendar ? {} : { minWidth: Platform.OS === 'web' ? '255px' : Device.width * 0.92 },
      ]}
      contentStyle={[
        ForceUpdateStyles.contentStyle,
        is7Percent && {
          left:  Platform.OS === 'web' ? '' :'7%',
        },
        isSuccess ? { paddingVertical: 20 } : {},
      ]}
      actionContainerStyle={ForceUpdateStyles.actionContainerStyle}
      cancelButtonStyle={[]}
      customView={customView}
    />
  );
});
