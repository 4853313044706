import { AppButton, AppSafeAreaView, AppText } from '@components';
import { AppSuccessScreen } from '@components/AppSuccessScreen';
import { FormItemView } from '@components/Form';
import GenericHeader from '@components/GenericHeader';
import { goBack } from '@routes/navigationUtils';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import theme, { globalStyle } from '@theme';
import { palette } from '@theme/colors';
import { Spacing } from '@theme/size';
import { FormTransform, getContentTitle } from '@utils/transform/form';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import {RenderHelper} from "@utils/form/render";

export const AppForm = props => {
  const {
    formik,
    data,
    setLayoutInfo,
    layoutInfo,
    scrollToY,
    scrollViewRef,
    title,
    description,
    attributes,
    buttonText,
    userLocation,
    isSuccess,
    messageInfo,
  } = props;

  const subForm = FormTransform.getSubFormDispatch(attributes);

  const refLayoutInfo = useRef(null)
    useEffect(() => {
        // console.log('AppForm>>>useEffect>>>')
        refLayoutInfo.current = {}
    },[])

  const renderForm = () => {
    return (
      <>
        <KeyboardAwareScrollView
          innerRef={ref => {
            if (ref) {
              scrollViewRef.current = ref;
            }
          }}
          style={[]}
          contentContainerStyle={{ flexGrow: 1 }}
          showsVerticalScrollIndicator={false}
          keyboardShouldPersistTaps="handled">
          <AppText
            style={[
              globalStyle.alignCenter,
              globalStyle.justifyCenter,
              RegisterFormStyles.paddingSpace,
              RegisterFormStyles.descriptionText,
              { marginVertical: description ? theme.spacing.md : 0 },
            ]}>
            {description}
          </AppText>

          <View
            style={[
              globalStyle.directionColumm,
              RegisterFormStyles.paddingSpace,
              globalStyle.flex1,
              ...props.itemStyle,
            ]}>
            {attributes?.map((item, index) => {
              return (
                <FormItemView
                    key={`${item?.internalId}_${index}`}
                  index={index}
                  item={item}
                  formik={formik}
                  isBoldTitle={true}
                  checkCondition={true}
                  onLayout={e => {
                      console.log('onLayout>>>FormItemView>>>', e, item)
                       RenderHelper.onLayout(e, item, layoutInfo, setLayoutInfo, refLayoutInfo)
                  }}
                  formData={attributes}
                  extraData={{
                    location: userLocation,
                    subForm: subForm,
                  }}
                  subFormRef={props.subFormRef}
                  scrollViewRef={props.scrollViewRef}
                />
              );
            })}
          </View>
          {!!buttonText && (
            <View
              style={[
                RegisterFormStyles.paddingSpace,
                { marginVertical: theme.spacing.sm, marginBottom: Spacing.height25 },
              ]}>
              <AppButton
                text={buttonText}
                // onPress={formik.handleSubmit}
                onPress={async () => {
                  await FormTransform.submitAndScroll(formik, attributes, layoutInfo, scrollToY);
                }}
              />
            </View>
          )}
        </KeyboardAwareScrollView>
      </>
    );
  };

  return (
    <AppSafeAreaView areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>
      <GenericHeader
        onBackClicked={() => {
          goBack();
        }}
        title={getContentTitle(title)}
        style={[{ backgroundColor: palette.BACKGROUND_GREY }]}
      />
      <View style={[globalStyle.directionColumm, globalStyle.flex1, RegisterFormStyles.containerView]}>
        {isSuccess ? (
          <AppSuccessScreen
            alert={messageInfo?.alert}
            icon={messageInfo?.icon}
            description={messageInfo?.description}
          />
        ) : (
          renderForm()
        )}
      </View>
    </AppSafeAreaView>
  );
};

AppForm.propTypes = {
  formik: PropTypes.any,
  layoutInfo: PropTypes.any,
  setLayoutInfo: PropTypes.any,
  scrollToY: PropTypes.func,
  scrollViewRef: PropTypes.any,
  attributes: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  userLocation: PropTypes.object,
  subFormRef: PropTypes.any,
  itemStyle: PropTypes.any,
  isSuccess: PropTypes.bool,
  messageInfo: PropTypes.object,
};

AppForm.defaultProps = {
  buttonText: 'buttonText',
  itemStyle: [],
  // isSuccess: true,
  // messageInfo: {
  //     alert: 'abc',
  //     description: 'desc'
  // }
};
