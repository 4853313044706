import { ChevronRightIcon, NoAvatarIcon } from '@assets';
import { CommonImages } from '@assets';
import { AppText } from '@components';
import { navigate } from '@routes/navigationUtils';
import { MENU_WEB_VIEW_SCREEN } from '@routes/route';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { isSvgUrl, isUrl } from '@utils/common/imageUtils';
import React from 'react';
import { Image, TouchableOpacity, View } from 'react-native';
import { SvgCssUri, SvgUri } from 'react-native-svg';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { useLogic } from './MenuItem.hook';
import { styles } from './MenuList.style';

export const FlexImageIcon = ({ icon, style, color = '#999999' }) => {
  if (isSvgUrl(icon)) {
    if (Device.deviceType !== 'web') {
      return (
        <SvgCssUri
          style={style}
          uri={icon}
          height={style?.height ? style?.height : 24}
          width={style?.width ? style?.width : 24}
          color={color}
        />
      );
    } else {
      return (
        <img
          src={icon}
          alt=""
          height={style?.height ? style?.height : 24} // should be custom in src/screens/main/Menu/Components/MenuList/MenuList.style.js
          width={style?.width ? style?.width : 24}
          color={color}
        />
      );
    }
  } else if (isUrl(icon)) {
    return <Image source={icon ? { uri: icon } : CommonImages.FallbackImage} style={style} />;
  }
  return <FontAwesome name={icon} color={color} />;
};

const MenuItem = props => {
  const { data } = props;
  const { handleNavigate } = useLogic(props);
  return (
    <TouchableOpacity onPress={handleNavigate} activeOpacity={0.7} style={styles.menuItem}>
      <FlexImageIcon icon={data.icon} style={styles.itemImg} />
      <AppText style={styles.title}>{data.title}</AppText>
      <ChevronRightIcon />
    </TouchableOpacity>
  );
};

export const MenuList = props => {
  const { data, isLast, isFirst } = props;
  return (
    <View style={styles.container}>
      <AppText style={[styles.heading, isFirst ? { paddingTop: 0 } : {}]}>{data?.heading}</AppText>
      <View style={styles.itemWrap}>
        {data?.data?.map((item, index) => {
          if (!item?.show) return;
          return <MenuItem key={index} data={item} />;
        })}
      </View>
      {isLast || <View style={styles.divider} />}
    </View>
  );
};
