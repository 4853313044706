import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import React from 'react';
import { DASHBOARD_TAB, REPORT_TAB, SCAN_QR_TAB, FAQ_TAB, MENU_TAB } from '../route';
import { MyTabBar } from './MyTabBar.view';
import { MenuStackScreen, DashboardStackScreen, FAQStackScreen } from './Stacks';

const Tab = createBottomTabNavigator();

export const BottomTab = () => {
  return (
    <Tab.Navigator initialRouteName={DASHBOARD_TAB} tabBar={props => <MyTabBar {...props} />}>
      <Tab.Screen
        options={{
          headerShown: false,
        }}
        name={DASHBOARD_TAB}
        component={DashboardStackScreen}
      />
      <Tab.Screen
        options={{
          headerShown: false,
        }}
        name={REPORT_TAB}
        component={FAQStackScreen}
      />
      <Tab.Screen
        options={{
          headerShown: false,
        }}
        name={SCAN_QR_TAB}
        component={FAQStackScreen}
      />
      <Tab.Screen
        options={{
          headerShown: false,
        }}
        name={FAQ_TAB}
        component={FAQStackScreen}
      />
      <Tab.Screen
        options={{
          headerShown: false,
        }}
        name={MENU_TAB}
        component={MenuStackScreen}
      />
    </Tab.Navigator>
  );
};
