import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppKAView, AppSafeAreaView, AppText } from '@components';
import { AppForm } from '@components/AppForm';
import GenericHeader from '@components/GenericHeader';
import { goBack } from '@routes/navigationUtils';
import { useRFID } from '@screens/main/RFID/form/RFID.hook';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font } from '@theme/size';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Text } from 'react-native';
import { useCDMForm } from './CDMForm.hook';
import { CDMFormStyle } from './CDMForm.style';

export const CDMFormScreen = props => {
  const { t } = useTranslation();
  const {
    formik,
    data,
    scrollToY,
    scrollViewRef,
    layoutInfo,
    setLayoutInfo,
    userLocation,
    subFormRef,
    messageInfo,
    isSuccess,
    isLastStep
  } = useCDMForm(props);
  return (
    <AppForm
      title={data?.title}
      description={data?.description}
      scrollViewRef={scrollViewRef}
      scrollToY={scrollToY}
      formik={formik}
      attributes={data?.attributes}
      setLayoutInfo={setLayoutInfo}
      layoutInfo={layoutInfo}
      buttonText={isLastStep ? t('SUBMIT') : t('NEXT')}
      userLocation={userLocation}
      subFormRef={subFormRef}
      messageInfo={messageInfo}
      isSuccess={isSuccess}
    />
  );
};
