import { Config } from '@constant/config';
import { UserMockData } from '@constant/model/mock/userMockData';
import api from '@services/api-client';
import { stringify } from 'qs';
import withQuery from 'with-query';

export const activeUserService = async payload => {
  console.log('activeUserService>>>', payload);
  const response = await api.post(`/users/activate.json`, payload);
  console.log('activeUserService response>>>', response);
  return response?.data;
};

export const getSettingsService = async payload => {
  console.log('getSettingsService>>>', payload);
  const response = await api.get(`/settings.json?${stringify(payload)}`);
  console.log('getSettingsService response>>>', response);

  return response?.data;
};

export const updateUserService = async payload => {
  console.log('updateUserService>>>', payload);
  const response = await api.put(`/users/${payload?.id}.json`, payload);
  console.log('updateUserService response>>>', response);
  return response?.data;
};

export const getUserService = async payload => {
  console.log('getUserService>>>', payload);
  // const response = await api.get(`/users/56b8563e-cf00-4706-bdcc-389b7c126385.json`)
  // @ts-ignore
  // if (Config.IsMockServer) {
  //   return {
  //     status: true,
  //     message: {
  //       detail: 'Panelist Infromation',
  //       data: UserMockData,
  //     },
  //   };
  // }

  const response = await api.get(`/users/${payload?.id}.json`);
  console.log('getUserService response>>>', response);

  return response?.data;
};

export const validateUserService = async payload => {
  console.log('validateUserService>>>', payload);
  const response = await api.get(`/users/validate.json?${stringify(payload)}`);
  console.log('validateUserService response>>>', response);

  return response?.data;
};

export const loginService = async payload => {
  console.log('loginService>>>', payload);
  const response = await api.post(`/user/login.json?${stringify(payload)}`, payload);
  console.log('loginService response>>>', response);
  return response?.data;
};

export const logoutService = async payload => {
  console.log('logoutService>>>', payload);
  const response = await api.get(`/user/logout.json?${stringify(payload)}`);
  console.log('logoutService response>>>', response);

  return response?.data;
};

export const forgotPasswordService = async payload => {
  console.log('forgotPasswordService>>>', payload);
  const response = await api.post(`/user/forgotPassword.json?${stringify(payload)}`, payload);
  console.log('forgotPasswordService response>>>', response);
  return response?.data;
};

export const resetPasswordService = async payload => {
  console.log('resetPasswordService>>>', payload);
  const response = await api.post(`/user/resetPassword.json?${stringify(payload)}`, payload);
  console.log('resetPasswordService response>>>', response);
  return response?.data;
};

export const getProfileService = async payload => {
  console.log('getProfileService>>>', payload);
  const response = await api.get(`/users/getProfile.json?${stringify(payload)}`);
  console.log('getProfileService response>>>', response);

  return response?.data;
};

export const updateProfileService = async payload => {
  console.log('updateProfileService>>>', payload);
  const response = await api.put(`/users/updateProfile.json`, payload);
  console.log('updateProfileService response>>>', response);
  return response?.data;
};

export const unlockSkillService = async payload => {
  console.log('unlockSkillService>>>', payload);
  const response = await api.get(`/users/unlockSkill.json?skill_key=${payload?.skill_key}`);
  console.log('unlockSkillService response>>>', response);
  return response?.data;
};

export const getBonusPointListService = async payload => {
  console.log('getBonusPointListService>>>', payload);
  const response = await api.get(`/users/630736fe6cb3754dc650019a/log/bonus.json?${stringify(payload)}`);
  console.log('getBonusPointListService response>>>', response);

  return response?.data;
};

export const unsubscribeUserService = async payload => {
  console.log('unlockSkillService>>>', payload);
  // return { status: true, data: {} };
  const response = await api.get(`/user/unsubscribe.json`);
  console.log('unlockSkillService response>>>', response);
  return response?.data;
};

export const listVoucherService = async payload => {
  console.log('listVoucherService>>>', payload);
  const response = await api.get(`/users/vouchers.json?${stringify(payload)}`);
  console.log('listVoucherService response>>>', response);

  return response?.data;
};

export const redeemVoucherService = async payload => {
  console.log('redeemVoucherService>>>', payload);
  const response = await api.post(`/users/redeem.json?${stringify(payload)}`, payload);
  console.log('redeemVoucherService response>>>', response);
  return response?.data;
};

export const requestChangeEmailService = async payload => {
  console.log('requestChangeEmailService>>>', payload);
  const response = await api.post(`/user/requestChangeEmail.json?${stringify(payload)}`, payload);
  console.log('requestChangeEmailService response>>>', response);
  return response?.data;
};

export const changeEmailService = async payload => {
  console.log('changeEmailService>>>', payload);
  const response = await api.post(`/user/changeEmail.json?${stringify(payload)}`, payload);
  console.log('changeEmailService response>>>', response);
  return response?.data;
};

export const checkUnsubscribeConditionsService = async payload => {
  console.log('checkUnsubscribeConditionsService>>>', payload);
  const response = await api.get(`/users/checkUnsubscribe.json?${stringify(payload)}`);
  console.log('checkUnsubscribeConditionsService response>>>', response);
  return response?.data
}

export const acceptUserCdmService = async payload => {
  console.log('acceptUserCdmService>>>', payload)
  const response = await api.post(`/users/accept.json?${stringify(payload)}`, payload)
  console.log('acceptUserCdmService response>>>', response)
  return response?.data
}
