import { FEEDBACK, FEEDBACK_TYPE, SURVEY_STATUS, UPLOAD_FILE } from '@constant';
import { FORM } from '@constant/model/RegisterForm';
import { FormTransform } from '@utils/transform/form';
import { t } from 'i18next';
import _ from 'lodash';
import format from 'string-format';
import {FileTransform} from "@utils/transform/file";

const conditionTotalMisdelivered = {
  javascript: 'return misdelivered_items >= {};',
  error: 'CDS_EXCEEDED_THE_TOTAL_ITEM',
};

const lessOneTotalItem = {
  javascript: 'return misdelivered_items == ({} + 1);',
  error: 'CDS_LESS_ONE_THAN_TOTAL_ITEM',
};

const lessManyTotalItem = {
  javascript: 'return misdelivered_items > {} + 1;',
  error: 'CDS_LESS_MANY_THAN_TOTAL_ITEM',
};

const greaterTotalItem = {
  javascript: 'return misdelivered_items < {};',
  error: 'CDS_GREATER_THAN_TOTAL_ITEM',
};

const equalTotalItem = {
  javascript: 'return misdelivered_items <= {};',
  error: 'CDS_EQUAL_TOTAL_ITEM',
};

const requiredTotalItem = {
  javascript: 'return 0 == {};',
  error: 'ERROR_REQUIRED',
};

export const CDSTransform = {
  getListExpressionSubForm: (formValues, extraFormValues, cloneId, formStructure = []) => {
    console.log('getListExpressionSubForm>>>', formValues, extraFormValues, cloneId);
    const con1 = _.cloneDeep(conditionTotalMisdelivered);
    con1.javascript = format(con1.javascript, CDSTransform.getTotalItemDetails(formValues, extraFormValues, cloneId, formStructure));
    con1.error = t(con1.error);
    return [con1];
  },

  getListExpressionForm: formValues => {
    const misdelivered_items = formValues[FORM.FORM_ELEMENT_NAME.misdelivered_items];
    const misdelivered_item_form = formValues[FORM.FORM_ELEMENT_NAME.misdelivered_item_form] ?? [];
    const sizeClones = misdelivered_item_form.length;
    const ls = [greaterTotalItem, lessManyTotalItem, lessOneTotalItem];
    const rs = [];
    for (const l of ls) {
      const item = _.cloneDeep(l);
      item.javascript = format(item.javascript, sizeClones);
      item.error = format(t(item.error), misdelivered_items - sizeClones);
      rs.push(item);
    }
    return rs;
  },
  getEqualExpressionForm: formValues => {
    const misdelivered_item_form = formValues[FORM.FORM_ELEMENT_NAME.misdelivered_item_form] ?? [];
    const sizeClones = misdelivered_item_form.length;
    const con1 = _.cloneDeep(equalTotalItem);
    con1.javascript = format(con1.javascript, sizeClones);
    con1.error = t(con1.error);
    return [con1];
  },
  getTotalItemDetailsFromServer: () => {

  },
  getTotalItemDetails: (formValues, extraFormValues, cloneId, formStructure = []) => {
    //NOTE: case cloneId is null and misdelivered_item_form is list of string.
    let misdelivered_item_form = extraFormValues[FORM.FORM_ELEMENT_NAME.misdelivered_item_form];
    misdelivered_item_form = _.isEmpty(misdelivered_item_form) ? [] : misdelivered_item_form
    let oldItems = []
    console.log('getTotalItemDetails>>>start>>>', misdelivered_item_form )

    let listCloneId = misdelivered_item_form?.map(x => FileTransform.getCloneIdOfCDS(x, formStructure))
    console.log('listCloneId>>>', listCloneId, cloneId, formStructure )
    oldItems = listCloneId.filter(x =>  x === cloneId);

    const oldCount = !_.isEmpty(misdelivered_item_form) ? misdelivered_item_form.length : 0;
    const oldCloneSize = oldItems ? oldItems.length : 0;
    const number_of_same_details_origin = formValues[FORM.FORM_ELEMENT_NAME.number_of_same_details]

    const number_of_same_details = !_.isEmpty(number_of_same_details_origin) ? parseInt(number_of_same_details_origin) : 1 ?? 1;
    const newCount = oldCount + number_of_same_details - oldCloneSize;
    console.log('getTotalItemDetails>>>', newCount, number_of_same_details, oldCount, oldCloneSize, formValues)
    return newCount;
  },

  getDiffCountItem: (formValues, extraFormValues, cloneId, formStructure = []) => {
    const misdelivered_items = extraFormValues[FORM.FORM_ELEMENT_NAME.misdelivered_items];
    const newCount = CDSTransform.getTotalItemDetails(formValues, extraFormValues, cloneId, formStructure);
    return newCount - misdelivered_items;
  },

  getCDSSubmitRequest: async (formValues, extraData, formData, dispatch, idSurvey, step, isCompleted = false) => {
    const values = _.cloneDeep(formValues);
    const type = extraData?.status === SURVEY_STATUS.Unsure_date ? FEEDBACK.type.unsure_date : FEEDBACK.type.date;
    const misdelivered_item_form = values[FORM.FORM_TYPE.misdelivered_item_form];
    if (!_.isEmpty(misdelivered_item_form)) {
      const subFormData = formData.find(x => x.type === FORM.FORM_TYPE.misdelivered_item_form);
      const subForms = [];
      console.log('getCDSSubmitRequest>>>1>>>', subFormData);
      for (const misdelivered_item of misdelivered_item_form) {
        const item = await FormTransform.uploadFileFormikFDBApi(
          misdelivered_item,
          subFormData?.subForms??[],
          null,
          dispatch,
          UPLOAD_FILE.folderUpload.cds,
        );
        console.log('uploadFileFormikApi>>>', item, misdelivered_item);
        subForms.push(item);
      }
      values[FORM.FORM_TYPE.misdelivered_item_form] = subForms;
    }

    const payload = {
      feedback: {
        type: type,
        target_week: extraData.week,
        target_date: type === FEEDBACK.type.unsure_date ? null : extraData.date, // hide if type is unsure_date
        s_id: type === FEEDBACK.type.unsure_date ? null : extraData.s_id, // hide if type is unsure_date
        status: isCompleted ? FEEDBACK.status.complete : FEEDBACK.status.incomplete,
        step: step,
        id: idSurvey,
      },
      answer: {
        ...values,
      },
    };

    return payload;
  },
};
