import { CrossIcon, LogoIcon, MessageCheckIcon } from '@assets';
import { AppAlert, AppButton, AppKAView, AppSafeAreaView, AppText } from '@components';
import { ForceUpdateStyles } from '@components/AppForceUpdate/ForceUpdate.style';
import { FormItemView } from '@components/Form';
import GenericHeader from '@components/GenericHeader';
import { UPLOAD_FILE } from '@constant';
import { goBack } from '@routes/navigationUtils';
import { AddReportStyle } from '@screens/main/report/AddReport/AddReport.style';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { Font, FontSize, Spacing } from '@theme/size';
import { FormTransform } from '@utils/transform/form';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Text, ScrollView, TouchableOpacity } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useAddTicket } from './AddTicket.hook';
import { AddTicketStyle } from './AddTicket.style';

export const AddTicketScreen = props => {
  const { t } = useTranslation();
  const { data, formik, modalShow, setModalShow, setLayoutInfo, layoutInfo, scrollToY, scrollViewRef } =
    useAddTicket(props);
  const PlatformView = Device.isIos ? AppKAView : Fragment;
  let propertyPlatformView = Device.isIos
    ? {
        keyboardVerticalOffset: 150,
      }
    : {};
  return (
    <View style={[globalStyle.directionColumm, globalStyle.flex1, AddReportStyle.containerView]}>
      <PlatformView {...propertyPlatformView}>
        <KeyboardAwareScrollView style={[]}
         showsVerticalScrollIndicator={false}
        >
          <View style={[globalStyle.directionColumm, RegisterFormStyles.paddingSpace]}>
            {data?.attributes?.map((item, index) => {
              return (
                // <FormItemView
                //   index={index}
                //   item={item}
                //   formik={formik}
                //   isBoldTitle={true}
                //   style={[{ marginBottom: theme.spacing.lg }]}
                //   folderUpload={UPLOAD_FILE.folderUpload.ticket}
                // />
                <FormItemView
                  index={index}
                  item={item}
                  formik={formik}
                  isBoldTitle={true}
                  checkCondition={true}
                  onLayout={e => {
                    const layout = e?.nativeEvent?.layout;
                    if (!layout) {
                      return;
                    }
                    const newInfo = { ...layoutInfo };
                    newInfo[item?.internalId] = layout.y + layout.height;
                    setLayoutInfo(newInfo);
                  }}
                  formData={data?.attributes}
                  // extraData={{
                  //     location: userLocation,
                  //     subForm: subForm,
                  // }}
                  folderUpload={UPLOAD_FILE.folderUpload.ticket}
                  subFormRef={props.subFormRef}
                  scrollViewRef={props.scrollViewRef}
                />
              );
            })}
          </View>
        </KeyboardAwareScrollView>
          <View
              style={[
                  RegisterFormStyles.paddingSpace,
                  { marginVertical: theme.spacing.sm, marginBottom: Spacing.height25 },
              ]}>
              <AppButton
                  text={t('Create')}
                  // onPress={formik.handleSubmit}
                  onPress={async () => {
                      await FormTransform.submitAndScroll(formik, data?.attributes, layoutInfo, scrollToY);
                  }}
              />
          </View>
      </PlatformView>
      {/* </PlatformView> */}
      <AppAlert
        isSuccess
        show={modalShow}
        is7Percent
        onDismiss={() => setModalShow(false)}
        customView={
          <View style={[ForceUpdateStyles.rootView]}>
            <TouchableOpacity
              onPress={() => {
                setModalShow(false);
              }}
              style={{
                position: 'absolute',
                top: -16,
                right: 6,
                backgroundColor: '#c9c9c9',
                zIndex: 1,
                padding: 6,
                borderRadius: 1000,
              }}>
              <CrossIcon color="white" />
            </TouchableOpacity>
            <View style={[globalStyle.directionColumm, { paddingVertical: Spacing.height20 }]}>
              <View style={[globalStyle.center]}>
                <MessageCheckIcon color={palette.GREEN_ACTIVE} />
              </View>
              <View
                style={[
                  globalStyle.alignCenter,
                  globalStyle.justifyCenter,
                  globalStyle.alignSelfCenter,
                  globalStyle.directionColumm,
                ]}>
                <AppText
                  style={[Font.Bold_700, { textAlign: 'center', margin: theme.spacing.sm, fontSize: FontSize.Font15 }]}>
                  {t('ADD_TICKET_SUCCESSFUL_TITLE')}
                </AppText>
                <AppText
                  style={[
                    {
                      textAlign: 'center',
                      marginHorizontal: theme.spacing.sm,
                      fontSize: FontSize.Font15,
                      color: '#666666',
                    },
                  ]}>
                  {t('ADD_TICKET_SUCCESSFUL_DESCRIPTION')}
                </AppText>
              </View>
            </View>
          </View>
        }
      />
    </View>
  );
};
