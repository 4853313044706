import { ArrowBorderIcon, LogoIcon, MessageCheckIcon } from '@assets';
import { AppKAView, AppRadio, AppSafeAreaView, AppText } from '@components';
import { QuizFailedStyle } from '@screens/main/quiz/QuizFailed/QuizFailed.style';
import { AppHtmlView } from '@screens/main/quiz/QuizFailed/components/AppNote.view';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font } from '@theme/size';
import { getContentTitle } from '@utils/transform/form';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text } from 'react-native';
import { useAppAnswerSingleSelection } from './AppAnswerSingleSelection.hook';
import { AppAnswerSingleSelectionStyle } from './AppAnswerSingleSelection.style';

// import PropTypes from 'prop-types'

export const AppAnswerSingleSelection = React.memo(props => {
  const {
    style,
    data = [],
    selected,
    onSelect,
    isRequired,
    title,
    error,
    isRadio = true,
    styleTitle,
    styleSelected,
    children,
    disabled,
  } = props;

  return (
    <View style={[AppAnswerSingleSelectionStyle.container, style]}>
      {!!title && (
        <AppText style={[AppAnswerSingleSelectionStyle.textTitle, styleTitle]}>
          {title}
          {isRequired && <AppText style={AppAnswerSingleSelectionStyle.isRequired}> *</AppText>}
        </AppText>
      )}
      {data?.map((item, index) => {
        return (
          <>
            <AppRadio
              key={index}
              data={item}
              onSelect={onSelect}
              selected={selected}
              isRadio={isRadio}
              styleSelected={styleSelected}
              disabled={disabled}
            />
            {/*{selected && { children }}*/}
            {/*{selected === item.id && children}*/}
            {selected === item.id && !!item?.hint && (
              <View style={[globalStyle.directionColumm]}>
                <View style={{height: 2}}></View>
                <View style={[{ marginLeft: 24, top: 1, zIndex: 100 }]}>
                  <ArrowBorderIcon height={14} width={16} strokeWidth={1} />
                </View>
                {/*<AppText style={QuizFailedStyle.hintView}>{getContentTitle(selectedItem?.hint)}</AppText>*/}
                <AppHtmlView text={getContentTitle(item?.hint)} style={QuizFailedStyle.hintView} />
              </View>
            )}
          </>
        );
      })}
      {!!error && <AppText style={AppAnswerSingleSelectionStyle.error}>{error}</AppText>}
    </View>
  );
});

AppAnswerSingleSelection.propTypes = {};

AppAnswerSingleSelection.defaultProps = {};
