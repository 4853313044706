import { CommonImages } from '@assets';
import { AppEmptyView } from '@components';
import { AppImage } from '@components/AppImage';
import { DailyItemStyle } from '@screens/main/D2D/dailyItem/DailyItem.style';
import { OverDueStyle as styles } from '@screens/main/D2D/overDue/OverDue.style';
import { CollapseView } from '@screens/main/D2D/overDue/group/collapse';
import theme, { WEB_WIDTH } from '@theme';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import _ from 'lodash';
import React from 'react';
import { FlatList, Platform, ScrollView, TouchableOpacity } from 'react-native';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';

export const GridImageView = ({ loading, onItemZoom, onItemPress, data, scrollEnabled = true }) => {
  // const x = [...data, ...data, ...data, ...data];
  // const cloneData = [...x, ...x, ...x, ...x];
  const cloneData = data;
  console.log('GridImageView>>>', cloneData);
  const contentContainerStyle = scrollEnabled
    ? {
        flexGrow: 1,
      }
    : {
        flex: 1,
        width: scrollEnabled
          ? undefined
          : Platform.OS === 'web'
          ? WEB_WIDTH - theme.spacing.md * 2
          : Device.width - theme.spacing.md * 2,
      };
  return (
    <FlatList
      horizontal={false}
      numColumns={2}
      data={cloneData}
      scrollEnabled={scrollEnabled}
      // style={{ flex: 1 }}
      contentContainerStyle={contentContainerStyle}
      ListEmptyComponent={() => {
        return !loading ? <AppEmptyView /> : <></>;
      }}
      keyExtractor={(item, index) => item.s_id + '_' + index}
      renderItem={({ item, index }) => {
        console.log('renderItem>>>', item, index);
        return (
          <TouchableOpacity
            onPress={() => {
              onItemPress(item);
            }}
            style={[styles.box, (index + 1) % 2 === 0 ? styles.outermostItem : {}, styles.imageWrap]}>
            <TouchableOpacity onPress={() => onItemZoom(item)} style={styles.close}>
              {/*<CrossIcon />*/}
              <FontAwesome5 name={'expand-alt'} size={24} color={palette.WHITE} />
            </TouchableOpacity>
            {/*<Image source={{ uri: item.image }} resizeMode="cover" style={styles.image} />*/}
            <AppImage
              // style={{ width: 200, height: 200 }}
              style={[styles.image]}
              // style={[{ width: 200, height: 200 }]}
              source={{ uri: item.image }}
              resizeMode={'cover'}
              defaultSource={CommonImages.ImageDefault}
              hideDefaultImage={true}
            />
          </TouchableOpacity>
        );
      }}
    />
  );
};

export const GroupGridView = ({ data, onItemZoom, onItemPress, loading }) => {
  console.log('GroupGridView>>>', data);
  // const cloneData = [...data, ...data, ...data];
  const cloneData = data;
  return (
    <ScrollView
      contentContainerStyle={_.isEmpty(cloneData) ? DailyItemStyle.containerEmpty : styles.content}
      style={styles.container}>
      {cloneData.map((item, index) => {
        console.log('GroupGridView>>>item>>>', item, item?.subItems);
        console.log('GroupGridView>>>index>>>', index);
        const splitTitle = item?.title?.split(' ');
        const title = splitTitle.slice(0, 2).join(' ');
        // const subTitle = splitTitle.slice(2).join(' ').replace('(', '').replace(')', '').trim();
        const subTitle = splitTitle.slice(2).join(' ').trim();

        return (
          <CollapseView title={title} subTitle={subTitle}>
            <GridImageView
              scrollEnabled={false}
              loading={loading}
              data={item?.subItems}
              onItemZoom={onItemZoom}
              onItemPress={onItemPress}
            />
          </CollapseView>
        );
      })}
      {_.isEmpty(cloneData) && <AppEmptyView />}
    </ScrollView>
  );
};
