export const CommonImages = {
  RoyalMail: require('./royalMail.png'),
  Splash: require('./splashscreen.png'),
  Branding: require('./branding.png'),
  SplashLayer: require('./splash_layer.png'),
  FallbackImage: require('./fallbackImage.png'),
  ImagePicker: require('./imagePicker.png'),
  ImagePlaceholder: require('./image_placeholder.png'),
  ImageDefault: require('./img_default.png'),
  Marker: require('./marker.png'),
  Marker2: require('./marker2.png'),
};
