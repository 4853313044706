import { LogoIcon, MessageCheckIcon } from '@assets';
import {AppButton, AppEmptyView, AppInput, AppKAView, AppSafeAreaView, AppText} from '@components';
import { AppSuccessScreen } from '@components/AppSuccessScreen';
import GenericHeader from '@components/GenericHeader';
import { goBack } from '@routes/navigationUtils';
import { CDSSuccessfulView } from '@screens/main/CDS/CDSSuccessful.view';
import { D2DSuccessfulView } from '@screens/main/D2D/form/components/D2DSuccessful.view';
import { D2DOverviewStyle } from '@screens/main/D2D/overview/D2DOverview.style';
import { SearchAddressStyle } from '@screens/main/SearchAddress/SearchAddress.style';
import { AppNoteView } from '@screens/main/quiz/QuizFailed/components/AppNote.view';
import { styles } from '@screens/main/survey/SurveyList/SurveyList.style';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font, Spacing, deviceWidth } from '@theme/size';
import { StringHelper } from '@utils/common/string';
import { getContentTitle } from '@utils/transform/form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    StatusBar,
    View,
    Text,
    ScrollView,
    TouchableOpacity,
    TextInput,
    RefreshControl,
    Keyboard,
    useWindowDimensions, Platform,
} from 'react-native';
import { backgroundColor } from 'react-native-calendars/src/style';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useDailyItem } from './DailyItem.hook';
import { DailyItemStyle } from './DailyItem.style';
import DeviceInfo from "react-native-device-info";
import {Device} from "@theme/device";

const DailyItem = ({ data, onChangeText, leftWidth, setLeftWidth, t }) => {
  // data.clickable = false;
  // let data = x;
  // data.clickable = false;
  let x = _.cloneDeep(data);
  // x.clickable = false;
  console.log('leftWidth<<<', leftWidth);
  return (
    <View
      key={data?.key}
      style={[globalStyle.directionRow, x.clickable ? DailyItemStyle.itemView : DailyItemStyle.itemViewDisable]}>
        {Platform.OS === 'web'? <AppText
            allowFontScaling={false}
            style={[DailyItemStyle.itemText, { width: '50%' }]}
            >
            {x?.name}
        </AppText> : <AppText
            allowFontScaling={false}
            style={[DailyItemStyle.itemText, { width: leftWidth ? leftWidth : '100%' }]}
            onTextLayout={e => {
                setLeftWidth(prev =>
                    e?.nativeEvent?.lines?.[0]?.width > prev ? e?.nativeEvent?.lines?.[0]?.width + 25 : prev,
                );
            }}>
            {x?.name}
        </AppText>}

      <View style={[globalStyle.directionRow, DailyItemStyle.box, { marginLeft: Spacing.width12, flex: 4 }]}>
        <TextInput
          editable={x.clickable}
          value={x.amount}
          onChangeText={txt => {
            const newTxt = StringHelper.getNumber(txt);
            onChangeText(newTxt, data);
          }}
          // keyboardType={'numeric'}
          keyboardType="number-pad"
          style={[globalStyle.flex1, DailyItemStyle.textInput, { marginLeft: theme.spacing.sm, paddingRight: 44 }]}
          allowFontScaling={false}
        />
        <AppText allowFontScaling={false} pointerEvents={'none'}  style={{ color: '#999999', position: 'absolute', right: theme.spacing.sm }}>{t('ITEMS')}</AppText>
      </View>
    </View>
  );
};

export const DailyItemScreen = props => {
  const { t } = useTranslation();
  const { data, onChangeText, headerTitle, onSave, onRefresh, refreshing, description, isSuccess, messageInfo, loading } =
    useDailyItem(props);
  const [leftWidth, setLeftWidth] = useState(0);
  const renderForm = () => {
    return (
      <View style={[globalStyle.directionColumm, globalStyle.flex1, DailyItemStyle.rootContainer]}>
        <KeyboardAwareScrollView
          contentContainerStyle={!loading && _.isEmpty(data) ? DailyItemStyle.containerEmpty : {}}
          // extraHeight={150}
          keyboardShouldPersistTaps="handled"
          showsVerticalScrollIndicator={false}
          onScrollBeginDrag={() => {
            Keyboard.dismiss();
          }}
          // keyboardDismissMode={'on-drag'}
          refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>

            <View style={[globalStyle.directionColumm, DailyItemStyle.container]}>
                {!!description && <AppNoteView style={RegisterFormStyles.formItemView} title={description} />}
                {!loading && _.isEmpty(data) && <AppEmptyView/> }
                {data?.map(item => {
              return (
                <DailyItem
                  data={item}
                  onChangeText={onChangeText}
                  setLeftWidth={setLeftWidth}
                  leftWidth={leftWidth}
                  t={t}
                />
              );
            })}
          </View>
        </KeyboardAwareScrollView>

          {!_.isEmpty(data) && <View
          style={[
            RegisterFormStyles.paddingSpace,
            { marginVertical: theme.spacing.sm, marginBottom: Spacing.height25 },
          ]}>
          <AppButton onPress={onSave} text={t('SAVE')} />
        </View>}

      </View>
    );
  };
  return (
    <>
      <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
      <AppSafeAreaView areaStyle={D2DOverviewStyle.areaStyle}>
        <GenericHeader onBackClicked={goBack} title={headerTitle} style={[{ backgroundColor: '#EDEDED' }]} />
        <View style={[globalStyle.directionColumm, RegisterFormStyles.containerView]}>
          {isSuccess ? (
            <AppSuccessScreen
              alert={messageInfo?.alert}
              icon={messageInfo?.icon}
              description={messageInfo?.description}
            />
          ) : (
            renderForm()
          )}
          {/*{isSuccess ? <D2DSuccessfulView /> : renderForm()}*/}
        </View>
      </AppSafeAreaView>
    </>
  );
};
