import { TICKET_STATUS } from '@constant';
import { Config } from '@constant/config';
import { getListStatusRequest } from '@redux/ticket/getListStatusSlice';
import { getListTicketRequest } from '@redux/ticket/getListTicketSlice';
import {
  getListTicketCreator,
  getListTicketSelector,
  getListTicketError,
  getListTicketErrorMsg,
  getListTicketLoading,
  getListStatusCreator, getForceRefreshListTicket,
} from '@redux/ticket/selectors';
import { navigate } from '@routes/navigationUtils';
import { Screens } from '@routes/route';
import { getListStatus } from '@utils/transform/ticket';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Modalize } from 'react-native-modalize';
import { useDispatch, useSelector } from 'react-redux';
import { all } from 'redux-saga/effects';
import {hideLoading, showLoading} from "@redux/global/globalSlice";

const modalizeRef = React.createRef(null);

const LIMIT = 25

export const useListTicket = props => {
  const dispatch = useDispatch();
  const getListTicketData = useSelector(getListTicketCreator);
  const getListStatusData = useSelector(getListStatusCreator)?.data;
  const paramStatus = props?.route?.params?.status ?? TICKET_STATUS.all;
  const isForceRefresh = useSelector(getForceRefreshListTicket);

  const [loadingMore, setLoadingMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(paramStatus);
  const [isShowModal, setShowModal] = useState(false);
  const [page, setPage] = useState(0);

  const listStatus = getListStatus(getListStatusData?.value);
  const getListStatusApi = () => {
    const payload = {};
    dispatch(getListStatusRequest(payload));
  };
  const getListTicketApi = (status, page = 0) => {
    if (page > 0) {
      setLoadingMore(true);
    }
    else{
      setLoading(true);
      dispatch(showLoading());
    }
    const payload = {
      offset: LIMIT * page,
      limit: LIMIT,
      status: status,
    };
    dispatch(
      getListTicketRequest(
        payload,
        rs => {
          setLoading(false);
          if (page > 0) {
            setLoadingMore(false);
          }
          const ls = rs.data;
          setData([...ls]);
          if (page === 0) {
            setAllData([...ls]);
          } else {
            setAllData([...allData, ...ls]);
          }
          dispatch(hideLoading());
        },
        err => {
          if (page > 0) {
            setLoadingMore(false);
          }
          else{
            setLoading(false);
          }
          dispatch(hideLoading());
        },
      ),
    );
  };

  const syncData = () => {
    setPage(0);
    getListTicketApi(selectedStatus, 0);
    getListStatusApi();
  };

  useEffect(() => {
    syncData();
  }, []);

  useEffect(() => {
    if(isForceRefresh){
      onRefresh()
    }
  }, [isForceRefresh]);

  const onRefresh = () => {
    setPage(0);
    getListTicketApi(selectedStatus, 0);
    // navigate(Screens.TICKET_DETAIL_SCREEN, { data: { _id: '6317119c7b4726093194638c' } });
  };

  const onItemPress = item => {
    if (Config.EnableFeature.ticketDetail) {
      navigate(Screens.TICKET_DETAIL_SCREEN, { data: item });
    }
  };
  const onItemStatusPress = item => {
    if (_.isEmpty(item)) {
      return;
    }
    modalizeRef?.current?.close();
    setShowModal(false);
    setSelectedStatus(item?.key);
    getListTicketApi(item?.key);
  };
  const onFilterPress = () => {
    modalizeRef?.current?.open();
    setShowModal(true);
  };

  const onLoadMore = () => {
    console.log('onLoadMore>>>');
    let newPage = page + 1;
    if (!loadingMore && !loading && data.length > 0) {
      setPage(newPage);
      // const payload = {
      //   offset: 0,
      //   limit: 25,
      //   status: selectedStatus,
      // };
      // dispatch(getListTicketRequest(payload));
      getListTicketApi(selectedStatus, newPage);
    }
  };
  useEffect(() => {
    setData(getListTicketData?.data ?? []);
  }, [getListTicketData]);
  return {
    data,
    allData,
    onRefresh,
    isRefreshing,
    loadingMore,
    loading,
    onLoadMore,
    onItemPress,
    getListStatusData,
    modalizeRef,
    onItemStatusPress,
    selectedStatus,
    onFilterPress,
    listStatus,
  };
};
