import { Constant } from '@constant';
import theme from '@theme';
import { palette } from '@theme/colors';
import { FontSize, Spacing } from '@theme/size';
import {Platform, StyleSheet} from 'react-native';

export const TicketDetailStyle = StyleSheet.create({
  container: {
    paddingHorizontal: Spacing.width15,
    // flex: 1,
    borderTopRightRadius: Spacing.width20,
    borderTopLeftRadius: Spacing.width20,
    backgroundColor: palette.WHITE,

    // alignItems: 'center',
    paddingTop: Platform.OS === 'web' ? theme.spacing.lg : Spacing.height25,
    paddingBottom: theme.spacing.md,
  },
  input: {
    height: Constant.MAIN_HEIGHT,
    flex: 1,
    width: '100%',

    fontSize: FontSize.Font15,
    color: '#333333',
    fontWeight: '400',
    paddingLeft: 10,
  },

  searchContainer: {
    borderWidth: 1,
    borderColor: '#C6C6C6',
    borderRadius: 21,
    minHeight: 42,
    // flex: 1,
    // alignItems: 'center',
    // textAlignVertical: 'center',
    // justifyContent: 'center',
  },
  searchView: {
    // borderWidth: 1,
    // borderColor: '#C6C6C6',
    paddingLeft: theme.spacing.lg,
    flex: 1,
    color: '#333333',
    // minHeight: 42,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    textAlignVertical: 'center',
    fontSize: FontSize.Font15,
    fontWeight: '400',
    ...Platform.select({
      web:{
        outlineStyle: 'none'
      }
    })
    // borderRadius: 4,
  },
  textSecondTitle: {
    color: '#333333',
    fontWeight: '400',
    fontFamily: 'arial',
    fontSize: FontSize.Font15,
  },

  viewMore: {
    color: '#144995',
    fontWeight: '400',
    fontFamily: 'arial',
    ...Platform.select({
      web:{
        cursor: 'pointer'
      }
    })
  },
});
