import {RESULTS} from "react-native-permissions";

export const NotificationPermissionHelper = {
    checkPermission: async () => {
        return RESULTS.GRANTED
    },
    hasPermission: async () => {
        const rs = await NotificationPermissionHelper.checkPermission()
        const x =  rs === RESULTS.GRANTED

        console.log('hasPermission>>', rs, x)
        return x
    }
}