import { createSlice } from '@reduxjs/toolkit';

export interface IgetFileState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IgetFileState = {
  loading: false,
  payload: null,
  error: null,
};

const getFileSlice = createSlice({
  name: 'getFile',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getFileRequest(state, action) {
      state.loading = true;
      // @ts-ignore
      state.payload = null;
      state.error = null;
    },
    getFileSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    getFileFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getFileRequest, getFileSuccess, getFileFailed } = getFileSlice.actions;

export default getFileSlice.reducer;
