import { Constant } from '@constant';
import { palette } from '@theme/colors';
import { FontSize } from '@theme/size';
import { Platform, StyleSheet } from 'react-native';

export const BundlePostCodeStyle = StyleSheet.create({
  boxInput: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    textAlignVertical: 'center',
    justifyContent: 'space-between',
    minHeight: Constant.MAIN_HEIGHT, //42
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#D5D5D5',
  },
  input: {
    // height: '100%',
    height: Platform.OS === 'web' ? '48px' : '100%',
    flex: 1,
    fontSize: FontSize.Font15,
    color: '#333333',
    fontWeight: '400',
    paddingLeft: 10,
    // textTransform: 'uppercase',
    ...Platform.select({
      web:{
        outlineStyle: 'none'
      }
    })
  },
  hint: {
    fontWeight: '400',
    color: '#999999',
    marginTop: 7,
  },
  error: {
    marginTop: 7,
    color: palette.ROYAL_MAIL_RED,
    fontWeight: '400',
  },
});
