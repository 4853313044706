import { isEmpty } from 'lodash';
import { getContentTitle } from '../form';

export const mappingDashboard = data => {
  if (!data || isEmpty(data)) return [];
  const transformedData = data?.map(item => {
    let childData;
    switch (item.type) {
      case 'point':
        return {
          ...item,
        };
      case 'list':
        // console.log('transformedData>>>', item)
          try {
            childData = item?.data?.map(childItem => {
              return {
                ...childItem,
                name: getContentTitle(childItem?.name),
              };
            });
            return {
              ...item,
              name: getContentTitle(item?.name),
              data: childData,
            };
          }
          catch (e) {
            return {
              ...item,
              name: getContentTitle(item?.name),
              data: [],
            };
          }


      case 'item':
      case 'date':
        return {
          ...item,
          name: getContentTitle(item?.name),
        };

      default:
        return {
          ...item,
          name: getContentTitle(item?.name),
        };
    }
  });
  return transformedData;
};

export const mappingSurveyList = data => {
  console.log({ data });
  if (!data || isEmpty(data)) return [];
  const transformedData = data?.map(item => {
    const splitTitle = item?.title?.split(' ');
    return {
      ...item,
      week: splitTitle?.[1],
      date: splitTitle?.slice(2, splitTitle?.length).join(' '),
      days: item?.data?.map(childItem => {
        return {
          ...childItem,
          day: childItem?.name,
          date: childItem?.date,
          status_key: childItem?.status?.key,
          status: childItem?.status?.name || childItem?.key,
          count: childItem?.data?.length,
        };
      }),
      isCurrent: item?.isCurrent,
    };
  });
  return transformedData;
};

export const DashboardTransform = {

}
