import { REPORT_TYPE, UPLOAD_FILE } from '@constant';
import { Config } from '@constant/config';
import { FORM } from '@constant/model/RegisterForm';
import { ReportBundleMockData } from '@constant/model/mock/report/bundle';
import { ReportICodeMockData } from '@constant/model/mock/report/iCode';
import { useNavigation, useRoute } from '@react-navigation/native';
import { getValidateReportRequest } from '@redux/app/appSlice';
import { reportModal as reportModalSelector, validateReportSelector } from '@redux/app/selectors';
import { loginRequest } from '@redux/auth/authSlice';
import { authUser } from '@redux/auth/selectors';
// import { loginRequest } from '@redux/auth/authSlice';
import { submitBundleRequest } from '@redux/bundle/submitBundleSlice';
import { uploadFileRequest } from '@redux/file/uploadFileSlice';
import { getReportBundleFormRequest } from '@redux/form/getReportBundleFormSlice';
import { getReportBundleFormCreator } from '@redux/form/selectors';
import { getProfileRequest } from '@redux/user/getProfileSlice';
import { getSettingsRequest } from '@redux/user/getSettingsSlice';
import { popToTop } from '@routes/navigationUtils';
import { BundleAddressContainerModalStyle } from '@screens/main/report/bundle/form/components/BundleAddressContainerModal.style';
import { SubForm } from '@screens/main/report/bundle/form/components/DispatchPoint.hook';
import { Device } from '@theme/device';
import { ReportModalTransform } from '@utils';
import { StringHelper } from '@utils/common/string';
import { checkFormValidateExpression, getInitialFormValue, getYupStructure } from '@utils/form';
import { useLocation } from '@utils/hooks';
import { BundleTransform } from '@utils/transform/bundle';
import { FormTransform, mappingStep } from '@utils/transform/form';
import { rejects } from 'assert';
import { useFormik } from 'formik';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { lazy } from 'yup';
import * as Yup from 'yup';
import {hideLoading, showLoading} from "@redux/global/globalSlice";

export const useReportBundleDetail = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { lat, long, loading } = useLocation(props);

  const paramsData = useRoute().params?.data;
  const paramsX = useRoute().params;
  console.log('useRoute()?.params>>>', useRoute()?.params);
  const formModal = useSelector(reportModalSelector);
  const validateReportData = useSelector(validateReportSelector);

  console.log('userData>>>', validateReportData);

  const headerTitle = ReportModalTransform.getReportTitle(paramsData?.reportType, formModal);
  const getReportBundleFormData = Config.IsDebug
    ? ReportBundleMockData.form
    : useSelector(getReportBundleFormCreator)?.data;
  const [data, setData] = useState(null);

  const getReportBundleFormApi = (total) => {
    const payload = {
      number_of_planned_items: total,
      bundle_id: paramsX?.id,

    };
    // dispatch(getReportBundleFormRequest(payload));
    dispatch(showLoading());
    dispatch(
        getReportBundleFormRequest(
            payload,
            res => {
              dispatch(hideLoading());
            },
            err => {
              dispatch(hideLoading());
            },
        ),
    );
  };

  const loginApi = () => {
    console.log('loginApi>>>', paramsX);
    dispatch(
      loginRequest(
        {
          jwt: paramsX?.jwt,
        },
        rs => {
          if (!rs.status) {
            window.parent.postMessage(
              JSON.stringify({
                message: 'panelist_not_found',
                status: true,
              }),
              paramsX?.host,
            );
            return;
          }
          console.log('loginApi>>>rs>>', rs);
          window.parent.postMessage(
            {
              status: true,
              message: 'login_success',
            },
            paramsX?.host,
          );
          dispatch(getProfileRequest());
          dispatch(getSettingsRequest({}));
          validateReportApi(rs?.data);
        },
        err => {
          window.parent.postMessage(JSON.stringify(err), paramsX?.host);
        },
      ),
    );
  };

  const validateReportApi = payload => {
    dispatch(
      getValidateReportRequest(
        {
          type: REPORT_TYPE.bundles,
          id: paramsX?.id,
          changeBy: payload?.changeBy,
          changeType: payload?.changeType,
        },
        rs => {
          console.log('getValidateReport>>>response', rs);
          getReportBundleFormApi(rs?.data?.count_testitems);
        },
        err => {},
      ),
    );
  };

  useEffect(() => {
    if (Config.IsDebug) {
      return;
    }
    loginApi();
  }, [dispatch]);

  useEffect(() => {
    const x = _.cloneDeep(getReportBundleFormData);
    const rd = mappingStep(!_.isEmpty(x) ? x[0] : null);
    setData(rd);
    console.log('getReportBundleFormData>>>', rd);
  }, [getReportBundleFormData]);

  return {
    data,
    headerTitle,
    validateReportData,
  };
};
