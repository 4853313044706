import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppKAView, AppSafeAreaView, AppText } from '@components';
import GenericHeader from '@components/GenericHeader';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MyTabBar } from '@routes/BottomTab/MyTabBar.view';
import { DashboardStackScreen, FAQStackScreen, MenuStackScreen } from '@routes/BottomTab/Stacks';
import { goBack } from '@routes/navigationUtils';
import { DASHBOARD_TAB, FAQ_TAB, MENU_TAB, REPORT_TAB, SCAN_QR_TAB, Screens } from '@routes/route';
import { AddReportScreen } from '@screens/main/report/AddReport';
import { ListReportScreen } from '@screens/main/report/ListReport';
import { TabBar } from '@screens/main/report/Tab/ReportTab.view';
import { AddTicketScreen } from '@screens/main/ticket/AddTicket';
import { ListTicketScreen } from '@screens/main/ticket/ListTicket';
import { TicketFilterView } from '@screens/main/ticket/ListTicket/components/TicketFilter.view';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font } from '@theme/size';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Text } from 'react-native';

const Tab = createMaterialTopTabNavigator();

export const TicketTab = props => {
  const { t } = useTranslation();
  return (
    <Tab.Navigator
      swipeEnabled={false}
      initialRouteName={props.initialRouteName}
      tabBar={props => <TabBar {...props} />}>
      <Tab.Screen
        options={{
          headerShown: false,
        }}
        name={Screens.ADD_TICKET_SCREEN}
        component={AddTicketScreen}
        initialParams={props.initialParams}
      />
      <Tab.Screen
        options={{
          headerShown: false,
        }}
        name={Screens.LIST_TICKET_SCREEN}
        component={ListTicketScreen}
        initialParams={props.initialParams}
      />
    </Tab.Navigator>
  );
};

TicketTab.propTypes = {
  initialRouteName: PropTypes.string,
  initialParams: PropTypes.object,
};

TicketTab.defaultProps = {
  initialRouteName: Screens.ADD_TICKET_SCREEN,
  initialParams: {},
};
