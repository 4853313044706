import { ClearIcon, LogoIcon, MessageCheckIcon } from '@assets';
import { AppEmptyView, AppKAView, AppKAViewV2, AppSafeAreaView, AppText } from '@components';
import GenericHeader from '@components/GenericHeader';
import { ADDRESS_TYPE } from '@constant';
import { goBack } from '@routes/navigationUtils';
import { SearchAddressStyle } from '@screens/main/SearchAddress/SearchAddress.style';
import { AddressBundleItem, AddressItem, AppLoadingMore } from '@screens/main/SearchPostcode';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import { AppHtmlRadioStyle } from '@screens/register/RegisterForm/components/AppHtmlRadio.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font } from '@theme/size';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, StatusBar, TextInput, TouchableOpacity, FlatList } from 'react-native';
import { Portal } from 'react-native-portalize';
import RenderHtml from 'react-native-render-html';
import { useAddressContainer } from './AddressContainer.hook';
import { AddressContainerStyle } from './AddressContainer.style';

export const AddressContainerView = props => {
  const { t } = useTranslation();
  const {} = useAddressContainer(props);
  const {
    onSearch,
    data,
    onRefresh,
    isRefreshing,
    loadingMore,
    loading,
    onLoadMore,
    onTextChanged,
    searchText,
    descriptionText,
    headerText,
    onItemPress,
  } = props;
  console.log('AddressContainerView>>>', descriptionText);
  const renderItem = ({ item, index }) => {
    switch (props.type) {
      case ADDRESS_TYPE.normal:
        return (
          <AddressItem
            data={item}
            isLast={index === data.length - 1}
            isSelected={false}
            onPress={() => onItemPress(item)}
          />
        );
      case ADDRESS_TYPE.bundle:
      case ADDRESS_TYPE.nearest:
        return (
          <AddressBundleItem
            data={item}
            isLast={index === data.length - 1}
            isSelected={false}
            onPress={() => onItemPress(item)}
          />
        );
      default:
        return (
          <AddressItem
            data={item}
            isLast={index === data.length - 1}
            isSelected={false}
            onPress={() => onItemPress(item)}
          />
        );
    }
  };

  const renderFooterLoading = () => {
    return <AppLoadingMore loadingMore={loadingMore} />;
  };

  const renderEmpty = () => {
    return <AppEmptyView emptyText={props.emptyText} />;
  };

  const renderMainContent = () => {
    const styles = props.isFullScreen
      ? [globalStyle.directionColumm, globalStyle.flex1, RegisterFormStyles.containerView]
      : [globalStyle.directionColumm, { height: '100%' }];
    return (
      <View style={styles}>
        {!!descriptionText && (
          <RenderHtml
            defaultTextProps={{ allowFontScaling: true }}
            source={{ html: descriptionText }}
            baseStyle={AddressContainerStyle.textTitle}
            renderersProps={{
              a: {
                onPress: (evt, href, htmlAttribs, target) => {
                  console.log('a>>>press>>>', href);
                  if (props.onClickHref) {
                    props.onClickHref(href);
                  }
                },
              },
            }}
          />
        )}
        {props.showSearch && (
          <View style={[globalStyle.directionRow, SearchAddressStyle.searchContainer]}>
            <TextInput
              value={searchText}
              style={[SearchAddressStyle.searchView]}
              onChangeText={txt => onTextChanged(txt)}
              placeholder={props?.placeHolder}
              allowFontScaling={true}
              autoCapitalize={props.isUpperCase ? 'characters' : ''}
            />
            {loading && <AppLoadingMore loadingMore={loading} />}
            <TouchableOpacity
              onPress={() => {
                onTextChanged('');
              }}
              style={{ marginRight: 12 }}>
              {<ClearIcon />}
            </TouchableOpacity>
          </View>
        )}
        <FlatList
          style={[SearchAddressStyle.listView]}
          // contentContainerStyle={[SearchAddressStyle.listView]}
          data={data}
          renderItem={renderItem}
          onRefresh={() => onRefresh()}
          refreshing={isRefreshing}
          ListEmptyComponent={renderEmpty}
          // onEndReached={!loading ? onLoadMore : null}
          onEndReachedThreshold={0.5}
          keyExtractor={(item, index) => index}
          ListFooterComponent={renderFooterLoading}
        />
      </View>
    );
  };

  return props.isFullScreen ? (
    <>
      <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
      <AppSafeAreaView areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>
        <GenericHeader onBackClicked={goBack} title={headerText} style={[{ backgroundColor: '#EDEDED' }]} />
        {renderMainContent()}
      </AppSafeAreaView>
    </>
  ) : (
    <AppSafeAreaView>
      <AppKAViewV2 areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>{renderMainContent()}</AppKAViewV2>
    </AppSafeAreaView>
  );
};

AddressContainerView.propTypes = {
  onSearch: PropTypes.func,
  data: PropTypes.array,
  onRefresh: PropTypes.func,
  isRefreshing: PropTypes.bool,
  loadingMore: PropTypes.bool,
  loading: PropTypes.bool,
  onLoadMore: PropTypes.func,
  onTextChanged: PropTypes.func,
  searchText: PropTypes.string,
  headerText: PropTypes.string,
  descriptionText: PropTypes.string,
  emptyText: PropTypes.string,
  onItemPress: PropTypes.func,
  dispatch: PropTypes.any,
  isFullScreen: PropTypes.bool,
  postCode: PropTypes.string,
  onClickHref: PropTypes.func,
  type: PropTypes.string,
  showSearch: PropTypes.bool,
  placeHolder: PropTypes.string,
  isUpperCase: PropTypes.bool,
  // onSelect:
};

AddressContainerView.defaultProps = {
  emptyText: 'No Data',
  isFullScreen: true,
  type: ADDRESS_TYPE.normal,
  showSearch: true,
  placeHolder: '',
  isUpperCase: false,
};
