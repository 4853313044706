import theme from '@theme';
import { palette } from '@theme/colors';
import { Font, FontSize, Spacing } from '@theme/size';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  areaStyle: { backgroundColor: '#EDEDED' },
  container: {
    paddingHorizontal: Spacing.width15,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    marginTop: Spacing.height38,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  logo: {
    marginBottom: Spacing.height38,
  },
  rememberMe: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  highlightText: {
    color: palette.ROYAL_MAIL_RED,
    fontSize: FontSize.Font14,
    marginLeft: Spacing.width4,
    paddingVertical: Spacing.height10,
  },
  signUpBox: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: Spacing.height14,
  },
  signUpText: {
    color: '#666666',
    fontSize: FontSize.Font15,
  },
  mb20: {
    marginBottom: Spacing.height20,
  },
  mb10: {
    marginBottom: Spacing.height10,
  },
});

export const NotificationItemStyle = StyleSheet.create({
  containerView: {
    borderTopRightRadius: theme.spacing.lg,
    borderTopLeftRadius: theme.spacing.lg,
    backgroundColor: palette.WHITE,
    width: '100%',
    flex: 1,
    // paddingHorizontal: theme.spacing.md,
  },
  viewContainer: {
    marginHorizontal: theme.spacing.md,
    // backgroundColor: palette.CARROT,
  },
  viewSelect: {
    borderBottomWidth: 1,
    borderColor: '#C6C6C6',
    marginHorizontal: 0,
    paddingBottom: 20,
    borderRadius: 4,
    width: '100%',
    marginBottom: theme.spacing.md,
    // marginLeft: theme.spacing.md,
    // marginRight: theme.spacing.md,
  },
  viewUnSelect: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#C6C6C6',
    marginHorizontal: 0,
    backgroundColor: '#F4F4F4',
    padding: 12,
    borderRadius: 4,
    width: '100%',
    marginBottom: theme.spacing.md,
  },
  itemTitle: {
    // color: '#666666',
  },
  itemText: {
    marginTop: 4,
    fontSize: FontSize.Font13,
    color: '#666666',
  },
});
