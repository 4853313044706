import { ClearIcon, EyeActiveIcon, EyeInactiveIcon, LogoIcon, MessageCheckIcon } from '@assets';
import { AppKAView, AppSafeAreaView, AppText } from '@components';
import GenericHeader from '@components/GenericHeader';
import { goBack } from '@routes/navigationUtils';
import { AddressContainerView } from '@screens/main/SearchAddress/components/AddressContainer.view';
import { AddressItem, AppEmptyView, AppLoadingMore } from '@screens/main/SearchPostcode';
import { useSearchPostcode } from '@screens/main/SearchPostcode/SearchPostcode.hook';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font } from '@theme/size';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Text, TextInput, TouchableOpacity, ScrollView, FlatList } from 'react-native';
import { useSearchAddress } from './SearchAddress.hook';
import { SearchAddressStyle } from './SearchAddress.style';
export const SearchAddressScreen = props => {
  const { t } = useTranslation();
  // const { onSearch } = useSearchAddress(props);
  const {
    onSearch,
    data,
    onRefresh,
    isRefreshing,
    loadingMore,
    loading,
    onLoadMore,
    onTextChanged,
    searchText,
    onItemPress,
  } = useSearchAddress(props);
  return (
    <AddressContainerView
      data={data}
      loadingMore={loadingMore}
      onTextChanged={onTextChanged}
      headerText={t('ADDRESS_HEADER')}
      isRefreshing={isRefreshing}
      loading={loading}
      onLoadMore={onLoadMore}
      emptyText={loading ? t('SEARCHING'): t('NO_ADDRESS_FOUND')}
      onRefresh={onRefresh}
      onSearch={onSearch}
      searchText={searchText}
      descriptionText={t('ADDRESS_DESCRIPTION')}
      onItemPress={onItemPress}
    />
  );
};
