import theme from '@theme';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { deviceHeight, deviceWidth, FontSize, Spacing } from '@theme/size';
import { Platform, StyleSheet } from 'react-native';

const overlayColor = '#00000050'; // this gives us a black color with a 50% transparency

const rectDimensions = deviceWidth * 0.65; // this is equivalent to 255 from a 393 device width: ;
const rectBorderColor = '#fff';

const scanBarWidth = deviceWidth * 0.46; // this is equivalent to 180 from a 393 device width
const scanBarHeight = deviceWidth * 0.0025; //this is equivalent to 1 from a 393 device width
const scanBarColor = 'red';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#000000',
  },
  form: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  closeBtn: {
    position: 'absolute',
    top: Spacing.width20,
    right: Spacing.width20,
    backgroundColor: '#c9c9c9',
    zIndex: 1,
    padding: Spacing.width6,
    borderRadius: 1000,
  },
  camera: {
    width: Device.width,
    height: Device.height,
  },
  rectangleContainer: {
    // flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    // flexDirection: 'column',
    // position: Platform.OS === 'web' ? 'relative' : undefined,

    // backgroundColor: 'red',
  },

  rectangle: {
    height: rectDimensions,
    width: rectDimensions,
    // borderWidth: rectBorderWidth,
    borderColor: rectBorderColor,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    position: 'relative',
    zIndex: 1,
  },

  topOverlay: {
    flex: 1,
    height: deviceWidth,
    // flex: 1,
    width: deviceWidth,
    backgroundColor: overlayColor,
    // backgroundColor: 'red',

    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  bottomOverlay: {
    position: 'relative',
    zIndex: Device.isIos ? -1 : 0,
    flex: 1,
    height: deviceWidth,
    width: deviceWidth,
    backgroundColor: overlayColor,
    paddingBottom: deviceWidth * 0.25,
  },

  leftAndRightOverlay: {
    position: 'relative',
    zIndex: Device.isIos ? -1 : 0,
    height: deviceWidth * 0.65,
    width: deviceWidth,
    backgroundColor: overlayColor,
  },

  scanBar: {
    width: scanBarWidth,
    height: scanBarHeight,
    backgroundColor: scanBarColor,
  },
  textHowTo: {
    color: 'white',
    fontWeight: '400',
    fontSize: FontSize.Font14,
    // borderRadius: 8,
    // borderWidth: 1,
    // textTransform: 'capitalize',
  },
  viewHowTo: {
    height: 36,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 1000,
    borderColor: 'white',
    borderWidth: 1,
    flexDirection: 'row',
    paddingHorizontal: theme.spacing.md,
    opacity: 0.5,
  },
  containerHowTo: {
    // width: '50%',
    // paddingHorizontal: 20,
    backgroundColor: 'transparent',
    // borderRadius: 1000,
    position: 'absolute',
    // borderWidth: 1,
    // borderColor: '#C6C6C6',
    bottom: -150,
    overflow: 'hidden',
    // opacity: 0.5,
  },
  topScanText: {
    backgroundColor: 'transparent',
    // position: 'absolute',
    // top: -110,
    overflow: 'hidden',
    width: Device.width,
    marginBottom: theme.spacing.sm
  },
  topScanTextWeb: {
    backgroundColor: 'transparent',
    position: 'absolute',
    top: -110,
    overflow: 'hidden',
    width: Device.width,
  },
  textScanTop: {
    color: 'white',
    fontWeight: '400',
    fontSize: FontSize.Font16,
    alignContent: 'center',
    textAlign: 'center',
    opacity: 0.5,
    marginBottom: theme.spacing.md,
    paddingHorizontal: theme.spacing.lg,

  },

  bottomScanText: {
    backgroundColor: 'transparent',
    position: 'absolute',
    top: Device.isLargeDevice ? 300 : 280,
    overflow: 'hidden',
  },
  bottomScanTextWeb: {
    backgroundColor: 'transparent',
    position: 'absolute',
    bottom: -80,
    // top: Device.width*0.65,
    overflow: 'hidden',
  },
  textScanBottom: {
    color: 'white',
    fontWeight: '400',
    fontSize: FontSize.Font14,
    // alignContent: 'center',
    textAlign: 'center',
    // opacity: 0.5
  },
});
