import { AppText } from '@components';
import { palette } from '@theme/colors';
import { Spacing } from '@theme/size';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { useReportTab } from './Tab.hook';
import { ReportTabStyle } from './Tab.style';

export const TabBar = memo(props => {
  const { t } = useTranslation();
  const {} = useReportTab(props);
  const { onItemPress, selectedTab, tabs } = props;
  const activeColors = ['#9b2d32', '#df010e'];
  const inactiveColors = ['white', 'white'];
  const selectedIndex = tabs?.findIndex(tab => tab?.name === selectedTab?.name);
  // const listName = [t('POST_BOX'), t('POST_OFFICES')];
  return (
    <View style={{ flexDirection: 'row', paddingHorizontal: Spacing.width15, marginBottom: 'auto', marginTop: 16 }}>
      {tabs?.map((tab, index) => {
        return (
          <TouchableOpacity
            key={index}
            activeOpacity={1}
            onPress={() => {
              // console.log({ index });
              onItemPress(tab);
            }}
            style={[
              ReportTabStyle.button,
              index === tabs.length - 1 && ReportTabStyle.isLast,
              index === 0 && ReportTabStyle.isFirst,
              selectedIndex === index && index === tabs.length - 1 ? { borderLeftWidth: 0 } : {},
              selectedIndex === index && index === 0 ? { borderRightWidth: 0 } : {},
            ]}>
            <LinearGradient
              colors={selectedIndex === index ? activeColors : inactiveColors}
              style={[
                ReportTabStyle.linearGradient,
                index === tabs.length - 1 &&
                  !(selectedIndex === index) && {
                    borderTopRightRadius: 4,
                    borderBottomRightRadius: 4,
                  },
                index === 0 &&
                  !(selectedIndex === index) && {
                    borderTopLeftRadius: 4,
                    borderBottomLeftRadius: 4,
                  },
              ]}>
              <AppText style={[selectedIndex === index ? { color: palette.WHITE } : {}]}>{tab?.name}</AppText>
            </LinearGradient>
          </TouchableOpacity>
        );
      })}
    </View>
  );
});
