import theme from '@theme';
import { FontSize, Spacing } from '@theme/size';
import { StyleSheet } from 'react-native';

export const CommentStyle = StyleSheet.create({
  avatarText: {
    color: '#333333',
    fontSize: FontSize.Font16,
    fontWeight: '700',
  },
  dateText: {
    color: '#333333',
    fontSize: FontSize.Font16,
  },
  viewInternal: {
    backgroundColor: '#FDF2F3',
    borderRadius: 4,
    paddingHorizontal: theme.spacing.md,
    marginBottom: theme.spacing.md,
    marginTop: 4,
  },
  viewExternal: {
    backgroundColor: '#F5F5F5',
    borderRadius: 4,
    paddingHorizontal: theme.spacing.md,
    marginBottom: theme.spacing.md,
    marginTop: 4,
  },
  commentText: {
    color: '#333333',
    fontSize: FontSize.Font16,
    fontWeight: '400',
    // marginVertical: theme.spacing.sm
  },
  imageWrapper: {
    // paddingLeft: 10,
    // marginTop: theme.spacing.sm,
    flex: 1,
    // height:100,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  imageBox: {
    flex: 1,
    maxWidth: '32%',
    height: Spacing.width76,
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    // borderStyle: 'dotted',
    // borderWidth: 1,
    marginBottom: Spacing.width10,
    marginRight: Spacing.width10,
  },
  outermostItem: {
    marginRight: 0,
  },
  imageWrap: {
    borderStyle: 'solid',
    borderColor: '#D5D5D5',
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: 4,
  },
});
