import theme from '@theme';
import { StyleSheet } from 'react-native';
import {FontSize} from "@theme/size";

export const TicketFilterStyle = StyleSheet.create({
  viewSelect: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#C6C6C6',
    marginHorizontal: 0,
    backgroundColor: '#F5F5F5',
    padding: 12,
    borderRadius: 4,
    width: '100%',
    marginBottom: theme.spacing.md,
    // marginLeft: theme.spacing.md,
    // marginRight: theme.spacing.md,
  },
  viewUnSelect: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#C6C6C6',
    marginHorizontal: 0,
    // backgroundColor: '#F4F4F4',
    padding: 12,
    borderRadius: 4,
    width: '100%',
    marginBottom: theme.spacing.md,
  },
  itemText: {
    paddingHorizontal: theme.spacing.sm,
    color: '#333333',
    fontSize: FontSize.Font16,
  },
});
