import { createAction, createSlice } from '@reduxjs/toolkit';

export interface IgetReimbursementState {
  loading: boolean;
  payload: any;
  error: any;
  refreshToken: any;
}

// @ts-ignore
const initialState: IgetReimbursementState = {
  loading: false,
  payload: null,
  error: null,
  refreshToken: null,
};

export const getReimbursementRequest = createAction(
  'getReimbursement/getReimbursementRequest',
  (payload, onSuccess, onFailed) => {
    console.log('prepareAction>>>', payload, onSuccess);
    return {
      payload: payload,
      meta: onSuccess,
      error: onFailed,
    };
  },
);

const getReimbursementSlice = createSlice({
  name: 'getReimbursement',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // getReimbursementRequest(state, action) {
    //   state.loading = true;
    //   // @ts-ignore
    //   state.payload = null;
    //   state.error = null;
    // },
    getReimbursementSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    getReimbursementFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getReimbursementSuccess, getReimbursementFailed } = getReimbursementSlice.actions;

export default getReimbursementSlice.reducer;
