import { AppText } from '@components';
import { globalStyle } from '@theme';
import { withLoading } from '@utils';
import _ from 'lodash';
import moment from 'moment';
import React, { memo } from 'react';
import { View } from 'react-native';
import { useLogic } from './Details.hook';
import { styles } from './Details.style';
import { t } from 'i18next';

const Content = ({ data, t }) => {
  const today = moment().format('ddd');
  const findData = _.find(data?.collection_times, item => item?.day === today);
  const formattedData = { day: moment(findData?.day, 'ddd').format('dddd'), time: findData?.time };
  return (
    <>
      <View style={styles.top}>
          <AppText style={styles.titleTop}>{data?.title}</AppText>
          <AppText style={styles.text}>{data?.description}</AppText>
        {!!data?.distance && <AppText style={styles.text}>{data?.distance}</AppText>}
        {/* <AppText style={[styles.text, styles.bold, styles.textRed]}>
          Closed now <AppText style={[styles.text, styles.bold]}>18:00</AppText>
        </AppText> */}
      </View>
      <View style={styles.bottom}>
        <AppText style={styles.titleBottom}>{t('LAST_COLLECTION_TIMES')}</AppText>
        <View style={styles.timeWrap}>
          <AppText style={[styles.text, globalStyle.flex1]}>{formattedData?.day}</AppText>
          <AppText style={styles.text}>{formattedData?.time}</AppText>
        </View>
      </View>
    </>
  );
};

const ContentWithLoading = withLoading(Content);

export const Details = memo(props => {
  const { detail, loading, t } = useLogic(props);
  return (
    <View style={styles.container}>
      <ContentWithLoading loading={loading || !detail} data={detail} t={t} />
    </View>
  );
});
