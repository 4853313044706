import { CommonActions, useNavigation } from '@react-navigation/native';
import { getListenDeepLink } from '@redux/app/appSlice';
import { isFirstTimeInApp as isFirstTimeInAppSelector } from '@redux/app/selectors';
import { navigatePush, navigateReset, reset } from '@routes/navigationUtils';
import { FORGOT_SEND_EMAIL, LOGIN_SCREEN, Screens, SIGN_UP_WITH_NAME_SCREEN } from '@routes/route';
import { deviceHeight, deviceWidth } from '@theme/size';
import { useEffect, useRef, useState } from 'react';
import { Animated, Easing } from 'react-native';
import SplashScreen from 'react-native-splash-screen';
import { useDispatch, useSelector } from 'react-redux';
import { Platform } from 'react-native';

export const useFirstSplash = props => {
  const dispatch = useDispatch();
  const slideAnim = useRef(new Animated.Value(0)).current;
  const fadeAnim = useRef(new Animated.Value(1)).current;
  const navigation = useNavigation();
  const isFirstTimeInApp = useSelector(isFirstTimeInAppSelector);
  const fadeIn = () => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 500,
      useNativeDriver: true,
      easing: Easing.in(),
    }).start(() => {});
  };
  const slideBottom = () => {
    // Will change fadeAnim value to 1 in 5 seconds
    Animated.timing(slideAnim, {
      toValue: 50,
      duration: 1000,
      useNativeDriver: true,
      easing: Easing.in(),
    }).start(() => {
      fadeIn();
      slideTop();
    });
  };

  const slideTop = () => {
    // Will change fadeAnim value to 0 in 3 seconds
    Animated.timing(slideAnim, {
      // toValue: -(deviceHeight - (deviceWidth * 285) / 1125),
      toValue: -deviceHeight,

      duration: 500,
      useNativeDriver: true,
      easing: Easing.in(),
    }).start(() => {
      // navigatePush(LOGIN_SCREEN);
      // navigateReset(LOGIN_SCREEN)
      // reset(0, [{ name: LOGIN_SCREEN }]);
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [
            {
              name: isFirstTimeInApp ? SIGN_UP_WITH_NAME_SCREEN + 'first' : LOGIN_SCREEN,
            },
          ],
        }),
      );
    });
  };
  useEffect(() => {
    // Hide Splashscreen when Fallback get willUnmount
    setTimeout(() => {
      if (Platform.OS !== 'web') {
        SplashScreen.hide();
      }
      setTimeout(() => {
        slideBottom();
      }, 300);
      //TODO: animate here.
    }, 1000);
  }, []);
  return { slideAnim, fadeAnim };
};
