import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { Font, FontSize, Spacing } from '@theme/size';
import { AppSetting } from '@utils/common/setting';
import { StyleSheet, Platform } from 'react-native';

export const styles = StyleSheet.create({
  areaStyle: {
    // backgroundColor: '#CE232C'
    backgroundColor: AppSetting.state?.colors?.DASHBOARD_BONUS_BANNER,
  },
  header: { backgroundColor: AppSetting.state?.colors?.ROYAL_MAIL_RED, paddingTop: Platform.OS === 'web' ? 10 : Spacing.height44, marginLeft: -9 },
  container: {
    borderTopRightRadius: Spacing.width20,
    borderTopLeftRadius: Spacing.width20,
    paddingHorizontal: Platform.OS === 'web' ? Spacing.width4 : Spacing.width16,
    paddingBottom: Platform.OS === 'web' ? Spacing.width4 : Spacing.width16,
    borderColor: 'transparent',
    flex: 1,
    paddingTop: Platform.OS === 'web' ? Spacing.width8 : undefined,
  },
  kaView: {
    backgroundColor: 'white',
    borderTopRightRadius: Spacing.width20,
    borderTopLeftRadius: Spacing.width20,
  },
  pointBox: {
    paddingVertical: Platform.OS === 'web' ? '20px' : Spacing.width12,
    paddingHorizontal: Spacing.width20,
  },
  pointText: {
    ...Font.Bold_400,
    marginBottom: Spacing.height2,
    color: AppSetting.state?.colors?.DASHBOARD_TEXT,
    fontSize: FontSize.Font15,
    flex: 1,
  },
  pointDesc: {
    color: `${AppSetting.state?.colors?.DASHBOARD_TEXT}50`,
  },
  point: {
    ...Font.Bold_700,
    color: `${AppSetting.state?.colors?.DASHBOARD_TEXT}`,
    fontSize: FontSize.Font30,
    paddingLeft: 10,
    // flex: 1,
  },
  textPoint: {
    // ...Font.,
    color: `${AppSetting.state?.colors?.DASHBOARD_TEXT}`,
    fontSize: FontSize.Font15,
    bottom: -4,
    alignItems: 'baseline',
    paddingBottom: -12,
  },
  fakePadding: {
    flex: 1,
    paddingTop: 15,
    borderTopRightRadius: Spacing.width20,
    borderTopLeftRadius: Spacing.width20,
    //
  },
  lowerText: {
    flexDirection: 'row',
  },
  statusDashboardView: {
    borderRadius: 12,
    borderWidth: 3,
    borderColor: 'white',
    height: 20,
    width: 20,
    marginLeft: 24,
    // marginTop:10,
    // left: -40,
    // bottom: 2,
    // position: 'absolute',
  },
  markerDashboardView: {
    // borderRadius:12,
    // borderWidth:3,
    // borderColor:'white',
    // height:16,
    // width:16,
    left: -70,
    // bottom: 2,
    position: 'absolute',
  },
  notificationDashboardView: {
    flexDirection: 'row',
    position: 'absolute',
    flex: 0,
    // right: -20,
    left: 10,
    top: 8,
    // bottom: 2,

    paddingHorizontal: Platform.OS === 'web' ? 0 : Spacing.width16,
    // backgroundColor: 'green'
  },
});
