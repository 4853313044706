import { MessageCheckIcon } from '@assets';
import { AppSafeAreaView, AppText } from '@components';
import { AppForm } from '@components/AppForm';
import { FlexImageIcon } from '@screens/main/Menu/Components';
import { AppNoteView } from '@screens/main/quiz/QuizFailed/components/AppNote.view';
import theme, { globalStyle } from '@theme';
import { palette } from '@theme/colors';
import { Font, FontSize, Spacing } from '@theme/size';
import PropTypes from 'prop-types';
import React from 'react';
import { Text, View } from 'react-native';
import {AppSuccessScreenStyle} from "@components/AppSuccessScreen/AppSuccessScreen.style";

export const AppSuccessScreen = props => {
  const { alert, description, icon } = props;
  console.log('AppSuccessScreen>>>', props);
  return (
    <View style={[globalStyle.flex1, globalStyle.center]}>
      {/*<Icon name="comment-dots" size={40} color={palette.ROYAL_MAIL_RED} />*/}
      {/*<MessageCheckIcon color={palette.GREEN_ACTIVE} />*/}
            <View
            >
                <FlexImageIcon
                    icon={icon}
                    style={AppSuccessScreenStyle.iconView}
                />
            </View>

            <View
                style={AppSuccessScreenStyle.viewContent}>
                <AppNoteView
                    titleStyle={AppSuccessScreenStyle.descriptionText}
                    title={description}
                />
                {!!alert && (
                    <AppNoteView
                        titleStyle={AppSuccessScreenStyle.alertText}
                        title={alert}
                    />
                )}
            </View>

    </View>
  );
};

AppSuccessScreen.propTypes = {
  formik: PropTypes.any,
  layoutInfo: PropTypes.any,
  setLayoutInfo: PropTypes.any,
  scrollToY: PropTypes.func,
  scrollViewRef: PropTypes.any,
  attributes: PropTypes.array,
  // title: PropTypes.string,
  // description: PropTypes.string,
  buttonText: PropTypes.string,
  userLocation: PropTypes.object,
  subFormRef: PropTypes.any,
  itemStyle: PropTypes.any,
  icon: PropTypes.string,
  description: PropTypes.string,
  alert: PropTypes.string,
};

AppSuccessScreen.defaultProps = {
  buttonText: 'buttonText',
  itemStyle: [],
};
