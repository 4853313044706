import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppButton, AppKAView, AppSafeAreaView, AppText } from '@components';
import { AppForm } from '@components/AppForm';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Text } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useTransponderForm } from './TransponderForm.hook';
import { TransponderFormStyle } from './TransponderForm.style';

export const TransponderFormScreen = props => {
  const { t } = useTranslation();
  const { formik, data, scrollToY,
    scrollViewRef, layoutInfo, setLayoutInfo, userLocation,
    subFormRef,
    messageInfo,
    isSuccess
  } = useTransponderForm(props);
  return (
    <AppForm
      title={data?.title}
      description={data?.description}
      scrollViewRef={scrollViewRef}
      scrollToY={scrollToY}
      formik={formik}
      attributes={data?.attributes}
      setLayoutInfo={setLayoutInfo}
      layoutInfo={layoutInfo}
      buttonText={t('CONFIRM')}
      userLocation={userLocation}
      subFormRef={subFormRef}
      messageInfo={messageInfo}
      isSuccess={isSuccess}
    />
  );
};
