import { palette } from '@theme/colors';
import { deviceWidth, deviceHeight, statusHeight } from '@theme/size';
import { Dimensions, StyleSheet } from 'react-native';

const { height } = Dimensions.get('screen');
console.log('height>>>>', height, deviceHeight, statusHeight)

export const SplashStyles = StyleSheet.create({
  // branding: {
  // position: 'absolute',
  //   width: deviceWidth,
  //   resizeMode: 'contain',
  //   bottom: 0,
  //   height: (deviceWidth * 285) / 1125,
  // },

  branding2: {
    width: deviceWidth,
    resizeMode: 'contain',
    // bottom: 0,
    height: (deviceWidth * 285) / 1125,
  },
  splash: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
    transform: [{ scale: 1.1 }],
  },
  viewContainer: {
    position: 'absolute',
    width: deviceWidth,
    height: deviceHeight + 100,
    // top: deviceHeight * (1125 - 285)/1125,
    top: deviceHeight - (deviceWidth * 285) / 1125,
    // bottom: -(height - (deviceWidth * 285) / 1125),

  },
  viewWhite: {
    width: '100%',
    flex: 1,
    backgroundColor: palette.WHITE,
  },
});
