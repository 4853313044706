export const FormMockData = {
  1: {
    title: 'Delivery Obstacles',
    step: '1',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
    attributes: [
      {
        id: 'formID',
        name: 'form',
        type: 'hidden',
        formID: '1',  
      },
      {
        id: 'cp11',
        name: 'cp11',
        type: 'MultipleSelection',
        required: 'Y',
        description: 'some text',
        label: {
          ES: 'spanish label',
          DE: 'german label',
          EN: 'english label',
        },
        error_msg: {
          ES: 'spanish error',
          DE: 'german error',
          EN: 'english error',
        },
        regex: '',
        maxlength: 0,
        default: '',
        conditions: '',
        data: [
          { id: '1', content: 'Key necessary' },
          { id: '2', content: 'Advertisement unwanted' },
          { id: '3', content: 'Howse of main street' },
          { id: '4', content: 'Main drop off point within the house' },
          { id: '5', content: 'Further hinderings' },
        ],
      },
    ],
  },
  2: {
    title: 'Address information',
    step: '2',
    description: 'Please enter your name and your postal address. We will send test letters to this address.',
    attributes: [
      {
        id: 'formID',
        name: 'form',
        type: 'hidden',
        formID: '1',
      },
      {
        id: 'cp21',
        name: 'title',
        type: 'input',
        required: 'Y',
        description: 'some text',
        label: {
          ES: 'spanish label',
          DE: 'german label',
          EN: 'Title',
        },
        error_msg: {
          ES: 'spanish error',
          DE: 'german error',
          EN: 'english error',
        },
        regex: '',
        maxlength: 0,
        default: '',
        conditions: '',
      },
      {
        id: 'cp22',
        name: 'firstname',
        type: 'input',
        required: 'Y',
        description: 'some text',
        label: {
          ES: 'spanish label',
          DE: 'german label',
          EN: 'Firstname',
        },
        error_msg: {
          ES: 'spanish error',
          DE: 'german error',
          EN: 'english error',
        },
        regex: '',
        maxlength: 0,
        default: '',
        conditions: '',
      },
      {
        id: 'cp23',
        name: 'lastname',
        type: 'input',
        required: 'Y',
        description: 'some text',
        label: {
          ES: 'spanish label',
          DE: 'german label',
          EN: 'Lastname',
        },
        error_msg: {
          ES: 'spanish error',
          DE: 'german error',
          EN: 'english error',
        },
        regex: '',
        maxlength: 0,
        default: '',
        conditions: '',
      },
      {
        id: 'cp24',
        name: 'Street and Housenumber',
        type: 'input',
        required: 'Y',
        description: 'some text',
        label: {
          ES: 'spanish label',
          DE: 'german label',
          EN: 'Street and Housenumber',
        },
        error_msg: {
          ES: 'spanish error',
          DE: 'german error',
          EN: 'english error',
        },
        regex: '',
        maxlength: 0,
        default: '',
        conditions: '',
      },
      {
        id: 'cp25',
        name: 'Postacode, City',
        type: 'input',
        required: 'Y',
        description: 'some text',
        label: {
          ES: 'spanish label',
          DE: 'german label',
          EN: 'Postacode, City',
        },
        error_msg: {
          ES: 'spanish error',
          DE: 'german error',
          EN: 'english error',
        },
        regex: '',
        maxlength: 0,
        default: '',
        conditions: '',
      },
      {
        id: 'cp26',
        name: 'Country',
        type: 'dropdown',
        required: 'Y',
        description: 'some text',
        label: {
          ES: 'spanish label',
          DE: 'german label',
          EN: 'Country',
        },
        error_msg: {
          ES: 'spanish error',
          DE: 'german error',
          EN: 'english error',
        },
        regex: '',
        maxlength: 0,
        default: '',
        conditions: '',
        data: [
          {
            id: '1',
            content: 'Germany',
          },
          {
            id: '2',
            content: 'Vietnam',
          },
          {
            id: '3',
            content: 'England',
          },
          {
            id: '4',
            content: 'France',
          },
          {
            id: '5',
            content: 'Spain',
          },
        ],
      },
      {
        id: 'cp27',
        name: 'Language',
        type: 'dropdown',
        required: 'Y',
        description: 'some text',
        label: {
          ES: 'spanish label',
          DE: 'german label',
          EN: 'Language',
        },
        error_msg: {
          ES: 'spanish error',
          DE: 'german error',
          EN: 'english error',
        },
        regex: '',
        maxlength: 0,
        default: '',
        conditions: '',
        data: [
          {
            id: '1',
            content: 'Germany',
          },
          {
            id: '2',
            content: 'Vietnam',
          },
          {
            id: '3',
            content: 'England',
          },
          {
            id: '4',
            content: 'France',
          },
          {
            id: '5',
            content: 'Spain',
          },
        ],
      },
      {
        id: 'cp28',
        name: 'phone',
        type: 'tel',
        required: 'Y',
        description: 'some text',
        label: {
          ES: 'spanish label',
          DE: 'german label',
          EN: 'Phone',
        },
        error_msg: {
          ES: 'spanish error',
          DE: 'german error',
          EN: 'english error',
        },
        regex: '^(\\+?\\d{0,4})?\\s?-?\\s?(\\(?\\d{3}\\)?)\\s?-?\\s?(\\(?\\d{3}\\)?)\\s?-?\\s?(\\(?\\d{4}\\)?)?$',

        maxlength: 0,
        default: '',
        conditions: '',
      },
    ],
  },
  3: {
    title: 'User Account',
    step: '3',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
    attributes: [
      {
        id: 'formID',
        name: 'form',
        type: 'hidden',
        formID: '1',
      },
      {
        id: 'cp31',
        name: 'Username',
        type: 'input',
        required: 'Y',
        description: 'some text',
        label: {
          ES: 'spanish label',
          DE: 'german label',
          EN: 'Username',
        },
        error_msg: {
          ES: 'spanish error',
          DE: 'german error',
          EN: 'english error',
        },
        regex: '',
        maxlength: 0,
        default: '',
        conditions: '',
      },
      {
        id: 'cp32',
        name: 'password',
        type: 'password',
        required: 'Y',
        description: 'some text',
        label: {
          ES: 'spanish label',
          DE: 'german label',
          EN: 'Password',
        },
        error_msg: {
          ES: 'spanish error',
          DE: 'german error',
          EN: 'english error',
        },
        regex: '',
        maxlength: 0,
        default: '',
        conditions: '',
      },
      {
        id: 'cp33',
        name: 'confirm_password',
        type: 'password',
        required: 'Y',
        description: 'some text',
        label: {
          ES: 'spanish label',
          DE: 'german label',
          EN: 'Confirm password',
        },
        error_msg: {
          ES: 'spanish error',
          DE: 'german error',
          EN: 'english error',
        },
        regex: '',
        maxlength: 0,
        default: '',
        conditions: '',
      },
      {
        id: 'cp34',
        name: 'email',
        type: 'email',
        required: 'Y',
        description: 'some text',
        label: {
          ES: 'spanish label',
          DE: 'german label',
          EN: 'Email',
        },
        error_msg: {
          ES: 'spanish error',
          DE: 'german error',
          EN: 'english error',
        },
        regex: '',
        maxlength: 0,
        default: '',
        conditions: '',
      },
    ],
  },
};
