import { createAction, createSlice } from '@reduxjs/toolkit';

export interface IdownloadReimbursementState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IdownloadReimbursementState = {
  loading: false,
  payload: null,
  error: null,
};

export const downloadReimbursementRequest = createAction(
  'downloadReimbursement/downloadReimbursementRequest',
  (payload, onSuccess, onFailed) => {
    console.log('prepareAction>>>', payload, onSuccess);
    return {
      payload: payload,
      meta: onSuccess,
      error: onFailed,
    };
  },
);

const downloadReimbursementSlice = createSlice({
  name: 'downloadReimbursement',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // downloadReimbursementRequest(state, action) {
    //   state.loading = true;
    //   // @ts-ignore
    //   state.payload = null;
    //   state.error = null;
    // },
    downloadReimbursementSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    downloadReimbursementFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { downloadReimbursementSuccess, downloadReimbursementFailed } = downloadReimbursementSlice.actions;

export default downloadReimbursementSlice.reducer;
