import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppKAView, AppSafeAreaView, AppText } from '@components';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { Font, FontSize } from '@theme/size';
import { HtmlUtil } from '@utils/common/html';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text } from 'react-native';
import RenderHtml from 'react-native-render-html';
import { useAppNote } from './AppNote.hook';
import { AppNoteStyle } from './AppNote.style';

export const AppHtmlView = ({
  text,
  style,
  isBoldTitle,
  data,
  structure,
  onClickHref,
  imagePadding = theme.spacing.md,
  tagStyle = {},
  numberOfLines = undefined,
  widthEstimate = undefined,
}) => {
  if (!text) {
    return <></>;
  }
  text = HtmlUtil.getFormatValue(text, data, structure);
  console.log('AppHtmlView>>>', text);
  if (HtmlUtil.isHtml(text)) {
    // eslint-disable-next-line no-undef

    return (
      <RenderHtml
        defaultTextProps={{ allowFontScaling: true, numberOfLines: numberOfLines }}
        source={{ html: text }}
        baseStyle={style ? style : { fontSize: FontSize.Font15 }}
        tagsStyles={{
          img: {
            resizeMode: 'cover',
            borderRadius: 4,
            // borderColor: palette.BACKGROUND_GREY,
            // borderWidth:1,
            width: Device.width - imagePadding * 2,
          },
          ...tagStyle,
        }}
        renderersProps={{
          a: {
            onPress: (evt, href, htmlAttribs, target) => {
              console.log('a>>>press>>>', href);
              if (onClickHref) {
                onClickHref(href);
              }
            },
          },
          img: {
            enableExperimentalPercentWidth: true,
          },
        }}
      />
    );
  }
  return (
    <AppText numberOfLines={numberOfLines} style={[style, isBoldTitle ? { fontWeight: '700' } : {}]}>
      {text}
    </AppText>
  );
};

export const AppNoteView = props => {
  const { t } = useTranslation();
  // const {} = useAppNote(props);
  const { title, hint, isRequired, style, isBoldTitle, titleStyle } = props;
  return (
    <View style={[AppNoteStyle.container, style]}>
      {/*{!!title && renderHtml(title, [AppNoteStyle.label, isBoldTitle ? { fontWeight: '700' } : {}])}*/}
      {/*{!!hint && renderHtml(hint, AppNoteStyle.hint)}*/}
      {!!title && (
        <AppHtmlView
          text={title}
          style={{ ...AppNoteStyle.label, ...titleStyle, ...(isBoldTitle ? { fontWeight: '700' } : {}) }}
          isBoldTitle={isBoldTitle}
          data={props.data}
          structure={props.structure}
          onClickHref={props.onClickHref}
        />
      )}
      {!!hint && <AppHtmlView text={hint} style={AppNoteStyle.hint} />}
    </View>
  );
};

AppNoteView.propTypes = {
  title: PropTypes.string,
  hint: PropTypes.string,
  style: PropTypes.any,
  isRequired: PropTypes.bool,
  isBoldTitle: PropTypes.bool,
  titleStyle: PropTypes.any,
  data: PropTypes.object,
  structure: PropTypes.array,
  onClickHref: PropTypes.func,
  imagePadding: PropTypes.number,
};

AppNoteView.defaultProps = {
  isRequired: false,
  isBoldTitle: false,
  style: [],
  structure: [],
};
