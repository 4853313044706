import { palette } from '@theme/colors';
import {Platform, StyleSheet} from 'react-native';
import { Device } from './device';

export const WEB_WIDTH = 810
const theme = {
  spacing: {
    sm: 8,
    ms: 10,
    md: 16,
    lg: 24,
  },
  colors: {
    statusBarBackground: palette.CONCRETE,
    headerBackground: palette.WHITE,
    mainBackground: palette.MIDNIGHT_BLUE,
    backButtonText: palette.BELIZE_HOLE,
    transparent: palette.TRANSPARENT,
    primary: palette.MIDNIGHT_BLUE,
    cardShadow: palette.GREY_SHADOW_7,
  },
  breakpoints: {},
  textVariants: {
    // ButtonText: {
    //
    //   lineHeight: 20,
    //   color: 'contrastText',
    // },
    // @TODO: to be defined
    // header: {
    //   fontFamily: fonts.boldOS,
    //   fontSize: 34,
    //   lineHeight: 42.5,
    //   color: palette.WET_ASPHALT,
    // },
    // subheader: {
    //   fontFamily: fonts.semiBoldOS,
    //   fontWeight: '600',
    //   fontSize: 28,
    //   lineHeight: 36,
    //   color: palette.WET_ASPHALT,
    // },
    // body: {
    //   fontFamily: fonts.regularOS,
    //   fontSize: 16,
    //   lineHeight: 24,
    //   color: palette.WET_ASPHALT,
    // },
  },
};

export const globalStyle = StyleSheet.create({
  width: {
    width: Platform.OS === 'web' ? WEB_WIDTH : undefined,
    margin: Platform.OS === 'web' ? 'auto' : undefined,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.15,
    shadowRadius: 15,
    elevation: 0,
  },
  webBg: {
    backgroundColor: Platform.OS === 'web' ? '#ce232c' : undefined,
    position: Platform.OS === 'web' ? 'absolute' : undefined,
    inset: Platform.OS === 'web' ? 0 : undefined,
    zIndex: Platform.OS === 'web' ? 0 : undefined,
  },
  directionRow: {
    flexDirection: 'row',
  },
  directionColumm: {
    flexDirection: 'column',
  },
  flex0: {
    flex: 0,
  },
  flex1: {
    flex: 1,
  },
  flexGrow1: {
    flexGrow: 1,
  },
  alignCenter: {
    alignItems: 'center',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  alignEnd: {
    alignItems: 'flex-end',
  },
  alignStart: {
    alignItems: 'flex-start',
  },
  justifySpaceBetween: {
    justifyContent: 'space-between',
  },
  alignSelfCenter: {
    alignSelf: 'center',
  },
  fullContainer: {
    backgroundColor: theme.colors.mainBackground,
    flex: 1,
    flexGrow: 1,
  },
  SafeAreaViewStyle: {
    height: Device.height,
    backgroundColor: theme.colors.headerBackground,
    flex: 1,
  },
  pageContainerStyle: {
    backgroundColor: theme.colors.mainBackground,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  fullWidth: {
    flex: 1,
    flexGrow: 1,
  },
});

export type Theme = typeof theme;
export default theme;
