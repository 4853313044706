import type { RootState } from '@redux/reducers';
import { createSelector } from '@reduxjs/toolkit';
import { getErrorMessage } from '@utils/api';

export const getReimbursementSelector = (state: RootState) => state.getReimbursement;
export const getReimbursementCreator = createSelector(getReimbursementSelector, state => state.payload);
export const getReimbursementLoading = createSelector(getReimbursementSelector, state => state.loading);
export const getReimbursementError = createSelector(getReimbursementSelector, state => state.error);
export const getReimbursementErrorMsg = createSelector(getReimbursementError, state => getErrorMessage(state));
export const getReimbursementRefreshToken = createSelector(getReimbursementSelector, state => state.refreshToken);

export const downloadReimbursementSelector = (state: RootState) => state.downloadReimbursement;
export const downloadReimbursementCreator = createSelector(downloadReimbursementSelector, state => state.payload);
export const downloadReimbursementLoading = createSelector(downloadReimbursementSelector, state => state.loading);
export const downloadReimbursementError = createSelector(downloadReimbursementSelector, state => state.error);
export const downloadReimbursementErrorMsg = createSelector(downloadReimbursementError, state => getErrorMessage(state));

