import { MessageCheckIcon, CrossIcon, WarningIcon } from '@assets';
import { AppAlert, AppText, AppButton } from '@components';
import { ForceUpdateStyles } from '@components/AppForceUpdate/ForceUpdate.style';
import { useGlobalSuccessModal } from '@components/GlobalSuccessModal/GlobalSuccessModal.hook';
import { PERMISSION_MODEL } from '@constant';
import { FlexImageIcon } from '@screens/main/Menu/Components';
import { AppNoteView } from '@screens/main/quiz/QuizFailed/components/AppNote.view';
import theme, { globalStyle } from '@theme';
import { deviceWidth, Font, FontSize, Spacing } from '@theme/size';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';

export const GlobalSuccessModal = props => {
  // const { show} = props;
  const { handleHideModal, data, onClose } = useGlobalSuccessModal(props);
  const { t } = useTranslation();
  const title = data?.title;
  const description = data?.description;
  const icon = data?.icon;

  return (
    <>
      {data?.visible && (
        <AppAlert
          onDismiss={handleHideModal}
          closeOnTouchOutside
          show={data?.visible}
          contentContainerStyle={{ maxWidth: '92%' }}
          customView={
            <View style={{ paddingHorizontal: 8 }}>
              <TouchableOpacity
                onPress={onClose}
                style={{
                  position: 'absolute',
                  top: 6,
                  right: 6,
                  backgroundColor: '#c9c9c9',
                  zIndex: 1,
                  padding: 6,
                  borderRadius: 1000,
                }}>
                <CrossIcon color="white" />
              </TouchableOpacity>
              {/*<View style={[globalStyle.directionColumm, { marginTop: 24 }]}>*/}
              {/*  <View style={[globalStyle.center]}>*/}
              {/*    <WarningIcon width={45} height={45} />*/}
              {/*  </View>*/}
              {/*  <View style={[globalStyle.center, { paddingVertical: 16 }]}>*/}
              {/*    <AppText style={[Font.Bold_700, globalStyle.center]}>{title}</AppText>*/}
              {/*    <AppText*/}
              {/*      style={[*/}
              {/*        {*/}
              {/*          fontSize: FontSize.Font14,*/}
              {/*          paddingVertical: 8,*/}
              {/*          textAlign: 'center',*/}
              {/*          color: '#666666',*/}
              {/*        },*/}
              {/*      ]}>*/}
              {/*      {description}*/}
              {/*    </AppText>*/}
              {/*  </View>*/}
              {/*</View>*/}
              <View style={[globalStyle.center,globalStyle.directionColumm, { marginTop: 24 }]}>
                <FlexImageIcon
                  icon={icon}
                  style={{
                    width: Spacing.width64,
                    height: Spacing.width64,
                  }}
                />
              </View>

              <View style={[globalStyle.center, { paddingVertical: 16 }]}>
                {!!title && (
                  <AppNoteView
                    titleStyle={{
                      fontSize: FontSize.Font15,
                      textAlign: 'center',
                      color: '#666666',
                      // marginTop: theme.spacing.md,
                      ...Font.Bold_700,
                      ...globalStyle.center,
                    }}
                    title={title}
                  />
                )}
                {!!description && (
                  <AppNoteView
                    titleStyle={{
                      fontSize: FontSize.Font15,
                      textAlign: 'center',
                      color: '#666666',
                      // marginTop: Spacing.height64,
                    }}
                    title={'description'}
                  />
                )}
              </View>
              <View style={[ForceUpdateStyles.row, { marginVertical: theme.spacing.md }]}>
                <AppButton
                  // style={ForceUpdateStyles.navigationButton}
                  onPress={onClose}
                  text={t('Ok')}
                />
              </View>
            </View>
          }
        />
      )}
    </>
  );
};

GlobalSuccessModal.propTypes = {};

GlobalSuccessModal.defaultProps = {};
