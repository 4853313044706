import { validateUserSaga } from '@redux/user/validateUserSaga';
import { validateUserRequest } from '@redux/user/validateUserSlice';
import { getSettingsSaga } from '@redux/user/getSettingsSaga';
import { getSettingsRequest } from '@redux/user/getSettingsSlice';
import { getUserSaga } from '@redux/user/getUserSaga';
import { getUserRequest } from '@redux/user/getUserSlice';
import { updateUserSaga } from '@redux/user/updateUserSaga';
import { updateUserRequest } from '@redux/user/updateUserSlice';
import { activeUserSaga } from '@redux/user/activeUserSaga';
import { activeUserRequest } from '@redux/user/activeUserSlice';
import { loginSaga } from '@redux/user/loginSaga';
import { loginRequest } from '@redux/user/loginSlice';
import { logoutSaga } from '@redux/user/logoutSaga';
import { logoutRequest } from '@redux/user/logoutSlice';
import { forgotPasswordSaga } from '@redux/user/forgotPasswordSaga';
import { forgotPasswordRequest } from '@redux/user/forgotPasswordSlice';
import { resetPasswordSaga } from '@redux/user/resetPasswordSaga';
import { resetPasswordRequest } from '@redux/user/resetPasswordSlice';
import { getProfileSaga } from '@redux/user/getProfileSaga';
import { getProfileRequest } from '@redux/user/getProfileSlice';
import { updateProfileSaga } from '@redux/user/updateProfileSaga';
import { updateProfileRequest } from '@redux/user/updateProfileSlice';
import { unlockSkillSaga } from '@redux/user/unlockSkillSaga';
import { unlockSkillRequest } from '@redux/user/unlockSkillSlice';
import { unsubscribeUserSaga } from '@redux/user/unsubscribeUserSaga';
import { unsubscribeUserRequest } from '@redux/user/unsubscribeUserSlice';
import { getBonusPointListSaga } from '@redux/user/getBonusPointListSaga';
import { getBonusPointListRequest } from '@redux/user/getBonusPointListSlice';
import { listVoucherSaga } from '@redux/user/listVoucherSaga';
import { listVoucherRequest } from '@redux/user/listVoucherSlice';
import { redeemVoucherSaga } from '@redux/user/redeemVoucherSaga';
import { redeemVoucherRequest } from '@redux/user/redeemVoucherSlice';
import { requestChangeEmailSaga } from '@redux/user/requestChangeEmailSaga';
import { requestChangeEmailRequest } from '@redux/user/requestChangeEmailSlice';
import { changeEmailSaga } from '@redux/user/changeEmailSaga';
import { changeEmailRequest } from '@redux/user/changeEmailSlice';
import { checkUnsubscribeConditionsSaga } from '@redux/user/checkUnsubscribeConditionsSaga';
import { checkUnsubscribeConditionsRequest } from '@redux/user/checkUnsubscribeConditionsSlice';
import { acceptUserCdmSaga } from '@redux/user/acceptUserCdmSaga';
import { acceptUserCdmRequest } from '@redux/user/acceptUserCdmSlice';
import { ForkEffect, takeLatest } from 'redux-saga/effects';

function* userSagas(): Generator<ForkEffect<never>, void> {
  yield takeLatest(validateUserRequest.type, validateUserSaga);
  yield takeLatest(getSettingsRequest.type, getSettingsSaga);
  yield takeLatest(getUserRequest.type, getUserSaga);
  yield takeLatest(updateUserRequest.type, updateUserSaga);
  yield takeLatest(activeUserRequest.type, activeUserSaga);
  // yield takeLatest(loginRequest.type, loginSaga);
  yield takeLatest(logoutRequest.type, logoutSaga);
  yield takeLatest(forgotPasswordRequest.type, forgotPasswordSaga);
  yield takeLatest(resetPasswordRequest.type, resetPasswordSaga);
  yield takeLatest(getProfileRequest.type, getProfileSaga);
  yield takeLatest(updateProfileRequest.type, updateProfileSaga);
  yield takeLatest(unlockSkillRequest.type, unlockSkillSaga);
  yield takeLatest(unsubscribeUserRequest.type, unsubscribeUserSaga);
  yield takeLatest(getBonusPointListRequest.type, getBonusPointListSaga);
  yield takeLatest(listVoucherRequest.type, listVoucherSaga);
  yield takeLatest(redeemVoucherRequest.type, redeemVoucherSaga);
  yield takeLatest(requestChangeEmailRequest.type, requestChangeEmailSaga);
  yield takeLatest(changeEmailRequest.type, changeEmailSaga);
  yield takeLatest(checkUnsubscribeConditionsRequest.type, checkUnsubscribeConditionsSaga);
  yield takeLatest(acceptUserCdmRequest.type, acceptUserCdmSaga);
}

export default userSagas;
