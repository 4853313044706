import { CirclePlusIcon, ClearIcon, LogoIcon, MessageCheckIcon, SentIcon } from '@assets';
import { AppInput, AppKAView, AppKAViewV2, AppSafeAreaView, AppText } from '@components';
import { useAppImagePicker } from '@components/AppImagePicker/AppImagePicker.hook';
import GenericHeader from '@components/GenericHeader';
import ReadMore from '@fawazahmed/react-native-read-more';
import { goBack } from '@routes/navigationUtils';
import { SearchAddressStyle } from '@screens/main/SearchAddress/SearchAddress.style';
import { styles } from '@screens/main/profile/ProfileEdit/ProfileEdit.style';
import { TicketItemStyles } from '@screens/main/ticket/ListTicket/components/TicketItem.style';
import { StatusView } from '@screens/main/ticket/ListTicket/components/TicketItem.view';
import { CommentStyle } from '@screens/main/ticket/TicketDetail/components/Comment.style';
import { CommentView } from '@screens/main/ticket/TicketDetail/components/Comment.view';
import { SendView } from '@screens/main/ticket/TicketDetail/components/Send.view';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font } from '@theme/size';
import { DATE_FORMAT, formatDate } from '@utils/common/MomentUtils';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Text, TouchableOpacity, FlatList, TextInput, ScrollView } from 'react-native';
import RenderHtml from 'react-native-render-html';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { useTicketDetail } from './TicketDetail.hook';
import { TicketDetailStyle } from './TicketDetail.style';
import {AppReadMore} from "@components/AppReadMore";

export const TicketDetailScreen = props => {
  const { t } = useTranslation();
  const { data, getListStatusData, headerTitle, images, setImages, onSend, text, setText } = useTicketDetail(props);
  // const { handlePickImage, handleDelete, selected = [] } = useAppImagePicker(props);

  const { colors = null, value = null } = getListStatusData ?? {};
  console.log('TicketDetailScreen>>>', data);
  const renderFooter = () => {
    return (
      <View
        style={[globalStyle.directionRow, { marginHorizontal: theme.spacing.md, marginVertical: theme.spacing.lg }]}>
        <TouchableOpacity
          style={[
            {
              alignItems: 'center',
              textAlignVertical: 'center',
              justifyContent: 'center',
              paddingRight: theme.spacing.sm,
            },
          ]}
          // onPress={handlePickImage}
        >
          <CirclePlusIcon />
        </TouchableOpacity>
        <View style={[globalStyle.directionRow, TicketDetailStyle.searchContainer, { flex: 1 }]}>
          {/*<View style={[globalStyle.directionColumm, {width: '100%'}]}>*/}
          <TextInput
            multiline={true}
            placeholder={'Type message'}
            style={[TicketDetailStyle.searchView, { marginTop: 0, paddingTop: 12 }]}
            allowFontScaling={true}
          />

          {/*</View>*/}
          <TouchableOpacity
            onPress={() => {
              // onTextChanged('');
            }}
            style={{ marginRight: 4 }}>
            <SentIcon />
          </TouchableOpacity>
        </View>
      </View>
    );
  };
  return (
    <>
      {/*<StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />*/}
      <AppSafeAreaView areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>
        <GenericHeader onBackClicked={goBack} title={headerTitle} style={[{ backgroundColor: '#EDEDED' }]} />
        {/*<AppKAView>*/}
        <AppKAViewV2 style={{ backgroundColor: palette.WHITE }}>
          <ScrollView style={[{ width: '100%', flexGrow: 1 }]}>
            <View>
              <View style={TicketDetailStyle.container}>
                <View
                  style={[
                    // styles.fill,
                    globalStyle.directionColumm,
                    // TicketItemStyles.container,
                    { width: '100%' },
                    // { marginBottom: props.isLast ? Dimens.Margin.Normal : 0 },
                  ]}>
                  <View style={[globalStyle.directionRow]}>
                    <AppText style={[TicketItemStyles.textTitle]}>
                      {'ID: '}
                      {`${data?.properties?.incrementalID}  `}
                      <View style={[TicketItemStyles.viewLine]} />
                      <AppText style={[TicketItemStyles.textSecondTitle]}>{`  ${data?.title}`}</AppText>
                    </AppText>
                  </View>
                  <AppText style={[TicketItemStyles.textNormal]}>
                    {formatDate(data?.createdAt, DATE_FORMAT.FullDate)}
                  </AppText>
                  {/*<AppText style={[TicketItemStyles.textSecondTitle, { marginBottom: 4 }]}>{data?.content}</AppText>*/}
                  {!!data?.content && (
                    // <ReadMore
                    //   numberOfLines={2}
                    //   allowFontScaling
                    //   style={[TicketDetailStyle.textSecondTitle, { marginBottom: 8 }]}
                    //   seeLessText={'view less'}
                    //   seeMoreText={'view more'}
                    //   seeLessStyle={[TicketDetailStyle.viewMore]}
                    //   seeMoreStyle={[TicketDetailStyle.viewMore]}>
                    //   {data?.content}
                    //   {/*<RenderHtml source={{ html: data?.content }} baseStyle={CommentStyle.commentText} />*/}
                    // </ReadMore>
                      <AppReadMore text={data?.content}/>
                  )}
                  <View style={[globalStyle.directionRow, globalStyle.center]}>
                    <StatusView status={data?.resolution ?? data?.process} colors={colors} dictStatus={value} />
                    <AppText style={[globalStyle.flex1]} />
                  </View>
                </View>
              </View>

              {Array.isArray(data?.comments) &&
                data?.comments?.map((item, i) => {
                  return <CommentView data={item} key={i} />;
                })}
            </View>
          </ScrollView>
          {/*<FlatList*/}
          {/*  style={[{ backgroundColor: palette.WHITE }]}*/}
          {/*  data={data?.comments ?? []}*/}
          {/*  keyExtractor={item => item._id || item}*/}
          {/*  renderItem={({ item, index }) => <CommentView data={item} />}*/}
          {/*/>*/}
          {/*{renderFooter()}*/}
          {!data?.notAllowOpenTicket && (<SendView
            selected={images}
            onSelect={x => setImages(x)}
            onSend={() => {
              onSend();
            }}
            text={text}
            onChangeText={txt => {
              setText(txt);
            }}
          />)}
        </AppKAViewV2>
      </AppSafeAreaView>
    </>
  );
};
