import theme, {WEB_WIDTH} from '@theme';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
// import { fonts } from '@theme/fonts';
import { StyleSheet, Platform } from 'react-native';

export const ForceUpdateStyles = StyleSheet.create({
  alertContainerStyle: {
    backgroundColor: Platform.OS === 'web' ? '' : 'rgba(0,0,0, 0.3)',
    overflow: Platform.OS === 'web' ? '' : 'hidden',
    zIndex: 1000,
    top: Platform.OS === 'web' ? '0' : undefined,
    width: Platform.OS === 'web' ? WEB_WIDTH : undefined,
    position: Platform.OS === 'web' ? 'fixed' : undefined,
  },

  contentContainerStyle: {
    // padding: 10,
    borderWidth: 0,
    borderRadius: 15,
    alignItems: 'center',
    justifyContent: 'center',
  },

  contentStyle: {
    padding: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },

  actionContainerStyle: {
    padding: 0,
    width: '100%',
    // marginRight: -100,
    display: 'none',
  },
  ButtonStyle: {
    margin: 0,
    width: '100%',
  },
  row: {
    flexDirection: 'row',
  },
  col: {
    flexDirection: 'column',
  },
  navigationButton: {
    backgroundColor: palette.SUN_FLOWER,
    borderWidth: 0,
    justifyContent: 'center',
  },
  buttonText: {
    color: palette.WHITE,
    // fontFamily: fonts.regular,
  },

  rootView: {
    // width: '90%',
    paddingHorizontal: theme.spacing.md,
  },
});
