import { CommonImages } from '@assets';
import { AppText } from '@components';
import { Font, FontSize, Spacing } from '@theme/size';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, StyleSheet, TouchableOpacity, View, Platform } from 'react-native';

export const AuthFooter = React.memo(props => {
  const { style } = props;
  const { t } = useTranslation();

  return (
    <View style={[styles.container, style]}>
      <AppText style={styles.text}>{t('ON_BEHALF_OF')}</AppText>
      <Image style={styles.image} source={CommonImages.RoyalMail} resizeMode="contain" />
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical:  Platform.OS === 'web' ? Spacing.height8 : Spacing.height15,
    backgroundColor: '#F5F5F5',
    // backgroundColor: 'red',
  },
  text: {
    color: '#888888',
    fontSize: FontSize.Font13,
    marginRight: Spacing.width8,
  },
  image: {
    width: Spacing.width42,
    height: Spacing.width28,
  },
});
