export const VoucherMockData = {
  bonusInfo: {
    note: 'With 2000 points, you will redeem a voucher',
    total: 21058,
    content: 'Find out more at <a href="https://www.cadooz.com">www.cadooz.com</a>',
  },
  data: [
    {
      url: '',
      content: 'Upto 50% off',
      date: 'Expiry: 12/12/2022',
    },
    {
      url: '',
      content: 'Upto 50% off',
      date: 'Expiry: 12/12/2022',
    },
    {
      url: '',
      content: 'Upto 50% off',
      date: 'Expiry: 12/12/2022',
    },
    {
      url: '',
      content: 'Upto 50% off',
      date: 'Expiry: 12/12/2022',
    },
    {
      url: '',
      content: 'Upto 50% off',
      date: 'Expiry: 12/12/2022',
    },
    {
      url: '',
      content: 'Upto 50% off',
      date: 'Expiry: 12/12/2022',
    },
    {
      url: '',
      content: 'Upto 50% off',
      date: 'Expiry: 12/12/2022',
    },
    {
      url: '',
      content: 'Upto 50% off',
      date: 'Expiry: 12/12/2022',
    },
  ],
};
