import { FORM } from '@constant/model/RegisterForm';
import { getHolidayFormRequest } from '@redux/form/getHolidayFormSlice';
import { getHolidayFormCreator } from '@redux/form/selectors';
import { addHolidayRequest } from '@redux/holiday/addHolidaySlice';
import { getListHolidaysRequest } from '@redux/holiday/getListHolidaysSlice';
import {goBack, navigate, popToTop} from '@routes/navigationUtils';
import {LOGIN_SCREEN, Screens} from '@routes/route';
import { DATE_FORMAT, formatDate } from '@utils/common/MomentUtils';
import {
  checkFormValidateExpression,
  getInitialFormValue,
  getInitialFormValueOrigin,
  getYupStructure,
  getYupStructureOrigin
} from '@utils/form';
import { handleShowError, mappingForm, mappingStep, mappingStepOrigin } from '@utils/transform/form';
import { useFormik } from 'formik';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { lazy } from 'yup';

export const useAddReport = navigation => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [modalShow, setModalShow] = useState(false);
  const getHolidayFormData = useSelector(getHolidayFormCreator)?.data;

  const rawData = [
    {
      _id: 'from',
      name: {
        en: t('START_DATE'),
      },
      type: FORM.FORM_TYPE.calendar,
      required: true,
      internalId: 'from',
      minDate: formatDate(Date(), DATE_FORMAT.UIDate, DATE_FORMAT.StandardDate),
      draft: false,
      options: [],
      errorMessage: {
        en: 'Mandatory field',
        es: 'Campo obligatorio',
        de: 'Pflichfeld',
      },
      editable: true,
    },
    {
      _id: 'to',
      name: {
        en: t('END_DATE'),
      },
      type: FORM.FORM_TYPE.calendar,
      required: true,
      internalId: 'to',
      minDate: formatDate(Date(), DATE_FORMAT.UIDate, DATE_FORMAT.StandardDate),
      draft: false,
      options: [],
      errorMessage: {
        en: 'Mandatory field',
        es: 'Campo obligatorio',
        de: 'Pflichfeld',
      },
      editable: true,
    },
  ];
  // const data = mappingStepOrigin(rawData);
  const [data, setData] = useState(null);

  const formik = useFormik({
    // initialValues: getInitialFormValueOrigin(data, {}),
    initialValues: getInitialFormValue(data, {}),
    enableReinitialize: true,
    // validationSchema: Yup.object().shape(getYupStructureOrigin(data, t, true)),
    validationSchema: () =>
      lazy(values => {
        // console.log('lazy>>>values>>>', values);
        const ys = getYupStructure(data, t, 'attributes', values);
        console.log('getYupStructure>>>', ys, formik);
        return Yup.object().shape(ys);
      }),
    initialErrors: false,
    onSubmit: (values, { setErrors, resetForm }) => {
      console.log('onSubmit>>>', values);
      const rs = checkFormValidateExpression(data?.attributes, values, setErrors);
      if (!_.isEmpty(rs)) {
        return;
      }
      addHolidayApi(values, setErrors, resetForm);
    },
  });
  const getListHolidaysApi = () => {
    /*
      payload_info
       */
    const payload = {};
    dispatch(getListHolidaysRequest(payload));
  };

  const getHolidayFormApi = () => {
    const payload = {};
    dispatch(getHolidayFormRequest(payload));
  };

  const addHolidayApi = (payload, setErrors, resetForm) => {
    dispatch(
      addHolidayRequest(
        payload,
        rs => {
          //TODO: call list api holiday here
          getListHolidaysApi();
          resetForm();
          navigate(Screens.SUCCESSFUL_SCREEN, {
            data: rs?.message,
            header: {
              // title: headerTitle,
              backClick: () => {
                goBack();
              },
            },
          });
          // setModalShow(true);
          // setTimeout(() => {
          //   setModalShow(false);
          // }, 5000);
        },
        err => {
          handleShowError(err?.error ? err : err?.message, setErrors);
        },
      ),
    );
  };

  useEffect(() => {
    getHolidayFormApi();
  }, []);

  useEffect(() => {
    if (getHolidayFormData) {
      const x = _.cloneDeep(getHolidayFormData);
      const rd = mappingStep(!_.isEmpty(x) ? x[0] : null);
      setData(rd);
    }
  }, [getHolidayFormData]);

  return { data, formik, modalShow, setModalShow };
};
