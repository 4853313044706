import { Config } from '@constant/config';
import { FORM } from '@constant/model/RegisterForm';
import { ReportBundleMockData } from '@constant/model/mock/report/bundle';
import { useRoute } from '@react-navigation/native';
import { getDashboardRequest, getNotificationPermission, submitRFIDNotification } from '@redux/app/appSlice';
import { notificationPermission } from '@redux/app/selectors';
import { getRFIDFormRequest } from '@redux/form/getRFIDFormSlice';
import {getReportBundleFormCreator, getReturnTransponderFormCreator, getRFIDFormCreator} from '@redux/form/selectors';
import { submitRFIDRequest } from '@redux/rfid/submitRFIDSlice';
import { getProfileRequest } from '@redux/user/getProfileSlice';
import { getProfileCreator } from '@redux/user/selectors';
import { updateProfileRequest } from '@redux/user/updateProfileSlice';
import {goBack, navigate, navigatePop, popToTop} from '@routes/navigationUtils';
import { AppNotification, getPanelistId } from '@utils';
import { checkFormValidateExpression, getInitialFormValue, getYupStructure } from '@utils/form';
import { useLocation } from '@utils/hooks';
import { fixFormValues, FormTransform, handleShowError, mappingStep } from '@utils/transform/form';
import { useFormik } from 'formik';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import replaceAll from 'string.prototype.replaceall';
import { lazy } from 'yup';
import * as Yup from 'yup';
import {Screens} from "@routes/route";
import {NavigateEvent, NavigationHelper} from "@utils/common/navigation";
import {FormikHelper} from "@utils/hooks/formik";
import {Constant} from "@constant";

export const useRFID = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const paramsData = useRoute().params?.data;
  // const [data, setData] = useState(null);
  // const getProfileData = useSelector(getRFIDFormCreator)?.data;
  const hasLocation = paramsData.hasLocation;
  const scrollViewRef = useRef(null);
  const subFormRef = useRef();

  const isTransponder = paramsData?.category === Constant.rfid_category.transponder

  // eslint-disable-next-line react-hooks/rules-of-hooks
  let lat = null;
  let long = null;
  let locationType = '';
  let handleGetLocation = null;
  if (hasLocation) {
    const location = useLocation(props);
    lat = location.lat;
    long = location.long;
    handleGetLocation = location.handleGetLocation
    locationType = location?.locationType
  }

  // const getRFIDFormData = useSelector(getRFIDFormCreator)?.data;
  const getRFIDFormData = _.cloneDeep(useSelector(getRFIDFormCreator)?.data)

  // const [data, setData] = useState(null);
  const data = getRFIDFormData ? mappingStep(getRFIDFormData[0]) : null

  const notificationPermissionState = useSelector(notificationPermission);
  const [value, setValue] = useState(notificationPermissionState);
  const [layoutInfo, setLayoutInfo] = useState({});
  const [userLocation, setUserLocation] = useState({});

  const [messageInfo, setMessageInfo] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [userLocationType, setUserLocationType] = useState('')
  // const getRFIDFormApi = () => {
  //   const payload = {
  //     ...paramsData,
  //     // category: replaceAll(paramsData?.category, '-', '_'),
  //     panelistId: getPanelistId(),
  //   };
  //   delete payload.message;
  //   delete payload.canDelete;
  //   dispatch(getRFIDFormRequest(payload));
  // };

  const formik = FormikHelper.useFormikWrapper({
    initialValues: {
      ...getInitialFormValue(data, {}),
      posting_location_id: null,
    },
    // validationSchema: Yup.object().shape(getYupStructure(rawData, t)),
    validationSchema: () =>
      lazy(values => {
        // console.log('lazy>>>values>>>', values);
        const ys = getYupStructure(data, t, 'attributes', values);
        console.log('getYupStructure>>>', ys);
        return Yup.object().shape(ys);
      }),
    initialErrors: false,
    enableReinitialize: true,
    onSubmit: async (values, { setErrors, resetForm }) => {
      console.log('onSubmit>>>', values, subFormRef);
      // updateUserApi(values, isLastStep);
      // onSavePress();
      const rs = checkFormValidateExpression(data?.attributes, values, setErrors);
      if (subFormRef?.current != null) {
        const subForm =
          data?.attributes?.find(x => x.internalId === FORM.FORM_ELEMENT_NAME.dispatch_point)?.subForms ?? [];
        console.log('validateForm>>>before>>>', subFormRef?.current);
        const errSubForm = await FormTransform.validateForm(subFormRef?.current, subForm, true);
        console.log('errSubForm>>>', errSubForm);
        if (!_.isEmpty(errSubForm)) {
          return;
        }
      }
      if (!_.isEmpty(rs)) {
        return;
      }
      submitRFIDApi(values, setErrors);
    },
  }, data?.attributes);

  const scrollToY = y => {
    // scrollViewRef.current.scroll
    scrollViewRef.current?.scrollTo({
      x: 0,
      y: y,
      animated: true,
    });
  };

  const submitRFIDApi = async (values, setErrors) => {
    /*
      payload_info
       */
    // const typeRFID = replaceAll(paramsData.type, 'rfid_', '');
    const payload = {
      ...paramsData,
      ...values,
      location_type: userLocationType
    };

    delete payload.message;
    delete payload.canDelete;
    // delete payload.type
    const payloadValue = await fixFormValues(payload, values);
    if (hasLocation && !_.isEmpty(userLocation) && !!userLocation?.longitude) {
      payload.user_location = Object.values(userLocation);
      payload.location_type = userLocationType
    } else {
      payload.user_location = null;
    }
    console.log('updateProfileApi>>>', payloadValue);
    // return
    dispatch(
      submitRFIDRequest(
        payloadValue,
        rs => {
          //TODO: reload api
          //FIXME: don't need re-calculate notification rfid, because call triggerActions.
          // dispatch(
          //   submitRFIDNotification({
          //     ...paramsData,
          //   }),
          // );
          // setIsSuccess(true);
          // setMessageInfo(rs.message)

          navigate(Screens.SUCCESSFUL_SCREEN, {
            data: rs?.message,
            header: {
              title: data?.title,
              backClick: () => {
                goBack();
                goBack();
              },
            },
          });
        },
        err => {
          handleShowError(err, setErrors);
        },
      ),
    );
  };

  useEffect(() => {
    // getRFIDFormApi();
  }, []);

  useEffect(() => {
    if(hasLocation){
      const unsubscribe = props.navigation?.addListener(NavigateEvent.transitionEnd, e => {
        // Do something
        setTimeout(() => {
          const currentScreen = NavigationHelper.getCurrentScreen(props?.navigation)
          if (currentScreen === Screens.RFID_FORM) {
            if (handleGetLocation) {
              handleGetLocation()
            }
          }
        }, 100);
      });
      return unsubscribe;
    }

  }, [props.navigation]);

  // useEffect(() => {
  //   const x = _.cloneDeep(getRFIDFormData);
  //   const rd = mappingStep(!_.isEmpty(x) ? x[0] : null);
  //   setData(rd);
  //   console.log('getReportBundleFormData>>>', rd);
  // }, [getRFIDFormData]);

  useEffect(() => {
    setUserLocation({
      latitude: lat,
      longitude: long,
    });
    console.log('location>>>', lat, long);
  }, [lat, long]);

  useEffect(() => {
    setUserLocationType(locationType)
  }, [locationType])

  // console.log('useRFID>>>', data, getRFIDFormData, paramsData);

  return {
    setValue,
    value,
    formik,
    data,
    scrollToY,
    scrollViewRef,
    layoutInfo,
    setLayoutInfo,
    userLocation,
    subFormRef,
    messageInfo,
    isSuccess,
    isTransponder
  };
};
