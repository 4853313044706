import { BOTTOM_TAB, DASHBOARD_TAB, MAIN, Screens } from '@routes/route';
import _ from 'lodash';

export const NavigateEvent = {
  transitionEnd: 'transitionEnd',
  transitionStart: 'transitionStart',
  focus: 'focus',
  gestureEnd: 'gestureEnd',
};

export const NavigationHelper = {
  getCurrentScreen: navigation => {
    if (_.isEmpty(navigation)) {
      return '';
    }
    const state = navigation?.getState();
    if (_.isEmpty(state)) {
      return '';
    }

    const routes = state.routes;
    if (_.isEmpty(routes)) {
      return '';
    }
    return routes[routes.length - 1].name;
  },
  getCurrentScreenOfMainStack: navigation => {
    if (_.isEmpty(navigation)) {
      return '';
    }
    const state = navigation?.getState();
    if (_.isEmpty(state)) {
      return '';
    }

    const routes = state.routes;
    console.log('getCurrentScreenOfMainStack>>>', routes);
    if (_.isEmpty(routes)) {
      return '';
    }
    const state1 = routes[routes.length - 1].state;
    const rootName = routes[routes.length - 1].name;
    if (!state1) {
      return rootName === MAIN ? DASHBOARD_TAB : rootName;
    }

    console.log('getCurrentScreenOfMainStack>>>state1>>>', state1);

    const index = state1.index;
    const routes_t = state1.routes;
    if (_.isEmpty(routes_t) || _.isNil(index)) {
      return rootName;
    }
    const route_t = routes_t[index];
    const name = route_t?.name;
    console.log('route_t>>>', route_t)
    try {
      if (name === BOTTOM_TAB) {
        const index_b = route_t?.state?.index;
        const routes_b = route_t?.state?.routes;
        console.log('routes_b>>>', routes_b)

        if (_.isEmpty(route_t) || _.isEmpty(routes_b)) {
          return name;
        }
        const name_b = routes_b[index_b]?.name;
        console.log('name_b>>>', name_b)

        if (name_b === DASHBOARD_TAB) {
          const state_d = routes_b[index_b]?.state;
          if(_.isEmpty(state_d)){
            return name_b;
          }
          const index_d = state_d?.index;
          const routes_d = state_d?.routes;
          if (_.isEmpty(routes_d)) {
            return name_b;
          }
          return routes_d[index_d]?.name;
        }
        return name_b;
      }
    } catch (e) {
      console('ex>>>getCurrentScreenOfMainStack>>>', e)
      return name;
    }


    return name;
  },
};
