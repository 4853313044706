import { useCallback, useEffect, useState } from 'react';
import { Linking, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

export const useForceUpdate = props => {
  const onGoToStorePress = useCallback(() => {
    if (Platform.OS === 'android') {
      Linking.canOpenURL('market://details?id=com.spectos.mailagents.app')
        .then(() => {
          Linking.openURL('market://details?id=com.spectos.mailagents.app');
        })
        .catch();
    } else if (Platform.OS === 'ios') {
      Linking.canOpenURL('itms-apps://itunes.apple.com/gb/app/apple-store/id1641426357')
        .then(() => Linking.openURL('itms-apps://itunes.apple.com/gb/app/apple-store/id1641426357'))
        .catch();
    }
  }, []);
  return { onGoToStorePress };
};
