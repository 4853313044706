import {checkUnsubscribeConditionsCreator, getProfileCreator} from '@redux/user/selectors';
import { unsubscribeUserRequest } from '@redux/user/unsubscribeUserSlice';
import {goBack, goBackWidthScreen, navigate, popToTop} from '@routes/navigationUtils';
import { Screens } from '@routes/route';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {checkUnsubscribeConditionsRequest} from "@redux/user/checkUnsubscribeConditionsSlice";
import {SURVEY_STATUS} from "@constant";
import {useTranslation} from "react-i18next";
import {SuccessfulUnsubTest} from "@utils/test/successful";
import {showErrorMsg} from "@utils/api";
import {hideLoading, showLoading} from "@redux/global/globalSlice";

export const useUnsubscribe = navigation => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const point = useSelector(getProfileCreator)?.message?.data?.bonuspoints ?? 0;
  const messageInfo = useSelector(checkUnsubscribeConditionsCreator)?.message
  const [showModal, setShowModal] = useState(false);
  const onNoModalPress = () => {
    setShowModal(false);
  };

  const onYesModalPress = () => {
    // const rs = SuccessfulUnsubTest
    // dispatch(showLoading())
    // setTimeout(() => {
    //   setShowModal(false);
    //   dispatch(hideLoading())
    // }, 3000)
    // return
    dispatch(
      unsubscribeUserRequest(
        {},
        rs => {
          setShowModal(false);
          navigate(Screens.SUCCESSFUL_SCREEN, {
            data: rs?.message,
            header: {
              title: t('UNSUBSCRIBE'),
              backClick: () => {
                popToTop();
              },
            },
          });
        },
        err => {
          setShowModal(false);
        },
      ),
    );
  };

  const onYesPress = () => {
    setShowModal(true);
  };
  const onNoPress = () => {
    goBack();
  };
  const checkUnsubscribeConditionsApi = () => {
    const payload = {}
    dispatch(checkUnsubscribeConditionsRequest(payload))
  }

  useEffect(() => {
    checkUnsubscribeConditionsApi()
  }, [])

  return { showModal, setShowModal, onNoModalPress, onYesPress, onYesModalPress, onNoPress, point, messageInfo };
};
