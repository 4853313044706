import {createAction, createSlice} from '@reduxjs/toolkit';

export interface IredeemVoucherState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IredeemVoucherState = {
  loading: false,
  payload: null,
  error: null,
};

export const redeemVoucherRequest = createAction(
    'redeemVoucher/redeemVoucherRequest',
    (payload, onSuccess, onFailed) => {
      console.log('prepareAction>>>', payload, onSuccess);
      return {
        payload: payload,
        meta: onSuccess,
        error: onFailed,
      };
    },
);

const redeemVoucherSlice = createSlice({
  name: 'redeemVoucher',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // redeemVoucherRequest(state, action) {
    //   state.loading = true;
    //   // @ts-ignore
    //   state.payload = null;
    //   state.error = null;
    // },
    redeemVoucherSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    redeemVoucherFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { redeemVoucherSuccess, redeemVoucherFailed } = redeemVoucherSlice.actions;

export default redeemVoucherSlice.reducer;
