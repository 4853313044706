import {hideCameraPermissionModal, hideGlobalModal, hideLocationPermissionModal} from '@redux/common/commonSlice';
import {
  cameraPermissionModalVisible as cameraPermissionModalVisibleSelector,
  locationPermissionModalVisible as locationPermissionModalVisibleSelector,
    globalModalSelector
} from '@redux/common/selectors';
import { openSettings } from 'react-native-permissions';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

export const useGlobalModal = props => {
  const dispatch = useDispatch();
  const globalModalData = useSelector(globalModalSelector);
  const data = globalModalData
  // const locationPermissionModalVisible = useSelector(locationPermissionModalVisibleSelector);
  const handleHideModal = () => {
    // cameraPermissionModalVisible && dispatch(hideCameraPermissionModal());
    // locationPermissionModalVisible && dispatch(hideLocationPermissionModal());
    dispatch(hideGlobalModal())
  };

  console.log('useGlobalModal>>>', data)
  return { handleHideModal, data };
};
