import createTicketSlice from '@redux/ticket/createTicketSlice';
import getListTicketSlice from '@redux/ticket/getListTicketSlice';
import getTicketDetailSlice from '@redux/ticket/getTicketDetailSlice';
import getListStatusSlice from '@redux/ticket/getListStatusSlice';
import getListSubjectSlice from '@redux/ticket/getListSubjectSlice';
import addCommentSlice from '@redux/ticket/addCommentSlice';

export const ticketReducer = {
 createTicket: createTicketSlice,
 getListTicket: getListTicketSlice,
 getTicketDetail: getTicketDetailSlice,
 getListStatus: getListStatusSlice,
 getListSubject: getListSubjectSlice,
 addComment: addCommentSlice,
};

