import { getReimbursementSaga } from '@redux/Reimbursement/getReimbursementSaga';
import { getReimbursementRequest } from '@redux/Reimbursement/getReimbursementSlice';
import { downloadReimbursementSaga } from '@redux/Reimbursement/downloadReimbursementSaga';
import { downloadReimbursementRequest } from '@redux/Reimbursement/downloadReimbursementSlice';
import { ForkEffect, takeLatest } from 'redux-saga/effects';

function* ReimbursementSagas(): Generator<ForkEffect<never>, void> {
  yield takeLatest(getReimbursementRequest.type, getReimbursementSaga);
  yield takeLatest(downloadReimbursementRequest.type, downloadReimbursementSaga);
}

export default ReimbursementSagas;
