import { palette } from '@theme/colors';
import { Font, FontSize, deviceWidth } from '@theme/size';
import {Platform, StyleSheet} from 'react-native';
import {WEB_WIDTH} from "@theme";

export const styles = StyleSheet.create({
  container: {
    width: Platform.OS === 'web' ? WEB_WIDTH - 32: deviceWidth - 32,
    minHeight: 180,
    backgroundColor: palette.WHITE,
    // position: 'absolute',
    marginBottom: 16,
    borderRadius: 5,
    padding: 12,

    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
  },
  top: {
    marginBottom: 14,
  },
  titleTop: {
    ...Font.Bold_700,
    lineHeight: 24,
  },
  text: {
    fontSize: FontSize.Font14,
    lineHeight: 24,
  },
  textRed: {
    fontSize: FontSize.Font14,
    color: palette.ROYAL_MAIL_RED,
  },
  textGreen: {
    fontSize: FontSize.Font14,
    color: '#589F28',
  },
  bottom: {},
  titleBottom: {
    ...Font.Bold_700,
    lineHeight: 24,
    fontSize: FontSize.Font14,
  },
  timeWrap: {
    flexDirection: 'row',
  },
  bold: {
    ...Font.Bold_700,
    fontSize: FontSize.Font14,
  },
});
