import _ from 'lodash';
import React from 'react';
import {LocationType} from "@utils/hooks";
import {Platform} from "react-native";

export const bottomMapModalRef = React.createRef();

export const MapHelper = {
  isShowMap: (address_selected_extra, location, verifyOnMap) => {
    if (!verifyOnMap || _.isNil(address_selected_extra?.latitude) || _.isNil(location?.latitude)) {
      return false;
    }
    return true;
  },
  isVerifyGood: (address_selected_extra, location, radius, locationType) => {
    if (_.isNil(address_selected_extra?.latitude) || _.isNil(location?.latitude) || _.isNil(radius) || locationType === LocationType.home) {
      return false;
    }
    const distance = MapHelper.distanceTwoLocation(
      Number(address_selected_extra?.latitude),
      Number(address_selected_extra?.longitude),
      Number(location?.latitude),
      Number(location?.longitude),
    );
    console.log('isVerifyGood>>>distance>>>', distance);
    console.log('isVerifyGood>>>location>>>', location);
    console.log('isVerifyGood>>>address_selected_extra>>>', address_selected_extra);

    return distance <= radius;
  },
  distanceTwoLocation: (lat1, lon1, lat2, lon2) => {
    // Bán kính của Trái Đất tính bằng km
    const R = 6371;

    // Chuyển đổi độ sang radian
    const toRadian = angle => angle * (Math.PI / 180);

    const dLat = toRadian(lat2 - lat1);
    const dLon = toRadian(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadian(lat1)) * Math.cos(toRadian(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    // Khoảng cách tính bằng m
    const distance = R * c * 1000;

    return distance;
  },
  showBottomMapModal: () => {
    bottomMapModalRef.current?.open();
  },
  hideBottomMapModal: () => {
    bottomMapModalRef.current?.close();
  },
  isShowModalWhenSubmit: (isAllowGps, isVerifyGood) => {
    //NOTE:  bật gps + ko verify thì mới có popup
    if (Platform.OS === 'web') {
      return false;
    }
    return !!isAllowGps && !isVerifyGood;
  },
};
