import { CommonImages } from '@assets';
import { globalStyle } from '@theme';
import { palette } from '@theme/colors';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Animated, View, Text, Image } from 'react-native';
import { useFirstSplash } from './FirstSplash.hook';
import { FirstSplashStyles } from './FirstSplash.style';

export const FirstSplashComponent = props => {
  const { slideAnim, fadeAnim } = useFirstSplash(props);

  return (
    <Animated.View
      style={[
        {
          position: 'relative',
          opacity: fadeAnim,
          // backgroundColor: palette.ROYAL_MAIL_RED,
        },
      ]}>
      {/*<View style={[{ backgroundColor: palette.ROYAL_MAIL_RED }]}>*/}
      {/*<Image source={CommonImages.SplashLayer} style={[FirstSplashStyles.splashLayer]}/>*/}
      <Image source={CommonImages.Splash} style={[FirstSplashStyles.splash]} />
      {/*</View>*/}
      {/*<Animated.Image*/}
      {/*  source={CommonImages.Branding}*/}
      {/*  resizeMode={'contain'}*/}
      {/*  style={[SplashStyles.branding, { translateY: slideAnim }]}*/}
      {/*/>*/}
      <Animated.View
        style={[
          FirstSplashStyles.viewContainer,
          globalStyle.directionColumm,
          {
            transform: [
              {
                translateY: slideAnim,
              },
            ],

            // opacity: fadeAnim
          },
        ]}>
        <Image source={CommonImages.Branding} resizeMode={'contain'} style={[FirstSplashStyles.branding2]} />
        <View style={[FirstSplashStyles.viewWhite]} />
      </Animated.View>
    </Animated.View>
  );
};
