import { Config } from '@constant/config';
import { FORM } from '@constant/model/RegisterForm';
import { ReportBundleMockData } from '@constant/model/mock/report/bundle';
import { useRoute } from '@react-navigation/native';
import { getNotificationPermission } from '@redux/app/appSlice';
import { notificationPermission } from '@redux/app/selectors';
import { getQRFormRequest } from '@redux/form/getQRFormSlice';
import { getRFIDFormRequest } from '@redux/form/getRFIDFormSlice';
import { getQRFormCreator, getReportBundleFormCreator, getRFIDFormCreator } from '@redux/form/selectors';
import { submitRFIDRequest } from '@redux/rfid/submitRFIDSlice';
import { getProfileRequest } from '@redux/user/getProfileSlice';
import { getProfileCreator } from '@redux/user/selectors';
import { updateProfileRequest } from '@redux/user/updateProfileSlice';
import { navigatePop } from '@routes/navigationUtils';
import { AppNotification } from '@utils';
import { checkFormValidateExpression, getInitialFormValue, getYupStructure } from '@utils/form';
import { fixFormValues, handleShowError, mappingStep } from '@utils/transform/form';
import { useFormik } from 'formik';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import replaceAll from 'string.prototype.replaceall';
import { lazy } from 'yup';
import * as Yup from 'yup';

export const useHowTo = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const paramsData = useRoute().params?.data;
  const [data, setData] = useState(null);
  // const getProfileData = useSelector(getRFIDFormCreator)?.data;
  const scrollViewRef = useRef(null);

  const getQRFormData = useSelector(getQRFormCreator)?.data;

  const [layoutInfo, setLayoutInfo] = useState({});

  const getQRFormApi = () => {
    const payload = {};
    dispatch(getQRFormRequest(payload));
  };

  const formik = useFormik({
    initialValues: getInitialFormValue(data, {}),
    // validationSchema: Yup.object().shape(getYupStructure(rawData, t)),
    validationSchema: () =>
      lazy(values => {
        // console.log('lazy>>>values>>>', values);
        const ys = getYupStructure(data, t, 'attributes', values);
        console.log('getYupStructure>>>', ys);
        return Yup.object().shape(ys);
      }),
    initialErrors: false,
    enableReinitialize: true,
    onSubmit: (values, { setErrors, resetForm }) => {
      console.log('onSubmit>>>', values);
      // updateUserApi(values, isLastStep);
      // onSavePress();
      const rs = checkFormValidateExpression(data?.attributes, values, setErrors);
      if (!_.isEmpty(rs)) {
        return;
      }
    },
  });

  const scrollToY = y => {
    // scrollViewRef.current.scroll
    scrollViewRef.current?.scrollTo({
      x: 0,
      y: y,
      animated: true,
    });
  };

  useEffect(() => {
    getQRFormApi();
  }, []);

  useEffect(() => {
    const x = _.cloneDeep(getQRFormData);
    // const rd = mappingStep(!_.isEmpty(x) ? x[0] : null);
    x?.attributes?.map(item => {
      if (item.type === FORM.FORM_TYPE.note) {
        item.internalId = 'note';
        item.title = item.title ?? item.description;
      }
      return item;
    });
    setData(x);
    console.log('getReportBundleFormData>>>', x);
  }, [getQRFormData]);

  console.log('useHowTo>>>', data, getQRFormData, paramsData);

  return { formik, data, scrollToY, scrollViewRef, layoutInfo, setLayoutInfo };
};
