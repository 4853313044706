import { ArrowRightIcon } from '@assets';
import { AppKAView, AppSafeAreaView, AppText } from '@components';
import GenericHeader from '@components/GenericHeader';
import { goBack } from '@routes/navigationUtils';
import { ListQuizStyle } from '@screens/main/quiz/ListQuiz/ListQuiz.style';
import theme, { globalStyle } from '@theme';
import { getContentTitle } from '@utils/transform/form';
// import { getLogoutRequest } from '@redux/auth/authSlice';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {FlatList, RefreshControl, StatusBar, TouchableOpacity, View} from 'react-native';
import { useDispatch } from 'react-redux';
import { useFAQ } from './FAQ.hook';
import { styles } from './FAQ.style';
import {palette} from "@theme/colors";
import Colors from "@screens/main/report/ListReport/Colors";

const FAQ = props => {
  const dispatch = useDispatch();
  const { data, onItemPress, loading, onRefresh } = useFAQ(props);
  const { t } = useTranslation();
  const renderItem = ({ item, index }) => {
    return (
      <View style={[globalStyle.directionRow, ListQuizStyle.viewContainer]}>
        <TouchableOpacity
          onPress={() => {
            onItemPress(item);
          }}
          activeOpacity={0.7}
          style={[ListQuizStyle.viewSelect, globalStyle.directionRow]}>
          <AppText style={[ ListQuizStyle.itemText]}>{getContentTitle(item?.title)}</AppText>
          {item?.passed ? <></> : <ArrowRightIcon />}
        </TouchableOpacity>
      </View>
    );
  };
  return (
    <>
      <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
      <AppSafeAreaView areaStyle={styles.areaStyle}>
        <GenericHeader title={t('FAQ')} style={[{ backgroundColor: '#EDEDED' }]} />
        <AppKAView>
          <View style={[ListQuizStyle.containerView]}>
            <FlatList
                contentContainerStyle={{ marginTop: theme.spacing.lg }}
                data={data}
                renderItem={renderItem}
                refreshControl={
                    <RefreshControl
                        refreshing={loading}
                        onRefresh={
                            loading
                                ? null
                                : () => {
                                    onRefresh();
                                }
                        }
                        tintColor={palette.LOADING_COLOR}
                        colors={[palette.LOADING_COLOR]}
                        titleColor={Colors.activityColor}
                    />
                }
            />
          </View>
        </AppKAView>
        {/* <AuthFooter /> */}
      </AppSafeAreaView>
    </>
  );
};

export { FAQ };
