import { CirclePlusIcon, CrossIcon, SentIcon } from '@assets';
import { AppModal, AppSingleSelection } from '@components';
import { useAppImagePicker } from '@components/AppImagePicker/AppImagePicker.hook';
import { UPLOAD_FILE } from '@constant';
import { IMAGE_PICKER_TYPE } from '@constant/model/RegisterForm';
import { TicketDetailStyle } from '@screens/main/ticket/TicketDetail/TicketDetail.style';
import { CommentStyle } from '@screens/main/ticket/TicketDetail/components/Comment.style';
import { CommentView } from '@screens/main/ticket/TicketDetail/components/Comment.view';
import { SendStyle } from '@screens/main/ticket/TicketDetail/components/Send.style';
import theme, { globalStyle } from '@theme';
import { t } from 'i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {FlatList, Image, Platform, ScrollView, TextInput, TouchableOpacity, View} from 'react-native';
import { AppText } from '../../../../../components/AppText/AppText';
import {useSend} from "@screens/main/ticket/TicketDetail/components/Send.hook";

export const SendView = props => {
    const {
        s3config,
        uploadFileName,
        folderUpload
    } = useSend(props)
  const { handlePickImage, handleDelete, selected = [], options, isShow, setShow } = useAppImagePicker({
      ...props,
      s3config,
      uploadFileName,
      folderUpload
  });
  const [text, setText] = useState('');
  const onSend = () => {
    if (props.onSend) {
      props.onSend({
        comment: text,
        attachments: selected,
      });
    }
  };
  return (
    <View style={[globalStyle.directionRow, { marginHorizontal: theme.spacing.md, marginVertical: theme.spacing.lg }]}>
      <TouchableOpacity
        style={[
          {
            alignItems: 'center',
            textAlignVertical: 'center',
            justifyContent: 'center',
            paddingRight: theme.spacing.sm,
          },
        ]}
        onPress={() => {
          setShow(true);
        }}>
        <CirclePlusIcon />
      </TouchableOpacity>
      <View style={[globalStyle.directionColumm, SendStyle.searchContainer, { flex: 1 }]}>
        {/*<View style={[globalStyle.directionColumm, {width: '100%'}]}>*/}
        <View
          style={[
            globalStyle.directionRow,
            { alignItems: 'center', textAlignVertical: 'center', justifyContent: 'center' },
          ]}>
          <TextInput
            multiline={true}
            placeholder={'Type message'}
            style={[
                TicketDetailStyle.searchView, {
                marginTop: 0,
                paddingBottom: 6
            }]}
            value={props.text}
            onChangeText={props.onChangeText}
            allowFontScaling={true}
          />

          {/*</View>*/}
          <TouchableOpacity
            onPress={() => {
              // onTextChanged('');
              if (props.onSend) {
                props.onSend();
              }
            }}
            style={{ marginRight: 4 }}>
            <SentIcon />
          </TouchableOpacity>
        </View>

        <View style={[SendStyle.imageWrapper, { margin: _.isEmpty(selected) ? 0 : 10 }]}>
          <FlatList
            horizontal={false}
            numColumns={3}
            data={[...selected]}
            keyExtractor={item => item.id || item}
            renderItem={({ item, index }) => {
              return (
                <View
                  style={[
                    SendStyle.imageBox,
                    (index + 1) % 3 === 0 ? SendStyle.outermostItem : {},
                    SendStyle.imageWrap,
                  ]}>
                  <Image source={{ uri: item.uri }} resizeMode="cover" style={SendStyle.image} />
                  <TouchableOpacity onPress={() => handleDelete(index)} style={SendStyle.close}>
                    <CrossIcon />
                  </TouchableOpacity>
                </View>
              );
            }}
          />
        </View>
      </View>
      <AppModal
        visible={isShow}
        onBackdropPress={() => {
          setShow(false);
        }}>
        <View
          style={SendStyle.header}
          // onLayout={e => {
          //   // console.log(e.nativeEvent.layout.height);
          // }}
        >
          <AppText style={SendStyle.headerTxt}>{t('HEADER_SELECT_IMAGE')}</AppText>
        </View>
        <ScrollView
          // ref={_ref => {
          //   if (_ref) {
          //     scrollViewRef.current = _ref;
          //   }
          // }}
          style={[{ paddingHorizontal: theme.spacing.md, marginBottom: theme.spacing.md }]}>
          <AppSingleSelection
            style={{ paddingTop: 0 }}
            // selected={selected}
            data={options}
            onSelect={item => {
              // onSelect(item);
              // handleBackdropPress();
              console.log('onSelect>>>', item);
              setShow(false);
              handlePickImage(item);
            }}
            isRadio={false}
          />
        </ScrollView>
      </AppModal>
    </View>
  );
};

SendView.propTypes = {
  onSelect: PropTypes.func,
  onChangeText: PropTypes.func,
  selected: PropTypes.array,
  type: PropTypes.string,
  onSend: PropTypes.func,
  folderUpload: PropTypes.string,
  text: PropTypes.string,
};

SendView.defaultProps = {
  selected: [],
  type: IMAGE_PICKER_TYPE.gallery,
  folderUpload: UPLOAD_FILE.folderUpload.ticket,
  text: '',
};
