import { LogoIcon, NumPadIcon, UserIcon, QRIcon } from '@assets';
import { AppSafeAreaView, AppInput, AppText, AuthFooter, AppButton, AppKAView } from '@components';
import { Device } from '@theme/device';
import { StringHelper } from '@utils/common/string';
import { useKeyboard } from '@utils/hooks';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useLogic } from './SignUpWithName.hook';
import { styles } from './SignUpWithName.style';
import {palette} from "@theme/colors";
import {globalStyle} from "@theme";

const SignUpWithName = () => {
  const { handleQRPress, formik, handleLoginPress } = useLogic();
  const { t } = useTranslation();
  const KAView = Device.isIos ? AppKAView : Fragment;
  const KAScrollView = Device.isIos ? ScrollView : KeyboardAwareScrollView;
  const keyboardShow = useKeyboard();

  return (
    <AppSafeAreaView>
      <KAView>
        <View style={styles.container}>
          <KAScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={styles.form}>
            <View style={styles.logo}>
              <LogoIcon />
            </View>
            <AppInput
              onChangeText={formik.handleChange('firstName')}
              value={formik.values.firstName}
              error={formik.errors.firstName}
              PreIcon={UserIcon}
              style={styles.mb20}
              placeholder={t('FIRST_NAME')}
            />
            <AppInput
              onChangeText={formik.handleChange('lastName')}
              value={formik.values.lastName}
              error={formik.errors.lastName}
              PreIcon={UserIcon}
              style={styles.mb20}
              placeholder={t('LAST_NAME')}
            />
            <AppInput
              // onChangeText={formik.handleChange('iCode')}
              onChangeText={text => {
                formik.setFieldValue('iCode', StringHelper.removeSpace(text));
              }}
              value={formik.values.iCode}
              error={formik.errors.iCode}
              PreIcon={NumPadIcon}
              style={styles.mb20}
              // keyboardType="number-pad"
              placeholder={t('ICODE')}
            />
            <View style={styles.divider} />
            <View style={styles.qrCode}>
              <View style={[globalStyle.directionColumm, globalStyle.center]}>
                <AppText style={styles.qrCodeText}>{t('SIGN_UP_QR_DESC')}</AppText>
                <TouchableOpacity style={{paddingTop:4}} onPress={handleQRPress}>
                  <QRIcon color={palette.ROYAL_MAIL_RED} />
                </TouchableOpacity>
              </View>
            </View>
          </KAScrollView>
          <AppButton text={t('SIGN_UP')} onPress={formik.handleSubmit} />
        </View>
      </KAView>
      {!keyboardShow && (
        <>
          <View style={styles.signUpBox}>
            <AppText style={styles.signUpText}>{t('ALREADY_HAVE_AN_ACCOUNT')}</AppText>
            <AppText onPress={handleLoginPress} style={styles.highlightText}>
              {t('LOGIN')}
            </AppText>
          </View>
          <AuthFooter />
        </>
      )}
    </AppSafeAreaView>
  );
};

export { SignUpWithName };
