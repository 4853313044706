import { LoadingView } from '@components/Loading/Loading.view';
import { FileTransform } from '@utils/transform/file';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import React from 'react';
import { Image } from 'react-native';

export const AppLazyImage = props => {
  // const [url, setUrl] = useState(props.url);
  const [uri, setUri] = useState(props.uri);
  const [loading, setLoading] = useState(false);

  console.log('AppLazyImage>>>>', props);

  const loadData = async () => {
    try {
      setLoading(true)
      const rs = await FileTransform.getImageFdbAsyncWithConfig(props.url, props.s3config);
      setUri(rs.uri);
      setLoading(false)
      if (props.onLoaded) {
        props.onLoaded(rs.uri);
      }
    }
    catch (e) {
      setLoading(false)
    }

  };
  useEffect(() => {
    if (!props.uri && props.url) {
      loadData();
    }
  }, [props.url, props.uri]);

  return (
    <>
      {loading && <LoadingView />}
      <Image source={{ uri: uri }} style={props.style} resizeMode={props.resizeMode} />
    </>
  );
};

AppLazyImage.propTypes = {
  url: PropTypes.string,
  uri: PropTypes.string,
  isS3: PropTypes.bool,
  style: PropTypes.any,
  resizeMode: PropTypes.any,
  onLoaded: PropTypes.func,
};

AppLazyImage.defaultProps = {
  isS3: true,
};
