import api from '@services/api-client';
import { stringify } from 'qs';
import withQuery from 'with-query';

export const getFormService = async payload => {
  console.log('getFormService>>>', payload);
  const formId = payload?.id ?? 1;
  let url = `/forms/${formId}.json`;
  if (payload.panelistId) {
    url = `/forms/${formId}.json?panelistId=${payload?.panelistId}`;
  }
  const response = await api.get(url);
  console.log('getFormService response>>>', response);

  return response?.data;
};

export const getFAQService = async payload => {
  console.log('getFAQService>>>', payload);
  const response = await api.get(`/forms/4.json?${stringify(payload)}`);
  console.log('getFAQService response>>>', response);

  return response?.data;
};

export const getProfileFormService = async payload => {
  console.log('getProfileFormService>>>', payload);
  const response = await api.get(`/forms/2.json?panelistId=${payload?.panelistId}`);
  console.log('getProfileFormService response>>>', response);

  return response?.data;
};

export const getForgotFormService = async payload => {
  const response = await api.get(`/forms/6.json?panelistId=${payload?.panelistId}`);

  return response?.data;
};

export const getReportModalService = async payload => {
  const response = await api.get(`/forms/5.json?panelistId=${payload?.panelistId}`);

  return response?.data;
};

export const getMenuformService = async payload => {
  console.log('getMenuformService>>>', payload);
  const response = await api.get(`/forms/3.json?${stringify(payload)}`);
  console.log('getMenuformService response>>>', response);

  return response?.data;
};

export const getReportPackageFormService = async payload => {
  console.log('getReportPackageFormService>>>', payload)
  const response = await api.get(`/forms/7.json?${stringify(payload)}`)
  console.log('getReportPackageFormService response>>>', response)

  return response?.data
}

export const getReportBundleFormService = async payload => {
  console.log('getReportBundleFormService>>>', payload)
  const response = await api.get(`/forms/9.json?${stringify(payload)}`)
  console.log('getReportBundleFormService response>>>', response)

  return response?.data
}

export const getICodeFormService = async payload => {
  console.log('getICodeFormService>>>', payload)
  const response = await api.get(`/forms/8.json?${stringify(payload)}`)
  console.log('getICodeFormService response>>>', response)

  return response?.data
}

export const getCDSFormService = async payload => {
  console.log('getCDSFormService>>>', payload)
  const response = await api.get(`/forms/10.json?${stringify(payload)}`)
  console.log('getCDSFormService response>>>', response)

  return response?.data
}

export const getD2DFormService = async payload => {
  console.log('getD2DFormService>>>', payload)
  const response = await api.get(`/forms/11.json?${stringify(payload)}`)
  console.log('getD2DFormService response>>>', response)

  return response?.data
}

export const getHolidayFormService = async payload => {
  console.log('getHolidayFormService>>>', payload)
  const response = await api.get(`/forms/report_holiday.json?${stringify(payload)}`)
  console.log('getHolidayFormService response>>>', response)

  return response?.data
}

export const getRFIDFormReceiverService = async payload => {
  console.log('getRFIDFormReceiverService>>>', payload)
  const response = await api.get(`/forms/rfid_receiver.json?${stringify(payload)}`)
  console.log('getRFIDFormReceiverService response>>>', response)

  return response?.data
}

export const getRFIDFormService = async payload => {
  console.log('getRFIDFormService>>>', payload)
  const response = await api.get(`/forms/${payload.category}.json?${stringify(payload)}`)
  console.log('getRFIDFormService response>>>', response)

  return response?.data
}

export const getQRFormService = async payload => {
  console.log('getQRFormService>>>', payload)
  const response = await api.get(`/forms/support_qr_code.json?${stringify(payload)}`)
  console.log('getQRFormService response>>>', response)

  return response?.data
}

export const getCreateTicketFormService = async payload => {
  console.log('getCreateTicketFormService>>>', payload)
  const response = await api.get(`/forms/create_ticket.json`)
  console.log('getCreateTicketFormService response>>>', response)
  return response?.data
}

export const getReturnTransponderFormService = async payload => {
  console.log('getReturnTransponderFormService>>>', payload)
  const response = await api.get(`/forms/not-returned-transponder.json?${stringify(payload)}`)
  console.log('getReturnTransponderFormService response>>>', response)
  return response?.data
}

