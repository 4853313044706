import {createAction, createSlice} from '@reduxjs/toolkit';

export interface IsaveFeedbackD2DForDailyState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IsaveFeedbackD2DForDailyState = {
  loading: false,
  payload: null,
  error: null,
};

export const saveFeedbackD2DForDailyRequest = createAction(
    'saveFeedbackD2DForDaily/saveFeedbackD2DForDailyRequest',
    (payload, onSuccess, onFailed) => {
      console.log('prepareAction>>>', payload, onSuccess);
      return {
        payload: payload,
        meta: onSuccess,
        error: onFailed,
      };
    },
);

const saveFeedbackD2DForDailySlice = createSlice({
  name: 'saveFeedbackD2DForDaily',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // saveFeedbackD2DForDailyRequest(state, action) {
    //   state.loading = true;
    //   // @ts-ignore
    //   state.payload = null;
    //   state.error = null;
    // },
    saveFeedbackD2DForDailySuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    saveFeedbackD2DForDailyFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { saveFeedbackD2DForDailySuccess, saveFeedbackD2DForDailyFailed } = saveFeedbackD2DForDailySlice.actions;

export default saveFeedbackD2DForDailySlice.reducer;
