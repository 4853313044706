import { Config } from '@constant/config';
import { FORM } from '@constant/model/RegisterForm';
import { ReportBundleMockData } from '@constant/model/mock/report/bundle';
import { useRoute } from '@react-navigation/native';
import { getDashboardRequest, getNotificationPermission, submitRFIDNotification } from '@redux/app/appSlice';
import { notificationPermission } from '@redux/app/selectors';
import { getRFIDFormRequest } from '@redux/form/getRFIDFormSlice';
import {getReportBundleFormCreator, getReturnTransponderFormCreator, getRFIDFormCreator} from '@redux/form/selectors';
import { submitRFIDRequest } from '@redux/rfid/submitRFIDSlice';
import { getProfileRequest } from '@redux/user/getProfileSlice';
import { getProfileCreator } from '@redux/user/selectors';
import { updateProfileRequest } from '@redux/user/updateProfileSlice';
import { navigatePop } from '@routes/navigationUtils';
import { AppNotification, getPanelistId } from '@utils';
import { checkFormValidateExpression, getInitialFormValue, getYupStructure } from '@utils/form';
import { useLocation } from '@utils/hooks';
import { fixFormValues, FormTransform, handleShowError, mappingStep } from '@utils/transform/form';
import { useFormik } from 'formik';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import replaceAll from 'string.prototype.replaceall';
import { lazy } from 'yup';
import * as Yup from 'yup';

export const useTransponderForm = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const paramsData = useRoute().params?.data;
  // const getProfileData = useSelector(getRFIDFormCreator)?.data;
  // const hasLocation = paramsData.hasLocation;
  const scrollViewRef = useRef(null);
  const subFormRef = useRef();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  // let lat = null;
  // let long = null;
  // if (hasLocation) {
  //   const location = useLocation(props);
  //   lat = location.lat;
  //   long = location.long;
  // }

  const getRFIDFormData = _.cloneDeep(useSelector(getReturnTransponderFormCreator)?.data)

  // const [data, setData] = useState(null);
  const data = getRFIDFormData ? mappingStep(getRFIDFormData[0]) : null

  console.log('useTransponderForm>>>', getRFIDFormData, data)

  const notificationPermissionState = useSelector(notificationPermission);
  const [value, setValue] = useState(notificationPermissionState);
  const [layoutInfo, setLayoutInfo] = useState({});
  const [messageInfo, setMessageInfo] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);

  // const [userLocation, setUserLocation] = useState({});

  const formik = useFormik({
    initialValues: {
      ...getInitialFormValue(data, {}),
    },
    // validationSchema: Yup.object().shape(getYupStructure(rawData, t)),
    validationSchema: () =>
      lazy(values => {
        // console.log('lazy>>>values>>>', values);
        const ys = getYupStructure(data, t, 'attributes', values);
        console.log('getYupStructure>>>', ys);
        return Yup.object().shape(ys);
      }),
    initialErrors: false,
    // enableReinitialize: true,
    onSubmit: async (values, { setErrors, resetForm }) => {
      console.log('onSubmit>>>', values, subFormRef);
      const rs = checkFormValidateExpression(data?.attributes, values, setErrors);
      if (!_.isEmpty(rs)) {
        return;
      }
      submitRFIDApi(values, setErrors);
    },
  });

  const scrollToY = y => {
    // scrollViewRef.current.scroll
    scrollViewRef.current?.scrollTo({
      x: 0,
      y: y,
      animated: true,
    });
  };

  const submitRFIDApi = async (values, setErrors) => {
    /*
      payload_info
       */
    // const typeRFID = replaceAll(paramsData.type, 'rfid_', '');
    const payload = {
      ...paramsData,
      ...values,
      // category: paramsData.type,
      category: 'not-returned-transponder'
    };

    delete payload.message;
    delete payload.canDelete;
    // delete payload.type
    const payloadValue = await fixFormValues(payload, values);
    console.log('updateProfileApi>>>', payloadValue);
    // return
    dispatch(
      submitRFIDRequest(
        payloadValue,
        rs => {
          //TODO: reload api
          setIsSuccess(true);
          setMessageInfo(rs.message)
          // dispatch(
          //   submitRFIDNotification({
          //     ...paramsData,
          //   }),
          // );
          // dispatch(getDashboardRequest());
          // navigatePop();
        },
        err => {
          handleShowError(err, setErrors);
        },
      ),
    );
  };

  // useEffect(() => {
  //   setUserLocation({
  //     latitude: lat,
  //     longitude: long,
  //   });
  //   console.log('location>>>', lat, long);
  // }, [lat, long]);

  // console.log('useRFID>>>', data, getRFIDFormData, paramsData);

  return {
    setValue,
    value,
    formik,
    data,
    scrollToY,
    scrollViewRef,
    layoutInfo,
    setLayoutInfo,
    // userLocation,
    subFormRef,
    messageInfo,
    isSuccess
  };
};
