import { CrossIcon, EyeActiveIcon, EyeInactiveIcon, MessageCheckIcon } from '@assets';
import { AppText } from '@components';
import { Constant } from '@constant';
import { AppLoadingMore } from '@screens/main/SearchPostcode';
import theme, { globalStyle } from '@theme';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { Font, FontSize } from '@theme/size';
import { AppSetting } from '@utils/common/setting';
import { LocationType } from '@utils/hooks';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { View, TextInput, StyleSheet, Keyboard, TouchableOpacity, Platform } from 'react-native';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { useVerifyMapFormItem } from './hook';
import { styles } from './styles';

const CheckItem = ({ msg, color, textColor, name }) => {
  return (
    <View style={[globalStyle.directionRow, globalStyle.center]}>
      {/*<MessageCheckIcon color={color} width={32} height={32} />*/}
      {/*<CrossIcon color={color} size={32}/>*/}
      <FontAwesome5 name={name} color={color} size={17} solid={true} />

      {/*<View style={[globalStyle.directionColumm, { flex: 1 }]}>*/}
      <AppText style={[Font.Bold_400, { textAlign: 'left', flex: 1, color: textColor, marginLeft: theme.spacing.ms }]}>
        {msg}
      </AppText>
      {/*</View>*/}
    </View>
  );
};

export const VerifyMapFormItem = React.memo(props => {
  console.log('VerifyMapFormItem>>>>', props);
  const { style, onLayout = null, location = null, mapSettings = null } = props;
  // eslint-disable-next-line prettier/prettier
  const { isGood, isShow } = useVerifyMapFormItem(props);
  const renderCheckItem = (isGood, locationType) => {
    if (locationType !== LocationType.gps) {
      return (
        <CheckItem
          msg={props?.mapSettings?.verifySetting?.message?.noGPS}
          color={palette.ROYAL_MAIL_RED}
          textColor={palette.VERIFY_MAP_TEXT}
          name={'times-circle'}
        />
      );
    }
    if (isGood) {
      return (
        <CheckItem
          msg={props?.mapSettings?.verifySetting?.message?.verifed}
          color={palette.GREEN_ACTIVE}
          textColor={palette.VERIFY_MAP_TEXT}
          name={'check-circle'}
        />
      );
    }
    return (
      <CheckItem
        msg={props?.mapSettings?.verifySetting?.message?.nonVerifed}
        color={palette.ROYAL_MAIL_RED}
        textColor={palette.VERIFY_MAP_TEXT}
        name={'times-circle'}
      />
    );
  };
  return (
    <>
      {!!isShow && (
        <TouchableOpacity
          style={[styles.container, style]}
          onPress={() => {
            if (props.onSelect) {
              props.onSelect();
            }
          }}
          onLayout={e => {
            if (onLayout) {
              onLayout(e);
            }
          }}>
          {renderCheckItem(isGood, props?.locationType)}
        </TouchableOpacity>
      )}
    </>
  );
});
