import { Constant } from '@constant';
import { redeemVoucherService } from '@services/user';
// @ts-ignore
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { redeemVoucherFailed, redeemVoucherSuccess } from './redeemVoucherSlice';
import {getListDashboardDetailSaga} from "@redux/app/sagas";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* redeemVoucherSaga({ payload, meta, error }): Generator<
  | CallEffect
  | PutEffect<{
      payload: any;
      type: string;
    }>,
  void
> {
  try {
    const res = yield call(redeemVoucherService, payload);
    console.log('redeemVoucherSaga>>>>', res);
    if (res?.status) {
      yield call(getListDashboardDetailSaga, { internalIds: res?.triggerActions ?? [] });
      yield put(redeemVoucherSuccess(res));
      if (meta) {
        yield call(meta, res);
      }
    } else {
      // @ts-ignore
      yield put(redeemVoucherFailed(res));
      if (error) {
        yield call(error, res);
      }
    }
  } catch (err) {
    // @ts-ignore
    yield put(redeemVoucherFailed(err));
    if (error) {
      yield call(error, err);
    }
  }
}
