import { palette } from '@theme/colors';
import { FontSize, Spacing } from '@theme/size';
import { StyleSheet } from 'react-native';

export const stylesWeb = StyleSheet.create({
  container: {
    paddingHorizontal: Spacing.width15,
    flex: 1,
    // maxWidth: "850px",
  },
  form: {
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    gap: Spacing.gap20,
  },
  logo: {
    marginBottom: Spacing.height18,
  },
  rememberMe: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  highlightText: {
    color: palette.ROYAL_MAIL_RED,
    fontSize: FontSize.Font14,
    marginLeft: Spacing.width4,
  },
  signUpBox: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: Spacing.height10,
    backgroundColor: 'white',
  },
  signUpText: {
    color: '#666666',
    fontSize: FontSize.Font14,
  },
});
