import theme from '@theme';
import { FontSize, Spacing } from '@theme/size';
import { StyleSheet } from 'react-native';

export const AddressContainerStyle = StyleSheet.create({
  textTitle: {
    fontWeight: '700',
    // marginBottom: 10,
    fontSize: FontSize.Font15,
    marginHorizontal: theme.spacing.md,
    marginTop: theme.spacing.md,
  },
});
