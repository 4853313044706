import React from 'react';
import {BarCodeScanner} from 'expo-barcode-scanner';

export const _default = {};

/**
 * An empty component used to manage SplashScreenWeb from Suspense fallback
 */
export const openSettings = () => {
    // enter code for require permision camera here (to show popup)
    return new Promise((res) => {
        return res('Success');
    })
};

const check = async () => {
    return await BarCodeScanner.requestPermissionsAsync();
};

const request = async () => {
    const {status} = await BarCodeScanner.requestPermissionsAsync();
    return status;
};

const RESULTS = Object.freeze({
    UNAVAILABLE: 'unavailable',
    BLOCKED: 'blocked',
    DENIED: 'denied',
    GRANTED: 'granted',
    LIMITED: 'limited',
} as const);

export default _default;
export { check, request, RESULTS};

