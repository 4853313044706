import type { RootState } from '@redux/reducers';
import { createSelector } from '@reduxjs/toolkit';
import { getErrorMessage } from '@utils/api';
import {mappingHolidays} from "@utils/transform/holiday";

export const getListHolidaysSelector = (state: RootState) => state.getListHolidays;
export const getListHolidaysCreator = createSelector(getListHolidaysSelector, state => state.payload);
export const getListHolidaysLoading = createSelector(getListHolidaysSelector, state => state.loading);
export const getListHolidaysError = createSelector(getListHolidaysSelector, state => state.error);
export const getListHolidaysErrorMsg = createSelector(getListHolidaysError, state => getErrorMessage(state));
export const getListHolidaysStandard = createSelector(getListHolidaysCreator, state => mappingHolidays(state?.data));

export const addHolidaySelector = (state: RootState) => state.addHoliday;
export const addHolidayCreator = createSelector(addHolidaySelector, state => state.payload);
export const addHolidayLoading = createSelector(addHolidaySelector, state => state.loading);
export const addHolidayError = createSelector(addHolidaySelector, state => state.error);
export const addHolidayErrorMsg = createSelector(addHolidayError, state => getErrorMessage(state));

export const deleteHolidaySelector = (state: RootState) => state.deleteHoliday;
export const deleteHolidayCreator = createSelector(deleteHolidaySelector, state => state.payload);
export const deleteHolidayLoading = createSelector(deleteHolidaySelector, state => state.loading);
export const deleteHolidayError = createSelector(deleteHolidaySelector, state => state.error);
export const deleteHolidayErrorMsg = createSelector(deleteHolidayError, state => getErrorMessage(state));

