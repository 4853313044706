import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { FontSize, Spacing } from '@theme/size';
import { StyleSheet, Platform } from 'react-native';

export const styles = StyleSheet.create({
  areaStyle: { backgroundColor: '#EDEDED' },
  container: {
    borderTopRightRadius: Spacing.width20,
    borderTopLeftRadius: Spacing.width20,
    paddingHorizontal:  Platform.OS === 'web' ? Spacing.width6 : Spacing.width16,
    paddingBottom:  Platform.OS === 'web' ? Spacing.width6 : Spacing.width16,
    borderColor: 'transparent',
    flex: 1,
    paddingTop: 15,
  },
  form: {
    marginTop: Spacing.height38,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  logo: {
    marginBottom: Spacing.height38,
  },
  rememberMe: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  highlightText: {
    color: palette.ROYAL_MAIL_RED,
    fontSize: FontSize.Font14,
    marginLeft: Spacing.width4,
    paddingVertical: Spacing.height10,
  },
  signUpBox: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: Spacing.height6,
  },
  signUpText: {
    color: '#666666',
    fontSize: FontSize.Font15,
  },
  fakePadding: {
    flex: 1,
    borderTopRightRadius: Spacing.width20,
    borderTopLeftRadius: Spacing.width20,
    backgroundColor: 'white',
  },
  mb20: {
    marginBottom: Spacing.height20,
  },
  mb10: {
    marginBottom: Spacing.height10,
  },
});
