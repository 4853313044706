import theme from '@theme';
import { palette } from '@theme/colors';
import { FontSize } from '@theme/size';
import { StyleSheet } from 'react-native';

export const QuizFailedStyle = StyleSheet.create({
  textTitle: {
    color: palette.ROYAL_MAIL_RED,
    paddingHorizontal: theme.spacing.lg,
    fontSize: FontSize.Font20,
  },
  textDescription: {
    color: '#333333',
    paddingHorizontal: theme.spacing.lg,
    fontSize: FontSize.Font16,
  },
  lineView: {
    height: 1,
    backgroundColor: '#DDDDDD',
    width: '100%',
    // flex: 1,
    marginVertical: theme.spacing.md,
  },
  containerView: {
    borderTopRightRadius: theme.spacing.lg,
    borderTopLeftRadius: theme.spacing.lg,
    backgroundColor: palette.WHITE,
    paddingTop: theme.spacing.lg,
    // paddingHorizontal: theme.spacing.md,
  },
  formItemView: {
    // marginBottom: theme.spacing.sm,
  },
  titleForm: {
    color: palette.ROYAL_MAIL_RED,
  },
  hintView: {
    borderRadius: theme.spacing.sm,
    backgroundColor: '#F7F7F7',
    padding: theme.spacing.md,
    borderWidth: 1,
    borderColor: '#C6C6C6',
    // marginBottom: theme.spacing.md,
    marginBottom: 4,

    color: palette.MAIN_BLACK,
    fontSize: FontSize.Font15,
  },

  failedItemForm: {
    borderColor: '#DA202A',
    borderWidth: 1,
    paddingVertical: 12,
    paddingHorizontal: 8,
    borderRadius: 4,
    marginBottom: 4,
    backgroundColor: '#F9ECEC',
  },
  passedItemForm: {
    borderColor: palette.GREEN_ACTIVE,
    borderWidth: 1,
    paddingVertical: 12,
    paddingHorizontal: 8,
    borderRadius: 4,
    marginBottom: 4,
    backgroundColor: '#8BB73B30',
  },
});
