import { Config } from '@constant/config';
import { ReportBundleMockData } from '@constant/model/mock/report/bundle';
import { ReportICodeMockData } from '@constant/model/mock/report/iCode';
import { ReportPackageMockData } from '@constant/model/mock/report/package';
import { useRoute } from '@react-navigation/native';
import { reportModal as reportModalSelector } from '@redux/app/selectors';
import { getICodeFormRequest } from '@redux/form/getICodeFormSlice';
import { getICodeFormCreator } from '@redux/form/selectors';
import { submitICodeRequest } from '@redux/iCode/submitICodeSlice';
import {goBack, navigate, popToTop} from '@routes/navigationUtils';
import { ReportModalTransform } from '@utils';
import { checkFormValidateExpression, getInitialFormValue, getYupStructure } from '@utils/form';
import { useLocation } from '@utils/hooks';
import { mappingStep } from '@utils/transform/form';
import { useFormik } from 'formik';
import _ from 'lodash';
import {useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { lazy } from 'yup';
import * as Yup from 'yup';
import {SuccessMessageMock} from "@constant/model/mock/successMessage";
import {Screens} from "@routes/route";
import {NavigateEvent, NavigationHelper} from "@utils/common/navigation";
import {FormikHelper} from "@utils/hooks/formik";
import {hideLoading, showLoading} from "@redux/global/globalSlice";

export const useReportICodeForm = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { lat, long, loading, handleGetLocation, locationType } = useLocation(props);
  const scrollViewRef = useRef(null);
  const [layoutInfo, setLayoutInfo] = useState({});

  const paramsData = useRoute().params?.data;
  const summary = {
    id: paramsData?.id,
  };
  const formModal = useSelector(reportModalSelector);
  const headerTitle = ReportModalTransform.getReportTitle(paramsData?.reportType, formModal);
  const getICodeFormData = useSelector(getICodeFormCreator)?.data;
  // const rawData = mappingStep(ReportICodeMockData.form);
  // const rawData = mappingStep(!_.isEmpty(getICodeFormData) ? getICodeFormData[0] : null);

  const [data, setData] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const lo = Config.IsMockServer ? ReportBundleMockData.userLocation : {};
  const [userLocation, setUserLocation] = useState(lo);
  const [point, setPoint] = useState(0);
  const [messageInfo, setMessageInfo] = useState({});
  const [disabled, setDisabled] = useState(false);

  const scrollToY = y => {
    // scrollViewRef.current.scroll
    scrollViewRef.current?.scrollTo({
      x: 0,
      y: y,
      animated: true,
    });
  };

  const getICodeFormApi = () => {
    const payload = {
      s_id: paramsData?.payload?.s_id,
    };
    // dispatch(getICodeFormRequest(payload));
    dispatch(showLoading());
    dispatch(
        getICodeFormRequest(
            payload,
            res => {
              dispatch(hideLoading());
            },
            err => {
              dispatch(hideLoading());
            },
        ),
    );
  };

  const formik = FormikHelper.useFormikWrapper({
    initialValues: getInitialFormValue(data, {}),
    enableReinitialize: true,
    // validationSchema: Yup.object().shape(getYupStructure(rawData, t)),
    validationSchema: () =>
      lazy(values => {
        // console.log('lazy>>>values>>>', values);
        const ys = getYupStructure(data, t, 'attributes', values);
        console.log('getYupStructure>>>', ys, formik);
        return Yup.object().shape(ys);
      }),
    initialErrors: false,

    onSubmit: (values, { setErrors, resetForm }) => {
      console.log('onSubmit>>>', values, formik.errors, data);
      const rs = checkFormValidateExpression(data?.attributes, values, setErrors);
      if (!_.isEmpty(rs)) {
        formik.setErrors(_.merge(formik.errors, rs));
        return;
      }

      handleSubmit(values);

      //TODO: validate here
      // updateUserApi(values, isLastStep);
      // onSavePress();
    },
  }, data?.attributes);

  const submitICodeApi = payload => {
    /*
  params:
  {
      "Token": "5aa5ce7dafc519e7a16d222a436938c40624606a24b70e8168572adab8c5703d",
      "Accept": "application/json",
      "spectos-apikey": "{{spectos-apikey}}"
  }
  body:
  {
      "receipt_date_known": true,
      "receipt_date": "2022-09-08",
      "receipt_date_sure": true,
      "receipt_time_known": true,
      "receipt_time": "14:30",
      "receipt_time_sure": true,
      "franking_value": "0.55",
      "user_location": ["51.5","0.2"]
  }
   */
    // const payload = {}
    // if()
    if (data?.canReview) {
      navigate(Screens.ICODE_FORM_REVIEW_SCREEN, { data: _.cloneDeep(data), values: _.cloneDeep(payload), header:{
        title: headerTitle
        } });
      return;
    }
    if (disabled) {
      return;
    }
    setDisabled(true);

    dispatch(
      submitICodeRequest(
        payload,
        rs => {
          handleSuccess(rs);
          setDisabled(false);
        },
        err => {
          setDisabled(false);
        },
      ),
    );
  };

  const handleSuccess = rs => {
    //TODO: reload api dashboard here
    setPoint(rs?.data?.point ?? 0);
    // setIsSuccess(true);
    // setMessageInfo(rs?.message)
    navigate(Screens.SUCCESSFUL_SCREEN, {
      data: rs?.message,
      header: {
        title: headerTitle,
        backClick: () => {
          popToTop();
        },
      },
    });
    // setTimeout(() => {
    //   popToTop();
    // }, 5000);
  };

  const handleSubmit = values => {
    let payload = {
      ...values,
      id: paramsData.id,
      s_id: paramsData?.payload?.s_id,
      location_type: locationType
    };
    if (!_.isEmpty(userLocation) && !!userLocation?.longitude) {
      payload.user_location = Object.values(userLocation);
    } else {
      payload.user_location = null;
    }

    const mock = Config.IsMockServer;
    if (mock) {
      handleSuccess();
    } else {
      submitICodeApi(payload);
    }
  };

  console.log('useReportICodeForm>>>data', data);
  useEffect(() => {
    getICodeFormApi();
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(hideLoading())
    }, 3000)
  }, [])

  useEffect(() => {
    const unsubscribe = props.navigation?.addListener(NavigateEvent.transitionEnd, e => {
      // Do something
      setTimeout(() => {
        const currentScreen = NavigationHelper.getCurrentScreen(props?.navigation)
        if (currentScreen === Screens.REPORT_ICODE_FORM_SCREEN) {
          handleGetLocation()
        }
      },100)
    });
    return unsubscribe;
  }, [props.navigation]);

  useEffect(() => {
    const x = _.cloneDeep(getICodeFormData);
    const rd = mappingStep(!_.isEmpty(x) ? x[0] : null);
    setData(rd);
  }, [getICodeFormData]);

  useEffect(() => {
    setUserLocation({
      latitude: lat,
      longitude: long,
    });
    console.log('location>>>', lat, long);
  }, [lat, long]);

  return { formik, data, summary, isSuccess, point, headerTitle,
    scrollToY,
    scrollViewRef,
    layoutInfo, setLayoutInfo,
    messageInfo
  };
};
