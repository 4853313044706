import { Config } from '@constant/config';
import { store } from '@redux/store';
import React from 'react';
import _ from 'lodash'

export const numberWithDots = num => {
  return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0;
};

export const isJsonString = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getAccessToken = () => {
  const state = store.getState();
  const accessToken = Config.IsUseToken ? Config.Mock.Token : state?.auth?.user?.accessToken;
  return accessToken;
};

export const getAccountId = () => {
  const state = store.getState();
  const accountId = state?.getSettings?.payload?.data?.upload?.accountId;
  return accountId;
};

export const getDashboardSetting = () => {
  const state = store.getState();
  const dashboard = state?.getSettings?.payload?.data?.dashboard;
  return dashboard;
};

export const getListDashboardInternalId = () => {
  const state = store.getState();
  const dashboard = state?.app?.dashboard;
  if(_.isEmpty(dashboard)){
    return []
  }
  return dashboard.map(x => x?.internalId);
}

export const getPanelistId = () => {
  const state = store.getState();
  const panelistID = state?.auth?.user?.panelistID;
  return panelistID;
};

export function getRegionForCoordinates(points) {
  // points should be an array of { latitude: X, longitude: Y }
  let minX, maxX, minY, maxY;

  // init first point
  (point => {
    minX = point.latitude;
    maxX = point.latitude;
    minY = point.longitude;
    maxY = point.longitude;
  })(points[0]);

  // calculate rect
  points.map(point => {
    minX = Math.min(minX, point.latitude);
    maxX = Math.max(maxX, point.latitude);
    minY = Math.min(minY, point.longitude);
    maxY = Math.max(maxY, point.longitude);
  });

  const midX = (minX + maxX) / 2;
  const midY = (minY + maxY) / 2;
  const deltaX = maxX - minX;
  const deltaY = maxY - minY;

  return {
    latitude: midX,
    longitude: midY,
    latitudeDelta: deltaX,
    longitudeDelta: deltaY,
  };
}

export const getSettingNotification = () => {
  const state = store.getState();
  const notification = state?.getSettings?.payload?.data?.notification;
  return notification;
}

export * from './reportModalHelper';
