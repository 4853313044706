import { FORM } from '@constant/model/RegisterForm';
import { Device } from '@theme/device';
import { DATE_FORMAT, FORMAT, formatDate } from '@utils/common/MomentUtils';
import _ from 'lodash';
import rnTextSize, { TSFontSpecs } from 'react-native-text-size';
import replaceAll from 'string.prototype.replaceall';

export const HtmlUtil = {
  isHtml: text => {
    if (_.isEmpty(text)) {
      return false;
    }

    return /<(br|basefont|hr|input|source|frame|param|area|meta|!--|col|link|option|base|img|wbr|!DOCTYPE).*?>|<(a|abbr|acronym|address|applet|article|aside|audio|b|bdi|bdo|big|blockquote|body|button|canvas|caption|center|cite|code|colgroup|command|datalist|dd|del|details|dfn|dialog|dir|div|dl|dt|em|embed|fieldset|figcaption|figure|font|footer|form|frameset|head|header|hgroup|h1|h2|h3|h4|h5|h6|html|i|iframe|ins|kbd|keygen|label|legend|li|map|mark|menu|meter|nav|noframes|noscript|object|ol|optgroup|output|p|pre|progress|q|rp|rt|ruby|s|samp|script|section|select|small|span|strike|strong|style|sub|summary|sup|table|tbody|td|textarea|tfoot|th|thead|time|title|tr|track|tt|u|ul|var|video).*?<\/\2>/i.test(
      text,
    );
  },

  getFormatValue: (text, values = null, structure = []) => {
    if (_.isEmpty(values)) {
      return text;
    }
    if (text.includes('{{') && text.includes('}}')) {
    }
    const regexp = /{{(\w+)}}/g;
    const matches = text.match(regexp);
    const arr = [...new Set(matches)];
    for (const matchesKey of arr) {
      const key = matchesKey.replace('{{', '').replace('}}', '');
      let value = values[key];
      const item = structure.find(x => x.internalId === key);
      if (!_.isEmpty(item)) {
        const type = item.type;
        switch (type) {
          case FORM.FORM_TYPE.calendar:
            value = formatDate(value, FORMAT.Calendar.format, FORMAT.Calendar.fromFormat);
            text = replaceAll(text, matchesKey, value);
            break;
          case FORM.FORM_TYPE.date:
            value = formatDate(value, FORMAT.DatePicker.format, FORMAT.DatePicker.fromFormat);
            text = replaceAll(text, matchesKey, value);
            break;
          case FORM.FORM_TYPE.time:
            value = formatDate(value, FORMAT.TimePicker.format, FORMAT.TimePicker.fromFormat);
            text = replaceAll(text, matchesKey, value);
            break;
          case FORM.FORM_TYPE.datetime:
            value = formatDate(value, FORMAT.DateTimePicker.format, FORMAT.DateTimePicker.fromFormat);
            text = replaceAll(text, matchesKey, value);
            break;
        }
      }
      text = replaceAll(text, matchesKey, value);
    }
    return text;
  },
  getTextSizeInfo: async (content, width, fontSize = 14, numLine = 3) => {
    // const width = Device.width;
    const fontSpecs = {
      fontSize: fontSize,
    };
    const rs = await rnTextSize.measure({
      text: content,
      width,
      lineInfoForLine: numLine - 1,
      ...fontSpecs,
    });
    console.log('getShortPlanText>>>', rs);
    return rs;
  },
  toPlainText: html => {
    let htmlLine = html?.replace('<br>', '\n');
    htmlLine = htmlLine?.replace('<br/>', '\n');
    return htmlLine?.replace(/<[^>]+>/g, '');
  },
};
