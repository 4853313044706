import { createSlice } from '@reduxjs/toolkit';

export interface IgetListStatusState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IgetListStatusState = {
  loading: false,
  payload: null,
  error: null,
};

const getListStatusSlice = createSlice({
  name: 'getListStatus',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getListStatusRequest(state, action) {
      state.loading = true;
      // @ts-ignore
      // state.payload = null;
      state.error = null;
    },
    getListStatusSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    getListStatusFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getListStatusRequest, getListStatusSuccess, getListStatusFailed } = getListStatusSlice.actions;

export default getListStatusSlice.reducer;
