import { CommonImages, CrossIcon, ImageIcon, LogoIcon, MessageCheckIcon } from '@assets';
import { AppKAView, AppSafeAreaView, AppText } from '@components';
import { AppLazyImage } from '@components/AppLazyImage';
import { AppConfirmModal } from '@components/AppModal/AppConfirmModal';
import { UPLOAD_FILE } from '@constant';
import { AppHtmlView } from '@screens/main/quiz/QuizFailed/components/AppNote.view';
import Colors from '@screens/main/report/ListReport/Colors';
import { CommentStyle } from '@screens/main/ticket/TicketDetail/components/Comment.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font } from '@theme/size';
import { FileTransform } from '@utils/transform/file';
import { FormTransform } from '@utils/transform/form';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, FlatList, TouchableOpacity, Image } from 'react-native';
import FastImage from 'react-native-fast-image';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { date } from 'yup';
import { useMisDelivered } from './MisDelivered.hook';
import { MisDeliveredStyle as styles } from './MisDelivered.style';

export const MisDeliveredView = props => {
  const { t } = useTranslation();
  const {
    handlePickImage,
    handleDelete,
    onItemSelected,
    onYesDelete,
    showModal,
    onDelete,
    onNoDelete,
    scrollViewRef,
    onLayout,
  } = useMisDelivered(props);
  const { isRequired, title, selected, error, hint, isBoldTitle, style, boxStyle } = props;
  // const handlePickImage =
  console.log('MisDeliveredView>>>', selected, error);
  return (
    <View
      style={[styles.container, style]}
      onLayout={e => {
        if (onLayout) {
          onLayout(e);
        }
      }}>
      {!!title && (
        <AppText style={[styles.title, isBoldTitle ? { fontWeight: '700' } : {}]}>
          {isRequired && <AppText style={styles.isRequired}>* </AppText>}
          {title}
        </AppText>
      )}
      <View style={styles.wrapper}>
        <FlatList
          horizontal={false}
          numColumns={3}
          data={['createItem', ...selected]}
          keyExtractor={item => item.id || item}
          renderItem={({ item, index }) => {
            if (item === 'createItem')
              return (
                <TouchableOpacity onPress={handlePickImage} style={[styles.box, boxStyle]}>
                  <Image
                    style={{ width: '100%', height: '100%' }}
                    source={CommonImages.ImagePicker}
                    resizeMode="contain"
                  />
                  <View style={{ position: 'absolute', opacity: 0.5 }}>
                    <FontAwesome5 name={'plus'} size={24} color={'#666666'} />
                  </View>
                </TouchableOpacity>
              );
            else {
              const firstImage = FormTransform.getFirstImageFormik(selected[index - 1], props?.data?.subForms ?? []);
              console.log('firstImage>>>', firstImage, selected, item);
              return (
                <TouchableOpacity
                  onPress={() => onItemSelected(index)}
                  style={[styles.box, (index + 1) % 3 === 0 ? styles.outermostItem : {}, styles.imageWrap]}>
                  <TouchableOpacity onPress={() => onDelete(index)} style={styles.close}>
                    <CrossIcon />
                  </TouchableOpacity>

                  {/*<TouchableOpacity style={{ width: '100%' }}>*/}
                  <AppLazyImage
                    style={styles.image}
                    uri={firstImage?.uri}
                    url={FileTransform.fixUrl(firstImage?.url)}
                    s3config={firstImage?.s3config}
                    resizeMode={'cover'}
                    onLoaded={uri => {
                      //TODO: cached here
                      // item.uri = uri
                      // onLoadedUri(item, uri);
                      if (firstImage) {
                        firstImage.uri = uri;
                      }
                    }}
                  />
                  {/*</TouchableOpacity>*/}
                </TouchableOpacity>
              );
            }
          }}
        />
      </View>
      {!!hint && <AppHtmlView style={styles.hint} text={hint} />}
      {!!error && <AppText style={styles.error}>{error}</AppText>}
      <AppConfirmModal
        onYesModalPress={onYesDelete}
        onNoModalPress={onNoDelete}
        showModal={showModal}
        textContent={t('CDS_CONTENT_DELETE_MISDELIVERED_ITEM')}
      />
    </View>
  );
};

MisDeliveredView.propTypes = {
  isRequired: PropTypes.bool,
  selected: PropTypes.any,
  title: PropTypes.string,
  hint: PropTypes.string,
  error: PropTypes.string,
  isBoldTitle: PropTypes.bool,
  style: PropTypes.any,
  boxStyle: PropTypes.any,
  data: PropTypes.any,
  onSubmitSuccess: PropTypes.func,
  onSelect: PropTypes.func,
  extraFormValues: PropTypes.object,
  formik: PropTypes.object,
  formData: PropTypes.any,
  scrollViewRef: PropTypes.any,
  onCheckValid: PropTypes.func,
  extraData: PropTypes.any,
  onLayout: PropTypes.func,
};

MisDeliveredView.defaultProps = {
  selected: [],
};
