import { Config } from '@constant/config';
import api from '@services/api-client';
import withQuery from 'with-query';

export async function signUpWithNameAndCode({ firstName, lastName, iCode }) {
  try {
    const url = withQuery(`/users/validate.json`, {
      firstname: firstName,
      lastname: lastName,
      icode: iCode,
    });

    // if (Config.IsMockServer) {
    //   return {
    //     status: true,
    //     data: {
    //       // panelistID: '56b8563e-cf00-4706-bdcc-389b7c126385',
    //       panelistID: '62f2101ffca0847e9bec5870',
    //     },
    //   };
    // }

    const rs = await api.get(url);
    return rs?.data;
  } catch (error) {
    console.error('Error: ', error);
    throw error;
  }
}

export async function activateUser(data) {
  const { panelistId, activationToken } = data;
  try {
    const url = withQuery(`/users/${panelistId}/activate.json?token=${activationToken}`);
    // const rs = await api.postForm(url, data);
    const rs = await api.get(url);

    return rs?.data;
  } catch (error) {
    console.error('Error:12312321 ', error);
    throw error;
  }
}

export async function login(formData) {
  try {
    const url = withQuery(`/user/login.json`);
    const rs = await api.postForm(url, formData);
    console.log('login response>>>', rs)
    return rs?.data;
  } catch (error) {
    console.error('Error: ', error);
    throw error;
  }
}

export async function forgotPassword(formData) {
  try {
    const url = withQuery(`/user/forgotPassword.json`);
    const rs = await api.postForm(url, formData);
    console.log({ rs });
    return rs?.data;
  } catch (error) {
    console.error('Error: ', error);
    throw error;
  }
}

export async function validateResetToken(resetToken) {
  try {
    const url = withQuery(`/user/resetPassword.json?reset_token=${resetToken}`);
    const res = await api.get(url);
    console.log({ res });
    return res.data;
  } catch (error) {
    console.error('Error: ', error);
    throw error;
  }
}

export async function resetPassword(formData) {
  try {
    const url = withQuery(`/user/resetPassword.json`);
    const res = await api.postForm(url, formData);
    console.log({ res });
    return res.data;
  } catch (error) {
    console.error('Error: ', error);
    throw error;
  }
}
