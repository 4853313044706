import { Constant } from '@constant';
import { getListPostingLocationService } from '@services/bundle';
// @ts-ignore
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { getListPostingLocationFailed, getListPostingLocationSuccess } from './getListPostingLocationSlice';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* getListPostingLocationSaga({ payload, meta, error }): Generator<
  | CallEffect
  | PutEffect<{
      payload: any;
      type: string;
    }>,
  void
> {
  try {
    const res = yield call(getListPostingLocationService, payload);
    console.log('getListPostingLocationSaga>>>>', res);
    if (res?.status) {
      yield put(getListPostingLocationSuccess(res));
      if (meta) {
        yield call(meta, res);
      }
    } else {
      // @ts-ignore
      yield put(getListPostingLocationFailed(res));
      if (error) {
        yield call(error, res);
      }
    }
  } catch (err) {
    // @ts-ignore
    yield put(getListPostingLocationFailed(err));
    yield call(error, err);
  }
}
