import { Constant } from '@constant';
import { submitPackageService } from '@services/package';
// @ts-ignore
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { submitPackageFailed, submitPackageSuccess } from './submitPackageSlice';
import {getListDashboardDetailSaga} from "@redux/app/sagas";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* submitPackageSaga({ payload, meta, error }): Generator<
  | CallEffect
  | PutEffect<{
      payload: any;
      type: string;
    }>,
  void
> {
  try {
    const res = yield call(submitPackageService, payload);
    console.log('submitPackageSaga>>>>', res);
    if (res?.status) {
      yield call(getListDashboardDetailSaga, { internalIds: res?.triggerActions ?? [] });

      yield put(submitPackageSuccess(res));
      if (meta) {
        yield call(meta, res);
      }
    } else {
      // @ts-ignore
      yield put(submitPackageFailed(res));
      if (error) {
        yield call(error, res);
      }
    }
  } catch (err) {
    // @ts-ignore
    yield put(submitPackageFailed(err));
    if (error) {
      yield call(error, err);
    }
  }
}
