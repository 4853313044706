import { TicketMockData } from '@constant/model/mock/ticketMockData';
import { useRoute } from '@react-navigation/native';
import { addCommentRequest } from '@redux/ticket/addCommentSlice';
import { addCommentLocal, getTicketDetailRequest } from '@redux/ticket/getTicketDetailSlice';
import { getListStatusCreator, getTicketDetailCreator } from '@redux/ticket/selectors';
import { getProfileCreator } from '@redux/user/selectors';
import React, { useEffect, useState } from 'react';
import { Text } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

export const useTicketDetail = props => {
  const paramsData = useRoute().params?.data;
  const getTicketDetailData = useSelector(getTicketDetailCreator)?.data;
  const getProfileData = useSelector(getProfileCreator)?.message?.data;

  // const getTicketDetailData = TicketMockData.data;
  const data = paramsData._id === getTicketDetailData?._id ? getTicketDetailData : paramsData;
  const getListStatusData = useSelector(getListStatusCreator)?.data;
  const headerTitle = `ID: ${data?.properties?.incrementalID}`;
  console.log('useTicketDetail>>>', data, getListStatusData);
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const [text, setText] = useState('');

  const getTicketDetailApi = () => {
    const payload = { _id: data?._id };
    dispatch(getTicketDetailRequest(payload));
  };

  const addCommentApi = () => {
    const payload = {
      ticketId: data?._id,
      comment: text,
      attachments: images?.map(x => {
        return {
          ...x,
          response: x.url,
        };
      }),
    };

    // dispatch(
    //   addCommentLocal({
    //     ...payload,
    //     name: `${getProfileData?.firstname} ${getProfileData?.lastname}`,
    //     email: getProfileData?.email,
    //   }),
    // );
    dispatch(
      addCommentRequest(
        payload,
        rs => {
          setText('');
          setImages([]);
          // getTicketDetailApi();
          dispatch(
            addCommentLocal({
              ...payload,
              name: `${getProfileData?.firstname} ${getProfileData?.lastname}`,
              email: getProfileData?.email,
            }),
          );
        },
        err => {},
      ),
    );
  };

  const onSend = () => {
    addCommentApi();
  };

  useEffect(() => {
    getTicketDetailApi();
  }, []);
  return { data, getListStatusData, headerTitle, images, setImages, onSend, text, setText };
};
