import { AppText, AppBottomSheet, AppInput, AppButton } from '@components';
import { useReportModal } from '@components/ReportModal/ReportModal.hook';
import { Constant } from '@constant';
import { navigate } from '@routes/navigationUtils';
import { Screens } from '@routes/route';
import { reportModal } from '@utils/common';
import React, { useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import { Modalize } from 'react-native-modalize';
import { styles } from './ReportModal.style';

const ReportItem = props => {
  const { data: dataReport } = props;
  const { t } = useTranslation();

  return (
    <View style={styles.reportItem}>
      <AppText style={styles.titleReport}>{dataReport.title}:</AppText>
      {dataReport?.data?.map(item => {
        return (
          <TouchableOpacity
            key={item.id}
            style={[styles.box]}
            onPress={() => {
              if (props.onPress) {
                props.onPress(item);
              }
            }}>
            <AppText style={styles.text}>
              {item.name}{' '}
              {item.type === 'coming-soon' && (
                <AppText style={[styles.text, { color: '#999999' }]}>({t('COMING_SOON')})</AppText>
              )}
            </AppText>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

export const ReportModal = props => {
  const { onReportPress, onItemPress, data } = useReportModal(props);
  return (
    <AppBottomSheet adjustToContentHeight modalRef={reportModal.ref}>
      <ScrollView contentContainerStyle={styles.scrollView}>
        {data?.map(item => {
          return <ReportItem key={item.id} data={item} onPress={onItemPress} />;
        })}
      </ScrollView>
    </AppBottomSheet>
  );
};
