import { FormMockData } from '@constant/model/mock/formMockData';
import { UserMockData } from '@constant/model/mock/userMockData';
import { CommonActions, useNavigation } from '@react-navigation/native';
// import { registerUserCompleted } from '@redux/user/updateUserSlice';
import { resetUserValidation } from '@redux/auth/authSlice';
import { authCreator } from '@redux/auth/selectors';
import { getFormCreator, getFormStandard } from '@redux/form/selectors';
import {
  updateUserCreator,
  updateUserSelector,
  updateUserError,
  updateUserErrorMsg,
  updateUserLoading,
  getUserCreator,
} from '@redux/user/selectors';
import { updateUserRequest } from '@redux/user/updateUserSlice';
import { navigate, navigatePop, navigatePush } from '@routes/navigationUtils';
import { LOGIN_SCREEN, Screens } from '@routes/route';
import { showErrorMsg } from '@utils/api';
import { getInitialFormValue, getPayloadStep, getYupStructure } from '@utils/form';
import { fixFormValues, handleShowError } from '@utils/transform/form';
import { useFormik } from 'formik';
import { cloneDeep } from 'lodash';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { lazy } from 'yup';
import {FormikHelper} from "@utils/hooks/formik";

export const useRegisterForm = props => {
  const paramsStep = props?.route.params?.step ?? 1;
  const getFormData = useSelector(getFormStandard);
  const rawData = cloneDeep(getFormData[paramsStep - 1]);
  const navigation = useNavigation();

  const isLastStep = parseInt(paramsStep) === getFormData.length;
  const isFirstStep = parseInt(paramsStep) === 1;
  const [step, setStep] = useState(paramsStep);
  const [data, setData] = useState(rawData);

  const { t } = useTranslation();
  //TODO: generate formik + schema here

  const dispatch = useDispatch();
  const userValidation = useSelector(authCreator);
  const getUserData = useSelector(getUserCreator)?.message?.data;
  const updateUserData = useSelector(updateUserCreator);
  const updateUserWithLoading = useSelector(updateUserLoading);
  const updateUserWithErrorMsg = useSelector(updateUserErrorMsg);
  const updateUserApi = async (values, isLast = false, setErrors = null) => {
    const filterValues = _.cloneDeep(getPayloadStep(values, getUserData));
    const payloadValue = await fixFormValues(filterValues, values);
    console.log('updateUserApi>>>', payloadValue, filterValues, values);
    if (_.isEmpty(payloadValue)) {
      if (isLastStep) {
        // navigatePush(Screens.REGISTER_COMPLETED_SCREEN);
      } else {
        navigatePush(Screens.REGISTER_FORM_SCREEN, { step: parseInt(step) + 1 });
      }
      return;
    }
    // if(isLast){
    //   return
    // }
    const payload = { ...payloadValue, completed: isLast, id: userValidation?.panelistID };
    dispatch(
      updateUserRequest(
        payload,
        rs => {
          console.log('updateUserRequest>>>onSuccess>>>', rs);
          if (isLastStep) {
            dispatch(resetUserValidation({}));
            // navigatePush(Screens.REGISTER_COMPLETED_SCREEN, { data: rs?.message});
            navigate(Screens.SUCCESSFUL_SCREEN, {
              data: rs?.message,
              header: {
                // title: headerTitle,
                backClick: () => {
                  navigation.dispatch(
                    CommonActions.reset({
                      index: 0,
                      routes: [
                        {
                          name: Screens.LOGIN_SCREEN_VERTICAL,
                        },
                      ],
                    }),
                  );
                },
              },
            });
          } else {
            navigatePush(Screens.REGISTER_FORM_SCREEN, { step: parseInt(step) + 1 });
          }
        },
        err => {
          // console.log('updateUserRequest>>>onFailed>>>', err);
          handleShowError(err, setErrors);
        },
      ),
    );
  };

  useEffect(() => {
    setData(rawData);
    setStep(paramsStep);
  }, [paramsStep]);

  useEffect(() => {
    setData(rawData);
    setStep(paramsStep);
  }, [paramsStep]);

  useEffect(() => {
    if (updateUserWithErrorMsg) {
      showErrorMsg(updateUserWithErrorMsg);
    }
  }, [updateUserWithErrorMsg]);
  const initialValues = getInitialFormValue(rawData, getUserData);
  const formik = FormikHelper.useFormikWrapper({
    initialValues: initialValues,
    // validationSchema: validationSchema,
    validationSchema: () =>
      lazy(values => {
        // console.log('lazy>>>values>>>', values);
        const ys = getYupStructure(rawData, t, 'attributes', values);
        console.log('getYupStructure>>>', ys);
        return Yup.object().shape(ys);
      }),
    initialErrors: false,
    onSubmit: (values, { setErrors, resetForm }) => {
      console.log('onSubmit>>>', values);
      updateUserApi(values, isLastStep, setErrors);
    },
  }, data?.attributes);

  console.log('useRegisterForm>>>', props, paramsStep, rawData, data, formik);
  // console.log('formik>>>', formik);

  const onNextPress = () => {
    console.log('onNextPress>>>', step);
    navigatePush(Screens.REGISTER_FORM_SCREEN, { step: parseInt(step) + 1 });
  };

  const onBackPress = () => {
    navigatePop();
  };
  return {
    isLastStep,
    onNextPress,
    step,
    data,
    isFirstStep,
    onBackPress,
    formik,
    // setYupValidation,
    // updateUserWithLoading,
  };
};
