import { Constant } from '@constant';
import { updateUserService } from '@services/user';
// @ts-ignore
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { updateUserFailed, updateUserSuccess } from './updateUserSlice';
import {act} from "react-test-renderer";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* updateUserSaga(action: any): any {
  try {
    const res = yield call(updateUserService, action?.payload);
    console.log('updateUserSaga>>>>', res, action);
    if (res?.status) {
      yield put(updateUserSuccess(res));
      if (action.meta) {
        yield call(action.meta, res);
      }
    } else {
      // @ts-ignore
      yield put(updateUserFailed(res));
      if (action.error) {
        yield call(action.error, res?.message);
      }
    }
  } catch (err) {
    // @ts-ignore
    yield put(updateUserFailed(err));
    if (action.error) {
      yield call(action.error, err);
    }
  }
}
