import { TIME_TABLE } from '@constant';
import { Config } from '@constant/config';
import { DashboardMockData } from '@constant/model/mock/dashboardMockData';
import { MenuMockData } from '@constant/model/mock/menuMockData';
import api from '@services/api-client';
import { stringify } from 'qs';
import withQuery from 'with-query';

export async function getMenu() {
  try {
    const url = withQuery(`/forms/3.json`);

    // if (Config.IsMockServer) {
    //   return {
    //     status: true,
    //     data: {
    //       data: MenuMockData,
    //     },
    //   };
    // }

    const rs = await api.get(url);
    return rs?.data;
  } catch (error) {
    console.error('Error: ', error);
    throw error;
  }
}

export async function getDashboard() {
  try {
    const url = withQuery(`/users/statistic.json`);

    // if (Config.IsMockServer) {
    //   return {
    //     status: true,
    //     data: {
    //       data: DashboardMockData,
    //     },
    //   };
    // }

    const rs = await api.get(url);
    console.log('getDashboard>>>response>>>', rs);
    return rs?.data;
  } catch (error) {
    console.error('Error: ', error);
    throw error;
  }
}

export async function getDashboardDetail({ internalId }) {
  try {
    const url = withQuery(`/dashboard/${internalId}.json`);

    const rs = await api.get(url);
    console.log('getDashboardDetail>>>response>>>', internalId, rs);
    return rs?.data;
  } catch (error) {
    console.error('Error: ', error);
    throw error;
  }
}

export async function getSurvey() {
  try {
    const url = withQuery(`/surveys/overview/cds.json`);

    // if (Config.IsMockServer) {
    //   return {
    //     status: true,
    //     data: {
    //       data: DashboardMockData,
    //     },
    //   };
    // }

    const rs = await api.get(url);
    return rs?.data;
  } catch (error) {
    console.error('Error: ', error);
    throw error;
  }
}

export async function getTimeline(type = TIME_TABLE.BUNDLE) {
  try {
    const url = withQuery(type === TIME_TABLE.BUNDLE ? `/bundles/open-bundles.json` : `/packages/open-packages`);

    const rs = await api.get(url);
    return rs?.data;
  } catch (error) {
    console.error('Error: ', error);
    throw error;
  }
}

export async function validateReport(payload) {
  try {
    const newPayload = { ...payload };
    delete newPayload.type;
    delete newPayload.id;
    const url = `/${payload?.type}/${payload?.id}?${stringify(newPayload)}`;
    const rs = await api.get(url);
    console.log('validateReport>>>response>>>', rs);

    return rs?.data;
  } catch (error) {
    console.error('Error: ', error);
    throw error;
  }
}

export const getMapSettingService = async payload => {
  try {
    console.log('getMapSettingService>>>request>>>');

    const response = await api.get(`/posting-locations/map/settings.json`);
    console.log('getMapSettingService>>>response>>>', response);

    return response?.data;
  } catch (error) {
    console.error('Error: ', error);
    throw error;
  }
};

export const getListDispatchPointService = async payload => {
  try {
    const response = await api.post(`/posting-locations/polygon`, payload);
    console.log('getListDispatchPointService>>>response>>>', response);

    return response?.data;
  } catch (error) {
    console.error('Error: ', error);
    throw error;
  }
};

export const getDetailDispatchPointService = async payload => {
  const { id, latitude, longitude } = payload;
  try {
    const response = await api.post(`/posting-locations/${id}`, {
      latitude,
      longitude,
    });
    console.log('getDetailDispatchPointService>>>response>>>', response);

    return response?.data;
  } catch (error) {
    console.error('Error: ', error);
    throw error;
  }
};
