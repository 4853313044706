import { getOverviewCDSSaga } from '@redux/survey/getOverviewCDSSaga';
import { getOverviewCDSRequest } from '@redux/survey/getOverviewCDSSlice';
import { getOverviewD2DSaga } from '@redux/survey/getOverviewD2DSaga';
import { getOverviewD2DRequest } from '@redux/survey/getOverviewD2DSlice';
import { getD2DItemsSaga } from '@redux/survey/getD2DItemsSaga';
import { getD2DItemsRequest } from '@redux/survey/getD2DItemsSlice';
import { saveFeedbackCDSSaga } from '@redux/survey/saveFeedbackCDSSaga';
import { saveFeedbackCDSRequest } from '@redux/survey/saveFeedbackCDSSlice';
import { saveFeedbackD2DSaga } from '@redux/survey/saveFeedbackD2DSaga';
import { saveFeedbackD2DRequest } from '@redux/survey/saveFeedbackD2DSlice';
import { saveFeedbackD2DForDailySaga } from '@redux/survey/saveFeedbackD2DForDailySaga';
import { saveFeedbackD2DForDailyRequest } from '@redux/survey/saveFeedbackD2DForDailySlice';
import { getCDSDetailSaga } from '@redux/survey/getCDSDetailSaga';
import { getCDSDetailRequest } from '@redux/survey/getCDSDetailSlice';
import { getListUnsureDateSaga } from '@redux/survey/getListUnsureDateSaga';
import { getListUnsureDateRequest } from '@redux/survey/getListUnsureDateSlice';
import { ForkEffect, takeLatest } from 'redux-saga/effects';

function* surveySagas(): Generator<ForkEffect<never>, void> {
  yield takeLatest(getOverviewCDSRequest.type, getOverviewCDSSaga);
  yield takeLatest(getOverviewD2DRequest.type, getOverviewD2DSaga);
  yield takeLatest(getD2DItemsRequest.type, getD2DItemsSaga);
  yield takeLatest(saveFeedbackCDSRequest.type, saveFeedbackCDSSaga);
  yield takeLatest(saveFeedbackD2DRequest.type, saveFeedbackD2DSaga);
  yield takeLatest(saveFeedbackD2DForDailyRequest.type, saveFeedbackD2DForDailySaga);
  yield takeLatest(getCDSDetailRequest.type, getCDSDetailSaga);
  yield takeLatest(getListUnsureDateRequest.type, getListUnsureDateSaga);
}

export default surveySagas;
