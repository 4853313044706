import { createSlice } from '@reduxjs/toolkit';

export interface IgetForgotPasswordState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IgetForgotPasswordState = {
  loading: false,
  payload: null,
  error: null,
};

const getForgotPasswordSlice = createSlice({
  name: 'getForgotPassword',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getForgotPasswordRequest(state, action) {
      state.loading = true;
      // @ts-ignore
      state.payload = null;
      state.error = null;
    },
    getForgotPasswordSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    getForgotPasswordFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getForgotPasswordRequest, getForgotPasswordSuccess, getForgotPasswordFailed } = getForgotPasswordSlice.actions;

export default getForgotPasswordSlice.reducer;
