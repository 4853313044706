import { createSlice } from '@reduxjs/toolkit';

export interface IgetRFIDFormReceiverState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IgetRFIDFormReceiverState = {
  loading: false,
  payload: null,
  error: null,
};

const getRFIDFormReceiverSlice = createSlice({
  name: 'getRFIDFormReceiver',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getRFIDFormReceiverRequest(state, action) {
      state.loading = true;
      // @ts-ignore
      state.payload = null;
      state.error = null;
    },
    getRFIDFormReceiverSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    getRFIDFormReceiverFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getRFIDFormReceiverRequest, getRFIDFormReceiverSuccess, getRFIDFormReceiverFailed } = getRFIDFormReceiverSlice.actions;

export default getRFIDFormReceiverSlice.reducer;
