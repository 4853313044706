import { getTicketDetailService } from '@services/ticket'

// @ts-ignore
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { getTicketDetailFailed, getTicketDetailSuccess } from './getTicketDetailSlice';
import { Constant } from "@constant";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* getTicketDetailSaga({ payload }): Generator<
  | CallEffect
  | PutEffect<{
      payload: any;
      type: string;
    }>,
  void
> {
  try {
    const res = yield call(getTicketDetailService, payload);
    console.log('getTicketDetailSaga>>>>', res);
    if (res?.status) {
      yield put(getTicketDetailSuccess(res));
    } else {
      // @ts-ignore
      yield put(getTicketDetailFailed(res));
    }
  } catch (err) {
    // @ts-ignore
    yield put(getTicketDetailFailed(err));
  }
}
