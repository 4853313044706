import { getContentTitle } from '@utils/transform/form';
import _ from 'lodash';

export const getColor = (value, colors) => {
  if (_.isEmpty(value) || _.isEmpty(colors)) {
    return '#95a5a6';
  }

  // eslint-disable-next-line no-prototype-builtins
  if (!colors.hasOwnProperty(value)) {
    return '#95a5a6';
  }
  return colors[value];
};

export const getText = (value, values) => {
  if (_.isEmpty(value)) {
    return null;
  }

  if (_.isEmpty(values)) {
    return value;
  }

  // eslint-disable-next-line no-prototype-builtins
  if (!values.hasOwnProperty(value)) {
    return value;
  }

  return getContentTitle(values[value]);
};

export const getListStatus = value => {
  if (_.isEmpty(value)) {
    return [];
  }
  console.log('...value(key)>>>>', value);
  const rs = Object.keys(value)?.map((key, index) => {
    const item = {
      key: key,
      title: value[key],
    };
    return item;
  });

  console.log('getListStatus>>>', rs);
  return rs;
};

export const mappingTicketSubject = data => {
  if (_.isEmpty(data)) {
    return [];
  }
  return data?.map(item => {
    const content = getContentTitle(item.name);
    return {
      ...item,

      content: content,
      id: content,
    };
  });
};

export const TicketTransform = {
  addComment: (ticketId, comment, attachments) => {
    //TODO: save local comment
    // state.getTicketDetail.payload.data.comments
    // ... author?.from
    // ... author?.name
    // ... content

    // --- image
    // ... .attachments[].link
  },
};
