import _ from 'lodash';

export const mappingHolidays = payload => {
  if(_.isEmpty(payload)){
    return []
  }
  return payload
};
export const mappingHolidaysOld = payload => {
  if(_.isEmpty(payload)){
    return []
  }
  const keys = Object.keys(payload);
  if (_.isEmpty(keys)) {
    return [];
  }
  const rs = [];
  for (const key of keys) {
    const item = {
      id: key,
      ...payload[key],
    };
    rs.push(item)
  }
  return rs
};
