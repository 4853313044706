import { FontSize } from '@theme/size';
import { StyleSheet } from 'react-native';

export const AppNoteStyle = StyleSheet.create({
  container: {
    width: '100%',
    // backgroundColor: 'red',
    // paddingTop: 10,
  },
  label: {
    fontWeight: '400',
    color: '#333333',
    fontSize: FontSize.Font15,
    // textAlign: 'center',
    // marginBottom: 8,
  },
  hint: {
    fontWeight: '400',
    color: '#999999',
    marginTop: 7,
  },
  isRequired: {
    color: '#FF5757',
  },
});
