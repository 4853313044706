import { useSplash } from '@components/Splashscreen/useSplash';
import React, { FC, useEffect } from 'react';
import {Image, View} from 'react-native';
import {CommonImages} from "@assets";

/**
 * An empty component used to manage SplashScreen from Suspense fallback
 */
const Splashscreen: FC = () => {
  // const {} = useSplash({});

  // To avoid strange crash if i18next load takes too much! 🙀🙀🙀
  return <View>
  </View>;
};
export default Splashscreen;

// export default React.memo(Splashscreen);
