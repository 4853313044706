import { SURVEY_STATUS } from '@constant';
import { getSurveyRequest } from '@redux/app/appSlice';
import { surveys } from '@redux/app/selectors';
import { navigate } from '@routes/navigationUtils';
import { Screens } from '@routes/route';
import { mappingSurveyList } from '@utils';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

export const useSurveyList = navigation => {
  const dispatch = useDispatch();
  const surveysList = useSelector(surveys);

  const handleOnDayPress = data => {
    console.log('handleOnDayPress>>>', data);
    // if ([SURVEY_STATUS.Answered, SURVEY_STATUS.Awaiting_data].includes(data?.status)) {
    //   return;
    // }
    if(!data?.clickable){
      return
    }
    if(data?.key === SURVEY_STATUS.Unsure_date){
      navigate(Screens.UNSURE_DATE_LIST_SCREEN, { data: data });
    }
    else{
      if(!!data?.editId){
        navigate(Screens.CDS_DETAIL_SCREEN, { data: data });
      }
      else{
        navigate(Screens.CDS_FORM_SCREEN, { data: data });
      }

    }
  };

  useEffect(() => {
    dispatch(getSurveyRequest());
  }, []);

  return { surveysList: mappingSurveyList(surveysList), handleOnDayPress };
};
