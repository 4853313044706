import env from '@env';
import api from '@services/api-client';
import { getAccessToken, getAccountId } from '@utils';
import { FileTransform } from '@utils/transform/file';
import { stringify } from 'qs';
import { URL } from 'react-native-url-polyfill';
import withQuery from 'with-query';
import {ApiHelper} from "@utils/api";

export const uploadFileService = async payload => {
  console.log('uploadFileService>>>', payload);
  const response = await api.postForm(`/files.json`, payload, {
    headers: {
      Accept: '*/*',
      'Content-Type': 'multipart/form-data; ',
      'spectos-apikey': ApiHelper.getApiKey(),
    },
  });
  console.log('uploadFileService response>>>', response);
  return response?.data;
};

export const uploadFileViaFDBService = async payload => {
  console.log('uploadFileViaFDBService>>>', payload);
  const formData = payload.formData;
  if(formData){
    const accountId = getAccountId();
    formData.append('accountId', accountId)
  }
  delete payload.formData

  const newPayload = {
    ...payload,
  };
  const url = FileTransform.getFDBUrl(`/upload?${stringify(newPayload)}`);
  const response = await api.postForm(url, formData, {
    baseURL: new URL(env.API_URL).origin,
    headers: {
      Accept: '*/*',
      'Content-Type': 'multipart/form-data; ',
      'spectos-apikey': ApiHelper.getApiKey(),
    },
  });
  console.log('uploadFileViaFDBService response>>>', response);
  return response?.data;
};

export const viewFileViaFDBService = async payload => {
  console.log('viewFileViaFDBService>>>', payload);
  const response = await api.get(`/files/view/636224e9d4284b6286aa7d750a01031a?${stringify(payload)}`);
  console.log('viewFileViaFDBService response>>>', response);

  return response?.data;
};

export const deleteFileService = async payload => {
  console.log('deleteFileService>>>', payload);
  const response = await api.delete(`/files/${payload.id}`);
  console.log('deleteFileService response>>>', response);

  return response?.data;
};

export const getFileService = async payload => {
  console.log('getFileService>>>', payload);
  const response = await api.get(`/files/62fb5632bb1446c49ec467830a01031a.json?${stringify(payload)}`);
  console.log('getFileService response>>>', response);

  return response?.data;
};
