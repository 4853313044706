import api from '@services/api-client';
import { stringify } from 'qs';
import withQuery from 'with-query';

export const getReimbursementService = async payload => {
  console.log('getReimbursementService>>>', payload)
  const response = await api.get(`/users/reimbursements/logs.json?${stringify(payload)}`)
  console.log('getReimbursementService response>>>', response)

  return response?.data
}

export const downloadReimbursementService = async payload => {
  console.log('downloadReimbursementService>>>', payload)
  const response = await api.get(`/users/reimbursements/download.json?${stringify(payload)}`)
  console.log('downloadReimbursementService response>>>', response)

  return response?.data
}

