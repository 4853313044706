import queryString from 'query-string';
import { Platform } from 'react-native';

export const LinkingHelper = {
  isForceOpen: () => {
    if (Platform.OS !== 'web') {
      return false;
    }
    const urlParams = queryString.parse(window?.location?.search);
    console.log('isForceOpen>>>', urlParams);
    if (urlParams?.jwt) {
      return true;
    }
    if (urlParams?.webLink) {
      return true;
    }
    return false;
  },
};
