import { EyeActiveIcon, EyeInactiveIcon } from '@assets';
import { AppText } from '@components';
import { Constant } from '@constant';
import theme, { globalStyle } from '@theme';
import { palette } from '@theme/colors';
import { FontSize } from '@theme/size';
import React, { useState } from 'react';
import { View, TextInput, StyleSheet, Keyboard, TouchableOpacity, Platform } from 'react-native';
import PhoneInput from 'react-native-phone-number-input';

export const AppPhoneInput = React.memo(props => {
  const {
    placeholder,
    multiline,
    keyboardType,
    onChangeText,
    style,
    maxLength = null,
    error = '',
    handleBlur,
    boxStyle,
    PreIcon,
    PostIcon,
    returnKeyType,
    value,
    placeholderTextColor,
    label,
    isRequired,
    inputStyle,
    isPassword,
    onPostIconPress,
    isBoldTitle,
    inputRef,
    disabled,
    multipleHeight = 246,
    hint = '',
    phoneCode = '+44',
    onLayout = null,
  } = props;

  const [secureTextEntry, setSecureTextEntry] = useState(isPassword);
  const [formattedValue, setFormattedValue] = useState('');

  return (
    <View style={[styles.container, style]}  onLayout={e => {
      if (onLayout) {
        onLayout(e);
      }
    }}>
      {!!label && (
        <AppText style={[styles.label, isBoldTitle ? { fontWeight: '700' } : {}]}>
          {isRequired && <AppText style={styles.isRequired}>* </AppText>}
          {label}
        </AppText>
      )}
      <View
        style={[
          styles.boxInput,
          boxStyle,
          disabled ? { opacity: disabled ? 0.5 : 1 } : {},
          multiline ? { height: multipleHeight } : {},
        ]}>
        {PreIcon && (
          <View style={[styles.postIcon, { paddingRight: 0 }]}>
            <PreIcon />
          </View>
        )}
        {/*<TextInput*/}
        {/*  ref={inputRef}*/}
        {/*  style={[*/}
        {/*    styles.input,*/}
        {/*    multiline ? { height: multipleHeight, textAlignVertical: 'top', ...styles.multiline } : {},*/}
        {/*    inputStyle,*/}
        {/*  ]}*/}
        {/*  returnKeyType={returnKeyType || (multiline ? undefined : 'done')}*/}
        {/*  keyboardType={keyboardType}*/}
        {/*  secureTextEntry={secureTextEntry}*/}
        {/*  placeholder={placeholder}*/}
        {/*  value={value}*/}
        {/*  placeholderTextColor={placeholderTextColor}*/}
        {/*  multiline={multiline}*/}
        {/*  blurOnSubmit={false}*/}
        {/*  onChangeText={onChangeText}*/}
        {/*  maxLength={maxLength}*/}
        {/*  onSubmitEditing={multiline ? undefined : Keyboard.dismiss}*/}
        {/*  onBlur={handleBlur}*/}
        {/*  allowFontScaling={false}*/}
        {/*  editable={!disabled}*/}
        {/*/>*/}
        <View style={[globalStyle.directionRow]}>
          <View style={[{ marginLeft: theme.spacing.sm }, globalStyle.center]}>
            <AppText
            // style={[styles.input]}
            >
              {phoneCode}
            </AppText>
          </View>

          <TextInput
            ref={inputRef}
            style={[
              styles.input,
              multiline ? { height: multipleHeight, textAlignVertical: 'top', ...styles.multiline } : {},
              inputStyle,
            ]}
            returnKeyType={returnKeyType || (multiline ? undefined : 'done')}
            keyboardType={keyboardType}
            secureTextEntry={secureTextEntry}
            placeholder={placeholder}
            value={value}
            placeholderTextColor={placeholderTextColor}
            multiline={multiline}
            blurOnSubmit={false}
            onChangeText={onChangeText}
            maxLength={maxLength}
            onSubmitEditing={multiline ? undefined : Keyboard.dismiss}
            onBlur={handleBlur}
            allowFontScaling={true}
            editable={!disabled}
          />
        </View>
        {/*<PhoneInput*/}
        {/*  containerStyle={[styles.input, { marginLeft: 4 }]}*/}
        {/*  ref={inputRef}*/}
        {/*  value={value}*/}
        {/*  defaultCode="GB"*/}
        {/*  layout="second"*/}
        {/*  onChangeText={text => {*/}
        {/*    // setValue(text);*/}
        {/*    console.log('onChangeText<<', text);*/}

        {/*    onChangeText(text);*/}
        {/*  }}*/}
        {/*  onChangeFormattedText={text => {*/}
        {/*    console.log('onChangeFormattedText<<', text);*/}
        {/*    setFormattedValue(text);*/}
        {/*  }}*/}
        {/*  // disableArrowIcon*/}
        {/*  // withDarkTheme*/}
        {/*  // withShadow*/}
        {/*  // autoFocus*/}
        {/*/>*/}
      </View>
      {!!hint && <AppText style={styles.hint}>{hint}</AppText>}
      {!!error && <AppText style={styles.error}>{error}</AppText>}
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },

  boxInput: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    textAlignVertical: 'center',
    justifyContent: 'space-between',
    minHeight: Constant.MAIN_HEIGHT, //42
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#D5D5D5',
  },
  input: {
    minHeight: Platform.OS === 'web' ? '48px' : '100%',
    flex: 1,
    fontSize: FontSize.Font15,
    color: '#333333',
    fontWeight: '400',
    paddingLeft: 10,
    outlineStyle: Platform.OS === 'web' ? 'none' : undefined,
  },
  label: {
    fontWeight: '400',
    color: '#333333',
    marginBottom: 8,
  },
  hint: {
    fontWeight: '400',
    color: '#999999',
    marginTop: 7,
  },
  isRequired: {
    color: '#FF5757',
  },
  error: {
    marginTop: 7,

    color: palette.ROYAL_MAIL_RED,
    fontWeight: '400',
  },
  multiline: {
    minHeight: 246,
    // paddingTop: 7,
    // paddingTop: 0,
    // paddingBottom: 0,
  },
  postIcon: {
    paddingHorizontal: 12,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
