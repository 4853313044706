import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cameraPermissionModalVisible: false,
  locationPermissionModalVisible: false,
  notificationPermissionModalVisible: false,
  notificationPermissionModal:{
    visible: false,
    title: '',
    description: '',
  },
  notificationImportantModalVisible: false,
  modalDeeplink: false,
  menus: [],
  globalModal: {
    title: '',
    description: '',
    visible: false,
  },
  globalSuccessModal: {
    title: '',
    description: '',
    icon: '',
    action: null,
    visible: false,
  },
  noInternetModalVisible: false,
  newsModal: {
    title: '',
    description: '',
    visible: false,
  },
};

const commonSlice = createSlice({
  name: 'todos',
  initialState,
  reducers: {
    showCameraPermissionModal(state) {
      state.cameraPermissionModalVisible = true;
    },
    hideCameraPermissionModal(state) {
      state.cameraPermissionModalVisible = false;
    },
    showLocationPermissionModal(state) {
      state.locationPermissionModalVisible = true;
    },
    hideLocationPermissionModal(state) {
      state.locationPermissionModalVisible = false;
    },
    showNotificationPermissionModal(state, action) {
      // state.notificationPermissionModalVisible = true;
      state.notificationPermissionModal = {
        ...action.payload,
        visible: true,
      }
    },
    hideNotificationPermissionModal(state) {
      console.log('hideNotificationPermissionModal>>>')
      // state.notificationPermissionModalVisible = false;
      state.notificationPermissionModal = {
        visible: false,
      }
    },
    showModalDeeplink(state) {
      state.modalDeeplink = true;
    },
    hideModalDeeplink(state) {
      state.modalDeeplink = false;
    },
    showGlobalModal(state, action) {
      console.log('showGlobalModal>>>', action);
      const modal = state.globalModal;
      modal.visible = true;
      modal.title = action?.payload?.title;
      modal.description = action?.payload?.description;
      state.globalModal = {
        ...modal,
      };
    },
    hideGlobalModal(state) {
      const modal = state.globalModal;
      modal.visible = false;
      state.globalModal = {
        ...modal,
      };
    },
    showGlobalSuccessModal(state, action) {
      console.log('showGlobalSuccessModal>>>', action);
      const modal = state.globalSuccessModal;
      modal.visible = true;
      modal.title = action?.payload?.title;
      modal.description = action?.payload?.description;
      modal.icon = action?.payload?.icon;
      modal.action = action?.payload?.action;
      state.globalSuccessModal = {
        ...modal,
      };
    },
    hideGlobalSuccessModal(state) {
      const modal = state.globalSuccessModal;
      modal.visible = false;
      state.globalSuccessModal = {
        ...modal,
      };
    },
    showNotificationImportantModal(state) {
      state.notificationImportantModalVisible = true;
    },
    hideNotificationImportantModal(state) {
      state.notificationImportantModalVisible = false;
    },
    showNoInternetModal(state) {
      state.noInternetModalVisible = true;
    },
    hideNoInternetModal(state) {
      state.noInternetModalVisible = false;
    },
    showNewsModal(state, action) {
      console.log('showNewsModal>>>', action)
      const modal = state.newsModal;
      modal.visible = true;
      modal.title = action?.payload?.title;
      modal.description = action?.payload?.description;
      state.newsModal = {
        ...modal,
      };
    },
    hideNewsModal(state) {
      const modal = state.newsModal;
      modal.visible = false;
      state.newsModal = {
        ...modal,
      };
    }
  },
});

export const {
  showCameraPermissionModal,
  hideCameraPermissionModal,
  showLocationPermissionModal,
  hideLocationPermissionModal,
  showNotificationPermissionModal,
  hideNotificationPermissionModal,
    showNotificationImportantModal,
    hideNotificationImportantModal,
  showModalDeeplink,
  hideModalDeeplink,
  showGlobalModal,
  hideGlobalModal,
    showGlobalSuccessModal,
    hideGlobalSuccessModal,
  showNoInternetModal,
  hideNoInternetModal,
  hideNewsModal,
  showNewsModal,
} = commonSlice.actions;

export default commonSlice.reducer;
