import { ReportICodeMockData } from '@constant/model/mock/report/iCode';
import { ReportPackageMockData } from '@constant/model/mock/report/package';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useTopICode = props => {
  const dispatch = useDispatch();
  // const data = ReportICodeMockData.summary;
  const data = props?.data;

  useEffect(() => {}, [dispatch]);
  return { data };
};
