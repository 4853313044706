import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppSafeAreaView, AppText, AuthSuccessScreen } from '@components';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font } from '@theme/size';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UnsubscribeSuccessfulStyles } from './UnsubscribeSuccessful.style';
import {useUnsubscribeSuccessful} from "@screens/main/unsub/UnsubscribeSuccessful/UnsubscribeSuccessful.hook";

export const UnsubscribeSuccessfulScreen = props => {
  const { waitingForLogout } = useUnsubscribeSuccessful(props);
  const { t } = useTranslation();
  return (
    <AuthSuccessScreen
      title={t('UNSUBSCRIBE_SUCCESSFUL_TITLE')}
      desc={t('UNSUBSCRIBE_SUCCESSFUL_DESCRIPTION')}
    />
  );
};
