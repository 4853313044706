import { fixFormValues } from '@utils/transform/form';
import _ from 'lodash';
import { t } from 'i18next';

export const BundleTransform = {
  mappingAddress: data => {
    if (_.isEmpty(data)) {
      return [];
    }

    return data?.map(item => {
      // const ls = [item?.street, item?.house_number, item?.postal_code];
      const ls = [item?.postbox_number, item?.postal_code, item?.street];

      const title = ls.filter(x => !!x).join(', ');
      return {
        ...item,
        postBox: item?.id,
        title: title,
        // distance: `${item.distance_in_miles ?? 'N/A'}${t("MILE_SHORT")}`,
      };
    });
  },
  mappingEmptying: data => {
    if (_.isEmpty(data)) {
      return [];
    }
    const rs = [];
    //TODO: map current date here.
    // for (const dataKey in data) {
    //   rs.push({
    //     day: dataKey,
    //     time: data[dataKey],
    //   });
    // }
    return data;
  },
  fixFormValues: values => {},
  getDefaultPostingLocationId: detail => {
    //NOTE: posting_location_id for check show posting_location_image_url. Please be carefully
    if (_.isEmpty(detail)) {
      return null;
    }
    const posting_location_id = detail.posting_location_id;
    console.log('getDefaultPostingLocationId>>>', detail, posting_location_id)

    // if (_.isEmpty(posting_location_id)) {
    //   if(!_.isEmpty(detail?.search_postcode) && _.isEmpty(detail?.search_address))
    //   return "";
    // }
    return posting_location_id;
  },
};
