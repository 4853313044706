import { LogoIcon, PadLockIcon } from '@assets';
import { AppButton, AppKAView, AppSafeAreaView, AppText, AuthFooter } from '@components';
import { FormItemView } from '@components/Form';
import { Device } from '@theme/device';
import { useKeyboard } from '@utils/hooks';
import { getContentTitle } from '@utils/transform/form';
import { t } from 'i18next';
import React, { Fragment } from 'react';
import { ScrollView, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useLogic } from './ForgotChangePass.hook';
import { styles } from './ForgotChangePass.style';

export const ForgotChangePass = () => {
  const { handleLoginPress, formik, rawData } = useLogic();
  const KAView = Device.isIos ? AppKAView : Fragment;
  const KAScrollView = Device.isIos ? ScrollView : KeyboardAwareScrollView;
  const keyboardShow = useKeyboard();

  return (
    <AppSafeAreaView>
      <KAView>
        <View style={styles.container}>
          <KAScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={styles.form}>
            <View style={styles.logo}>
              <LogoIcon />
            </View>
            <AppText style={styles.signUpDesc}>{getContentTitle(rawData?.description)}</AppText>
            {rawData?.attributes?.map((item, index) => {
              return (
                <View style={{ marginBottom: -6, width: '100%' }}>
                  <FormItemView
                    PreIcon={PadLockIcon}
                    formik={formik}
                    item={item}
                    index={index}
                    isBoldTitle
                    showPlaceholder
                    showHint={true}
                  />
                </View>
              );
            })}
          </KAScrollView>
          <AppButton onPress={formik.handleSubmit} text={t('CONFIRM')} />
        </View>
      </KAView>
      {!keyboardShow && (
        <>
          <View style={styles.signUpBox}>
            <AppText style={styles.signUpText}>{t('ALREADY_HAVE_AN_ACCOUNT')}</AppText>
            <AppText onPress={handleLoginPress} style={styles.highlightText}>
              {t('LOGIN')}
            </AppText>
          </View>
          <AuthFooter />
        </>
      )}
    </AppSafeAreaView>
  );
};
