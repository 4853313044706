import { getICodeFormService } from '@services/form'

// @ts-ignore
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { getICodeFormFailed, getICodeFormSuccess } from './getICodeFormSlice';
import { Constant } from "@constant";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* getICodeFormSaga({ payload, meta, error }): Generator<
  | CallEffect
  | PutEffect<{
      payload: any;
      type: string;
    }>,
  void
> {
  try {
    const res = yield call(getICodeFormService, payload);
    console.log('getICodeFormSaga>>>>', res);
    if (res?.status) {
      yield put(getICodeFormSuccess(res));
        if (meta) {
            yield call(meta, res);
        }
    } else {
      // @ts-ignore
      yield put(getICodeFormFailed(res));
        if (error) {
            yield call(error, res);
        }
    }
  } catch (err) {
    // @ts-ignore
    yield put(getICodeFormFailed(err));
      if (error) {
          yield call(error, err);
      }
  }
}
