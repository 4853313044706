import api from '@services/api-client';
import { stringify } from 'qs';
import withQuery from 'with-query';

export const getListQuizService = async payload => {
  console.log('getListQuizService>>>', payload)
  const response = await api.get(`/quizzes.json?${stringify(payload)}`)
  console.log('getListQuizService response>>>', response)

  return response?.data
}

export const getQuizService = async payload => {
  console.log('getQuizService>>>', payload)
  const response = await api.get(`/quizzes/${payload?.id}.json?`)
  console.log('getQuizService response>>>', response)

  return response?.data
}

