import { getFormRequest } from '@redux/form/getFormSlice';
import { getFormCreator, getFormSelector, getFormError, getFormErrorMsg, getFormLoading } from '@redux/form/selectors';
import { navigatePop, popToTop } from '@routes/navigationUtils';
import { showErrorMsg } from '@utils/api';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useRegisterFormStack = props => {
  const dispatch = useDispatch();
  const getFormData = useSelector(getFormCreator);
  const getFormWithErrorMsg = useSelector(getFormErrorMsg);
  console.log('useRegisterFormStack>>', getFormWithErrorMsg)
  const getFormApi = () => {
    /*
      payload_info
       */
    console.log('getFormApi>>>');
    const payload = {};
    dispatch(getFormRequest(payload));
  };

  useEffect(() => {
    // getFormApi();
  }, []);

  useEffect(() => {
    if (getFormWithErrorMsg) {
      showErrorMsg(getFormWithErrorMsg);
    }
  }, [getFormWithErrorMsg]);

  const waitingForSendMail = () => {
    // popToTop()
  };
  return { waitingForSendMail };
};
