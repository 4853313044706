import { FORM } from '@constant/model/RegisterForm';
import { navigate } from '@routes/navigationUtils';
import { Screens } from '@routes/route';
import { ViewUtil } from '@utils/common/view';
import { CDSTransform } from '@utils/transform/cds';
import { FormTransform } from '@utils/transform/form';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {FileTransform} from "@utils/transform/file";

export const useMisDelivered = props => {
  const { onSelect, selected = [] } = props;
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const onYesDelete = () => {
    try {
      handleDelete(selectedIndex);
      setShowModal(false);
    } catch (e) {
      console.log('ex>>', e);
    }
  };

  const onNoDelete = () => {
    setSelectedIndex(-1);
    setShowModal(false);
  };
  const onDelete = index => {
    setSelectedIndex(index);
    setShowModal(true);
  };
  const handleDelete = index => {
    setSelectedIndex(-1);
    const cloneSelected = selected?.map(item => {
      return {
        ...item,
        cloneId: FileTransform.getCloneIdOfCDS(_.cloneDeep(item), props.data?.subForms)
      }
    })
    const itemSelected = cloneSelected[index - 1];
    const size = getSizeOfClone(itemSelected);

    console.log('handleDelete>>>', itemSelected)

    if (size > 1) {
      const cloneId = itemSelected.cloneId;
      const listClone = cloneSelected.filter(x => x.cloneId === cloneId);
      for (const listCloneElement of listClone) {
        const key = FORM.FORM_ELEMENT_NAME.number_of_same_details;
        listCloneElement[key] = size - 1;
      }
    }
    cloneSelected.splice(index - 1, 1);
    onSelect([...cloneSelected]);
  };

  const getSizeOfClone = data => {
    const key = FORM.FORM_ELEMENT_NAME.number_of_same_details;
    let numberClone = 1;
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty(key)) {
      numberClone = data[key] ? parseInt(data[key]) : 1;
    }
    return numberClone;
  };
  const onSubmitSuccess = data => {
    //TODO: check duplicate item
    console.log('onSubmitSuccess>>>', data);
    if (_.isEmpty(data)) {
      return;
    }

    let numberClone = getSizeOfClone(data);
    if (onSelect) {
      const rs = [];
      const cloneId = Math.random() * Math.random();
      for (let i = 0; i < numberClone; i++) {
        rs.push({ id: Math.random() * Math.random(), ...data, cloneId: cloneId });
      }
      console.log('onSubmitSuccess>>>onSelect>>>', rs, numberClone);

      onSelect([...selected, ...rs]);
    }
  };

  const onSubmitSuccessWithIndex = (index, data) => {
    // selected?.forEach(item => {
    //   try {
    //     console.log('forEach>>>', item)
    //     item.cloneId = FileTransform.getCloneIdOfCDS(_.cloneDeep(item), props.data?.subForms)
    //   }
    //   catch (e){
    //     console.log('ex>>>', e)
    //   }
    //
    // })
    // for (const item of selected) {
    //   item.cloneId = FileTransform.getCloneIdOfCDS(item, props.data?.subForms)
    // }
    const cloneSelected = selected?.map(item => {
      return {
        ...item,
        cloneId: FileTransform.getCloneIdOfCDS(_.cloneDeep(item), props.data?.subForms)
      }
    })
    const itemSelected = cloneSelected[index - 1];

    const cloneId = itemSelected.cloneId;
    //TODO: should check when from api
    const oldClones = cloneSelected.filter(x => x.cloneId === cloneId);
    const firstIndex = cloneSelected.findIndex(x => x.cloneId === cloneId);

    const sizeOldClone = oldClones?.length ?? 0;
    const newSize = getSizeOfClone(data);
    console.log('onSubmitSuccessWithIndex>>>', cloneSelected, index, sizeOldClone, newSize, cloneId, props.data?.subForms);

    if (onSelect) {
      if (newSize === 0) {
        cloneSelected[index - 1] = data;
        onSelect([...cloneSelected]);
      } else {
        cloneSelected.splice(firstIndex, sizeOldClone);
        for (let i = 0; i < newSize; i++) {
          const id = Math.random() * Math.random();
          cloneSelected.splice(firstIndex, 0, { ...data, id: id, cloneId: cloneId });
        }
        onSelect([...cloneSelected]);
      }
    }
  };

  const onItemSelected = index => {
    console.log('onItemSelected>>>', index, selected);
    const formData = _.cloneDeep(selected[index - 1])
    navigate(Screens.CDS_SUBFORM_SCREEN, {
      data: props.data,
      extraFormValues: props.extraFormValues,
      extraData: props.extraData,
      formData: {
        ...formData,
        cloneId: FileTransform.getCloneIdOfCDS(formData, props.data?.subForms)
      },
      onSubmitSuccess: data => {
        onSubmitSuccessWithIndex(index, data);
      },
    });
  };

  const checkValidate = () => {
    const { formik, formData } = props;
    console.log('checkValidate>>', formik, formData);
    if (_.isEmpty(formik) || _.isEmpty(formData)) {
      return true;
    }
    const { setErrors, values, setFieldError, setFieldTouched } = formik;
    const MisdeliveredItemForm = formData?.find(x => x.type === FORM.FORM_TYPE.misdelivered_item_form);
    if (_.isEmpty(MisdeliveredItemForm)) {
      return true;
    }

    const listExp = CDSTransform.getEqualExpressionForm(values);
    console.log('getEqualExpressionForm>>>', listExp);
    return !FormTransform.checkPositiveCustomCondition(
      FORM.FORM_ELEMENT_NAME.misdelivered_item_form,
      values,
      listExp,
      setErrors,
      // setFieldError,
      // setFieldTouched,
    );
  };

  const scrollToEnd = (timeout = 200) => {
    ViewUtil.scrollToEnd(props.scrollViewRef?.current, timeout, false);
  };

  const handlePickImage = async () => {
    //TODO: validate form before add
    // const isValid = checkValidate();
    // if (!isValid) {
    //   // const { formik, formData } = props;
    //   // await formik.validateForm();
    //   //FIXME: temp fix validate to show error.
    //   // const id = FORM.FORM_ELEMENT_NAME.misdelivered_item_form;
    //   // const newValue = formik.values[id];
    //   // formik.setFieldValue(id, [...newValue]);
    //   // checkValidate();
    //   // console.log('checkValidate>>>handlePickImage>>>', formik);
    //   scrollToEnd();
    //   return;
    // }
    if (props.onCheckValid && !props.onCheckValid()) {
      return;
    }
    navigate(Screens.CDS_SUBFORM_SCREEN, {
      data: props.data,
      extraFormValues: props.extraFormValues,
      extraData: props.extraData,
      onSubmitSuccess: onSubmitSuccess,
    });
  };

  useEffect(() => {}, [dispatch]);
  return {
    handleDelete,
    handlePickImage,
    onItemSelected,
    onYesDelete,
    showModal,
    onDelete,
    onNoDelete,
    // scrollViewRef
  };
};
