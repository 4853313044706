import { Config, ConfigMode } from '@constant/config';
import env from '@env';
import { getAccountId } from '@utils';
import { ApiHelper } from '@utils/api';
import { isUrl } from '@utils/common/imageUtils';
// import api from '@services/api-client';
import axios from 'axios';
import _ from 'lodash';
import { stringify } from 'qs';
import FastImage from 'react-native-fast-image';
import { URL } from 'react-native-url-polyfill';
import 'react-native-get-random-values';
import { v4 } from 'uuid';
import {FORM} from "@constant/model/RegisterForm";
import {StringHelper} from "@utils/common/string";
import replaceAll from "string.prototype.replaceall";

export const FileTransform = {
    PREFIX_FDB_URL: 'files/view',
    getDeletePath: responsePath => {
        //62fb5632bb1446c49ec467830a01031a?path=Ticket/
        if (!responsePath) {
            return responsePath;
        }
        return responsePath.replace('?path=', '.json?path=');
    },
    getFullLink: link => {
        if (_.isEmpty(link)) {
            return link;
        }

        const rootUrl = new URL(env.API_URL).origin;
        return `${rootUrl}${link}`;
    },
    getFDBUrl: path => {
        const mode = Config.Api.mode;
        const fdbApiName = ConfigMode[mode].fdbapi;
        let newPath = `/${fdbApiName}${path}`;
        switch (env.ENV) {
            case 'prepro':
                newPath = `/${fdbApiName}${path}`;
                break;
        }
        return newPath;
    },
    getListImageUrl: urls => {
        if (_.isEmpty(urls)) {
            return [];
        }
        return urls.map(x => {
            if (isUrl(x)) {
                return {
                    url: x,
                    uri: x,
                };
            }
            if(typeof (x) == "string"){
                return {
                    // url: `/files/view/${x}`,
                    url: `${x}`,
                    response:`${x}`,
                };
            }
            return {
                ...x,
            }
        });
    },
    getImageSourceForPicker: (uri, url, s3config = '') => {
        //NOTE:
        if (!_.isEmpty(uri)) {
            return {
                uri: uri,
            };
        }
        if(_.isEmpty(url)){
            return {}
        }

        return FileTransform.getImageFdbSourceWithConfig(url, s3config);
    },
    getImageSourceForPickerAsync: async (uri, url, isS3 = true) => {
        if (!_.isEmpty(uri)) {
            return {
                uri: uri,
            };
        }

        return await FileTransform.getImageFdbAsync(url, isS3);
    },
    getImageFdbUrl: (path, isS3 = false) => {
        const rootUrl = new URL(env.API_URL).origin;
        const accountId = getAccountId();
        if (path.charAt(0) !== '/') {
            path = `/${path}`;
        }
        const params = {
            accountId,
        };
        if (isS3) {
            params.s3config = 'TTM';
        }
        const mode = Config.Api.mode;
        // const fdbApiName = Config.Api.fdbapi[mode];
        const fdbApiName = ConfigMode[mode].fdbapi;
        let newPath = `${rootUrl}/${fdbApiName}${path}&${stringify(params)}`;
        switch (env.ENV) {
            case 'prepro':
                newPath = `${rootUrl}/${fdbApiName}${path}&${stringify(params)}`;
                break;
        }
        return newPath;
    },
    getImageFdbUrlWithConfig: (path, s3config = '') => {
        const rootUrl = new URL(env.API_URL).origin;
        const accountId = getAccountId();
        if (path.charAt(0) !== '/') {
            path = `/${path}`;
        }
        const params = {
            accountId,
        };
        if (!_.isEmpty(s3config)) {
            params.s3config = s3config;
        }
        const mode = Config.Api.mode;
        // const fdbApiName = Config.Api.fdbapi[mode];
        const fdbApiName = ConfigMode[mode].fdbapi;
        let newPath = `${rootUrl}/${fdbApiName}${path}&${stringify(params)}`;
        switch (env.ENV) {
            case 'prepro':
                newPath = `${rootUrl}/${fdbApiName}${path}&${stringify(params)}`;
                break;
        }
        return newPath;
    },

    getImageFdbAsyncUrl: async url => {
        const rs = await axios.get(url, {
            headers: {
                'spectos-apikey': ApiHelper.getApiKey(),
            },
            responseType: 'blob',
        });
        const data = rs?.data;
        console.log('getImageFdbAsync>>>', data);

        return {
            // uri: URL.createObjectURL(rs),
            uri: window.URL.createObjectURL(data),

            // uri: URL.createObjectURL(data),

            // uri: data,
        };
    },
    getImageFdbAsync: async (path, isS3 = false, prefix = '') => {
        const url = FileTransform.getImageFdbUrl(`${prefix}${path}`, isS3);
        return FileTransform.getImageFdbAsyncUrl(url);
    },

    getImageFdbAsyncWithConfig: async (path, s3config = '', prefix = '') => {
        const url = FileTransform.getImageFdbUrlWithConfig(`${prefix}${path}`, s3config);
        return FileTransform.getImageFdbAsyncUrl(url);
    },
    getImageFdbSource: (path, isS3 = false) => {
        const newPath = FileTransform.getImageFdbUrl(path, isS3);
        const rs = {
            uri: newPath,
            method: 'GET',
            headers: {
                'spectos-apikey': ApiHelper.getApiKey(),
                Pragma: 'no-cache',
            },
            priority: 'normal',
        };
        console.log('getImageFdbSource>>>', rs);
        return rs;
    },
    getImageFdbSourceWithConfig: (path, s3config='TTM') => {
        const newPath = FileTransform.getImageFdbUrlWithConfig(path, s3config);
        const rs = {
            uri: newPath,
            method: 'GET',
            headers: {
                'spectos-apikey': ApiHelper.getApiKey(),
                Pragma: 'no-cache',
            },
            priority: 'normal',
        };
        console.log('getImageFdbSource>>>', rs);
        return rs;
    },

    fixUploadPath: path => {
        if(_.isEmpty(path)){
            return ''
        }
        const length = path.length
        const lastChar = path?.charAt(length-1);
        if(lastChar === '/'){
            return path.slice(0, -1);
        }
        return path
    },
    fixFileName: (fileName, newName) => {
        if (_.isEmpty(newName)) {
            return fileName
        }
        if (_.isEmpty(fileName)) {
            return newName
        }

        const ls = fileName?.split('.')
        if (_.isEmpty(ls)) {
            return newName
        }
        const extension = ls[ls.length - 1]
        const uuid = v4()
        return `${newName}_${uuid}.${extension}`
    },
    fixUrl: url => {
        if(_.isEmpty(url)){
            return url
        }
        if(url.startsWith(FileTransform.PREFIX_FDB_URL)){
            return url
        }
        return `${FileTransform.PREFIX_FDB_URL}/${url}`
    },
    fixUploadName: (uploadName, values) => {
        if(_.isEmpty(values) || _.isEmpty(uploadName)){
            return uploadName
        }
        const isSupportVariable = StringHelper.isSupportVariable(uploadName);
        let rs = uploadName
        if(isSupportVariable){
            // return StringHelper.fixEvalExpressionWithVariable(uploadName, values)
            const keys = Object.keys(values);
            for (const valuesKey of keys) {
                rs = replaceAll(rs, `{{${valuesKey}}}`, values[valuesKey]);
            }
        }
        return rs
    },
    getCloneIdOfCDS: (formData, formStructure) => {
        if(_.isEmpty(formData)){
            return ''
        }

        if(!!formData.cloneId){
            return formData.cloneId
        }

        const internalId = formStructure?.find(x => x.type === FORM.FORM_TYPE.camera)?.internalId
        if(_.isEmpty(internalId)){
            return ''
        }
        const images = formData[internalId]
        if(_.isEmpty(images)){
            return ''
        }

        if(typeof images[0] === 'string'){
            return images[0]
        }
        return ''
    },

};
