export const data = {
    hasRegister: false,
    "colors": {
        "TORQUISE": "#1abc9c",
        "EMERALD": "#2ecc71",
        "GREEN_SEA": "#16a085",
        "NEPHRITIS": "#27ae60",
        "SUN_FLOWER": "#f1c40f",
        "ORANGE": "#f39c12",
        "CARROT": "#e67e22",
        "PUMPKIN": "#d35400",
        "PETER_RIVER": "#3498db",
        "BELIZE_HOLE": "#2980b9",
        "ALIZARIN": "#e74c3c",
        "POMEGRANATE": "#c0392b",
        "AMETHYST": "#9b59b6",
        "WISTERIA": "#8e44ad",
        "WET_ASPHALT": "#34495e",
        "MIDNIGHT_BLUE": "#2c3e50",
        "ASBESTOS": "#7f8c8d",
        "CONCRETE": "#95a5a6",
        "SILVER": "#bdc3c7",
        "CLOUDS": "#ecf0f1",
        "WHITE": "#ffffff",
        "TRANSPARENT": "#00000000",
        "GREY_SHADOW_7": "rgba(216,216,216,0.7)",
        "MAIN_BLACK": "#333333",
        "LOADING_COLOR": "#999999",
        "ROYAL_MAIL_RED": "#fdd31f",
        "BACKGROUND_GREY": "#EDEDED",
        "RECESS_GREY": "#c1c6c8",
        "STRONG_GREY": "#53535a",
        "DARK_GREY": "#404044",
        "BLACK": "#2a2a2d",
        "POSITIVE_BLUE": "#0892cb",
        "GO_GREEN": "#62a531",
        "DEEP_GREEN": "#088578",
        "DYNAMIC_YELLOW": "#fdda24",
        "CLEAR_BLUE": "#aadaea",
        "GREEN_ACTIVE": "#8BB73B",
        "TAB_SELECTED": "#404044",
        "TAB_UNSELECTED": "#898989",
        "TAB_FAQ_UNSELECTED": "#fdd31f",
        "REPORT_BANNER_START": "#fdd31f",
        "REPORT_BANNER_END": "#fdd31f",
        "MAP_TAB_ACTIVE_START": "#fdd31f",
        "MAP_TAB_ACTIVE_END": "#fdd31f",
        "CHILD_TAB_ACTIVE_START": "#fdd31f",
        "CHILD_TAB_ACTIVE_END": "#fdd31f",
        "BUTTON_BACKGROUND": "#fdd31f",
        "BUTTON_TEXT": "#333333",
        "DASHBOARD_TEXT": "#333333",
        "DASHBOARD_BONUS_BANNER": "#fdd31f",
        "CHILD_TAB_TEXT_SELECTED": "#2a2a2d",
        "CHILD_TAB_TEXT_UNSELECTED": "#2a2a2d",
        "DASHBOARD_IMAGE": "#2a2a2d",
        "DASHBOARD_MAP": "#2a2a2d",
        "ERROR_TEXT": "#da202a"

    },

    "spacing": {
        "sm": 8,
        "ms": 10,
        "md": 16,
        "lg": 24
    },
    "icons": {
        "MailIcon": {},
        "EyeActiveIcon": {},
        "MarkerDashboardIcon": {},
        "PadLockIcon": {},
        "EyeInactiveIcon": {},
        "NumPadIcon": {},
        "QRIcon": {},
        "FrameIcon": {},
        "RadioIcon": {},
        "ActiveRadioIcon": {},
        "CheckIcon": {},
        "ActiveCheckIcon": {},
        "ChevronDownIcon": {},
        "CalendarIcon": {},
        "ClockIcon": {},
        "TickIcon": {},
        "CrossIcon": {},
        "MarkerQRIcon2": {},
        "ChevronPickerIcon": {},
        "UserIcon": {},
        "MarkerQRIcon": {},
        "LogoIcon": {},
        "MessageCheckIcon": {},
        "ThankYouIcon": {},
        "WarningIcon": {},
        "QRIcon2": {},
        "HomeIcon": {},
        "TaskIcon": {},
        "FAQIcon": {},
        "MenuIcon": {},
        "BackIcon": {},
        "NoAvatarIcon": {},
        "ChevronRightIcon": {},
        "PencilIcon": {},
        "PassedIcon": {},
        "FailedIcon": {},
        "ArrowRightIcon": {},
        "ArrowLeftIcon": {},
        "ArrowBorderIcon": {},
        "ClearIcon": {},
        "FilterIcon": {},
        "ImageIcon": {},
        "CirclePlusIcon": {},
        "SentIcon": {},
        "LogoBigIcon": {},
        "OverdueIcon": {},
        "AnswerIcon": {},
        "AwaitingIcon": {}
    },
    "images": {
        "Splash": "https://spectos-correos-prod.s3.eu-central-1.amazonaws.com/app/icons/inapp/splashscreen.png",
        "FallbackImage": "https://spectos-correos-prod.s3.eu-central-1.amazonaws.com/app/icons/inapp/fallbackImage.png",
        "ImagePicker": "https://spectos-correos-prod.s3.eu-central-1.amazonaws.com/app/icons/inapp/imagePicker.png",
        "ImageDefault": "https://spectos-correos-prod.s3.eu-central-1.amazonaws.com/app/icons/inapp/img_default.png"
    }
}