import _ from 'lodash'
import {Platform} from "react-native";
export const ValueType = {
    string: "string",
    number: 'number',
    bigint: 'bigint',
    boolean: 'boolean'
}
export const KeyboardHelper = {
    getKeyboardType: (type) => {
        switch (type){
            case ValueType.number:
            case ValueType.bigint:
                return 'number-pad'
                // return 'decimal-pad'
                // return Platform.OS === 'ios' ? 'numbers-and-punctuation' : 'decimal-pad'

        }
        return undefined
    },
    getKeyboardTypeOfPicker: data => {
        if(_.isEmpty(data)){
            return undefined
        }

        if(data && data.length > 0){
            const type = typeof data[0].id
            return KeyboardHelper.getKeyboardType(type)
        }

        return undefined
    },
    getValueTypeOfPicker: data => {
        if(_.isEmpty(data)){
            return undefined
        }

        if(data && data.length > 0){
            const type = typeof data[0].id
            return type
        }
        return undefined
    },
    correctValueOfPicker: (value, data) => {
        if(_.isEmpty(value)){
            return ''
        }
        // if(_.isEmpty())
        const type = KeyboardHelper.getValueTypeOfPicker(data)
        console.log('correctValueOfPicker>>>', type, value, data, _.isNumber(value))

        if(type === ValueType.number){
            return Number(value)
        }
        else if(type === ValueType.bigint){
            return BigInt(value)
        }
        //TODO: should support boolean.
        else{
            return value
        }
    }
}