import { CommonActions, StackActions, useNavigation, useRoute } from '@react-navigation/native';
import { getForgotFormRequest } from '@redux/auth/authSlice';
import { forgotFormSelector } from '@redux/auth/selectors';
import { getFormRequest } from '@redux/form/getFormSlice';
import { getFormStandard } from '@redux/form/selectors';
import { hideLoading, showLoading } from '@redux/global/globalSlice';
import { navigate, reset } from '@routes/navigationUtils';
import { FORGOT_SUCCESS, LOGIN_SCREEN, Screens } from '@routes/route';
import { resetPassword } from '@services/auth';
import { showErrorMsg } from '@utils/api';
import { getInitialFormValue, getYupStructure } from '@utils/form';
import { handleShowError } from '@utils/transform/form';
import { useFormik } from 'formik';
// import { t } from 'i18next';
import { cloneDeep } from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import { getHashPassword } from '@utils/common/passwordUtils';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

// const validationSchema = Yup.object().shape({
//   password: Yup.string().required(t('PASSWORD_IS_REQUIRED')),
//   confirmPassword: Yup.string()
//     .required(t('CONFIRM_PASSWORD_IS_REQUIRED'))
//     .oneOf([Yup.ref('password'), null], t('PASSWORDS_MUST_MATCH')),
// });

export const useLogic = () => {
  const { _, token } = useRoute()?.params || {};
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const { t } = useTranslation();
  const getFormData = useSelector(forgotFormSelector);
  const rawData = cloneDeep(getFormData?.[0]);

  const formik = useFormik({
    initialValues: getInitialFormValue(rawData, {}),
    validationSchema: Yup.object().shape(getYupStructure(rawData, t)),
    initialErrors: false,
    onSubmit: async (values, { setErrors, resetForm }) => {
      console.log(values);
      const { password } = values;
      try {
        let hashedPassword = await getHashPassword(password);
        const formData = new FormData();
        formData.append('reset_token', token);
        formData.append('password', hashedPassword);

        dispatch(showLoading());
        const rs = await resetPassword(formData);
        // navigation.dispatch(StackActions.replace(FORGOT_SUCCESS, { data: rs.message }));
        navigation.dispatch(
          StackActions.replace(Screens.SUCCESSFUL_SCREEN, {
            data: rs.message,
            header: { backClick: () => navigate(LOGIN_SCREEN) },
          }),
        );
      } catch (error) {
        //FIXME: why still navigate to FORGOT_SUCCESS when error?
        // navigation.dispatch(StackActions.replace(FORGOT_SUCCESS));
        handleShowError(error);
      } finally {
        dispatch(hideLoading());
      }
    },
  });

  useEffect(() => {
    getFormApi();
  }, []);

  const getFormApi = () => {
    console.log('forgotFormSaga>>>>');
    const payload = { id: 6 };
    dispatch(getForgotFormRequest(payload));
  };
  // const dispatch = useDispatch();
  // const navigation = useNavigation();

  // const formik = useFormik({
  //   initialValues: {
  //     password: '',
  //     confirmPassword: '',
  //   },
  //   onSubmit: values => {
  //     handleConfirm(values);
  //   },
  //   validationSchema: validationSchema,
  //   validateOnChange: false,
  //   validateOnBlur: false,
  // });

  const handleLoginPress = () => {
    navigate(LOGIN_SCREEN);
  };

  // const handleConfirm = async values => {
  //   const { password } = values;
  //   try {
  //     const hashedPassword = await sha256(password);

  //     const formData = new FormData();
  //     formData.append('reset_token', token);
  //     formData.append('password', hashedPassword);

  //     dispatch(showLoading());
  //     const res = await resetPassword(formData);
  //     if (res.status) {
  //       navigation.dispatch(StackActions.replace(FORGOT_SUCCESS));
  //     } else {
  //       throw res.message;
  //     }
  //   } catch (error) {
  //     showErrorMsg(error);
  //   } finally {
  //     dispatch(hideLoading());
  //   }
  // };
  console.log('useLogic>>>', formik, rawData);

  return { handleLoginPress, formik, rawData };
};
