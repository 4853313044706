import { CrossIcon, MarkerQRIcon } from '@assets';
import { AppButton, AppSafeAreaView, AppText } from '@components';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {Platform, TouchableHighlight, TouchableOpacity, View} from 'react-native';
import QRCodeScanner from 'react-native-qrcode-scanner';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { useLogic } from './ScanQR.hook';
import { styles } from './ScanQR.style';
import {globalStyle, WEB_WIDTH} from "@theme";

const HowToButton = ({ text, onPress }) => {
  return (
    <TouchableHighlight
        underlayColor='none'
      onPress={onPress}
      activeOpacity={1}
      style={styles.containerHowTo}>
      <View
        style={[
          styles.viewHowTo,
        ]}>
        <FontAwesome5 name={'info-circle'} color={'white'} size={17} style={[{ marginRight: 6 }]} />
        <AppText style={styles.textHowTo}>{text}</AppText>
      </View>
    </TouchableHighlight>
  );
};

const ScanQR = () => {
  const { handleReadQR, handleClosePress, onHowToPress, showHowTo } = useLogic();
  const { t } = useTranslation();
  const [focus, setFocus] = useState({
    width: 1,
    height: 1,
    x: 1,
    y: 1,
  });

  const renderMarkerForWebV2 = () => {
    console.log('renderMarkerForWebV2>>>', Device)

    return <View style={[{flexDirection: "column", width: WEB_WIDTH, height: WEB_WIDTH}]}>
      <View style={[{backgroundColor: '#00000050', height: WEB_WIDTH * 0.2}]}></View>
      <View style={[{ flexDirection: 'row', flex:1,  zIndex:100}]}>
        <View style={[{backgroundColor: '#00000050', width: WEB_WIDTH * 0.2}]}></View>
        <View style={[{backgroundColor: 'transparent', flex:1,     alignItems: 'center',
          justifyContent: 'center', zIndex:100}]}>
          <View>
            <MarkerQRIcon
                width={WEB_WIDTH * 0.65}
                height={WEB_WIDTH * 0.65}
                opacity={1}
            />
          </View>
          {showHowTo && (
              <HowToButton
                  style={[{ backgroundColor: 'transparent' }]}
                  text={t('HOW_TO')}
                  onPress={onHowToPress}
              />
          )}
        </View>
        <View style={[{backgroundColor: '#00000050', width: WEB_WIDTH* 0.2}]}></View>
      </View>

      <View style={[{backgroundColor: '#00000050', height: WEB_WIDTH * 0.2}]}></View>

    </View>
  }

  return (
    <AppSafeAreaView>
      <View
        style={styles.container}
        // onLayout={e => {
        //   const layout = {
        //     width: e.nativeEvent.layout.width,
        //     height: e.nativeEvent.layout.height,
        //   };
        //   setFocus(prev => {
        //     return { ...prev, width: layout.width, height: layout.height };
        //   });
        // }}
        // onTouchStart={e => {
        //   const location = {
        //     x: e.nativeEvent?.locationX,
        //     y: e.nativeEvent?.locationY,
        //   };
        //   setFocus(prev => {
        //     return { ...prev, x: location.x, y: location.y };
        //   });
        // }}
      >
        <View style={styles.form}>
          <TouchableOpacity onPress={handleClosePress} style={styles.closeBtn}>
            <CrossIcon />
          </TouchableOpacity>
          <QRCodeScanner
            cameraProps={{
              cameraViewDimensions: {
                width: Device.width,
                height: Device.height,
                // width: 50,
                // height: 50,
              },
              autoFocusPointOfInterest: { x: focus.x / focus.width, y: focus.y / focus.height },
            }}
            // cameraContainerStyle={[{width:50, height:50}]}
            vibrate={false}
            reactivate
            reactivateTimeout={2000}
            cameraStyle={styles.camera}
            onRead={handleReadQR}
            showMarker
            customMarker={
              <View style={styles.rectangleContainer}>
                <View style={styles.topOverlay} />

                <View style={{ flexDirection: 'row' }}>
                  <View style={styles.leftAndRightOverlay} />
                  <View style={styles.rectangle}>
                    <View>
                      <MarkerQRIcon
                        width={Device.isLargeDevice ? 300 : 280}
                        height={Device.isLargeDevice ? 300 : 280}
                      />
                    </View>

                    {showHowTo && (
                      <HowToButton
                        style={[{ backgroundColor: 'transparent' }]}
                        text={t('HOW_TO')}
                        onPress={onHowToPress}
                      />
                    )}

                  </View>

                  <View style={styles.leftAndRightOverlay} />
                </View>

                <View style={styles.bottomOverlay} />
              </View>
            }
          />
          {Platform.OS === 'web' && (<View style={[{position:'absolute', width: Device.width,
            height: Device.height, }, globalStyle.center]}>{renderMarkerForWebV2()}</View>)}
        </View>
      </View>
    </AppSafeAreaView>
  );
};

export { ScanQR };
