import theme from '@theme';
import { palette } from '@theme/colors';
import { StyleSheet } from 'react-native';

export const SuccessfulStyles = StyleSheet.create({
  areaStyle: { backgroundColor: '#EDEDED' },
  containerView: {
    borderTopRightRadius: theme.spacing.lg,
    borderTopLeftRadius: theme.spacing.lg,
    backgroundColor: palette.WHITE,
    // paddingTop: theme.spacing.lg,
    flex: 1,
    flexGrow: 1,
    // paddingHorizontal: theme.spacing.md,
    // paddingTop: theme.spacing.md,/
  },
  headerIconContent: {
    fontSize: 30,
    marginRight: -8,
    opacity: 0.5,
  },
});
