import theme from '@theme';
import { Font, FontSize, Spacing } from '@theme/size';
import { StyleSheet } from 'react-native';

export const ListTicketStyle = StyleSheet.create({
  listView: {
    marginVertical: theme.spacing.md,
    paddingHorizontal: theme.spacing.sm,
    // paddingLeft: Dimens.Margin.Normal,
    // paddingRight: Dimens.Margin.Normal,
  },
  filterView: {
    marginHorizontal: theme.spacing.md,
    marginTop: theme.spacing.md,
  },
  filterText: {
    fontSize: FontSize.Font15,
    color: '#666666',
    paddingLeft: theme.spacing.sm,
  },
  modalTitle: {
    // marginVertical: theme.spacing.md,
    paddingHorizontal: theme.spacing.md,
    marginVertical: Spacing.height16,

    // fontSize: FontSize.Font18,
    // fontWeight: '800',
    // color: '#333333',
    ...Font.Bold_700,
    fontSize: FontSize.Font17,
    color: '#333333',
  },
});
