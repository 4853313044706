import { ArrowBorderIcon, LogoIcon, MessageCheckIcon } from '@assets';
import {
  AppButton,
  AppKAView,
  AppMultipleSelection,
  AppPicker,
  AppSafeAreaView,
  AppSingleSelection,
  AppText,
} from '@components';
import GenericHeader from '@components/GenericHeader';
import { FORM } from '@constant/model/RegisterForm';
import { useQuizDetail } from '@screens/main/quiz/QuizDetail/QuizDetail.hook';
import { AppAnswerSingleSelection } from '@screens/main/quiz/QuizFailed/components/AppAnswerSingleSelection.view';
import { AppHtmlView, AppNoteView } from '@screens/main/quiz/QuizFailed/components/AppNote.view';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font, Spacing } from '@theme/size';
import { getErrorForm } from '@utils/form';
import { getContentTitle } from '@utils/transform/form';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, ScrollView } from 'react-native';
import { useQuizCompleted } from './QuizCompleted.hook';
import { QuizCompletedStyle } from './QuizCompleted.style';
import {QuizFailedStyle} from "@screens/main/quiz/QuizFailed/QuizFailed.style";

export const QuizCompletedScreen = props => {
  const { t } = useTranslation();
  const { data, formik, headerTitle } = useQuizCompleted(props);
  const renderItem = (item, index) => {
    const key = `${item._id}_${index}`;
    const selectedItem = item?.data?.find(x => x._id === formik.values[item._id]);
    // if (selectedItem?.correct) {
    //   return <></>;
    // }
    console.log('renderItem>>>', item, index, selectedItem, formik.values);
    switch (item.type) {
      case FORM.FORM_TYPE.hidden:
        return <></>;

      case FORM.FORM_TYPE.dropdown:
      case FORM.FORM_TYPE.singlechoice:
      case FORM.FORM_TYPE.stringlistsingle:
        if (item.popup === false) {
          return (
            <AppAnswerSingleSelection
              key={key}
              style={QuizCompletedStyle.formItemView}
              styleTitle={QuizCompletedStyle.titleForm}
              styleSelected={selectedItem?.correct ? QuizFailedStyle.passedItemForm : QuizFailedStyle.failedItemForm}
              data={item.data}
              selected={formik.values[item._id] ? formik.values[item._id] : ''}
              title={item?.title}
              onSelect={i => {
                console.log('AppSingleSelection>>>onSelect>>>', item, i, item._id, i?._id);
                formik.setFieldValue(item._id, i);
              }}
              disabled={true}
              error={getErrorForm(formik.errors, formik.touched, item._id)}>
              {/*{!!selectedItem?.hint && (*/}
              {/*  <View style={[globalStyle.directionColumm]}>*/}
              {/*    <View style={[{ marginLeft: 24, top: 1, zIndex: 100 }]}>*/}
              {/*      <ArrowBorderIcon height={14} width={16} strokeWidth={1} />*/}
              {/*    </View>*/}
              {/*    /!*<AppText style={QuizFailedStyle.hintView}>{getContentTitle(selectedItem?.hint)}</AppText>*!/*/}
              {/*    <AppHtmlView text={getContentTitle(selectedItem?.hint)} style={QuizFailedStyle.hintView} />*/}
              {/*  </View>*/}
              {/*)}*/}
            </AppAnswerSingleSelection>
          );
        }
        return (
          <AppPicker
            key={key}
            data={item.data}
            style={QuizCompletedStyle.formItemView}
            selected={formik.values[item._id]}
            title={item?.title}
            header={item?.title}
            onSelect={i => {
              console.log('onSelect>>>', item._id, i, i?._id);
              formik.setFieldValue(item._id, i);
            }}
            error={getErrorForm(formik.errors, formik.touched, item._id)}
          />
        );
      case FORM.FORM_TYPE.MultipleSelection:
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return (
          <AppMultipleSelection
            key={key}
            style={QuizCompletedStyle.formItemView}
            data={item.data}
            selected={formik.values[item._id] ? formik.values[item._id] : []}
            title={item?.title}
            onSelect={i => {
              console.log('AppSingleSelection>>>onSelect>>>', item, i, item._id, i?._id);
              formik.setFieldValue(item._id, i);
            }}
            error={getErrorForm(formik.errors, formik.touched, item._id)}
          />
        );
      case FORM.FORM_TYPE.SingleSelection:
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return (
          <AppSingleSelection
            key={key}
            style={QuizCompletedStyle.formItemView}
            data={item.data}
            selected={formik.values[item._id] ? formik.values[item._id] : []}
            title={item?.title}
            onSelect={i => {
              console.log('AppMultipleSelection>>>onSelect>>>', item, i, item._id, i?._id);
              formik.setFieldValue(item._id, i);
            }}
            error={getErrorForm(formik.errors, formik.touched, item._id)}
          />
        );
      // case FORM.FORM_TYPE.note:
      //   console.log('type>>>>note>>>', item);
      //   return (
      //     <AppNoteView
      //       key={key}
      //       style={QuizFailedStyle.formItemView}
      //       // data={item.data}
      //       // selected={formik.values[item._id] ? formik.values[item._id] : []}
      //       title={item?.title}
      //       // onSelect={i => {
      //       //   console.log('AppMultipleSelection>>>onSelect>>>', item, i, item._id, i?._id);
      //       //   formik.setFieldValue(item._id, i);
      //       // }}
      //       // error={getErrorForm(formik.errors, formik.touched, item._id)}
      //     />
      //   );
    }
    return <></>;
  };
  return (
    <AppSafeAreaView areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>
      {/*<AppKAView>*/}
      <GenericHeader title={headerTitle} style={[{ backgroundColor: palette.BACKGROUND_GREY }]} />
      <View style={[globalStyle.directionColumm, globalStyle.flex1, QuizCompletedStyle.containerView]}>
        <ScrollView style={[]} keyboardShouldPersistTaps="handled">
          {/*<AppText*/}
          {/*  style={[*/}
          {/*    globalStyle.alignCenter,*/}
          {/*    globalStyle.justifyCenter,*/}
          {/*    QuizCompletedStyle.textTitle,*/}
          {/*    Font.Bold_700,*/}
          {/*    { textAlign: 'center' },*/}
          {/*  ]}>*/}
          {/*  {t('QUIZ_FAILED_TITLE')}*/}
          {/*</AppText>*/}
          {/*<AppText*/}
          {/*  style={[*/}
          {/*    globalStyle.alignCenter,*/}
          {/*    globalStyle.justifyCenter,*/}
          {/*    QuizCompletedStyle.textDescription,*/}
          {/*    { textAlign: 'center' },*/}
          {/*  ]}>*/}
          {/*  {t('QUIZ_FAILED_DESCRIPTION')}*/}
          {/*</AppText>*/}
          {/*<View style={[{ marginHorizontal: theme.spacing.md }]}>*/}
          {/*  <View style={[QuizFailedStyle.lineView]} />*/}
          {/*</View>*/}
          <View style={[globalStyle.directionColumm, RegisterFormStyles.paddingSpace]}>
            {data?.map((item, index) => {
              return renderItem(item, index);
            })}
          </View>
        </ScrollView>
        <View style={[RegisterFormStyles.paddingSpace, { marginVertical: Spacing.height25 }]}>
          <AppButton text={t('OK')} onPress={formik.handleSubmit} />
        </View>
      </View>
    </AppSafeAreaView>
  );
};
