import { REPORT_TYPE, UPLOAD_FILE } from '@constant';
import { Config } from '@constant/config';
import { FORM } from '@constant/model/RegisterForm';
import { ReportBundleMockData } from '@constant/model/mock/report/bundle';
import { ReportICodeMockData } from '@constant/model/mock/report/iCode';
import { useNavigation, useRoute } from '@react-navigation/native';
import { getValidateReportRequest } from '@redux/app/appSlice';
import { reportModal as reportModalSelector, validateReportSelector } from '@redux/app/selectors';
import { loginRequest } from '@redux/auth/authSlice';
import { authUser } from '@redux/auth/selectors';
import { getListPostingLocationRequest } from '@redux/bundle/getListPostingLocationSlice';
// import { loginRequest } from '@redux/auth/authSlice';
import { submitBundleRequest } from '@redux/bundle/submitBundleSlice';
import { uploadFileRequest } from '@redux/file/uploadFileSlice';
import { getReportBundleFormRequest } from '@redux/form/getReportBundleFormSlice';
import { getReportBundleFormCreator } from '@redux/form/selectors';
import { getProfileRequest } from '@redux/user/getProfileSlice';
import { getSettingsRequest } from '@redux/user/getSettingsSlice';
import {navigate, popToTop} from '@routes/navigationUtils';
import { BundleAddressContainerModalStyle } from '@screens/main/report/bundle/form/components/BundleAddressContainerModal.style';
import { SubForm } from '@screens/main/report/bundle/form/components/DispatchPoint.hook';
import { Device } from '@theme/device';
import { ReportModalTransform } from '@utils';
import { StringHelper } from '@utils/common/string';
import { checkFormValidateExpression, getInitialFormValue, getYupStructure } from '@utils/form';
import { useLocation } from '@utils/hooks';
import { BundleTransform } from '@utils/transform/bundle';
import { FormTransform, mappingStep } from '@utils/transform/form';
import { rejects } from 'assert';
import { useFormik } from 'formik';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { lazy } from 'yup';
import * as Yup from 'yup';
import {Screens} from "@routes/route";

export const useContainerBundle = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { lat, long, loading } = useLocation(props);
  const scrollViewRef = useRef(null);
  const [layoutInfo, setLayoutInfo] = useState({});

  const paramsX = useRoute().params;
  console.log('useRoute()?.params>>>', useRoute()?.params);
  const formModal = useSelector(reportModalSelector);
  const validateReportData = useSelector(validateReportSelector);
  const loginInfo = useSelector(authUser);

  console.log('userData>>>', validateReportData);

  const headerTitle = ReportModalTransform.getReportTitle(REPORT_TYPE.bundles, formModal);
  const info = {
    id: paramsX?.id,
    total: props.data?.data?.count_testitems,
    date: props.data?.data?.target_dispatch_date,
    reportDetail: props.data?.data?.reportDetail,
  };
  const lo = Config.IsMockServer ? ReportBundleMockData.userLocation : {};
  const [userLocation, setUserLocation] = useState(lo);
  const [isSuccess, setIsSuccess] = useState(false);
  const [point, setPoint] = useState(0);
  const [summary, setSummary] = useState({
    ...info,
  });
  const [messageInfo, setMessageInfo] = useState({});


  const subFormRef = useRef();

  const initialValues = getInitialFormValue(props.formData, {
    ...props.data?.data?.reportDetail,
  });
  const posting_location_id = BundleTransform.getDefaultPostingLocationId(props.data?.data?.reportDetail);
  const postbox_image_url = initialValues?.postbox_image_url ?? [];
  const reset_bundle_address = !!props.data?.data?.reportDetail?.reset_bundle_address;
  const collection_times_image_url = initialValues?.collection_times_image_url ?? [];
  const confirm_collection_time = initialValues?.confirm_collection_time ?? '';

  const scrollToY = y => {
    // scrollViewRef.current.scroll
    scrollViewRef.current?.scrollTo({
      x: 0,
      y: y,
      animated: true,
    });
  };

  const x = {
    ...initialValues,
    posting_location_id,
    postbox_image_url,
    collection_times_image_url,
    confirm_collection_time,
  };

  const formik = useFormik({
    initialValues: {
      ...x,
    },
    enableReinitialize: false,
    validationSchema: () =>
      lazy(vl => {
        const values = _.cloneDeep(formik.values);
        console.log('lazy>>>values>>>', vl, values);
        // setPostingLocationId(v.posting_location_id);
        const ys = getYupStructure(props.formData, t, 'attributes', values);
        console.log('getYupStructure>>>', ys, formik);
        return Yup.object().shape(ys);
      }),
    initialErrors: false,
    onSubmit: async (values, { setErrors, resetForm }) => {
      console.log('onSubmit>>>', values, formik.errors, props.formData);

      const rs = checkFormValidateExpression(props.formData?.attributes, values, setErrors);
      console.log('validateForm>>>init', subFormRef?.current);

      //TODO: should check before submit validate in parent form
      if (subFormRef?.current != null) {
        const subForm =
          props.formData?.attributes?.find(x => x.internalId === FORM.FORM_ELEMENT_NAME.dispatch_point)?.subForms ?? [];
        // const subForm = SubForm

        console.log('validateForm>>>before>>>', subFormRef?.current);
        // subFormRef?.current?.handleSubmit
        const errSubForm = await FormTransform.validateForm(subFormRef?.current, subForm, true);
        console.log('errSubForm>>>', errSubForm);
        if (!_.isEmpty(errSubForm)) {
          return;
        }
        values = {
          ...values,
          ...subFormRef?.current?.values,
        };
      }

      console.log('validateForm>>>parent', rs);

      if (!_.isEmpty(rs)) {
        formik.setErrors(_.merge(formik.errors, rs));
        return;
      }
      // return
      handleSubmit(values);

      //TODO: validate here
      // updateUserApi(values, isLastStep);
      // onSavePress();
    },
  });

  const submitBundleApi = payload => {
    dispatch(
      submitBundleRequest(
        payload,
        rs => {
          handleSuccess(rs);
        },
        err => {},
      ),
    );
  };

  const handleSuccess = rs => {
    //TODO: reload api dashboard here
    setPoint(rs?.data?.point ?? 0);
    // setMessageInfo(rs?.message);
    // setIsSuccess(true);
    navigate(Screens.SUCCESSFUL_SCREEN, {
      data: rs?.message,
      header: {
        title: headerTitle,
        backClick: () => {
          // popToTop();
        },
      },
    });
  };

  const handleSubmit = async values => {
    // let payload = await uploadAllFileApi(values);
    let payload = await FormTransform.uploadFileFormikFDBApi(
      values,
      props.formData?.attributes,
      formik,
      dispatch,
      UPLOAD_FILE.folderUpload.bundle,
    );
    payload.id = paramsX?.id;
    payload.deviceType = Device.deviceType;
    // payload.posting_location_id = values?.posting_location_id ?? summary?.reportDetail?.posting_location_id;
    payload = {
      ...payload,
      changeBy: loginInfo?.changeBy,
      changeType: loginInfo?.changeType,
    };
    if (!_.isEmpty(userLocation) && !!userLocation?.longitude) {
      payload.user_location = Object.values(userLocation);
    } else {
      payload.user_location = null;
    }
    console.log('handleSubmit>>>payload>>', values, payload, userLocation, !!userLocation?.longitude);
    // return;
    const mock = Config.IsMockServer;
    if (mock) {
      handleSuccess();
    } else {
      submitBundleApi(payload);
    }
  };

  console.log('useReportPackageForm>>>', formik);

  useEffect(() => {
    setUserLocation({
      latitude: lat,
      longitude: long,
    });
    console.log('location>>>', lat, long);
  }, [lat, long]);
  useEffect(() => {
    if (x.dispatch_point == '"Collection' && x.search_postcode && x.dispatch_point) {
      let payload = {
        longitude: long,
        latitude: lat,
        type: x.dispatch_point,
      };
      dispatch(
          getListPostingLocationRequest(
              payload,
              rs => {
                if (subFormRef?.current != null) {
                  subFormRef.current.values[FORM.EXTRA_FORM_ELEMENT_NAME.reset_bundle_address] = reset_bundle_address;
                }
                // let data =  BundleTransform.mappingAddress(rs?.data);
                // _.forEach(data, function (item){
                //   console.log('======reset_bundle_address=====', reset_bundle_address);
                // });
              }
          ),
      );
    }
  }, []);
  return {
    formik,
    summary,
    isSuccess,
    userLocation,
    setUserLocation,
    point,
    headerTitle,
    subFormRef,
    messageInfo,
    scrollViewRef,
    layoutInfo,
    setLayoutInfo,
    scrollToY,
  };
};
