import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppKAView, AppSafeAreaView, AppText } from '@components';
import { AppSuccessScreen } from '@components/AppSuccessScreen';
import GenericHeader from '@components/GenericHeader';
import { SuccessMessageMock } from '@constant/model/mock/successMessage';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font } from '@theme/size';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text } from 'react-native';
import { useQuizSuccessful } from './QuizSuccessful.hook';
import { QuizSuccessfulStyle } from './QuizSuccessful.style';
import {goBack, popToTop} from "@routes/navigationUtils";

export const QuizSuccessfulScreen = props => {
  const { t } = useTranslation();
  const { headerTitle, messageInfo } = useQuizSuccessful(props);
  return (
    // <AppSafeAreaView areaStyle={[globalStyle.flex1, globalStyle.alignCenter, globalStyle.justifyCenter]}>
    //   {/*<Icon name="comment-dots" size={40} color={palette.ROYAL_MAIL_RED} />*/}
    //   <MessageCheckIcon color={palette.GREEN_ACTIVE} />
    //   <View
    //     style={[
    //       globalStyle.alignCenter,
    //       globalStyle.justifyCenter,
    //       globalStyle.alignSelfCenter,
    //       globalStyle.directionColumm,
    //     ]}>
    //     <Text style={[Font.Bold_700, { textAlign: 'center', margin: theme.spacing.sm }]}>
    //       {t('QUIZ_SUCCESSFUL_TITLE')}
    //     </Text>
    //     <AppText style={[{ textAlign: 'center', marginHorizontal: theme.spacing.sm }]}>
    //       {t('QUIZ_SUCCESSFUL_DESCRIPTION')}
    //       {'\n'}
    //       {`${headerTitle}`}
    //     </AppText>
    //   </View>
    // </AppSafeAreaView>
    <AppSafeAreaView areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>
      {/*<AppKAView>*/}
      <GenericHeader title={headerTitle} style={[{ backgroundColor: palette.BACKGROUND_GREY }]} onBackClicked={popToTop}/>

      <AppSuccessScreen alert={messageInfo?.alert} icon={messageInfo?.icon} description={messageInfo?.description} />
    </AppSafeAreaView>
  );
};
