import type { RootState } from '@redux/reducers';
import { createSelector } from '@reduxjs/toolkit';
import { getErrorMessage } from '@utils/api';
import { QuizTransform} from '@utils/transform/quiz';

export const getListQuizSelector = (state: RootState) => state.getListQuiz;
export const getListQuizCreator = createSelector(getListQuizSelector, state => QuizTransform.mappingListQuiz(state.payload));
export const getListQuizLoading = createSelector(getListQuizSelector, state => state.loading);
export const getListQuizError = createSelector(getListQuizSelector, state => state.error);
export const getListQuizErrorMsg = createSelector(getListQuizError, state => getErrorMessage(state));
export const getAnswerQuiz = createSelector(getListQuizSelector, state => state.answers);


export const getQuizSelector = (state: RootState) => state.getQuiz;
export const getQuizCreator = createSelector(getQuizSelector, state => state.payload);
export const getQuizLoading = createSelector(getQuizSelector, state => state.loading);
export const getQuizError = createSelector(getQuizSelector, state => state.error);
export const getQuizErrorMsg = createSelector(getQuizError, state => getErrorMessage(state));

