import { ChevronRightIcon } from '@assets';
import { AppText } from '@components';
import { DASHBOARD_ITEM_TYPE } from '@constant';
import { FlexImageIcon } from '@screens/main/Menu/Components';
import { FontSize, Spacing } from '@theme/size';
import _ from 'lodash';
import React from 'react';
import { Platform, TouchableOpacity, View, useWindowDimensions } from 'react-native';
import { useLogic } from './DashboardItem.hook';
import { styles } from './DashboardItem.style';
import {AppHtmlView} from "@screens/main/quiz/QuizFailed/components/AppNote.view";

// import {DashboardHelper} from "@utils/common/dashboard";
import {Device} from "@theme/device";

const Content = ({ type, data, internalId, info, statistics, dashboard }) => {
  const scaleFont = useWindowDimensions().fontScale;
  const hasProperty = property => data?.some(item => property in item);
  switch (type) {
    case DASHBOARD_ITEM_TYPE.list:
      // if (hasProperty('amount')) {
      return (
        <View style={styles.list}>
          {data?.map((item, index) => {
            return (
              <View key={index} style={styles.itemWrap}>
                <View
                  style={{
                    // marginTop: Spacing.height4,
                    // marginBottom: Spacing.height10,
                    flexDirection: 'row',
                    alignItems: 'center',
                    flex: 1,
                  }}>
                  {!!item?.icon && (
                    <FlexImageIcon
                      icon={item?.icon}
                      style={{
                        width: Spacing.width16 * scaleFont,
                        height: Spacing.width16 * scaleFont,
                        marginRight: Spacing.width9,
                      }}
                    />
                  )}
                  {_.isNil(item?.amount) && (
                    <AppText
                      style={[
                        styles.name,
                        // styles.item,
                        {
                          textAlignVertical: 'center',
                          marginTop: 0,
                        },
                      ]}>
                      {item?.name}
                    </AppText>
                  )}
                  {!_.isNil(item?.amount) && (
                    <AppText
                      style={[styles.name, { flex: 0, whiteSpace: Platform.OS === 'web' ? 'nowrap' : undefined }]}>
                      {item?.name}: <AppText style={[styles.count]}>{item?.amount || 0}</AppText>
                    </AppText>
                  )}
                </View>
              </View>
            );
          })}
        </View>
      );
    // }
    // return (
    //   <View style={styles.list}>
    //     {data?.map((item, index) => {
    //       return (
    //         <View key={index} style={styles.itemWrap}>
    //           <View
    //             style={{
    //               // marginTop: Spacing.height4,
    //               // marginBottom: Spacing.height10,
    //               flexDirection: 'row',
    //               alignItems: 'center',
    //               flex: 1,
    //             }}>
    //             <FlexImageIcon
    //               icon={item?.icon}
    //               style={{
    //                 width: Spacing.width16 * scaleFont,
    //                 height: Spacing.width16 * scaleFont,
    //                 // marginTop: 5,
    //               }}
    //             />
    //             <AppText
    //               style={[
    //                 styles.name,
    //                 styles.item,
    //                 {
    //                   textAlignVertical: 'center',
    //                   marginTop: 0,
    //                 },
    //               ]}>
    //               {item?.name}
    //             </AppText>
    //           </View>
    //         </View>
    //       );
    //     })}
    //   </View>
    // );

    case DASHBOARD_ITEM_TYPE.item:
      console.log('DASHBOARD_ITEM_TYPE.item>>>', data, info);
      // const htmlText = '<b>The Panelmanagement team</b> needs the possibility to publish instant quick messages to panellists <br> which are displayed within the dashboard for every panellist within a panel'
      // const htmlText = '<b>The Panelmanagement team</b> needs the possibility to publish instant quick'
      if(!!info?.supportHtml){
        return (
          <AppHtmlView
            style={styles.descHtml}
            text={data?.toString()}
            // text={htmlText}
            numberOfLines={3}
            widthEstimate={Device.width - 58 * 2}
          />
        );
      }
      return <AppText style={styles.desc} numberOfLines={3} >{data?.toString()}</AppText>;
    case DASHBOARD_ITEM_TYPE.date:
      return <AppText style={styles.date}>{data}</AppText>;

    default:
      return <AppText style={styles.desc} numberOfLines={3} >{data}</AppText>;
  }
};

export const DashboardItem = props => {
  const { isLast, data, notificationItem, dashboard } = props;
  const fontScale = useWindowDimensions().fontScale;

  // const {} = useLogic();

  console.log('DashboardItem>>>', props)

  if (data?.type === 'notification' || !data?.show) {
    return null;
  }

  const xData = data?.data;
  // const htmlText = '<b>The Panelmanagement team</b> needs the possibility to publish instant quick messages to panellists <br> which are displayed within the dashboard for every panellist within a panel'
  // const htmlText = '<b>The Panelmanagement team</b> needs the possibility to publish instant quick'

  if (_.isEmpty(xData)) {
    return <></>;
  }
    return (
    <TouchableOpacity
      onPress={() => {
        // if (data?.type === 'list') navigate(LIST_QUIZ_SCREEN);
        if (props.onPress) {
          props.onPress(data);
        }
      }}
      activeOpacity={0.7}
      style={[Platform.OS === 'web' ? styles.content : styles.container, isLast ? { marginBottom: 54 } : {}]}>
      <View style={[styles.left]}>
        <View style={[styles.leftContainer]}>
          <FlexImageIcon icon={data?.icon} style={styles.iconDashboard} />
        </View>
      </View>
      <View style={styles.right}>
        <View
          style={[
            styles.nameWrap,
            {
              minHeight: Platform.OS === 'web' ? undefined : Spacing.height40 * fontScale,
              borderBottomWidth: 1,
              borderBottomColor: 'white',
              paddingVertical: Spacing.width6,
            },
          ]}>
          <AppHtmlView
            style={{
              ...styles.name,
              marginBottom: 0,
            }}
            // widthEstimate={Device.width - 58 * 2}
            numberOfLines={3}
            text={data?.name}
            // text={htmlText}
          />
          <ChevronRightIcon />
        </View>
        <Content
          type={data?.type}
          data={xData}
          internalId={data?.internalId}
          info={data}
          statistics={notificationItem?.data?.statistics}
          dashboard={dashboard}
        />
      </View>
    </TouchableOpacity>
  );
};
