import { AppText } from '@components';
import { globalStyle } from '@theme';
import { palette } from '@theme/colors';
import { Font, FontSize } from '@theme/size';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Switch } from 'react-native-switch';

export const AppSwitch = props => {
  const {
    textStyle,
    value,
    onValueChange,
    style,
    error = '',
    label,
    isRequired,
    disabled = false,
    onLayout = null,
    hint = '',
    isBoldTitle,
  } = props;
  // console.log('AppRadio>>>', data, selected);
  // const checkId = id => {
  //   if (typeof id === 'boolean') {
  //     return id !== undefined;
  //   }
  //   return !!id;
  // };
  return (
    <View
      style={[style, { width: '100%' }]}
      onLayout={e => {
        if (onLayout) {
          onLayout(e);
        }
      }}>
      <View style={styles.container}>
        {!!label && (
          <AppText style={[styles.text, textStyle, isBoldTitle ? { fontWeight: '700' } : {}]}>
            {isRequired && <AppText style={styles.isRequired}>* </AppText>}
            {label}
          </AppText>
        )}
        <Switch
          value={value}
          onValueChange={onValueChange}
          disabled={disabled}
          circleSize={22}
          barHeight={28}
          circleBorderWidth={0}
          backgroundActive={palette.ROYAL_MAIL_RED}
          backgroundInactive={palette.RECESS_GREY}
          circleActiveColor={palette.WHITE}
          circleInActiveColor={palette.WHITE}
          changeValueImmediately={true} // if rendering inside circle, change state immediately or wait for animation to complete
          innerCircleStyle={{ marginLeft: value ? -5 : 5 }} // style for inner animated circle for what you (may) be rendering inside the circle
          renderActiveText={false}
          renderInActiveText={false}
        />
      </View>
      {!!hint && <AppText style={styles.hint}>{hint}</AppText>}
      {!!error && <AppText style={styles.error}>{error}</AppText>}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  text: {
    ...Font.Bold_700,
    flex: 1,
    fontSize: FontSize.Font15,
  },
  hint: {
    fontWeight: '400',
    color: '#999999',
    marginTop: 7,
  },
  error: {
    marginTop: 7,
    color: palette.ROYAL_MAIL_RED,
    fontWeight: '400',
  },
  isRequired: {
    color: '#FF5757',
  },
});
