import { AppText } from '@components';
import React, { memo } from 'react';
import { View } from 'react-native';
import { useLogic } from './Footer.hook';
import { styles } from './Footer.style';
import {globalStyle} from "@theme";

export const Footer = memo(props => {
  const { data } = props;
  const { t } = useLogic();
  return (
    <View style={[styles.container]}>
      <AppText style={[styles.text]}>{data > 1 ? t('SHOW_LOCATIONS', { data }) : t('SHOW_LOCATION', { data })}</AppText>
    </View>
  );
});
