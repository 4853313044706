import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppKAView, AppSafeAreaView, AppText } from '@components';
import GenericHeader from '@components/GenericHeader';
import { goBack } from '@routes/navigationUtils';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font, Spacing } from '@theme/size';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Text, TouchableOpacity } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { useReportTab } from './ReportTab.hook';
import { ReportTabStyle } from './ReportTab.style';

export const TabBar = props => {
  const { state, descriptors, navigation } = props;
  const { t } = useTranslation();
  const { onItemPress, listName, selectedIndex } = useReportTab(props);
  console.log({ listName });

  const activeColors = ['#E1000E', '#870007CC'];
  const inactiveColors = ['white', 'white'];
  return (
    <View style={{ flexDirection: 'row', paddingHorizontal: Spacing.width15 }}>
      {listName?.map((name, index) => {
        return (
          <TouchableOpacity
            onPress={() => {
              onItemPress(index);
            }}
            style={[
              ReportTabStyle.button,
              index === listName.length - 1 ? ReportTabStyle.isLast : ReportTabStyle.isFirst,
              selectedIndex === index && index === listName.length - 1 ? { borderLeftWidth: 0 } : {},
              selectedIndex === index && index === 0 ? { borderRightWidth: 0 } : {},
            ]}>
            <LinearGradient
              colors={selectedIndex === index ? activeColors : inactiveColors}
              style={[
                ReportTabStyle.linearGradient,
                index === listName.length - 1 &&
                  !(selectedIndex === index) && {
                    borderTopRightRadius: 4,
                    borderBottomRightRadius: 4,
                  },
                index === 0 &&
                  !(selectedIndex === index) && {
                    borderTopLeftRadius: 4,
                    borderBottomLeftRadius: 4,
                  },
              ]}>
              <AppText style={[selectedIndex === index ? { color: palette.WHITE } : {}]}>{name}</AppText>
            </LinearGradient>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};