import React, {FC} from 'react';
import {View} from 'react-native';

/**
 * An empty component used to manage SplashScreenWeb from Suspense fallback
 */
const SplashScreen: FC = () => {
    return (<View>
        </View>
    )
};
export default SplashScreen;

