import { getFormSaga } from '@redux/form/getFormSaga';
import { getFormRequest } from '@redux/form/getFormSlice';
import { getProfileFormSaga } from '@redux/form/getProfileFormSaga';
import { getProfileFormRequest } from '@redux/form/getProfileFormSlice';
import { getMenuformSaga } from '@redux/form/getMenuformSaga';
import { getMenuformRequest } from '@redux/form/getMenuformSlice';
import { getFAQSaga } from '@redux/form/getFAQSaga';
import { getFAQRequest } from '@redux/form/getFAQSlice';
import { getReportModalSaga } from '@redux/form/getReportModalSaga';
import { getReportModalRequest } from '@redux/form/getReportModalSlice';
import { getForgotPasswordSaga } from '@redux/form/getForgotPasswordSaga';
import { getForgotPasswordRequest } from '@redux/form/getForgotPasswordSlice';
import { getReportPackageFormSaga } from '@redux/form/getReportPackageFormSaga';
import { getReportPackageFormRequest } from '@redux/form/getReportPackageFormSlice';
import { getReportBundleFormSaga } from '@redux/form/getReportBundleFormSaga';
import { getReportBundleFormRequest } from '@redux/form/getReportBundleFormSlice';
import { getICodeFormSaga } from '@redux/form/getICodeFormSaga';
import { getICodeFormRequest } from '@redux/form/getICodeFormSlice';
import { getCDSFormSaga } from '@redux/form/getCDSFormSaga';
import { getCDSFormRequest } from '@redux/form/getCDSFormSlice';
import { getD2DFormSaga } from '@redux/form/getD2DFormSaga';
import { getD2DFormRequest } from '@redux/form/getD2DFormSlice';
import { getHolidayFormSaga } from '@redux/form/getHolidayFormSaga';
import { getHolidayFormRequest } from '@redux/form/getHolidayFormSlice';
import { getRFIDFormReceiverSaga } from '@redux/form/getRFIDFormReceiverSaga';
import { getRFIDFormReceiverRequest } from '@redux/form/getRFIDFormReceiverSlice';
import { getRFIDFormSaga } from '@redux/form/getRFIDFormSaga';
import { getRFIDFormRequest } from '@redux/form/getRFIDFormSlice';
import { getQRFormSaga } from '@redux/form/getQRFormSaga';
import { getQRFormRequest } from '@redux/form/getQRFormSlice';
import { getCreateTicketFormSaga } from '@redux/form/getCreateTicketFormSaga';
import { getCreateTicketFormRequest } from '@redux/form/getCreateTicketFormSlice';
import { getReturnTransponderFormSaga } from '@redux/form/getReturnTransponderFormSaga';
import { getReturnTransponderFormRequest } from '@redux/form/getReturnTransponderFormSlice';

import { ForkEffect, takeLatest } from 'redux-saga/effects';

function* formSagas(): Generator<ForkEffect<never>, void> {
  yield takeLatest(getFormRequest.type, getFormSaga);
  yield takeLatest(getProfileFormRequest.type, getProfileFormSaga);
  yield takeLatest(getMenuformRequest.type, getMenuformSaga);
  yield takeLatest(getFAQRequest.type, getFAQSaga);
  yield takeLatest(getReportModalRequest.type, getReportModalSaga);
  yield takeLatest(getForgotPasswordRequest.type, getForgotPasswordSaga);
  yield takeLatest(getReportPackageFormRequest.type, getReportPackageFormSaga);
  yield takeLatest(getReportBundleFormRequest.type, getReportBundleFormSaga);
  yield takeLatest(getICodeFormRequest.type, getICodeFormSaga);
  yield takeLatest(getCDSFormRequest.type, getCDSFormSaga);
  yield takeLatest(getD2DFormRequest.type, getD2DFormSaga);
  yield takeLatest(getHolidayFormRequest.type, getHolidayFormSaga);
  yield takeLatest(getRFIDFormReceiverRequest.type, getRFIDFormReceiverSaga);
  yield takeLatest(getRFIDFormRequest.type, getRFIDFormSaga);
  yield takeLatest(getQRFormRequest.type, getQRFormSaga);
  yield takeLatest(getCreateTicketFormRequest().type, getCreateTicketFormSaga);
  yield takeLatest(getReturnTransponderFormRequest.type, getReturnTransponderFormSaga);
}

export default formSagas;
