import { useRoute } from '@react-navigation/native';
import { Screens } from '@routes/route';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useTicket = props => {
  const dispatch = useDispatch();
  const initialRouteName = props?.route?.params?.tabScreen ?? Screens.ADD_TICKET_SCREEN;
  const initialParams = props?.route?.params?.params ?? {};

  console.log('useTicket>>>', props?.route?.params, initialRouteName);
  return { initialRouteName, initialParams };
};
