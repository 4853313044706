import { CommonImages, CrossIcon, EyeActiveIcon, EyeInactiveIcon, ImageIcon } from '@assets';
import { AppModal, AppPicker, AppSingleSelection, AppText } from '@components';
import { AppLazyImage } from '@components/AppLazyImage';
import { UPLOAD_FILE } from '@constant';
import { IMAGE_PICKER_TYPE } from '@constant/model/RegisterForm';
import theme from '@theme';
import { palette } from '@theme/colors';
import { FontSize, Spacing } from '@theme/size';
import { AppSetting } from '@utils/common/setting';
import { FileTransform } from '@utils/transform/file';
import { t } from 'i18next';
import _ from 'lodash';
import { array } from 'prop-types';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, TextInput, StyleSheet, Keyboard, TouchableOpacity, Image, FlatList, ScrollView } from 'react-native';
import { useAppImagePicker } from './AppImagePicker.hook';

export const AppImagePicker = React.memo(props => {
  const {
    style,
    error = '',
    boxStyle,
    title,
    isRequired,
    isBoldTitle,
    hint = '',
    type = IMAGE_PICKER_TYPE.gallery,
    folderUpload = '',
    maxFile = null,
  } = props;
  const {
    handlePickImage,
    handleDelete,
    selected = [],
    isEnable,
    options,
    isShow,
    setShow,
    onLoadedUri,
  } = useAppImagePicker(props);
  // console.log('AppImagePicker>>>', selected);
  // console.log('AppImagePicker>>>hint>>>', hint);
  // console.log('AppImagePicker>>>error>>>', error);
  // console.log('AppImagePicker>>>boxStyle>>>', boxStyle);
  // // const error = 'test test tes'

  const renderListItem = () => {
    // if (_.isEmpty(selected)) {
    //   return (
    //     <TouchableOpacity
    //       disabled={!isEnable()}
    //       onPress={() => {
    //         setShow(true);
    //       }}
    //       style={[styles.boxSingle, boxStyle]}>
    //       <Image style={[{ width: '100%' }]} source={CommonImages.ImagePicker} resizeMode="contain" />
    //       <ImageIcon style={{ position: 'absolute' }} />
    //     </TouchableOpacity>
    //   );
    // }
    return (
        <FlatList
            contentContainerStyle={{
              paddingTop: 10,
              // backgroundColor: 'red'
            }}
            horizontal={false}
            numColumns={3}
            data={['createItem', ...selected]}
            keyExtractor={item => item.id || item}
            renderItem={({ item, index }) => {
              console.log('renderItem>>>index>>>', index);
              console.log('renderItem>>>item>>>', item);

              if (item === 'createItem')
                return (
                    <TouchableOpacity
                        disabled={!isEnable()}
                        onPress={() => {
                          setShow(true);
                        }}
                        style={[styles.box, boxStyle]}>
                      <Image style={[{ width: '100%' }]} source={CommonImages.ImagePicker} resizeMode="contain" />
                      <ImageIcon style={{ position: 'absolute' }} />
                    </TouchableOpacity>
                );
              else
                return (
                    <View style={[styles.box, (index + 1) % 3 === 0 ? styles.outermostItem : {}, Math.floor((index) / 3) > 0 ? styles.topItem : {}, styles.imageWrap]}>
                      <TouchableOpacity onPress={() => handleDelete(index)} style={styles.close}>
                        <CrossIcon />
                      </TouchableOpacity>
                      {/*<Image source={{ uri: item.uri }} resizeMode="cover" style={styles.image} />*/}
                      <AppLazyImage
                          style={styles.image}
                          // source={item.uri}
                          uri={item.uri}
                          url={FileTransform.fixUrl(item?.url)}
                          isS3={folderUpload !== UPLOAD_FILE.folderUpload.ticket}
                          s3config={props?.s3config}
                          resizeMode={'cover'}
                          onLoaded={uri => {
                            //TODO: cached here
                            // item.uri = uri
                            onLoadedUri(item, uri);
                          }}
                      />
                      {/*<FastImage*/}
                      {/*    // style={{ width: 200, height: 200 }}*/}
                      {/*    style={styles.image}*/}
                      {/*    source={item?.uri ? { uri: item.uri } : FileTransform.getImageFdbSourceWithConfig(FileTransform.fixUrl(item?.url), props?.s3config )}*/}
                      {/*    resizeMode={FastImage.resizeMode.cover}*/}
                      {/*/>*/}
                    </View>
                );
            }}
        />

    );
  };

  return (
    <View style={[styles.container, style]}>
      {!!title && (
        <AppText style={[styles.title, isBoldTitle ? { fontWeight: '700' } : {}]}>
          {isRequired && <AppText style={styles.isRequired}>* </AppText>}
          {title}
        </AppText>
      )}
      {renderListItem()}
      {/*<AppPicker/>*/}
      {!!hint && <AppText style={styles.hint}>{hint}</AppText>}
      {!!error && <AppText style={styles.error}>{error}</AppText>}
      <AppModal
        visible={isShow}
        onBackdropPress={() => {
          setShow(false);
        }}>
        <View
          style={styles.header}
          // onLayout={e => {
          //   // console.log(e.nativeEvent.layout.height);
          // }}
        >
          <AppText style={styles.headerTxt}>{t('HEADER_SELECT_IMAGE')}</AppText>
        </View>
        <ScrollView
          // ref={_ref => {
          //   if (_ref) {
          //     scrollViewRef.current = _ref;
          //   }
          // }}
          style={[{ paddingHorizontal: theme.spacing.md, marginBottom: theme.spacing.md }]}>
          <AppSingleSelection
            style={{ paddingTop: 0 }}
            // selected={selected}
            data={options}
            onSelect={item => {
              // onSelect(item);
              // handleBackdropPress();
              console.log('onSelect>>>', item);
              setShow(false);
              handlePickImage(item);
            }}
            isRadio={false}
          />
        </ScrollView>
      </AppModal>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    // backgroundColor: 'yellow'
  },
  wrapper: {
    // paddingLeft: 10,
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: -Spacing.width10,
  },
  box: {
    flex: 1,
    maxWidth: '32%',
    height: Spacing.width76,
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    // borderStyle: 'dotted',
    // borderWidth: 1,
    // marginTop: Spacing.width10,
    marginRight: Spacing.width10,
    paddingRight: 4,
  },
  boxSingle: {
    // flex: 1,
    maxWidth: '32%',
    height: Spacing.width76,
    // height: 60,

    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    // borderStyle: 'dotted',
    // borderWidth: 1,
    // marginBottom: Spacing.width10,
    marginRight: Spacing.width10,
    paddingRight: 4,
  },
  image: {
    width: '100%',
    height: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D5D5D5',
    borderRadius: 4,
  },
  shadow: {
    borderWidth: 0,
  },
  imageWrap: {
    // borderWidth: 1,
    // borderStyle: 'solid',
    // borderColor: '#D5D5D5',
    // borderRadius: 4,
    paddingRight: 4,
    // marginRight:8
    // backgroundColor: 'red'
  },
  close: {
    position: 'absolute',
    top: -12,
    right: -5,
    zIndex: 1000,
    padding: 5,
  },
  outermostItem: {
    marginRight: 0,
  },
  topItem: {
    marginTop: theme.spacing.md,
  },
  title: {
    fontWeight: '400',
    color: '#333333',
    marginBottom: 8,
  },
  isRequired: {
    color: '#FF5757',
  },
  hint: {
    fontWeight: '400',
    color: '#999999',
    marginTop: 7,
  },
  error: {
    marginTop: 7,

    color: palette.ROYAL_MAIL_RED,
    fontWeight: '400',
  },
  header: {
    backgroundColor: '#F7F7F7',
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    minHeight: 43,
  },
  headerTxt: {
    fontWeight: '700',

    color: '#000000',
    paddingHorizontal: 15,
    paddingVertical: 12,
  },
});
