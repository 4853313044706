import React from 'react';
import { View } from 'react-native';
import { Modalize } from 'react-native-modalize';

export const AppBottomSheet = props => {
  const { children, adjustToContentHeight, modalRef, modalHeight, contentStyle } = props;
  return (
    <Modalize
      adjustToContentHeight={adjustToContentHeight}
      modalHeight={modalHeight}
      ref={modalRef}
      handleStyle={{ opacity: 0.5 }}>
      <View style={contentStyle}>{children}</View>
    </Modalize>
  );
};
