import { FORM, FORM_ELEMENT_NAME } from '@constant/model/RegisterForm';
import { FileTransform } from '@utils/transform/file';
import {Equal, Matches, Max, Maxlength, Min, Minlength, OneOf, Required} from '@utils/form/yupUtil';
import { checkEvalCondition, checkEvalExpression, FormTransform, getContentTitle } from '@utils/transform/form';
import _ from 'lodash';
import moment from 'moment-timezone';
import * as Yup from 'yup';
import {StringHelper} from "@utils/common/string";
import i18next from "i18next";

export const getYupStructure = (stepData, t, keyAttributes = 'attributes', data = null) => {
  //TODO: map with attribute
  if (_.isEmpty(stepData)) {
    return {};
  }
  const attributes = stepData[keyAttributes];
  return getYupStructureOrigin(attributes, t, false, data);
};

export const getYupStructureOrigin = (attributes, t, isCustomError = false, data = null) => {
  const rs = {};
  // console.log('getYupStructureOrigin>>>', attributes, data);
  if (_.isEmpty(attributes)) {
    return rs;
  }
  for (const item of attributes) {
    const ty = item?.type;
    if (
      [
        FORM.FORM_TYPE.hidden,
        FORM.FORM_TYPE.note,
        FORM.FORM_TYPE.misdelivered_item_form,
        // FORM.FORM_ELEMENT_NAME.nearest_address,
      ].includes(ty)
    ) {
      continue;
    }

    //TODO: check condition with data
    // console.log('checkItem>>>', item.type);
    if (!_.isEmpty(data)) {
      const isShow = checkEvalCondition(item?.condition?.react, data);
      console.log('isShow>>>', item.internalId, isShow, item);
      if (!isShow) {
        continue;
      }
    }

    console.log('checkItem>>>passedCondition>>>', item.type);

    const isRequired = item?.required;
    const requiredCondition = item?.requiredCondition?.react

    const name = item?.internalId ?? item._id;
    // const msgRequired = FormTransform.getErrorMessage(item, FORM.FORM_ERROR_TYPE.required);
    const msgRequired = item?.errors?.required ?? i18next.t('ERROR_REQUIRED');

    let schema = null;
    if (
      [
        FORM.FORM_TYPE.MultipleSelection,
        FORM.FORM_TYPE.stringlistmultiple,
        FORM.FORM_TYPE.imagePicker,
        FORM.FORM_TYPE.camera,
        FORM.EXTRA_FORM_ELEMENT_NAME.list_post_position,
        FORM.FORM_TYPE.emptying_time,
      ].includes(ty)
    ) {
      schema = Yup.array().nullable();
      console.log('stringlistmultiple>>>>', ty, isRequired);
      if (isRequired && _.isEmpty(requiredCondition)) {
        schema = Min(schema, 1, msgRequired);
      }
    } else if (ty === FORM.FORM_TYPE.checkbox) {
      schema = Yup.bool().nullable();
      if (isRequired && _.isEmpty(requiredCondition))  {
        // schema = Required(schema, isCustomError ? t('ERROR_REQUIRED') : '');

        schema = OneOf(schema, [true], msgRequired);
      }
    } else if(ty === FORM.FORM_TYPE.integer  && !item?.validation?.regex){
      schema = Yup.number().nullable().typeError(item?.errors?.validation??t('INTEGER_INVALID_FORMAT'));
      if (isRequired && _.isEmpty(requiredCondition))  {
        schema = Required(schema, msgRequired);
      }
    }
    else {
      schema = Yup.string().nullable();
      if (isRequired && _.isEmpty(requiredCondition))  {
        schema = Required(schema, msgRequired);
      }
    }

    schema = Maxlength(schema, item?.maxlength);
    schema = Matches(schema, getContentTitle(item?.validation?.regex), item?.errors?.validation);
    if (name === FORM_ELEMENT_NAME.confirm_password) {
      schema = Equal(schema, 'password', t ? t('ERROR_PASSWORD_NOT_MATCH') : null);
    }

    // if(item.type === FORM.FORM_TYPE.integer && !item?.validation?.regex){
    //   schema = Matches(schema, StringHelper.getRegexNumber(), t('ERROR_TYPE'));
    // }

    if (ty === FORM.FORM_TYPE.email && _.isEmpty(item?.regex)) {
      schema = schema.email();
    }

    if(ty === FORM.FORM_TYPE.bundle_postcode && !_.isNil(item?.minSearch)){
      schema = Minlength(schema, item?.minSearch, item?.errors?.minSearch);
    }
    rs[name] = schema;
  }
  // console.log('getYupStructure>>>', rs);

  return rs;
};

export const getYupSchema = stepData => {
  const rs = getYupStructure(stepData);
  return Yup.object().shape(rs);
};

export const getInitialFormValue = (stepData, data, keyValue = 'attributes') => {
  // console.log('getInitialFormValue>>>', stepData, data, keyValue)
  if (_.isEmpty(stepData)) {
    return {};
  }
  const attributes = stepData[keyValue];
  const rs = getInitialFormValueOrigin(attributes, data);
  console.log('getInitialFormValue>>>rs>>', rs, data);
  return rs;
};

export const getInitialFormValueOrigin = (attributes, data) => {
  const rs = {};
  // console.log('getInitialFormValueOrigin>>', attributes, data);
  if (_.isEmpty(attributes)) {
    return rs;
  }

  for (const item of attributes) {
    const ty = item?.type;
    if (ty === FORM.FORM_TYPE.hidden) {
      continue;
    }

    const name = item?.internalId ?? item?._id;
    if (!_.isEmpty(data) && _.has(data, name)) {
      if (ty === FORM.FORM_TYPE.date) {
        const m = moment(data[name]);
        if (m.isValid()) {
          rs[name] = data[name];
        }
      } else if (ty === FORM.FORM_TYPE.checkbox) {
        rs[name] = !!data[name];
      } else if (ty === FORM.FORM_TYPE.integer) {
        rs[name] = !_.isNil(data[name]) ? data[name].toString() : data[name];
      }
      // else if ([FORM.FORM_TYPE.camera, FORM.FORM_TYPE.imagePicker].includes(ty)) {
      //   //TODO: fixed url here
      //   console.log('FileTransform.getListImageUrl>>>', data[name])
      //   rs[name] = FileTransform.getListImageUrl(data[name]);
      // }
      else {
        rs[name] = data[name];
        if (ty === FORM.FORM_TYPE.bundle_postcode) {
          rs[FORM.EXTRA_FORM_ELEMENT_NAME.reset_bundle_address] = !!data[FORM.EXTRA_FORM_ELEMENT_NAME.reset_bundle_address];
        }
      }
      // init for subform
      if(!_.isEmpty(item.subForms)){
        _.forEach(item.subForms, function (subItem) {
          if (subItem.internalId && _.has(data, subItem.internalId)) {
            rs[subItem.internalId] = data[subItem.internalId];
          }
        });
      }
    } else {
      if (
        [
          FORM.FORM_TYPE.MultipleSelection,
          FORM.FORM_TYPE.stringlistmultiple,
          FORM.FORM_TYPE.camera,
          FORM.FORM_TYPE.imagePicker,
        ].includes(item.type)
      ) {
        rs[name] = [];
      }
      // else if(item?.type === FORM.FORM_TYPE.misdelivered_item_form){
      //
      // }
      else if ([FORM.FORM_TYPE.checkbox, FORM.FORM_TYPE.toggleSwitch].includes(item.type)) {
        rs[name] = false;
      } else {
        rs[name] = '';
      }
    }
  }
  console.log('getInitialFormValueOrigin>>xxx>>', rs);

  return rs;
};

export const getErrorForm = (errors, touched, fieldName) => {
  // console.log('getErrorForm>>>000', errors, fieldName, _.isEmpty(fieldName));
  if (_.isEmpty(fieldName)) {
    return '';
  }
  // console.log('getErrorForm>>>111', _.get(errors, fieldName, ''), _.get(touched, fieldName, ''));
  return _.get(errors, fieldName, '') && _.get(touched, fieldName, '') ? _.get(errors, fieldName, '') : '';
};

export const getErrorFormWithoutTouch = (errors, fieldName) => {
  // console.log('getErrorForm>>>000', errors, fieldName, _.isEmpty(fieldName));
  if (_.isEmpty(fieldName)) {
    return '';
  }
  // console.log('getErrorForm>>>111', _.get(errors, fieldName, ''), _.get(touched, fieldName, ''));
  return _.get(errors, fieldName, '') ? _.get(errors, fieldName, '') : '';
};

export const getPayloadStep = (values, data) => {
  const cloneData = _.cloneDeep(data);
  if (_.isEmpty(data)) {
    return {};
  }
  if (_.isEmpty(values)) {
    return values;
  }
  const rs = _.cloneDeep(values);
  for (const valuesKey in values) {
    if (valuesKey in cloneData) {
      rs[valuesKey] = values[valuesKey];
    }
  }

  return rs;
};

export const checkRadioId = id => {
  console.log('checkRadioId>>>', id, typeof id);
  if (typeof id === 'boolean') {
    return id !== undefined;
  }

  if (typeof id === 'number') {
    return id !== undefined;
  }
  return !!id;
};

export const checkFormValidateExpression = (formStructure, values, setErrors) => {
  const keys = Object.keys(values);
  // console.log('checkFormValidateExpression>>>', keys, _.isEmpty(formStructure));

  if (_.isEmpty(formStructure)) {
    return {};
  }
  const error = {};

  for (const item of formStructure) {
    const internalId = item?.internalId;

    if (keys.includes(internalId)) {
      // const xxx = {};
      // xxx[item.internalId] = values[internalId];
      const xxx = _.cloneDeep(values);

      // console.log('checkFormValidateExpression>>>internalId>>>', internalId, xxx);
      const isCondition = checkEvalCondition(item?.condition?.react, values);
      if (!isCondition) {
        continue;
      }

      const requiredCondition = item?.requiredCondition?.react
      if(!_.isEmpty(requiredCondition)){
        const isRequired = checkEvalCondition(requiredCondition, values);
        if(isRequired){
          error[item.internalId] = item?.errors?.required;
          console.log('isRequired>>>', item.internalId, isRequired)
          break
        }
        else{
          // error[item.internalId] = item?.errors?.required;
          // delete error[item.internalId]
          error[item.internalId] = null
        }
      }

      const validations = item?.validations
      let isExpression = true
      if(!_.isEmpty(validations)){
        //TODO: check validate
        console.log('checkFormValidateExpression>>>validations>>', validations, item)
        for (const validation of validations) {
          isExpression = checkEvalExpression(validation?.javascript, xxx);
          if(!isExpression){
            error[item.internalId] = validation?.errMsg ?? validation?.errorMsg  ?? i18next.t('FORM_INVALID_FIELD');
            break
          }
        }
      }
      else{
        isExpression = checkEvalExpression(item?.validation?.javascript, xxx);
        if (!isExpression) {
          error[item.internalId] = FormTransform.getErrorMessage(item, FORM.FORM_ERROR_TYPE.validation);
        }
      }

      // console.log('isExpression>>>internalId>>>', internalId, isExpression, xxx);

    }
  }

  if (!_.isEmpty(error)) {
    if (setErrors) {
      console.log('setErrors>>>', error)
      setErrors(error);
    }

    for(const k in error){
      if(_.isNil(error[k])){
        delete error[k]
      }
    }
    return error;
  }
  return {};
};
