import {createAction, createSlice} from '@reduxjs/toolkit';

export interface IsubmitPackageState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IsubmitPackageState = {
  loading: false,
  payload: null,
  error: null,
};

export const submitPackageRequest = createAction('submitPackage/submitPackageRequest', (payload, onSuccess, onFailed) => {
  console.log('prepareAction>>>', payload, onSuccess);
  return {
    payload: payload,
    meta: onSuccess,
    error: onFailed,
  };
});

const submitPackageSlice = createSlice({
  name: 'submitPackage',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // submitPackageRequest(state, action) {
    //   state.loading = true;
    //   // @ts-ignore
    //   state.payload = null;
    //   state.error = null;
    // },
    submitPackageSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    submitPackageFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { submitPackageSuccess, submitPackageFailed } = submitPackageSlice.actions;

export default submitPackageSlice.reducer;
