import { createTicketSaga } from '@redux/ticket/createTicketSaga';
import { createTicketRequest } from '@redux/ticket/createTicketSlice';
import { getListTicketSaga } from '@redux/ticket/getListTicketSaga';
import { getListTicketRequest } from '@redux/ticket/getListTicketSlice';
import { getTicketDetailSaga } from '@redux/ticket/getTicketDetailSaga';
import { getTicketDetailRequest } from '@redux/ticket/getTicketDetailSlice';
import { getListStatusSaga } from '@redux/ticket/getListStatusSaga';
import { getListStatusRequest } from '@redux/ticket/getListStatusSlice';
import { getListSubjectSaga } from '@redux/ticket/getListSubjectSaga';
import { getListSubjectRequest } from '@redux/ticket/getListSubjectSlice';
import { addCommentSaga } from '@redux/ticket/addCommentSaga';
import { addCommentRequest } from '@redux/ticket/addCommentSlice';
import { ForkEffect, takeLatest } from 'redux-saga/effects';

function* ticketSagas(): Generator<ForkEffect<never>, void> {
  yield takeLatest(createTicketRequest.type, createTicketSaga);
  yield takeLatest(getListTicketRequest.type, getListTicketSaga);
  yield takeLatest(getTicketDetailRequest.type, getTicketDetailSaga);
  yield takeLatest(getListStatusRequest.type, getListStatusSaga);
  yield takeLatest(getListSubjectRequest.type, getListSubjectSaga);
  yield takeLatest(addCommentRequest.type, addCommentSaga);
}

export default ticketSagas;
