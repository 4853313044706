import api from '@services/api-client';
import { stringify } from 'qs';
import withQuery from 'with-query';

export const getOverviewCDSService = async payload => {
  console.log('getOverviewCDSService>>>', payload)
  const response = await api.get(`/surveys/overview/cds.json?${stringify(payload)}`)
  console.log('getOverviewCDSService response>>>', response)

  return response?.data
}

export const getOverviewD2DService = async payload => {
  console.log('getOverviewD2DService>>>', payload)
  const response = await api.get(`/surveys/overview/d2d.json?${stringify(payload)}`)
  console.log('getOverviewD2DService response>>>', response)

  return response?.data
}

export const getD2DItemsService = async payload => {
  console.log('getD2DItemsService>>>', payload)
  // const response = await api.get(`/surveys/d2dItems/overdue_items.json?${stringify(payload)}`)
  const response = await api.get(`/surveys/d2dItems/${payload.key}.json`);

  console.log('getD2DItemsService response>>>', response)

  return response?.data
}

export const saveFeedbackCDSService = async payload => {
  console.log('saveFeedbackCDSService>>>', payload)
  const response = await api.post(`/surveys/cds/saveFeedback.json?${stringify(payload)}`, payload)
  console.log('saveFeedbackCDSService response>>>', response)
  return response?.data
}

export const saveFeedbackD2DService = async payload => {
  console.log('saveFeedbackD2DService>>>', payload)
  const response = await api.post(`/surveys/d2d/saveFeedback.json`, payload)
  console.log('saveFeedbackD2DService response>>>', response)
  return response?.data
}

export const saveFeedbackD2DForDailyService = async payload => {
  console.log('saveFeedbackD2DForDailyService>>>', payload)
  const response = await api.post(`/surveys/saveDailyItems.json`, payload)
  console.log('saveFeedbackD2DForDailyService response>>>', response)
  return response?.data
}

export const getCDSDetailService = async payload => {
  console.log('getCDSDetailService>>>', payload)
  const response = await api.get(`/surveys/feedbacks/${payload?.id}.json?${stringify(payload)}`)
  console.log('getCDSDetailService response>>>', response)

  return response?.data
}

export const getListUnsureDateService = async payload => {
  console.log('getListUnsureDateService>>>', payload)
  const response = await api.get(`/surveys/cds/listUnsureDates.json?${stringify(payload)}`)
  console.log('getListUnsureDateService response>>>', response)

  return response?.data
}

