import { AppSafeAreaView, AppKAView, AppEmptyView } from '@components';
import GenericHeader from '@components/GenericHeader';
import { palette } from '@theme/colors';
import { withLoading } from '@utils';
import React from 'react';
import { StatusBar, ScrollView, RefreshControl, View } from 'react-native';
import { MenuHeader, MenuList } from './Components';
import { useLogic } from './Menu.hook';
import { styles } from './Menu.style';
import theme from "@theme";

const Content = ({ menuList }) => {
  return (
    <>
      {menuList?.map((menu, index) => {
        if (!menu?.show) return;
        return <MenuList key={index} data={menu} isLast={index === menuList.length - 1} isFirst={index === 0} />;
      })}
    </>
  );
};
const ContentWithLoading = withLoading(Content);

const Menu = () => {
  const { menuList, handleRefresh, refresh, fakeLoading, loading } = useLogic();
  return (
    <>
      <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
      <AppSafeAreaView areaStyle={styles.areaStyle}>
        {/*<GenericHeader LeftAction={<MenuHeader />} style={[{ backgroundColor: '#EDEDED' }]} />*/}
          <MenuHeader style={[{paddingHorizontal: theme.spacing.md, paddingVertical: theme.spacing.sm}]} />
          <AppKAView>
          <View style={styles.fakePadding}>
            <ScrollView
              style={styles.container}
              contentContainerStyle={menuList?.length === 0 ? { flexGrow: 1 } : {}}
              refreshControl={
                <RefreshControl
                  refreshing={refresh}
                  onRefresh={handleRefresh}
                  tintColor={palette.LOADING_COLOR}
                  colors={[palette.LOADING_COLOR]}
                />
              }>
              {!loading && !fakeLoading && menuList?.length === 0 ? (
                <AppEmptyView />
              ) : (
                <ContentWithLoading menuList={menuList} loading={loading || fakeLoading} />
              )}
            </ScrollView>
          </View>
        </AppKAView>
      </AppSafeAreaView>
    </>
  );
};

export { Menu };
