import { navigate } from '@routes/navigationUtils';
import { LOGIN_SCREEN } from '@routes/route';
import { useEffect } from 'react';
import {useRoute} from "@react-navigation/native";

export const useLogic = () => {
  const messageInfo = useRoute().params?.data

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     navigate(LOGIN_SCREEN);
  //   }, 5000);
  //
  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, []);
  const onClose = () => {
    navigate(LOGIN_SCREEN)
  }

  return { messageInfo, onClose};
};
