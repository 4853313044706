import {Platform, StyleSheet} from 'react-native';
import {FontSize, Spacing} from "@theme/size";
import theme, {globalStyle} from "@theme";

export const AppSuccessScreenStyle = StyleSheet.create({
  iconView:{
    width: Platform.OS === 'web' ? Spacing.width108 : Spacing.width64,
    height: Platform.OS === 'web' ? Spacing.width108 : Spacing.width64,
    // width: Spacing.width64,
    // height: Spacing.width64,
  },
  descriptionText: {
    fontSize: FontSize.Font15,
    textAlign: 'center',
    color: '#666666',
    marginTop: theme.spacing.md,
    // opacity: 0.5,
  },
  alertText: {
    fontSize: FontSize.Font15,
    textAlign: 'center',
    color: '#666666',
    marginTop: Spacing.height64,
    // opacity: 0.5,
  },
  viewContent: {
    ...globalStyle.alignCenter,
    ...globalStyle.justifyCenter,
    ...globalStyle.alignSelfCenter,
    ...globalStyle.directionColumm,
    marginHorizontal: theme.spacing.md,
  }
});
