import { AppReviewForm } from '@components/AppReviewForm';
import { UPLOAD_FILE } from '@constant';
import { useReportICodeReview } from '@screens/main/report/iCode/review/hook';
import React, { useEffect, useRef } from 'react';
import {goBack} from "@routes/navigationUtils";

export const ReportICodeReviewScreen = props => {
  const { data, values, title, banner, onSubmit } = useReportICodeReview(props);
  return (
    <AppReviewForm
      data={data}
      values={values}
      title={title}
      banner={banner}
      folderUpload={UPLOAD_FILE.folderUpload.bundle}
      onSubmitPress={onSubmit}
      // onBackClicked={() => {
      //   goBack()
      // }}
    />
  );
  // return <View />;
};
