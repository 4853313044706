import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppSafeAreaView, AppText } from '@components';
import { AppSuccessScreen } from '@components/AppSuccessScreen';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font } from '@theme/size';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text } from 'react-native';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { useActiveSuccessful } from './ActiveSuccessful.hook';
import { ActiveSuccessfulStyles } from './ActiveSuccessful.style';

export const ActiveSuccessfulScreen = props => {
  const { messageInfo } = useActiveSuccessful(props);
  const { t } = useTranslation();
  return (
    <AppSuccessScreen alert={messageInfo?.alert} icon={messageInfo?.icon} description={messageInfo?.description} />
  );
  // return (
  //   <AppSafeAreaView areaStyle={[globalStyle.flex1, globalStyle.alignCenter, globalStyle.justifyCenter]}>
  //     {/*<Icon name="comment-dots" size={40} color={palette.ROYAL_MAIL_RED} />*/}
  //     <MessageCheckIcon color="#8BB73B" />
  //     <View
  //       style={[
  //         globalStyle.alignCenter,
  //         globalStyle.justifyCenter,
  //         globalStyle.alignSelfCenter,
  //         globalStyle.directionColumm,
  //         { paddingHorizontal: 40 },
  //       ]}>
  //       <AppText style={[Font.Bold_700, { textAlign: 'center', marginVertical: 14 }]}>
  //         {t('ACTIVE_ACCOUNT_SUCCESSFUL_TITLE')}
  //       </AppText>
  //       <AppText style={[{ textAlign: 'center', marginHorizontal: theme.spacing.sm, color: '#666666' }]}>
  //         {t('ACTIVE_ACCOUNT_SUCCESSFUL_DESCRIPTION')}
  //       </AppText>
  //     </View>
  //   </AppSafeAreaView>
  // );
};
