export const DashboardMockData = [
  {
    type: 'point',
    point: 12232,
  },
  {
    type: 'list',
    name: {
      en: 'Test your knowledge',
    },
    icon: '',
    data: [
      {
        id: 'ea01286c-b543-466c-b13e-612776dcda48',
        name: {
          en: 'Sender and Receiver',
        },
        skill_key: 'skillttm',
        passed: true,
      },
      {
        id: '8335ccb2-e89c-4d7a-99ee-9fbebbcf0f28',
        name: {
          en: 'Correctly Delivered Mail',
        },
        skill_key: 'skillcds',
        passed: true,
      },
      {
        id: 'c4679e12-59d8-44ad-a031-42cd15867119',
        name: {
          en: 'Unaddressed Mail (Door-to-Door)',
        },
        skill_key: 'skilld2d',
        passed: true,
      },
    ],
  },
  {
    type: 'item',
    icon: '',
    name: {
      en: 'Number of planned items',
    },
    data: '324',
  },
  {
    type: 'item',
    icon: '',
    name: {
      en: 'Number of open tickets',
    },
    data: '54',
  },
  {
    type: 'item',
    icon: '',
    name: {
      en: 'Number of open surveys',
    },
    data: '54',
  },
  {
    type: 'item',
    icon: '',
    name: {
      en: 'Next participation period',
    },
    data: '12/08/2022',
  },
];
