import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

export interface IgetListQuizState {
  loading: boolean;
  payload: any;
  error: any;
  answers: any;
}

// @ts-ignore
const initialState: IgetListQuizState = {
  loading: false,
  payload: null,
  error: null,
  answers: {},
};

const getListQuizSlice = createSlice({
  name: 'getListQuiz',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getListQuizRequest(state, action) {
      state.loading = true;
      // @ts-ignore
      state.payload = null;
      state.error = null;
    },
    getListQuizSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    getListQuizFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    saveAnswerQuiz(state, action) {
      const payload = action.payload;
      const answers = _.cloneDeep(state.answers);
      const keys = Object.keys(payload);
      if (!_.isEmpty(keys)) {
        const key = keys[0];
        // answers.update()
        // eslint-disable-next-line no-prototype-builtins
        if (answers.hasOwnProperty(key)) {
          const oldValue = answers[key];
          answers[key] = {
            ...oldValue,
            ...payload[key],
          };
        } else {
          answers[key] = { ...payload[key] };
        }
        state.answers = answers;
      }
    },
    resetAnswerQuiz(state, action) {
      state.answers = {}
    },
  },
});

export const { getListQuizRequest, getListQuizSuccess, getListQuizFailed, saveAnswerQuiz, resetAnswerQuiz } = getListQuizSlice.actions;

export default getListQuizSlice.reducer;
