import { Constant, QR_SCANNER_TYPE, REPORT_TYPE } from '@constant';
import { Config } from '@constant/config';
import { useRoute } from '@react-navigation/native';
import { getValidateReportRequest } from '@redux/app/appSlice';
import { reportModal as reportModalSelector } from '@redux/app/selectors';
import { navigate } from '@routes/navigationUtils';
import { SCAN_QR_SCREEN, Screens } from '@routes/route';
import { ReportBundleFormScreen } from '@screens/main/report/bundle/form';
import { ReportModalTransform } from '@utils';
import { requestPermission } from '@utils/common/deviceUtil';
import { useLocation } from '@utils/hooks';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

export const useLogic = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const location = useLocation();
  // console.log(location);

  const [value, setValue] = useState('');
  const params = useRoute().params;
  console.log('useLogic>>>', params);

  const handleGetValueFromParam = useCallback(
    (value1, value2, value3) => {
      switch (params.type) {
        case Constant.menu.report_test_items:
          return value1;

        case Constant.menu.posting_package:
          return value2;

        case Constant.menu.report_bundle_dispatch:
          return value3;

        default:
          return null;
      }
    },
    [params],
  );

  const formModal = useSelector(reportModalSelector);
  const reportType = ReportModalTransform.getReportType(params.type);
  const headerTitle = ReportModalTransform.getReportTitle(reportType, formModal);
  const desc = handleGetValueFromParam(t('DIGIT_DESC_ICODE'), t('DIGIT_DESC_PACKAGE'), t('DIGIT_DESC_BUNDLE'));
  const qrText = handleGetValueFromParam(t('OR_WITH_QR_SCAN_ICODE'), t('OR_WITH_QR_SCAN'), t('OR_WITH_QR_SCAN_BUNDLE'));
  // const headerTitle = useMemo(
  //   () => handleGetValueFromParam(t('REPORT_TEST_ITEMS'), t('POSTING_PACKAGE'), t('REPORT_BUNDLE_DISPATCH')),
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [handleGetValueFromParam],
  // );

  const cellCount = useRef(params?.cellCount || 10).current;

  const handleProceed = () => {
    const reportType = handleGetValueFromParam(REPORT_TYPE.icodes, REPORT_TYPE.packages, REPORT_TYPE.bundles);
    if (Config.IsDebug) {
      navigate(Screens.REPORT_BUNDLE_FORM_SCREEN, {
        data: { id: value.toUpperCase(), payload: {}, reportType: reportType },
      });
      return;
    }

    dispatch(
      getValidateReportRequest(
        {
          type: reportType,
          id: value.toUpperCase(),
        },
        rs => {
          console.log('getValidateReport>>>response', rs, reportType);
          // navigate(Screens.)
          setValue('');
          switch (reportType) {
            case REPORT_TYPE.icodes:
              navigate(Screens.REPORT_ICODE_FORM_SCREEN, {
                data: { id: value.toUpperCase(), payload: rs, reportType: reportType },
              });
              break;
            case REPORT_TYPE.bundles:
              navigate(Screens.REPORT_BUNDLE_FORM_SCREEN, {
                data: { id: value.toUpperCase(), payload: rs, reportType: reportType },
              });

              break;
            case REPORT_TYPE.packages:
              navigate(Screens.REPORT_PACKAGE_FORM_SCREEN, {
                data: { id: value.toUpperCase(), payload: rs, reportType: reportType },
              });

              break;
          }
        },
        err => {},
      ),
    );
  };

  const handleQRPress = async () => {
    console.log(123);
    const qrScannerType = handleGetValueFromParam(
      QR_SCANNER_TYPE.REPORT_TEST_ITEMS,
      QR_SCANNER_TYPE.POSTING_PACKAGE,
      QR_SCANNER_TYPE.REPORT_BUNDLE_DISPATCH,
    );
    await requestPermission(() => {
      navigate(SCAN_QR_SCREEN, { type: qrScannerType });
    });
  };

  // const [menus, setMenus] = useState([]);

  return { value, setValue, handleProceed, cellCount, handleQRPress, headerTitle, desc, qrText };
};
