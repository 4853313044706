import { createSlice } from '@reduxjs/toolkit';

export interface IresetPasswordState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IresetPasswordState = {
  loading: false,
  payload: null,
  error: null,
};

const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resetPasswordRequest(state, action) {
      state.loading = true;
      // @ts-ignore
      state.payload = null;
      state.error = null;
    },
    resetPasswordSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    resetPasswordFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { resetPasswordRequest, resetPasswordSuccess, resetPasswordFailed } = resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;
