import { ArrowRightIcon, FilterIcon, LogoIcon, MessageCheckIcon } from '@assets';
import { AppEmptyView, AppKAView, AppSafeAreaView, AppText } from '@components';
import GenericHeader from '@components/GenericHeader';
import { goBack } from '@routes/navigationUtils';
import { SearchAddressStyle } from '@screens/main/SearchAddress/SearchAddress.style';
import { AddressItem, AppLoadingMore } from '@screens/main/SearchPostcode';
import { ListQuizStyle } from '@screens/main/quiz/ListQuiz/ListQuiz.style';
import { TicketFilterView } from '@screens/main/ticket/ListTicket/components/TicketFilter.view';
import { TicketItemStyles } from '@screens/main/ticket/ListTicket/components/TicketItem.style';
import { TicketItemView } from '@screens/main/ticket/ListTicket/components/TicketItem.view';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font, FontSize } from '@theme/size';
import { getContentTitle } from '@utils/transform/form';
import { getText } from '@utils/transform/ticket';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Text, FlatList, TouchableOpacity } from 'react-native';
import { Modalize } from 'react-native-modalize';
import { Host, Portal } from 'react-native-portalize';
import { useListTicket } from './ListTicket.hook';
import { ListTicketStyle } from './ListTicket.style';

export const ListTicketScreen = props => {
  const { t } = useTranslation();
  const {
    data,
    allData,
    onRefresh,
    isRefreshing,
    loadingMore,
    loading,
    onLoadMore,
    onItemPress,
    getListStatusData,
    modalizeRef,
    isShowModal,
    onItemStatusPress,
    selectedStatus,
    onFilterPress,
    listStatus,
  } = useListTicket(props);
  const renderFooterLoading = () => {
    return <AppLoadingMore loadingMore={loadingMore} />;
  };

  const renderEmpty = () => {
    return !loading ? <AppEmptyView /> : <></>;
  };
  const renderItem = ({ item, index }) => {
    return (
      <TicketItemView
        data={item}
        isLast={index === allData.length - 1}
        isSelected={false}
        colors={getListStatusData?.colors}
        dictStatus={getListStatusData?.value}
        onPress={() => onItemPress(item)}
      />
    );
  };
  return (
    <View style={[globalStyle.directionColumm, globalStyle.flex1, { backgroundColor: palette.WHITE }]}>
      <TicketFilterView
        selectedStatus={selectedStatus}
        onFilterPress={onFilterPress}
        modalizeRef={modalizeRef}
        listStatus={listStatus}
        onItemStatusPress={onItemStatusPress}
      />
      <FlatList
        style={[ListTicketStyle.listView]}
        showsVerticalScrollIndicator={false}
        data={allData}
        renderItem={renderItem}
        onRefresh={() => onRefresh()}
        refreshing={isRefreshing}
        contentContainerStyle={_.isEmpty(allData) ? { flexGrow: 1 } : {}}
        ListEmptyComponent={renderEmpty}
        onEndReached={!loading ? onLoadMore : null}
        onEndReachedThreshold={0.5}
        keyExtractor={(item, index) => index}
        ListFooterComponent={renderFooterLoading}
      />
    </View>
  );
};
