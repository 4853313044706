import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppButton, AppKAView, AppSafeAreaView, AppText } from '@components';
import { AppForm } from '@components/AppForm';
import { FormItemView } from '@components/Form';
import GenericHeader from '@components/GenericHeader';
import { goBack } from '@routes/navigationUtils';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font, Spacing } from '@theme/size';
import { getContentTitle } from '@utils/transform/form';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, ScrollView } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useProfileForm } from './ProfileForm.hook';
import { ProfileFormStyle } from './ProfileForm.style';

export const ProfileFormScreen = props => {
  const { t } = useTranslation();
  const { data, formik, scrollToY, scrollViewRef, layoutInfo, setLayoutInfo } = useProfileForm(props);
  return (
    <AppForm
      title={data?.title}
      description={data?.description}
      scrollViewRef={scrollViewRef}
      scrollToY={scrollToY}
      formik={formik}
      attributes={data?.attributes}
      setLayoutInfo={setLayoutInfo}
      layoutInfo={layoutInfo}
      buttonText={t('SAVE')}
    />
  );
};
