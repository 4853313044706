import { useRoute } from '@react-navigation/native';
import { getTimelineRequest, resetTimeline } from '@redux/app/appSlice';
import { timelineSelector } from '@redux/app/selectors';
import { mappingTimeline } from '@utils';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {BundleTimetableTest, PackageTimetableTest} from "@utils/test/timetable";

export const usePackageTimeline = navigation => {
  const dispatch = useDispatch();
  const timelineData = useSelector(timelineSelector);
  // const timelineData = PackageTimetableTest;

  const { type } = useRoute()?.params;

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (!refresh) return;
    dispatch(getTimelineRequest({ type }));
    setRefresh(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    dispatch(getTimelineRequest({ type }));

    return () => dispatch(resetTimeline());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const handleRefresh = () => {
    setRefresh(true);
  };

  return { data: mappingTimeline(timelineData), handleRefresh, refresh, type };
};
