export const ReportModalMockData = {
  form: {
    data: [
      {
        id: 1,
        title: {
          en: 'Report receipts and postings',
        },
        data: [
          {
            id: 1,
            internalId: 'posting_package',
            name: {
              en: 'Test Parcel Receipt',
            },
            type: 'coming-soon',
          },
          {
            id: 2,
            internalId: 'report_bundle_dispatch',
            name: {
              en: 'Test Bundle Posting',
            },
            type: 'coming-soon',
          },
          {
            id: 3,
            internalId: 'report_test_items',
            name: {
              en: 'Test Letter Receipt',
            },
            type: 'coming-soon',
          },
        ],
      },
      {
        id: 2,
        title: {
          en: 'Others',
        },
        data: [
          {
            id: 1,
            name: {
              en: 'Create Enquiry',
            },
            internalId: 'create_enquiry',
          },
          {
            id: 2,
            internalId: 'report_time_off',
            name: {
              en: 'Report Your Time-Off',
            },
          },
        ],
      },
    ],
  },
};

export const IcodeFormMockData = {
  status: true,
  message: 'Test item report successfully fetched',
  data: [
    {
      title: 'Report Test Items',
      description: '',
      canReview: true,
      attributes: [
        {
          name: 'Please enter date of receipt',
          type: 'date',
          icon: 'calendar',
          internalId: 'receipt_date',
          minDate: {
            javascript: "return moment('26/09/2022, 00:00', 'DD-MM-YYYY HH:mm:ss');",
          },
          maxDate: {
            javascript: 'return moment();',
          },
          errorMessage: 'This field is required',
          errors: {
            required: 'This field is required',
          },
          required: true,
          editable: true,
          requiredCondition: {
            // react: 'return !{{receipt_date}} && {{receipt_date_unsure}} == false;',
            react: 'return {{receipt_date_unsure}} == false && !{{receipt_date}};',

          },
          placeHolder: 'Select date',
        },
        {
          name: '',
          description: '',
          type: 'note',
          styleCSS: {
            marginTop: -17,
          },
          required: false,
          condition: {
            react: 'return false;',
          },
        },
        {
          name: '',
          description:
            '<span style="color: #da202a;">This is not a working day. Normally deliveries are not sent on Sundays or bank holidays.</span>',
          type: 'note',
          styleCSS: {
            marginTop: -17,
          },
          required: false,
          condition: {
            react: 'return [6,0].indexOf(moment({{receipt_date}}).weekday()) != -1;',
          },
        },
        {
          name: '',
          description: '',
          type: 'note',
          styleCSS: {
            marginTop: -17,
          },
          required: false,
          condition: {
            react: 'return false;',
          },
        },
        {
          internalId: 'receipt_date_unsure',
          required: false,
          type: 'checkbox',
          name: '',
          content: 'I am not sure about the date of receipt',
        },
        {
          name: 'Please enter time of receipt',
          condition: {
            react: 'return {{receipt_date}} && (!{{receipt_date_unsure}} || {{receipt_date_unsure}} === false)',
          },
          type: 'stringlistsingle',
          internalId: 'receipt_time',
          errorMessage: 'This field is required',
          errors: {
            required: 'This field is required',
            validation: 'Date in the future is not allowed.',
          },
          validation: {
            type: 'javascript',
            javascript:
              "return !{{receipt_time}} || ({{receipt_time}} && moment().diff(moment({{receipt_date}} + ' ' + {{receipt_time}})) >= 0);",
          },
          required: true,
          requiredCondition: {
            react: 'return !{{receipt_time}} && {{receipt_time_unsure}} == false;',
          },
          editable: true,
          options: [
            {
              internalId: '07:59',
              name: 'Before 8:00 AM',
            },
            {
              internalId: '08:00',
              name: 'Between 8:00 and 8:29 AM',
            },
            {
              internalId: '08:30',
              name: 'Between 8:30 and 8:59 AM',
            },
            {
              internalId: '09:00',
              name: 'Between 9:00 and 9:29 AM',
            },
            {
              internalId: '09:30',
              name: 'Between 9:30 and 9:59 AM',
            },
            {
              internalId: '10:00',
              name: 'Between 10:00 and 10:29 AM',
            },
            {
              internalId: '10:30',
              name: 'Between 10:30 and 10:59 AM',
            },
            {
              internalId: '11:00',
              name: 'Between 11:00 and 11:29 AM',
            },
            {
              internalId: '11:30',
              name: 'Between 11:30 AM and 11:59 AM',
            },
            {
              internalId: '12:00',
              name: 'Between 12:00 and 12:29 PM',
            },
            {
              internalId: '12:30',
              name: 'Between 12:30 and 12:59 PM',
            },
            {
              internalId: '13:00',
              name: 'Between 01:00 and 01:29 PM',
            },
            {
              internalId: '13:30',
              name: 'Between 01:30 and 01:59 PM',
            },
            {
              internalId: '14:00',
              name: 'Between 02:00 and 02:29 PM',
            },
            {
              internalId: '14:30',
              name: 'Between 02:30 and 02:59 PM',
            },
            {
              internalId: '15:00',
              name: 'Between 03:00 and 03:29 PM',
            },
            {
              internalId: '15:30',
              name: 'Between 03:30 and 03:59 PM',
            },
            {
              internalId: '16:00',
              name: 'Between 04:00 and 04:29 PM',
            },
            {
              internalId: '16:30',
              name: 'Between 04:30 and 04:59 PM',
            },
            {
              internalId: '17:00',
              name: 'Between 05:00 and 05:29 PM',
            },
            {
              internalId: '17:30',
              name: 'Between 05:30 and 05:59 PM',
            },
            {
              internalId: '18:00',
              name: 'Between 06:00 and 06:29 PM',
            },
            {
              internalId: '18:30',
              name: 'Between 06:30 and 06:59 PM',
            },
            {
              internalId: '19:00',
              name: 'Between 07:00 and 07:29 PM',
            },
            {
              internalId: '19:30',
              name: 'Between 07:30 and 07:59 PM',
            },
            {
              internalId: '20:00',
              name: '08:00 PM or later',
            },
          ],
        },
        {
          internalId: 'receipt_time_unsure',
          required: false,
          type: 'checkbox',
          name: '',
          content: 'I am not sure about the time of receipt',
          condition: {
            react: 'return {{receipt_date}} && (!{{receipt_date_unsure}} || {{receipt_date_unsure}} === false)',
          },
        },
        {
          name: 'How did you receive the test item?',
          condition: {
            react: 'return false;',
          },
          type: 'stringlistsingle',
          internalId: 'receipt_type',
          options: [
            {
              name: 'Received directly from driver',
              internalId: '1',
            },
            {
              name: 'Received from a neighbour/Neighbouring business',
              internalId: '2',
            },
            {
              name: 'Collected from delivery office, sorting office or local post office',
              internalId: '3',
            },
            {
              name: 'Left outside/Put in shared entrance',
              internalId: '4',
            },
            {
              name: 'Left in Safeplace (attempted delivery card left)',
              internalId: '5',
            },
            {
              name: 'Arranged a re-delivery',
              internalId: '6',
            },
          ],
          errorMessage: 'This field is required',
          errors: {
            required: 'This field is required',
          },
          required: true,
          editable: true,
        },
        {
          name: 'Please select the postage amount of the item, as shown in the example below',
          description: '',
          type: 'stringlistsingle',
          internalId: 'franking_value',
          errorMessage: 'This field is required',
          errors: {
            required: 'This field is required',
          },
          required: true,
          editable: true,
          condition: {
            react: 'return {{receipt_time}};',
          },
          options: [
            {
              internalId: '0.59',
              name: '£ 0.59',
              scanQR: {
                react:
                  "return {{scan_franking_value}}.substr(4,1) == 'B' && {{scan_franking_value}}.substr(28,6) == '000590';",
              },
            },
            {
              internalId: '0.69',
              name: '£ 0.69',
              scanQR: {
                react:
                  "return {{scan_franking_value}}.substr(4,1) == 'B' && {{scan_franking_value}}.substr(28,6) == '000690';",
              },
            },
            {
              internalId: '0.90',
              name: '£ 0.90',
              scanQR: {
                react:
                  "return {{scan_franking_value}}.substr(4,1) == 'B' && {{scan_franking_value}}.substr(28,6) == '000900';",
              },
            },
            {
              internalId: '1.02',
              name: '£ 1.02',
              scanQR: {
                react:
                  "return {{scan_franking_value}}.substr(4,1) == 'B' && {{scan_franking_value}}.substr(28,6) == '001020';",
              },
            },
            {
              internalId: '1.06',
              name: '£ 1.06',
              scanQR: {
                react:
                  "return {{scan_franking_value}}.substr(4,1) == 'B' && {{scan_franking_value}}.substr(28,6) == '001060';",
              },
            },
            {
              internalId: '1.13',
              name: '£ 1.13',
              scanQR: {
                react:
                  "return {{scan_franking_value}}.substr(4,1) == 'B' && {{scan_franking_value}}.substr(28,6) == '001130';",
              },
            },
            {
              internalId: '1.37',
              name: '£ 1.37',
              scanQR: {
                react:
                  "return {{scan_franking_value}}.substr(4,1) == 'B' && {{scan_franking_value}}.substr(28,6) == '001370';",
              },
            },
            {
              internalId: '1.52',
              name: '£ 1.52',
              scanQR: {
                react:
                  "return {{scan_franking_value}}.substr(4,1) == 'B' && {{scan_franking_value}}.substr(28,6) == '001520';",
              },
            },
            {
              internalId: '3.69',
              name: '£ 3.69',
              scanQR: {
                react:
                  "return {{scan_franking_value}}.substr(4,1) == 'B' && {{scan_franking_value}}.substr(28,6) == '003690';",
              },
            },
            {
              internalId: '4.49',
              name: '£ 4.49',
              scanQR: {
                react:
                  "return {{scan_franking_value}}.substr(4,1) == 'B' && {{scan_franking_value}}.substr(28,6) == '004490';",
              },
            },
            {
              internalId: '5.29',
              name: '£ 5.29',
              scanQR: {
                react:
                  "return {{scan_franking_value}}.substr(4,1) == 'B' && {{scan_franking_value}}.substr(28,6) == '005290';",
              },
            },
            {
              internalId: 'cant_read',
              name: 'Anything else (e.g. other postage…)',
            },
          ],
          scanId: 'scan_franking_value',
        },
        {
          name: 'Please tell us the class of the item',
          description: '',
          type: 'stringlistsingle',
          internalId: 'postage_value',
          errorMessage: 'This field is required',
          errors: {
            required: 'This field is required',
          },
          required: true,
          editable: true,
          condition: {
            react: 'return false;',
          },
          options: [
            {
              internalId: '1st',
              name: '1st Class/Royal Mail 24',
            },
            {
              internalId: '2nd',
              name: '2nd Class/Royal Mail 48',
            },
          ],
        },
        {
          name: 'What is the format of the item?',
          description: '',
          type: 'stringlistsingle',
          internalId: 'size_of_item',
          errors: {
            required: 'This field is required',
          },
          required: true,
          editable: true,
          condition: {
            react: 'return false;',
          },
          options: [
            {
              internalId: 'LE',
              name: 'Letters',
            },
            {
              internalId: 'LL',
              name: 'Large Letter',
            },
            {
              internalId: 'Jiffy',
              name: 'Jiffy bag',
            },
            {
              internalId: 'DDV',
              name: 'DVD box',
            },
            {
              internalId: 'Small',
              name: 'Small parcel',
            },
            {
              internalId: 'Medium',
              name: 'Medium parcel',
            },
          ],
        },
        {
          name: 'What is the class of the item?',
          description: '',
          type: 'stringlistsingle',
          internalId: 'class_of_item',
          errors: {
            required: 'This field is required',
          },
          required: true,
          editable: true,
          condition: {
            react: 'return {{size_of_item}};',
          },
          options: [
            {
              internalId: '1st',
              name: '1st class',
            },
            {
              internalId: '2nd',
              name: '2nd class',
            },
            {
              internalId: 'anything_else',
              name: 'Anything else (e.g. other postage…)',
            },
          ],
        },
        {
          name: 'Does your postage look like this?',
          type: 'boolean',
          internalId: 'correct_postage',
          options: [
            {
              name: 'Yes',
              internalId: true,
              icon: 'check',
            },
            {
              name: 'No',
              internalId: false,
              icon: 'times',
            },
          ],
          errors: {
            required: 'This field is required',
          },
          required: true,
          editable: true,
          condition: {
            react: "return {{franking_value}} && {{franking_value}} != 'cant_read' && {{franking_value}} != 'other';",
          },
        },
        {
          name: '',
          description:
            "<img src='https://spectos-royalmail-prod.s3.eu-central-1.amazonaws.com/app/form/franking_value.png'/>",
          type: 'note',
          required: false,
          condition: {
            react: "return {{franking_value}} && {{franking_value}} != 'cant_read' && {{franking_value}} != 'other';",
          },
        },
        {
          name: '',
          description:
            "<img src='https://spectos-royalmail-prod.s3.eu-central-1.amazonaws.com/app/form/stamps_and_meter_franks/1c_letter.png'/>",
          type: 'note',
          required: false,
          internalId: 'note_stamp_le_1st',
          condition: {
            react: 'return false;',
          },
        },
        {
          name: '',
          description:
            "<img src='https://spectos-royalmail-prod.s3.eu-central-1.amazonaws.com/app/form/stamps_and_meter_franks/2c_letter.png'/>",
          type: 'note',
          required: false,
          internalId: 'note_stamp_le_2nd',
          condition: {
            react: 'return false;',
          },
        },
        {
          name: '',
          description:
            "<img src='https://spectos-royalmail-prod.s3.eu-central-1.amazonaws.com/app/form/stamps_and_meter_franks/1c_large_letter.png'/>",
          type: 'note',
          required: false,
          internalId: 'note_stamp_ll_1st',
          condition: {
            react: 'return false;',
          },
        },
        {
          name: '',
          description:
            "<img src='https://spectos-royalmail-prod.s3.eu-central-1.amazonaws.com/app/form/stamps_and_meter_franks/2c_large_letter.png'/>",
          type: 'note',
          required: false,
          internalId: 'note_stamp_ll_2nd',
          condition: {
            react: 'return false;',
          },
        },
        {
          name: '',
          description:
            "<img src='https://spectos-royalmail-prod.s3.eu-central-1.amazonaws.com/app/form/ppi/1st_class.png'/>",
          type: 'note',
          required: false,
          internalId: 'note_stamp_small_1st',
          condition: {
            react: 'return false;',
          },
        },
        {
          name: '',
          description:
            "<img src='https://spectos-royalmail-prod.s3.eu-central-1.amazonaws.com/app/form/ppi/2nd_class.png'/>",
          type: 'note',
          required: false,
          internalId: 'note_stamp_small_2nd',
          condition: {
            react: 'return false;',
          },
        },
        {
          name: '',
          description:
            "<img style='max-width: 760px' src='https://spectos-royalmail-prod.s3.eu-central-1.amazonaws.com/app/form/ppi/1c_ppi_barcode.png' /><br/><img style='max-width: 760px' src='https://spectos-royalmail-prod.s3.eu-central-1.amazonaws.com/app/form/ppi/rm24_basic_barcode.png' /><br/><img style='max-width: 760px' src='https://spectos-royalmail-prod.s3.eu-central-1.amazonaws.com/app/form/ppi/1st_class.png' />",
          type: 'note',
          internalId: 'note_ppi_postage_value_1st',
          required: false,
          condition: {
            react: 'return false;',
          },
        },
        {
          name: '',
          description:
            "<img style='max-width: 760px' src='https://spectos-royalmail-prod.s3.eu-central-1.amazonaws.com/app/form/ppi/2c_ppi_barcode.png' /><br/><img style='max-width: 760px' src='https://spectos-royalmail-prod.s3.eu-central-1.amazonaws.com/app/form/ppi/rm48_basic_barcode.png' /><br/><img style='max-width: 760px' src='https://spectos-royalmail-prod.s3.eu-central-1.amazonaws.com/app/form/ppi/2nd_class.png' />",
          type: 'note',
          internalId: 'note_ppi_postage_value_2nd',
          required: false,
          condition: {
            react: 'return false;',
          },
        },
        {
          name: '',
          description:
            "<img style='max-width: 760px' src='https://spectos-royalmail-prod.s3.eu-central-1.amazonaws.com/app/form/postage_labels/1c_label_new.png' /><br/><img style='max-width: 760px' src='https://spectos-royalmail-prod.s3.eu-central-1.amazonaws.com/app/form/postage_labels/rm24.png' />",
          type: 'note',
          internalId: 'note_ppa_postage_value_1st',
          required: false,
          condition: {
            react: 'return false;',
          },
        },
        {
          name: '',
          description:
            "<img style='max-width: 760px' src='https://spectos-royalmail-prod.s3.eu-central-1.amazonaws.com/app/form/ppi/2nd_class.png' /><br/><img style='max-width: 760px' src='https://spectos-royalmail-prod.s3.eu-central-1.amazonaws.com/app/form/postage_labels/rm48.png' />",
          type: 'note',
          internalId: 'note_ppa_postage_value_2nd',
          required: false,
          condition: {
            react: 'return false;',
          },
        },
        {
          name: 'Photo of postage',
          type: 'camera',
          internalId: 'photo_of_postage',
          errors: {
            required: 'This field is required',
          },
          required: true,
          editable: true,
          condition: {
            react: 'return {{correct_postage}} === false;',
          },
          uploadFileName: 'icode_20240528_63060ddf94733b0da58eb1fc_1234567',
          uploadPath: 'app/icode/1234567',
          s3config: 'TTM',
        },
        {
          name: 'Please scan the mailmark',
          description: 'Where to find the mailmark',
          type: 'camera',
          internalId: 'postage_mailmark',
          errorMessage: 'This field is required',
          errors: {
            required: 'This field is required',
          },
          required: true,
          editable: true,
          condition: {
            react: 'return false;',
          },
          uploadFileName: 'icode_20240528_63060ddf94733b0da58eb1fc_1234567',
          uploadPath: 'app/icode/1234567',
          s3config: 'TTM',
        },
        {
          name: 'Please let us know the amount.',
          condition: {
            react: "return franking_value == 'other';",
          },
          type: 'string',
          internalId: 'amount_postage',
          description: 'e.g. "1.30", "0.85"',
          errorMessage: 'This field is required',
          errors: {
            required: 'This field is required',
            validation: 'Please enter a valid number',
          },
          validation: {
            type: 'regex',
            regex: '^(?:\\d{1,9})(?:\\.\\d{1,2})?$',
          },
          required: true,
          editable: true,
        },
        {
          name: 'Tell us what’s wrong',
          condition: {
            react:
              "return franking_value == 'cant_read' || postage_value == 'anything_else' || class_of_item == 'anything_else';",
          },
          type: 'textArea',
          internalId: 'deviations',
          errorMessage: 'This field is required',
          errors: {
            required: 'This field is required',
          },
          required: false,
          editable: true,
        },
        {
          name: 'Was the item delivered with other addressed items?',
          condition: {
            react: 'return false;',
          },
          type: 'boolean',
          internalId: 'dsa_other_items',
          options: [
            {
              name: 'Yes',
              internalId: true,
              icon: 'check',
            },
            {
              name: 'No',
              internalId: false,
              icon: 'times',
            },
            {
              name: "Don't know",
              internalId: 'unsure',
              icon: 'cross',
            },
          ],
          errorMessage: 'This field is required',
          errors: {
            required: 'This field is required',
          },
          required: true,
          editable: true,
        },
      ],
    },
  ],
};
