import { SuccessMessageMock } from '@constant/model/mock/successMessage';
import { submitICodeRequest } from '@redux/iCode/submitICodeSlice';
import { navigate, popToTop } from '@routes/navigationUtils';
import { Screens } from '@routes/route';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export const useReportICodeReview = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const data = props?.route?.params?.data;
  const values = props?.route?.params?.values;
  const title = props?.route?.params?.header?.title;
  const [disabled, setDisabled] = useState(false);

  const banner = {
    description: t('ICODE_REVIEW_BANNER_DESCRIPTION'),
  };
  const handleSuccess = rs => {
    //TODO: reload api dashboard here
    // setIsSuccess(true);
    // setMessageInfo(rs?.message)
    navigate(Screens.SUCCESSFUL_SCREEN, {
      data: rs?.message,
      header: {
        title: title,
        backClick: () => {
          popToTop();
        },
      },
    });
    // setTimeout(() => {
    //   popToTop();
    // }, 5000);
  };
  const onSubmit = () => {
    // handleSuccess({ message: SuccessMessageMock });
    //
    // return;
    if (disabled) {
      return;
    }
    setDisabled(true);

    dispatch(
      submitICodeRequest(
        values,
        rs => {
          handleSuccess(rs);
          setDisabled(false);
        },
        err => {
          setDisabled(false);
        },
      ),
    );
  };
  return {
    data,
    values,
    title,
    banner,
    onSubmit,
  };
};
