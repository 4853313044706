import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { Spacing } from '@theme/size';
import { StyleSheet } from 'react-native';

export const OverDueStyle =
  Device.deviceType === 'web'
    ? StyleSheet.create({
        rootContainer: {
          borderTopRightRadius: Spacing.width20,
          borderTopLeftRadius: Spacing.width20,
          backgroundColor: palette.WHITE,
          // backgroundColor: 'red',

          flex: 1,
          paddingHorizontal: Spacing.width15,
          paddingTop: Spacing.height15,
          paddingBottom: Spacing.height15,
          // background
        },
        modal: {
          width: 810,
          margin: 'auto',
        },
        modalContent: {
          flex: 1,
          backgroundColor: 'white',
          padding: 50,
          margin: 20,
        },
        container: {
          marginTop: 30,
          padding: 5,
          flexWrap: 'wrap',
        },
        item: {
          width: '33%',
          // height: 200,
          borderWidth: 5,
          borderColor: 'transparent',
          overflow: 'hidden',
        },
        close: {
          position: 'absolute',
          backgroundColor: '#E3E3E370',
          top: 0,
          right: 0,
          zIndex: 1,
          padding: 5,
        },
        image: {
          flex: 1,
          width: '100%',
          // height: '100%',
          minHeight: 200,
          borderRadius: 4,
          borderColor: '#C6C6C6',
          borderWidth: 1,
        },
        btn: {
          position: 'absolute',
          right: 10,
          top: 10,
          color: palette.WHITE,
          backgroundColor: '#E3E3E370',
          border: 'none',
          borderRadius: 4,
          cursor: 'pointer',
          padding: 5,
          width: 35,
          height: 35,
        },
      })
    : StyleSheet.create({
        container: {
          flex: 1,
        },

        rootContainer: {
          borderTopRightRadius: Spacing.width20,
          borderTopLeftRadius: Spacing.width20,
          backgroundColor: palette.WHITE,
          flex: 1,
          paddingHorizontal: Spacing.width15,
          paddingTop: Spacing.height15,
          paddingBottom: Spacing.height15,
        },
        wrapper: {
          // paddingLeft: 10,
          flex: 1,
          // flexGrow: 1,
          flexDirection: 'row',
          // flexWrap: 'wrap',
        },
        box: {
          flex: 1,
          maxWidth: '49%',
          height: (Spacing.width76 * 3) / 2,
          // minHeight: (Device.width * 7) / 20,

          backgroundColor: 'transparent',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 4,
          // borderStyle: 'dotted',
          // borderWidth: 1,
          marginBottom: Spacing.width10,
          marginRight: Spacing.width10,
        },
        image: {
          width: '100%',
          height: '100%',
          borderRadius: 4,
          borderColor: '#C6C6C6',
          borderWidth: 1,
        },
        shadow: {
          borderWidth: 0,
        },
        imageWrap: {
          borderStyle: 'solid',
          borderColor: '#D5D5D5',
        },
        close: {
          position: 'absolute',
          backgroundColor: '#E3E3E370',
          top: 0,
          right: 0,
          zIndex: 1,
          padding: 5,
        },
        outermostItem: {
          marginRight: 0,
        },
        title: {
          fontWeight: '400',
          color: '#333333',
          marginBottom: 8,
        },
        isRequired: {
          color: '#FF5757',
        },
        hint: {
          fontWeight: '400',
          color: '#999999',
          marginTop: 7,
        },
        error: {
          marginTop: 7,

          color: palette.ROYAL_MAIL_RED,
          fontWeight: '400',
        },
      });
