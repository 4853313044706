export const DeeplinkType = {
  active: {
    key: 'active',
    pattern: '/active',
  },
  resetPassword: {
    key: 'resetPassword',
    pattern: '/resetPassword',
  },
  notifications: {
    key: 'notifications',
    pattern: '/notifications',
  },
  changeEmail: {
    key: 'changeEmail',
    pattern: '/changeEmail',
  },
};
export const DeeplinkHelper = {
  getDeeplinkType: url => {
    console.log('getDeeplinkType>>>', url);
    for (const deeplinkTypeKey in DeeplinkType) {
      const item = DeeplinkType[deeplinkTypeKey];
      const pattern = item.pattern;
      console.log('pattern>>>', pattern, item.key);
      if (url.includes(pattern)) {
        return item.key;
      }
    }
    return '';
  },
  getDurationDelay: (typeLink, isFirstTime, isInit = true) => {
    if (isFirstTime) {
      // if is
      if (isInit) {
        switch (typeLink) {
          case DeeplinkType.changeEmail.key:
          //NOTE: the isInit will be open faster: 3500ms
          case DeeplinkType.notifications.key:
            return 3500;
        }
      }
      return 4500;
    }

    switch (typeLink) {
      case DeeplinkType.active.key:
      case DeeplinkType.resetPassword.key:
        return 300;
      case DeeplinkType.changeEmail.key:
      case DeeplinkType.notifications.key:
        return 300;
    }

    return 300;
  },
};
