import { globalStyle } from '@theme';
import { palette } from '@theme/colors';
import PropTypes from 'prop-types';
import React from 'react';
import { View, Text, ActivityIndicator, Modal } from 'react-native';
import { useLoading } from './Loading.hook';
import { LoadingStyles } from './Loading.style';
// import {Modal} from "@ui-kitten/components";

export const LoadingView = props => {
  const { style } = props;
  return (
    <View style={[LoadingStyles.loadingContainer, style]}>
      <View style={[globalStyle.center, globalStyle.flex1, LoadingStyles.background]}>
        <ActivityIndicator size={'large'} color={palette?.LOADING_COLOR} style={{ transform: [{ scale: 1.2 }] }} />
      </View>
    </View>
  );
};

export const LoadingModal = props => {
    const { style = {}, visible = false, children, modalRef, onBackdropPress, containerStyle = [], supportKeyboard = false } = props;

    return (
        <Modal
            animationType={'none'}
            style={(style, { justifyContent: 'center' })}
            visible={true}
            // visible={props.modalVisible}
            // backdropStyle={LoadingStyles.backdrop}
            transparent
            statusBarTranslucent={true}>
            <LoadingView style={[LoadingStyles.children]}/>
        </Modal>
    )
}

LoadingView.propTypes = {
    style: PropTypes.array,
};

LoadingView.defaultProps = {
    style: []
};


