import { CrossIcon } from '@assets';
import { AppSafeAreaView, AuthSuccessScreen } from '@components';
import { AppSuccessScreen } from '@components/AppSuccessScreen';
import GenericHeader from '@components/GenericHeader';
import { palette } from '@theme/colors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View } from 'react-native';
import { useLogic } from './ForgotSuccess.hook';

export const ForgotSuccess = () => {
  const { messageInfo, onClose } = useLogic();
  const { t } = useTranslation();
  return (
    <AppSafeAreaView areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>
      <GenericHeader
        onBackClicked={() => {
          // goBack();
          onClose();
        }}
        // title={getContentTitle(data?.title)}
        style={[{ backgroundColor: palette.BACKGROUND_GREY }]}
      />
      {/*<TouchableOpacity*/}
      {/*  onPress={()=> {onClose()}}*/}
      {/*  style={{*/}
      {/*    position: 'absolute',*/}
      {/*    // top: -16,*/}
      {/*    right: 6,*/}
      {/*    backgroundColor: '#c9c9c9',*/}
      {/*    zIndex: 1,*/}
      {/*    padding: 6,*/}
      {/*    borderRadius: 1000,*/}
      {/*  }}>*/}
      {/*  <CrossIcon color="white" />*/}
      {/*</TouchableOpacity>*/}
      <AppSuccessScreen alert={messageInfo?.alert} icon={messageInfo?.icon} description={messageInfo?.description} />
    </AppSafeAreaView>
  );
  // return <AuthSuccessScreen title={t('PASSWORD_RESET_SUCCESSFUL_TITLE')} desc={t('PASSWORD_RESET_SUCCESSFUL_DESC')} />;
};
