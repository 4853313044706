import { FilterIcon, LogoIcon, MessageCheckIcon } from '@assets';
import { AppKAView, AppSafeAreaView, AppText } from '@components';
import { ListQuizStyle } from '@screens/main/quiz/ListQuiz/ListQuiz.style';
import { ListTicketStyle } from '@screens/main/ticket/ListTicket/ListTicket.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font, Spacing } from '@theme/size';
import { getContentTitle } from '@utils/transform/form';
import { getText } from '@utils/transform/ticket';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, TouchableOpacity } from 'react-native';
import { Modalize } from 'react-native-modalize';
import { Portal } from 'react-native-portalize';
import { useTicketFilter } from './TicketFilter.hook';
import { TicketFilterStyle } from './TicketFilter.style';

export const TicketFilterView = props => {
  const { selectedStatus, onFilterPress, modalizeRef, onItemStatusPress, listStatus } = props;
  const { t } = useTranslation();
  const {} = useTicketFilter(props);
  const renderStatusItem = ({ item, index }) => {
    console.log('renderStatusItem>>>', item);
    return (
      <View style={[globalStyle.directionRow, ListQuizStyle.viewContainer]}>
        <TouchableOpacity
          onPress={() => {
            onItemStatusPress(item);
          }}
          activeOpacity={0.7}
          style={[
            item.key === selectedStatus ? TicketFilterStyle.viewSelect : TicketFilterStyle.viewUnSelect,
            globalStyle.directionRow,
          ]}>
          <AppText style={[globalStyle.fullWidth, TicketFilterStyle.itemText]}>{getContentTitle(item?.title)}</AppText>
        </TouchableOpacity>
      </View>
    );
  };
  return (
    <View style={[globalStyle.directionRow]}>
      <TouchableOpacity
        style={[globalStyle.directionRow, ListTicketStyle.filterView, globalStyle.center]}
        onPress={() => {
          onFilterPress();
        }}>
        <FilterIcon />
        <AppText style={[ListTicketStyle.filterText]}>
          {getContentTitle(listStatus.find(x => x.key === selectedStatus)?.title)}
        </AppText>
      </TouchableOpacity>
      <Portal>
        <Modalize ref={modalizeRef} disableScrollIfPossible adjustToContentHeight>
          <View style={[globalStyle.directionColumm, { marginBottom: Spacing.height25 }]}>
            <AppText style={[ListTicketStyle.modalTitle]}>{t('STATUS')}</AppText>
            {listStatus?.map((item, index) => {
              // if (index > 4) {
              //   return <></>;
              // }
              return renderStatusItem({ item, index });
            })}
            {/*</View>*/}
          </View>
        </Modalize>
      </Portal>
    </View>
  );
};

TicketFilterView.propTypes = {
  selectedStatus: PropTypes.string.isRequired,
  onFilterPress: PropTypes.func.isRequired,
  modalizeRef: PropTypes.any.isRequired,
  listStatus: PropTypes.array.isRequired,
  onItemStatusPress: PropTypes.func.isRequired,
};

TicketFilterView.defaultProps = {};
