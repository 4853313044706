// import {FastImage} from "@utils/common/fast-image";
import { CommonImages } from '@assets';
import { AppEmptyView, AppSafeAreaView } from '@components';
import { AppImage } from '@components/AppImage';
import GenericHeader from '@components/GenericHeader';
import { goBack } from '@routes/navigationUtils';
import { GridImageView, GroupGridView } from '@screens/main/D2D/overDue/group/grid';
import { useOverDueGroup } from '@screens/main/D2D/overDue/group/hook';
import { D2DOverviewStyle } from '@screens/main/D2D/overview/D2DOverview.style';
import { AppNoteView } from '@screens/main/quiz/QuizFailed/components/AppNote.view';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import { globalStyle } from '@theme';
import { palette } from '@theme/colors';
import React from 'react';
import { FlatList, Image, TouchableOpacity, View, StatusBar, ScrollView } from 'react-native';
import ImageViewer from 'react-native-image-zoom-viewer';
import Modal from 'react-native-modal';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { useOverDue } from './../OverDue.hook';
import { OverDueStyle as styles } from './../OverDue.style';

export const OverDueGroupScreen = props => {
  const {
    onItemPress,
    onItemZoom,
    getD2DItemsData,
    visible,
    setIsVisible,
    paramsData,
    selectedImage,
    description,
    loading,
    isGroup,
  } = useOverDueGroup(props);
  const images = getD2DItemsData;

  const renderImageModal = () => {
    return (
      <Modal isVisible={visible} style={styles.modal}>
        <View style={[styles.modalContent]}>
          <button style={styles.btn} onClick={() => setIsVisible(false)}>
            <FontAwesome5 name="times" size={22} />
          </button>
          <ImageViewer imageUrls={selectedImage} renderIndicator={() => null} />
        </View>
      </Modal>
    );
  };
  const renderImage = () => {
    return (
      <View style={styles.rootContainer}>
        {!!description && <AppNoteView style={RegisterFormStyles.formItemView} title={description} />}
        {/*<FlatList*/}
        {/*  horizontal={false}*/}
        {/*  numColumns={3}*/}
        {/*  contentContainerStyle={{*/}
        {/*    flexGrow: 1,*/}
        {/*    // backgroundColor: 'green',*/}
        {/*    // flex: 1,*/}
        {/*  }}*/}
        {/*  // style={{ gap: 10 }}*/}
        {/*  data={images}*/}
        {/*  ListEmptyComponent={() => {*/}
        {/*    return !loading ? <AppEmptyView /> : <></>;*/}
        {/*  }}*/}
        {/*  renderItem={({ item, index }) => {*/}
        {/*    return (*/}
        {/*      <View style={styles.item}>*/}
        {/*        <TouchableOpacity*/}
        {/*          key={index}*/}
        {/*          onPress={() => {*/}
        {/*            onItemPress(item);*/}
        {/*          }}>*/}
        {/*          <TouchableOpacity onPress={() => onItemZoom(item)} style={styles.close}>*/}
        {/*            /!*<CrossIcon />*!/*/}
        {/*            <FontAwesome5 name={'expand-alt'} size={24} color={palette.WHITE} />*/}
        {/*          </TouchableOpacity>*/}
        {/*          /!*<Image style={styles.image} source={{ uri: item.image }} />*!/*/}
        {/*          <AppImage*/}
        {/*            // style={{ width: 200, height: 200 }}*/}
        {/*            style={[styles.image]}*/}
        {/*            source={{ uri: item.image }}*/}
        {/*            resizeMode={'cover'}*/}
        {/*            defaultSource={CommonImages.ImageDefault}*/}
        {/*            hideDefaultImage={true}*/}
        {/*          />*/}
        {/*        </TouchableOpacity>*/}
        {/*      </View>*/}
        {/*    );*/}
        {/*  }}*/}
        {/*/>*/}
        {!isGroup && <GridImageView data={getD2DItemsData} onItemPress={onItemPress} onItemZoom={onItemZoom} />}
        {isGroup && (
          <GroupGridView loading={loading} data={getD2DItemsData} onItemPress={onItemPress} onItemZoom={onItemZoom} />
        )}
      </View>
    );
  };

  return (
    <View style={globalStyle.flex1}>
      <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
      <AppSafeAreaView areaStyle={[D2DOverviewStyle.areaStyle]}>
        <GenericHeader onBackClicked={goBack} title={paramsData.name} style={[{ backgroundColor: '#EDEDED' }]} />
        {renderImage()}
        {renderImageModal()}
        {/*<ScrollView>{renderImage()}</ScrollView>*/}
      </AppSafeAreaView>
    </View>
  );
};
