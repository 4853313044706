import theme from '@theme';
import { palette } from '@theme/colors';
import { FontSize } from '@theme/size';
import { StyleSheet, Platform } from 'react-native';

export const RegisterFormStyles = StyleSheet.create({
  containerView: {
    borderTopRightRadius: theme.spacing.lg,
    borderTopLeftRadius: theme.spacing.lg,
    backgroundColor: palette.WHITE,
    // paddingTop: theme.spacing.lg,
    flex: 1,
    flexGrow: 1,
    // paddingHorizontal: theme.spacing.md,
    // paddingTop: theme.spacing.md,/
  },
  formItemView: {
    // marginBottom: theme.spacing.md,
    marginBottom: theme.spacing.lg,
  },
  paddingSpace: {
    paddingHorizontal: theme.spacing.md,
  },
  descriptionText: {
    fontSize: FontSize.Font15,
    textAlign: 'center',
  },
});
