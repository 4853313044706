import { PERMISSION_MODEL } from '@constant';
import { getProfileCreator } from '@redux/user/selectors';
import { requestPermission } from '@utils/common/deviceUtil';
import { useEffect, useState } from 'react';
import Geolocation from 'react-native-geolocation-service';
import { useSelector } from 'react-redux';
import _ from 'lodash'
export const LocationType = {
  home: 'home',
  gps: 'gps'
}

export const useLocation = (deps, isUpdateUserLocation, timeout = 0) => {
  const getProfileData = useSelector(getProfileCreator)?.message?.data;

  const [location, setLocation] = useState();
  const [loading, setLoading] = useState(true);
  const [watchId, setWatchId] = useState(null);
  const [locationType, setLocationType] = useState('')

  // useEffect(() => {
  //   const id = setTimeout(() => {
  //     handleGetLocation();
  //   }, 600);
  //   return () => clearTimeout(id);
  // }, [deps]);

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     if (isUpdateUserLocation) handleGetLocation();
  //   }, timeout);
  //   return () => clearTimeout(timeoutId);
  // }, [isUpdateUserLocation]);

  useEffect(() => {
    if(!_.isEmpty(getProfileData?.latitude)){
        setLocationType(LocationType.home)
    }
  }, [getProfileData])

  const handleGetLocation = async () => {
    try {
      setLoading(true);
      await requestPermission(() => {
        setWatchId(Geolocation.getCurrentPosition(
          position => {
            setLocation(position?.coords);
            setLocationType(LocationType.gps)
            setLoading(false);
          },
          error => {
            console.log(error.code, error.message);
            setLoading(false);
          },
          { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 },
        ));
      }, PERMISSION_MODEL.LOCATION);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // const updateUserLocation = async () => {
  //   try {
  //     setLoading(true);
  //     await requestPermission(() => {
  //       setWatchId(
  //         Geolocation.watchPosition(
  //           position => {
  //             console.log('Geolocation.watchPosition');
  //             setLocation(position?.coords);
  //             setLoading(false);
  //           },
  //           error => {
  //             console.log(error.code, error.message);
  //             setLoading(false);
  //           },
  //           { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000, distanceFilter: 30 },
  //         ),
  //       );
  //     }, PERMISSION_MODEL.LOCATION);
  //   } catch (error) {
  //     setLoading(false);
  //     console.log(error);
  //   }
  // };

  return {
    lat: location?.latitude || Number(getProfileData?.latitude),
    long: location?.longitude || Number(getProfileData?.longitude),
    locationType,
    loading: loading,
    watchId,
    handleGetLocation
  };
};
