import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppSafeAreaView, AppText } from '@components';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font, FontSize } from '@theme/size';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text } from 'react-native';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { useRegisterCompleted } from './RegisterCompleted.hook';
import { RegisterCompletedStyles } from './RegisterCompleted.style';

export const RegisterCompletedComponent = props => {
  const { waitingForSendMail } = useRegisterCompleted(props);
  const { t } = useTranslation();

  return (
    <AppSafeAreaView areaStyle={[globalStyle.flex1, globalStyle.alignCenter, globalStyle.justifyCenter]}>
      {/*<Icon name="comment-dots" size={40} color={palette.ROYAL_MAIL_RED} />*/}
      <MessageCheckIcon color={palette.GREEN_ACTIVE} />
      <View
        style={[
          globalStyle.alignCenter,
          globalStyle.justifyCenter,
          globalStyle.alignSelfCenter,
          globalStyle.directionColumm,
        ]}>
        <AppText style={[Font.Bold_700, { textAlign: 'center', margin: theme.spacing.sm, fontSize: FontSize.Font15 }]}>
          {t('REGISTER_COMPLETED_TITLE')}
        </AppText>
        <AppText
          style={[
            { textAlign: 'center', marginHorizontal: theme.spacing.sm, fontSize: FontSize.Font15, color: '#666666' },
          ]}>
          {t('REGISTER_COMPLETED_DESCRIPTION')}
        </AppText>
      </View>
    </AppSafeAreaView>
  );
};
