// import {currentLocale} from '../assets/translations/i18n';
import i18n from 'i18next';
import momentX from 'moment';
import moment from 'moment-timezone';
import 'moment/min/locales';

export const DATE_FORMAT = {
  // DateApi: 'DD-MM-YYYY',
  DateApi: 'DD/MM/YYYY',
  FullDateApi: 'DD/MM/YYYY HH:mm:ss',
  UIDate: 'DD/MM/YYYY',
  // UIDate: 'DD-MM-YYYY',
  StandardDate: 'YYYY-MM-DD',
  UIFullDate: 'DD/MM/YYYY HH:mm',
  StandardFullDate: 'YYYY-MM-DD HH:mm:ss',
  StandarDatetime: 'YYYY-MM-DDTHH:mm',
  Time: 'HH:mm',
  StandardTime: 'HH:mm',
  TimeApi: 'HH:mm:ss',
  DateToServer: 'YYYY-MM-DD',
  DateUI: 'DD.MM.YYYY',
  FullDate: 'DD/MM/YYYY HH:mm:ss',
  // format Wed, 4 Dec at 09:53
  commentDate: 'ddd, DD MMM HH:mm'
};

export const FORMAT = {
  DatePicker: {
    format: DATE_FORMAT.UIDate,
    fromFormat: DATE_FORMAT.StandardDate,
  },
  TimePicker: {
    format: DATE_FORMAT.Time,
    fromFormat: DATE_FORMAT.StandardTime,
  },
  Calendar: {
    format: DATE_FORMAT.UIDate,
    fromFormat: DATE_FORMAT.StandardDate,
  },
  DateTimePicker: {
    format: DATE_FORMAT.UIFullDate,
    fromFormat: DATE_FORMAT.StandardFullDate,
  },
};

const formatDateTime = (time, tz = 'UTC', datePattern = 'MM/DD/YYYY HH:mm zz') => {
  let strDate = moment.utc(time).tz(tz).format(datePattern);
  return strDate;
};

const formatDateWithTz = (time, tz = 'UTC', datePattern = 'MMM DD, YYYY') => {
  let strDate = moment.utc(time).tz(tz).format(datePattern);
  return strDate;
};

const formatDate = (date, toFormat = DATE_FORMAT.DateApi, fromFormat = '') => {
  if (moment.isMoment(date)) {
    let strDate = momentX(date).format(toFormat);
    return strDate;
  }
  console.log('formatDate>>>', date, toFormat, fromFormat);
  const m = fromFormat ? momentX(date, fromFormat) : momentX(date);
  const rs = momentX(date || moment(), toFormat).toDate();

  if (m.isValid()) {
    let strDate = m.format(toFormat);
    return strDate;
  }
  // console.log('formatDate>>>', date, toFormat, m, m.isValid(), m.toDate(), rs);
  return date;
};

export const formatDateV1 = (date, format = DATE_FORMAT.UIDate) => {
  const m = momentX(date);
  if (m.isValid()) {
    let strDate = m.format(format);
    return strDate;
  }
  // console.log('formatDate>>>', date, toFormat, m, m.isValid(), m.toDate(), rs);
  return date;
};

const formatDayTime = (time, tz = 'UTC', timePattern = 'HH:mm zz') => {
  let strTime = moment.utc(time).tz(tz).format(timePattern);
  return strTime;
};

const getDurationTime = (start, end) => {
  let substract = moment(end).format('X') - moment(start).format('X');
  return formatTimeToString(substract);
};

const getDateToServer = (time, datePattern = DATE_FORMAT.DateToServer) => {
  let momentDate = moment(time, datePattern);
  const year = momentDate.get('year');
  const month = momentDate.get('month') + 1;
  const date = momentDate.get('date');
  const dateStr = `${date}.${month}.${year}`;
  return dateStr;
};

const formatTimeToString = input => {
  if (input) {
    var seconds = input % 60;
    var minutes = Math.floor(input / 60);
    var hours = Math.floor(minutes / 60);
    minutes %= 60;
    var days = Math.floor(hours / 24);
    hours %= 24;

    var result = [];
    if (days > 0) {
      result.push(days + ' ' + (days > 1 ? 'days' : 'day'));
    }
    if (hours > 0) {
      result.push(hours + ' ' + (hours > 1 ? 'hours' : 'hour'));
    }
    if (minutes > 0) {
      result.push(minutes + ' ' + (minutes > 1 ? 'mins' : 'min'));
    }
    if (seconds > 0) {
      result.push(seconds + ' ' + (seconds > 1 ? 'secs' : 'sec'));
    }
    return result.slice(0, 2).join(' ');
  }
  return input;
};

export const getLocaleDateFormat = () => {
  const locale = i18n.language;
  // const rs = moment.localeData().longDateFormat('L')
  // console.log('getLocaleDateFormat>>>', rs)
  // return rs
  return getDateFormat(locale);
};

export const getDateFormat = locale => {
  const rs = moment.localeData(locale).longDateFormat('L');
  return rs;
};

const getDateByAddedDay = day => {
  let nowDate = moment();
  let rs = nowDate.toDate();
  if (day != 0) {
    rs = nowDate.add(day, 'd').toDate();
  }
  const newDate = new Date(rs.toDateString());
  return newDate;
};

const getCurentDateTimeText = (datePattern = DATE_FORMAT.DateApi) => {
  let strDate = moment(new Date()).format(datePattern);
  return strDate;
};

const getCurrentDateTimeLocalText = (format = DATE_FORMAT.FullDateApi) => {
  const date = new Date();
  // console.log('getCurrentDateTimeLocalText>>>', date)
  let strDate = moment(date).format(format);
  return strDate;
};

export const getDateFromServerDot = dateDot => {
  let momentDate = moment(dateDot, DATE_FORMAT.DateUI);
  console.log('getDateFromServerDot>>>', momentDate);
  return momentDate.toDate();
};

export {
  formatDateTime,
  formatDate,
  formatDateWithTz,
  formatDayTime,
  getDurationTime,
  formatTimeToString,
  getDateByAddedDay,
  getDateToServer,
  getCurentDateTimeText,
  getCurrentDateTimeLocalText,
};
