export const CanEnterWithInt = [
    {
        "name": "Posting packages",
        "internalId": "Posting packages",
        "type": "both",
        "labelId": "6464843ec173fe4f0a111dfa",
        "content": "Posting packages",
        "id": 1
    },
    {
        "name": "Bundle posting",
        "internalId": "Bundle posting",
        "type": "both",
        "labelId": "6464844bc173fe4f0a111e3a",
        "content": "Bundle posting",
        "id": 2
    },
    {
        "name": "Report test items",
        "internalId": "Report test items",
        "type": "both",
        "labelId": "64648459c173fe4f0a111e82",
        "content": "Report test items",
        "id": 3
    }
]

export const CanEnterWithFloat = [
    {
        "name": "Posting packages",
        "internalId": "Posting packages",
        "type": "both",
        "labelId": "6464843ec173fe4f0a111dfa",
        "content": "Posting packages",
        "id": 1.1
    },
    {
        "name": "Bundle posting",
        "internalId": "Bundle posting",
        "type": "both",
        "labelId": "6464844bc173fe4f0a111e3a",
        "content": "Bundle posting",
        "id": 1.2,
    },
    {
        "name": "Report test items",
        "internalId": "Report test items",
        "type": "both",
        "labelId": "64648459c173fe4f0a111e82",
        "content": "Report test items",
        "id": 1.3,
    },
    {
        "name": "Posting packages",
        "internalId": "Posting packages",
        "type": "both",
        "labelId": "6464843ec173fe4f0a111dfa",
        "content": "Posting packages",
        "id": 1.1
    },
    {
        "name": "Bundle posting",
        "internalId": "Bundle posting",
        "type": "both",
        "labelId": "6464844bc173fe4f0a111e3a",
        "content": "Bundle posting",
        "id": 1.2,
    },
    {
        "name": "Report test items",
        "internalId": "Report test items",
        "type": "both",
        "labelId": "64648459c173fe4f0a111e82",
        "content": "Report test items",
        "id": 1.3,
    },
    {
        "name": "Posting packages",
        "internalId": "Posting packages",
        "type": "both",
        "labelId": "6464843ec173fe4f0a111dfa",
        "content": "Posting packages",
        "id": 1.1
    },
    {
        "name": "Bundle posting",
        "internalId": "Bundle posting",
        "type": "both",
        "labelId": "6464844bc173fe4f0a111e3a",
        "content": "Bundle posting",
        "id": 1.2,
    },
    {
        "name": "Report test items",
        "internalId": "Report test items",
        "type": "both",
        "labelId": "64648459c173fe4f0a111e82",
        "content": "Report test items",
        "id": 1.3,
    }
]