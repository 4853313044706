import theme from '@theme';
import { palette } from '@theme/colors';
import { Font, FontSize } from '@theme/size';
import { StyleSheet } from 'react-native';

export const HistoryPointsStyle = StyleSheet.create({
  point: {
    paddingHorizontal: theme.spacing.md,
    paddingTop: 2,
    paddingBottom: 3,

    borderRadius: 10,
    borderColor: palette.ROYAL_MAIL_RED,
    backgroundColor: '#E9E9E9',
  },
  textPoint: {
    color: palette.MAIN_BLACK,
    fontSize: FontSize.Font14,
    // fontWeight: '700',
  },
  textHeader: {
    color: palette.MAIN_BLACK,
    fontSize: FontSize.Font30,
    ...Font.Bold_700,
  },
  textDescription: {
    color: palette.MAIN_BLACK,
    fontSize: FontSize.Font14,
    ...Font.Bold_700,
    marginTop: 4,
  },
  textDescription2: {
    color: palette.MAIN_BLACK,
    fontSize: FontSize.Font14,
    // ...Font.Bold_700,
    marginTop: 4,
  },
  container: {
    // backgroundColor: '#F5F5F5',
    marginLeft: theme.spacing.sm,
    marginRight: theme.spacing.sm,
    // marginBottom: theme.spacing.md,
    paddingVertical: theme.spacing.md,
    // borderRadius: 4,
    // borderColor: '#C6C6C6',
  },
  viewLine: {
    marginHorizontal: theme.spacing.sm,
    backgroundColor: '#E1E1E1',
    height:1,
  },
});
