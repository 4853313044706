import { Constant } from '@constant';
import { Config } from '@constant/config';
import { ReportBundleMockData } from '@constant/model/mock/report/bundle';
import { searchForAddressRequest } from '@redux/address/searchForAddressSlice';
import { getListAddressRequest } from '@redux/bundle/getListAddressSlice';
import { navigatePop } from '@routes/navigationUtils';
import { mappingAddress } from '@utils/transform/address';
import { BundleTransform } from '@utils/transform/bundle';
import { use } from 'i18next';
import * as _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export const useBundleAddressContainerModal = props => {
  const postcode = props?.postCode;
  const search = props?.searchText;
  const onSelected = props?.onSelect;
  const dispatch = props?.dispatch;
  const [loadingMore, setLoadingMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(props.data);
  const [rawData, setRawData] = useState(props.data);
  const [searchText, setSearchText] = useState(search);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [totalPage, setTotalPage] = useState(2);
  const [pageNumber, setPageNumber] = useState(Constant.START_PAGE);
  // const [loadedData, setLoadedData] = useState(false);
  // const {postcode:'', onSelected : null}: any = props?.route?.params;

  const [currentPayload, setCurrentPayload] = useState({
    postcode: postcode,
    PageNumber: Constant.START_PAGE,
    PageSize: Constant.SIZE_PAGE,
    // Status: 0,
    hideLoading: false,
  });
  const onTextChanged = text => {
    console.log('onTextChanged>>>', text);
    setSearchText(text);
    // if (_.isEmpty(text)) {
    //   setLoading(false);
    //   setData([]);
    //   return;
    // }
    // debounceSearch(text);
    handleSearchLocal(text, rawData);
  };
  const onSearch = text => {
    // getCustomerListApi(text)
    searchForAddressApi(
      {
        ...currentPayload,
        search: searchText,
      },
      true,
    );
  };

  const debounceSearch = useCallback(
    _.debounce(nextValue => {
      searchForAddressApi({ search: nextValue, hideLoading: true }, true);
    }, 700),
    [],
  );

  const searchForAddressApi = (params, isReset, isPullToRefresh = false, isLoadMore = false) => {
    /*
          payload_info
           */
    const request = {
      postcode: postcode,
      ...params,
      // SearchTerm: searchText,
      // hideLoading: isLoadMore,
      PageNumber: isReset ? Constant.START_PAGE : params.PageNumber,
    };
    if (isLoadMore) {
      setLoadingMore(true);
    }
    const search = params?.search;
    console.log('searchForAddressApi>>>', params, rawData);
    if (_.isEmpty(search)) {
      setData(_.cloneDeep(rawData));
    } else {
      setData(rawData?.filter(x => x.title?.toLowerCase().includes(search?.toLowerCase())));
    }
  };

  const handleSearchLocal = (text, xData) => {
    console.log('handleSearchLocal>>>', text);
    if (_.isEmpty(text)) {
      setData(_.cloneDeep(xData));
    } else {
      setData(xData?.filter(x => x?.title?.toLowerCase().includes(text?.toLowerCase())));
    }
  };

  const getListAddressRequestApi = () => {
    setLoading(true);

    if (Config.IsDebug) {
      const data = BundleTransform.mappingAddress(ReportBundleMockData.address);
      setRawData(data);
      // setData(_.cloneDeep(data));
      handleSearchLocal(search, _.cloneDeep(data));
      setLoading(false);
      return;
    }
    const payload = {
      postal_code: postcode,
      type: props.dispatchType,
      ...props.location,
    };
    dispatch(
      getListAddressRequest(
        payload,
        rs => {
          console.log('response>>>', rs, searchText);
          const data = BundleTransform.mappingAddress(rs?.data);
          // setData(data);
          setRawData(data);
          // setData(_.cloneDeep(data));
          handleSearchLocal(search, _.cloneDeep(data));

          setLoading(false);
        },
        err => {
          setLoading(false);
        },
      ),
    );
  };

  const onRefresh = () => {
    setIsRefreshing(false);
    searchForAddressApi(
      {
        ...currentPayload,
        search: searchText,
      },
      true,
    );
  };

  const onItemPress = item => {
    if (props?.isFullScreen) {
      navigatePop();
    }
    console.log('onItemPress1>>>', item);

    if (onSelected) {
      console.log('onItemPress>>>', item);
      const originItem = _.cloneDeep(item);
      delete originItem.title;
      onSelected(item?.address, originItem);
    }
  };

  const handleLoadMore = () => {
    console.log('handleLoadMore>>>', totalPage, pageNumber, currentPayload);
    totalPage >= pageNumber + 1 &&
      searchForAddressApi(
        {
          ...currentPayload,
          PageNumber: pageNumber + 1,
          search: searchText,
          hideLoading: true,
        },
        false,
        false,
        true,
      );
  };

  useEffect(() => {
    // searchForPostCodeApi();
    // onTextChanged(search);
    // if (_.isEmpty(props.data)) {
    //   getListAddressRequestApi();
    // } else {
    //   setLoading(false);
    // }
    setLoading(false);

  }, []);

  useEffect(() => {}, [dispatch]);
  return {
    onRefresh,
    onSearch,
    onTextChanged,
    handleLoadMore,
    data,
    loadingMore,
    loading,
    isRefreshing,
    searchText,
    onItemPress,
  };
};
