import { QRIcon } from '@assets';
import { AppButton, AppDigit, AppKAView, AppSafeAreaView, AppText } from '@components';
import GenericHeader from '@components/GenericHeader';
import { goBack } from '@routes/navigationUtils';
import { globalStyle } from '@theme';
import { Device } from '@theme/device';
import { Spacing } from '@theme/size';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import {Platform, ScrollView, TouchableOpacity, View} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useLogic } from './Digit.hook';
import { styles } from './Digit.style';
import {palette} from "@theme/colors";
import {Constant} from "@constant";

const Digit = () => {
  const { value, setValue, handleProceed, cellCount, handleQRPress, headerTitle, desc, qrText } = useLogic();
  // const point = dashboard?.find(item => item?.type === 'point')?.point;
  const { t } = useTranslation();
  const PlatformWrap = Device.isAndroid ? View : AppKAView;
  const KAScrollView = Device.isIos ? ScrollView : KeyboardAwareScrollView;
  const KAView = Device.isIos ? AppKAView : Fragment;
  const propsKAView = Device.isIos ? {
    behavior: 'padding', keyboardVerticalOffset: 1
  } : {};

  return (
    <AppSafeAreaView areaStyle={styles.areaStyle}>
      <GenericHeader onBackClicked={goBack} title={headerTitle} style={[{ backgroundColor: '#EDEDED' }]} />
      <KAView {...propsKAView}>
        <View style={styles.container}>
          <KAScrollView
            style={{ width: '100%' }}
            contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
            keyboardShouldPersistTaps="handled">
            <View style={[globalStyle.flex1, styles.wrapper]}>
              <View>
                <AppText style={{ color: '#666666', textAlign: 'center', marginBottom: Spacing.height30 }}>
                  {desc}
                </AppText>
                <AppDigit
                  style={{ marginBottom: Spacing.height40 }}
                  value={value}
                  onChangeText={setValue}
                  cellCount={cellCount}
                />
              </View>
              
              <View style={styles.qrCode}>
                <AppText style={[styles.qrCodeText]}>
                  {qrText}
                </AppText>
                <TouchableOpacity onPress={handleQRPress} style={[{
                  height: 24,
                  marginLeft: 8,
                  paddingTop:4,
                  // marginBottom:-4,
                  alignItems: 'center',
                }]}>
                  <QRIcon color={palette?.ROYAL_MAIL_RED}/>
                </TouchableOpacity>
              </View>
            </View>
          </KAScrollView>
          <AppButton
            disabled={value.length !== cellCount}
            style={styles.button}
            text={t('PROCEED')}
            onPress={handleProceed}
          />
        </View>
      </KAView>
      {/* <View style={{ width: '100%', height: 40, backgroundColor: 'red' }} /> */}
    </AppSafeAreaView>
  );
};

export { Digit };
