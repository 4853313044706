import { createSlice } from '@reduxjs/toolkit';

export interface IrequestChangeEmailState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IrequestChangeEmailState = {
  loading: false,
  payload: null,
  error: null,
};

const requestChangeEmailSlice = createSlice({
  name: 'requestChangeEmail',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    requestChangeEmailRequest(state, action) {
      state.loading = true;
      // @ts-ignore
      state.payload = null;
      state.error = null;
    },
    requestChangeEmailSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    requestChangeEmailFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { requestChangeEmailRequest, requestChangeEmailSuccess, requestChangeEmailFailed } = requestChangeEmailSlice.actions;

export default requestChangeEmailSlice.reducer;
