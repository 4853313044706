import { createSlice } from '@reduxjs/toolkit';

export interface IForceUpdateState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IForceUpdateState = {
  loading: false,
  payload: null,
  error: null,
};

const forceUpdateSlice = createSlice({
  name: 'forceUpdate',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    forceUpdateRequest(state, action) {
      state.loading = true;
      // @ts-ignore
      state.payload = null;
      state.error = null;
    },
    forceUpdateSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
    },
    forceUpdateFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { forceUpdateRequest, forceUpdateSuccess, forceUpdateFailed } = forceUpdateSlice.actions;

export default forceUpdateSlice.reducer;
