import addressSagas from '@redux/address/sagas';
import appSagas from '@redux/app/sagas';
import authSagas from '@redux/auth/sagas';
import formSagas from '@redux/form/sagas';
import holidaySagas from '@redux/holiday/sagas';
import quizzesSagas from '@redux/quizzes/sagas';
import ticketSagas from '@redux/ticket/sagas';
import userSagas from '@redux/user/sagas';
import versionSagas from '@redux/version/sagas';
import fileSagas from '@redux/file/sagas';
import bundleSagas from '@redux/bundle/sagas';
import iCodeSagas from '@redux/iCode/sagas';
import packageSagas from '@redux/package/sagas';
import languageSagas from '@redux/language/sagas';
import surveySagas from '@redux/survey/sagas';
import rfidSagas from '@redux/rfid/sagas';
import notificationSagas from '@redux/notification/sagas';
import triggerActionSagas from '@redux/app/trigger_action/sagas';
import ReimbursementSagas from '@redux/Reimbursement/sagas';



import { all, AllEffect, call, ForkEffect, spawn } from 'redux-saga/effects';

function* rootSaga(): Generator<AllEffect<ForkEffect<void>>> {
  const sagas = [
    authSagas,
    versionSagas,
    formSagas,
    userSagas,
    appSagas,
    quizzesSagas,
    addressSagas,
    holidaySagas,
    ticketSagas,
    fileSagas,
    bundleSagas,
    iCodeSagas,
    packageSagas,
    languageSagas,
    surveySagas,
    rfidSagas,
    notificationSagas,
    triggerActionSagas,
    ReimbursementSagas,
  ];

  yield all(
    sagas?.map(saga =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.error(e);
          }
        }
      }),
    ),
  );
}

export default rootSaga;
