import { useFocusEffect } from '@react-navigation/native';
import { getMenu } from '@redux/app/appSlice';
import { menus } from '@redux/app/selectors';
import { globalLoading } from '@redux/global/selectors';
import { getProfileRequest } from '@redux/user/getProfileSlice';
import { mappingMenuList } from '@utils';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

export const useLogic = () => {
  const dispatch = useDispatch();
  const menuList = useSelector(menus);
  const loading = useSelector(globalLoading);

  const [refresh, setRefresh] = useState(false);
  const [fakeLoading, setFakeLoading] = useState(true);

  useFocusEffect(
    useCallback(() => {
      getMenuApi();
    }, []),
  );

  useEffect(() => {
    if (menuList) setFakeLoading(false);
  }, [menuList]);

  useEffect(() => {
    if (refresh) {
      getMenuApi();
      handleRefresh();
      setRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const handleRefresh = () => {
    setRefresh(true);
  };

  const getMenuApi = () => {
    dispatch(getMenu());
  };

  // const [menus, setMenus] = useState([]);

  return { menuList: mappingMenuList(menuList), handleRefresh, refresh, fakeLoading, loading };
};
