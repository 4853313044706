import { hideLoading } from '@redux/global/globalSlice';
import { MapHelper } from '@utils/common/map';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Geolocation from 'react-native-geolocation-service';
import { useDispatch, useSelector } from 'react-redux';

export const useVerifyMapFormItem = props => {
  const dispatch = useDispatch();

  console.log('useVerifyMapFormItem>>>props', props);
  const isGood = MapHelper.isVerifyGood(
    props?.address_selected_extra,
    props?.location,
    props?.mapSettings?.verifySetting?.radius,
    props.locationType,
  );
  const isShow = MapHelper.isShowMap(props?.address_selected_extra, props?.location, props?.verifyOnMap);
  return { isGood, isShow };
};
