import React, { useEffect, useRef } from 'react';
import {useRoute} from "@react-navigation/native";

export const useAppReviewForm = props => {
  const data = useRoute()?.params?.data;
  const values = useRoute()?.params?.values;
  console.log('useAppReviewForm>>>data', data);
  console.log('useAppReviewForm>>>values', values);

  return {
    data,
    values,
  };
};
