import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import { authUser } from '@redux/auth/selectors';
import { useDeepLink } from '@utils/hooks';
import _ from 'lodash';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { AuthStackScreen } from './AuthStack';
import { MainStackScreen } from './MainStack';
import { RegisterFormStackScreen } from './RegisterFormStack';
import { AUTH, MAIN, Screens } from './route';

const RootStack = createStackNavigator();
interface Props {
  isRegisterForm: boolean;
}
import {LinkingHelper} from "@utils/common/linking";

export const RootStackScreen: FC<Props> = props => {
  // console.log('RootStackScreen>>>', urlParams, window.location);
  const token = useSelector(authUser)?.accessToken;
  // const isRegisterForm = props.isRegisterForm;
  const isRegisterForm = false;
  useDeepLink();

  return (
    <RootStack.Navigator
      screenOptions={
        {
          // presentation: 'card', ...routeOverlayOption
        }
      }>
      {token || LinkingHelper.isForceOpen() ? (
        <RootStack.Screen
          name={MAIN}
          component={MainStackScreen}
          options={{
            headerShown: false,
          }}
        />
      ) : isRegisterForm ? (
        <RootStack.Screen
          name={Screens.REGISTER_FORM_STACK}
          component={RegisterFormStackScreen}
          options={{
            headerShown: false,
            ...TransitionPresets.ModalFadeTransition,
          }}
        />
      ) : (
        <RootStack.Screen
          name={AUTH}
          component={AuthStackScreen}
          options={{
            headerShown: false,
            // ...TransitionPresets.ModalPresentationIOS,
          }}
        />
      )}
    </RootStack.Navigator>
  );
};
