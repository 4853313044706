import { getUserService } from '@services/user'

// @ts-ignore
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { getUserFailed, getUserSuccess } from './getUserSlice';
import { Constant } from "@constant";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* getUserSaga({ payload }): Generator<
    | CallEffect
    | PutEffect<{
    payload: any;
    type: string;
}>,
    void
    > {
    try {
        const res = yield call(getUserService, payload);
        console.log('getUserSaga>>>>', res);
        if (res?.status) {
            yield put(getUserSuccess(res));
        } else {
            // @ts-ignore
            const msg = res?.message ?? Constant.ERROR_NO_RESPONSE
            yield put(getUserFailed(msg));
        }
        return res
    } catch (err) {
        // @ts-ignore
        yield put(getUserFailed(err));
        return err
    }
}
