import {CommonImages, MarkerDashboardIcon} from '@assets';
import { AppText } from '@components';
import { Device } from '@theme/device';
import { isSvgUrl, isUrl } from '@utils/common/imageUtils';
import React, { memo, useState } from 'react';
import FastImage from 'react-native-fast-image';
import { SvgCssUri } from 'react-native-svg';
import {TouchableOpacity, View} from "react-native";
import { Marker } from 'react-native-maps';
import {FlexImageIcon} from "@screens/main/Menu/Components";
// import {FlexImageIcon} from "@screens/main/PostingLocation/Components/Marker/Marker.view";


export const MarkerComponent = props => {
  const { marker, handleMarkerPress, isSelected, selectedTab } = props;
    const coordinate = {
        latitude: Number(marker.latitude) || 0,
        longitude: Number(marker.longitude) || 0,
    }
    console.log('MarkerComponent>>>', marker, selectedTab, coordinate)

    const [tracksViewChanges, setTracksViewChanges] = useState(false);
  return (
    <Marker

      // style={{ zIndex: isSelected ? 1000 : undefined }}
      coordinate={{
        latitude: Number(marker.latitude) || 0,
        longitude: Number(marker.longitude) || 0,
      }}
      tracksViewChanges={tracksViewChanges}
      // onPress={() => handleMarkerPress(marker)}
      // onSelect={() => Device.isAndroid && handleMarkerPress(marker)}
    >
        <TouchableOpacity onPress={() => handleMarkerPress(marker)}
                          onSelect={() => Device.isAndroid && handleMarkerPress(marker)}
        >
            <FlexImageIcon
                icon={isSelected ? selectedTab.selectedIcon : selectedTab.icon}
                // setTracksViewChanges={setTracksViewChanges}
            />
        </TouchableOpacity>

    </Marker>
  );
};
