import { createAction, createSlice } from '@reduxjs/toolkit';

export interface IuploadFileState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IuploadFileState = {
  loading: false,
  payload: null,
  error: null,
};
export const uploadFileRequest = createAction('uploadFile/uploadFileRequest', (payload, onSuccess, onFailed) => {
  console.log('prepareAction>>>', payload, onSuccess);
  return {
    payload: payload,
    meta: onSuccess,
    error: onFailed,
  };
});
const uploadFileSlice = createSlice({
  name: 'uploadFile',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // uploadFileRequest(state, action) {
    //   state.loading = true;
    //   // @ts-ignore
    //   state.payload = null;
    //   state.error = null;
    // },
    uploadFileSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    uploadFileFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { uploadFileSuccess, uploadFileFailed } = uploadFileSlice.actions;

export default uploadFileSlice.reducer;
