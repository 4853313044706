import { activeUserService } from '@services/user'

// @ts-ignore
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { activeUserFailed, activeUserSuccess } from './activeUserSlice';
import { Constant } from "@constant";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* activeUserSaga({ payload }): Generator<
    | CallEffect
    | PutEffect<{
    payload: any;
    type: string;
}>,
    void
    > {
    try {
        const res = yield call(activeUserService, payload);
        console.log('activeUserSaga>>>>', res);
        if (!isEmpty(res)) {
            yield put(activeUserSuccess(res));
        } else {
            // @ts-ignore
            yield put(activeUserFailed(Constant.ERROR_NO_RESPONSE));
        }
    } catch (err) {
        // @ts-ignore
        yield put(activeUserFailed(err));
    }
}
