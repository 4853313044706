import {checkImportantNotificationRequest, updateFirstTimeLogin} from '@redux/app/appSlice';
import {
  checkStatusOfDashboardRequest,
} from '@redux/app/appSlice';
import { isFirstTimeLogin } from '@redux/app/selectors';
import { getProfileFormRequest } from '@redux/form/getProfileFormSlice';
import { getListSubjectRequest } from '@redux/ticket/getListSubjectSlice';
import { getSettingsRequest } from '@redux/user/getSettingsSlice';
import { getPanelistId } from '@utils';
import { LinkingHelper } from '@utils/common/linking';
import { useEffect, useRef, useState } from 'react';
import { AppState, Platform } from 'react-native';
import SplashScreen from 'react-native-splash-screen';
import { useDispatch, useSelector } from 'react-redux';
import {getSettingsNotification} from "@redux/user/selectors";
import * as RNLocalize from "react-native-localize";
import {showNotificationImportantModal} from "@redux/common/commonSlice";
import {NavigationHelper} from "@utils/common/navigation";
import {useNavigation} from "@react-navigation/native";
import {DASHBOARD_SCREEN, DASHBOARD_TAB} from "@routes/route";

export const useMainStack = props => {
  const dispatch = useDispatch();
  const [showSkillModal, setShowSkillModal] = useState(false);
  const isFirsTime = useSelector(isFirstTimeLogin);
  const navigation = useNavigation();
  console.log('useMainStack>>>', isFirsTime);

  // const settingNotification = useSelector(getSettingsNotification)

  const appState = useRef(AppState.currentState);
  const [appStateVisible, setAppStateVisible] = useState(appState.current);

  useEffect(() => {
    const subscription = AppState.addEventListener('change', nextAppState => {
      if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
        const currentScreen = NavigationHelper.getCurrentScreenOfMainStack(navigation);
        console.log('AppState>>>active>>>', currentScreen, navigation);
        if (currentScreen === DASHBOARD_TAB || currentScreen === DASHBOARD_SCREEN) {
          checkImportantNotification();
        }
      }

      appState.current = nextAppState;
      setAppStateVisible(appState.current);
      console.log('AppState', appState.current);
    });

    return () => {
      subscription.remove();
    };
  }, []);

  const checkImportantNotification = () => {
    dispatch(checkImportantNotificationRequest({}, res => {
      //TODO: show popup here
      console.log('checkImportantNotificationRequest>>>', res)
      if(!!res?.data?.internalId){
        dispatch(showNotificationImportantModal())
      }
    }, err => {

    }))
  }

  const syncData = () => {
    const payload = {};
    if (LinkingHelper.isForceOpen()) {
      return;
    }
    dispatch(getProfileFormRequest({ panelistId: getPanelistId() }));
    dispatch(getListSubjectRequest(payload));
    dispatch(getSettingsRequest(payload));
    checkImportantNotification()

    dispatch(getSettingsRequest(payload));

    // dispatch(checkStatusOfDashboardRequest({}, res => {
    //   console.log('checkStatusOfDashboardRequest>>>', res)
    // }, err => {
    //
    // }))
  };
  useEffect(() => {
    if (Platform.OS !== 'web') {
      SplashScreen.hide();
    }
    syncData();
  }, []);

  //NOTE: don't need show modal quizz
  // useEffect(() => {
  //   if (isFirsTime) {
  //     setShowSkillModal(true);
  //     setTimeout(() => {
  //       setShowSkillModal(false);
  //       //TODO:
  //       dispatch(updateFirstTimeLogin({ isFirstTimeLogin: false }));
  //     }, 5000);
  //   }
  // }, [isFirsTime]);
  return { showSkillModal, setShowSkillModal, isFirsTime };
};
