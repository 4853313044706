import { createAction, createSlice } from '@reduxjs/toolkit';

export interface IsaveFeedbackD2DState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IsaveFeedbackD2DState = {
  loading: false,
  payload: null,
  error: null,
};

export const saveFeedbackD2DRequest = createAction(
  'saveFeedbackD2D/saveFeedbackD2DRequest',
  (payload, onSuccess, onFailed) => {
    console.log('prepareAction>>>', payload, onSuccess);
    return {
      payload: payload,
      meta: onSuccess,
      error: onFailed,
    };
  },
);

const saveFeedbackD2DSlice = createSlice({
  name: 'saveFeedbackD2D',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // saveFeedbackD2DRequest(state, action) {
    //   state.loading = true;
    //   // @ts-ignore
    //   state.payload = null;
    //   state.error = null;
    // },
    saveFeedbackD2DSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    saveFeedbackD2DFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { saveFeedbackD2DSuccess, saveFeedbackD2DFailed } = saveFeedbackD2DSlice.actions;

export default saveFeedbackD2DSlice.reducer;
