import {
  AppButton,
  AppDateTimePicker,
  AppInput,
  AppKAView,
  AppKAViewV2,
  AppMultipleSelection,
  AppPicker,
  AppSafeAreaView,
  AppSingleSelection,
  AppText,
} from '@components';
import { FormItemView } from '@components/Form';
import GenericHeader from '@components/GenericHeader';
import { LoadingView } from '@components/Loading/Loading.view';
import { FORM_TYPE } from '@constant/model/RegisterForm';
import theme, { globalStyle } from '@theme';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { Font, FontSize, Spacing } from '@theme/size';
import { formatDate } from '@utils/common/MomentUtils';
import { getErrorForm } from '@utils/form';
import { getContentTitle } from '@utils/transform/form';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, ScrollView, KeyboardAvoidingView } from 'react-native';
import { KeyboardType, Platform } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useRegisterForm } from './RegisterForm.hook';
import { RegisterFormStyles } from './RegisterForm.style';

export const RegisterFormScreen = props => {
  const { t } = useTranslation();
  // const {step} =
  const { isLastStep, onNextPress, step, data, isFirstStep, onBackPress, formik, updateUserWithLoading } =
    useRegisterForm(props);

  return (
    <AppSafeAreaView areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>
      <GenericHeader
        onBackClicked={isFirstStep ? null : onBackPress}
        title={getContentTitle(data.title)}
        style={[{ backgroundColor: palette.BACKGROUND_GREY }]}
      />
      <View style={[globalStyle.directionColumm, globalStyle.flex1, RegisterFormStyles.containerView]}>
        <KeyboardAwareScrollView
          // style={[globalStyle.flex1, { flexGrow: 1 }]}
          style={[]}
          contentContainerStyle={{ flexGrow: 1 }}
          showsVerticalScrollIndicator={false}
          keyboardShouldPersistTaps="handled">
          <AppText
            style={[
              globalStyle.alignCenter,
              globalStyle.justifyCenter,
              RegisterFormStyles.paddingSpace,
              RegisterFormStyles.descriptionText,
              { marginVertical: data.description ? theme.spacing.md : 0 },
            ]}>
            {data.description}
          </AppText>

          <View style={[globalStyle.directionColumm, RegisterFormStyles.paddingSpace, globalStyle.flex1]}>
            {data?.attributes?.map((item, index) => {
              return (
                <FormItemView formik={formik} item={item} index={index} isBoldTitle={true} checkCondition={true} />
              );
            })}
          </View>
          <View
            style={[
              RegisterFormStyles.paddingSpace,
              { marginVertical: theme.spacing.sm, marginBottom: Platform.OS === 'web' ? '20px' : Spacing.height25 },
            ]}>
            <AppButton
              text={isLastStep ? t('REGISTER_FORM_SAVE') : t('REGISTER_FORM_NEXT')}
              onPress={formik.handleSubmit}
            />
          </View>
        </KeyboardAwareScrollView>
      </View>
    </AppSafeAreaView>
  );
};
