import { Constant } from '@constant';
import { palette } from '@theme/colors';
import { StyleSheet } from 'react-native';

export const AppAddressStyle = StyleSheet.create({
  container: {
    width: '100%',
  },
  textTitle: {
    fontWeight: '400',

    marginBottom: 10,
    color: '#333333',
  },
  viewSelect: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#C6C6C6',
    marginHorizontal: 0,
    // backgroundColor: '#D5D5D5',
    paddingHorizontal: 12,
    paddingVertical: 10,
    borderRadius: 4,
    minHeight: Constant.MAIN_HEIGHT, //42
    opacity: 0,
  },
  isRequired: {
    color: '#FF5757',
  },
  textAfterSelect: {
    flex: 1,
    fontWeight: '400',

    color: '#333333',
    // marginLeft: 12,
    // textTransform: 'capitalize',
  },
  textSelect: {
    flex: 1,
    fontWeight: '400',

    color: '#999999',
    // marginLeft: 12,
    // textTransform: 'capitalize',
  },
  modalContent: {
    paddingHorizontal: 16,
  },
  header: {
    backgroundColor: '#F7F7F7',
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    minHeight: 43,
  },
  headerTxt: {
    fontWeight: '700',

    color: '#000000',
    paddingHorizontal: 15,
    paddingVertical: 12,
  },
  hint: {
    fontWeight: '400',
    color: '#999999',
    marginTop: 7,
  },
  error: {
    marginTop: 7,

    color: palette.ROYAL_MAIL_RED,
    fontWeight: '400',
  },
});
