import { FORM } from '@constant/model/RegisterForm';
import { useNavigation, useRoute } from '@react-navigation/native';
import { getCDSFormRequest } from '@redux/form/getCDSFormSlice';
import { getCDSFormCreator } from '@redux/form/selectors';
import { goBack } from '@routes/navigationUtils';
import { checkFormValidateExpression, getInitialFormValue, getYupStructure } from '@utils/form';
import { CDSTransform } from '@utils/transform/cds';
import { FormTransform, mappingStep, mappingStepOrigin } from '@utils/transform/form';
import { useFormik } from 'formik';
import _ from 'lodash';
import {useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import format from 'string-format';
import { lazy } from 'yup';
import * as Yup from 'yup';
import {ViewUtil} from "@utils/common/view";
import {FormikHelper} from "@utils/hooks/formik";

export const useCDSSubForm = navigation => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const params = useRoute().params;
  const scrollViewRef = useRef(null);

  const formData = {
    attributes: mappingStepOrigin(params?.data?.subForms),
  };

  const extraFormValues = params.extraFormValues;

  const modelData = params?.formData ?? {};
  const extraData = params?.extraData ?? {};
  const onSubmitSuccess = params?.onSubmitSuccess;
  console.log('useCDSSubForm>>>', formData, useRoute().params);
  const [data, setData] = useState(formData);

  // const getCDSFormData = useSelector(getCDSFormCreator)?.data;
  // const getCDSFormWithLoading = useSelector(getCDSFormLoading);
  // const getCDSFormWithErrorMsg = useSelector(getCDSFormErrorMsg);

  const getCDSFormApi = () => {
    const payload = {};
    dispatch(getCDSFormRequest(payload));
  };

  const checkSubForm = (extraFormValues, formValues, setErrors, formStructure = []) => {
    const listExp = CDSTransform.getListExpressionSubForm(formValues, extraFormValues, modelData?.cloneId, formStructure);
    console.log('checkSubForm>>>', listExp, modelData, formStructure)

    return FormTransform.checkCustomCondition(
      FORM.FORM_ELEMENT_NAME.number_of_same_details,
      formValues,
      listExp,
      setErrors,
    );
  };

  const scrollToEnd = (timeout = 200) => {
    ViewUtil.scrollToEnd(scrollViewRef.current, timeout, false);
  };

  const formik = FormikHelper.useFormikWrapper({
    initialValues: {
      ...getInitialFormValue(data, {...modelData,...extraFormValues}),
    },
    enableReinitialize: true,
    dirty: false,
    // validationSchema: Yup.object().shape(getYupStructure(rawData, t)),
    validationSchema: () =>
      lazy(vl => {
        const values = _.cloneDeep(formik.values);

        console.log('lazy>>>values>>>', vl, values);
        const ys = getYupStructure(data, t, 'attributes', { ...extraFormValues, ...values });
        console.log('getYupStructure>>>', ys, formik);
        return Yup.object().shape(ys);
      }),
    initialErrors: true,
    onSubmit: (values, { setErrors, resetForm }) => {
      console.log('onSubmit>>>', values, formik.errors, data, onSubmitSuccess);
      const rs = checkFormValidateExpression(data?.attributes, {...extraFormValues, ...values}, setErrors);
      if (!_.isEmpty(rs)) {
        return;
      }
      const isValid = checkSubForm(extraFormValues, {...extraFormValues, ...values}, setErrors, formData.attributes);
      if (!isValid) {
        scrollToEnd()
        return;
      }
      console.log("onSubmit>>>>>>checkSubForm>>>", isValid, onSubmitSuccess)
      // return;

      goBack();
      if (onSubmitSuccess) {
        onSubmitSuccess(values);
      }
      //TODO: validate here
      // updateUserApi(values, isLastStep);
      // onSavePress();
    },
  }, formData?.attributes);
  useEffect(() => {
    // getCDSFormApi();
  }, []);

  // useEffect(() => {
  //   const x = _.cloneDeep(getCDSFormData);
  //   const rd = mappingStep(!_.isEmpty(x) ? x[0] : null);
  //   setData(rd);
  //   console.log('getCDSFormData>>>', rd);
  // }, [getCDSFormData]);
  return { data, formik, extraFormValues, scrollViewRef, scrollToEnd };
};
