import { LogoIcon, MessageCheckIcon } from '@assets';
import {
  AppButton,
  AppDateTimePicker,
  AppInput,
  AppKAView,
  AppMultipleSelection,
  AppPicker,
  AppSafeAreaView,
  AppSingleSelection,
  AppText,
} from '@components';
import GenericHeader from '@components/GenericHeader';
import { FORM, FORM_TYPE } from '@constant/model/RegisterForm';
import { QuizFailedStyle } from '@screens/main/quiz/QuizFailed/QuizFailed.style';
import { AppNoteView } from '@screens/main/quiz/QuizFailed/components/AppNote.view';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font, Spacing } from '@theme/size';
import { formatDate } from '@utils/common/MomentUtils';
// import GestureRecognizer, { swipeDirections } from '@utils/common/gesture';
import { getErrorForm } from '@utils/form';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, ScrollView } from 'react-native';
import { Platform } from 'react-native';
import {
  FlingGestureHandler,
  Directions,
  State,
  FlingGestureHandlerStateChangeEvent,
} from 'react-native-gesture-handler';
import { useQuizDetail } from './QuizDetail.hook';
import { QuizDetailStyle } from './QuizDetail.style';

export const QuizDetailScreen = props => {
  const { t } = useTranslation();
  const {
    isLastStep,
    onSavePress,
    onNextPress,
    step,
    data,
    isFirstStep,
    onBackPress,
    formik,
    headerTitle,
    onSwipeLeft,
    onSwipeRight,
  } = useQuizDetail(props);
  // const config = {
  //   velocityThreshold: 0.3,
  //   directionalOffsetThreshold: 80,
  //   // activeOffsetX: {[-5, 5]}
  // };
  const renderItem = (item, index) => {
    const key = `${item._id}_${index}`;
    // console.log('renderItem>>>', item, index);
    switch (item.type) {
      case FORM.FORM_TYPE.hidden:
        return <></>;

      case FORM.FORM_TYPE.dropdown:
      case FORM.FORM_TYPE.singlechoice:
      case FORM.FORM_TYPE.stringlistsingle:
        if (item.popup === false) {
          return (
            <AppSingleSelection
              key={key}
              style={RegisterFormStyles.formItemView}
              data={item.data}
              selected={formik.values[item._id] ? formik.values[item._id] : []}
              title={item?.title}
              isRequired={item.required}
              onSelect={i => {
                console.log('AppSingleSelection>>>onSelect>>>', item, i, item._id, i?._id);
                formik.setFieldValue(item._id, i);
              }}
              isBoldTitle
              error={getErrorForm(formik.errors, formik.touched, item._id)}
            />
          );
        }
        return (
          <AppPicker
            key={key}
            data={item.data}
            style={RegisterFormStyles.formItemView}
            selected={formik.values[item._id]}
            isRequired={item.required}
            title={item?.title}
            header={item?.title}
            onSelect={i => {
              console.log('onSelect>>>', item._id, i, i?._id);
              formik.setFieldValue(item._id, i);
            }}
            isBoldTitle
            error={getErrorForm(formik.errors, formik.touched, item._id)}
          />
        );
      case FORM.FORM_TYPE.MultipleSelection:
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return (
          <AppMultipleSelection
            key={key}
            style={RegisterFormStyles.formItemView}
            data={item.data}
            selected={formik.values[item.id] ? formik.values[item.id] : []}
            title={item?.title}
            isRequired={item.required}
            onSelect={i => {
              console.log('AppSingleSelection>>>onSelect>>>', item, i, item.id, i?.id);
              formik.setFieldValue(item.id, i);
            }}
            isBoldTitle
            error={getErrorForm(formik.errors, formik.touched, item.id)}
          />
        );
      case FORM.FORM_TYPE.SingleSelection:
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return (
          <AppSingleSelection
            key={key}
            style={RegisterFormStyles.formItemView}
            data={item.data}
            selected={formik.values[item.id] ? formik.values[item.id] : []}
            title={item?.title}
            isRequired={item.required}
            onSelect={i => {
              console.log('AppMultipleSelection>>>onSelect>>>', item, i, item.id, i?.id);
              formik.setFieldValue(item.id, i);
            }}
            isBoldTitle
            error={getErrorForm(formik.errors, formik.touched, item.id)}
          />
        );
      case FORM.FORM_TYPE.note:
        return <AppNoteView key={key} style={QuizDetailStyle.formItemView} title={item?.title} />;
    }
    return <></>;
  };

  // const onSwipeLeft = x => {
  //   console.log('onSwipeLeft>>>', x);
  //   //TOOD: next screen
  // };
  //
  // const onSwipeRight = x => {
  //   console.log('onSwipeRight>>>', x);
  //   //TODO: goback
  //
  // };
  return (
    <AppSafeAreaView areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>
      {/*<AppKAView>*/}
      <GenericHeader
        onBackClicked={onBackPress}
        title={headerTitle}
        style={[{ backgroundColor: palette.BACKGROUND_GREY }]}
      />
      <FlingGestureHandler
        hitSlop={{ left: Platform.OS === 'ios' ? -50 : 0 }}
        direction={Directions.LEFT}
        onEnded={x => {
          onSwipeLeft(x);
        }}>
        <FlingGestureHandler
          // config={}
          // hitSlop={{ left: -50 }}
          direction={Directions.RIGHT}
          // numberOfPointers={2}
          onEnded={x => {
            console.log('onBegan>>>RIGHT>>>', x);
            onSwipeRight(x);
          }}>
          <View style={[globalStyle.directionColumm, globalStyle.flex1, RegisterFormStyles.containerView]}>
            <ScrollView style={{ marginTop: 10 }} keyboardShouldPersistTaps="handled">
              <View style={[globalStyle.directionColumm, RegisterFormStyles.paddingSpace]}>
                {data?.map((item, index) => {
                  return renderItem(item, index);
                })}
              </View>
            </ScrollView>
            <View style={[RegisterFormStyles.paddingSpace, { marginVertical: theme.spacing.sm }]}>
              <AppButton
                style={{
                  marginBottom: Spacing.height25,
                }}
                text={isLastStep ? t('REGISTER_FORM_SAVE') : t('REGISTER_FORM_NEXT')}
                onPress={formik.handleSubmit}
              />
            </View>
          </View>
        </FlingGestureHandler>
      </FlingGestureHandler>
    </AppSafeAreaView>
  );
};
