import { Constant, REPORT_TYPE } from '@constant';
import { isEmpty } from 'lodash';
import _ from 'lodash';
import { getContentTitle } from '../form';

export const mappingReportModal = data => {
  if (!data || isEmpty(data)) return [];
  const transformedData = data?.map(item => {
    const childData = item?.data?.map(childItem => {
      return {
        ...childItem,
        name: getContentTitle(childItem?.name),
      };
    });
    return {
      ...item,
      title: getContentTitle(item?.title),
      data: childData,
    };
  });
  return transformedData;
};

export const ReportModalTransform = {
  getReportType: internalId => {
    switch (internalId) {
      case Constant.menu.posting_package:
        return REPORT_TYPE.packages;
      case Constant.menu.report_test_items:
        return REPORT_TYPE.icodes;
      case Constant.menu.report_bundle_dispatch:
        return REPORT_TYPE.bundles;
    }
  },

  getReportTitle: (reportType, formData) => {
    if (_.isEmpty(reportType) || _.isEmpty(formData)) {
      return '';
    }
    let allData = [];
    formData.map(item => {
      allData = [...allData, ...item.data];
    });
    let internId = '';
    switch (reportType) {
      case REPORT_TYPE.bundles:
        internId = Constant.menu.report_bundle_dispatch;
        return getContentTitle(allData.find(x => x.internalId === internId)?.name);
        break;
      case REPORT_TYPE.packages:
        internId = Constant.menu.posting_package;
        return getContentTitle(allData.find(x => x.internalId === internId)?.name);

        break;
      case REPORT_TYPE.icodes:
        internId = Constant.menu.report_test_items;
        return getContentTitle(allData.find(x => x.internalId === internId)?.name);

        break;
    }
    return '';
  },
};
