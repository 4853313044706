import _, { isEmpty } from 'lodash';

export const mappingTimeline = data => {
  if (!data || isEmpty(data)) return [];
  const _data = _.cloneDeep(data);
  const count = _data.length
  for (let i = 0; i < count; i++) {
    // data[i].isLast =
    const item = _data[i]
    if(i === 0){
      //NOTE: using for show first title
      item.isFirst = true
    }
    // const next =
    if(i + 1 <= count -1){
      const next = _data[i+1]
      _data[i].isLast = next.status !== item.status
    }
  }

  const statusArr = _.uniqBy(_data, 'status')?.map(item => {
    return item?.status;
  });

  statusArr?.forEach(status => {
    const findingData = _data?.find(item => item.status === status);
    findingData.title = `TIMELINE_${findingData?.status}`.toUpperCase();
  });

  return _data;
};
