import { Config } from '@constant/config';
import { LoginMockData } from '@constant/model/mock/loginMockData';
import { deviceToken as deviceTokenSelector } from '@redux/app/selectors';
import { getLoginRequest } from '@redux/auth/authSlice';
import { authUser } from '@redux/auth/selectors';
import {hideLoading, showLoading} from '@redux/global/globalSlice';
import {isFirstTimeApp, navigate} from '@routes/navigationUtils';
import {SIGN_UP_WITH_NAME_SCREEN, FORGOT_SEND_EMAIL, Screens, FORGOT_SUCCESS} from '@routes/route';
import { AppNotification } from '@utils';
import { showErrorMsg } from '@utils/api';
import { useFormik } from 'formik';
import { t } from 'i18next';
import {useEffect, useState} from 'react';
import { getHashPassword } from '@utils/common/passwordUtils';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import {SuccessMessageMock} from "@constant/model/mock/successMessage";
import {StackActions, useNavigation} from "@react-navigation/native";
import {BackHandler, Platform} from "react-native";

const validationSchema = Yup.object().shape({
  username: Yup.string().required(t('EMAIL_IS_REQUIRED')).email(t('EMAIL_IS_INVALID_FORMAT')),
  password: Yup.string().required(t('PASSWORD_IS_REQUIRED')),
});

export const useLogic = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const email = useSelector(authUser)?.email;
  const deviceToken = useSelector(deviceTokenSelector);
  function handleBackButtonClick() {
    console.log('handleBackButtonClick>>>')
    isFirstTimeApp.current = true;
    BackHandler.exitApp()
    return true;
  }
  useEffect(() => {
    const backHandler = BackHandler.addEventListener('hardwareBackPress', handleBackButtonClick)
    return () => backHandler.remove()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      dispatch(hideLoading())
    }, 3000)
  }, [])

  const formik = useFormik({
    initialValues: {
      username: Config.IsDebug ? LoginMockData.username : email || '',
      password: Config.IsDebug ? LoginMockData.password : '',
    },
    onSubmit: values => {
      handleLogin(values);
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const [rememberMe, setRememberMe] = useState(1);

  const handleToggleRememberMe = id => {
    setRememberMe(prev => (prev ? 0 : id));
  };

  const handleSignUpPress = () => {
    navigate(SIGN_UP_WITH_NAME_SCREEN);
    // navigate(Screens.ACTIVE_SUCCESSFUL_SCREEN, { data: SuccessMessageMock})
    // navigate(Screens.REGISTER_COMPLETED_SCREEN, { data: SuccessMessageMock})
    // navigate(FORGOT_SUCCESS, { data: SuccessMessageMock})
    // navigation.dispatch(StackActions.replace(FORGOT_SUCCESS, { data: SuccessMessageMock }));
  };

  const handleForgotPress = () => {
    navigate(FORGOT_SEND_EMAIL);
  };

  const handleLogin = async values => {
    try {
      const hashedPassword = await getHashPassword(values.password);
      const token = await AppNotification.getToken();
      dispatch(
        getLoginRequest({
          email: values.username,
          password: hashedPassword,
          device_token: deviceToken || token,
          deviceType: Platform.OS,
          rememberMe,
        }),
      );
    } catch (error) {
      showErrorMsg(error);
    } finally {
      formik.setSubmitting(false);
    }
  };

  return { rememberMe, handleToggleRememberMe, handleSignUpPress, handleForgotPress, handleLogin, formik };
};
