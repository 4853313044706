import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useEmptyingTime = props => {
  const dispatch = useDispatch();
  const selectedDate = props.selectedDate;
  const value = props.value;
  // const data = []

  const getSelectedValue = (strDate, lsValue) => {
    if (_.isEmpty(lsValue) || _.isEmpty(strDate)) {
      return [];
    }
    const date = moment(strDate); // Thursday Feb 2015
    const index = date.day() - 1;
    console.log('getSelectedValue>>>', index);
    try {
      const item = lsValue.find(x => x.index === index);
      if(_.isEmpty(item)){
        return []
      }
      return [item];
    } catch (e) {
      return [];
    }
  };

  //NOTE: dont' need filter by selectedDate
  // const data = getSelectedValue(selectedDate, value);
  const data = value;

  console.log('useEmptyingTime>>>', data, props);
  useEffect(() => {}, [dispatch]);
  return { data };
};
