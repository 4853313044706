import { Constant } from '@constant';
import theme from '@theme';
import { palette } from '@theme/colors';
import { FontSize, Spacing } from '@theme/size';
import { StyleSheet, Platform } from 'react-native';

export const DailyItemStyle = StyleSheet.create({
  rootContainer: {
    borderTopRightRadius: Spacing.width20,
    borderTopLeftRadius: Spacing.width20,
    backgroundColor: palette.WHITE,
  },
  container: {
    paddingHorizontal: Spacing.width15,
    flexGrow: 1,
    flex: 1,
    // borderTopRightRadius: Spacing.width20,
    // borderTopLeftRadius: Spacing.width20,
    // backgroundColor: palette.WHITE,

    alignItems: 'center',
    paddingTop: Spacing.height15,
  },
  containerEmpty: {
    flexGrow: 1,
    flex: 1,
    alignItems: 'center',
    paddingTop: Spacing.height15,
  },
  itemView: {
    backgroundColor: '#B1E4CF',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: Platform.OS === 'web' ? '20px' : Spacing.width15,
    paddingVertical: 3,
    height: Constant.MAIN_HEIGHT,
    // borderWidth: 1,
    // borderColor: '#C6C6C6',
    borderRadius: 4,
    marginBottom: Spacing.width15,
  },
  itemViewDisable: {
    backgroundColor: '#EEEEEE',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: Platform.OS === 'web' ? '20px' : Spacing.width15,
    paddingVertical: 3,
    height: Constant.MAIN_HEIGHT,
    // borderWidth: 1,
    // borderColor: '#C6C6C6',
    borderRadius: 4,
    marginBottom: Spacing.width15,
  },
  itemText: {
    paddingHorizontal: Spacing.width3,
    color: palette.MAIN_BLACK,
  },
  textInput: {
    fontSize: FontSize.Font15,
    color: '#333333',
    fontWeight: '400',
    height: Platform.OS === 'web' ? '44px' : undefined,
    outlineStyle: Platform.OS === 'web' ? 'none' : undefined,
    // width: Platform.OS === 'web' ? 200 : undefined,
  },

  box: {
    height: '100%',
    // flex: 1,
    // flexDirection: 'row',
    // width: '100%',
    alignItems: 'center',
    textAlignVertical: 'center',
    // justifyContent: 'space-between',
    // height: Constant.MAIN_HEIGHT, //42
    borderRadius: 2,
    // borderWidth: 1,
    // borderColor: 'clean',
    backgroundColor: 'white',
    margin: 3,
    paddingRight: theme.spacing.sm,
  },
});
