import { MessageCheckIcon } from '@assets';
import { AppSafeAreaView, AppText } from '@components';
import theme, { globalStyle } from '@theme';
import { palette } from '@theme/colors';
import { Font, FontSize } from '@theme/size';
import React from 'react';
import { Text, View } from 'react-native';

export const AuthSuccessScreen = props => {
  const { title, desc } = props;
  return (
    <AppSafeAreaView areaStyle={[globalStyle.flex1, globalStyle.alignCenter, globalStyle.justifyCenter]}>
      {/*<Icon name="comment-dots" size={40} color={palette.ROYAL_MAIL_RED} />*/}
      <MessageCheckIcon color={palette.GREEN_ACTIVE} />
      <View
        style={[
          globalStyle.alignCenter,
          globalStyle.justifyCenter,
          globalStyle.alignSelfCenter,
          globalStyle.directionColumm,
        ]}>
        <AppText style={[Font.Bold_700, { textAlign: 'center', margin: theme.spacing.sm, fontSize: FontSize.Font15 }]}>
          {title}
        </AppText>
        <AppText
          style={[
            { textAlign: 'center', marginHorizontal: theme.spacing.sm, fontSize: FontSize.Font15, color: '#666666' },
          ]}>
          {desc}
        </AppText>
      </View>
    </AppSafeAreaView>
  );
};
