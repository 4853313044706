import { AppKAView, AppSafeAreaView } from '@components';
import GenericHeader from '@components/GenericHeader';
import { POSTING_LOCATION } from '@constant';
import { goBack } from '@routes/navigationUtils';
import { Device } from '@theme/device';
import { calcDistance } from '@utils/common/locationUtils';
import React from 'react';
import { StatusBar, View } from 'react-native';
import MapView from 'react-native-maps'

import { Details, Footer, MarkerComponent } from './Components';
import { TabBar } from './Components/Tab';
import { useLogic } from './PostingLocation.hook';
import { styles } from './PostingLocation.style';
import {Config} from "@constant/config";
// import scriptLoader from 'react-async-script-loader';


const mapStyle = [
  {
    // featureType: 'poi.business',
    // stylers: [{ visibility: 'off' }],
  },
];

const PostingLocation = (props) => {
  const {
    selectedTab,
    setSelectedTab,
    onItemPress,
    region,
    mapViewRef,
    setSelectedMarker,
    handleMarkerPress,
    selectedMarker,
    t,
    mapSettings,
    handleLoadMore,
    markers,
    regionRef,
  } = useLogic(props);

  return (
      <>
        <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
        <AppSafeAreaView areaStyle={styles.areaStyle}>
          <GenericHeader
              title={t('POSTING_LOCATIONS')}
              style={[{ backgroundColor: '#EDEDED' }]}
              onBackClicked={() => {
                goBack();
              }}
          />
          <AppKAView>
            <View style={[styles.containerViewWeb]}>
              {region && selectedTab && (
                  <>
                    <View style={styles.mapWeb}>
                      <MapView
                          ref={mapViewRef}
                          // provider={PROVIDER_GOOGLE}
                          provider="google"

                          // style={styles.map}
                          onPress={e => {
                            console.log('map>>>press>>>',e)
                            if (e.nativeEvent.action !== 'marker-press') {
                              setSelectedMarker(undefined);
                              // pressed the map
                            } else {
                              // pressed a marker
                            }
                          }}
                          // mapType='standard'
                          onPoiClick={() => {
                            setSelectedMarker(undefined);
                          }}
                          region={{region}}
                          onRegionChange={regionValue => {
                            handleLoadMore(regionValue);
                          }}
                          initialRegion={region}
                          customMapStyle={mapStyle}
                          // minZoomLevel={10} // default => 0
                          maxZoomLevel={17}
                          initialCamera = {{
                            zoom: 13,
                            center: region
                          }}
                          rotateEnabled={false}
                          googleMapsApiKey={Config.Api.googleApiKey}
                          // followsUserLocation = {true}
                          // zoomControlEnabled={false}
                          // liteMode={true}
                          // showsMyLocationButton={}
                          mapPadding ={
                              {
                              top: -10,
                              right: 0,
                              bottom: 10,
                              left: 0
                              }
                            }
                          // toolbarEnabled={false}
                          showsUserLocation>
                        {markers.map((marker, index) => {
                          return (
                              <MarkerComponent
                                  marker={marker}
                                  handleMarkerPress={handleMarkerPress}
                                  isSelected={
                                    selectedMarker?.latitude === marker?.latitude &&
                                    selectedMarker?.longitude === marker?.longitude
                                  }
                                  selectedTab={selectedTab}
                              />
                          );
                        })}
                      </MapView>
                    </View>
                    <TabBar selectedTab={selectedTab} onItemPress={onItemPress} tabs={mapSettings?.postTypes} />
                    {!!selectedMarker && <View style={{
                      alignItems: 'center',
                    }}> <Details data={selectedMarker} region={region} /> </View>}
                    <View style={{backgroundColor:'#EDEDED'}}>
                      <Footer data={markers?.length} />
                    </View>
                  </>
              )}
            </View>
          </AppKAView>
          {/* <AuthFooter /> */}
        </AppSafeAreaView>
      </>
  );
};

export { PostingLocation };
