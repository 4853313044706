import { useRoute } from '@react-navigation/native';
import { getD2DItemsRequest } from '@redux/survey/getD2DItemsSlice';
import {
  getD2DItemsCreator,
  getD2DItemsSelector,
  getD2DItemsError,
  getD2DItemsErrorMsg,
  getD2DItemsLoading,
} from '@redux/survey/selectors';
import { navigate } from '@routes/navigationUtils';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Screens} from "@routes/route";
import _ from 'lodash'
import {globalLoading} from "@redux/global/selectors";
import {hideLoading} from "@redux/global/globalSlice";

export const useOverDue = props => {
  const dispatch = useDispatch();
  const selectorData = useSelector(getD2DItemsCreator)?.data
  const getD2DItemsData = _.cloneDeep(selectorData?.items) ?? [];
  const loading = useSelector(globalLoading);

  const description = selectorData?.description;
  const paramsData = useRoute().params?.data;
  const [visible, setIsVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);
  const onItemPress = item => {
    navigate(Screens.D2D_FORM, { data: paramsData, extraData: item });
  };

  const onItemZoom = item => {
    setSelectedImage([{ uri: item.image, url: item.image }]);
    setIsVisible(true);
  };
  const getD2DItemsApi = () => {
    /*
  params:
  {
      "spectos-apikey": "{{spectos-apikey}}",
      "Token": "fbbfc346de1ba3c5ab642b3496aef98974cfcd6d3be93b44c7b7eaa27354b675"
  }
  body:
  {}
   */
    const payload = {
      ...paramsData,
    };
    dispatch(getD2DItemsRequest(payload));
  };

  useEffect(() => {
    getD2DItemsApi();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      dispatch(hideLoading())
    }, 3000)
  }, [])

  return {
    onItemPress,
    onItemZoom,
    getD2DItemsData,
    visible,
    setIsVisible,
    paramsData,
    selectedImage,
    description,
    loading
  };
};
