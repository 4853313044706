import { FileTransform } from '@utils/transform/file';
import React, { useEffect, useState } from 'react';
import { Image } from 'react-native';
import { LoadingView } from '@components/Loading/Loading.view';

/**
 * An empty component used to manage SplashScreenWeb from Suspense fallback
 */
const FastImage = React.memo(props => {
  // const { onLoadedUri } = useAppImagePicker(props);
  // const header = props?.source?.header
  const [uri, setUri] = useState(!!props.source?.headers ? '' : props.source?.uri);
  const [loading, setLoading] = useState(false);

  console.log('FastImage>>>>', props);

  const loadData = async () => {
    try {
      console.log('FastImage>>>loadData>>>', props)
      setLoading(true);
      const rs = await FileTransform.getImageFdbAsyncUrl(props.source?.uri);
      setUri(rs.uri);
      setLoading(false);
      if (props.onLoaded) {
        props.onLoaded(rs.uri);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props.source?.headers && !uri) {
      loadData();
    }
  }, [props.source?.headers]);

  return (
    // <AppLazyImage
    //     style={props?.style}
    //     url={props?.item?.link}
    //     isS3={false}
    //     prefix={false}
    //     resizeMode={'cover'}
    //     onLoaded={uri => {
    //         onLoadedUri(props?.item, uri)
    //     }}
    // />
    // <Image style={props.style} source={props.source} resizeMode={props.resizeMode}/>
    <>
      {loading && <LoadingView />}
      <Image source={{ uri: uri }} style={props.style} resizeMode={props.resizeMode} />
    </>
  );
});

export default FastImage;
