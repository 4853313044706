import {createAction, createSlice} from '@reduxjs/toolkit';

export interface IchangeEmailState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IchangeEmailState = {
  loading: false,
  payload: null,
  error: null,
};

export const changeEmailRequest = createAction(
    'changeEmail/changeEmailRequest',
    (payload, onSuccess, onFailed) => {
      console.log('prepareAction>>>', payload, onSuccess);
      return {
        payload: payload,
        meta: onSuccess,
        error: onFailed,
      };
    },
);

const changeEmailSlice = createSlice({
  name: 'changeEmail',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // changeEmailRequest(state, action) {
    //   state.loading = true;
    //   // @ts-ignore
    //   state.payload = null;
    //   state.error = null;
    // },
    changeEmailSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    changeEmailFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { changeEmailSuccess, changeEmailFailed } = changeEmailSlice.actions;

export default changeEmailSlice.reducer;
