import { createSlice } from '@reduxjs/toolkit';

export interface IgetD2DItemsState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IgetD2DItemsState = {
  loading: false,
  payload: null,
  error: null,
};

const getD2DItemsSlice = createSlice({
  name: 'getD2DItems',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getD2DItemsRequest(state, action) {
      state.loading = true;
      // @ts-ignore
      state.payload = null;
      state.error = null;
    },
    getD2DItemsSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    getD2DItemsFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getD2DItemsRequest, getD2DItemsSuccess, getD2DItemsFailed } = getD2DItemsSlice.actions;

export default getD2DItemsSlice.reducer;
