import { MessageCheckIcon, CrossIcon, WarningIcon } from '@assets';
import { AppAlert, AppText, AppButton } from '@components';
import { ForceUpdateStyles } from '@components/AppForceUpdate/ForceUpdate.style';
import theme, { globalStyle } from '@theme';
import { deviceWidth, Font, FontSize } from '@theme/size';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';
import { useDeeplinkModal } from './DeeplinkModal.hook';

export const DeeplinkModal = props => {
  const { show } = props;
  const { handleLogout, handleHideModal } = useDeeplinkModal(props);
  const { t } = useTranslation();
  return (
    <AppAlert
      onDismiss={handleHideModal}
      closeOnTouchOutside
      show={show}
      contentContainerStyle={{ maxWidth: '92%' }}
      customView={
        <View style={{ paddingHorizontal: 8 }}>
          <TouchableOpacity
            onPress={handleHideModal}
            style={{
              position: 'absolute',
              top: 6,
              right: 6,
              backgroundColor: '#c9c9c9',
              zIndex: 1,
              padding: 6,
              borderRadius: 1000,
            }}>
            <CrossIcon color="white" />
          </TouchableOpacity>
          <View style={[globalStyle.directionColumm, { marginTop: 24 }]}>
            <View style={[globalStyle.center]}>
              <WarningIcon width={45} height={45} />
            </View>
            <View style={[globalStyle.center, { paddingVertical: 16 }]}>
              <AppText style={[Font.Bold_700, globalStyle.center]}>{t('DEEPLINK_MODAL_TITLE')}</AppText>
              <AppText
                style={[
                  {
                    fontSize: FontSize.Font15,
                    paddingVertical: 8,
                    textAlign: 'center',
                    color: '#666666',
                    maxWidth: '85%',
                  },
                ]}>
                {t('DEEPLINK_MODAL_DESCRIPTION')}
              </AppText>
            </View>
          </View>
          <View style={[ForceUpdateStyles.row, { marginVertical: theme.spacing.md }]}>
            <AppButton
              // style={ForceUpdateStyles.navigationButton}
              onPress={handleLogout}
              text={t('DEEPLINK_MODAL_BUTTON')}
            />
          </View>
        </View>
      }
    />
  );
};
