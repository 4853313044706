import { LogoIcon, MessageCheckIcon } from '@assets';
import {AppButton, AppEmptyView, AppKAView, AppSafeAreaView, AppText} from '@components';
import { AppSuccessScreen } from '@components/AppSuccessScreen';
import { FormItemView } from '@components/Form';
import GenericHeader from '@components/GenericHeader';
import { UPLOAD_FILE } from '@constant';
import { CommonActions, useNavigation } from '@react-navigation/native';
import { goBack, popToTop, reset } from '@routes/navigationUtils';
import { BOTTOM_TAB, Screens, SURVEY_LIST_SCREEN } from '@routes/route';
import { CDSSuccessfulView } from '@screens/main/CDS/CDSSuccessful.view';
import { D2DSuccessfulView } from '@screens/main/D2D/form/components/D2DSuccessful.view';
import { useReportICodeForm } from '@screens/main/report/iCode/form/ReportICodeForm.hook';
import { TopICodeView } from '@screens/main/report/iCode/form/components/TopICode.view';
import { ReportSuccessfulView } from '@screens/main/report/package/form/components/ReportSuccessful.view';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font, Spacing } from '@theme/size';
import { FormTransform } from '@utils/transform/form';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Text } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useD2DForm } from './D2DForm.hook';
import { D2DFormStyle } from './D2DForm.style';
import {RenderHelper} from "@utils/form/render";

export const D2DFormScreen = props => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const {
    data,
    formik,
    summary,
    isSuccess,
    // point,
    headerTitle,
    layoutInfo,
    setLayoutInfo,
    scrollToY,
    scrollViewRef,
    hasDesc,
    messageInfo,
    isEmptyData,
  } = useD2DForm(props);

    const refLayoutInfo = useRef(null)
    useEffect(() => {
        // console.log('AppForm>>>useEffect>>>')
        refLayoutInfo.current = {}
    },[])

  const renderForm = () => {
    return (
      <>
        <KeyboardAwareScrollView
          innerRef={ref => {
            if (ref) {
              scrollViewRef.current = ref;
            }
          }}
          style={[
            {
              backgroundColor: palette.WHITE,
              borderTopRightRadius: theme.spacing.lg,
              borderTopLeftRadius: theme.spacing.lg,
            },
          ]}
          contentContainerStyle={{ flexGrow: 1 }}
          showsVerticalScrollIndicator={false}
          keyboardShouldPersistTaps="handled">
          {/*<TopICodeView data={summary} />*/}
          <View style={[globalStyle.directionColumm, RegisterFormStyles.paddingSpace, globalStyle.flex1]}>
            {data?.attributes?.map((item, index) => {
              return (
                <FormItemView
                  index={index}
                  item={item}
                  formik={formik}
                  isBoldTitle={true}
                  checkCondition={true}
                  formData={data?.attributes}
                  folderUpload={UPLOAD_FILE.folderUpload.d2d}
                  onLayout={e => {
                    // const layout = e?.nativeEvent?.layout;
                    // if (!layout) {
                    //   return;
                    // }
                    // const newInfo = { ...layoutInfo };
                    // newInfo[item?.internalId] = layout.y + layout.height;
                    // setLayoutInfo(newInfo);
                      RenderHelper.onLayout(e, item, layoutInfo, setLayoutInfo, refLayoutInfo)

                  }}
                />
              );
            })}
          </View>
          <View
            style={[
              RegisterFormStyles.paddingSpace,
              { marginVertical: theme.spacing.sm, marginBottom: Spacing.height25 },
            ]}>
            <AppButton
              onPress={async () => {
                await FormTransform.submitAndScroll(formik, data?.attributes, layoutInfo, scrollToY);
              }}
              text={t('Submit')}
            />
          </View>
        </KeyboardAwareScrollView>
      </>
    );
  };
  return (
    <>
      <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
      <AppSafeAreaView areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>
        <GenericHeader
          onBackClicked={
            isSuccess
              ? () => {
                  goBack();
                }
              : goBack
          }
          // onBackClicked={goBack}
          // title={t('REPORT_TEST_ITEMS')}
          title={headerTitle}
          style={[{ backgroundColor: '#EDEDED' }]}
        />
        <View style={[globalStyle.directionColumm, RegisterFormStyles.containerView]}>
          {isSuccess ? (
            <AppSuccessScreen
              alert={messageInfo?.alert}
              icon={messageInfo?.icon}
              description={messageInfo?.description}
            />
          ) : isEmptyData ? (
            <AppEmptyView />
          ) : (
            renderForm()
          )}
          {/*{isSuccess ? <D2DSuccessfulView hasDesc={hasDesc} /> : renderForm()}*/}
        </View>
      </AppSafeAreaView>
    </>
  );
};
