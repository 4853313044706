import { Constant } from '@constant';
// import Layout from './Layout';
// import Global from './Global';
// import {satisfies} from 'semver';
import { Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';

const BOTTOM_VIEW_HEIGHT = DeviceInfo.isTablet() ? 90 : 72;
const iconAddOpinionWidth = DeviceInfo.isTablet() ? 60 : 48;
const iconAddOpinionHeight = iconAddOpinionWidth * 1.24;
const iconAddOpinionOffsetY = iconAddOpinionHeight * 0.21;
const iconAddOpinionOffsetX = iconAddOpinionHeight * 0.28;
const iconAddOpinionVerticalShift = iconAddOpinionHeight * 0.58;
const iconAddOpinionHorizontalShift = iconAddOpinionWidth * 0.44;
// dimen for social icon
const iconSocialMediaWidthHeight = DeviceInfo.isTablet() ? 120 : 80;
const SocialItemSpace = DeviceInfo.isTablet() ? 50 : 30;
// dimen for icon in text input
const iconTextInputWidth = 50;
const markerSize = DeviceInfo.isTablet() ? 80 : 60;
const iconSize = DeviceInfo.isTablet() ? 60 : 40;
const loadMoreIconSize = DeviceInfo.isTablet() ? 80 : 50;
// diment for text box
const textBoxHeight = DeviceInfo.isTablet() ? 180 : 130;
const iconAddPinSize = DeviceInfo.isTablet() ? 64 : 48;
const iconRePositIconSize = DeviceInfo.isTablet() ? 52 : 36;

const baseMargin = 16;
const baseViewHeight = Platform.OS == 'android' ? 42 : 40;
const baseFontSize = 16;
const baseButtonHeight = DeviceInfo.isTablet() ? 56 : 48;

export default {
  Margin: {
    Extra_Short: 0.25 * baseMargin,
    Short: 0.5 * baseMargin,
    Normal: baseMargin,
    VeryShort: 0.25 * baseMargin,
    Long: 1.5 * baseMargin,
    Extra_Long: 2 * baseMargin,
    defaultMargin: baseMargin,
  },
  Space: {
    SocialItemSpace: 50,
  },
  FontSize: {
    Small: 12,
    Normal: baseFontSize,
    Big: 22,
    VeryBig: 2 * baseFontSize,
    Title: 18,
    SubTitle: baseFontSize - 2,
  },
  IconSize: {
    Extra_Small: 16,
    Small: 20,
    Normal: 28,
    Big: 42,
    VeryBig: 56,
  },
  ItemHeight: {
    Normal: baseViewHeight,
    LitteBig: 48,
    TabbarHeight: DeviceInfo.isTablet() ? 56 : 49,
    TextBoxHeight: textBoxHeight,
    TextInputHeight: Constant.MAIN_HEIGHT,
    Button: baseButtonHeight,
    TabBarHeight: 34,
  },
  BOTTOM_VIEW_HEIGHT,
  iconAddOpinionWidth,
  iconAddOpinionOffsetX,
  iconAddOpinionOffsetY,
  iconAddOpinionHeight,
  iconAddOpinionVerticalShift,
  iconAddOpinionHorizontalShift,
  iconSocialMediaWidthHeight,
  SocialItemSpace,
  iconTextInputWidth,
  markerSize,
  iconSize,
  loadMoreIconSize,
  iconAddPinSize,
  iconRePositIconSize,
};
