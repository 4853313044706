import { registerCompletedSelector } from '@redux/auth/selectors';
import { navigatePush, navigateReset, reset } from '@routes/navigationUtils';
import { LOGIN_SCREEN, Screens } from '@routes/route';
import { deviceHeight, deviceWidth } from '@theme/size';
import { useEffect, useRef, useState } from 'react';
import { Animated, Easing } from 'react-native';
import SplashScreen from 'react-native-splash-screen';
import { useDispatch, useSelector } from 'react-redux';

export const SplashStep = {
  first_splash: 'fist_splash',
  register_completed: 'register_completed',
};

export const useSplash = props => {
  const isRegisterCompleted = useSelector(registerCompletedSelector);
  // const isRegisterCompleted = true

  const splashStep = isRegisterCompleted ? SplashStep.register_completed : SplashStep.first_splash;
  console.log('useSplash>>>', isRegisterCompleted, splashStep)

  useEffect(() => {
    // Hide Splashscreen when Fallback get willUnmount
  }, []);
  return { splashStep };
};
