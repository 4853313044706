import { CrossIcon, LogoIcon, MessageCheckIcon, WarningIcon } from '@assets';
import { AppAlert, AppButton, AppKAView, AppModal, AppSafeAreaView, AppText } from '@components';
import { ForceUpdateStyles } from '@components/AppForceUpdate/ForceUpdate.style';
import GenericHeader from '@components/GenericHeader';
import { goBack } from '@routes/navigationUtils';
import { FlexImageIcon } from '@screens/main/Menu/Components';
import { AppHtmlView, AppNoteView } from '@screens/main/quiz/QuizFailed/components/AppNote.view';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { Font, FontSize, Spacing } from '@theme/size';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Text, ScrollView, TouchableOpacity, useWindowDimensions } from 'react-native';
import RenderHtml from 'react-native-render-html';
import { useUnsubscribe } from './Unsubscribe.hook';
import { UnsubscribeStyle } from './Unsubscribe.style';

export const UnsubscribeScreen = props => {
  const { t } = useTranslation();
  const scaleFont = useWindowDimensions().fontScale;
  const { showModal, setShowModal, onNoModalPress, onYesPress, onYesModalPress, onNoPress, point, messageInfo } =
    useUnsubscribe(props);
  return (
    <>
      <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />

      <AppSafeAreaView areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>
        <GenericHeader
          onBackClicked={goBack}
          title={t('UNSUBSCRIBE')}
          style={[{ backgroundColor: palette.BACKGROUND_GREY }]}
        />
        <View
          style={[
            globalStyle.directionColumm,
            globalStyle.flex1,
            RegisterFormStyles.containerView,
            // { paddingTop: theme.spacing.sm },
          ]}>
          <ScrollView style={[globalStyle.flex1]} keyboardShouldPersistTaps="handled">
            {/*<RenderHtml*/}
            {/*  source={{ html: `${t('UNSUBSCRIBE_CONTENT', { point: point })}` }}*/}
            {/*  baseStyle={UnsubscribeStyle.htmlView}*/}
            {/*/>*/}
            <AppHtmlView
              isBoldTitle={false}
              style={UnsubscribeStyle.htmlView}
              text={`${t('UNSUBSCRIBE_CONTENT', { point: point })}`}
            />
          </ScrollView>
          <View
            style={[
              scaleFont > 1.5 ? globalStyle.directionColumm : globalStyle.directionRow,
              { margin: theme.spacing.md, marginBottom: Spacing.height25 },
              scaleFont > 1.5 && { height: 120 },
            ]}>
            <AppButton
              onPress={() => {
                // onGoToStorePress();
                onNoPress();
              }}
              text={t('STAY')}
              style={[
                { flex: 1, marginRight: theme.spacing.md },
                scaleFont > 1.5 && { marginBottom: 16 },
              ]}
            />
            <AppButton
              onPress={() => {
                // onGoToStorePress();
                onYesPress();
              }}
              text={t('UNSUBSCRIBE')}
              style={[{ flex: 1, backgroundColor: '#C1C6C8' }]}
            />
          </View>
        </View>
        <AppModal
          // onDismiss={handleHideModal}
          closeOnTouchOutside
          visible={showModal}
          style={[{ padding: theme.spacing.md }]}
          containerStyle={[{ borderRadius: 8, maxHeight: Device.height }]}
          // contentContainerStyle={{ maxWidth: '92%' }}
        >
          <View>
            <View style={[globalStyle.directionColumm, { marginTop: 24 }]}>
              <View style={[globalStyle.center]}>
                {/*<WarningIcon width={45} height={45} />*/}
                <FlexImageIcon
                  icon={messageInfo?.icon}
                  style={{
                    width: Spacing.width64,
                    height: Spacing.width64,
                  }}
                />
              </View>
              <View style={[globalStyle.center, { paddingVertical: theme.spacing.md }]}>
                <AppNoteView
                  style={{
                    fontSize: FontSize.Font15,
                    paddingVertical: theme.spacing.sm,
                    textAlign: 'center',
                    color: '#666666',
                    maxWidth: '85%',
                  }}
                  title={messageInfo?.description}
                />
              </View>
            </View>
            <View style={[ForceUpdateStyles.row, { margin: theme.spacing.md }]}>
              <AppButton
                onPress={() => {
                  // onGoToStorePress();
                  onNoModalPress();
                }}
                text={t('NO')}
                style={[{ flex: 1, backgroundColor: '#C1C6C8', marginRight: theme.spacing.md }]}
              />
              <AppButton
                onPress={() => {
                  // onGoToStorePress();
                  onYesModalPress();
                }}
                text={t('YES')}
                style={[{ flex: 1 }]}
              />
            </View>
          </View>
        </AppModal>
      </AppSafeAreaView>
    </>
  );
};
