import { getAnswerQuiz, getQuizCreator } from '@redux/quizzes/selectors';
import { popToTop } from '@routes/navigationUtils';
import { getInitialFormValueOrigin, getYupStructureOrigin } from '@utils/form';
import { getContentTitle, mappingStepOrigin } from '@utils/transform/form';
import { useFormik } from 'formik';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import {QuizTransform} from "@utils/transform/quiz";

export const useQuizCompleted = props => {
  const getQuizData = useSelector(getQuizCreator);
  const quizData = mappingStepOrigin(cloneDeep(getQuizData)?.quiz?.values) ?? [];
  // const getAnswerQuizData = useSelector(getAnswerQuiz);
  // const getAnswerQuizData = QuizTransform.getCorrectAnswer(getQuizData?.quiz?.id, quizData);
  const getAnswerQuizData = QuizTransform.getCorrectAnswer(getQuizData?.quiz);

  const rawData = cloneDeep(
    quizData?.map(x => {
      return {
        ...x,
        required: false,
      };
    }),
  );
  const headerTitle = getContentTitle(getQuizData?.quiz?.name);
  console.log('useQuizFailed>>>', quizData, rawData, getAnswerQuizData, getQuizData);
  const [data, setData] = useState(rawData);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: getInitialFormValueOrigin(rawData, { ...getAnswerQuizData[getQuizData?.quiz?.id] }),
    validationSchema: Yup.object().shape(getYupStructureOrigin(rawData, t, true)),
    initialErrors: false,
    onSubmit: values => {
      console.log('onSubmit>>>', values);
      popToTop();
    },
  });
  useEffect(() => {}, [dispatch]);
  return { data, headerTitle, formik };
};
