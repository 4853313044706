import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppButton, AppEmptyView, AppKAView, AppSafeAreaView, AppText } from '@components';
import GenericHeader from '@components/GenericHeader';
import { goBack } from '@routes/navigationUtils';
import { AppLoadingMore } from '@screens/main/SearchPostcode';
import { HistoryItemView } from '@screens/main/reward/HistoryPoints';
import { HistoryPointsStyle } from '@screens/main/reward/HistoryPoints/HistoryPoints.style';
import { ListTicketStyle } from '@screens/main/ticket/ListTicket/ListTicket.style';
import { TicketItemStyles } from '@screens/main/ticket/ListTicket/components/TicketItem.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font } from '@theme/size';
import { DATE_FORMAT, formatDateV1 } from '@utils/common/MomentUtils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Text, FlatList, TouchableOpacity } from 'react-native';
import { useReimbursement } from './Reimbursement.hook';
import { ReimbursementStyle } from './Reimbursement.style';

export const ReimbursementItemView = props => {
  const { t } = useTranslation();
  const data = props.data;

  // console.log('TicketItemView>>>>', props)

  return (
    <View>
      <View style={[HistoryPointsStyle.viewLine]} />
      <TouchableOpacity
        style={[
          // styles.fill,
          globalStyle.directionColumm,
          HistoryPointsStyle.container,
          // { marginBottom: props.isLast ? Dimens.Margin.Normal : 0 },
        ]}
        onPress={() => {
          props.onPress(data);
        }}>
        <View style={[globalStyle.directionRow]}>
          <View style={[globalStyle.directionColumm, globalStyle.flex1, { marginRight: 16 }]}>
            <AppText style={[TicketItemStyles.textTitle, globalStyle.flex1]}>{`${data?.activity}`}</AppText>
            {!!data?.details && (
              <AppText style={[TicketItemStyles.textTitle, globalStyle.flex1, { marginTop: 4 }]}>
                {`${data?.details}`}
              </AppText>
            )}
            {!!data?.posting_date && (
              <AppText style={[TicketItemStyles.textNormal, { marginTop: 4 }]}>
                {formatDateV1(data?.posting_date, DATE_FORMAT.UIDate)}
              </AppText>
            )}
          </View>
          <View>
            <View style={[ReimbursementStyle.point]}>
              <AppText style={[ReimbursementStyle.textPoint]}>
                {/*{getTextPriority(priority)}*/}
                {data?.amount}
              </AppText>
            </View>
          </View>

        </View>

        {/*<AppText style={[TicketItemStyles.textNormal]}>{formatDateV1(data?.posting_date, DATE_FORMAT.UIDate)}</AppText>*/}
      </TouchableOpacity>
    </View>
  );
};

export const ReimbursementScreen = props => {
  const { t } = useTranslation();
  const { allData, info, loading, isRefreshing, loadingMore, onRefresh, onLoadMore, onItemPress, onDownloadPress } =
    useReimbursement(props);
  const renderFooterLoading = () => {
    return <AppLoadingMore loadingMore={loadingMore} />;
  };

  const renderEmpty = () => {
    return !loading ? <AppEmptyView emptyText={props.emptyText} /> : <></>;
  };
  const renderItem = ({ item, index }) => {
    return (
      <ReimbursementItemView
        data={item}
        isLast={index === allData.length - 1}
        isSelected={false}
        onPress={() => onItemPress(item)}
      />
    );
  };
  return (
    <>
      <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
      <AppSafeAreaView areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>
        <GenericHeader
          onBackClicked={goBack}
          title={t('REIMBURSEMENT_TITLE')}
          style={[{ backgroundColor: '#EDEDED' }]}
        />
        <AppKAView>
          <View style={[globalStyle.directionColumm, globalStyle.flex1, { backgroundColor: palette.WHITE }]}>
            <View style={[{ marginHorizontal: theme.spacing.md, marginTop: theme.spacing.md }]}>
              <View style={[globalStyle.directionRow, { alignItems: 'baseline' }]}>
                <AppText style={[{ ...Font.Bold_700 }]}>{t('BALANCE')}: </AppText>
                <AppText style={[HistoryPointsStyle.textHeader]}>
                  {_.isNil(info?.balance) ? 'N/A' : info?.balance}
                </AppText>
                {/*<AppText style={[]}> {t('POINTS')}</AppText>*/}
              </View>
              {/*<AppText style={[HistoryPointsStyle.textDescription]}>{bonusInfo?.note}</AppText>*/}
            </View>
            <FlatList
              style={[ListTicketStyle.listView]}
              contentContainerStyle={_.isEmpty(allData) ? { flexGrow: 1 } : {}}
              data={allData}
              renderItem={renderItem}
              onRefresh={() => onRefresh()}
              refreshing={isRefreshing}
              ListEmptyComponent={renderEmpty}
              onEndReached={!loading ? onLoadMore : null}
              onEndReachedThreshold={0.5}
              keyExtractor={(item, index) => index}
              ListFooterComponent={renderFooterLoading}
            />
            <View style={[{ margin: theme.spacing.md }]}>
              <AppButton text={t('DOWNLOAD')} onPress={onDownloadPress} />
            </View>
          </View>
        </AppKAView>
      </AppSafeAreaView>
    </>
  );
};
