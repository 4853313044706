import { BackIcon } from '@assets';
import { AppText } from '@components/AppText';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useAppHeader } from './AppHeader.hook';
import styles from './AppHeader.style';

export const AppHeader = props => {
  const { LeftComponent, RightComponent, CenterComponent, centerText, leftStyles, centerStyles, rightStyles } = props;
  const { handleLeftPress, handleRightPress } = useAppHeader(props);

  const Left = () => {
    if (LeftComponent) return <LeftComponent />;
    return <BackIcon />;
  };
  const Center = () => {
    if (CenterComponent) return <CenterComponent />;
    return <AppText style={styles.centerText}>{centerText}</AppText>;
  };

  const Right = () => {
    if (RightComponent) return <RightComponent />;
    return <></>;
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity style={[styles.left, leftStyles]} onPress={handleLeftPress}>
        <Left />
      </TouchableOpacity>
      <TouchableOpacity activeOpacity={1} style={[styles.center, centerStyles]} onPress={handleLeftPress}>
        <Center />
      </TouchableOpacity>
      <TouchableOpacity style={[styles.right, rightStyles]} onPress={handleRightPress}>
        <Right />
      </TouchableOpacity>
    </View>
  );
};
