import _ from 'lodash';

export const mappingPostcode = rs => {
  if (_.isEmpty(rs)) {
    return [];
  }

  return rs?.map(x => {
    return {
      title: x.postcode,
      ...x,
    };
  });
};

export const mappingAddress = rs => {
  if (_.isEmpty(rs)) {
    return [];
  }

  return rs?.map(x => {
    return {
      title: x.address_summary,
      ...x,
    };
  });
};
