import { CalendarIcon, ClockIcon } from '@assets';
import { AppText, AppAlert, AppInput } from '@components';
import { Constant } from '@constant';
import { FORM } from '@constant/model/RegisterForm';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { FontSize } from '@theme/size';
import { DATE_FORMAT, formatDate } from '@utils/common/MomentUtils';
import { DateHelper } from '@utils/common/date';
import i18next, { t } from 'i18next';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { createElement } from 'react-native';
import { Calendar, CalendarList, Agenda } from 'react-native-calendars';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import MaskedTextInput from 'react-text-mask';

const TAB_KEY = 9;
const initMaxDate = (date, format = DATE_FORMAT.StandardDate) => {
  if (date && moment(date).isValid()) return moment(date).format(format);
  return date;
};

const isWord = value => {
  const regex = /^[0-9a-zA-Z]+$/;
  if (value.match(regex)) {
    return true;
  }
  return false;
};

const fixFormat = format => {
  return format.replace('DD', 'dd').replace('YYYY', 'yyyy').replace('YY', 'yy');
};

const convertMask = format => {
  // const letter = Object.
  const ls = format.split('');
  const rs = [];
  for (const l of ls) {
    if (isWord(l)) {
      rs.push(/\d/);
    } else {
      rs.push(l.toString());
    }
  }
  console.log('convertMask>>>', rs);
  return rs;
  // rs =
  // return [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
  // return [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, ":", /\d/, /\d/
};

export const AppDateTimePicker = props => {
  const {
    error,
    style,
    title,
    onPickDate,
    isRequired,
    mode = 'date',
    disabled,
    isBoldTitle,
    hint = '',
    minuteInterval = 1,
    format = DATE_FORMAT.UIDate,
    fromFormat = DATE_FORMAT.StandardDate,
    placeHolder = '',
    onLayout = null,
  } = props;

  const CustomTimeInput = props => {
    console.log('CustomTimeInput>>>', props);
    return (
      <TouchableOpacity
        onPress={props.onClick}
        disabled={disabled}
        activeOpacity={0.7}
        style={[styles.viewSelect, { opacity: disabled ? 0.5 : 1 }]}>
        {/*{_.isEmpty(props?.value) ? <AppText style={[styles.textSelect, value ? styles.textAfterSelect : {}]}>*/}
        {/*  {placeHolder ? placeHolder : `Select ${mode}`}*/}
        {/*</AppText>: */}
        {/*}*/}
        <MaskedTextInput style={styles.textSelectWeb} type="text" mask={dateTimeMask} {...props} />
        {mode === 'time' ? <ClockIcon /> : <CalendarIcon />}
      </TouchableOpacity>
    );
  };
  let { value, maxDate, minDate } = props;
  const calendarRef = useRef(null);

  const [dateTimeMask] = useState(convertMask(format));
  const [startDate, setStartDate] = useState(null);

  console.log('AppDateTimePicker>>>', props, format, value, fromFormat, maxDate, minDate, mode);
  useEffect(() => {
    if (props.value) {
      // console.log('useEffect>>>setStartDate>>>', value);
      const rs = DateHelper.getDateValue(props.value, mode, format);
      setStartDate(rs);
    }
  }, [format, mode, props.value]);

  const handleConfirm = date => {
    console.log('handleConfirm>>>date>>', date);
    onPickDate(date);
  };
  if (mode === FORM.FORM_TYPE.time) {
    return (
      <View
        style={[styles.container, style]}
        onLayout={e => {
          if (onLayout) {
            onLayout(e);
          }
        }}>
        <AppText style={[styles.textTitle, isBoldTitle ? { fontWeight: '700' } : {}]}>
          {isRequired && <AppText style={styles.isRequired}>* </AppText>}
          {title}
        </AppText>
        <DatePicker
          // showPopperArrow={false}
          popperModifiers={[
            {
              name: 'arrow',
              options: {
                padding: ({ popper }) => ({
                  right: popper.width - 32,
                  // top: popper.height -10,
                }),
              },
            },
          ]}
          locale={i18next.language}
          ref={calendarRef}
          dateFormat={fixFormat(format)}
          selected={startDate}
          onChange={date => {
            let finalDate = date;
            handleConfirm(finalDate);
            setStartDate(finalDate);
          }}
          timeIntervals={minuteInterval}
          disabled={disabled}
          // timeCaption={t("common.time")}
          onKeyDown={event => {
            if (event.which === TAB_KEY) calendarRef.current.setOpen(false, true);
          }}
          shouldCloseOnSelect
          placeholderText={placeHolder ? placeHolder : `Select ${mode}`}
          showTimeSelect
          showTimeSelectOnly
          customInput={<CustomTimeInput disabled={disabled} />}
        />
        {!!error && <AppText style={styles.error}>{error}</AppText>}
      </View>
    );
  }
  if (mode === FORM.FORM_TYPE.datetime || mode === FORM.FORM_TYPE.date) {
    // set default when element is rendered
    let _value = value || null;
    return (
      <View
        style={[styles.container, style]}
        onLayout={e => {
          if (onLayout) {
            onLayout(e);
          }
        }}>
        <AppText style={[styles.textTitle, isBoldTitle ? { fontWeight: '700' } : {}]}>
          {isRequired && <AppText style={styles.isRequired}>* </AppText>}
          {title}
        </AppText>
        <DatePicker
          // showPopperArrow={false}
          popperModifiers={[
            {
              name: 'arrow',
              options: {
                padding: ({ popper }) => ({
                  right: popper.width - 32,
                  // top: popper.height -10,
                }),
              },
            },
          ]}
          maxDate={props.maxDate}
          minDate={props.minDate}
          locale={i18next.language}
          ref={calendarRef}
          // dateFormat={"dd/MM/yyyy HH:mm"}
          dateFormat={fixFormat(format)}
          // popperPlacement={props.popperPlacement}
          selected={startDate}
          // showIcon
          onChange={date => {
            let finalDate = date;
            handleConfirm(finalDate);
            setStartDate(finalDate);
          }}
          timeIntervals={minuteInterval}
          disabled={disabled}
          // timeCaption={t("common.time")}
          onKeyDown={event => {
            if (event.which === TAB_KEY) calendarRef.current.setOpen(false, true);
          }}
          shouldCloseOnSelect
          placeholderText={placeHolder ? placeHolder : `Select ${mode}`}
          showTimeSelect={mode === FORM.FORM_TYPE.datetime}
          portalId={'picker-portal-id'}
          customInput={<CustomTimeInput disabled={disabled} />}
        />

        {!!error && <AppText style={styles.error}>{error}</AppText>}
      </View>
    );
  }
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  textTitle: {
    fontWeight: '400',

    marginBottom: 10,
    color: '#333333',
  },
  viewSelect: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#C6C6C6',
    marginHorizontal: 0,
    // backgroundColor: '#D5D5D5',
    minHeight: Constant.MAIN_HEIGHT, //42
    paddingHorizontal: 12,
    borderRadius: 4,
  },

  viewSelectWeb: {
    // flexDirection: 'row',
    fontSize: FontSize.Font15,
    width: '100%',
    flex: 1,
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#C6C6C6',
    fontFamily: 'arial',
    marginHorizontal: 0,
    // backgroundColor: '#D5D5D5',
    minHeight: Constant.MAIN_HEIGHT, //42
    paddingHorizontal: 12,
    borderRadius: 4,
    fontWeight: '400',
    color: '#333333',
    // marginLeft: 12,
  },
  isRequired: {
    color: '#FF5757',
  },
  textAfterSelect: {
    // color: 'red',
    fontWeight: '400',

    color: '#333333',
    marginLeft: 12,
    // textTransform: 'capitalize',
  },
  textSelect: {
    fontWeight: '400',

    color: '#666666',
    marginLeft: 12,
    // textTransform: 'capitalize',
  },

  textSelectWeb: {
    fontSize: FontSize.Font15,
    fontWeight: '400',
    flex: 1,
    color: '#666666',
    // marginLeft: 12,
    borderColor: 'transparent',
    borderRadius: 0,
    padding: 0,
    backgroundImage: 'none',
    fontFamily: 'arial',
    outlineStyle: Platform.OS === 'web' ? 'none' : undefined,
  },
  hint: {
    fontWeight: '400',
    color: '#999999',
    marginTop: 7,
  },
  error: {
    marginTop: 7,

    color: palette.ROYAL_MAIL_RED,
    fontWeight: '400',
  },
});
