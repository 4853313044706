import { createSlice } from '@reduxjs/toolkit';

export interface IforgotPasswordState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IforgotPasswordState = {
  loading: false,
  payload: null,
  error: null,
};

const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    forgotPasswordRequest(state, action) {
      state.loading = true;
      // @ts-ignore
      state.payload = null;
      state.error = null;
    },
    forgotPasswordSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    forgotPasswordFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { forgotPasswordRequest, forgotPasswordSuccess, forgotPasswordFailed } = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
