import type { RootState } from '@redux/reducers';
import { createSelector } from '@reduxjs/toolkit';
import { getErrorMessage } from '@utils/api';
import {mappingForm} from "@utils/transform/form";

export const getFormSelector = (state: RootState) => state.getForm;

export const getFormCreator = createSelector(getFormSelector, state => state.payload);
export const getFormLoading = createSelector(getFormSelector, state => state.loading);
export const getFormError = createSelector(getFormSelector, state => state.error);
export const getFormErrorMsg = createSelector(getFormSelector, state => getErrorMessage(state.error));
export const getFormStandard = createSelector(getFormCreator, state => mappingForm(state?.form_data));

export const getProfileFormSelector = (state: RootState) => state.getProfileForm;
export const getProfileFormCreator = createSelector(getProfileFormSelector, state => state.payload);
export const getProfileFormLoading = createSelector(getProfileFormSelector, state => state.loading);
export const getProfileFormError = createSelector(getProfileFormSelector, state => state.error);
export const getProfileFormErrorMsg = createSelector(getProfileFormError, state => getErrorMessage(state));
export const getProfileFormStandard = createSelector(getProfileFormCreator, state => mappingForm(state?.form_data));

export const getMenuformSelector = (state: RootState) => state.getMenuform;
export const getMenuformCreator = createSelector(getMenuformSelector, state => state.payload);
export const getMenuformLoading = createSelector(getMenuformSelector, state => state.loading);
export const getMenuformError = createSelector(getMenuformSelector, state => state.error);
export const getMenuformErrorMsg = createSelector(getMenuformError, state => getErrorMessage(state));

export const getFAQSelector = (state: RootState) => state.getFAQ;
export const getFAQCreator = createSelector(getFAQSelector, state => state.payload);
export const getFAQLoading = createSelector(getFAQSelector, state => state.loading);
export const getFAQError = createSelector(getFAQSelector, state => state.error);
export const getFAQErrorMsg = createSelector(getFAQError, state => getErrorMessage(state));

export const getReportModalSelector = (state: RootState) => state.getReportModal;
export const getReportModalCreator = createSelector(getReportModalSelector, state => state.payload);
export const getReportModalLoading = createSelector(getReportModalSelector, state => state.loading);
export const getReportModalError = createSelector(getReportModalSelector, state => state.error);
export const getReportModalErrorMsg = createSelector(getReportModalError, state => getErrorMessage(state));

export const getForgotPasswordSelector = (state: RootState) => state.getForgotPassword;
export const getForgotPasswordCreator = createSelector(getForgotPasswordSelector, state => state.payload);
export const getForgotPasswordLoading = createSelector(getForgotPasswordSelector, state => state.loading);
export const getForgotPasswordError = createSelector(getForgotPasswordSelector, state => state.error);
export const getForgotPasswordErrorMsg = createSelector(getForgotPasswordError, state => getErrorMessage(state));

export const getReportPackageFormSelector = (state: RootState) => state.getReportPackageForm;
export const getReportPackageFormCreator = createSelector(getReportPackageFormSelector, state => state.payload);
export const getReportPackageFormLoading = createSelector(getReportPackageFormSelector, state => state.loading);
export const getReportPackageFormError = createSelector(getReportPackageFormSelector, state => state.error);
export const getReportPackageFormErrorMsg = createSelector(getReportPackageFormError, state => getErrorMessage(state));

export const getReportBundleFormSelector = (state: RootState) => state.getReportBundleForm;
export const getReportBundleFormCreator = createSelector(getReportBundleFormSelector, state => state.payload);
export const getReportBundleFormLoading = createSelector(getReportBundleFormSelector, state => state.loading);
export const getReportBundleFormError = createSelector(getReportBundleFormSelector, state => state.error);
export const getReportBundleFormErrorMsg = createSelector(getReportBundleFormError, state => getErrorMessage(state));

export const getICodeFormSelector = (state: RootState) => state.getICodeForm;
export const getICodeFormCreator = createSelector(getICodeFormSelector, state => state.payload);
export const getICodeFormLoading = createSelector(getICodeFormSelector, state => state.loading);
export const getICodeFormError = createSelector(getICodeFormSelector, state => state.error);
export const getICodeFormErrorMsg = createSelector(getICodeFormError, state => getErrorMessage(state));
export const getICodeFormStandard = createSelector(getICodeFormSelector, state => mappingForm(state?.data));

export const getCDSFormSelector = (state: RootState) => state.getCDSForm;
export const getCDSFormCreator = createSelector(getCDSFormSelector, state => state.payload);
export const getCDSFormLoading = createSelector(getCDSFormSelector, state => state.loading);
export const getCDSFormError = createSelector(getCDSFormSelector, state => state.error);
export const getCDSFormErrorMsg = createSelector(getCDSFormError, state => getErrorMessage(state));

export const getD2DFormSelector = (state: RootState) => state.getD2DForm;
export const getD2DFormCreator = createSelector(getD2DFormSelector, state => state.payload);
export const getD2DFormLoading = createSelector(getD2DFormSelector, state => state.loading);
export const getD2DFormError = createSelector(getD2DFormSelector, state => state.error);
export const getD2DFormErrorMsg = createSelector(getD2DFormError, state => getErrorMessage(state));

export const getHolidayFormSelector = (state: RootState) => state.getHolidayForm;
export const getHolidayFormCreator = createSelector(getHolidayFormSelector, state => state.payload);
export const getHolidayFormLoading = createSelector(getHolidayFormSelector, state => state.loading);
export const getHolidayFormError = createSelector(getHolidayFormSelector, state => state.error);
export const getHolidayFormErrorMsg = createSelector(getHolidayFormError, state => getErrorMessage(state));

export const getRFIDFormReceiverSelector = (state: RootState) => state.getRFIDFormReceiver;
export const getRFIDFormReceiverCreator = createSelector(getRFIDFormReceiverSelector, state => state.payload);
export const getRFIDFormReceiverLoading = createSelector(getRFIDFormReceiverSelector, state => state.loading);
export const getRFIDFormReceiverError = createSelector(getRFIDFormReceiverSelector, state => state.error);
export const getRFIDFormReceiverErrorMsg = createSelector(getRFIDFormReceiverError, state => getErrorMessage(state));

export const getRFIDFormSelector = (state: RootState) => state.getRFIDForm;
export const getRFIDFormCreator = createSelector(getRFIDFormSelector, state => state.payload);
export const getRFIDFormLoading = createSelector(getRFIDFormSelector, state => state.loading);
export const getRFIDFormError = createSelector(getRFIDFormSelector, state => state.error);
export const getRFIDFormErrorMsg = createSelector(getRFIDFormError, state => getErrorMessage(state));

export const getQRFormSelector = (state: RootState) => state.getQRForm;
export const getQRFormCreator = createSelector(getQRFormSelector, state => state.payload);
export const getQRFormLoading = createSelector(getQRFormSelector, state => state.loading);
export const getQRFormError = createSelector(getQRFormSelector, state => state.error);
export const getQRFormErrorMsg = createSelector(getQRFormError, state => getErrorMessage(state));

export const getCreateTicketFormSelector = (state: RootState) => state.getCreateTicketForm;
export const getCreateTicketFormCreator = createSelector(getCreateTicketFormSelector, state => state.payload);
export const getCreateTicketFormLoading = createSelector(getCreateTicketFormSelector, state => state.loading);
export const getCreateTicketFormError = createSelector(getCreateTicketFormSelector, state => state.error);
export const getCreateTicketFormErrorMsg = createSelector(getCreateTicketFormError, state => getErrorMessage(state));

export const getReturnTransponderFormSelector = (state: RootState) => state.getReturnTransponderForm;
export const getReturnTransponderFormCreator = createSelector(getReturnTransponderFormSelector, state => state.payload);
export const getReturnTransponderFormLoading = createSelector(getReturnTransponderFormSelector, state => state.loading);
export const getReturnTransponderFormError = createSelector(getReturnTransponderFormSelector, state => state.error);
export const getReturnTransponderFormErrorMsg = createSelector(getReturnTransponderFormError, state => getErrorMessage(state));
