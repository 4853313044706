import { createAction, createSlice } from '@reduxjs/toolkit';

export interface IsearchForPostCodeState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IsearchForPostCodeState = {
  loading: false,
  payload: null,
  error: null,
};

export const searchForPostCodeRequest = createAction(
  'searchForPostCode/searchForPostCodeRequest',
  (payload, onSuccess, onFailed) => {
    console.log('prepareAction>>>', payload, onSuccess);
    return {
      payload: payload,
      meta: onSuccess,
      error: onFailed,
    };
  },
);

const searchForPostCodeSlice = createSlice({
  name: 'searchForPostCode',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // searchForPostCodeRequest(state, action) {
    //   state.loading = true;
    //   // @ts-ignore
    //   state.payload = null;
    //   state.error = null;
    // },
    searchForPostCodeSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    searchForPostCodeFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  // searchForPostCodeRequest,
  searchForPostCodeSuccess,
  searchForPostCodeFailed,
} = searchForPostCodeSlice.actions;

export default searchForPostCodeSlice.reducer;
