import api from '@services/api-client';
import { stringify } from 'qs';
import withQuery from 'with-query';

export const searchForPostCodeService = async payload => {
  console.log('searchForPostCodeService>>>', payload)
  const response = await api.get(`/addresses.json?${stringify(payload)}`)
  console.log('searchForPostCodeService response>>>', response)

  return response?.data
}

export const searchForAddressService = async payload => {
  console.log('searchForAddressService>>>', payload)
  const response = await api.get(`/addresses.json?${stringify(payload)}`)
  console.log('searchForAddressService response>>>', response)

  return response?.data
}

