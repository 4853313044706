import { REPORT_TYPE } from '@constant';
import { Config } from '@constant/config';
import { ReportBundleMockData } from '@constant/model/mock/report/bundle';
import { ReportPackageMockData } from '@constant/model/mock/report/package';
import { useRoute } from '@react-navigation/native';
import { getValidateReportRequest } from '@redux/app/appSlice';
import { reportModal as reportModalSelector } from '@redux/app/selectors';
import { loginRequest } from '@redux/auth/authSlice';
import { getReportPackageFormRequest } from '@redux/form/getReportPackageFormSlice';
import { getReportPackageFormCreator } from '@redux/form/selectors';
import { submitPackageRequest } from '@redux/package/submitPackageSlice';
import { popToTop } from '@routes/navigationUtils';
import { ReportModalTransform } from '@utils';
import { checkFormValidateExpression, getInitialFormValue, getYupStructure } from '@utils/form';
import { mappingForm, mappingStep } from '@utils/transform/form';
import { useFormik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { lazy } from 'yup';
import * as Yup from 'yup';
import {hideLoading, showLoading} from "@redux/global/globalSlice";

export const useReportPackageDetail = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const paramsData = useRoute().params?.data;
  const paramsX = useRoute().params;

  const info = {
    id: paramsX?.id,
    total: paramsData?.payload?.data?.count_bundles,
  };
  const formModal = useSelector(reportModalSelector);
  const headerTitle = ReportModalTransform.getReportTitle(paramsData?.reportType, formModal);
  const getReportPackageFormData = useSelector(getReportPackageFormCreator)?.data;
  // const rawData = mappingStep(ReportPackageMockData.form);
  // const rawData = mappingStep(!_.isEmpty(getReportPackageFormData) ? getReportPackageFormData[0] : null);
  const [data, setData] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [point, setPoint] = useState(0);
  const [summary, setSummary] = useState({
    ...info,
  });
  const [messageInfo, setMessageInfo] = useState({});

  const [loginInfo, setLoginInfo] = useState({})

  // const lo = Config.IsMockServer ? ReportBundleMockData.userLocation : {};
  // const [userLocation, setUserLocation] = useState(lo);

  const loginApi = () => {
    console.log('loginApi>>>', paramsX);
    dispatch(
      loginRequest(
        {
          jwt: paramsX?.jwt,
        },
        rs => {
          console.log('loginApi>>>rs>>', rs);
          setLoginInfo(rs?.data)
          validateReportApi(rs?.data);
          getReportPackageFormApi();
        },
        err => {},
      ),
    );
  };

  const validateReportApi = payload => {
    dispatch(
      getValidateReportRequest(
        {
          changeBy: payload?.changeBy,
          changeType: payload?.changeType,
          type: REPORT_TYPE.packages,
          id: paramsX?.id,
        },
        rs => {
          console.log('getValidateReport>>>response', rs);
          //TODO: apply data to form
          // handleNavigateReportForm(rs, REPORT_TYPE.icodes, data?.icode);
          setSummary({
            ...summary,
            // reportDetail: rs.
            ...rs?.data,
            total: rs?.data?.count_bundles,
            // date: rs?.data?.target_dispatch_date,
          });
        },
        err => {},
      ),
    );
  };

  const getReportPackageFormApi = () => {
    const payload = { number_of_planned_items: summary.total };
    dispatch(showLoading());
    dispatch(
      getReportPackageFormRequest(
        payload,
        res => {
          dispatch(hideLoading());
        },
        err => {
          dispatch(hideLoading());
        },
      ),
    );
  };
  const submitPackageApi = payload => {
    /*
  params:
  {
      "Token": "{{token}}",
      "Accept": "application/json"
  }
  body:
  {
      "receipt_date": "2022-09-08",
      "correct_amount_items": true,
      "deviations":null
  }
   */
    dispatch(
      submitPackageRequest(
        payload,
        rs => {
          handleSuccess(rs);
        },
        err => {},
      ),
    );
  };

  const formik = useFormik({
    initialValues: getInitialFormValue(data, {
      ...summary?.reportDetail,
    }),
    enableReinitialize: true,
    // validationSchema: Yup.object().shape(getYupStructure(rawData, t)),
    validationSchema: () =>
      lazy(values => {
        // console.log('lazy>>>values>>>', values);
        const ys = getYupStructure(data, t, 'attributes', values);
        console.log('getYupStructure>>>', ys, formik);
        return Yup.object().shape(ys);
      }),
    initialErrors: false,
    onSubmit: (values, { setErrors, resetForm }) => {
      console.log('onSubmit>>>', values, formik.errors, data);
      const rs = checkFormValidateExpression(data?.attributes, values, setErrors);
      console.log('checkFormValidateExpression>>>', rs);
      if (!_.isEmpty(rs)) {
        return;
      }
      handleSubmit(values);
      //TODO: validate here
      // updateUserApi(values, isLastStep);
      // onSavePress();
    },
  });

  const handleSuccess = rs => {
    //TODO: reload api dashboard here
    setPoint(rs?.data?.point ?? 0);
    setMessageInfo(rs?.message)
    setIsSuccess(true);
    // setTimeout(() => {
    //   popToTop();
    // }, 5000);
  };

  const handleSubmit = values => {
    const payload = {
      ...values,
      id: paramsX?.id,
      changeBy: loginInfo?.changeBy,
      changeType: loginInfo?.changeType,
    };

    const mock = Config.IsMockServer;
    if (mock) {
      handleSuccess();
    } else {
      submitPackageApi(payload);
    }
  };

  console.log('useReportPackageDetail>>>', formik, data, getReportPackageFormData);
  useEffect(() => {
    // getReportPackageFormApi();
    loginApi();
  }, []);

  useEffect(() => {
    const x = _.cloneDeep(getReportPackageFormData);
    const rd = mappingStep(!_.isEmpty(x) ? x[0] : null);
    setData(rd);
  }, [getReportPackageFormData]);
  return { formik, data, summary, isSuccess, point, headerTitle, messageInfo };
};
