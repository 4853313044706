import {checkEvalCondition, FormTransform} from '@utils/transform/form';
import _ from 'lodash';
import {FORM} from "@constant/model/RegisterForm";
import {getInitialFormValueOrigin} from "@utils/form";

export const FormGroupHelper = {
  getTestGroupData: data => {
    return {
      ...data,
      string: 'string_abc',
      string2: 'string2_123',
      depend_string: 'depend_string_111'
    }
  },
  getTestGroupForm: form => {
    const newForm = [...form]
    newForm.map(item => {
      const attributes = item.attributes
      if(item.step === 1){
        attributes.push({
          name: 'String',
          type: 'string',
          internalId: 'string',
          errorMessage: 'Field is required',
          errors: {
            required: 'Field is required',
          },
          title: "string",
          // required: true,
          editable: true,
        })
        attributes.push({
          type: 'string',
          internalId: 'string2',
          errorMessage: 'Field is required',
          errors: {
            required: 'Field is required',
          },
          condition: {
            react: "return !!{{depend_string}};",
          },
          title: "string2",
          // required: true,
          editable: true,
        })
      }
      else if(item.step === 2){
        attributes.push({
          name: 'depend_string',
          condition: {
            react: "return !!{{string}};",
          },
          type: 'string',
          internalId: 'depend_string',
          errorMessage: 'Field is required',
          errors: {
            required: 'Field is required',
          },
          title: "depend_string",

          // required: true,
          editable: true,
        })
      }

    })
    return newForm
  },
  getFieldChanged: (originValues, values) => {
    if (_.isEmpty(values)) {
      return {};
    }
    if (_.isEmpty(originValues)) {
      return values;
    }
    const rs = {};
    for (const valuesKey in values) {
      if (values[valuesKey] != originValues[valuesKey]) {
        // rs.push(valuesKey);
        rs[valuesKey] = valuesKey;
      }
    }

    return rs;
  },
  hasInScripted: (key, scripted) => {
    if (_.isEmpty(key) || _.isEmpty(scripted)) {
      return false;
    }
    return scripted.includes('{{' + key + '}}');
  },

  getDependencyFieldsWithAllForm: (fieldChanges, mainForms, allForms, values) => {
    const mainAttrs = mainForms.attributes;
    const otherForms = allForms.filter(x => x.step !== mainForms.step);
    const currentStep = mainForms.step
    const otherKeys = {};
    for (const otherForm of otherForms) {
      // const internalIds = otherForm.attributes?.map(x => x.internalId);
      // const filterIds = internalIds.filter(x => !!x);
      const filterIds = otherForm.attributes?.filter(x => !!x.internalId);

      // otherKeys = [...otherKeys, ...filterIds];
      filterIds?.map((x, index) => {
        otherKeys[x.internalId] = {
          step: otherForm.step,
          order: index,
          scripted:x?.condition?.react,
          checked:false,
        };
      });
    }
    const rs = {};
    const oKeys = Object.keys(otherKeys);
    console.log('oKeys>>', otherKeys)

    for (const fieldChangesKey in fieldChanges) {
      const internalIds = oKeys.filter(k => FormGroupHelper.hasInScripted(fieldChangesKey, otherKeys[k].scripted) && !checkEvalCondition(otherKeys[k].scripted, values));
      console.log('scripted>>>', fieldChangesKey, internalIds)

      internalIds.map(id => {
        const infoKey = otherKeys[id]
        rs[id] = infoKey;
        const currentForm = otherForms.find(x => x.step === infoKey.step)
        const attrs = currentForm?.attributes ?? []
        values = FormGroupHelper.resetInternalField(id,infoKey.scripted,attrs, values )
        infoKey.checked = true
        //TODO:
        values = FormGroupHelper.getDependencyFieldsWithAllForm()
      });
      // }
    }

    console.log('getDependencyFieldsWithAllForm>>>',fieldChanges, rs)

    return rs;
  },
  calculateInfoOfForm : allForms => {
    const otherKeys = {}
    if(_.isEmpty(allForms)){
      return otherKeys
    }
    for (const otherForm of allForms) {
      const filterIds = otherForm.attributes?.filter(x => !!x.internalId);
      for (const x of filterIds) {
        const step =  otherForm.step
        const order = x.index
        otherKeys[x.internalId] = {
          step: step,
          order: order,
          scripted:x?.condition?.react,
          checked:false,
          internalId: x.internalId
        };
      }
    }
    console.log('calculateInfoOfForm>>>', otherKeys, allForms)
    return otherKeys
  },
  calculateFieldChanged:(fieldChangesKey, mainForms, allForms, values, order = null, otherKeys = null) => {
    console.log('calculateFieldChanged>>>', fieldChangesKey, order, otherKeys)
    const mainAttrs = mainForms.attributes;
    // const otherForms = allForms.filter(x => x.step !== mainForms.step);
    const currentStep = mainForms.step
    // const currentIndex = order ?? mainAttrs.attributes?.find(x => x.internalId === fieldChangesKey)?.index
    const currentIndex = order

    const rs = {};
    const oKeys = Object.keys(otherKeys);
    console.log('oKeys>>', otherKeys)

    const internalIds = oKeys.filter(k => FormGroupHelper.hasInScripted(fieldChangesKey, otherKeys[k].scripted) && !checkEvalCondition(otherKeys[k].scripted, values));
    console.log('scripted>>>', fieldChangesKey, internalIds)

    if(_.isEmpty(internalIds)){
      return values
    }
    for (const id of internalIds) {
      const infoKey = otherKeys[id]
      rs[id] = infoKey;
      if( infoKey.step === currentStep && infoKey.order < currentIndex){
        continue
      }
      const currentForm = allForms.find(x => x.step === infoKey.step)
      const attrs = currentForm?.attributes ?? []
      values = FormGroupHelper.resetInternalField(id,infoKey.scripted,attrs, values )
      infoKey.checked = true
      //TODO:
      values = FormGroupHelper.calculateFieldChanged(id, currentForm,allForms, values, infoKey.order, otherKeys)
    }

    return values
  },
  resetInternalField: (internalId,scripted, attributes, values) => {
    //Cần reset toàn bộ trong form other.
    // F1A1 => F1A2 => F1A3
    // const internalId = mapInternal.internalId;
    if (_.isEmpty(attributes)) {
      return values;
    }

    if (_.isEmpty(values)) {
      return values;
    }

    const value = values[internalId];
    const item = attributes.find(x => x.internalId === internalId);
    if (_.isEmpty(item)) {
      return values;
    }
    const emptyValue = FormTransform.getEmptyValue(item);
    if (value == emptyValue) {
      return values;
    }
    // can check co hidden field => hidden thi reset value
    values[internalId] = emptyValue;
    return values;
  },
  resetAllForm: (fieldChanges, values, form, otherForms) => {

  },
  resetInForm: (fieldChanges, values, form) => {
    const mapInternalIds = FormGroupHelper.getMapInternalIds(form.attributes);
    // get mapInternalIds => reset value
    const rs = {};
    for (const fieldChange of fieldChanges) {
      const mapInternal = mapInternalIds[fieldChange];
      const depend = FormGroupHelper.getDependencyFieldsByOtherScriptedsRecursive(mapInternal, mapInternalIds);
      rs[fieldChange] = {
        ...depend,
      };
    }
    return FormGroupHelper.resetInFormWithTree(values, rs, form.attributes)
  },

  resetInFormWithTree: (values, tree, attributes) => {
    // const attributes = form.attributes
    for (const treeKey in tree) {
      const internalId = treeKey;
      values = FormGroupHelper.resetInternalField(internalId, tree.scripted, attributes, values);
      const depend = treeKey.dependencies
      values = FormGroupHelper.resetInFormWithTree(values, depend, attributes)
    }

    return values;
  },

  getDependencyFields: (fieldChanges, mainForm, allForms, originValues) => {
    const keys = Object.keys(fieldChanges);
    const ignoreKeys = [...keys];
    //TODO should check null values
    const originKeys = Object.keys(originValues);

    const mainAttrs = mainForm.attributes;
  },
  getDependencyFieldsByScripted: scripted => {
    const regexp = /{{(\w+)}}/g;
    const matches = scripted.match(regexp);
    const arr = [...new Set(matches)];
    const result = arr.map(x => x.replace('{{', '').replace('}}', ''));
    console.log('getDependencyFieldsByScripted>>>', result);
    return result;
  },
  getMapInternalIds: attributes => {
    const mapInternalId = {};
    attributes.map((x, index) => {
      const scripted = x?.condition?.react;
      if (!!x.internalId && x?.condition?.react) {
        mapInternalId[x.internalId] = { order: index, scripted: scripted, internalId: x.internalId };
      }
    });
    return mapInternalId;
  },
  getDependencyFieldsByOtherScripteds: (internalId, order, mapInternalIds) => {
    const f_map = Object.values(mapInternalIds).filter(x => x.order > order);
    const rs = {};
    for (const fMapElement of f_map) {
      const scripted = fMapElement.scripted;
      if (scripted.includes('{{' + internalId + '}}')) {
        rs[fMapElement.internalId] = fMapElement;
      }
    }

    return {
      internalId: internalId,
      order: order,
      dependencies: rs,
    };
  },
  getDependencyFieldsByOtherScriptedsRecursive: (mapInternal, mapInternalIds) => {
    //Nen ap dung giai thuat duyet cay de giam thieu vong lap de quy
    const f_map = Object.values(mapInternalIds).filter(x => x.order > mapInternal.order);
    const rs = {};
    for (const fMapElement of f_map) {
      const scripted = fMapElement.scripted;
      if (scripted.includes('{{' + mapInternal.internalId + '}}')) {
        // rs[fMapElement.internalId] = fMapElement;
        rs[fMapElement.internalId] = FormGroupHelper.getDependencyFieldsByOtherScriptedsRecursive(
          { ...fMapElement },
          mapInternalIds,
        );
      }
    }

    return {
      ...mapInternal,
      dependencies: rs,
    };
  },
  getDependencyFieldsByOtherScriptedsRecursiveV2: (mapInternal, mapInternalIds, values) => {
    //Nen ap dung giai thuat duyet cay de giam thieu vong lap de quy
    const f_map = Object.values(mapInternalIds).filter(x => x.order > mapInternal.order);
    const rs = {};
    for (const fMapElement of f_map) {
      const scripted = fMapElement.scripted;
      if (scripted.includes('{{' + mapInternal.internalId + '}}') && checkEvalCondition(scripted, values)) {
        // rs[fMapElement.internalId] = fMapElement;
        rs[fMapElement.internalId] = FormGroupHelper.getDependencyFieldsByOtherScriptedsRecursiveV2(
            { ...fMapElement },
            mapInternalIds,
            values
        );
      }
    }

    return {
      ...mapInternal,
      dependencies: rs,
    };
  },
  addOrderIndexInForm: formStructure => {
    const mainAttrs = formStructure.attributes;
    mainAttrs.map((x,index) => {
      x.index = index
    })
    return formStructure
  },
  getValuesWhenSubmit: (values, formValues, formStructure, otherStructures, setFieldValue) => {
    //TODO: check & reset value when submit
    //1. get field change value from main form
    //2. get list field depend on main field in other forms.
    //3. get list field depend on other fields in other form
    // optimize thì cần loại cái form main, theo chiều A => B => C => D.
    // ở các form thì các field ảnh hưởng theo chiều trên => dưới, dưới ko thể ảnh hưởng tới trên.
    // loop như thế nào để hết dần field. F1A1 => F2A2 => F2A3 => F2A4 => F3A1 =>
    console.log('getValuesWhenSubmit>>>', formStructure, otherStructures)
    const keyChanges = FormGroupHelper.getFieldChanged(values, formValues)
    console.log('keyChangeds>>', keyChanges)
    // FormGroupHelper.getDependencyFieldsWithAllForm(keyChanges, formStructure, otherStructures, {
    //   ...values,
    //   ...formValues
    // })
    formStructure = FormGroupHelper.addOrderIndexInForm(formStructure)
    for (let otherStructure of otherStructures) {
      otherStructure = FormGroupHelper.addOrderIndexInForm(otherStructure)
    }
    let fixValues = {
      ...values,
      ...formValues
    }
    if(_.isEmpty(keyChanges)){
      return fixValues
    }

    const fieldInfos = FormGroupHelper.calculateInfoOfForm(otherStructures)
    const rs = []

    for (const keyChange in keyChanges) {
        const item = fieldInfos[keyChange]
        rs.push({...item})
    }

    // rs.sort(x => x.order)
    const orderFieldChanges = _.sortBy(rs, ['order'])
    for (const orderFieldChange of orderFieldChanges) {
      if(!(orderFieldChange.internalId in fieldInfos)){
        continue
      }
      fieldInfos[orderFieldChange.internalId].checked = true
      fixValues = FormGroupHelper.calculateFieldChanged(orderFieldChange.internalId, formStructure, otherStructures, fixValues, orderFieldChange.order, fieldInfos)
      console.log('fixValues>>>', fixValues)
    }

    //TODO: should reset field change
    const resetFields = FormGroupHelper.getFieldChanged(fixValues, formValues)
    if(!_.isEmpty(resetFields)){
      // setFieldValue()
      // console.log('resetFields>>>', resetFields)
      for (const resetFieldsKey in resetFields) {
        if(fieldInfos[resetFieldsKey].step !== formStructure.step){
          continue
        }
        console.log('setFieldValue>>>', resetFieldsKey, fixValues[resetFieldsKey])
        setFieldValue(resetFieldsKey, fixValues[resetFieldsKey], true)
      }
    }

    return fixValues
  },
  checkHideGroupForm: (structure, originValues) => {
    const form = structure?.attributes
    // console.log('checkHideGroupForm>>>',structure?.title)
    if(_.isEmpty(form) || _.isEmpty(originValues)){
      return true
    }
    let values = {
      ...originValues,
      ...getInitialFormValueOrigin(form, originValues)
    }

    let rs = true
    for (const formElement of form) {
      const internalId = formElement.internalId
      const type = formElement.type
      if(type === FORM.FORM_TYPE.hidden){
        continue
      }
      if(_.isEmpty(internalId)){
        continue
      }

      const scripted = formElement?.condition?.react
      if(_.isEmpty(scripted)){
        rs = false
        break;
      }

      const show = checkEvalCondition(scripted, values)
      // console.log('checkHideGroupForm>>>formElement>>>', internalId, scripted, show, values)

      if(show){
        rs = false
        break
      }
    }
    return rs
  }
};
