import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppButton, AppKAView, AppSafeAreaView, AppText } from '@components';
import { AppConfirmModal } from '@components/AppModal/AppConfirmModal';
import { AppSuccessScreen } from '@components/AppSuccessScreen';
import { FormItemView } from '@components/Form';
import GenericHeader from '@components/GenericHeader';
import { UPLOAD_FILE } from '@constant';
import { FORM } from '@constant/model/RegisterForm';
import { SuccessMessageMock } from '@constant/model/mock/successMessage';
import { CommonActions, useNavigation } from '@react-navigation/native';
import { goBack, popToTop, reset } from '@routes/navigationUtils';
import { BOTTOM_TAB, DASHBOARD_TAB, Screens } from '@routes/route';
import { Dashboard } from '@screens/main/Dashboard';
import { TopBundleView } from '@screens/main/report/bundle/form/components/TopBundle.view';
import { ReportSuccessfulView } from '@screens/main/report/package/form/components/ReportSuccessful.view';
import { TopPackageView } from '@screens/main/report/package/form/components/TopPackage.view';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font, Spacing } from '@theme/size';
import { useLocation } from '@utils/hooks';
import { FormTransform } from '@utils/transform/form';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Text, Platform } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useReportBundleForm } from './ReportBundleForm.hook';
import { ReportBundleFormStyle } from './ReportBundleForm.style';

export const ReportBundleFormScreen = props => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const {
    formik,
    data,
    summary,
    isSuccess,
    userLocation,
    setUserLocation,
    point,
    headerTitle,
    subFormRef,
    scrollToY,
    scrollViewRef,
    layoutInfo,
    setLayoutInfo,
    messageInfo,
    disabled,
    mapSettings,
    onBackPress,
    locationType,
    onYesConfirm,
    onNoConfirm,
    showConfirmModal,
  } = useReportBundleForm(props);
  // const messageInfo = SuccessMessageMock;
  const subForm = FormTransform.getSubFormDispatch(data?.attributes);
  const verifyOnMap = FormTransform.getVerifyOnMap(data?.attributes);
  console.log('ReportBundleFormScreen>>>', subForm, data?.attributes);
  const renderForm = () => {
    return (
      <>
        <KeyboardAwareScrollView
          extraHeight={150}
          innerRef={ref => {
            if (ref) {
              scrollViewRef.current = ref;
            }
          }}
          style={[
            {
              backgroundColor: palette.WHITE,
              borderTopRightRadius: theme.spacing.lg,
              borderTopLeftRadius: theme.spacing.lg,
              // flex: 1,
            },
          ]}
          contentContainerStyle={{ flexGrow: 1 }}
          showsVerticalScrollIndicator={false}
          keyboardShouldPersistTaps="handled">
          {/*<View style={[globalStyle.flex1]}>*/}
          <TopBundleView data={summary} />
          <View style={[globalStyle.directionColumm, RegisterFormStyles.paddingSpace, globalStyle.flex1]}>
            {data?.attributes?.map((item, index) => {
              return (
                <FormItemView
                  key={`${item?.internalId}_${index}`}
                  style={[{ zIndex: Platform.OS === 'web' ? 'unset' : undefined }]}
                  index={index}
                  item={item}
                  formik={formik}
                  isBoldTitle={true}
                  checkCondition={true}
                  extraData={{
                    location: userLocation,
                    subForm: subForm,
                    mapSettings: mapSettings,
                    verifyOnMap: verifyOnMap,
                    locationType: locationType,
                  }}
                  formData={data?.attributes}
                  folderUpload={UPLOAD_FILE.folderUpload.bundle}
                  subFormRef={subFormRef}
                  onLayout={e => {
                    const layout = e?.nativeEvent?.layout;
                    if (!layout) {
                      return;
                    }
                    const newInfo = { ...layoutInfo };
                    newInfo[item?.internalId] = layout.y + layout.height;
                    setLayoutInfo(newInfo);
                  }}
                  scrollViewRef={scrollViewRef}
                />
              );
            })}
          </View>
          <View
            style={[
              RegisterFormStyles.paddingSpace,
              { marginVertical: theme.spacing.sm, marginBottom: Spacing.height25 },
            ]}>
            <AppButton
              disabled={disabled}
              onPress={async () => {
                // formik.handleSubmit()
                // await FormTransform.submitWithSubForm(
                //   formik,
                //   subFormRef,
                //   data?.attributes,
                //   FORM.FORM_ELEMENT_NAME.dispatch_point,
                // );
                await FormTransform.submitAndScrollWithSubForm(
                  formik,
                  data?.attributes,
                  layoutInfo,
                  scrollToY,
                  subFormRef,
                  FORM.FORM_ELEMENT_NAME.dispatch_point,
                );
              }}
              text={t('REPORT')}
            />
          </View>
        </KeyboardAwareScrollView>
      </>
    );
  };
  return (
    <>
      <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
      <AppSafeAreaView areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>
        <GenericHeader
          onBackClicked={() => onBackPress()}
          // title={t('REPORT_BUNDLE_DISPATCH')}
          title={headerTitle}
          style={[{ backgroundColor: '#EDEDED' }]}
        />
        <View style={[globalStyle.directionColumm, RegisterFormStyles.containerView]}>
          {isSuccess ? (
            <AppSuccessScreen
              alert={messageInfo?.alert}
              icon={messageInfo?.icon}
              description={messageInfo?.description}
            />
          ) : (
            renderForm()
          )}
        </View>
        <AppConfirmModal
          onYesModalPress={onYesConfirm}
          onNoModalPress={onNoConfirm}
          showModal={showConfirmModal}
          textContent={mapSettings?.verifySetting?.message?.submitVerifyMessage}
        />
      </AppSafeAreaView>
    </>
  );
};
