import { CommonActions, useNavigation, useRoute } from '@react-navigation/native';
import { resetUserValidation } from '@redux/auth/authSlice';
import { authCreator } from '@redux/auth/selectors';
import { navigatePop, popToTop } from '@routes/navigationUtils';
import { LOGIN_SCREEN, Screens } from '@routes/route';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useRegisterCompleted = props => {
  const dispatch = useDispatch();
  const userValidation = useSelector(authCreator);
  const navigation = useNavigation();
  const messageInfo = useRoute().params?.data;

  // console.log('useRegisterCompleted>>>>');
  const waitingForSendMail = () => {
    // popToTop()
    console.log('waitingForSendMail>>>>');
    setTimeout(() => {
      // dispatch(resetUserValidation({}));
      // navigation.dispatch(
      //   CommonActions.reset({
      //     index: 0,
      //     routes: [
      //       {
      //         name: Screens.LOGIN_SCREEN_VERTICAL,
      //       },
      //     ],
      //   }),
      // );
      onClose()
    }, 6000);
  };

  const onClose = () => {
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [
          {
            name: Screens.LOGIN_SCREEN_VERTICAL,
          },
        ],
      }),
    );
  };
  useEffect(() => {
    // if (!_.isEmpty(userValidation)) {
    // waitingForSendMail();
    // }
  }, [userValidation]);

  return { waitingForSendMail, messageInfo, onClose };
};
