import submitBundleSlice from '@redux/bundle/submitBundleSlice';
import getListPostingLocationSlice from '@redux/bundle/getListPostingLocationSlice';
import getListAddressSlice from '@redux/bundle/getListAddressSlice';

export const bundleReducer = {
 submitBundle: submitBundleSlice,
 getListPostingLocation: getListPostingLocationSlice,
 getListAddress: getListAddressSlice,
};

