import { palette } from '@theme/colors';
import { deviceWidth } from '@theme/size';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: palette.WHITE,
    // position: 'absolute',
    // marginHorizontal: 16,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 64,
  },
  text: {
    // paddingVertical: 16,
  },
});
