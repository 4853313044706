import { Constant } from '@constant';
import { getListAddressRequest } from '@redux/bundle/getListAddressSlice';
import { BundleTransform } from '@utils/transform/bundle';
import * as _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export const useBundlePostCode = props => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {}, [dispatch]);

  const onTextChanged = text => {
    console.log('onTextChanged>>>', text);
    // if (props.onLoadedData) {
    //   props.onLoadedData([], text, false);
    // }
    // setSearchText(text);
    // if (_.isEmpty(text)) {
    //   // setLoading(false)
    //   setData([]);
    //   return;
    // }
    // const nextValue = { search: text, type: props.dispatchType, location: props?.location };
    // console.log('nextValue>>>', nextValue);
    //
    // debounceSearch(nextValue);
  };

  const onSearchPress = () => {
      console.log('onSearchPress>>>',props)
      const metaItem = props.metaItem
      if(!_.isNil(metaItem?.minSearch) && props.value.length <  metaItem?.minSearch){
          //FIXME: what should we do?
          if(_.isEmpty(props.value)){
              setLoading(false);
              if (props.onLoadedData) {
                  //NOTE: it will show validate error
                  props.onLoadedData([], props.value, false);
              }
          }

          return
      }
    searchForAddressApi(
      {
        search: props.value,
        type: props.dispatchType,
        location: props?.location,
          posting_datetime: props.posting_datetime,
        hideLoading: true,
      },
      true,
    );
  };

  const debounceSearch = useCallback(
    _.debounce((nextValue, nextType) => {
      searchForAddressApi({ ...nextValue, hideLoading: true }, true);
    }, 700),
    [],
  );

  const searchForAddressApi = (params, isReset, isPullToRefresh = false, isLoadMore = false) => {
    /*
              payload_info
               */
    setLoading(true);
    const search = params?.search;
    const payload = {
      postal_code: search,
      type: params.type,
        posting_datetime: params.posting_datetime,
        ...params.location,
    };
    console.log('searchForAddressApi>>>', payload);
    dispatch(
      getListAddressRequest(
        payload,
        rs => {
          console.log('response>>>', rs, search);
          const data = BundleTransform.mappingAddress(rs?.data);
          setData(data);
          setLoading(false);
          if (props.onLoadedData) {
            props.onLoadedData(data, search, true);
          }
        },
        err => {
          setLoading(false);
          if (props.onLoadedData) {
            props.onLoadedData([], search, false);
          }
        },
      ),
    );
  };

  return { loading, data, onTextChanged, onSearchPress };
};
