import { Constant } from '@constant';
import { Config } from '@constant/config';
import { ReportBundleMockData } from '@constant/model/mock/report/bundle';
import { searchForAddressRequest } from '@redux/address/searchForAddressSlice';
import { getListPostingLocationRequest } from '@redux/bundle/getListPostingLocationSlice';
import { getListPostingLocationStandard } from '@redux/bundle/selectors';
import { navigatePop } from '@routes/navigationUtils';
import { mappingAddress } from '@utils/transform/address';
import { BundleTransform } from '@utils/transform/bundle';
import * as _ from 'lodash';
import {useCallback, useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useNearestAddressContainerModal = props => {
  const postcode = props?.postCode;
  const search = props?.searchText;
  const onSelected = props?.onSelect;
  const dispatch = props?.dispatch;
  const [loadingMore, setLoadingMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState(search);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [totalPage, setTotalPage] = useState(2);
  const [pageNumber, setPageNumber] = useState(Constant.START_PAGE);

  const refLocation = useRef(null);

  // const getListPostingLocationData = useSelector(getListPostingLocationStandard);
  console.log('useNearestAddressContainerModal>>>', props)
  // const [loadedData, setLoadedData] = useState(false);
  // const {postcode:'', onSelected : null}: any = props?.route?.params;

  const [currentPayload, setCurrentPayload] = useState({
    postcode: postcode,
    PageNumber: Constant.START_PAGE,
    PageSize: Constant.SIZE_PAGE,
    // Status: 0,
    hideLoading: false,
  });
  const onTextChanged = text => {
    console.log('onTextChanged>>>', text);
    setSearchText(text);
    // if (_.isEmpty(text)) {
    //   setLoading(false);
    //   setData([]);
    //   return;
    // }
    debounceSearch(text);
  };
  const onSearch = text => {
    // getCustomerListApi(text)
    console.log('onSearch>>>searchForAddressApi>>>')
    searchForAddressApi(
      {
        ...currentPayload,
        search: searchText,
      },
      true,
    );
  };

  const debounceSearch = useCallback(
    _.debounce(nextValue => {
      searchForAddressApi({ search: nextValue, hideLoading: true }, true);
    }, 700),
    [],
  );
  
  // const handleSearch = useCallback(() => {
  //
  // }, )

  const searchForAddressApi = (params, isReset, isPullToRefresh = false, isLoadMore = false) => {
    /*
              payload_info
               */
    const request = {
      postcode: postcode,
      ...params,
      // SearchTerm: searchText,
      // hideLoading: isLoadMore,
      PageNumber: isReset ? Constant.START_PAGE : params.PageNumber,
    };
    if (isLoadMore) {
      setLoadingMore(true);
    }
    setLoading(true);

    if (Config.IsDebug) {
      setData(BundleTransform.mappingAddress(ReportBundleMockData.address));
      setLoading(false);
      return;
    }

    const payload = {
      // longitude: 0.10444991327,
      // latitude: 51.49849611888,
      ...refLocation.current,
      type: props.dispatchType,
      posting_datetime: props.posting_datetime,
    };
    dispatch(
      getListPostingLocationRequest(
        payload,
        rs => {
          const data = BundleTransform.mappingAddress(rs?.data);
          setData(data);
          setLoading(false);
        },
        err => {
          setLoading(false);
        },
      ),
    );
  };

  const onRefresh = () => {
    setIsRefreshing(false);
    searchForAddressApi(
      {
        ...currentPayload,
        search: searchText,
      },
      true,
    );
  };

  const onItemPress = item => {
    if (props?.isFullScreen) {
      navigatePop();
    }
    console.log('onItemPress1>>>', item);

    if (onSelected) {
      console.log('onItemPress>>>', item);
      const originItem = _.cloneDeep(item);
      // delete originItem.title;
      onSelected(item?.address, originItem);
    }
  };

  const handleLoadMore = () => {
    console.log('handleLoadMore>>>', totalPage, pageNumber, currentPayload);
    totalPage >= pageNumber + 1 &&
      searchForAddressApi(
        {
          ...currentPayload,
          PageNumber: pageNumber + 1,
          search: searchText,
          hideLoading: true,
        },
        false,
        false,
        true,
      );
  };

  // useEffect(() => {
  //   // searchForPostCodeApi();
  //   console.log('useEffect>>>onTextChanged>>>props?.location>>>', props?.location)
  //   onTextChanged(search);
  // }, [props?.location?.latitude, props?.location?.longitude]);

  // useEffect(() => {
  //   setData(_.cloneDeep(getListPostingLocationData));
  // }, [getListPostingLocationData]);

  useEffect(() => {}, [dispatch]);
  useEffect(() => {
    console.log('useNearestAddressContainerModal>>>show>>>props.location>>>', props.location)
    refLocation.current = props.location
    onTextChanged(search);

    return () => {
      refLocation.current = null
      console.log('useNearestAddressContainerModal>>>remove')
    }
  }, []);

  return {
    onRefresh,
    onSearch,
    onTextChanged,
    handleLoadMore,
    data,
    loadingMore,
    loading,
    isRefreshing,
    searchText,
    onItemPress,
  };
};
