import theme, { WEB_WIDTH } from '@theme';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { deviceWidth } from '@theme/size';
import { absoluteFill, Platform, StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  areaStyle: { backgroundColor: '#EDEDED' },
  map: {
    ...StyleSheet.absoluteFillObject,
    // borderTopRightRadius: 20,
    // borderTopLeftRadius: 20,
    // width: Device.width,
    // height: Device.height,
    // flex: 1,
    // marginBottom: 52,
    // marginTop:64,
    // marginBottom:64,
  },
  bottomView: {
    width: Platform.OS === 'web' ? WEB_WIDTH - 32 : deviceWidth - 32,
    minHeight: 120,
    backgroundColor: palette.WHITE,
    // position: 'absolute',
    marginBottom: 16,
    borderRadius: 5,
    padding: 12,

    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
  },
  containerView: {
    ...StyleSheet.absoluteFillObject,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    backgroundColor: '#EDEDED',
    width: '100%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    overflow: 'hidden',
  },
});
