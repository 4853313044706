import { getListQuizSaga } from '@redux/quizzes/getListQuizSaga';
import { getListQuizRequest } from '@redux/quizzes/getListQuizSlice';
import { getQuizSaga } from '@redux/quizzes/getQuizSaga';
import { getQuizRequest } from '@redux/quizzes/getQuizSlice';
import { ForkEffect, takeLatest } from 'redux-saga/effects';

function* quizzesSagas(): Generator<ForkEffect<never>, void> {
  yield takeLatest(getListQuizRequest.type, getListQuizSaga);
  yield takeLatest(getQuizRequest.type, getQuizSaga);
}

export default quizzesSagas;
