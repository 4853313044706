import {createAction, createSlice} from '@reduxjs/toolkit';

export interface IlistVoucherState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IlistVoucherState = {
  loading: false,
  payload: null,
  error: null,
};

export const listVoucherRequest = createAction(
    'listVoucher/listVoucherRequest',
    (payload, onSuccess, onFailed) => {
      console.log('prepareAction>>>', payload, onSuccess);
      return {
        payload: payload,
        meta: onSuccess,
        error: onFailed,
      };
    },
);

const listVoucherSlice = createSlice({
  name: 'listVoucher',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // listVoucherRequest(state, action) {
    //   state.loading = true;
    //   // @ts-ignore
    //   state.payload = null;
    //   state.error = null;
    // },
    listVoucherSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    listVoucherFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { listVoucherSuccess, listVoucherFailed } = listVoucherSlice.actions;

export default listVoucherSlice.reducer;
