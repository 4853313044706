import {
  hideCameraPermissionModal,
  hideLocationPermissionModal,
  hideNotificationImportantModal
} from '@redux/common/commonSlice';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  notificationImportantModalVisible as notificationImportantModalVisibleSelector
} from "@redux/common/selectors";
import {importantNotificationSelector} from "@redux/app/selectors";
import {navigate} from "@routes/navigationUtils";
import {useState} from "react";

export const useCheckStatusModal = props => {
  const dispatch = useDispatch();
  const {show, setShow} = props

  // const data = useSelector()
  const handlePressButton = () => {
      setShow(false)
  };
  const handleHideModal = () => {
      setShow(false)
  };
  return {  handlePressButton, handleHideModal, show, setShow };
};
