import theme from '@theme';
import { palette } from '@theme/colors';
import { FontSize } from '@theme/size';
import { StyleSheet } from 'react-native';

export const TopBundleStyle = StyleSheet.create({
  topView: {
    borderRadius: 8,
    margin: theme.spacing.md,
    padding: theme.spacing.md,
    marginBottom: 24,
  },
  textTitle: {
    fontWeight: '700',
    fontSize: FontSize.Font30,
    color: palette.WHITE,
  },
  textNormal: {
    fontWeight: '400',
    // fontSize: FontSize,
    color: palette.WHITE,
  },
});
