import { ChevronRightIcon, LogoIcon, MessageCheckIcon } from '@assets';
import { AppAccordion, AppKAView, AppSafeAreaView, AppText } from '@components';
import GenericHeader from '@components/GenericHeader';
import { goBack } from '@routes/navigationUtils';
import { styles } from '@screens/main/survey/SurveyList/SurveyList.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font } from '@theme/size';
import { getContentTitle } from '@utils/transform/form';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Text, ScrollView, TouchableOpacity } from 'react-native';
import { useD2DOverview } from './D2DOverview.hook';
import { D2DOverviewStyle } from './D2DOverview.style';

const OverviewItem = ({ data, onPress }) => {
  return (
    <TouchableOpacity
      key={data?.key}
      style={[D2DOverviewStyle.itemView, { backgroundColor: data?.color }]}
      onPress={() => {
        if (onPress) {
          onPress(data);
        }
      }}>
      <AppText style={styles.itemText}>{getContentTitle(data?.name)}</AppText>
      {/*<ChevronRightIcon />*/}
    </TouchableOpacity>
  );
};
export const D2DOverviewScreen = props => {
  const { t } = useTranslation();
  const { getOverviewD2DData, onItemPress } = useD2DOverview(props);
  return (
    <>
      {/*<StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />*/}
      {/*<AppSafeAreaView areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>*/}
      {/*  <GenericHeader onBackClicked={ goBack } title="D2DOverview" style={[{ backgroundColor: '#EDEDED' }]} />*/}
      {/* <AppKAView>*/}
      {/*  <View style={ globalStyle.flex1 }>*/}
      {/*   <Text>D2DOverview</Text>*/}
      {/*  </View>*/}
      {/*</AppKAView>*/}
      {/*</AppSafeAreaView>*/}
      <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
      <AppSafeAreaView areaStyle={D2DOverviewStyle.areaStyle}>
        <GenericHeader onBackClicked={goBack} title={t('D2D_HEADER')} style={[{ backgroundColor: '#EDEDED' }]} />
        <View
          style={{
            borderTopRightRadius: theme.spacing.lg,
            borderTopLeftRadius: theme.spacing.lg,
            backgroundColor: palette.WHITE,
            width: '100%',
            flex: 1,
          }}>
          <ScrollView contentContainerStyle={{ marginTop: 15, paddingHorizontal: 15 }}>
            {getOverviewD2DData?.map(item => {
              return <OverviewItem data={item} onPress={onItemPress} />;
            })}
          </ScrollView>
        </View>
      </AppSafeAreaView>
    </>
  );
};
