import { createSlice } from '@reduxjs/toolkit';

export interface IgetProfileFormState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IgetProfileFormState = {
  loading: false,
  payload: null,
  error: null,
};

const getProfileFormSlice = createSlice({
  name: 'getProfileForm',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getProfileFormRequest(state, action) {
      state.loading = true;
      // @ts-ignore
      // state.payload = null;
      state.error = null;
    },
    getProfileFormSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    getProfileFormFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getProfileFormRequest, getProfileFormSuccess, getProfileFormFailed } = getProfileFormSlice.actions;

export default getProfileFormSlice.reducer;
