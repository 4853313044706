import { MessageCheckIcon, CrossIcon, WarningIcon } from '@assets';
import { AppAlert, AppText, AppButton } from '@components';
import { ForceUpdateStyles } from '@components/AppForceUpdate/ForceUpdate.style';
import { PERMISSION_MODEL } from '@constant';
import theme, { globalStyle } from '@theme';
import { deviceWidth, Font, FontSize } from '@theme/size';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';
import { useCameraPermissionModal } from './PermissionModal.hook';
import _ from 'lodash'

export const PermissionModal = props => {
  const { show, type = PERMISSION_MODEL.CAMERA } = props;
  const { handleOpenSettings, handleHideModal, notificationPermissionModal } = useCameraPermissionModal(props);
  const { t } = useTranslation();

  const text = useMemo(() => {
    switch (type) {
      case PERMISSION_MODEL.CAMERA:
        return {
          title: t('CAMERA_BLOCKED'),
          desc: `${t('CAMERA_PROHIBITED')} ${t('ENABLE_SETTINGS')}`,
        };
      case PERMISSION_MODEL.LOCATION:
        return {
          title: t('NO_LOCATION_ACCESS'),
          desc: `${t('WHY_NEED_LOCATION')}`,
        };
        case PERMISSION_MODEL.NOTIFICATION:
            const description = notificationPermissionModal.description
            return {
                title: t('NOTIFICATION_BLOCKED'),
                // desc: _.isEmpty(description) ? `${t('NOTIFICATION_PROHIBITED')} ${t('ENABLE_SETTINGS')}` : description,
                desc: _.isEmpty(description) ? t('NOTIFICATION_DEVICE_OFF') : description,

            };
      default:
        break;
    }
  }, [type, t]);

  const { title, desc } = text;

  return (
    <AppAlert
      onDismiss={handleHideModal}
      closeOnTouchOutside
      show={show}
      contentContainerStyle={{ maxWidth: '92%' }}
      customView={
        <View style={{ paddingHorizontal: 8 }}>
          <TouchableOpacity
            onPress={handleHideModal}
            style={{
              position: 'absolute',
              top: 6,
              right: 6,
              backgroundColor: '#c9c9c9',
              zIndex: 1,
              padding: 6,
              borderRadius: 1000,
            }}>
            <CrossIcon color="white" />
          </TouchableOpacity>
          <View style={[globalStyle.directionColumm, { marginTop: 24 }]}>
            <View style={[globalStyle.center]}>
              <WarningIcon width={45} height={45} />
            </View>
            <View style={[globalStyle.center, { paddingVertical: 16 }]}>
              <AppText style={[Font.Bold_700, globalStyle.center]}>{title}</AppText>
              <AppText
                style={[
                  {
                    fontSize: FontSize.Font14,
                    paddingVertical: 8,
                    textAlign: 'center',
                    color: '#666666',
                  },
                ]}>
                {desc}
              </AppText>
            </View>
          </View>
          <View style={[ForceUpdateStyles.row, { marginVertical: theme.spacing.md }]}>
            <AppButton
              // style={ForceUpdateStyles.navigationButton}
              onPress={handleOpenSettings}
              text={t('GO_TO_SETTINGS')}
            />
          </View>
        </View>
      }
    />
  );
};
