import { LogoIcon, MessageCheckIcon } from '@assets';
import {AppButton, AppKAView, AppSafeAreaView, AppText} from '@components';
import GenericHeader from '@components/GenericHeader';
import {goBack, popToTop} from '@routes/navigationUtils';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import {Font, Spacing} from '@theme/size';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Text } from 'react-native';
import { useDetail } from './Detail.hook'
import { DetailStyle } from './Detail.style'
import {useCDSForm} from "@screens/main/CDS/form/CDSForm.hook";
import {RegisterFormStyles} from "@screens/register/RegisterForm/RegisterForm.style";
import {KeyboardAwareScrollView} from "react-native-keyboard-aware-scroll-view";
import {FormItemView} from "@components/Form";
import {FORM} from "@constant/model/RegisterForm";
import _ from "lodash";
import {checkFormValidateExpression} from "@utils/form";
import {min} from "lodash/math";
import {AppSuccessScreen} from "@components/AppSuccessScreen";
import {RenderHelper} from "@utils/form/render";

export const DetailScreen = (props) => {
    const { t } = useTranslation();
    const {
        data,
        formik,
        headerTitle,
        scrollViewRef,
        scrollToEnd,
        onCheckValid,
        paramsData,
        layoutInfo,
        setLayoutInfo,
        scrollToY,
        isSuccess,
        messageInfo,
        lastStep
    } = useDetail(props);

    const refLayoutInfo = useRef(null)
    useEffect(() => {
        refLayoutInfo.current = {}
    },[])

    const renderForm = () => {
        return (
            <View style={[globalStyle.directionColumm, globalStyle.flex1, RegisterFormStyles.containerView]}>
                <KeyboardAwareScrollView
                    innerRef={ref => {
                        if (ref) {
                            scrollViewRef.current = ref;
                        }
                    }}
                    extraHeight={150}
                    style={[
                        {
                            backgroundColor: palette.WHITE,
                            borderTopRightRadius: theme.spacing.lg,
                            borderTopLeftRadius: theme.spacing.lg,
                            paddingTop: theme.spacing.md,
                        },
                    ]}
                    contentContainerStyle={{ flexGrow: 1 }}
                    showsVerticalScrollIndicator={false}
                    keyboardShouldPersistTaps="handled">
                    {/*<TopBundleView data={summary} />*/}
                    <View style={[globalStyle.directionColumm, RegisterFormStyles.paddingSpace, globalStyle.flex1, {}]}>
                        {data?.attributes?.map((item, index) => {
                            return (
                                <FormItemView
                                    index={index}
                                    item={item}
                                    formik={formik}
                                    isBoldTitle={true}
                                    checkCondition={true}
                                    // extraData={{
                                    //   location: userLocation,
                                    //   subForm: subForm,
                                    // }}
                                    formData={data?.attributes}
                                    extraFormValues={formik.values}
                                    extraData={paramsData}
                                    scrollViewRef={scrollViewRef}
                                    onSelect={(item, value) => {
                                        if (item.type === FORM.FORM_TYPE.misdelivered_item_form) {
                                            scrollToEnd(400);
                                        }
                                    }}
                                    onCheckValid={onCheckValid}
                                    onLayout={e => {
                                        // const layout = e?.nativeEvent?.layout;
                                        // if (!layout) {
                                        //     return;
                                        // }
                                        // const newInfo = { ...layoutInfo };
                                        // newInfo[item?.internalId] = layout.y + layout.height;
                                        // setLayoutInfo(newInfo);
                                        RenderHelper.onLayout(e, item, layoutInfo, setLayoutInfo, refLayoutInfo)
                                    }}
                                />
                            );
                        })}
                    </View>
                    <View
                        style={[
                            RegisterFormStyles.paddingSpace,
                            { marginVertical: theme.spacing.sm, marginBottom: Spacing.height25 },
                        ]}>
                        <AppButton
                            onPress={async () => {
                                const vl = await formik.validateForm(formik.values);
                                const x = await formik.submitForm();

                                console.log('submitForm>>>', formik, x, vl);
                                let err = {
                                    ...vl,
                                    ...formik.errors,
                                };
                                if (_.isEmpty(err)) {
                                    err = checkFormValidateExpression(data?.attributes, formik.values);
                                    if (_.isEmpty(err)) {
                                        return;
                                    }
                                }
                                setTimeout(() => {
                                    const rs = [];
                                    for (const x1 in err) {
                                        rs.push(layoutInfo[x1]);
                                    }

                                    const maxY = min(rs);
                                    if (maxY) {
                                        scrollToY(maxY);
                                    }
                                }, 300);
                            }}
                            text={ lastStep ? t('SUBMIT') : t('NEXT')}
                        />
                    </View>
                </KeyboardAwareScrollView>

            </View>
        );
    };
    return (
        <>
            <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
            <AppSafeAreaView areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>
                <GenericHeader
                    onBackClicked={isSuccess ? () => popToTop() : goBack}
                    // title={t('Misdelivered Items')}
                    title={headerTitle}
                    style={[{ backgroundColor: '#EDEDED' }]}
                />
                <View style={[globalStyle.directionColumm, RegisterFormStyles.containerView]}>
                    {isSuccess ? (
                        <AppSuccessScreen
                            alert={messageInfo?.alert}
                            icon={messageInfo?.icon}
                            description={messageInfo?.description}
                        />
                    ) : (
                        renderForm()
                    )}
                    {/*{isSuccess ? <CDSSuccessfulView /> : renderForm()}*/}
                </View>
            </AppSafeAreaView>
        </>
    );
}
