import { LogoIcon, MessageCheckIcon } from '@assets';
import {AppButton, AppKAView, AppModalV2, AppSafeAreaView, AppText} from '@components';
import { AppPostcodeStyle } from '@screens/main/SearchPostcode/components/AppPostcode.style';
import { AppSearchAddress } from '@screens/main/report/bundle/form/components/BundleAddress.view';
import { BundleAddressContainerModalView } from '@screens/main/report/bundle/form/components/BundleAddressContainerModal.view';
import { NearestAddressContainerModalView } from '@screens/main/report/bundle/form/components/NearestAddressContainerModal.view';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { Font } from '@theme/size';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, Platform } from 'react-native';
import { useDispatch } from 'react-redux';
import { useNearestAddress } from './NearestAddress.hook';
import { NearestAddressStyle } from './NearestAddress.style';

export const NearestAddressView = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { titlePopup, selected, style, error } = props;
  const {
    visible,
    setVisible,
    notInList,
    setNotInList,
    handleOpenScreen,
    handleBackdropPress,
    onSelected,
    onClickHref,
  } = useNearestAddress(props);
  console.log('NearestAddressView>>>>', props.visible, visible, selected);
  console.log('NearestAddressView>>>>props>>>', props);
  console.log('NearestAddressView>>>>error>>>', error);

  return (
    <>
      {!!selected && (
        <AppSearchAddress
          handleOpenScreen={handleOpenScreen}
          value={selected}
          style={style}
          // styleTitle={styleTitle}
          // disabled={disabled}
          // title={title}
          // error={error}
          // isRequired={isRequired}
          // isBoldTitle={isBoldTitle}
          // notInList={notInList}
          // description={props.description}
        />
      )}
      {/*<AppButton text={'abc'}></AppButton>*/}
      {selected === null && (<AppSearchAddress
          handleOpenScreen={handleOpenScreen}
          value={selected}
          style={style}
          // styleTitle={styleTitle}
          // disabled={disabled}
          // title={title}
          error={error}
          // isRequired={isRequired}
          // isBoldTitle={isBoldTitle}
          // notInList={notInList}
          // description={props.description}
          placeHolder={titlePopup}
      />)}
      <AppModalV2 visible={visible} onBackdropPress={handleBackdropPress}>
        <View
          style={[
            globalStyle.directionColumm,
            {
              minHeight: (Device.height * 1) / 2,
              maxHeight: (Device.height * 2) / 3,
              width: Platform.OS === 'web' ? 810 : Device.width * 0.9,
              marginVertical: Platform.OS === 'web' ? 'auto' : undefined,
              // backgroundColor: 'yellow',
              // borderRadius:8,
            },
          ]}>
          {!!titlePopup && (
            <View
              style={AppPostcodeStyle.header}
              onLayout={e => {
                console.log(e.nativeEvent.layout.height);
              }}>
              <AppText style={AppPostcodeStyle.headerTxt}>{titlePopup}</AppText>
            </View>
          )}
          <NearestAddressContainerModalView
            // postCode={props.postCode}
            searchText={''}
            onSelect={onSelected}
            dispatch={dispatch}
            // header={props.titlePopup}
            description={props.descriptionPopup}
            onClickHref={onClickHref}
            dispatchType={props.dispatchType}
            location={props.location}
            placeHolder={props.placeHolder}
            posting_datetime={props.posting_datetime}
          />
          {/*</View>*/}
        </View>
      </AppModalV2>
    </>
  );
};

NearestAddressView.propTypes = {
  // value: PropTypes.any,
  style: PropTypes.any,
  visible: PropTypes.bool,
  selected: PropTypes.any,
  // header: PropTypes.string,
  onSelect: PropTypes.func,
  onVisible: PropTypes.func,
  formik: PropTypes.any,
  descriptionPopup: PropTypes.string,
  titlePopup: PropTypes.string,
  onClickHref: PropTypes.func,
  dispatchType: PropTypes.string,
  location: PropTypes.object,
  posting_datetime: PropTypes.any,
  onBackDrop: PropTypes.func,
};

NearestAddressView.defaultProps = {
  visible: false,
};
