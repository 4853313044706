import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {navigate} from "@routes/navigationUtils";
import {Screens} from "@routes/route";

export const useAppPicker = (props) => {
    const onQRPress = () => {
        navigate(Screens.SCAN_CODE_SCREEN)
    }
    return {
        onQRPress
    }
}