import { Constant } from '@constant/index';

export const ModeApi = {
  green: 'green',
  blue: 'blue',
  normal: 'normal',
};

export const ConfigMode = {
  green: {
    'spectos-apikey': '178cf093-6103-11ed-8860-0acdbf5239cc',
    fdbapi: 'fdbapi-green',
    ttmservice: 'ttmservice-green',
  },
  blue: {
    'spectos-apikey': 'ec2dfab7-6729-11ed-9546-06f4236ff4da',
    fdbapi: 'fdbapi-blue',
    ttmservice: 'ttmservice-blue',
  },
  normal: {
    'spectos-apikey': 'ba2abe97-1969-11ed-a237-0692e6d949ba',
    fdbapi: 'fdb-api',
    ttmservice: 'ttm-service',
  },
};

export const Config = {
  Mock: {
    SignUp: {
      // firstname: 'Hoang Hoa',
      // lastname: 'Nguyen',
      // icode: 'A3677672',
      firstname: 'Lien',
      lastname: 'Le',
      icode: 'RM1234567',
    },
    Token: 'test',
  },
  IsDebug: false,
  IsMockServer: false,
  IsUseToken: false,
  // IsNotification: true,
  // IsTicketDetail: true,
  EnableFeature: {
    notification: true,
    ticketDetail: true,
    postingLocation: true,
  },
  Ignore: {
    GlobalLoading: [
      // Constant.reducer.auth,
      Constant.reducer.forceUpdate,
      Constant.reducer.searchForAddress,
      Constant.reducer.searchForPostCode,
      Constant.reducer.getListStatus,
      Constant.reducer.getProfile,
      Constant.reducer.getListPostingLocation,
      Constant.reducer.getListAddress,
      Constant.reducer.getListTicket,
      Constant.reducer.getQRForm,
      Constant.reducer.getBonusPointList,
        // Constant.reducer.getNotificationModal,
      Constant.reducer.getDashboard,
      Constant.reducer.getSettings,
      Constant.reducer.getReportModal,
      Constant.reducer.downloadReimbursement,

      Constant.reducer.getReportPackageForm,
      Constant.reducer.getICodeForm,
      Constant.reducer.getReportBundleForm,
      Constant.reducer.getRFIDForm,

    ],
    GlobalError: [
      Constant.reducer.forceUpdate,
      Constant.reducer.updateProfile,
      Constant.reducer.updateUser,
      Constant.reducer.saveFeedbackCDS,
    ],
  },
  Api: {
    spectosLangKey: '59c31284-5261-11ec-8ed7-021be7a6b3ea',
    googleApiKey: 'AIzaSyAlpIvGaWRrN_eyMjAlvjmdFptl7Jnyg8A',
    fdbapi: {
      blue: 'fdbapi-blue',
      green: 'fdbapi-green',
      normal: 'fdb-api',
    },
    ttmservice: {
      blue: 'ttmservice-blue',
      green: 'ttmservice-green',
      normal: 'ttm-service',
    },
    // NOTE: prepro ko có blue,green chỉ có normal
    mode: ModeApi.green,
  },
};
