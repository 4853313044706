import { AntDesignIconsPack } from '@components/IconsAdapter/antdesign-icons';
import { EntypoIconsPack } from '@components/IconsAdapter/entypo-icons';
import { EvilIconsPack } from '@components/IconsAdapter/evil-icons';
import { FeatherIconsPack } from '@components/IconsAdapter/feather-icons';
import { FontAwesome5IconsPack } from '@components/IconsAdapter/fontawesome5-icons';
import { FontAwesomeIconsPack } from '@components/IconsAdapter/fontawesome-icons';
import { FontistoIconsPack } from '@components/IconsAdapter/fontisto-icons';
import { FoundationIconsPack } from '@components/IconsAdapter/foundation-icons';
import { IoniconsIconsPack } from '@components/IconsAdapter/ionicons-icons';
import { MaterialIconsPack } from '@components/IconsAdapter/material-icons';
import { MaterialCommunityIconsPack } from '@components/IconsAdapter/materialcommunity-icons';
import { OcticonsIconsPack } from '@components/IconsAdapter/octicons-icons';
import { SimpleLineIconsIconsPack } from '@components/IconsAdapter/simpleline-icons';
import { ZocialIconsPack } from '@components/IconsAdapter/zocial-icons';
import Splashscreen from '@components/Splashscreen';
import * as eva from '@eva-design/eva';
import '@i18n';
import { store, persistor } from '@redux/store';
import { isMountedRef } from '@routes/navigationUtils';
import { RootScreen } from '@routes/root';
import theme from '@theme';
import { ApplicationProvider, IconRegistry } from '@ui-kitten/components';
import React, { FC, Suspense, useEffect } from 'react';
import 'react-native-gesture-handler';
import { gestureHandlerRootHOC } from 'react-native-gesture-handler';
// import { enableScreens } from 'react-native-screens';
import SplashScreen from 'react-native-splash-screen';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import {Helmet} from "react-helmet";
import {Config} from "@constant/config";

// enableScreens();
// enableLatestRenderer();

const App: FC = () => {
  useEffect(() => {
    isMountedRef.current = true;
    // dispatch(forceUpdateRequest());

    return () => {
      isMountedRef.current = false;
    };
  }, []);
  console.log(isMountedRef);

  return (
      <>
          {/*<Helmet>*/}
          {/*    <script src={`https://maps.googleapis.com/maps/api/js?key=${Config.Api.googleApiKey}`}></script>*/}
          {/*</Helmet>*/}
          <Suspense fallback={<Splashscreen />}>
              <ApplicationProvider {...eva} theme={{ ...eva.light, ...theme }}>
                  <IconRegistry
                      icons={[
                          AntDesignIconsPack,
                          EntypoIconsPack,
                          EvilIconsPack,
                          FeatherIconsPack,
                          FontAwesomeIconsPack,
                          FontAwesome5IconsPack,
                          FontistoIconsPack,
                          FoundationIconsPack,
                          IoniconsIconsPack,
                          MaterialIconsPack,
                          MaterialCommunityIconsPack,
                          OcticonsIconsPack,
                          SimpleLineIconsIconsPack,
                          ZocialIconsPack,
                      ]}
                  />
                  <Provider store={store}>
                      <PersistGate loading={<Splashscreen />} persistor={persistor}>
                          <RootScreen />
                      </PersistGate>
                  </Provider>
              </ApplicationProvider>
          </Suspense>

      </>
  );
};

export default gestureHandlerRootHOC(App);
