import _ from 'lodash';

export const Colors = {
  default_color: '#8D38C9',
  alphabets: [
    '#357EC7', // A
    '#C11B17', // B
    '#008080', // C
    '#728C00', // D
    '#0020C2', // E
    '#347C17', // F
    '#D4A017', // G
    '#7D0552', // H
    '#9F000F', // I
    '#E42217', // J
    '#F52887', // K
    '#571B7E', // L
    '#1F45FC', // M
    '#C35817', // N
    '#F87217', // O
    '#41A317', // P
    '#4C4646', // Q
    '#4CC417', // R
    '#C12869', // S
    '#15317E', // T
    '#AF7817', // U
    '#F75D59', // V
    '#FF0000', // W
    '#000000', // X
    '#E9AB17', // Y
    '#8D38C9', // Z
  ],
  numbers: [
    '#FF0000', // 0
    '#8D38C9', // 1
    '#000000', // 2
    '#008080', // 3
    '#0020C2', // 4
    '#41A317', // 5
    '#7D0552', // 6
    '#C35817', // 7
    '#F75D59', // 8
    '#571B7E', // 9
  ],
};
export const AvatarUtil = {
  sumChars: str => {
    let sum = 0;
    for (let i = 0; i < str.length; i++) {
      sum += str.charCodeAt(i);
    }
    return sum;
  },
  getColor(letter) {
    if (letter) {
      let charCode = letter.charCodeAt(0);
      let isNumber = charCode >= 48 && charCode <= 57;
      let isAlphabet = charCode >= 65 && charCode <= 90;
      if (isNumber || isAlphabet) {
        let charIndex = letter.charCodeAt(0) - (isAlphabet ? 65 : 48);
        let colorIndex = charIndex % (isAlphabet ? Colors.alphabets.length : Colors.numbers.length);
        return isAlphabet ? Colors.alphabets[colorIndex] : Colors.numbers[colorIndex];
      }
    }
    return Colors.default_color;
    // let defaultBackground = Colors.default_color;
    // if (/[A-Z]/.test(initials)) {
    //   let index = initials.charCodeAt() - 65;
    //
    //   if (backgroundColor) {
    //     defaultBackground = backgroundColor;
    //   } else if (Colors && Colors.length) {
    //     let i = sumChars(name) % backgroundColors.length;
    //     defaultBackground = backgroundColors[i];
    //   } else {
    //     defaultBackground = defaultColors[index];
    //   }
    // } else if (/[\d]/.test(initials)) {
    //   defaultBackground = defaultColors[parseInt(initials)];
    // } else {
    //   defaultBackground = '#051923';
    // }
  },
  getNameInitials: (name = '') => {
    // let splittedName = name.toString().toUpperCase().split(' ');
    // if (_.isEmpty(splittedName)) {
    //   if (!_.isEmpty(name)) {
    //     return name.toString().toUpperCase()?.charAt(0);
    //   }
    // }
    let initials = '';
    const names = name.split(' ');
    console.log('splittedName>>>', names, names.length, name);

    if (names.length === 1) {
      initials = names[0].substring(0, 1).toUpperCase();
      if (_.isEmpty(initials)) {
        if (!_.isEmpty(name)) {
          initials = name.toString().toUpperCase()?.charAt(0);
        }
      }
    } else if (names.length > 1) {
      names.forEach((n, i) => {
        initials += names[i].substring(0, 1).toUpperCase();
      });
    }

    console.log('getNameInitials>>>', initials)
    return initials;
  },
};
