import { Constant, TICKET_STATUS } from '@constant';
import { SuccessMessageMock } from '@constant/model/mock/successMessage';
import { getReturnTransponderFormRequest } from '@redux/form/getReturnTransponderFormSlice';
import { navigate } from '@routes/navigationUtils';
import { MENU_WEB_VIEW_SCREEN, Screens, WEB_VIEW_SCREEN_FULLSCREEN } from '@routes/route';
import { getPanelistId } from '@utils';
import { getContentTitle } from '@utils/transform/form';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

export const useLogic = props => {
  const { data } = props;
  const { dispatch } = useDispatch();
  const getReturnTransponderFormApi = async () => {
    dispatch(
      getReturnTransponderFormRequest(
        {
          panelistId: getPanelistId(),
        },
        res => {
          // navigate(Screens.)
        },
        err => {},
      ),
    );
  };

  const handleNavigate = () => {
    if (data.type === 'html')
      navigate(MENU_WEB_VIEW_SCREEN, { html: getContentTitle(data.content), header: getContentTitle(data.title) });
    if (data.type === 'link') {
      // navigate(WEB_VIEW_SCREEN_FULLSCREEN, { uri: data.content });
      if (!_.isEmpty(data.content)) {
        navigate(MENU_WEB_VIEW_SCREEN, { uri: getContentTitle(data.content), header: getContentTitle(data.title) });
      }
    }
    switch (data.internalId) {
      case Constant.menu.report_holidays:
        navigate(Screens.REPORT_SCREEN);
        break;
      case Constant.menu.create_ticket:
        navigate(Screens.TICKET_SCREEN, { tabScreen: Screens.ADD_TICKET_SCREEN });
        break;
      case Constant.menu.list_tickets:
        navigate(Screens.TICKET_SCREEN, { tabScreen: Screens.LIST_TICKET_SCREEN });
        break;
      case Constant.menu.unsubscribe:
        navigate(Screens.UNSUBSCRIBE_SCREEN);
        // navigate(Screens.UNSUBSCRIBE_SUCCESSFUL_SCREEN);
        // navigate(Screens.QUIZ_SUCCESSFUL_SCREEN, { data: SuccessMessageMock });

        break;
      case Constant.menu.reward:
        navigate(Screens.REWARD, {
          tabScreen: Screens.VOUCHER,
        });
        // navigate(Screens.UNSUBSCRIBE_SUCCESSFUL_SCREEN);

        break;
      case Constant.menu.transponder:
        navigate(Screens.TRANSPONDER_FROM_SCREEN);
        // navigate(Screens.UNSUBSCRIBE_SUCCESSFUL_SCREEN);

        break;
      case Constant.menu.reimbursement:
        navigate(Screens.REIMBURSEMENT_SCREEN);
        break;
    }
  };
  return { handleNavigate };
};
