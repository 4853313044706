import { navigate } from '@routes/navigationUtils';
import { PROFILE_EDIT_SCREEN } from '@routes/route';
import { useTranslation } from 'react-i18next';

export const useLogic = () => {
  const { t } = useTranslation();

  const handleGoToProfileEdit = () => {
    navigate(PROFILE_EDIT_SCREEN);
  };
  return { handleGoToProfileEdit, t };
};
