// import styles from '@scenes/Homepage/styles';
// import { fonts } from '@theme/fonts';
import { LogoIcon } from '@assets';
import { AppButton, AppText } from '@components';
import theme, { globalStyle } from '@theme';
import { Font, FontSize } from '@theme/size';
import { Button, Icon } from '@ui-kitten/components';
import PropTypes from 'prop-types';
import React from 'react';
// import { useTranslation } from 'react-i18next';
import { Trans, useTranslation } from 'react-i18next';
import { View, Text, TouchableOpacity } from 'react-native';
import AwesomeAlert from 'react-native-awesome-alerts';
import { useForceUpdate } from './ForceUpdate.hook';
import { ForceUpdateStyles } from './ForceUpdate.style';

export const ForceUpdateView = props => {
  const { onGoToStorePress } = useForceUpdate(props);
  const [t] = useTranslation();
  const hideCancelButton = false;
  return (
    <AwesomeAlert
      show={true}
      animatedValue={1}
      showProgress={false}
      closeOnTouchOutside={false}
      closeOnHardwareBackPress={false}
      showCancelButton={true}
      showConfirmButton={false}
      // cancelButtonColor={Colors.red}
      onCancelPressed={() => {
        // setShowAlert(false);
      }}
      alertContainerStyle={[ForceUpdateStyles.alertContainerStyle]}
      contentContainerStyle={[ForceUpdateStyles.contentContainerStyle]}
      contentStyle={[ForceUpdateStyles.contentStyle]}
      actionContainerStyle={ForceUpdateStyles.actionContainerStyle}
      cancelButtonStyle={[ForceUpdateStyles.cancelButtonStyle]}
      customView={
        <View style={[ForceUpdateStyles.rootView]}>
          <View style={[globalStyle.directionColumm]}>
            <View style={[globalStyle.center, { paddingVertical: theme.spacing.md }]}>
              <LogoIcon />
            </View>
            <View style={[globalStyle.center, { paddingVertical: theme.spacing.lg }]}>
              <AppText style={[Font.Bold_700, globalStyle.ce, { textAlign: 'center' }]}>
                {t('FORCE_UPDATE_TITLE')}
              </AppText>
              <AppText
                style={[{ fontSize: FontSize.Font15, paddingVertical: 8, color: '#666666', textAlign: 'center' }]}>
                {t('FORCE_UPDATE_DESCRIPTION')}
              </AppText>
            </View>

            <View style={[ForceUpdateStyles.row, { marginVertical: theme.spacing.md }]}>
              <AppButton
                // style={ForceUpdateStyles.navigationButton}
                onPress={() => {
                  onGoToStorePress();
                }}
                text={t('FORCE_UPDATE_TO_STORE')}
              />
            </View>
          </View>
        </View>
      }
    />
  );
};

ForceUpdateView.propTypes = {};

ForceUpdateView.defaultProps = {};
