import { UPLOAD_FILE } from '@constant';
import { IMAGE_PICKER_TYPE } from '@constant/model/RegisterForm';
import { deleteFileRequest } from '@redux/file/deleteFileSlice';
import { uploadFileRequest } from '@redux/file/uploadFileSlice';
import { Device } from '@theme/device';
import { requestPermission } from '@utils/common/deviceUtil';
import { FileTransform } from '@utils/transform/file';
import { FormTransform } from '@utils/transform/form';
import _ from 'lodash';
import { useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

import * as ImagePicker from 'react-native-image-picker';
import { useDispatch } from 'react-redux';

export const useAppImagePicker = props => {
  const { onSelect, folderUpload, maxFile } = props;
  const originSelect = props.selected
  const selected = FileTransform.getListImageUrl(originSelect)
  const dispatch = useDispatch();
  const [isShow, setShow] = useState(false);

  const options = [
    {
      id: IMAGE_PICKER_TYPE.camera,
      content: t('CHOOSE_CAMERA'),
      type: IMAGE_PICKER_TYPE.camera,
    },
    {
      id: IMAGE_PICKER_TYPE.gallery,
      content: t('CHOOSE_GALLERY'),
      type: IMAGE_PICKER_TYPE.gallery,
    },
  ];
  console.log('useAppImagePicker>>>', props);
  // const disabled = _.isNil(maxFile) || parseInt(maxFile) >= selected.length
  const isEnable = () => {
    console.log('isEnable>>>', maxFile, _.isNil(maxFile));
    if (_.isNil(maxFile)) {
      return true;
    }
    const count = _.isEmpty(selected) ? 0 : selected.length;
    return maxFile > count;
  };
  const onReceivedImage = async res => {
    let data = {};
    if (Device.deviceType === 'web') {
      if (res?.cancelled || _.isEmpty(res?.uri)) return;
      data = res;
    } else {
      if (res?.didCancel || _.isEmpty(res?.assets)) return;
      data = res?.assets[0];
    }
    //TODO: upload to server

    if (_.isEmpty(folderUpload)) {
      onSelect([...selected, { id: Math.random() * Math.random(), ...data }]);
    } else {
      // const result = await FormTransform.uploadFileApi([data], dispatch, folderUpload);

      const oldSelected = [...selected]
      onSelect([...oldSelected, { id: Math.random() * Math.random(), ...data }]);
      const result = await FormTransform.uploadFileFDBApi(
        [data],
        dispatch,
        folderUpload,
          props.s3config,
          props.uploadFileName
      );

      if(_.isEmpty(result)){
        onSelect([...oldSelected])
        return
      }
      onSelect([...oldSelected, { id: Math.random() * Math.random(), ...result[0], ...data }]);
    }
  };

  const handlePickImage = async (type = IMAGE_PICKER_TYPE.gallery) => {
    // type = Device.deviceType === 'web'? IMAGE_PICKER_TYPE.gallery : type
    let _props = {};
    type = type === IMAGE_PICKER_TYPE.camera && Device.deviceType === 'web' ? IMAGE_PICKER_TYPE.gallery : type;

    switch (type) {
      case IMAGE_PICKER_TYPE.camera:
        await requestPermission(() => {
          ImagePicker.launchCamera(
            {
              mediaType: 'photo',
              quality: 0.99,
              maxWidth: 1080,
              maxHeight: 1080,
              presentationStyle: 'fullScreen',
            },
            res => {
              // if (res?.didCancel || _.isEmpty(res?.assets)) return;
              // const data = res?.assets[0];
              // //TODO: upload to server
              // onSelect([...selected, { id: Math.random() * Math.random(), ...data }]);
              onReceivedImage(res);
            },
          );
        });
        break;
      case IMAGE_PICKER_TYPE.gallery:
        _props = { mediaType: 'photo', presentationStyle: 'fullScreen' };
        if (Device.deviceType === 'web') {
          _props = {
            mediaTypes: 'Images', allowsEditing: true, aspect: [], quality: 1, base64: true
          };
        }
        ImagePicker.launchImageLibrary(_props, res => {
          // if (res?.didCancel || _.isEmpty(res?.assets)) return;
          // const data = res?.assets[0];
          // //TODO: upload to server
          // onSelect([...selected, { id: Math.random() * Math.random(), ...data }]);
          onReceivedImage(res);
        });
        break;
    }
  };

  const onDeleteSuccess = index => {
    selected.splice(index - 1, 1);
    console.log('onDeleteSuccess>>>', selected)
    //TODO: call api delete
    onSelect([...selected]);
  };

  const handleDelete = index => {
    //TODO: integrate delete file here
    const fileSelected = selected[index - 1];
    if (_.isEmpty(fileSelected)) {
      return;
    }
    if (_.isEmpty(fileSelected?.response)) {
      onDeleteSuccess(index);
    } else {
      dispatch(
        deleteFileRequest(
          { id: FileTransform.getDeletePath(fileSelected?.response) },
          rs => {
            // onSuccess(rs?.data);
            onDeleteSuccess(index);
          },
          err => {
            // resolve([]);
            onDeleteSuccess(index);
          },
        ),
      );
    }

    // selected.splice(index - 1, 1);
    // //TODO: call api delete
    // onSelect([...selected]);
  };

  const onLoadedUri = (item, uri) =>{
    item.uri = uri
    // onSelect([...selected]);
  }
  return { handlePickImage, selected, handleDelete, isEnable, options, isShow, setShow, onLoadedUri };
};
