// import { logoutRequest } from '@redux/user/logoutSlice';
import { FORM } from '@constant/model/RegisterForm';
import { getNotificationPermission } from '@redux/app/appSlice';
import { notificationPermission } from '@redux/app/selectors';
import { getProfileRequest } from '@redux/user/getProfileSlice';
import { getProfileCreator } from '@redux/user/selectors';
import { updateProfileRequest } from '@redux/user/updateProfileSlice';
import { goBack, navigatePop } from '@routes/navigationUtils';
import { Device } from '@theme/device';
import { updateProfileService } from '@services/user';
import { AppNotification } from '@utils';
import { checkFormValidateExpression, getInitialFormValue, getYupStructure } from '@utils/form';
import { fixFormValues, handleShowError } from '@utils/transform/form';
import { useFormik } from 'formik';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { lazy } from 'yup';
import * as Yup from 'yup';
import {Platform} from "react-native";
import {FormikHelper} from "@utils/hooks/formik";

export const useNotificationSetting = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const rawData = props?.route.params?.data;
  const [data, setData] = useState(rawData);
  const getProfileData = useSelector(getProfileCreator)?.message?.data;

  const scrollViewRef = useRef(null);

  const notificationPermissionState = useSelector(notificationPermission);
  const [value, setValue] = useState(notificationPermissionState);
  const [layoutInfo, setLayoutInfo] = useState({});

  const getProfileApi = () => {
    const payload = {};
    dispatch(getProfileRequest(payload));
  };

  // const xData = {
  //   ...getProfileData,
  //   // suspend_notification: null
  // }
  // delete xData.suspend_notification

  const formik = FormikHelper.useFormikWrapper({
    initialValues: getInitialFormValue(rawData, getProfileData),
    // validationSchema: Yup.object().shape(getYupStructure(rawData, t)),
    validationSchema: () =>
      lazy(values => {
        // console.log('lazy>>>values>>>', values);
        const ys = getYupStructure(rawData, t, 'attributes', values);
        console.log('getYupStructure>>>', ys, values);
        return Yup.object().shape(ys);
      }),
    initialErrors: false,
    enableReinitialize: true,
    onSubmit: (values, { setErrors, resetForm }) => {
      console.log('onSubmit>>>', values);

      // updateUserApi(values, isLastStep);
      // onSavePress();
      const rs = checkFormValidateExpression(data?.attributes, values, setErrors);
      if (!_.isEmpty(rs)) {
        return;
      }
      updateProfileApi(values, setErrors);
    },
  }, data?.attributes);

  const scrollToY = y => {
    // scrollViewRef.current.scroll
    scrollViewRef.current?.scrollTo({
      x: 0,
      y: y,
      animated: true,
    });
  };

  const updateProfileApi = async (values, setErrors) => {
    /*
  payload_info
   */
    const fcmToken = await AppNotification.getToken();
    const payload = {
      ...values,
      device_token: fcmToken || '',
      deviceType: Platform.OS,
    };
    const payloadValue = await fixFormValues(payload, values);
    console.log('updateProfileApi>>>', payloadValue);
    dispatch(
      updateProfileRequest(
        payloadValue,
        rs => {
          // console.log('updateProfilexxx>>>>', rs);
          //TODO: reload api
          dispatch(getNotificationPermission(values[FORM.FORM_ELEMENT_NAME.push_notification]));
          getProfileApi();
          navigatePop();
        },
        err => {
          handleShowError(err, setErrors);
        },
      ),
    );
  };

  console.log('useNotificationSettingZ>>>', data, getProfileData);

  return { setValue, value, formik, data, rawData, scrollToY, scrollViewRef, layoutInfo, setLayoutInfo };
};
