import { QRIcon2, HomeIcon, TaskIcon, FAQIcon, MenuIcon } from '@assets';
import { AppText } from '@components';
import { SVGIcons } from '@constant/appSetting';
import { navigate } from '@routes/navigationUtils';
import { SCAN_QR_SCREEN } from '@routes/route';
import { FlexImageIcon } from '@screens/main/Menu/Components';
import { globalStyle } from '@theme';
import { Device } from '@theme/device';
import { FontSize } from '@theme/size';
import { reportModal } from '@utils/common';
import { AppSetting } from '@utils/common/setting';
import { IconSvg, IconSvgLocal } from '@utils/common/svg';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity } from 'react-native';
import { DASHBOARD_TAB, REPORT_TAB, SCAN_QR_TAB, FAQ_TAB, MENU_TAB } from '../route';
import { useMyTabBar } from './MyTabBar.hook';
import styles from './MyTabBar.style';

const mapTitle = {
  DASHBOARD_TAB: 'Home',
  REPORT_TAB: 'Report',
  FAQ_TAB: 'FAQ',
  MENU_TAB: 'Menu',
};
export const MyTabBar = ({ state, descriptors, navigation }) => {
  const { handleQRPress, tabs } = useMyTabBar(state, descriptors, navigation);
  const { t } = useTranslation();
  const tabIcons = [
    [
      ({ color }) => {
        console.log('re-render', color);
        return <IconSvg imageKey={SVGIcons.HomeIcon} color={color} />;
      },
      ({ color }) => <IconSvg imageKey={SVGIcons.TaskIcon} color={color} />,
    ],
    <></>,
    [
      ({ color }) => (
        <IconSvg imageKey={SVGIcons.FAQIcon} color={color} colorText={AppSetting.state?.colors?.DASHBOARD_IMAGE} />
      ),
      ({ color }) => <IconSvg imageKey={SVGIcons.MenuIcon} color={color} />,
    ],
  ];

  return (
    <View style={styles.container}>
      {tabs?.map((route, index1) => {
        if (route.name === SCAN_QR_TAB) {
          return (
            <TouchableOpacity activeOpacity={0.7} onPress={handleQRPress} key={route.name} style={styles.containerQr}>
              <View style={styles.qrTab} />
              <View
                style={{
                  ...styles.qr,
                  backgroundColor: AppSetting.state?.colors?.ROYAL_MAIL_RED,
                }}>
                <IconSvg imageKey={SVGIcons.QRIcon2} color={AppSetting?.state?.colors?.DASHBOARD_IMAGE} />
                {/*<FlexImageIcon icon={'https://spectos-correos-prod.s3.eu-central-1.amazonaws.com/app/icons/inapp/testQRIcon2.svg'} />*/}
                {/*<FlexImageIcon icon={'https://spectos-royalmail-dev.s3.eu-central-1.amazonaws.com/app/icons/dashboard/skill.svg'} />*/}
              </View>
            </TouchableOpacity>
          );
        }
        if (Array.isArray(route)) {
          return (
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-evenly' }} key={Math.random()}>
              {Device.isIos && (
                <View
                  style={{
                    width: (Device.width - 60) / 2,
                    height: 1,
                    backgroundColor: '#F2F2F2',
                    position: 'absolute',
                    left: -1,
                    top: -1,
                  }}
                />
              )}
              {route.map((item, index2) => {
                const isFocused = state.routes?.[state.index].name === item.name;
                const Icon = tabIcons[index1 === 0 ? 0 : 2]?.[index2];
                const onPress = () => {
                  const event = navigation.emit({
                    type: 'tabPress',
                    target: item.key,
                    canPreventDefault: true,
                  });

                  if (!isFocused && !event.defaultPrevented) {
                    // The `merge: true` option makes sure that the params inside the tab screen are preserved
                    switch (item.name) {
                      case REPORT_TAB:
                        reportModal.show();
                        break;
                      case DASHBOARD_TAB:
                      case FAQ_TAB:
                      case MENU_TAB:
                        navigation.navigate({ name: item.name, merge: true });
                        break;
                      default:
                        break;
                    }
                  }
                };

                const onLongPress = () => {
                  console.log('onLongPress>>>', item.name);
                  navigation.emit({
                    type: 'tabLongPress',
                    target: item.key,
                  });

                  onPress();
                };
                return (
                  <TouchableOpacity
                    key={item.name}
                    accessibilityRole="button"
                    accessibilityState={isFocused ? { value: true } : {}}
                    // accessibilityLabel={options.tabBarAccessibilityLabel}
                    // testID={options.tabBarTestID}
                    hitSlop={{ left: 20, right: 20 }}
                    onPress={onPress}
                    onLongPress={onLongPress}
                    style={styles.tab}>
                    <View style={[globalStyle.directionColumm, globalStyle.center]}>
                      <Icon
                        color={
                          isFocused
                            ? AppSetting.state?.colors?.TAB_SELECTED
                            : item.name === FAQ_TAB
                            ? AppSetting.state?.colors?.TAB_FAQ_UNSELECTED
                            : AppSetting.state?.colors?.TAB_UNSELECTED
                        }
                      />
                      <AppText
                        style={[
                          {
                            color: isFocused
                              ? AppSetting.state?.colors?.TAB_SELECTED
                              : item.name === FAQ_TAB
                              ? AppSetting.state?.colors?.TAB_FAQ_UNSELECTED
                              : AppSetting.state?.colors?.TAB_UNSELECTED,
                            fontSize: FontSize.Font11,
                            marginTop: 4,
                          },
                        ]}>
                        {t(`${item.name}_TEXT`)}
                      </AppText>
                    </View>
                  </TouchableOpacity>
                );
              })}
            </View>
          );
        }
      })}
    </View>
  );
};
