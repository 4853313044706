import { FORM } from '@constant/model/RegisterForm';
import { DATE_FORMAT, formatDate } from '@utils/common/MomentUtils';
import { StringHelper } from '@utils/common/string';
import _ from 'lodash';
import replaceAll from 'string.prototype.replaceall';

const LIST_DATE_TYPE = [
  FORM.FORM_TYPE.date,
  FORM.FORM_TYPE.datetime,
  FORM.FORM_TYPE.calendar,
  FORM.FORM_TYPE.birtday,
  FORM.FORM_TYPE.birthday,
];

export const FormUtil = {
  getTitleDynamic: (originTitle, formValues, formData) => {
    // let keys = Object.keys(formValues);
    if (_.isEmpty(formValues)) {
      return originTitle;
    }
    const lsKeyScript = StringHelper.getKeysFromScript(originTitle);
    let keys = [...new Set(lsKeyScript)];
    let title = originTitle;
    for (const valuesKey of keys) {
      if (valuesKey in formValues) {
        const value = formValues[valuesKey];
        const itemStruct = _.find(formData, x => x.internalId === valuesKey);
        // console.log('getTitleDynamic>>>typeOf>>>', valuesKey, typeof value, itemStruct)
        const itemType = itemStruct?.type;
        if (!!value && !!itemType && LIST_DATE_TYPE.includes(itemType)) {
          const date_format = formatDate(value, DATE_FORMAT.UIDate, DATE_FORMAT.StandardDate);
          title = replaceAll(title, `{{${valuesKey}}}`, date_format);
        } else {
          title = replaceAll(title, `{{${valuesKey}}}`, value);
        }
      }
    }
    // return StringHelper.fixEvalExpression(originTitle, formValues);
    return title;
  },
};
