import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppKAView, AppModal, AppModalV2, AppSafeAreaView, AppText } from '@components';
import { navigatePush } from '@routes/navigationUtils';
import { Screens } from '@routes/route';
import { AppSearch } from '@screens/main/SearchAddress/components/AppAddress.view';
import { PostcodeContainerModal } from '@screens/main/SearchPostcode/components/PostcodeContainerModal';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { Font } from '@theme/size';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, Platform } from 'react-native';
import { useDispatch } from 'react-redux';
import { useAppPostcode } from './AppPostcode.hook';
import { AppPostcodeStyle } from './AppPostcode.style';

export const AppPostcode = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {} = useAppPostcode(props);
  const { title, disabled, error, isRequired, styleTitle, value, style, isBoldTitle } = props;
  const header = t('POSTCODE_HEADER');
  // const visible = true
  const [visible, setVisible] = useState(false);
  const onSelected = item => {
    console.log('onSelected>>>', item);
    if (props.onSelect) {
      props.onSelect(item);
    }
    setVisible(false);
  };

  const handleOpenScreen = () => {
    console.log('handleOpenScreen>>>');
    setVisible(true);

    // navigatePush(Screens.SEARCH_POSTCODE_SCREEN, { onSelected: onSelected, searchText: value });
  };
  const handleBackdropPress = () => {
    setVisible(false);
  };
  return (
    <>
      <AppSearch
        handleOpenScreen={handleOpenScreen}
        value={value}
        style={style}
        styleTitle={styleTitle}
        disabled={disabled}
        title={title}
        error={error}
        isRequired={isRequired}
        isBoldTitle={isBoldTitle}
        onLayout={props.onLayout}
      />
      <AppModalV2 visible={visible} onBackdropPress={handleBackdropPress}>
        <View
          style={[
            globalStyle.directionColumm,
            {
              minHeight: (Device.height * 1) / 2,
              maxHeight: (Device.height * 2) / 3,
              // maxHeight: (Device.height * 1) / 2,
              width: Platform.OS === 'web' ? 810 * 0.9 : Device.width * 0.9,
            },
          ]}>
          {!!header && (
            <View
              style={AppPostcodeStyle.header}
              onLayout={e => {
                console.log(e.nativeEvent.layout.height);
              }}>
              <AppText style={AppPostcodeStyle.headerTxt}>{header}</AppText>
            </View>
          )}
          {/*<View*/}
          {/*  style={[*/}
          {/*    globalStyle.directionColumm,*/}
          {/*    {*/}
          {/*      flex:1*/}
          {/*    },*/}
          {/*  ]}>*/}
          <PostcodeContainerModal searchText={value} onSelect={onSelected} dispatch={dispatch} header={header} />
          {/*</View>*/}
          {/*<PostcodeContainerModal searchText={value} onSelect={onSelected} dispatch={dispatch} header={header} />*/}
        </View>
      </AppModalV2>
    </>
  );
};

AppPostcode.propTypes = {
  value: PropTypes.any,
  error: PropTypes.any,
  style: PropTypes.any,
  title: PropTypes.string,
  isRequired: PropTypes.bool,
  onSelect: PropTypes.func,
  header: PropTypes.any,
  styleTitle: PropTypes.any,
  disabled: PropTypes.bool,
  addressId: PropTypes.string,
  hint: PropTypes.string,
  isBoldTitle: PropTypes.bool,
  placeHolder: PropTypes.string,
  onLayout: PropTypes.func,
};

AppPostcode.defaultProps = {
  // header: true
  placeHolder:''
};
