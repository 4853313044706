import { ActiveCheckIcon, CheckIcon } from '@assets';
import { AppText } from '@components';
import { Constant } from '@constant';
import { AppHtmlView } from '@screens/main/quiz/QuizFailed/components/AppNote.view';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { FontSize, Spacing } from '@theme/size';
import React, { useRef, useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

const CheckBoxItem = ({ dataRef, item, index, onSelect, selected }) => {
  const [textLine, setTextLine] = useState(1);
  return (
    <TouchableOpacity
      key={index}
      onPress={() => {
        if (dataRef.current.includes(item.id)) {
          const filteredData = dataRef.current.filter(id => {
            return id !== item.id;
          });
          dataRef.current = filteredData;
        } else dataRef.current = [...dataRef.current, item.id];

        onSelect(dataRef.current);
      }}
      style={styles.radioBox}
      activeOpacity={0.7}>
      {/* {selected.includes(item.id) ? <ActiveCheckIcon /> : <CheckIcon />} */}
      <View style={{ height: textLine > 1 ? (Device.isAndroid ? '90%' : '95%') : 'auto', marginRight: 4 }}>
        {selected.includes(item.id) ? <ActiveCheckIcon /> : <CheckIcon />}
      </View>
      <AppText
        onTextLayout={e => {
          setTextLine(e.nativeEvent.lines.length);
        }}
        style={styles.text}>
        {item.content}
      </AppText>
    </TouchableOpacity>
  );
};

/**
 * - Data must be object and include ID and CONTENT
 * - Selected must be ID ARRAY
 * - Selected is also default data
 */
export const AppMultipleSelection = React.memo(props => {
  const {
    style,
    data = [],
    selected = [],
    onSelect,
    isRequired,
    title,
    error,
    styleTitle,
    isBoldTitle,
    hint = '',
    onLayout = null,
  } = props;

  const dataRef = useRef(selected);

  return (
    <View
      style={[styles.container, style]}
      onLayout={e => {
        if (onLayout) {
          onLayout(e);
        }
      }}>
      <AppText style={[styles.textTitle, styleTitle, isBoldTitle ? { fontWeight: '700' } : {}]}>
        {isRequired && <AppText style={styles.isRequired}>* </AppText>}
        {title}
      </AppText>
      {data?.map((item, index) => {
        return <CheckBoxItem dataRef={dataRef} item={item} index={index} onSelect={onSelect} selected={selected} />;
      })}
      {/*{!!hint && <AppText style={styles.hint}>{hint}</AppText>}*/}
      {!!hint && <AppHtmlView style={styles.hint} text={hint} />}

      {!!error && <AppText style={styles.error}>{error}</AppText>}
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  textTitle: {
    fontWeight: '400',

    marginBottom: 10,
  },
  radioBox: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#D5D5D5',
    paddingHorizontal: 8,
    minHeight: Constant.MAIN_HEIGHT,
    borderRadius: 4,
    marginBottom: 15,
    padding: 10,
  },
  text: {
    fontWeight: '400',
    color: '#333333',
    fontSize: FontSize.Font15,
    // lineHeight: 20,
    marginLeft: Spacing.width4,
    paddingRight: 16,
  },
  hint: {
    fontWeight: '400',
    color: '#999999',
    marginTop: 7,
  },
  error: {
    color: palette.ROYAL_MAIL_RED,
    fontWeight: '400',
  },
  isRequired: {
    color: '#FF5757',
  },
});
