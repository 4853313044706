import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppKAView, AppPicker, AppSafeAreaView, AppText } from '@components';
import { FormItemView } from '@components/Form';
import {FORM, FORM_ELEMENT_NAME} from '@constant/model/RegisterForm';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font } from '@theme/size';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text } from 'react-native';
import { Portal } from 'react-native-portalize';
import { useDispatchPoint } from './DispatchPoint.hook';
import { DispatchPointStyle } from './DispatchPoint.style';

export const DispatchPointView = React.memo(props => {
  const { t } = useTranslation();
  const { formik, data, isHide } = useDispatchPoint(props);
  console.log('DispatchPointView???', props, data, !_.isEmpty(props.selected) && props.selected !== FORM.FORM_ELEMENT_NAME.collection)
  const renderForm = () => {
    return (
      <View style={[globalStyle.directionColumm, { marginBottom: theme.spacing.sm }]}>
        {data?.map((item, index) => {
          return (
            <FormItemView
              index={index}
              item={item}
              formik={formik}
              isBoldTitle={true}
              checkCondition={true}
              showPlaceholder={true}
              formData={data}
              extraFormik={props.formik}
              extraFormData={props.formData}
              extraData={{
                location: props.location,
                mapSettings: props.mapSettings,
                verifyOnMap: props.verifyOnMap,
                locationType: props.locationType,
              }}
              style={[{ marginBottom: theme.spacing.sm }]}
              scrollViewRef={props.scrollViewRef}
            />
          );
        })}
      </View>
    );
  };
  return (
    <>
      <AppPicker
        selected={props.selected}
        error={props.error}
        style={props.style}
        title={props.title}
        isRequired={props.isRequired}
        onSelect={(value, extraValue) => {
          if (props.onSelect) {
            props.onSelect(value);
          }

          console.log('onSelect>>>DispatchPointView>>>', value, extraValue);
          //TODO: show modal here.
          // formik.setValues(FORM_ELEMENT_NAME.emptying_time);
          // formik.setFieldValue(`visible_${FORM_ELEMENT_NAME.nearest_address}`, false);
          formik.setFieldValue(`visible_${FORM_ELEMENT_NAME.nearest_address}`, true);

          //NOTE: for show/hide + check required of form nearest_address
          // enable = true when: click on dispatch item
          // enable = false when: click on href here
          formik.setFieldValue(`enable_${FORM_ELEMENT_NAME.nearest_address}`, true);

        }}
        header={props.header}
        data={props.data}
        styleTitle={props.styleTitle}
        disabled={props.disabled}
        isBoldTitle={props.isBoldTitle}
        hint={props.hint}
        placeHolder={props.placeHolder}
      />
      {!_.isEmpty(props.selected) && !isHide && renderForm()}
    </>
  );
});

DispatchPointView.propTypes = {
  selected: PropTypes.any,
  error: PropTypes.any,
  style: PropTypes.any,
  title: PropTypes.string,
  isRequired: PropTypes.bool,
  onSelect: PropTypes.func,
  header: PropTypes.string,
  data: PropTypes.any,
  styleTitle: PropTypes.any,
  disabled: PropTypes.bool,
  isBoldTitle: PropTypes.bool,
  hint: PropTypes.string,
  formik: PropTypes.any,
  location: PropTypes.any,
  placeHolder: PropTypes.string,
  subForm: PropTypes.array,
  onCheckValid: PropTypes.func,
  formikRef: PropTypes.any,
  scrollViewRef: PropTypes.any,
  defaultValue: PropTypes.any,
};

DispatchPointView.defaultProps = {
  // selected,
  // error,
  // style,
  // title,
  // isRequired,
  // onSelect,
  // header,
  // data,
  // styleTitle,
  // disabled,
  // isBoldTitle,
  hint: '',
  placeHolder: '',
  defaultValue:{}
};
