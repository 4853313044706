import {useFormik} from "formik";
import {FORM, FORM_ELEMENT_NAME} from "@constant/model/RegisterForm";
import {sha256} from "react-native-sha256";
import _ from "lodash";
import {Constant} from "@constant";
import {StringHelper} from "@utils/common/string";

export const FormikHelper = {
    fixedValues: (values, formStructure = []) => {
        if(_.isEmpty(values) || _.isEmpty(formStructure)){
            return values
        }

        for (const valuesKey in values) {
            const item = formStructure?.find(x => x.internalId === valuesKey)
            if(item?.type === FORM.FORM_TYPE.integer){
                const value = values[valuesKey]
                const valueNumber = _.toNumber(value)
                // console.log('integer>>>', value, item, _.isNil(value))
                if(_.isNil(value) || _.isNaN(valueNumber) || (typeof value === 'string' && _.isEmpty(value))){
                    // console.log('integer>>>continue>>>')
                    values[valuesKey] = null
                    continue
                }
                values[valuesKey] = _.toNumber(value)
            }
        }
        // console.log('fixedValues>>>', values)
        return values
    },
    useFormikWrapper :({initialValues, validationSchema, initialErrors, onSubmit, enableReinitialize, dirty, handleChange}, formStructure = []) => {
        return useFormik({
            initialValues,
            validationSchema,
            initialErrors,
            enableReinitialize,
            dirty,
            onSubmit: (values, formikHelpers) => {
                if(onSubmit){
                    onSubmit(FormikHelper.fixedValues(values, formStructure), formikHelpers)
                }
            }
        })
    },
    useGroupFormikWrapper :({initialValues, validationSchema, initialErrors, onSubmit, enableReinitialize, dirty}, {
        formStructure = [],
        groupInfo: {}
    }) => {
        return useFormik({
            initialValues,
            validationSchema,
            initialErrors,
            enableReinitialize,
            dirty,
            onSubmit: (values, formikHelpers) => {
                if(onSubmit){
                    const fixValues = FormikHelper.fixedValues(values, formStructure)
                    //TODO:
                    onSubmit(fixValues, formikHelpers)
                }
            }
        })
    }


}