import { searchForAddressService } from '@services/address'

// @ts-ignore
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { searchForAddressFailed, searchForAddressSuccess } from './searchForAddressSlice';
import { Constant } from "@constant";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* searchForAddressSaga({ payload, meta, error }): Generator<
  | CallEffect
  | PutEffect<{
      payload: any;
      type: string;
    }>,
  void
> {
  try {
    const res = yield call(searchForAddressService, payload);
    console.log('searchForAddressSaga>>>>', res);
    if (res?.status) {
      yield put(searchForAddressSuccess(res));
      if (meta) {
        yield call(meta, res);
      }
    } else {
      // @ts-ignore
      yield put(searchForAddressFailed(Constant.ERROR_NO_RESPONSE));
      if (error) {
        yield call(error, Constant.ERROR_NO_RESPONSE);
      }
    }
  } catch (err) {
    // @ts-ignore
    yield put(searchForAddressFailed(err));
    if (error) {
      yield call(error, error);
    }
  }
}
