import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppKAView, AppSafeAreaView, AppText } from '@components';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font } from '@theme/size';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { useTopICode } from './TopICode.hook';
import { TopICodeStyle } from './TopICode.style';

export const TopICodeView = props => {
  const { t } = useTranslation();
  const { data } = useTopICode(props);
  return (
    <View areaStyle={[globalStyle.flex1, globalStyle.alignCenter, globalStyle.justifyCenter, {margin: theme.spacing.sm}]}>
      <LinearGradient style={TopICodeStyle.topView} colors={['#FF000E', '#870007']}>
        <View style={[globalStyle.directionColumm]}>
          <View style={[globalStyle.directionRow, globalStyle.alignCenter]}>
            <AppText style={[globalStyle.flex1, TopICodeStyle.textNormal]}>{t('ICODE')}</AppText>
            <AppText style={[TopICodeStyle.textTitle]}>{data?.id}</AppText>
          </View>
          {/*<View style={[globalStyle.directionRow]}>*/}
          {/*  <AppText style={[globalStyle.flex1, TopBundleStyle.textNormal]}>{t('Number of bundles')}</AppText>*/}
          {/*  <AppText style={[TopBundleStyle.textNormal]}>{data?.total}</AppText>*/}
          {/*</View>*/}
        </View>
      </LinearGradient>
    </View>
  );
};

TopICodeView.propTypes = {
  data: PropTypes.object,
};

TopICodeView.defaultProps = {
  data: {}
};
