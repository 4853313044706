import { FEEDBACK } from '@constant';

export const D2DTransform = {
  getD2DSubmitRequest: async (formValues, extraData, isCompleted = true) => {
    const payload = {
      feedback: {
        type: extraData.key,
        // target_week: extraData.week,
        // target_date: type === FEEDBACK.type.unsure_date ? null : extraData.date, // hide if type is unsure_date
        s_id: extraData.s_id, // hide if type is unsure_date
        status: isCompleted ? FEEDBACK.status.complete : FEEDBACK.status.incomplete,
        step: 1,
        // id: idSurvey,
      },
      answer: {
        ...formValues,
      },
    };
    return payload
  },
};
