import _ from "lodash";
import {all, call, put, select, take, fork} from "redux-saga/effects";
import {getListDashboardDetailSaga, getMenuSaga} from "@redux/app/sagas";
import {getProfileFormSaga} from "@redux/form/getProfileFormSaga";
import {getAccessToken, getPanelistId} from "@utils";
import {getReportModalSaga} from "@redux/form/getReportModalSaga";
import {StringHelper} from "@utils/common/string";

// const EXTENDABLE_ACTION = ['bottom_modal', 'bottom_modal', 'bottom_modal']
const TRIGGER_ACTION_EXTEND = {
    bottom_modal: 'bottom_modal',
    menu: 'menu',
    profile_form: 'profile_form'
}

export function* getTriggerActionSaga(rs) {
    try {
        console.log('getTriggerActionSaga>>>', rs)
        const internalIds = rs?.payload?.triggerActions;
        if (_.isEmpty(internalIds)) {
            return;
        }

        const token = getAccessToken()
        if(_.isEmpty(token)){
            return
        }

        const dashboardInternalIds = []
        const extendInternalIds = []
        for (const internalId of internalIds) {
            if(internalId in TRIGGER_ACTION_EXTEND){
                extendInternalIds.push(internalId)
            }
            else{
                dashboardInternalIds.push(internalId)
            }
        }

        //TODO: get
        if(!!dashboardInternalIds){
            yield call(getListDashboardDetailSaga,{
                internalIds:dashboardInternalIds
            })
        }

        if(!!extendInternalIds){
            yield call(extendTriggerActionSaga(extendInternalIds))
        }
    }
    catch (e) {
        console.log('getTriggerActionSaga>>ex>>>', e)
    }
}

export function* extendTriggerActionSaga(internalIds) {
    if (_.isEmpty(internalIds)) {
        return;
    }
    //TODO: should apply multi thread
    for (const internalId of internalIds) {
        switch (internalId) {
            case TRIGGER_ACTION_EXTEND.menu:
                yield call(getMenuSaga, {})
                break
            case TRIGGER_ACTION_EXTEND.bottom_modal:
                yield call(getReportModalSaga, { id: 5 })
                break
            case TRIGGER_ACTION_EXTEND.profile_form:
                yield call(getProfileFormSaga, { panelistId: getPanelistId() })
                break

        }
    }
}


export const takeEveryRegex = (includes = [], ignores = [], saga, ...args) =>
    fork(function* () {
        while (true) {
            const action = yield take("*")
            console.log("takeEveryRegex>>>begin>>>", action.type, includes, ignores)
            const checkInclude = StringHelper.includesAny(action.type, includes)
            const checkIgnore = StringHelper.includesAny(action.type, ignores)
            // console.log("takeEveryRegex>>>includes>>>", includes)
            // console.log("takeEveryRegex>>>ignores>>>", ignores)
            // console.log("takeEveryRegex>>>checkInclude>>>", checkInclude)
            // console.log("takeEveryRegex>>>checkIgnore>>>", checkIgnore)

            if (checkInclude && (_.isEmpty(ignores) || !checkIgnore)) {
                // console.log("takeEveryRegex>>>pattern>>>", action, includes, ignores)
                // console.log("takeEveryRegex>>>action>>>",...args.concat(action))
                yield fork(saga, ...args.concat(action))
            }
        }
    })

export default function* triggerActionSagas() {
    yield takeEveryRegex(['Success'], ['getDashboard'], getTriggerActionSaga)

}