import Colors from '@screens/main/report/ListReport/Colors';
import Dimens from '@screens/main/report/ListReport/Dimens';
import { FontSize } from '@theme/size';
import { StyleSheet } from 'react-native';

export const ListReportStyle = StyleSheet.create({
  historyRowContainer: {
    flexDirection: 'row',
    width: '100%',
    // paddingHorizontal: 1.5 * Dimens.Margin.Short,
    marginBottom: 2,
  },
  startDateContainer: {
    flex: 1,
    flexDirection: 'row',
    minHeight: Dimens.ItemHeight.Normal,
    paddingHorizontal: Dimens.Margin.Normal,
    alignItems: 'center',
    backgroundColor: Colors.grayColor3,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  endDateContainer: {
    flex: 1,
    flexDirection: 'row',
    minHeight: Dimens.ItemHeight.Normal,
    paddingHorizontal: Dimens.Margin.Normal,
    alignItems: 'center',
    backgroundColor: Colors.grayColor3,
    marginLeft: 2,
  },
  deleteContainer: {
    minHeight: Dimens.ItemHeight.Normal,
    width: Dimens.ItemHeight.Normal,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.grayColor3,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    marginLeft: 2,
  },
  textDate: {
    // textTransform: 'capitalize',
  },
  resetButton: {
    width: '100%',
    minHeight: Dimens.ItemHeight.Normal,
    borderRadius: 6,
    borderColor: Colors.borderColor,
    backgroundColor: Colors.whiteColor,
    borderWidth: 1,
    marginTop: Dimens.Margin.Normal,
    // justifyContent: 'center',
    // alignItems: 'center'
  },
});
