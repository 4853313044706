import uploadFileSlice from '@redux/file/uploadFileSlice';
import uploadFileViaFDBSlice from '@redux/file/uploadFileViaFDBSlice';
import viewFileViaFDBSlice from '@redux/file/viewFileViaFDBSlice';
import deleteFileSlice from '@redux/file/deleteFileSlice';
import getFileSlice from '@redux/file/getFileSlice';

export const fileReducer = {
 uploadFile: uploadFileSlice,
 uploadFileViaFDB: uploadFileViaFDBSlice,
 viewFileViaFDB: viewFileViaFDBSlice,
 deleteFile: deleteFileSlice,
 getFile: getFileSlice,
};

