import { Constant } from '@constant';
import { uploadFileViaFDBService } from '@services/file';
// @ts-ignore
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { uploadFileViaFDBFailed, uploadFileViaFDBSuccess } from './uploadFileViaFDBSlice';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* uploadFileViaFDBSaga({ payload, meta, error }): Generator<
  | CallEffect
  | PutEffect<{
      payload: any;
      type: string;
    }>,
  void
> {
  try {
    const res = yield call(uploadFileViaFDBService, payload);
    console.log('uploadFileViaFDBSaga>>>>', res);
    if (res) {
      yield put(uploadFileViaFDBSuccess(res));
      if (meta) {
        yield call(meta, res);
      }
    } else {
      // @ts-ignore
      yield put(uploadFileViaFDBFailed(res));
      if (error) {
        yield call(error, res);
      }
    }
  } catch (err) {
    // @ts-ignore
    yield put(uploadFileViaFDBFailed(err));
    if (error) {
      yield call(error, err);
    }
  }
}
