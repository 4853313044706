import { Constant } from '@constant';
import { palette } from '@theme/colors';
import { FontSize } from '@theme/size';
import { AppSetting } from '@utils/common/setting';
import { Platform, StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  boxInput: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    textAlignVertical: 'center',
    justifyContent: 'space-between',
    minHeight: Constant.MAIN_HEIGHT, //42
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#D5D5D5',
  },
  input: {
    height: Platform.OS === 'web' ? '48px' : '100%',
    width: '100%',
    flex: 1,
    fontSize: FontSize.Font15,
    color: '#333333',
    fontWeight: '400',
    paddingLeft: 10,
    //start Web
    backgroundImage: 'none',
    // borderWidth: 1,
    // borderColor: '#D5D5D5',
    borderRadius: 5,
    outlineStyle: Platform.OS === 'web' ? 'none' : undefined,
    // paddingTop: Platform.OS === 'web' ? 10 : undefined,
    //end Web
  },
  label: {
    fontWeight: '400',
    color: '#333333',
    marginBottom: 8,
  },
  valueText: {
    fontWeight: '400',
    color: '#999999',
    marginBottom: 8,
  },
  hint: {
    fontWeight: '400',
    color: '#999999',
    marginTop: 7,
  },
  isRequired: {
    color: '#FF5757',
  },
  error: {
    marginTop: 7,
    color: AppSetting.state?.colors?.ERROR_TEXT ?? palette.ROYAL_MAIL_RED,
    fontWeight: '400',
  },
  multiline: {
    // height: 246,
    // paddingTop: 7,
    // paddingTop: 0,
    // paddingBottom: 0,
  },
  postIcon: {
    paddingHorizontal: 12,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
