import { Config, ConfigMode } from '@constant/config';
import env from '@env';
import { store } from '@redux/store';
import { getAccessToken } from '@utils/common';
import axios from 'axios';
import {ApiHelper} from "@utils/api";
import _ from 'lodash'
import i18next from "i18next";
import { t } from 'i18next';
const api = axios.create({
  baseURL: ApiHelper.getApiUrl(),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'spectos-apikey': ApiHelper.getApiKey(),
  },
  timeout: 30000,
});
// import statusHandler from './statusHandler';

// Add a request interceptor
api.interceptors.request.use(
  function (config) {
    // @ts-ignore
    if (!ApiHelper.isConnected()) {
      throw t('NO_INTERNET_TITLE');
    }
    config.url = config.baseURL + config.url;
    const state = store.getState();
    const accessToken = getAccessToken();
    if (accessToken) {
      // @ts-ignore
      console.log('accessToken>>>', accessToken);
      config.headers.Token = accessToken;
    }

    console.log(config.url);

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

// Add a response interceptor
api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // statusHandler(error);
    return Promise.reject(error);
  },
);

export default api;
