import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {notificationModal, notificationModalContent} from "@redux/app/selectors";
import _ from "lodash";
import {getNotificationModal} from "@redux/app/appSlice";
import {NOTIFICATION} from "@constant";
import {NotificationHelper} from "@utils/common/notification";
// import {clearInterval} from "timers";

export const useAppFlashMessage = props => {
    const [messages, setMessages] = useState([]);
    const notificationModalVisible = useSelector(notificationModal);
    const notificationModalContentSelector = useSelector(notificationModalContent);
    const data = notificationModalContentSelector.data;
    const dispatch = useDispatch();
    const timer = useRef();
    const refMessages = useRef();

    console.log('useAppFlashMessage>>>', messages)
    useEffect(() => {
        refMessages.current = _.cloneDeep(messages)
        console.log('refMessages>>>', refMessages.current)
    }, [messages])
    useEffect(() => {
        if (!_.isEmpty(notificationModalContentSelector)) {

            if (timer.current) {
                clearTimeout(timer.current);
            }
            timer.current = setInterval(() => {
                // dispatch(getNotificationModal(false));
                // setMessages([]);
                console.log('setTimeout>>>', refMessages.current)
                const x = NotificationHelper.filterNotification(refMessages.current, NOTIFICATION.foregroundDuration)
                if(_.isEmpty(x)){
                    dispatch(getNotificationModal(false));
                    clearInterval(timer.current)
                }

                if(x.length != refMessages.current.length){
                    console.log('setTimeout>>>timer.current>>>', x)
                    setMessages([...x])
                }

            }, 1000);
            const newMsgs = NotificationHelper.filterNotification([...messages, notificationModalContentSelector], NOTIFICATION.foregroundDuration)
            setMessages(newMsgs);

        }
        console.log('useEffect>>>')
        return () => {
            console.log('clearTimeout>>>')
            return clearInterval(timer.current)
        };
    }, [notificationModalContentSelector]);

    const onSwipe = () => {
        dispatch(getNotificationModal(false));
    }
    const onItemPress = (item) => {
        dispatch(getNotificationModal(false));
        setMessages([]);
        NotificationHelper.openNotification(item?.data, 100);
    }
    return {messages, setMessages, onSwipe, notificationModalVisible, onItemPress, dispatch}
}