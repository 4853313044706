import getFormSlice from '@redux/form/getFormSlice';
import getProfileFormSlice from '@redux/form/getProfileFormSlice';
import getMenuformSlice from '@redux/form/getMenuformSlice';
import getFAQSlice from '@redux/form/getFAQSlice';
import getReportModalSlice from '@redux/form/getReportModalSlice';
import getForgotPasswordSlice from '@redux/form/getForgotPasswordSlice';
import getReportPackageFormSlice from '@redux/form/getReportPackageFormSlice';
import getReportBundleFormSlice from '@redux/form/getReportBundleFormSlice';
import getICodeFormSlice from '@redux/form/getICodeFormSlice';
import getCDSFormSlice from '@redux/form/getCDSFormSlice';
import getD2DFormSlice from '@redux/form/getD2DFormSlice';
import getHolidayFormSlice from '@redux/form/getHolidayFormSlice';
import getRFIDFormReceiverSlice from '@redux/form/getRFIDFormReceiverSlice';
import getRFIDFormSlice from '@redux/form/getRFIDFormSlice';
import getQRFormSlice from '@redux/form/getQRFormSlice';
import getCreateTicketFormSlice from '@redux/form/getCreateTicketFormSlice';
import getReturnTransponderFormSlice from '@redux/form/getReturnTransponderFormSlice';

export const formReducer = {
 getForm: getFormSlice,
 getProfileForm: getProfileFormSlice,
 getMenuform: getMenuformSlice,
 getFAQ: getFAQSlice,
 getReportModal: getReportModalSlice,
 getForgotPassword: getForgotPasswordSlice,
 getReportPackageForm: getReportPackageFormSlice,
 getReportBundleForm: getReportBundleFormSlice,
 getICodeForm: getICodeFormSlice,
 getCDSForm: getCDSFormSlice,
 getD2DForm: getD2DFormSlice,
 getHolidayForm: getHolidayFormSlice,
 getRFIDFormReceiver: getRFIDFormReceiverSlice,
 getRFIDForm: getRFIDFormSlice,
 getQRForm: getQRFormSlice,
 getCreateTicketForm: getCreateTicketFormSlice,
 getReturnTransponderForm: getReturnTransponderFormSlice
};

