import { downloadReimbursementRequest } from '@redux/Reimbursement/downloadReimbursementSlice';
import { getReimbursementRequest } from '@redux/Reimbursement/getReimbursementSlice';
import { getReimbursementCreator, getReimbursementRefreshToken } from '@redux/Reimbursement/selectors';
import { hideLoading, showLoading } from '@redux/global/globalSlice';
import { showErrorMsg } from '@utils/api';
import { FileDownloadUtil } from '@utils/common/file/download';
import { FileTransform } from '@utils/transform/file';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

const LIMIT = 25;

export const useReimbursement = navigation => {
  const dispatch = useDispatch();
  // getListStatusData = useSelector(getReimbursementCreator)?.data,
  const refreshToken = useSelector(getReimbursementRefreshToken);
  const [page, setPage] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [data, setData] = useState([]);
  const [info, setInfo] = useState({
    balance: null,
  });
  const [allData, setAllData] = useState([]);
  const getReimbursementApi = (page = 0) => {
    if (page > 0) {
      setLoadingMore(true);
    } else {
      setLoading(true);
      dispatch(showLoading());
    }
    const payload = {
      offset: LIMIT * page,
      limit: LIMIT,
    };

    console.log('getReimbursementApi>>>', page);

    dispatch(
      getReimbursementRequest(
        payload,
        rs => {
          setLoading(false);
          if (page > 0) {
            setLoadingMore(false);
          }
          const ls = rs?.data?.logs;
          setData([...ls]);
          if (page === 0) {
            setAllData([...ls]);
            //NOTE: should set bonusInfo when page = 0
            setInfo({ balance: rs?.data?.balance });
          } else {
            setAllData([...allData, ...ls]);
          }
        },
        err => {
          setData([]);
          setLoading(false);
          if (page > 0) {
            setLoadingMore(false);
          } else {
            // setLoading(false);
            // dispatch(hideLoading());
          }
        },
      ),
    );
  };
  const syncData = () => {
    setPage(0);
    // getListTicketApi(selectedStatus, 0);
    // getListStatusApi();
    getReimbursementApi(0);
  };

  useEffect(() => {
    syncData();
  }, [refreshToken]);

  const onRefresh = () => {
    setPage(0);
    getReimbursementApi(0);
  };
  const onLoadMore = () => {
    console.log('onLoadMore>>>');
    let newPage = page + 1;
    if (!loadingMore && !loading && data.length > 0) {
      setPage(newPage);
      getReimbursementApi(newPage);
    }
  };

  const onItemPress = item => {};

  const onDownloadPress = () => {
    downloadReimbursementApi();
  };

  const downloadReimbursementApi = () => {
    // setLoading(true);
    dispatch(showLoading());

    dispatch(
      downloadReimbursementRequest(
        {},
        rs => {
          // setLoading(false);
          const imgSource = FileTransform.getImageSourceForPicker(
            null,
            FileTransform.fixUrl(rs?.data?.fileLink),
            rs?.data?.s3Config,
          );
          console.log('downloadReimbursementRequest>>>rs>>>', rs);
          console.log('downloadReimbursementRequest>>>url>>>', imgSource);
          // downloadFile({
          //   ...imgSource,
          //   fileName: rs?.data?.uploadFileName,
          // });
          FileDownloadUtil.downloadFile(
            { ...imgSource, fileName: rs?.data?.uploadFileName },
            rs => {
              if (Platform.OS === 'ios') {
                showErrorMsg(t('DOWNLOAD_SUCCESS'), '', 'success');
              }
              dispatch(hideLoading());
            },
            err => {
              dispatch(hideLoading());
            },
          );
        },
        err => {
          // setLoading(false);
          dispatch(hideLoading());
        },
      ),
    );
  };

  return {
    data,
    allData,
    onRefresh,
    isRefreshing,
    loadingMore,
    loading,
    onLoadMore,
    onItemPress,
    info,
    onDownloadPress,
  };
};
