// Import here your languages
import en from './locales/en.json';
import it from './locales/it.json';

// Set here you favourite default language
export const defaultLanguage = 'en';

// Export here your language files import
export const languagesResources = {
  en: {
    translation: en,
  },

  it: {
    translation: it,
  },
};

// export const languagesResources = {
//   en,
//   it,
// };

