import { palette } from '@theme/colors';
import { FontSize, Spacing } from '@theme/size';
import { StyleSheet, Platform } from 'react-native';

export const styles = StyleSheet.create({
  areaStyle: { backgroundColor: '#EDEDED' },
  container: {
    paddingHorizontal: Spacing.width20,
    // backgroundColor: 'red',
    flex: 1,
    borderTopRightRadius: Spacing.width20,
    borderTopLeftRadius: Spacing.width20,
    //
    backgroundColor: palette.WHITE,
  },
  closeBtn: {
    backgroundColor: '#c9c9c9',
    borderRadius: 1000,
    width: Spacing.width30,
    height: Spacing.width30,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
