import { getListHolidaysService } from '@services/holiday'

// @ts-ignore
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { getListHolidaysFailed, getListHolidaysSuccess } from './getListHolidaysSlice';
import { Constant } from "@constant";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* getListHolidaysSaga({ payload }): Generator<
  | CallEffect
  | PutEffect<{
      payload: any;
      type: string;
    }>,
  void
> {
  try {
    const res = yield call(getListHolidaysService, payload);
    console.log('getListHolidaysSaga>>>>', res);
    if (!isEmpty(res)) {
      yield put(getListHolidaysSuccess(res));
    } else {
      // @ts-ignore
      yield put(getListHolidaysFailed(Constant.ERROR_NO_RESPONSE));
    }
  } catch (err) {
    // @ts-ignore
    yield put(getListHolidaysFailed(err));
  }
}
