import { Constant } from '@constant';
import { palette } from '@theme/colors';
import { Font, FontSize, Spacing } from '@theme/size';
import { StyleSheet, Platform } from 'react-native';

export const styles = StyleSheet.create({
  areaStyle: { backgroundColor: '#EDEDED' },
  container: {
    paddingHorizontal: Spacing.width15,
    flex: 1,
    borderTopRightRadius: Spacing.width20,
    borderTopLeftRadius: Spacing.width20,
    backgroundColor: palette.WHITE,
    alignItems: Platform.OS === 'web' ? '' : 'center',
    paddingTop: Platform.OS === 'web' ? Spacing.height10 : Spacing.height45,
  },
  avatarWrap: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: Platform.OS === 'web' ? Spacing.height10 : Spacing.height35,
  },
  avatar: {
    position: 'relative',
  },
  editAvatar: {
    position: 'absolute',
    bottom: 0,
    right: -10,
  },
  name: {
    ...Font.Bold_700,
    fontSize: FontSize.Font16,
    color: '#333333',
    marginTop: Spacing.height6,
  },
  profileItem: {
    backgroundColor: '#F7F7F7',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: Spacing.width15,
    minHeight: Constant.MAIN_HEIGHT,
    borderWidth: 1,
    borderColor: '#C6C6C6',
    borderRadius: 4,
    marginBottom: Platform.OS === 'web' ? '20px' : Spacing.height15,
  },
  button: {
    backgroundColor: palette.RECESS_GREY,
    marginBottom: Spacing.height25,
    marginHorizontal: Platform.OS === 'web' ? '' : Spacing.height15,
  },
  scrollView: { width: '100%', flexGrow: 1 },
  itemText: {
    flex: 1,
    ...Font.Bold_400,
    fontSize: FontSize.Font15,
    color: '#333333',
  },
});
