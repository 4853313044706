import type { RootState } from '@redux/reducers';
import { createSelector } from '@reduxjs/toolkit';
import { getErrorMessage } from '@utils/api';
import {mappingTicketSubject} from "@utils/transform/ticket";

export const createTicketSelector = (state: RootState) => state.createTicket;
export const createTicketCreator = createSelector(createTicketSelector, state => state.payload);
export const createTicketLoading = createSelector(createTicketSelector, state => state.loading);
export const createTicketError = createSelector(createTicketSelector, state => state.error);
export const createTicketErrorMsg = createSelector(createTicketError, state => getErrorMessage(state));

export const getListTicketSelector = (state: RootState) => state.getListTicket;
export const getListTicketCreator = createSelector(getListTicketSelector, state => state.payload);
export const getListTicketLoading = createSelector(getListTicketSelector, state => state.loading);
export const getListTicketError = createSelector(getListTicketSelector, state => state.error);
export const getForceRefreshListTicket = createSelector(getListTicketSelector, state => state.forceRefresh);

export const getListTicketErrorMsg = createSelector(getListTicketError, state => getErrorMessage(state));

export const getTicketDetailSelector = (state: RootState) => state.getTicketDetail;
export const getTicketDetailCreator = createSelector(getTicketDetailSelector, state => state.payload);
export const getTicketDetailLoading = createSelector(getTicketDetailSelector, state => state.loading);
export const getTicketDetailError = createSelector(getTicketDetailSelector, state => state.error);
export const getTicketDetailErrorMsg = createSelector(getTicketDetailError, state => getErrorMessage(state));

export const getListStatusSelector = (state: RootState) => state.getListStatus;
export const getListStatusCreator = createSelector(getListStatusSelector, state => state.payload);
export const getListStatusLoading = createSelector(getListStatusSelector, state => state.loading);
export const getListStatusError = createSelector(getListStatusSelector, state => state.error);
export const getListStatusErrorMsg = createSelector(getListStatusError, state => getErrorMessage(state));

export const getListSubjectSelector = (state: RootState) => state.getListSubject;
export const getListSubjectCreator = createSelector(getListSubjectSelector, state => state.payload);
export const getListSubjectLoading = createSelector(getListSubjectSelector, state => state.loading);
export const getListSubjectError = createSelector(getListSubjectSelector, state => state.error);
export const getListSubjectErrorMsg = createSelector(getListSubjectError, state => getErrorMessage(state));
export const getListSubjectStandard = createSelector(getListSubjectCreator, state => mappingTicketSubject(state));

export const addCommentSelector = (state: RootState) => state.addComment;
export const addCommentCreator = createSelector(addCommentSelector, state => state.payload);
export const addCommentLoading = createSelector(addCommentSelector, state => state.loading);
export const addCommentError = createSelector(addCommentSelector, state => state.error);
export const addCommentErrorMsg = createSelector(addCommentError, state => getErrorMessage(state));



