import { getContentTitle } from '../form';

export const mappingMenuList = data => {
  // console.log({ data });
  // return [];
  // if (!data || isEmpty(data)) return [];
  const transformedData = data?.map(item => {
    const childData = Array.isArray(item?.data) ? item?.data : [];
    const childMappedData = childData?.map(cDItem => {
      return {
        ...cDItem,
        content: getContentTitle(cDItem?.content),
        title: getContentTitle(cDItem?.title),
      };
    });
    return {
      ...item,
      heading: getContentTitle(item?.heading),
      data: childMappedData,
    };
  });
  return transformedData;
};


