import { AppForm } from '@components/AppForm';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotificationSetting } from './NotificationSetting.hook';

const NotificationSetting = props => {
  const { setValue, value, formik, data, scrollToY, scrollViewRef, layoutInfo, setLayoutInfo } =
    useNotificationSetting(props);
  const { t } = useTranslation();

  return (
    // <AppSafeAreaView areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>
    //   <GenericHeader
    //     onBackClicked={() => {
    //       goBack();
    //     }}
    //     title={getContentTitle(data?.title)}
    //     style={[{ backgroundColor: palette.BACKGROUND_GREY }]}
    //   />
    //   <View style={[globalStyle.directionColumm, globalStyle.flex1, RegisterFormStyles.containerView]}>
    //     <KeyboardAwareScrollView
    //       innerRef={ref => {
    //         if (ref) {
    //           scrollViewRef.current = ref;
    //         }
    //       }}
    //       style={[]}
    //       keyboardShouldPersistTaps="handled">
    //       <AppText
    //         style={[
    //           globalStyle.alignCenter,
    //           globalStyle.justifyCenter,
    //           RegisterFormStyles.paddingSpace,
    //           RegisterFormStyles.descriptionText,
    //           { marginVertical: data?.description ? theme.spacing.md : 0 },
    //         ]}>
    //         {data?.description}
    //       </AppText>
    //
    //       <View style={[globalStyle.directionColumm, RegisterFormStyles.paddingSpace]}>
    //         {data?.attributes?.map((item, index) => {
    //           return (
    //             <FormItemView
    //               index={index}
    //               item={item}
    //               formik={formik}
    //               isBoldTitle={true}
    //               checkCondition={true}
    //               onLayout={e => {
    //                 const layout = e?.nativeEvent?.layout;
    //                 if (!layout) {
    //                   return;
    //                 }
    //                 const newInfo = { ...layoutInfo };
    //                 newInfo[item?.internalId] = layout.y + layout.height;
    //                 setLayoutInfo(newInfo);
    //               }}
    //             />
    //           );
    //         })}
    //       </View>
    //     </KeyboardAwareScrollView>
    //     <View
    //       style={[
    //         RegisterFormStyles.paddingSpace,
    //         { marginVertical: theme.spacing.sm, marginBottom: Spacing.height25 },
    //       ]}>
    //       <AppButton
    //         text={t('SAVE')}
    //         // onPress={formik.handleSubmit}
    //         onPress={async () => {
    //           await FormTransform.submitAndScroll(formik, data?.attributes, layoutInfo, scrollToY);
    //         }}
    //       />
    //     </View>
    //   </View>
    // </AppSafeAreaView>
    <AppForm
      title={data?.title}
      description={data?.description}
      scrollViewRef={scrollViewRef}
      scrollToY={scrollToY}
      formik={formik}
      attributes={data?.attributes}
      setLayoutInfo={setLayoutInfo}
      layoutInfo={layoutInfo}
      buttonText={t('SAVE')}
    />
  );
};

export { NotificationSetting };
