import { StackActions, useNavigation, useRoute } from '@react-navigation/native';
import { LOGIN_SCREEN } from '@routes/route';
// import {authCreator} from "@redux/auth/selectors";
import { useEffect } from 'react';
import {BackHandler} from "react-native";

// import { useDispatch } from 'react-redux';

export const useSuccessful = props => {
  const navigation = useNavigation();
  const messageInfo = useRoute().params?.data;
  const backClick = useRoute().params?.header?.backClick;
  const headerTitle = useRoute().params?.header?.title;
  useEffect(() => {
    const backHandler = BackHandler.addEventListener('hardwareBackPress', () => true)
    return () => backHandler.remove()
  }, [])
  // console.log('useRegisterCompleted>>>>');
  // useEffect(() => {
  //   console.log('waitingForSendMail>>>>');
  //   setTimeout(() => {
  //     // dispatch(resetUserValidation({}));
  //     navigation.dispatch(StackActions.replace(LOGIN_SCREEN));
  //   }, 5000);
  // }, []);
  return { messageInfo, backClick, headerTitle };
};
