import { ArrowLeftIcon } from '@assets';
import styles from '@components/GenericHeader/styles';
import { globalStyle } from '@theme';
import { TopNavigation } from '@ui-kitten/components';
import React, { FC, memo } from 'react';
import { TouchableOpacity, View } from 'react-native';
import {AppText} from "@components";

const DashboardHeader = ({
  BodyHeader,
  LeftAction,
  title,
  RightAction,
  onBackClicked,
  style = {},
  subtitle = undefined,
  withShadow = false,
}) => {
  return (
    <TopNavigation
      alignment="center"
      style={[styles.headerContainer, withShadow && styles.HeaderShadow, style]}
      title={() => (
        <View style={styles.bodyContainer}>
          {/*
            You can pass the page name or a component that will be rendered in the middle of your Header
          */}
          <View style={styles.BodyContent}>
            {!!title && (
              <AppText style={styles.mainPageTitle} numberOfLines={1} ellipsizeMode="tail">
                {title}
              </AppText>
            )}
            {BodyHeader}
          </View>
        </View>
      )}
      accessoryLeft={() => (
        <View style={[globalStyle.directionRow, globalStyle.center]}>
          {/*
            The GenericHeader component accepts an onBackClicked prop.
            The route where you want to go is specified in the import of this Header in your scene component
          */}
          {!!onBackClicked && (
            <TouchableOpacity onPress={onBackClicked} style={styles.backButtonContainer}>
              <ArrowLeftIcon />
              {/* <Trans style={styles.backButtonStyle} i18nKey="Header:back" /> */}
            </TouchableOpacity>
          )}
          {/*
            You can also pass a custom component defined in you scene component for the left section of the Header
          */}
          {LeftAction}
        </View>
      )}
      accessoryRight={() => (
        <View style={[globalStyle.directionRow, globalStyle.center]}>
          {/*
            You can also pass a custom component defined in you scene component for the right section of the Header
          */}
          {RightAction}
        </View>
      )}
      subtitle={evaProps => (!subtitle ? <></> : <AppText {...evaProps}>{subtitle}</AppText>)}
    />
  );
};

export default memo(DashboardHeader);
