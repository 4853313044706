import { palette } from '@theme/colors';
import { StyleSheet } from 'react-native';

export const AppAnswerSingleSelectionStyle = StyleSheet.create({
  container: {
    width: '100%',
    paddingTop: 15,
  },
  textTitle: {
    fontWeight: '700',
    marginBottom: 12,
  },
  isRequired: {
    color: '#FF5757',
  },
  error: {
    marginTop: 7,

    color: palette.ROYAL_MAIL_RED,
    fontWeight: '400',
  },
});
