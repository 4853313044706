import { Constant } from '@constant';
import { getListSubjectService } from '@services/ticket';
// @ts-ignore
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { getListSubjectFailed, getListSubjectSuccess } from './getListSubjectSlice';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* getListSubjectSaga({ payload }): Generator<
  | CallEffect
  | PutEffect<{
      payload: any;
      type: string;
    }>,
  void
> {
  try {
    const res = yield call(getListSubjectService, payload);
    console.log('getListSubjectSaga>>>>', res);
    if (!isEmpty(res)) {
      yield put(getListSubjectSuccess(res));
    } else {
      // @ts-ignore
      yield put(getListSubjectFailed(Constant.ERROR_NO_RESPONSE));
    }
  } catch (err) {
    // @ts-ignore
    yield put(getListSubjectFailed(err));
  }
}
