import { AppComponentLoading } from '@components';
import React from 'react';

export const withLoading = Component => {
  const Wrapped = ({ loading, error, ...rest }) => {
    if (error) {
      return <AppComponentLoading />;
    }

    if (loading) {
      return <AppComponentLoading />;
    }

    return <Component {...rest} />;
  };

  return Wrapped;
};
