import { Constant } from '@constant';
import { unsubscribeUserService } from '@services/user';
// @ts-ignore
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { unsubscribeUserFailed, unsubscribeUserSuccess } from './unsubscribeUserSlice';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// @ts-ignore
export function* unsubscribeUserSaga({ payload, meta, error }): Generator<
  | CallEffect
  | PutEffect<{
      payload: any;
      type: string;
    }>,
  void
> {
  try {
    const res = yield call(unsubscribeUserService, payload);
    console.log('unsubscribeUserSaga>>>>', res);
    if (res?.status) {
      yield put(unsubscribeUserSuccess(res));
      if (meta) {
        yield call(meta, res);
      }
    } else {
      // @ts-ignore
      yield put(unsubscribeUserFailed(res));
      if (error) {
        yield call(error, res);
      }
    }
  } catch (err) {
    // @ts-ignore
    yield put(unsubscribeUserFailed(err));
    if (error) {
      yield call(error, err);
    }
  }
}
