import React from 'react';
// import ShowMoreText from "react-show-more-text";
import {TicketDetailStyle} from "@screens/main/ticket/TicketDetail/TicketDetail.style";
import {t} from 'i18next'
import PropTypes from "prop-types";
import AppText from "@components/AppText/AppText";
import ShowMoreText from "@components/AppReadMore/web/ShowMoreText";
export const AppReadMore = (props) => {
    // return <ReadMore
    //     numberOfLines={2}
    //     allowFontScaling
    //     style={[TicketDetailStyle.textSecondTitle, { marginBottom: 8 }]}
    //     seeLessText={t('VIEW_LESS')}
    //     seeMoreText={t('VIEW_MORE')}
    //     seeLessStyle={[TicketDetailStyle.viewMore]}
    //     seeMoreStyle={[TicketDetailStyle.viewMore]}>
    //     {props?.text}
    //     {/*<RenderHtml source={{ html: data?.content }} baseStyle={CommentStyle.commentText} />*/}
    // </ReadMore>
    console.log('AppReadMore>>>', props)
    return <ShowMoreText
        lines={2}
        more={t('VIEW_MORE')}
        less={t('VIEW_LESS')}
        // className={}
        // anchorClass={TicketDetailStyle.textSecondTitle}
        // onClick={this.executeOnClick}
        style={{
            ...TicketDetailStyle.textSecondTitle,
            marginBottom: 8}}
        anchorStyle={TicketDetailStyle.viewMore}
        expanded={false}
        // width={100}
        truncatedEndingComponent={"... "}
    >
        <AppText style={TicketDetailStyle.textSecondTitle}>
            {props.text}

        </AppText>
    </ShowMoreText>
}

AppReadMore.propTypes = {
    text: PropTypes.string,
};

AppReadMore.defaultProps = {};