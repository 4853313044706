import { Constant } from '@constant';
import { getQuizService } from '@services/quizzes';
// @ts-ignore
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { getQuizFailed, getQuizSuccess } from './getQuizSlice';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// @ts-ignore
export function* getQuizSaga({ payload, meta, error }): Generator<
  | CallEffect
  | PutEffect<{
      payload: any;
      type: string;
    }>,
  void
> {
  try {
    const res = yield call(getQuizService, payload);
    console.log('getQuizSaga>>>>', res);
    if (!isEmpty(res)) {
      yield put(getQuizSuccess(res));
      if (meta) {
        yield call(meta, res);
      }
    } else {
      // @ts-ignore
      yield put(getQuizFailed(Constant.ERROR_NO_RESPONSE));
      if (error) {
        yield call(error, Constant.ERROR_NO_RESPONSE);
      }
    }
  } catch (err) {
    // @ts-ignore
    yield put(getQuizFailed(err));
    if (error) {
      yield call(error, err);
    }
  }
}
