import { Constant } from '@constant';
import { Config } from '@constant/config';
import { ReportModalMockData } from '@constant/model/mock/form/reportModal';
import {mapSettingSelector, reportModal as reportModalSelector} from '@redux/app/selectors';
import { getReturnTransponderFormRequest } from '@redux/form/getReturnTransponderFormSlice';
import { navigate, navigatePush, navigateReset } from '@routes/navigationUtils';
import { DIGIT, Screens } from '@routes/route';
import { getPanelistId, mappingReportModal, reportModal } from '@utils';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useBottomMapModal = props => {
  // const reportModalData = useSelector(reportModalSelector);

  const dispatch = useDispatch();
  const mapSettings = useSelector(mapSettingSelector);

  return { mapSettings};
};
