import { loginService } from '@services/user'

// @ts-ignore
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { loginFailed, loginSuccess } from './loginSlice';
import { Constant } from "@constant";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* loginSaga({ payload, meta, error }) {
    try {
        const res = yield call(loginService, payload);
        console.log('loginSaga>>>>', res);
        if (!isEmpty(res)) {
            // yield put(loginSuccess(res));
            yield put(
                loginSuccess({
                    ...res.data,
                    accessToken: res.data?.access_token,
                    email: res.data.email,
                }),
            );
            if(meta){
                meta(res)
            }
            if (res.status === false) throw res.message;
        } else {
            // @ts-ignore
            yield put(loginFailed(Constant.ERROR_NO_RESPONSE));
            if(error){
                error(res)
            }
        }
    } catch (err) {
        // @ts-ignore
        yield put(loginFailed(err));
        if(err){
            error(err)
        }
    }
}
