// import { Colors, Dimens } from '@/constant';
import theme from '@theme';
import { palette } from '@theme/colors';
import { FontSize } from '@theme/size';
import { Platform, StyleSheet } from 'react-native';

export const TicketItemStyles = StyleSheet.create({
  container: {
    backgroundColor: '#F5F5F5',
    marginLeft: theme.spacing.sm,
    marginRight: theme.spacing.sm,
    marginBottom: theme.spacing.md,
    padding: theme.spacing.md,
    borderRadius: 4,
    borderColor: '#C6C6C6',
  },
  textTitle: {
    color: '#333333',
    fontSize: FontSize.Font15,
    fontWeight: '700',
    flexWrap: 'wrap',
    // padding: 4,
    // flexShrink: 1,
  },
  textSecondTitle: {
    color: '#333333',
    fontWeight: '400',
  },
  textNormal: {
    color: '#666666',
    fontSize: FontSize.Font14,
    marginVertical: 8,
    // color: 'green',

    // fontSize: Dimens.FontSize.Small,
    // fontWeight: Dimens.FontWeight.Normal,
  },
  priority: {
    color: palette.WHITE,
    fontSize: FontSize.Font14,
    paddingHorizontal: theme.spacing.sm,
    paddingVertical: 2,
    borderRadius: 4,
    borderColor: palette.ROYAL_MAIL_RED,
  },
  viewLine: {
    height: 12,
    // height: '100%',
    width: 2,
    backgroundColor: '#333333',
    // backgroundColor: Colors.redColor,

    marginLeft: Platform.OS == 'ios' ? 0 : 4,
    marginRight: Platform.OS == 'ios' ? 0 : 4,
    // padding: 4,
    // justifyContent: 'center',
  },
});
