import { LogoIcon, MessageCheckIcon, WarningIcon } from '@assets';
import { AppButton, AppDateTimePicker, AppKAView, AppModal, AppSafeAreaView, AppText } from '@components';
import { ForceUpdateStyles } from '@components/AppForceUpdate/ForceUpdate.style';
import { AppConfirmModal } from '@components/AppModal/AppConfirmModal';
import GenericHeader from '@components/GenericHeader';
import { goBack } from '@routes/navigationUtils';
import Colors from '@screens/main/report/ListReport/Colors';
import Dimens from '@screens/main/report/ListReport/Dimens';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font, FontSize } from '@theme/size';
import { DATE_FORMAT, formatDate, formatDateV1, getDateByAddedDay } from '@utils/common/MomentUtils';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Text, TouchableOpacity, FlatList, RefreshControl } from 'react-native';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { useListReport } from './ListReport.hook';
import { ListReportStyle as styles } from './ListReport.style';

export const ListReportScreen = props => {
  const { t } = useTranslation();
  const {
    currentScreen,
    loading,
    processing,
    startDate,
    endDate,
    onButtonSavePressed,
    onButtonResetPressed,
    onDeleteItemPressed,
    setEndDate,
    setStartDate,
    holidays,
    onRefresh,
    showHistories,
    processingReset,
    user,
    showModal,
    onNoModalPress,
    onYesModalPress,
  } = useListReport(props);

  const renderHeaderView = () => {
    return (
      <View style={styles.historyRowContainer}>
        <View style={[styles.startDateContainer, { backgroundColor: Colors.grayColor5 }]}>
          <AppText style={[styles.textDate, { fontWeight: 'bold' }]}>{t('START_DATE')}</AppText>
        </View>
        <View style={[styles.endDateContainer, { backgroundColor: Colors.grayColor5 }]}>
          <AppText style={[styles.textDate, { fontWeight: 'bold' }]}>{t('END_DATE')}</AppText>
        </View>
        <View style={[styles.deleteContainer, { backgroundColor: Colors.grayColor5 }]} />
      </View>
    );
  };

  const renderItemRow = item => {
    return (
      <View style={styles.historyRowContainer}>
        <View style={[styles.startDateContainer, { backgroundColor: '#F5F5F5' }]}>
          <AppText style={styles.textDate}>{formatDateV1(item.from, DATE_FORMAT.UIDate)}</AppText>
        </View>
        <View style={[styles.endDateContainer, { backgroundColor: '#F5F5F5' }]}>
          <AppText style={styles.textDate}>{formatDateV1(item.to, DATE_FORMAT.UIDate)}</AppText>
        </View>
        <View style={[styles.deleteContainer, { backgroundColor: '#F5F5F5' }]}>
          {item.isDelete && (
            <TouchableOpacity
              // style={[styles.deleteContainer, { backgroundColor: '#F5F5F5' }]}
              onPress={() => onDeleteItemPressed(item)}>
              <FontAwesome5 name={'trash-alt'} size={16} color={Colors.textColor} />
            </TouchableOpacity>
          )}
        </View>
      </View>
    );
  };

  return (
    <View style={[globalStyle.flex1, { padding: 16, backgroundColor: palette.WHITE }]}>
      <View
        style={{
          width: '100%',
          flex: 1,
          borderRadius: 4,
          borderWidth: 1,
          borderColor: '#D5D5D5',
          backgroundColor: palette.WHITE,
          padding: 2,
          marginBottom: 15,
        }}>
        {renderHeaderView()}
        <FlatList
          showsVerticalScrollIndicator={false}
          data={holidays}
          keyExtractor={item => item.id}
          renderItem={({ item, index }) => renderItemRow(item)}
          refreshControl={
            <RefreshControl
              refreshing={loading}
              onRefresh={
                loading
                  ? null
                  : () => {
                      onRefresh();
                    }
              }
              tintColor={palette.LOADING_COLOR}
              colors={[palette.LOADING_COLOR]}
              titleColor={Colors.activityColor}
            />
          }
        />
      </View>
      <AppConfirmModal
        onNoModalPress={onNoModalPress}
        onYesModalPress={onYesModalPress}
        showModal={showModal}
        textContent={t('HOLIDAY_DELETE_MODAL_CONTENT')}
      />
      {/*<AppModal*/}
      {/*  // onDismiss={handleHideModal}*/}
      {/*  closeOnTouchOutside*/}
      {/*  visible={showModal}*/}
      {/*  style={[{ padding: theme.spacing.md }]}*/}
      {/*  containerStyle={[{ borderRadius: 8 }]}*/}
      {/*  // contentContainerStyle={{ maxWidth: '92%' }}*/}
      {/*>*/}
      {/*  <View>*/}
      {/*    <View style={[globalStyle.directionColumm, { marginTop: 24 }]}>*/}
      {/*      <View style={[globalStyle.center]}>*/}
      {/*        <WarningIcon width={45} height={45} />*/}
      {/*      </View>*/}
      {/*      <View style={[globalStyle.center, { paddingVertical: theme.spacing.md }]}>*/}
      {/*        /!*<AppText style={[Font.Bold_700, globalStyle.center]}>{t('DEEPLINK_MODAL_TITLE')}</AppText>*!/*/}
      {/*        <AppText*/}
      {/*          style={[*/}
      {/*            {*/}
      {/*              fontSize: FontSize.Font15,*/}
      {/*              paddingVertical: theme.spacing.sm,*/}
      {/*              textAlign: 'center',*/}
      {/*              color: '#666666',*/}
      {/*              maxWidth: '85%',*/}
      {/*            },*/}
      {/*          ]}>*/}
      {/*          {t('HOLIDAY_DELETE_MODAL_CONTENT')}*/}
      {/*        </AppText>*/}
      {/*      </View>*/}
      {/*    </View>*/}
      {/*    <View style={[ForceUpdateStyles.row, { margin: theme.spacing.md }]}>*/}
      {/*      <AppButton*/}
      {/*        onPress={() => {*/}
      {/*          // onGoToStorePress();*/}
      {/*          onNoModalPress();*/}
      {/*        }}*/}
      {/*        text={t('NO')}*/}
      {/*        style={[{ flex: 1, backgroundColor: '#C1C6C8', marginRight: theme.spacing.md }]}*/}
      {/*      />*/}
      {/*      <AppButton*/}
      {/*        onPress={() => {*/}
      {/*          onYesModalPress();*/}
      {/*        }}*/}
      {/*        text={t('YES')}*/}
      {/*        style={[{ flex: 1 }]}*/}
      {/*      />*/}
      {/*    </View>*/}
      {/*  </View>*/}
      {/*</AppModal>*/}
    </View>
  );
};
