import { Config } from '@constant/config';
import { getLogoutRequest, resetUserValidation } from '@redux/auth/authSlice';
import { getFormRequest } from '@redux/form/getFormSlice';
import { getProfileFormRequest } from '@redux/form/getProfileFormSlice';
import { getFormStandard, getProfileFormStandard } from '@redux/form/selectors';
import { getProfileRequest } from '@redux/user/getProfileSlice';
// import { logoutRequest } from '@redux/user/logoutSlice';
import { getProfileCreator } from '@redux/user/selectors';
import { navigatePush } from '@routes/navigationUtils';
import { Screens } from '@routes/route';
import { getPanelistId } from '@utils';
import { cloneDeep } from 'lodash';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {FormGroupHelper} from "@utils/form/group";
import {hideLoading} from "@redux/global/globalSlice";

export const useProfileEdit = props => {
  const dispatch = useDispatch();
  const getFormData = useSelector(getProfileFormStandard) ?? [];
  const rawData =  cloneDeep(getFormData);
  // const rawData =  FormGroupHelper.getTestGroupForm(cloneDeep(getFormData));
  // const [data, setData] = useState(rawData);

  // const scripted = 'return {{registrationsource}} == \'web\' || moment({{creationdate}}).diff(moment(\'2023-02-01\')) >= 0 && moment({{creationdate}}).diff(moment(\'2023-02-10\')) <= 0 || parseInt({{integer}}) >= 2 && parseInt({{integer}}) <= 6 || {{boolean}} == 1 || {{multiple}} == \'63e3673d4e2f4252acf692d4\' || {{singlechoice}} == \'63e3752a3f66203a424f1dec\' || {{nameeee}}.indexOf(\'duc\') !== -1 || {{panellistid}} != \'abc\';'
  // FormGroupHelper.getDependencyFieldsByScripted(scripted)
  const getProfileData = useSelector(getProfileCreator)?.message?.data;
  const data = rawData.filter(x => !FormGroupHelper.checkHideGroupForm(x, getProfileData));
  // const data = rawData
  console.log('useProfileEdit>>>', getFormData, rawData, data);

  const getFormApi = () => {
    dispatch(getProfileFormRequest({ panelistId: getPanelistId() }));
  };
  const getProfileApi = () => {
    const payload = {};
    dispatch(getProfileRequest(payload));
  };

  const logoutApi = () => {
    const payload = {};
    dispatch(
      getLogoutRequest(
        payload,
        res => {
          //TODO: remove token, user
        },
        error => {},
      ),
    );
  };

  const onItemPress = item => {
    console.log('onItemPress>>>>', item);
    // const attributes = item?.attributes;
    // if (!_.isEmpty(attributes) && attributes[0]?.internalId === 'push_notification') {
    //   if (Config.EnableFeature.notification) {
    //     navigatePush(Screens.NOTIFICATION_SETTING, { data: item, form: rawData });
    //   }
    //   return;
    // }

    navigatePush(Screens.PROFILE_FORM_SCREEN, { data: item, form:rawData });
  };

  const onLogoutPress = () => {
    logoutApi();
  };

  useEffect(() => {
    getFormApi();
    getProfileApi();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      dispatch(hideLoading())
    }, 3000)
  }, [])

  return { data, onItemPress, getProfileData, onLogoutPress };
};
