import api from '@services/api-client';
import { stringify } from 'qs';
import withQuery from 'with-query';

export const submitICodeService = async payload => {
  console.log('submitICodeService>>>', payload)
  const response = await api.put(`/icodes/${payload.id}`, payload)
  console.log('submitICodeService response>>>', response)
  return response?.data
}

