import { createSlice } from '@reduxjs/toolkit';

export interface IgetListSubjectState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IgetListSubjectState = {
  loading: false,
  payload: null,
  error: null,
};

const getListSubjectSlice = createSlice({
  name: 'getListSubject',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getListSubjectRequest(state, action) {
      state.loading = true;
      // @ts-ignore
      state.payload = null;
      state.error = null;
    },
    getListSubjectSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    getListSubjectFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getListSubjectRequest, getListSubjectSuccess, getListSubjectFailed } = getListSubjectSlice.actions;

export default getListSubjectSlice.reducer;
