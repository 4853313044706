import { NavigationContainerRef, StackActions } from '@react-navigation/native';
import { CommonActions } from '@react-navigation/native';
import { NavigationAction } from '@react-navigation/routers';
import { createRef } from 'react';

export const navigationRef = createRef<NavigationContainerRef<any>>();
export const isMountedRef = createRef();
// @ts-ignore
export const isFirstTimeApp = createRef(true);

interface NavigateProps {
  (name: string, params: Record<string, unknown>): void;
}

// Use this function to navigate to specific page when you are using Redux-Saga
export const navigate: NavigateProps = (name, params) => {
  if (isMountedRef.current && navigationRef.current) {
    // Perform navigation if the app has mounted
    // Keyboard.dismiss()
    console.log('navigate>>>', name, params, navigationRef.current.getState());
    navigationRef.current.navigate(name, params);
    // navigationRef.current?.dispatch(StackActions.push(name, params));
  } else {
    // You can decide what to do if the app hasn't mounted
    // You can ignore this, or add these actions to a queue you can call later
  }
};

export const goBack = (): void => {
  if (navigationRef.current) {
    // Perform navigation if the app has mounted
    if(navigationRef.current.canGoBack()){
      navigationRef.current.goBack();
    }
  } else {
    // You can decide what to do if the app hasn't mounted
    // You can ignore this, or add these actions to a queue you can call later
  }
};

export const goBackWidthScreen = (name): void => {
  if (isMountedRef.current && navigationRef.current) {
    // Perform navigation if the app has mounted
    // Keyboard.dismiss()
    console.log('navigate>>>', name);
    navigationRef.current.navigate(name);
    // navigationRef.current?.dispatch(StackActions.push(name, params));
  } else {
    // You can decide what to do if the app hasn't mounted
    // You can ignore this, or add these actions to a queue you can call later
  }
};

export const reset = (index: number, routes: { name: string; params?: any }[]): void => {
  if (!navigationRef.current) return;
  navigationRef.current.dispatch(
    //@ts-ignore
    CommonActions.reset({
      index: index,
      routes: routes?.map(route => {
        return { name: route.name, params: route.params };
      }),
    }),
  );
};

export const replace: NavigateProps = (name, params): void => {
  if (!navigationRef.current) return;
  navigationRef.current.dispatch(
    //@ts-ignore
    StackActions.replace(name, params),
  );
};

export const navigatePush: NavigateProps = (name, params) => {
  if (isMountedRef.current && navigationRef.current) {
    // Perform navigation if the app has mounted
    // navigationRef.current.navigate(name, params);
    // Keyboard.dismiss()
    navigationRef.current?.dispatch(StackActions.push(name, params));
  } else {
    // You can decide what to do if the app hasn't mounted
    // You can ignore this, or add these actions to a queue you can call later
  }
};

export const navigatePop = (): void => {
  if (isMountedRef.current && navigationRef.current) {
    // Perform navigation if the app has mounted
    navigationRef.current?.dispatch(StackActions.pop());
  } else {
    // You can decide what to do if the app hasn't mounted
    // You can ignore this, or add these actions to a queue you can call later
  }
};

export const navigateReset = ({
  routes,
  index,
}: {
  routes: {
    name: string;
  }[];
  index: number;
}): void => {
  if (!navigationRef.current || index > routes.length || index < 0) return;
  navigationRef.current.dispatch(
    CommonActions.reset({
      index: index,
      routes: routes?.map((route: any) => {
        return { name: route.name, params: route.params };
      }),
    }),
  );
};

export const popToTop = (): void => {
  if (isMountedRef.current && navigationRef.current) {
    // Perform navigation if the app has mounted
    navigationRef.current?.dispatch(StackActions.popToTop());
  } else {
    // You can decide what to do if the app hasn't mounted
    // You can ignore this, or add these actions to a queue you can call later
  }
};

export const navDispatch = (action: NavigationAction): void => {
  if (isMountedRef.current && navigationRef.current) {
    // Perform navigation if the app has mounted
    navigationRef.current?.dispatch(action);
  }
};
