import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppButton, AppKAView, AppSafeAreaView, AppText } from '@components';
import { AppSuccessScreen } from '@components/AppSuccessScreen';
import { FormItemView } from '@components/Form';
import GenericHeader from '@components/GenericHeader';
import { UPLOAD_FILE } from '@constant';
import { CommonActions, useNavigation } from '@react-navigation/native';
import { goBack } from '@routes/navigationUtils';
import { BOTTOM_TAB } from '@routes/route';
import { useReportICodeDetail } from '@screens/main/report/iCode/detail/ICodeDetail.hook';
import { TopICodeView } from '@screens/main/report/iCode/form/components/TopICode.view';
import { ReportSuccessfulView } from '@screens/main/report/package/form/components/ReportSuccessful.view';
import { TopPackageView } from '@screens/main/report/package/form/components/TopPackage.view';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font, Spacing } from '@theme/size';
import { FormTransform } from '@utils/transform/form';
import { useLocation } from '@utils/hooks';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Text, Platform } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import {RenderHelper} from "@utils/form/render";

export const ReportICodeDetailScreen = props => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { data, formik, summary, isSuccess, point, headerTitle, messageInfo,
    layoutInfo,
    setLayoutInfo,
    scrollViewRef,
    scrollToY,
  } = useReportICodeDetail(props);

    const refLayoutInfo = useRef(null)
    useEffect(() => {
        refLayoutInfo.current = {}
    },[])

  const renderForm = () => {
    return (
      <>
        <KeyboardAwareScrollView
          style={[
            {
              backgroundColor: palette.WHITE,
              borderTopRightRadius: theme.spacing.lg,
              borderTopLeftRadius: theme.spacing.lg,
            },
          ]}
          innerRef={ref => {
              if (ref) {
                  scrollViewRef.current = ref;
              }
          }}
          keyboardShouldPersistTaps="handled">
          <TopICodeView data={summary} />
          <View style={[globalStyle.directionColumm, RegisterFormStyles.paddingSpace]}>
            {data?.attributes?.map((item, index) => {
              return (
                <FormItemView
                    key={`${item?.internalId}_${index}`}
                    style={[{ zIndex: Platform.OS === 'web' ? 'unset' : undefined}]}
                  index={index}
                  item={item}
                  formik={formik}
                  isBoldTitle={true}
                  checkCondition={true}
                  formData={data?.attributes}
                  folderUpload={UPLOAD_FILE.folderUpload.bundle}
                  onLayout={e => {
                      RenderHelper.onLayout(e, item, layoutInfo, setLayoutInfo, refLayoutInfo)
                  }}
                  scrollViewRef={scrollViewRef}
                />
              );
            })}
          </View>
        </KeyboardAwareScrollView>
        <View
          style={[
            RegisterFormStyles.paddingSpace,
            { marginVertical: theme.spacing.sm, marginBottom: Platform.OS === 'web' ? '20px' : Spacing.height25 },
          ]}>
          {/*<AppButton onPress={formik.handleSubmit} text={t('Report')} />*/}
            <AppButton
                // onPress={formik.handleSubmit}
                onPress={async () => {
                    await FormTransform.submitAndScroll(formik, data?.attributes, layoutInfo, scrollToY);
                }}
                text={t('Report')}
            />
        </View>
      </>
    );
  };
  return (
    <>
      <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
      <AppSafeAreaView areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>
        <GenericHeader title={headerTitle} style={[{ backgroundColor: '#EDEDED' }]} />
        <View style={[globalStyle.directionColumm, RegisterFormStyles.containerView]}>
          {isSuccess ? (
            <AppSuccessScreen
              alert={messageInfo?.alert}
              icon={messageInfo?.icon}
              description={messageInfo?.description}
            />
          ) : (
            renderForm()
          )}
        </View>
      </AppSafeAreaView>
    </>
  );
};
