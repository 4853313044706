import { AppSafeAreaView, AppKAView, AppText, AppButton, AppAccordion } from '@components';
import GenericHeader from '@components/GenericHeader';
import { useNavigation, useRoute } from '@react-navigation/native';
import { goBack, navigate, navigatePush, replace, reset } from '@routes/navigationUtils';
import { BOTTOM_TAB, DASHBOARD_SCREEN } from '@routes/route';
import theme from '@theme';
import { palette } from '@theme/colors';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, ScrollView, View, TouchableOpacity } from 'react-native';
import { useSurveyList } from './SurveyList.hook';
import { styles } from './SurveyList.style';

const SurveyListScreen = () => {
  const { surveysList, handleOnDayPress } = useSurveyList();
  const [isExpand, setIsExpand] = useState(false);
  const { t } = useTranslation();
  const navigation = useNavigation();
  const isFromDashboard = useRoute()?.params?.isFromDashboard;

  return (
    <>
      <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
      <AppSafeAreaView areaStyle={styles.areaStyle}>
        <GenericHeader
          onBackClicked={() =>
            isFromDashboard
              ? navigation.navigate(DASHBOARD_SCREEN)
              : navigation.replace(BOTTOM_TAB, { screen: DASHBOARD_SCREEN })
          }
          title={t('CORRECT_DELIVERY_SURVEY')}
          style={[{ backgroundColor: '#EDEDED' }]}
        />
        <View
          style={{
            borderTopRightRadius: theme.spacing.lg,
            borderTopLeftRadius: theme.spacing.lg,
            backgroundColor: palette.WHITE,
            width: '100%',
            flex: 1,
          }}>
          <ScrollView contentContainerStyle={{ marginTop: 15, paddingHorizontal: 15 }}>
            {surveysList.map((item, index) => {
              return (
                <AppAccordion
                  key={item?.week || index}
                  data={item}
                  onDayPress={handleOnDayPress}
                  isLast={index === surveysList.length - 1}
                  setIsExpand={setIsExpand}
                  isExpand={isExpand}
                />
              );
            })}
          </ScrollView>
        </View>
      </AppSafeAreaView>
    </>
  );
};

export { SurveyListScreen };
