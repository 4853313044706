import api from '@services/api-client';
import { stringify } from 'qs';
import withQuery from 'with-query';

export const getListHolidaysService = async payload => {
  console.log('getListHolidaysService>>>', payload)
  const response = await api.get(`/holidays.json?${stringify(payload)}`)
  console.log('getListHolidaysService response>>>', response)

  return response?.data
}

export const addHolidayService = async payload => {
  console.log('addHolidayService>>>', payload)
  const response = await api.post(`/holidays.json?${stringify(payload)}`, payload)
  console.log('addHolidayService response>>>', response)
  return response?.data
}

export const deleteHolidayService = async payload => {
  console.log('deleteHolidayService>>>', payload)
  const response = await api.delete(`/holidays/${payload?.id}.json?`)
  console.log('deleteHolidayService response>>>', response)
  return response?.data
}

