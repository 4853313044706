import { MessageCheckIcon, CrossIcon, WarningIcon } from '@assets';
import { AppAlert, AppText, AppButton } from '@components';
import { ForceUpdateStyles } from '@components/AppForceUpdate/ForceUpdate.style';
import { LoadingView } from '@components/Loading/Loading.view';
import { PERMISSION_MODEL } from '@constant';
import theme, { globalStyle } from '@theme';
import { deviceWidth, Font, FontSize } from '@theme/size';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';
import { useGlobalModal } from './GlobalModal.hook';

export const GlobalModal = props => {
  // const { show} = props;
  const { handleHideModal, data } = useGlobalModal(props);
  const { t } = useTranslation();

  // const text = useMemo(() => {
  //   switch (type) {
  //     case PERMISSION_MODEL.CAMERA:
  //       return {
  //         title: t('CAMERA_BLOCKED'),
  //         desc: `${t('CAMERA_PROHIBITED')} ${t('ENABLE_SETTINGS')}`,
  //       };
  //     case PERMISSION_MODEL.LOCATION:
  //       return {
  //         title: t('NO_LOCATION_ACCESS'),
  //         desc: `${t('WHY_NEED_LOCATION')}`,
  //       };
  //
  //     default:
  //       break;
  //   }
  // }, [type, t]);

  // const { title, desc } = text;
  const title = data?.title;
  const description = data?.description;

  return (
    <>
      {data?.visible && (
        <AppAlert
          onDismiss={handleHideModal}
          closeOnTouchOutside
          show={data?.visible}
          contentContainerStyle={{ maxWidth: '92%' }}
          customView={
            <View style={{ paddingHorizontal: 8 }}>
              <TouchableOpacity
                onPress={handleHideModal}
                style={{
                  position: 'absolute',
                  top: 6,
                  right: 6,
                  backgroundColor: '#c9c9c9',
                  zIndex: 1,
                  padding: 6,
                  borderRadius: 1000,
                }}>
                <CrossIcon color="white" />
              </TouchableOpacity>
              <View style={[globalStyle.directionColumm, { marginTop: 24 }]}>
                <View style={[globalStyle.center]}>
                  <WarningIcon width={45} height={45} />
                </View>
                <View style={[globalStyle.center, { paddingVertical: 16 }]}>
                  <AppText style={[Font.Bold_700, globalStyle.center]}>{title}</AppText>
                  <AppText
                    style={[
                      {
                        fontSize: FontSize.Font14,
                        paddingVertical: 8,
                        textAlign: 'center',
                        color: '#666666',
                      },
                    ]}>
                    {description}
                  </AppText>
                </View>
              </View>
              <View style={[ForceUpdateStyles.row, { marginVertical: theme.spacing.md }]}>
                <AppButton
                  // style={ForceUpdateStyles.navigationButton}
                  onPress={handleHideModal}
                  text={t('Ok')}
                />
              </View>
            </View>
          }
        />
      )}
    </>
  );
};

GlobalModal.propTypes = {};

GlobalModal.defaultProps = {};
