import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppButton, AppKAView, AppSafeAreaView, AppText } from '@components';
import { AppSuccessScreen } from '@components/AppSuccessScreen';
import { FormItemView } from '@components/Form';
import GenericHeader from '@components/GenericHeader';
import { UPLOAD_FILE } from '@constant';
import { CommonActions, useNavigation } from '@react-navigation/native';
import { goBack, popToTop } from '@routes/navigationUtils';
import { BOTTOM_TAB } from '@routes/route';
import { TopICodeView } from '@screens/main/report/iCode/form/components/TopICode.view';
import { ReportSuccessfulView } from '@screens/main/report/package/form/components/ReportSuccessful.view';
import { TopPackageView } from '@screens/main/report/package/form/components/TopPackage.view';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font, Spacing } from '@theme/size';
import { useLocation } from '@utils/hooks';
import { FormTransform } from '@utils/transform/form';
import PropTypes from 'prop-types';
import React, {useEffect, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import {StatusBar, View, Text, Platform} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useReportICodeForm } from './ReportICodeForm.hook';
import { ReportICodeFormStyle } from './ReportICodeForm.style';
import {RenderHelper} from "@utils/form/render";

export const ReportICodeFormScreen = props => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const {
    data,
    formik,
    summary,
    isSuccess,
    point,
    headerTitle,
    scrollToY,
    scrollViewRef,
    layoutInfo,
    setLayoutInfo,
    messageInfo,
    disabled,
  } = useReportICodeForm(props);
  const btnText = data?.canReview ? t('BUTTON_REVIEW') : t('REPORT');

  const refLayoutInfo = useRef(null)
  useEffect(() => {
    refLayoutInfo.current = {}
  },[])

  const renderForm = () => {
    return (
      <>
        <KeyboardAwareScrollView
          style={[
            {
              backgroundColor: palette.WHITE,
              borderTopRightRadius: theme.spacing.lg,
              borderTopLeftRadius: theme.spacing.lg,
            },
          ]}
          contentContainerStyle={{ flexGrow: 1 }}
          innerRef={ref => {
            if (ref) {
              scrollViewRef.current = ref;
            }
          }}
          showsVerticalScrollIndicator={false}
          keyboardShouldPersistTaps="handled">
          <TopICodeView data={summary} />
          <View style={[globalStyle.directionColumm, RegisterFormStyles.paddingSpace, globalStyle.flex1]}>
            {data?.attributes?.map((item, index) => {
              return (
                <FormItemView
                    key={`${item?.internalId}_${index}`}
                    style={[{ zIndex: Platform.OS === 'web' ? 'unset' : undefined }]}
                  index={index}
                  item={item}
                  formik={formik}
                  isBoldTitle={true}
                  checkCondition={true}
                  formData={data?.attributes}
                  folderUpload={UPLOAD_FILE.folderUpload.bundle}
                  onLayout={e => {
                    RenderHelper.onLayout(e, item, layoutInfo, setLayoutInfo, refLayoutInfo)
                  }}
                  scrollViewRef={scrollViewRef}
                />
              );
            })}
          </View>
          <View
            style={[
              RegisterFormStyles.paddingSpace,
              { marginVertical: theme.spacing.sm, marginBottom: Spacing.height25 },
            ]}>
            <AppButton
              // onPress={formik.handleSubmit}
              disabled={disabled}
              onPress={async () => {
                await FormTransform.submitAndScroll(formik, data?.attributes, layoutInfo, scrollToY);
              }}
              text={btnText}

            />
          </View>
        </KeyboardAwareScrollView>
      </>
    );
  };
  return (
    <>
      <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
      <AppSafeAreaView areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>
        <GenericHeader
          onBackClicked={
            isSuccess
              ? () =>
                  // navigation.dispatch(
                  //   CommonActions.reset({
                  //     index: 0,
                  //     routes: [
                  //       {
                  //         name: BOTTOM_TAB,
                  //       },
                  //     ],
                  //   }),
                  // )
                  popToTop()
              : goBack
          }
          // onBackClicked={goBack}
          // title={t('REPORT_TEST_ITEMS')}
          title={headerTitle}
          style={[{ backgroundColor: '#EDEDED' }]}
        />
        <View style={[globalStyle.directionColumm, RegisterFormStyles.containerView]}>
          {isSuccess ? (
            <AppSuccessScreen
              alert={messageInfo?.alert}
              icon={messageInfo?.icon}
              description={messageInfo?.description}
            />
          ) : (
            renderForm()
          )}
          {/*{isSuccess ? <ReportSuccessfulView point={point} /> : renderForm()}*/}
        </View>
      </AppSafeAreaView>
    </>
  );
};
