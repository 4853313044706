import {Platform} from "react-native";
import _ from 'lodash'

export const RenderHelper = {
    forceKey : value => {
        return new Date().toISOString() + value?.toString()
    },
    forceKeyForWeb: value => {
        if(Platform.OS === 'web'){
            return RenderHelper.forceKey(value)
        }
        return undefined
    },
    onLayout : (e, item, layoutInfo, setLayoutInfo, refLayoutInfo) => {
        const layout = e?.nativeEvent?.layout;
        if (!layout) {
            return null;
        }
        const newInfo = { ...layoutInfo };

        newInfo[item?.internalId] = layout.y + layout.height;
        if(_.isNull(refLayoutInfo)) {
            if(setLayoutInfo){
                setLayoutInfo(refLayoutInfo.current);
            }
            return newInfo
        }

        if(!refLayoutInfo.current){
            refLayoutInfo.current = newInfo
        }
        else{
            const oldLayout = {
                ...refLayoutInfo.current
            }
            refLayoutInfo.current = {
                ...oldLayout,
                ...newInfo
            }
        }

        if(setLayoutInfo){
            setLayoutInfo(refLayoutInfo.current);
        }

        console.log('onLayout>>>', refLayoutInfo.current)
        return refLayoutInfo.current
    }
}