import {getListQuizRequest, resetAnswerQuiz} from '@redux/quizzes/getListQuizSlice';
import { getQuizRequest } from '@redux/quizzes/getQuizSlice';
import {
  getListQuizCreator,
  getListQuizSelector,
  getListQuizError,
  getListQuizErrorMsg,
  getListQuizLoading,
} from '@redux/quizzes/selectors';
import {navigate, navigatePush} from '@routes/navigationUtils';
import {PROFILE_EDIT_SCREEN, Screens} from '@routes/route';
import { useEffect } from 'react';
import { Screen } from 'react-native-screens';
import { useDispatch, useSelector } from 'react-redux';

export const useListQuiz = navigation => {
  const dispatch = useDispatch();
  const getListQuizData = useSelector(getListQuizCreator);
  // const getListQuizWithLoading = useSelector(getListQuizLoading);
  // const getListQuizWithErrorMsg = useSelector(getListQuizErrorMsg);
  console.log('useListQuiz>>>', getListQuizData);
  const data = getListQuizData;
  const getListQuizApi = () => {
    /*
  payload_info
   */
    const payload = {};
    dispatch(getListQuizRequest(payload));
  };

  const getQuizApi = (id, isPassed = false) => {
    /*
  payload_info
   */
    const payload = { id };
    //TODO: should reset answer before go to detail
    dispatch(resetAnswerQuiz({}))
    dispatch(
      getQuizRequest(
        payload,
        rs => {
          //TODO: navigate to quiz detail
          console.log('getQuizxxxxxxxxxxxx>>>>>')
          if(isPassed){
            navigate(Screens.QUIZ_COMPLETED_SCREEN);
          }
          else{
            navigate(Screens.QUIZ_DETAIL_SCREEN, { paramsStep: 1 });
          }
          // navigate(PROFILE_EDIT_SCREEN);

          // navigatePush(Screens.QUIZ_FAILED_SCREEN);
        },
        err => {},
      ),
    );
  };

  useEffect(() => {
    getListQuizApi();
  }, []);

  const onItemPress = item => {
    // if (item.passed) {
    //   // return;
    //   navigate(Screens.QUIZ_FAILED_SCREEN);
    // }
    // else{
    //   getQuizApi(item?.id);
    // }

    getQuizApi(item?.id, item?.passed);


  };

  return { data, onItemPress };
};
