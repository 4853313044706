import { Constant } from '@constant';
import { palette } from '@theme/colors';
import { Spacing } from '@theme/size';
import { StyleSheet, Platform } from 'react-native';

export const D2DOverviewStyle = StyleSheet.create({
  areaStyle: { backgroundColor: '#EDEDED' },
  rootContainer: {
    borderTopRightRadius: Spacing.width20,
    borderTopLeftRadius: Spacing.width20,
    backgroundColor: palette.WHITE,
    flex: 1,
  },

  container: {
    paddingHorizontal: Spacing.width15,
    // flexGrow: 1,
    // flex: 1,
    // borderTopRightRadius: Spacing.width20,
    // borderTopLeftRadius: Spacing.width20,
    // backgroundColor: palette.WHITE,

    alignItems: 'center',
    paddingTop: Spacing.height15,
    paddingBottom: Spacing.height15,
  },
  itemView: {
    backgroundColor: '#F7F7F7',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: Spacing.width15,
    minHeight: Constant.MAIN_HEIGHT,
    // borderWidth: 1,
    borderColor: '#C6C6C6',
    borderRadius: 4,
    marginBottom: Platform.OS === 'web' ? '20px' : Spacing.height15,
  },
});
