import {createAction, createSlice} from '@reduxjs/toolkit';

export interface IgetReportPackageFormState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IgetReportPackageFormState = {
  loading: false,
  payload: null,
  error: null,
};

export const getReportPackageFormRequest = createAction(
    'getReportPackageForm/getReportPackageFormRequest',
    (payload, onSuccess, onFailed) => {
      console.log('prepareAction>>>', payload, onSuccess);
      return {
        payload: payload,
        meta: onSuccess,
        error: onFailed,
      };
    },
);

const getReportPackageFormSlice = createSlice({
  name: 'getReportPackageForm',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // getReportPackageFormRequest(state, action) {
    //   state.loading = true;
    //   // @ts-ignore
    //   state.payload = null;
    //   state.error = null;
    // },
    getReportPackageFormSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    getReportPackageFormFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getReportPackageFormSuccess, getReportPackageFormFailed } = getReportPackageFormSlice.actions;

export default getReportPackageFormSlice.reducer;
