import Colors from '@screens/main/report/ListReport/Colors';
import Dimens from '@screens/main/report/ListReport/Dimens';
import { StyleSheet } from 'react-native';

export const EmptyingTimeStyle = StyleSheet.create({
  historyRowContainer: {
    flexDirection: 'row',
    width: '100%',
    // paddingHorizontal: 1.5 * Dimens.Margin.Short,
    // marginBottom: 4,
  },
  startDateContainer: {
    flex: 1,
    flexDirection: 'row',
    minHeight: Dimens.ItemHeight.Normal,
    paddingHorizontal: Dimens.Margin.Normal,
    alignItems: 'center',
    backgroundColor: Colors.grayColor3,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  endDateContainer: {
    flex: 1,
    flexDirection: 'row',
    minHeight: Dimens.ItemHeight.Normal,
    paddingHorizontal: Dimens.Margin.Normal,
    alignItems: 'center',
    backgroundColor: Colors.grayColor3,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    marginLeft: 2,
  },
});
