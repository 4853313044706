import _ from "lodash";
import {FORM} from "@constant/model/RegisterForm";
import {FileTransform} from "@utils/transform/file";

export const ImageTransform = {
    getImageInfo: (getCreateTicketFormData, settings, props) => {
        let item = null;
        let s3config = null
        let uploadFileName = ''
        let folderUpload = ''
        if(!_.isEmpty(getCreateTicketFormData)){
            item = getCreateTicketFormData[0].attributes?.find(x => x.type === FORM.FORM_TYPE.camera)
            const uploadPath = FileTransform.fixUploadPath(item?.uploadPath)
            folderUpload=!_.isEmpty(uploadPath) ? uploadPath : folderUpload
            uploadFileName = FileTransform.fixUploadName(item?.uploadFileName, {...props})
            s3config = item?.s3config
        }

        s3config = s3config ?? settings?.upload?.s3config ?? "TTM"
        return {
            s3config,
            uploadFileName,
            folderUpload
        }
    }
}