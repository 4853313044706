import { QR_SCANNER_TYPE, REPORT_TYPE } from '@constant';
import { useFocusEffect, useRoute } from '@react-navigation/native';
import { getDashboardRequest, getValidateReportRequest } from '@redux/app/appSlice';
import { getUserValidationRequest } from '@redux/auth/authSlice';
import { globalLoading } from '@redux/global/selectors';
import { goBack, navigate, replace } from '@routes/navigationUtils';
import { Screens } from '@routes/route';
import { Device } from '@theme/device';
import { isJsonString } from '@utils';
import { showErrorMsg } from '@utils/api';
import { StringHelper } from '@utils/common/string';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

export const useLogic = () => {
  const dispatch = useDispatch();
  const loading = useSelector(globalLoading);
  const type = useRoute().params?.type;
  const { t } = useTranslation();
  const [reactivate, setReactivate] = useState(false);
  const [showHowTo, setShowHowTo] = useState(type === QR_SCANNER_TYPE.FROM_SIGN_UP_WITH_NAME);

  useEffect(() => {
    // console.log('useEffect>>>loading>>>', loading)
    //FIXME: why setReactivate from global loading?
    if (!loading) setTimeout(() => setReactivate(true), 1000);
    else setReactivate(false);
  }, [loading]);

  useFocusEffect(
      useCallback(() => {
        //TODO: reactive after goback
        console.log('useFocusEffect>>>');
        setReactivate(true);
      }, []),
  );

  const handleShowMsg = typeTemp => {
    switch (typeTemp) {
      case QR_SCANNER_TYPE.FROM_SIGN_UP_WITH_NAME:
        showErrorMsg(t('INVALID_QR_CODE'));
        break;
      case QR_SCANNER_TYPE.REPORT_TEST_ITEMS:
      case QR_SCANNER_TYPE.POSTING_PACKAGE:
      case QR_SCANNER_TYPE.REPORT_BUNDLE_DISPATCH:
        showErrorMsg(t('INVALID_QR_CODE_2'));
        break;
      case QR_SCANNER_TYPE.FROM_BOTTOM_TAB:
        showErrorMsg(t('INVALID_QR_CODE_3'));
        break;

      default:
        break;
    }
  };

  const handleReadQR = async e => {
    if ((Device.deviceType !== 'web' && !reactivate) || !isValidArea(e)) return;

    let data;
    if (!isJsonString(e?.data)) {
      handleShowMsg(type);
      setReactivate(false);
      setTimeout(() => setReactivate(true), 1500);
      return;
    }
    data = JSON.parse(e?.data);

    switch (type) {
      case QR_SCANNER_TYPE.FROM_SIGN_UP_WITH_NAME:
        handleScanFromSignUp(data);
        break;
      case QR_SCANNER_TYPE.REPORT_TEST_ITEMS:
      case QR_SCANNER_TYPE.POSTING_PACKAGE:
      case QR_SCANNER_TYPE.REPORT_BUNDLE_DISPATCH:
        handleScanFromDigitScreen(data);
        break;
      case QR_SCANNER_TYPE.FROM_BOTTOM_TAB:
        handleScanFromBottomTab(data);
        break;

      default:
        break;
    }
  };

  const handleScanFromSignUp = data => {
    if (data?.type === 'registration')
      dispatch(
        getUserValidationRequest({
          firstName: data.firstname,
          lastName: data.lastname,
          iCode: StringHelper.removeSpace(data.icode),
        }),
      );
    else showErrorMsg(t('INVALID_QR_CODE'));
  };

  const handleNavigateReportForm = (rs, reportType, id) => {
    id = StringHelper.removeSpace(id);
    switch (reportType) {
      case REPORT_TYPE.icodes:
        replace(Screens.REPORT_ICODE_FORM_SCREEN, { data: { id: id, payload: rs, reportType: reportType } });
        break;
      case REPORT_TYPE.bundles:
        replace(Screens.REPORT_BUNDLE_FORM_SCREEN, { data: { id: id, payload: rs, reportType: reportType } });

        break;
      case REPORT_TYPE.packages:
        replace(Screens.REPORT_PACKAGE_FORM_SCREEN, { data: { id: id, payload: rs, reportType: reportType } });

        break;
    }
  };

  const handleScanFromBottomTab = async data => {
    console.log('handleScanFromBottomTab>>>', data);
    if (data?.type) {
      switch (data?.type) {
        case 'reportIcode':
          dispatch(
            getValidateReportRequest(
              {
                type: REPORT_TYPE.icodes,
                id: StringHelper.removeSpace(data?.icode),
              },
              rs => {
                console.log('getValidateReport>>>response', rs);
                handleNavigateReportForm(rs, REPORT_TYPE.icodes, data?.icode);
              },
              err => {},
            ),
          );
          break;
        case 'reportPackage':
          dispatch(
            getValidateReportRequest(
              {
                type: REPORT_TYPE.packages,
                id: StringHelper.removeSpace(data?.packageID),
              },
              rs => {
                console.log('getValidateReport>>>response', rs);
                handleNavigateReportForm(rs, REPORT_TYPE.packages, data?.packageID);
              },
              err => {},
            ),
          );
          break;
        case 'postingBundle':
          dispatch(
            getValidateReportRequest(
              {
                type: REPORT_TYPE.bundles,
                id: StringHelper.removeSpace(data?.bundleID),
              },
              rs => {
                console.log('getValidateReport>>>response', rs);
                handleNavigateReportForm(rs, REPORT_TYPE.bundles, data?.bundleID);
              },
              err => {},
            ),
          );
          break;
        default:
          showErrorMsg(t('INVALID_QR_CODE'));
          break;
      }
    } else showErrorMsg(t('INVALID_QR_CODE'));
  };

  const handleScanFromDigitScreen = data => {
    if (!isValidQRCodeFromDigitScreen(data)) {
      showErrorMsg(t('INVALID_QR_CODE'));
      return;
    }
    console.log('handleScanFromDigitScreen>>>');
    const reportType = handleGetValueFromParam(REPORT_TYPE.icodes, REPORT_TYPE.packages, REPORT_TYPE.bundles);
    const idName = handleGetValueFromParam('icode', 'packageID', 'bundleID');
    dispatch(
      getValidateReportRequest(
        {
          type: reportType,
          id: data?.[idName],
        },
        rs => {
          console.log('getValidateReport>>>response', rs);
          switch (reportType) {
            case REPORT_TYPE.icodes:
              replace(Screens.REPORT_ICODE_FORM_SCREEN, {
                data: { id: data?.[idName], payload: rs, reportType: reportType },
              });
              break;
            case REPORT_TYPE.bundles:
              replace(Screens.REPORT_BUNDLE_FORM_SCREEN, {
                data: { id: data?.[idName], payload: rs, reportType: reportType },
              });
              break;
            case REPORT_TYPE.packages:
              replace(Screens.REPORT_PACKAGE_FORM_SCREEN, {
                data: { id: data?.[idName], payload: rs, reportType: reportType },
              });
              break;
          }
        },
        err => {},
      ),
    );
  };

  const isValidQRCodeFromDigitScreen = data => {
    if (!data?.type) return false;

    switch (type) {
      case QR_SCANNER_TYPE.REPORT_TEST_ITEMS:
        return data?.type === 'reportIcode';
      case QR_SCANNER_TYPE.POSTING_PACKAGE:
        return data?.type === 'reportPackage';
      case QR_SCANNER_TYPE.REPORT_BUNDLE_DISPATCH:
        return data?.type === 'postingBundle';
      default:
        return false;
    }
  };

  const handleGetValueFromParam = useCallback(
    (value1, value2, value3) => {
      switch (type) {
        case QR_SCANNER_TYPE.REPORT_TEST_ITEMS:
          return value1;

        case QR_SCANNER_TYPE.POSTING_PACKAGE:
          return value2;

        case QR_SCANNER_TYPE.REPORT_BUNDLE_DISPATCH:
          return value3;

        default:
          return null;
      }
    },
    [type],
  );

  const isValidArea = e => {
    if(Device.deviceType === 'web'){
      return true;
    }
    const currentWidth = e.bounds.width;
    if (Device.isAndroid) {
      if (
        currentWidth <= 1920 &&
        Number(e.bounds.origin[0].x) <= 1368 &&
        Number(e.bounds.origin[0].x) >= 591 &&
        Number(e.bounds.origin[0].y) <= 1113 &&
        Number(e.bounds.origin[0].y) >= 508 &&
        Number(e.bounds.origin[1].x) <= 1065 &&
        Number(e.bounds.origin[1].x) >= 550 &&
        Number(e.bounds.origin[1].y) <= 1015 &&
        Number(e.bounds.origin[1].y) >= 441 &&
        Number(e.bounds.origin[2].x) <= 1065 &&
        Number(e.bounds.origin[2].x) >= 565 &&
        Number(e.bounds.origin[2].y) <= 1008 &&
        Number(e.bounds.origin[2].y) >= 440
      )
        return true;
      if (
        currentWidth > 1920 &&
        Number(e.bounds.origin[0].x) <= 1517 &&
        Number(e.bounds.origin[0].x) >= 591 &&
        Number(e.bounds.origin[0].y) <= 1513 &&
        Number(e.bounds.origin[0].y) >= 508 &&
        Number(e.bounds.origin[1].x) <= 1399 &&
        Number(e.bounds.origin[1].x) >= 550 &&
        Number(e.bounds.origin[1].y) <= 1383 &&
        Number(e.bounds.origin[1].y) >= 441 &&
        Number(e.bounds.origin[2].x) <= 1400 &&
        Number(e.bounds.origin[2].x) >= 565 &&
        Number(e.bounds.origin[2].y) <= 1386 &&
        Number(e.bounds.origin[2].y) >= 440
      )
        return true;
      else return false;
    }
    if (Device.isIos) {
      if (
        Device.isLargeDevice &&
        Number(e.bounds.origin.x) <= 256.076477 &&
        Number(e.bounds.origin.x) >= 76.869278 &&
        Number(e.bounds.origin.y) <= 446.045296 &&
        Number(e.bounds.origin.y) >= 262.360744 &&
        Number(e.bounds.size.width) <= 246.665 &&
        Number(e.bounds.size.width) >= 50 &&
        Number(e.bounds.size.height) <= 243.664 &&
        Number(e.bounds.size.height) >= 50
      )
        return true;

      if (
        !Device.isLargeDevice &&
        Number(e.bounds.origin.x) <= 247.921082 &&
        Number(e.bounds.origin.x) >= 68.281028 &&
        Number(e.bounds.origin.y) <= 430.143101 &&
        Number(e.bounds.origin.y) >= 239.037729 &&
        Number(e.bounds.size.width) <= 240 &&
        Number(e.bounds.size.width) >= 50 &&
        Number(e.bounds.size.height) <= 240 &&
        Number(e.bounds.size.height) >= 50
      )
        return true;
      else return false;
    }
  };

  const handleClosePress = () => {
    goBack();
  };

  const onHowToPress = () => {
    //TODO: stop scan function before navigate to another screen
    setReactivate(false);
    navigate(Screens.HOW_TO_FORM);
  };

  return { handleReadQR, handleClosePress, reactivate, onHowToPress, showHowTo };
};
