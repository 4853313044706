import { createSlice } from '@reduxjs/toolkit';

export interface IgetCDSDetailState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IgetCDSDetailState = {
  loading: false,
  payload: null,
  error: null,
};

const getCDSDetailSlice = createSlice({
  name: 'getCDSDetail',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getCDSDetailRequest(state, action) {
      state.loading = true;
      // @ts-ignore
      state.payload = null;
      state.error = null;
    },
    getCDSDetailSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    getCDSDetailFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getCDSDetailRequest, getCDSDetailSuccess, getCDSDetailFailed } = getCDSDetailSlice.actions;

export default getCDSDetailSlice.reducer;
