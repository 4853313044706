import { getDashboardRequest } from '@redux/app/appSlice';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export const useCDSSuccessful = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const point = props.point;
  const desc1 = t('THANK_TO_REPORT');
  const desc2 = ' ' + point > 1 ? t('SPECTOS_POINTS') : t('SPECTOS_POINT');
  const desc3 = t('TO_YOUR_ACCOUNT');
  const desc4 = t('REPORT_SUCCESS');
  const desc5 = t('REPORT_SUCCESS_DESC');
  const note = t('YOUR_UPDATED_SENT');
  useEffect(() => {
    // dispatch(getDashboardRequest());
  }, [dispatch]);
  useEffect(() => {}, []);
  return { desc1, desc2, desc3, point, note, desc4, desc5 };
};
