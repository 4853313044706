import type { RootState } from '@redux/reducers';
import { createSelector } from '@reduxjs/toolkit';
import { getErrorMessage } from '@utils/api';

export const getOverviewCDSSelector = (state: RootState) => state.getOverviewCDS;
export const getOverviewCDSCreator = createSelector(getOverviewCDSSelector, state => state.payload);
export const getOverviewCDSLoading = createSelector(getOverviewCDSSelector, state => state.loading);
export const getOverviewCDSError = createSelector(getOverviewCDSSelector, state => state.error);
export const getOverviewCDSErrorMsg = createSelector(getOverviewCDSError, state => getErrorMessage(state));

export const getOverviewD2DSelector = (state: RootState) => state.getOverviewD2D;
export const getOverviewD2DCreator = createSelector(getOverviewD2DSelector, state => state.payload);
export const getOverviewD2DLoading = createSelector(getOverviewD2DSelector, state => state.loading);
export const getOverviewD2DError = createSelector(getOverviewD2DSelector, state => state.error);
export const getOverviewD2DErrorMsg = createSelector(getOverviewD2DError, state => getErrorMessage(state));

export const getD2DItemsSelector = (state: RootState) => state.getD2DItems;
export const getD2DItemsCreator = createSelector(getD2DItemsSelector, state => state.payload);
export const getD2DItemsLoading = createSelector(getD2DItemsSelector, state => state.loading);
export const getD2DItemsError = createSelector(getD2DItemsSelector, state => state.error);
export const getD2DItemsErrorMsg = createSelector(getD2DItemsError, state => getErrorMessage(state));

export const saveFeedbackCDSSelector = (state: RootState) => state.saveFeedbackCDS;
export const saveFeedbackCDSCreator = createSelector(saveFeedbackCDSSelector, state => state.payload);
export const saveFeedbackCDSLoading = createSelector(saveFeedbackCDSSelector, state => state.loading);
export const saveFeedbackCDSError = createSelector(saveFeedbackCDSSelector, state => state.error);
export const saveFeedbackCDSErrorMsg = createSelector(saveFeedbackCDSError, state => getErrorMessage(state));

export const saveFeedbackD2DSelector = (state: RootState) => state.saveFeedbackD2D;
export const saveFeedbackD2DCreator = createSelector(saveFeedbackD2DSelector, state => state.payload);
export const saveFeedbackD2DLoading = createSelector(saveFeedbackD2DSelector, state => state.loading);
export const saveFeedbackD2DError = createSelector(saveFeedbackD2DSelector, state => state.error);
export const saveFeedbackD2DErrorMsg = createSelector(saveFeedbackD2DError, state => getErrorMessage(state));

export const saveFeedbackD2DForDailySelector = (state: RootState) => state.saveFeedbackD2DForDaily;
export const saveFeedbackD2DForDailyCreator = createSelector(saveFeedbackD2DForDailySelector, state => state.payload);
export const saveFeedbackD2DForDailyLoading = createSelector(saveFeedbackD2DForDailySelector, state => state.loading);
export const saveFeedbackD2DForDailyError = createSelector(saveFeedbackD2DForDailySelector, state => state.error);
export const saveFeedbackD2DForDailyErrorMsg = createSelector(saveFeedbackD2DForDailyError, state => getErrorMessage(state));

export const getCDSDetailSelector = (state: RootState) => state.getCDSDetail;
export const getCDSDetailCreator = createSelector(getCDSDetailSelector, state => state.payload);
export const getCDSDetailLoading = createSelector(getCDSDetailSelector, state => state.loading);
export const getCDSDetailError = createSelector(getCDSDetailSelector, state => state.error);
export const getCDSDetailErrorMsg = createSelector(getCDSDetailError, state => getErrorMessage(state));

export const getListUnsureDateSelector = (state: RootState) => state.getListUnsureDate;
export const getListUnsureDateCreator = createSelector(getListUnsureDateSelector, state => state.payload);
export const getListUnsureDateLoading = createSelector(getListUnsureDateSelector, state => state.loading);
export const getListUnsureDateError = createSelector(getListUnsureDateSelector, state => state.error);
export const getListUnsureDateErrorMsg = createSelector(getListUnsureDateError, state => getErrorMessage(state));

