import { FORM_ELEMENT_NAME } from '@constant/model/RegisterForm';
import { getListPostingLocationRequest } from '@redux/bundle/getListPostingLocationSlice';
import { getListPostingLocationCreator, getListPostingLocationStandard } from '@redux/bundle/selectors';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useNearestAddress = props => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [notInList, setNotInList] = useState(false);
  // const getListPostingLocationData = useSelector(getListPostingLocationStandard);
  console.log('useNearestAddress>>>', props)
  const handleOpenScreen = () => {
    console.log('handleOpenScreen>>address>>>', props);
    // setVisible(true);
    onSetVisible(true);
  };

  const onSetVisible = value => {
    setVisible(value);
    props.formik?.setFieldValue(`visible_${FORM_ELEMENT_NAME.nearest_address}`, value);

    if (props.onVisible) {
      props.onVisible(value);
    }
  };

  const handleBackdropPress = () => {
    // setVisible(false);
    onSetVisible(false);
    if (props.onBackDrop) {
      props.onBackDrop();
    }
  };

  const onSelected = (text, item) => {
    console.log('useNearestAddress>>>onSelected>>>', text, item);
    if (props.onSelect) {
      props.onSelect(text, item);
    }
    // setVisible(false);
    onSetVisible(false);
    setNotInList(false);
  };

  const onClickHref = href => {
    if (props.onClickHref) {
      props.onClickHref(href);
    }
    // setVisible(false);
    onSetVisible(false);
    setNotInList(true);
  };

  useEffect(() => {}, [dispatch]);
  useEffect(() => {
    setVisible(props.visible);
    if (props.visible) {
      //TODO: get list address
    }
  }, [props.visible]);

  return {
    visible,
    setVisible,
    notInList,
    setNotInList,
    handleOpenScreen,
    handleBackdropPress,
    onSelected,
    onClickHref,
  };
};
