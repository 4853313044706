import {
  hideCameraPermissionModal,
  hideLocationPermissionModal,
  hideNotificationPermissionModal
} from '@redux/common/commonSlice';
import {
  cameraPermissionModalVisible as cameraPermissionModalVisibleSelector,
  locationPermissionModalVisible as locationPermissionModalVisibleSelector,
  notificationPermissionModalVisible as notificationPermissionModalVisibleSelector,

} from '@redux/common/selectors';
import { openSettings } from 'react-native-permissions';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {PERMISSION_MODEL} from "@constant";

export const useCameraPermissionModal = props => {
  const dispatch = useDispatch();
  const cameraPermissionModalVisible = useSelector(cameraPermissionModalVisibleSelector);
  const locationPermissionModalVisible = useSelector(locationPermissionModalVisibleSelector);
  const notificationPermissionModal = useSelector(notificationPermissionModalVisibleSelector);

  const handleOpenSettings = () => {
    openSettings().catch(() => {
      console.log('Cannot open settings');
    });

    setTimeout(() => {
      cameraPermissionModalVisible && dispatch(hideCameraPermissionModal());
      locationPermissionModalVisible && dispatch(hideLocationPermissionModal());
      notificationPermissionModal?.visible && dispatch(hideNotificationPermissionModal());
    }, 0);
  };
  const handleHideModal = () => {
    cameraPermissionModalVisible && dispatch(hideCameraPermissionModal());
    locationPermissionModalVisible && dispatch(hideLocationPermissionModal());
    notificationPermissionModal?.visible && dispatch(hideNotificationPermissionModal());
  };
  return { handleOpenSettings, handleHideModal, notificationPermissionModal };
};
