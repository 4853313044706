import {
  hideGlobalSuccessModal,
} from '@redux/common/commonSlice';
import {
  globalModalSelector, globalSuccessModalSelector
} from '@redux/common/selectors';
import { openSettings } from 'react-native-permissions';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

export const useGlobalSuccessModal = props => {
  const dispatch = useDispatch();
  const globalModalData = useSelector(globalSuccessModalSelector);
  const data = globalModalData
  // const locationPermissionModalVisible = useSelector(locationPermissionModalVisibleSelector);
  const handleHideModal = () => {
    // cameraPermissionModalVisible && dispatch(hideCameraPermissionModal());
    // locationPermissionModalVisible && dispatch(hideLocationPermissionModal());
    dispatch(hideGlobalSuccessModal())
  };

  const onAction = () => {
    console.log('globalModalData>>>', globalModalData)
    if(data?.action){
      data.action()
    }
  }

  const onClose = () =>{
    onAction()
    handleHideModal()
  }

  console.log('useGlobalModal>>>', data)
  return { handleHideModal, data , onClose};
};
