import { palette } from '@theme/colors';
import { Font, FontSize } from '@theme/size';
import React from 'react';
import { Text } from 'react-native';

export const AppText = React.memo(props => {
  const { children, style, onPress, numberOfLines, allowFontScaling = true, ...restProps } = props;

  return (
    <Text
      allowFontScaling={allowFontScaling}
      style={[
        {
          ...Font.Bold_400,
          fontSize: FontSize.Font15,
          color: palette.MAIN_BLACK,
        },
        style,
      ]}
      onPress={onPress}
      numberOfLines={numberOfLines}
      {...restProps}>
      {children}
    </Text>
  );
});

export default AppText;
