import {createAction, createSlice} from '@reduxjs/toolkit';

export interface IsubmitRFIDState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IsubmitRFIDState = {
  loading: false,
  payload: null,
  error: null,
};

export const submitRFIDRequest = createAction('submitRFID/submitRFIDRequest', (payload, onSuccess, onFailed) => {
  console.log('prepareAction>>>', payload, onSuccess);
  return {
    payload: payload,
    meta: onSuccess,
    error: onFailed,
  };
});

const submitRFIDSlice = createSlice({
  name: 'submitRFID',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // submitRFIDRequest(state, action) {
    //   state.loading = true;
    //   // @ts-ignore
    //   state.payload = null;
    //   state.error = null;
    // },
    submitRFIDSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    submitRFIDFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { submitRFIDSuccess, submitRFIDFailed } = submitRFIDSlice.actions;

export default submitRFIDSlice.reducer;
