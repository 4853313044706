import {useDispatch, useSelector} from 'react-redux'
import {useEffect, useRef, useState} from 'react'
import {useTranslation} from "react-i18next";
import {useRoute} from "@react-navigation/native";
import {getCDSFormCreator} from "@redux/form/selectors";
import {getCDSFormRequest} from "@redux/form/getCDSFormSlice";
import _ from "lodash";
import {FORM} from "@constant/model/RegisterForm";
import {CDSTransform} from "@utils/transform/cds";
import {FormTransform, getContentTitle, handleShowError, mappingStep} from "@utils/transform/form";
import {ViewUtil} from "@utils/common/view";
import {useFormik} from "formik";
import {checkFormValidateExpression, getInitialFormValue, getYupStructure} from "@utils/form";
import {lazy} from "yup";
import * as Yup from "yup";
import {saveFeedbackCDSRequest} from "@redux/survey/saveFeedbackCDSSlice";
import {getSurveyRequest} from "@redux/app/appSlice";
import {goBack, goBackWidthScreen, navigate, navigatePush, popToTop} from "@routes/navigationUtils";
import {Screens} from "@routes/route";
import {min} from "lodash/math";
import {getCDSDetailCreator} from "@redux/survey/selectors";
import {getCDSDetailRequest} from "@redux/survey/getCDSDetailSlice";
import {SURVEY_STATUS} from "@constant";

export const useDetail = props => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [data, setData] = useState(null);
    const params = useRoute()?.params;
    const paramsStep = params?.step ?? 1;
    const paramsData = params?.data;
    const feedback_id = params?.feedback_id;
    // const extraData = paramsData
    const scrollViewRef = useRef(null);

    const getCDSFormData = useSelector(getCDSFormCreator)?.data;
    const getCDSDetailData = useSelector(getCDSDetailCreator)?.data

    const count = getCDSFormData?.length ?? 0;
    const [headerTitle, setHeaderTitle] = useState('');
    const [surveyId, setSurveyId] = useState('');
    const [layoutInfo, setLayoutInfo] = useState({});
    const [isSuccess, setIsSuccess] = useState(false);
    const [messageInfo, setMessageInfo] = useState({});
    const lastStep = count === paramsStep

    // const getCDSFormWithLoading = useSelector(getCDSFormLoading);
    // const getCDSFormWithErrorMsg = useSelector(getCDSFormErrorMsg);

    const getCDSFormApi = () => {
        const payload = {
            ...paramsData,
            week: paramsData?.week,
            editId: paramsData?.editId,
        };
        if (paramsData?.date) {
            payload.appropriate_date = paramsData?.date;
            payload.s_id = paramsData?.s_id
        }
        dispatch(getCDSFormRequest(payload));
    };

    const getCDSDetailApi = () => {
        const payload = {
            id: paramsData?.editId
        }
        dispatch(getCDSDetailRequest(payload))
    }


    const checkMisdeliveredItemForm = (formData, formValues, setErrors) => {
        if (_.isEmpty(formData)) {
            return true;
        }

        const MisdeliveredItemForm = formData?.attributes?.find(x => x.type === FORM.FORM_TYPE.misdelivered_item_form);
        if (_.isEmpty(MisdeliveredItemForm)) {
            return true;
        }

        const listExp = CDSTransform.getListExpressionForm(formValues);
        console.log('checkSubForm>>>', listExp);

        return !FormTransform.checkPositiveCustomCondition(
            FORM.FORM_ELEMENT_NAME.misdelivered_item_form,
            formValues,
            listExp,
            setErrors,
        );
    };

    const scrollToEnd = (timeout = 200) => {
        // return;
        ViewUtil.scrollToEnd(scrollViewRef.current, timeout, false);
    };

    const scrollToY = y => {
        // scrollViewRef.current.scroll
        scrollViewRef.current?.scrollTo({
            x: 0,
            y: y,
            animated: true,
        });
    };

    const checkTotalValidate = () => {
        // const { formik, formData } = props;
        // console.log('checkValidate>>', formik, formData);
        // if (_.isEmpty(formik) || _.isEmpty(formData)) {
        //   return true;
        // }
        const { setErrors, values, setFieldError, setFieldTouched } = formik;
        const MisdeliveredItemForm = data?.attributes?.find(x => x.type === FORM.FORM_TYPE.misdelivered_item_form);
        if (_.isEmpty(MisdeliveredItemForm)) {
            return true;
        }

        const listExp = CDSTransform.getEqualExpressionForm(values);
        console.log('getEqualExpressionForm>>>', listExp);
        return !FormTransform.checkPositiveCustomCondition(
            FORM.FORM_ELEMENT_NAME.misdelivered_item_form,
            values,
            listExp,
            setErrors,
            // setFieldError,
            // setFieldTouched,
        );
    };
    const onCheckValid = () => {
        const isValid = checkTotalValidate();
        if (!isValid) {
            scrollToEnd();
            console.log('onCheckValid>>>', formik);
            return false;
        }
        return true;
    };

    const formik = useFormik({
        initialValues: {
            ...getInitialFormValue(data, {
                ...getCDSDetailData
            }),
        },
        enableReinitialize: true,
        dirty: true,
        // validationSchema: Yup.object().shape(getYupStructure(rawData, t)),

        validationSchema: () =>
            lazy(vl => {
                // const values = _.cloneDeep(formik.values);
                const values = _.cloneDeep(vl);

                console.log('lazy>>>values>>>', vl, values, formik.values);
                const ys = getYupStructure(data, t, 'attributes', values);
                console.log('getYupStructure>>>', ys, formik.values);
                return Yup.object().shape(ys);
            }),
        // initialErrors: true,
        onSubmit: async (values, { setErrors, resetForm }) => {
            console.log('onSubmit>>>', values, formik.errors, data);
            const rs = checkFormValidateExpression(data?.attributes, values, setErrors);
            console.log('onSubmit>>>step1>>>', values, formik, data);

            if (!_.isEmpty(rs)) {
                return;
            }
            //TODO: check subForm

            const isValid = checkMisdeliveredItemForm(data, values, setErrors);

            console.log('onSubmit>>>step2>>>', values, formik.errors, data);

            if (!isValid) {
                //TODO: should scrollToEnd
                scrollToEnd();
                return;
            }
            // onNext();
            let payload = await CDSTransform.getCDSSubmitRequest(
                values,
                paramsData,
                data?.attributes,
                dispatch,
                feedback_id ?? surveyId,
                paramsStep,
                lastStep,
            );

            if(lastStep){
                payload.feedback.editId = paramsData.editId
            }

            console.log('getCDSSubmitRequest>>>', payload);
            // if (count === paramsStep) {
            //   return;
            // }
            saveFeedbackCDSApi(payload, setErrors);
            //TODO: validate here
            // updateUserApi(values, isLastStep);
            // onSavePress();
        },
    });

    const saveFeedbackCDSApi = (values, setErrors) => {
        //TODO: transform format data
        const payload = {
            ...values,
        };
        dispatch(
            saveFeedbackCDSRequest(
                payload,
                rs => {
                    if (lastStep) {
                        //TODO: refresh overview cds
                        dispatch(getSurveyRequest());
                        // popToTop();
                        // setIsSuccess(true);
                        // setMessageInfo(rs.message)
                        navigate(Screens.SUCCESSFUL_SCREEN, {
                            data: rs?.message,
                            header: {
                                title: headerTitle,
                                backClick: () => {
                                    // goBack()
                                    // goBack()
                                    if(paramsData.key === SURVEY_STATUS.Unsure_date){
                                        goBackWidthScreen(Screens.UNSURE_DATE_LIST_SCREEN)
                                    }
                                    else{
                                        popToTop();
                                    }
                                },
                            },
                        });
                    } else {
                        const feedbackId = rs?.data?.feedback_id;
                        setSurveyId(feedbackId);
                        navigatePush(Screens.CDS_DETAIL_SCREEN, {
                            step: paramsStep + 1,
                            data: paramsData,
                            feedback_id: feedbackId,
                        });
                    }
                },
                err => {
                    console.log('err>>>', err);
                    // ResponseT
                    // const error = err?.message?.error
                    const error = err?.message?.error;
                    handleShowError(err?.message, setErrors, err);
                    if (_.isEmpty(error)) {
                        return;
                    }
                    setTimeout(() => {
                        const rs = [];
                        for (const x1 in error) {
                            rs.push(layoutInfo[x1]);
                        }

                        const maxY = min(rs);
                        if (maxY) {
                            scrollToY(maxY);
                        }
                    }, 300);
                },
            ),
        );
    };

    // const onNext = () => {
    //   if (count === paramsStep) {
    //     popToTop();
    //   } else {
    //     navigatePush(Screens.CDS_FORM_SCREEN, { step: paramsStep + 1 });
    //   }
    // };
    useEffect(() => {
        if (paramsStep <= 1) {
            getCDSDetailApi()
            getCDSFormApi();
        }
    }, []);

    useEffect(() => {
        const x = _.cloneDeep(getCDSFormData);
        const rd = mappingStep(!_.isEmpty(x) ? x[paramsStep - 1] : null);
        setData(rd);
        console.log('getCDSFormData>>>', rd, getCDSDetailData);
        setHeaderTitle(getContentTitle(rd?.title));
    }, [getCDSFormData, paramsStep]);
    return {
        data,
        formik,
        headerTitle,
        scrollViewRef,
        scrollToEnd,
        onCheckValid,
        paramsData,
        layoutInfo,
        setLayoutInfo,
        scrollToY,
        isSuccess,
        messageInfo,
        lastStep
    };
}
