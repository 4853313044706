import {ChevronPickerIcon, LogoIcon, MessageCheckIcon} from '@assets';
import { AppKAView, AppModalV2, AppSafeAreaView, AppText } from '@components';
import { navigate } from '@routes/navigationUtils';
import { MENU_WEB_VIEW_SCREEN } from '@routes/route';
import { AddressContainerModal } from '@screens/main/SearchAddress/components/AddressContainerModal';
import { AppAddressStyle } from '@screens/main/SearchAddress/components/AppAddress.style';
import { AppSearch } from '@screens/main/SearchAddress/components/AppAddress.view';
import { AppPostcodeStyle } from '@screens/main/SearchPostcode/components/AppPostcode.style';
import { BundleAddressContainerModalView } from '@screens/main/report/bundle/form/components/BundleAddressContainerModal.view';
import { AppHtmlRadioStyle as styles } from '@screens/register/RegisterForm/components/AppHtmlRadio.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { Font } from '@theme/size';
import { getContentTitle } from '@utils/transform/form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, TouchableOpacity, Platform } from 'react-native';
import RenderHtml from 'react-native-render-html';
import { URL } from 'react-native-url-polyfill';
import { useDispatch } from 'react-redux';
import { useBundleAddress } from './BundleAddress.hook';
import { BundleAddressStyle } from './BundleAddress.style';

export const AppSearchAddress = props => {
  const { title, disabled, error, handleOpenScreen, isRequired, styleTitle, value, style, isBoldTitle, hint, onLayout } = props;
  console.log('AppSearch>>>', value);
  const { t } = useTranslation();
  return (
    <>
      <View style={[AppAddressStyle.container, style]} onLayout={e => {
        if (onLayout) {
          onLayout(e);
        }
      }}>
        {!!title && (
          <AppText style={[AppAddressStyle.textTitle, styleTitle, isBoldTitle ? { fontWeight: '700' } : {}]}>
            {isRequired && <AppText style={AppAddressStyle.isRequired}>* </AppText>}
            {title}
          </AppText>
        )}
        <TouchableOpacity
          disabled={disabled}
          onPress={handleOpenScreen}
          // activeOpacity={0.7}
          style={[AppAddressStyle.viewSelect, { opacity: disabled ? 0.5 : 1 }]}>
          {/* {mode === 'date' ? <CalendarIcon /> : <ClockIcon />} */}
          <AppText
            style={[AppAddressStyle.textSelect, value || props.notInList ? AppAddressStyle.textAfterSelect : {}]}>
            {value
              ? value
              : props.notInList
              ? `Not in the list`
              : props.placeHolder
              ? props.placeHolder
              : t('SELECT_PLACE_HOLDER_DEFAULT')}
          </AppText>
          <ChevronPickerIcon />
        </TouchableOpacity>
        {!!hint && <AppText style={AppAddressStyle.hint}>{hint}</AppText>}
        {!!error && <AppText style={AppAddressStyle.error}>{error}</AppText>}
      </View>
    </>
  );
};

AppSearchAddress.propTypes = {
  value: PropTypes.any,
  error: PropTypes.any,
  style: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.string,
  isRequired: PropTypes.bool,
  styleTitle: PropTypes.any,
  disabled: PropTypes.bool,
  handleOpenScreen: PropTypes.func,
  isBoldTitle: PropTypes.bool,
  hint: PropTypes.string,
  notInList: PropTypes.bool,
  placeHolder: PropTypes.string,
  onLayout: PropTypes.func,
};

export const BundleAddressView = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {} = useBundleAddress(props);
  const { title, disabled, error, isRequired, styleTitle, value, style, isBoldTitle } = props;
  const header = t('ADDRESS_HEADER');
  // const visible = true
  const [visible, setVisible] = useState(false);
  const [notInList, setNotInList] = useState(false);
  const onSelected = (text, item) => {
    if (props.onSelect) {
      props.onSelect(text, item);
    }
    setVisible(false);
    setNotInList(false);
  };

  const onClickHref = href => {
    if (props.onClickHref) {
      props.onClickHref(href);
    }
    setVisible(false);
    setNotInList(true);
  };
  const handleOpenScreen = () => {
    console.log('handleOpenScreen>>address>>>', props);
    setVisible(true);

    // navigatePush(Screens.SEARCH_ADDRESS_SCREEN, {
    //   onSelected: onSelected,
    //   postCode: props.postCode,
    //   searchText: value,
    // });
  };

  const handleBackdropPress = () => {
    setVisible(false);
  };
  return (
    <>
      <AppSearchAddress
        handleOpenScreen={handleOpenScreen}
        value={value}
        style={style}
        styleTitle={styleTitle}
        disabled={disabled}
        title={title}
        error={error}
        isRequired={isRequired}
        isBoldTitle={isBoldTitle}
        notInList={notInList}
        placeHolder={props.placeHolder}
        // description={props.description}
        onLayout={props.onLayout}
      />
      <AppModalV2 visible={visible} onBackdropPress={handleBackdropPress}>
        <View
          style={[
            globalStyle.directionColumm,
            {
              minHeight: (Device.height * 1) / 2,
              maxHeight: (Device.height * 2) / 3,
              width: Platform.OS === 'web' ? 810 : Device.width * 0.9,
              marginVertical: Platform.OS === 'web' ? 'auto' : undefined,
              // backgroundColor: 'yellow',
              // borderRadius:8,
            },
          ]}>
          {!!header && (
            <View
              style={AppPostcodeStyle.header}
              onLayout={e => {
                console.log(e.nativeEvent.layout.height);
              }}>
              <AppText style={AppPostcodeStyle.headerTxt}>{header}</AppText>
            </View>
          )}
          <BundleAddressContainerModalView
            postCode={props.postCode}
            searchText={value}
            onSelect={onSelected}
            dispatch={dispatch}
            header={header}
            description={props.descriptionPopup}
            onClickHref={onClickHref}
            dispatchType={props.dispatchType}
            location={props.location}
            data={props.data}
          />
          {/*</View>*/}
        </View>
      </AppModalV2>
    </>
  );
};

BundleAddressView.propTypes = {
  value: PropTypes.any,
  error: PropTypes.any,
  style: PropTypes.any,
  title: PropTypes.string,
  isRequired: PropTypes.bool,
  onSelect: PropTypes.func,
  onClickHref: PropTypes.func,
  header: PropTypes.any,
  styleTitle: PropTypes.any,
  disabled: PropTypes.bool,
  postCode: PropTypes.string,
  isBoldTitle: PropTypes.bool,
  descriptionPopup: PropTypes.string,
  dispatchType: PropTypes.string,
  location: PropTypes.object,
  placeHolder: PropTypes.string,
  data: PropTypes.array,
};

BundleAddressView.defaultProps = {
  placeHolder: '',
  data: [],
};
