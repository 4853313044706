import { PERMISSION_MODEL, PERMISSION_TYPE } from '@constant';
import {
  showCameraPermissionModal,
  showLocationPermissionModal,
  showNotificationPermissionModal,
} from '@redux/common/commonSlice';
import { store } from '@redux/store';
import { Device } from '@theme/device';
import { NotificationPermissionHelper } from '@utils/common/permission/notification';
import { createRef, useRef } from 'react';
import { check, PERMISSIONS, request, RESULTS } from 'react-native-permissions';

export const PermissionUtil = {
  cameraPermission: async type => {
    try {
      let granted;
      if (Device.isIos)
        granted = await (type === PERMISSION_TYPE.CHECK
          ? check(PERMISSIONS.IOS.CAMERA)
          : request(PERMISSIONS.IOS.CAMERA));
      if (Device.isAndroid)
        granted = await (type === PERMISSION_TYPE.CHECK
          ? check(PERMISSIONS.ANDROID.CAMERA)
          : request(PERMISSIONS.ANDROID.CAMERA));
      if (Device.deviceType === 'web') {
        granted = await (type === PERMISSION_TYPE.CHECK ? check() : request());
      }
      console.log('cameraPermission>>>', granted);
      return granted;
    } catch (error) {
      console.log(error);
    }
  },
  locationPermission: async type => {
    try {
      let granted;
      if (Device.isIos) {
        granted = await (type === PERMISSION_TYPE.CHECK
          ? check(PERMISSIONS.IOS.LOCATION_WHEN_IN_USE)
          : request(PERMISSIONS.IOS.LOCATION_WHEN_IN_USE));
      }
      if (Device.isAndroid) {
        granted = await (type === PERMISSION_TYPE.CHECK
          ? check(PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION)
          : request(PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION));
      }

      if (Device.deviceType === 'web') {
        const result = await navigator.permissions.query({ name: 'geolocation' });
        granted = result.state;
        if (granted === 'prompt') {
          navigator.geolocation.getCurrentPosition(location => {}, null);
        }
      }
      console.log('locationPermission>>>', type, granted);

      return granted;
    } catch (error) {
      console.log(error);
    }
  },
  showPermissionModal: type => {
    switch (type) {
      case PERMISSION_MODEL.CAMERA:
        store.dispatch(showCameraPermissionModal());
        break;
      case PERMISSION_MODEL.LOCATION:
        store.dispatch(showLocationPermissionModal());
        break;
      case PERMISSION_MODEL.NOTIFICATION:
        store.dispatch(showNotificationPermissionModal());
        break;
    }
  },
  requestPermission: async (callback, permission_model = PERMISSION_MODEL.CAMERA, permission_type = PERMISSION_TYPE.REQUEST) => {
    let granted = '';
    switch (permission_model) {
      case PERMISSION_MODEL.CAMERA:
        granted = await PermissionUtil.cameraPermission();
        break;
      case PERMISSION_MODEL.LOCATION:
        granted = await PermissionUtil.locationPermission(permission_type);
        break;
      case PERMISSION_MODEL.NOTIFICATION:
        granted = await NotificationPermissionHelper.checkPermission();
        break;
    }
    switch (granted) {
      case RESULTS.UNAVAILABLE:
        permission_model === PERMISSION_MODEL.CAMERA
          ? alert('Camera is not supported on this device')
          : alert('Location is not supported on this device');
        break;
      case RESULTS.DENIED:
        if (Device.deviceType === 'web') {
          PermissionUtil.showPermissionModal(permission_model);
        }
        break;
      case RESULTS.GRANTED:
      case RESULTS.LIMITED:
        callback();
        break;
      case RESULTS.BLOCKED:
        PermissionUtil.showPermissionModal(permission_model);
        break;
      default:
        break;
    }

    return granted;
  },
};
