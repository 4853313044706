import { ArrowBorderIcon, ChevronDownIcon } from '@assets';
import { AppHtmlView } from '@screens/main/quiz/QuizFailed/components/AppNote.view';
import theme, { globalStyle } from '@theme';
import { Font, FontSize } from '@theme/size';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, useWindowDimensions, View } from 'react-native';
import Collapsible from 'react-native-collapsible';
import { EasingNode } from 'react-native-reanimated';
import { AppText } from '../../../../../../components/AppText/AppText';
import { styles } from './styles';

export const CollapseView = ({ title, alert, subTitle, children, key }) => {
  const scaleFont = useWindowDimensions().fontScale;
  const [isExpand, setIsExpand] = useState(false);

  // const [headerLines, setHeaderLines] = useState(2);
  const handleCollapsed = () => {
    console.log('handleCollapsed>>>', isExpand);
    setIsExpand(!isExpand);
  };

  const { t } = useTranslation();
  const [currentHeight, setCurrentHeight] = useState(0);

  const onLayout = event => {
    const newHeight = event.nativeEvent.layout.height;

    // Don't do anything for height = 0 (collapsed state)
    if (newHeight === 0) return;

    // Only toggle key if the height has changed
    if (newHeight !== currentHeight) {
      setCurrentHeight(newHeight);
    }
  };
  return (
    <View style={[styles.container]} key={key}>
      <TouchableOpacity
        activeOpacity={0.7}
        onPress={handleCollapsed}
        style={[styles.accordion, !isExpand ? {} : styles.isExpanded]}>
        <View
          style={[
            globalStyle.directionColumm,
            globalStyle.flex1,
            { paddingVertical: theme.spacing.md, paddingLeft: theme.spacing.md },
          ]}>
          <AppText style={{ ...Font.Bold_700, fontSize: FontSize.Font15 }}>{title}</AppText>
          {!!subTitle && <AppText style={{ fontSize: FontSize.Font13, marginTop: 2 }}>{subTitle}</AppText>}

          {!!alert && <AppHtmlView text={alert} style={{ fontSize: FontSize.Font13, marginTop: 2 }} />}
        </View>

        <ChevronDownIcon style={{ transform: isExpand ? [{ rotate: '180deg' }] : [] }} />
      </TouchableOpacity>
      <View
        style={[
          {
            top: -8,
            left: 30,
            zIndex: 100,
            transform: [{ rotate: '180deg' }],
            paddingRight: !isExpand ? 16 : 0,
            // alignItems: 'flex-start',
            alignSelf: 'flex-start',
          },
        ]}>
        <View style={{ opacity: isExpand ? 1 : 0 }}>
          {<ArrowBorderIcon height={14} width={16} strokeWidth={1} fillColor="#E3E3E3" strokeColor="#E3E3E3" />}
        </View>
      </View>
      <Collapsible
        style={{ width: '100%' }}
        easing={EasingNode.linear}
        onAnimationEnd={() => {
          console.log('Collapsible>>>onAnimationEnd');
        }}
        collapsed={!isExpand}
        key={currentHeight}
        renderChildrenCollapsed={true}>
        <View onLayout={onLayout}>{children}</View>
      </Collapsible>
    </View>
  );
};
