import QrReader from 'react-camera-qr';
import React from 'react';
import { View, StyleSheet } from 'react-native';


/**
 * An empty component used to manage SplashScreenWeb from Suspense fallback
 */
const QRCodeScanner = (props) => {
    const { onRead, reactivateTimeout } = props;
    const handleBarCodeScanned = (data) => {
        if(data){
            onRead({data: data});
        }
    };
    const handleError = err => {
        console.error(err)
    }
    return (
        <View style={styles.container}>
            <QrReader
                delay={reactivateTimeout}
                showViewFinder={false}
                onError={handleError}
                style={StyleSheet.absoluteFillObject}
                onScan={handleBarCodeScanned}
            />
        </View>
    );
};
const styles = StyleSheet.create({
    container: {
        width: 600,
        height: 600
    },
});
export default QRCodeScanner;

