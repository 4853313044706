import {createAction, createSlice} from '@reduxjs/toolkit';

export interface IgetReportBundleFormState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IgetReportBundleFormState = {
  loading: false,
  payload: null,
  error: null,
};

export const getReportBundleFormRequest = createAction(
  'getReportBundleForm/getReportBundleFormRequest',
  (payload, onSuccess, onFailed) => {
  console.log('prepareAction>>>', payload, onSuccess);
  return {
    payload: payload,
    meta: onSuccess,
    error: onFailed,
  };
});

const getReportBundleFormSlice = createSlice({
  name: 'getReportBundleForm',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // getReportBundleFormRequest(state, action) {
    //   state.loading = true;
    //   // @ts-ignore
    //   state.payload = null;
    //   state.error = null;
    // },
    getReportBundleFormSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    getReportBundleFormFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getReportBundleFormSuccess, getReportBundleFormFailed } = getReportBundleFormSlice.actions;

export default getReportBundleFormSlice.reducer;
