import { QR_SCANNER_TYPE, REPORT_TYPE } from '@constant';
import { useFocusEffect, useRoute } from '@react-navigation/native';
import { getDashboardRequest, getValidateReportRequest } from '@redux/app/appSlice';
import { getUserValidationRequest } from '@redux/auth/authSlice';
import { globalLoading } from '@redux/global/selectors';
import { goBack, navigate, replace } from '@routes/navigationUtils';
import { Screens } from '@routes/route';
import { Device } from '@theme/device';
import { isJsonString } from '@utils';
import { showErrorMsg } from '@utils/api';
import { StringHelper } from '@utils/common/string';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {QRTransform} from "@utils/transform/QR";
import _ from 'lodash'

export const useScanCode = (props) => {
  const dispatch = useDispatch();
  const loading = useSelector(globalLoading);
  // const type = useRoute().params?.type;
  const paramsData = useRoute().params
  const formData = paramsData?.formData
  const infoQR = paramsData?.info
  const scanId = infoQR?.scanId

  const onSuccess = paramsData?.onSuccess
  const { t } = useTranslation();
  const [reactivate, setReactivate] = useState(false);
  const [resultText, setResultText] = useState('')

  useFocusEffect(
      useCallback(() => {
        //TODO: reactive after goback
        console.log('useFocusEffect>>>');
        setTimeout(() => {
          console.log('useFocusEffect>>>end');
          setReactivate(true);
        }, 3000)
      }, []),
  );

  const handleReadQR = async e => {
    console.log('handleReadQR>>>', e, reactivate)
    //FIXME: improved isValidArea later
    // if (!reactivate || !isValidArea(e)) return;
    if (!reactivate) return;

    console.log('handleReadData>>>', e.data)

    handleReadData(e.data)
  };

  const handleReadData = strData => {
    if(_.isEmpty(strData)){
      return;
    }
    setReactivate(false);
    setResultText(strData)
    const options = infoQR.data
    const xData = {
      ...formData,
    }
    xData[scanId] = strData
    const result = QRTransform.getValidQRFromOptions(options, xData)

    if(_.isNil(result)){
      // NOTE: show error if not map result
      showErrorMsg(t('QR_SCAN_ERROR'))
    }
    if(!!result && onSuccess){
      goBack()
      onSuccess(result)
      return
    }

    setTimeout(() => setReactivate(true), 1500);

    //TODO: check valid item
    // setTimeout(() => setReactivate(true), 1500);


  }
  const handleClosePress = () => {
    goBack();
  };


  return { handleReadQR, handleClosePress, reactivate, resultText };
};
