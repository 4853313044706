// import {getAppSettings, getDashboardSetting, getSettings} from "@utils";
import { AppCustomer, DefaultData, MappingCountry } from '@constant/appSetting';
import _ from 'lodash';

export class AppSetting {
  static myInstance = null;
  // _state = {
  //     // colors:{...palette},
  //     // spacing:{...theme.spacing},
  // };
  _state = DefaultData.royalmail;
  static get instance() {
    if (AppSetting.myInstance == null) {
      AppSetting.myInstance = new AppSetting();
    }
    return this.myInstance;
  }
  static get state() {
    return AppSetting.instance._state;
  }
  static set state(state) {
    AppSetting.instance._state = state;
  }
  static setState(state) {
    AppSetting.instance._state = { ...AppSetting.instance._state, ...state };
  }
}

export const SettingHelper = {
  // getColors: () => {
  //     // AppSetting.state
  //     return getAppSettings()?.colors
  // },
  // getSpacing: () => {
  //     return getAppSettings()?.spacing
  // },
  // getCustomerByCountry: (country) => {
  //     let customer = AppCustomer.royalmail
  //     if(country in MappingCountry){
  //         customer = MappingCountry[country]
  //     }
  //     return customer
  // },
  // setupCachedSetting: (country) => {
  //     const settings = getSettings()
  //     const app = settings?.app
  //     let appSetting = !!app ? {...app} : {}
  //     const customer = settings?.customer
  //     const localCustomer = SettingHelper.getCustomerByCountry(country)
  //     console.log('setupCachedSetting>>>',customer !== localCustomer, customer, localCustomer, appSetting)
  //     if(customer !== localCustomer){
  //         appSetting = DefaultData[localCustomer]
  //     }
  //     AppSetting.state = {...appSetting}
  //     console.log('setupCachedSetting>>>end>>',AppSetting.state, settings)
  //
  //     return AppSetting.state
  // },
  // setupCachedSettingWithLoginCustomer: (loginCustomer, country) => {
  //     const settings = getSettings()
  //     const app = settings?.app
  //     let appSetting = !!app ? {...app} : {}
  //     const customer = settings?.customer
  //     if(_.isEmpty(loginCustomer)){
  //         SettingHelper.setupCachedSetting(country)
  //         return
  //     }
  //
  //     if(loginCustomer !== customer){
  //         appSetting = DefaultData[loginCustomer]
  //     }
  //
  //     AppSetting.state = {...appSetting}
  //     console.log('setupCachedSettingWithLoginCustomer>>>end>>',AppSetting.state, settings)
  //
  //     return AppSetting.state
  // },
};
