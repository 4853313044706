import { createSlice } from '@reduxjs/toolkit';
import uniqBy from 'lodash/uniqBy';

export interface IFilmsState {
  loading: boolean;
  films: {
    id: string;
    title: string;
    description: string;
    director: string;
    producer: string;
    release_date: string;
    rt_score: string;
    people: string;
    species: string;
    locations: string;
    url: string;
  }[];
}

const initialState: IFilmsState = {
  loading: false,
  films: [],
};

const filmsSlice = createSlice({
  name: 'todos',
  initialState,
  reducers: {
    getAllFilmsRequest(state, action) {
      state.loading = true;
      state.films = [];
    },
    getAllFilmsSuccess(state, action) {
      state.loading = false;
      state.films = uniqBy(action.payload, 'id');
    },
    getAllFilmsFailed(state) {
      state.loading = false;
    },
  },
});

export const { getAllFilmsRequest, getAllFilmsSuccess, getAllFilmsFailed } = filmsSlice.actions;

export default filmsSlice.reducer;
