import {createAction, createSlice} from '@reduxjs/toolkit';

export interface IsaveFeedbackCDSState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IsaveFeedbackCDSState = {
  loading: false,
  payload: null,
  error: null,
};

export const saveFeedbackCDSRequest = createAction('saveFeedbackCDS/saveFeedbackCDSRequest', (payload, onSuccess, onFailed) => {
  console.log('prepareAction>>>', payload, onSuccess);
  return {
    payload: payload,
    meta: onSuccess,
    error: onFailed,
  };
});

const saveFeedbackCDSSlice = createSlice({
  name: 'saveFeedbackCDS',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // saveFeedbackCDSRequest(state, action) {
    //   state.loading = true;
    //   // @ts-ignore
    //   state.payload = null;
    //   state.error = null;
    // },
    saveFeedbackCDSSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    saveFeedbackCDSFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { saveFeedbackCDSSuccess, saveFeedbackCDSFailed } = saveFeedbackCDSSlice.actions;

export default saveFeedbackCDSSlice.reducer;
