import remoteConfig from '@react-native-firebase/remote-config';
import { Platform } from 'react-native';

export async function getRemoteConfig(): Promise<any> {
  if (Platform.OS === 'web') {
    return {};
  }
  
  try {
    const isProd = false;
    await remoteConfig().setConfigSettings({
      minimumFetchIntervalMillis: isProd ? 14400000 : 60000,
    });
    await remoteConfig()
      .setDefaults({
        is_force_update: false,
        ios_version: '0.0.0',
        android_version: '0.0.0',
      })
      .then(() => remoteConfig().fetchAndActivate());
    const is_force_update = remoteConfig().getBoolean('is_force_update');
    const build_version = is_force_update
      ? Platform.OS === 'android'
        ? remoteConfig().getString('android_version')
        : remoteConfig().getString('ios_version')
      : '0.0.0';
    console.log('getRemoteConfig>>>', remoteConfig());
    return {
      is_force_update,
      build_version,
    };
  } catch (error) {
    console.error('getRemoteConfig - Error: ', error);
    throw error;
  }
}
