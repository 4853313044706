import { createSelector } from '@reduxjs/toolkit';

const allCommonSelector = state => state.common;

export const cameraPermissionModalVisible = createSelector(
  allCommonSelector,
  commonState => commonState.cameraPermissionModalVisible,
);

export const locationPermissionModalVisible = createSelector(
  allCommonSelector,
  commonState => commonState.locationPermissionModalVisible,
);

export const notificationPermissionModalVisible = createSelector(
    allCommonSelector,
    commonState => commonState.notificationPermissionModal,
);

export const modalDeeplinkVisible = createSelector(allCommonSelector, commonState => commonState.modalDeeplink);
export const globalModalSelector = createSelector(allCommonSelector, commonState => commonState.globalModal);
export const globalSuccessModalSelector = createSelector(
  allCommonSelector,
  commonState => commonState.globalSuccessModal,
);

export const notificationImportantModalVisible = createSelector(
    allCommonSelector,
    commonState => commonState.notificationImportantModalVisible,
);

export const noInternetModalVisible = createSelector(
  allCommonSelector,
  commonState => commonState.noInternetModalVisible,
);

export const newsModalVisible = createSelector(allCommonSelector, commonState => commonState.newsModal?.visible);
export const newsModalSelector = createSelector(allCommonSelector, commonState => commonState.newsModal);
