import { goBack } from '@routes/navigationUtils';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useAppHeader = props => {
  const { onLeftPress, onRightPress } = props;
  const handleLeftPress = () => {
    if (onLeftPress) return onLeftPress;
    return goBack();
  };
  const handleRightPress = () => {
    if (onRightPress) return onRightPress;
    return goBack();
  };
  return { handleLeftPress, handleRightPress };
};
