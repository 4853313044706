import { FORM } from '@constant/model/RegisterForm';
import { getContentTitle, getTitle } from '@utils/transform/form';
import i18next from 'i18next';
import _ from 'lodash';

export const mappingListQuiz = payload => {
  if (_.isEmpty(payload)) {
    return [];
  }

  return payload?.map(x => {
    return {
      ...x,
      title: getContentTitle(x?.name),
    };
  });
};

export const mappingQuizDetail = payload => {
  if (_.isEmpty(payload)) {
    return [];
  }
  const questions = payload?.values;
  if (_.isEmpty(questions)) {
    return [];
  }

  questions?.map(att => {
    const values = att?.values;
    if (!_.isEmpty(values)) {
      if (Array.isArray(values)) {
        const fixValues = values?.map(x => {
          return {
            ...x,
            content: getContentTitle(x?.name),
          };
        });
        att.data = fixValues;
      } else if (typeof values === 'object') {
        att.data = [];
      }
    }

    att.title = getTitle(att);

    return att;
  });
  return payload;
};

export const QuizTransform = {
  checkCorrectAnswer: (answers, quiz) => {
    const quizId = quiz?.id;
    const values = quiz?.values;
    // console.log('checkCorrectAnswer>>>', answers, quiz);
    // console.log('checkCorrectAnswer>>>quiz>>>', quiz);

    if (_.isEmpty(quiz) || _.isEmpty(answers || _.isEmpty(values))) {
      return false;
    }
    const answersValue = answers[quizId];
    if (_.isEmpty(answersValue)) {
      return false;
    }

    // const keys = Object.keys(answersValue)
    let rs = true;
    for (const answersValueKey in answersValue) {
      const item = values.find(x => x._id === answersValueKey);

      if (_.isEmpty(item)) {
        rs = false;
        break;
      }
      const type = item.type;
      if (type === FORM.FORM_TYPE.note) {
        continue;
      }
      const options = item?.options;
      const ans = answersValue[answersValueKey];
      const optionMap = options?.find(x => x._id === ans);
      if (_.isEmpty(optionMap)) {
        rs = false;
        break;
      }

      if (!optionMap.correct) {
        rs = false;
        break;
      }
    }

    console.log('checkCorrectAnswer>>>', rs);
    return rs;
  },
  mappingListQuiz: payload => {
    if (_.isEmpty(payload)) {
      return [];
    }

    return payload?.map(x => {
      return {
        ...x,
        title: getContentTitle(x?.name),
      };
    });
  },
  fixQuizAnswer: (values, formData) => {
    const rs = {};
    for (const valuesKey in values) {
      const item = formData?.find(x => x._id === valuesKey);
      if (_.isEmpty(item)) {
        continue;
      }
      const type = item.type;
      if (type === FORM.FORM_TYPE.note) {
        continue;
      }
      rs[valuesKey] = values[valuesKey];
    }
    return rs;
  },
  getCorrectAnswer: quiz => {
    console.log('getCorrectAnswer>>>', quiz);
    const quizId = quiz?.id;
    const values = quiz?.values;
    const rs = {};
    const result = {};
    // rs[quiz?.id] = {}
    for (const item of values) {
      const _id = item._id;
      const type = item.type;
      if (type === FORM.FORM_TYPE.note) {
        continue;
      }
      const options = item?.options;
      const correctId = options?.find(x => x.correct)?._id
      rs[_id] = correctId;
    }
    result[quizId] = rs;
    console.log('getCorrectAnswer>>>end>>>', rs, result)
    return result;
  },
};
