import {
    AnswerIcon,
    ArrowBorderIcon,
    AwaitingIcon,
    ChevronDownIcon, CirclePlusIcon,
    LogoIcon,
    MessageCheckIcon,
    OverdueIcon, PencilIcon
} from '@assets';
import {AppAccordion, AppKAView, AppSafeAreaView, AppText} from '@components';
import GenericHeader from '@components/GenericHeader';
import { goBack } from '@routes/navigationUtils';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import {Font, FontSize} from '@theme/size';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {StatusBar, View, Text, ScrollView, TouchableOpacity, useWindowDimensions, Platform} from 'react-native';
import { useUnsureDateList } from './UnsureDateList.hook'
import { UnsureDateListStyle } from './UnsureDateList.style'
import {SURVEY_STATUS} from "@constant";
import FontAwesome5, {FA5Style} from "react-native-vector-icons/FontAwesome5";
import { backgroundColor } from 'react-native-calendars/src/style';

export const UnsureDateListScreen = (props) => {
  const { t } = useTranslation()
  const scaleFont = useWindowDimensions().fontScale;
  const StatusIcon = ({ status }) => {
        switch (status) {
            case SURVEY_STATUS.Today:
            case SURVEY_STATUS.Awaiting_data:
                return <AwaitingIcon />;
            case SURVEY_STATUS.Answered:
                return <AnswerIcon />;
            case SURVEY_STATUS.Overdue:
                return <OverdueIcon />;
            default:
                return null;
        }
    };
  const {onAddNew, onEdit, listData, listUnsured } = useUnsureDateList(props)
  return (
      <>
        <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
        <AppSafeAreaView areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>
          <GenericHeader onBackClicked={ goBack }
                         title={t('CORRECT_DELIVERY_SURVEY')}
                         style={[{ backgroundColor: '#EDEDED' }]} />
            <View
                style={{
                    borderTopRightRadius: theme.spacing.lg,
                    borderTopLeftRadius: theme.spacing.lg,
                    backgroundColor: palette.WHITE,
                    width: '100%',
                    flex: 1,
                }}>
                <ScrollView contentContainerStyle={{ marginTop: 4, paddingHorizontal: 15 }}>
                    <TouchableOpacity
                        // key={index}
                        activeOpacity={0.7}
                        onPress={() => {}}
                        style={[UnsureDateListStyle.accordionItem, { backgroundColor: '#98C9F5', marginBottom:theme.spacing.sm }]}>
                        <View
                            style={[
                                {
                                    bottom: -20,
                                    left: 30,
                                    zIndex: 100,
                                    transform: [{ rotate: '180deg' }],
                                    paddingRight:  0,
                                },
                            ]}>
                            <ArrowBorderIcon height={10} width={16} strokeWidth={1} fillColor="#98C9F5" strokeColor="#98C9F5" />
                        </View>
                        {/*<AppText style={[{ ...Font.Bold_700, flex: 1 }]}>*/}
                        {/*    {t('UNSURE_DATE')}*/}
                        {/*</AppText>*/}
                        <View style={[globalStyle.directionColumm, {flex:1}]}>
                            <AppText style={[{ ...Font.Bold_700}]}>
                                {t('UNSURE_DATE')}
                            </AppText>
                            <AppText style={[{fontSize: FontSize.Font13, marginTop:2}]}>
                                ({listData.length} {listData.lengthl > 1 ? t('REPORTS') : t('REPORT')})
                            </AppText>
                        </View>
                    </TouchableOpacity>
                    {listUnsured?.addNew && (<TouchableOpacity
                        // key={index}
                        activeOpacity={0.7}
                        onPress={() => {onAddNew()}}
                        style={[UnsureDateListStyle.accordion, globalStyle.center, {borderWidth:1, marginTop: theme.spacing.sm}]}>
                            <FontAwesome5 name={'plus'} size={15} solid={true}/>
                            <AppText style={[{ ...Font.Bold_700, marginLeft:4 }]}>
                                {t('ADD_NEW')}
                            </AppText>

                    </TouchableOpacity>)}
                    {listData.map((item, index) => {
                        return <TouchableOpacity
                            key={index}
                            activeOpacity={0.7}
                            onPress={() => onEdit(item)}
                            style={[
                                {
                                    flexDirection: 'row',
                                    backgroundColor: '#F5F5F5',
                                    alignItems: 'center',
                                    marginTop: 15,
                                },
                            ]}>
                            <View  style={[
                                UnsureDateListStyle.accordionItemChild,
                                // scaleFont > 1.2 && {
                                //     flexWrap: 'wrap',
                                //     flexDirection: 'column',
                                //     flex:1,
                                //   }
                            ]}>
                                <AppText style={[ { flex: 1,width: '100%' }]}>{item?.name}</AppText>
                                <AppText style={[ { color: '#888888', fontSize: FontSize.Font13 }]}>({item?.date})</AppText>
                            </View>
                            {Platform.OS === 'web' ? <View style={[UnsureDateListStyle.editViewWeb]}>
                                <PencilIcon color={!!item?.editId ? '#666666' : '#66666638'} />
                            </View> :
                                // <View style={[UnsureDateListStyle.editContainer]}>
                                <View style={[UnsureDateListStyle.editView]}>
                                    <PencilIcon color={!!item?.editId ? '#666666' : '#66666638'} />
                                </View>
                            // </View>
                                }
                        </TouchableOpacity>
                    })}

                </ScrollView>
            </View>
        </AppSafeAreaView>
      </>
  )
}
