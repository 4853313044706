import theme from '@theme';
import { Device } from '@theme/device';
import { Spacing } from '@theme/size';
import { Platform, StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    borderRadius: 3,
    alignItems: Platform.OS === 'web' ? '' : 'center',
    // backgroundColor: '#E3E3E3',
    // marginBottom: 10,
  },
  editView: {
    // backgroundColor: 'white',
    borderRadius: 3,
    margin: 2,
    // flex: 1,
    maxHeight: 40,
    height: '100%',
    minWidth: 40,
    // aspectRatio: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  editViewWeb: {
    // backgroundColor: 'white',
    borderRadius: 3,
    margin: 2,
    marginLeft: theme.spacing.sm,
    // flex: 1,
    maxHeight: 40,
    height: '100%',
    // maxWidth:40,
    aspectRatio: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  editContainer: {
    marginLeft: theme.spacing.sm,
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  accordionWrap: {
    borderRadius: 3,
    width: '100%',
  },
  isExpanded: {
    // backgroundColor: '#F9ECEC',
  },
  accordion: {
    width: '100%',
    marginVertical: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlignVertical: 'center',
    // height: 42, //42
    paddingRight: Device.isAndroid ? 12 : 15,
    borderRadius: 3,
    backgroundColor: '#E3E3E3',
  },
  accordionItem: {
    flexDirection: 'row',
    backgroundColor: '#FFDB80',
    width: '100%',
    paddingVertical: 12,
    paddingLeft: 15,
    // width: 100,
    borderRadius: 3,
    marginTop: 15,
  },
  accordionItemChild: {
    flexDirection: 'row',
    // flexWrap: 'wrap',
    // width: '100%',
    flex: 1,
    paddingVertical: 12,
    paddingLeft: 15,
    // width: 100,
    borderRadius: 3,
    // marginTop: 15,
  },
  date: {
    flex: 1,
    marginLeft: Spacing.width35,
  },
  content: {
    paddingHorizontal: 16,
  },
  contentText: {
    paddingVertical: 15,
  },
});
