import { AppKAView, AppSafeAreaView, AppText } from '@components';
import GenericHeader from '@components/GenericHeader';
import { TIME_TABLE } from '@constant';
import { navigate } from '@routes/navigationUtils';
import { DASHBOARD_SCREEN } from '@routes/route';
import { globalStyle } from '@theme';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { Font, FontSize, Spacing } from '@theme/size';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {FlatList, RefreshControl, View, Platform, useWindowDimensions} from 'react-native';
import { useTimeline } from './Timeline.hook';
import { TimelineStyle } from './Timeline.style';
import {max} from "lodash";

const TimelineItem = ({ item, textWidth, setTextWidth, t, isLast, type }) => {
    const scaleFont = useWindowDimensions().fontScale;

    return (
      <View style={[
          {
              paddingHorizontal: Spacing.width20,
              // backgroundColor: 'yellow'
          },
          // type === TIME_TABLE.BUNDLE
          // ? { marginTop: item?.status === 'today' ? 16 : 16, marginBottom: item?.status === 'today' ? 5 : 0 }
          // : { marginTop: item?.status === 'today' ? 28 : 25, marginBottom: item?.status === 'today' ? 5 : 0 },
      ]}>
          <AppText
              style={{
                  ...Font.Bold_700,
                  textAlign: 'left',
                  color: item?.status === 'today' ? item?.color : palette.MAIN_BLACK,
              }}>
              {item?.title ? `${t(item?.title)}:` : ''}
          </AppText>
          <View
              style={[
                  globalStyle.directionRow,
                  TimelineStyle.viewContainer,
                  {
                      paddingLeft: 60,
                      // backgroundColor: 'red'
                  }
              ]}>

              <View style={{ flexDirection: 'row', flex: 1, marginLeft: 10}}>
                  <AppText
                      // allowFontScaling={false}
                      numberOfLines={1}
                      style={[
                          { flex: 1, textAlign: 'right' },
                          item?.status === 'today' ? { ...Font.Bold_700, color: item?.color } : {},
                      ]}>
                      {item?.sendDateTarget}
                  </AppText>
                  <View
                      style={{
                          alignItems: 'center',
                          // position: 'relative',
                          flexDirection: 'column',
                          paddingHorizontal: 12,
                          width: 40,
                          marginTop: 5,
                          // backgroundColor: 'blue'
                      }}>
                      <View
                          style={
                              item?.status === 'today'
                                  ? { width: 10*scaleFont, height: 10*scaleFont, borderRadius: 1000, backgroundColor: item?.color }
                                  : { width: 10*scaleFont, height: 10*scaleFont, borderRadius: 1000, backgroundColor: item?.color }
                          }
                      />
                      {((type === TIME_TABLE.PACKAGE && !isLast) || type === TIME_TABLE.BUNDLE) && (
                          <View
                              style={{
                                  height: type === TIME_TABLE.PACKAGE ? 25* scaleFont : 40 * scaleFont,
                                  width: scaleFont*1.5,
                                  backgroundColor: '#C6C6C6',
                                  marginTop: type === TIME_TABLE.PACKAGE ? 10 : 10 * (scaleFont - 1),
                                  transform:
                                      type === TIME_TABLE.PACKAGE
                                          ? [{ translateY: item?.status === 'today' ? 16 : 5 }]
                                          : [{ translateY: item?.status === 'today' ? 11 : 5 }],
                              }}
                          />
                      )}
                  </View>
                  <View style={[{ flex: 2 }]}>
                      <AppText
                          // allowFontScaling={false}
                          numberOfLines={1}
                          style={[{ color: '#33333' }, item?.status === 'today' ? { ...Font.Bold_700, color: item?.color } : {}]}>
                          {type === TIME_TABLE.BUNDLE ? item?.bundleId : item?.packageId}
                      </AppText>
                      {type === TIME_TABLE.BUNDLE && (
                          <>
                              <AppText
                                  // allowFontScaling={false}
                                  numberOfLines={1}
                                  style={[{ color: '#888888', fontSize: FontSize.Font14 }]}>
                                  {item?.numberOfTestItems} {item?.numberOfTestItems > 1 ? t('TEST_ITEMS') : t('TEST_ITEM')}
                              </AppText>
                              <AppText
                                  // allowFontScaling={false}
                                  numberOfLines={1}
                                  style={[{ color: '#888888', fontSize: FontSize.Font14 }]}>
                                  {!!item?.testFormat ? item?.testFormat : ' '}
                              </AppText>
                          </>
                      )}
                  </View>
              </View>
          </View>
      </View>

  );
};

export const TimelineScreen = props => {
  const { t } = useTranslation();
  const { data, handleRefresh, refresh, type } = useTimeline(props);
  const [textWidth, setTextWidth] = useState(0);
  const renderItem = ({ item, index, ...rest }) => {
    return (
      <TimelineItem
        item={item}
        textWidth={textWidth}
        setTextWidth={setTextWidth}
        t={t}
        isLast={index === data.length - 1}
        type={type}
      />
    );
  };
  return (
    <AppSafeAreaView areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>
      <AppKAView>
        <GenericHeader
          onBackClicked={() => navigate(DASHBOARD_SCREEN)}
          title={t(type === TIME_TABLE.BUNDLE ? 'BUNDLE_TIMELINE' : 'PACKAGE_TIMELINE')}
          style={[{ backgroundColor: palette.BACKGROUND_GREY }]}
        />
        <View style={[TimelineStyle.containerView]}>
          {/*<AppText>{t('LIST_QUIZ_SUBTITLE')}</AppText>*/}
          <FlatList
            data={data}
            keyExtractor={item => {
              return item?.bundleId || item?.packageId;
            }}
            renderItem={renderItem}
            refreshControl={
              <RefreshControl
                refreshing={refresh}
                onRefresh={handleRefresh}
                tintColor={palette.LOADING_COLOR}
                colors={[palette.LOADING_COLOR]}
                titleColor={palette.LOADING_COLOR}
              />
            }
          />
        </View>
      </AppKAView>
    </AppSafeAreaView>
  );
};
