import theme, {WEB_WIDTH} from '@theme';
import { Font, FontSize } from '@theme/size';
import { StyleSheet } from 'react-native';

export const VoucherStyle = StyleSheet.create({
  container: {
    backgroundColor: '#F5F5F5',
    // marginLeft: theme.spacing.sm,
    // marginRight: theme.spacing.sm,
    // marginBottom: theme.spacing.md,
    margin: theme.spacing.sm,
    borderRadius: 4,
    borderColor: '#C6C6C6',
    borderWidth: 1,
    flex: 1,
    maxWidth: '100%',

    // flex:1,
    // width: '100%',
    // marginTop: theme.spacing.md,
  },
  leftContainer: {
    backgroundColor: '#F5F5F5',
    marginLeft: theme.spacing.md,
    // marginRight: theme.spacing.md,
    marginBottom: theme.spacing.md,
    padding: 20,
    paddingRight: 20 - 10,
    borderRadius: 4,
    borderColor: '#C6C6C6',
  },
  rightContainer: {
    backgroundColor: '#F5F5F5',
    // marginLeft: 2,
    // marginLeft: theme.spacing.md,
    marginRight: theme.spacing.md,
    marginBottom: theme.spacing.md,
    padding: theme.spacing.md,
    // borderRadius: 4,
    borderColor: '#C6C6C6',
    flex: 1,
  },

  leftTriangle: {
    width: 10,
    height: 86,
    borderBottomWidth: 10,
    borderBottomColor: 'white',
    // borderLeftWidth: 10,
    // borderLeftColor: "green",
    borderRightWidth: 10,
    // borderRightColor: '#F5F5F5',
    borderRightColor: '#F5F5F5',

    borderTopWidth: 10,
    borderTopColor: 'white',
    borderStyle: 'solid',
    backgroundColor: '#F5F5F5',
    // marginLeft: 2,
    transform: [{ rotate: '180deg' }],
  },
  rightTriangle: {
    width: 10,
    height: 86,
    borderBottomWidth: 10,
    borderBottomColor: 'white',
    // borderLeftWidth: 10,
    // borderLeftColor: "green",
    borderRightWidth: 10,
    // borderRightColor: '#F5F5F5',
    borderRightColor: '#F5F5F5',

    borderTopWidth: 10,
    borderTopColor: 'white',
    borderStyle: 'solid',
    backgroundColor: '#F5F5F5',
    marginLeft: 2,
    // transform: [{ rotate: '270deg' }],
  },
  image: {
    // width: '100%',
    height: 60,
    // width: 100,
    // backgroundColor: 'red',
    // figure out your image aspect ratio
    aspectRatio: 1.5,
    // ratio:1,
    // borderRadius: 4,
  },
  image_web: {
    // width: '100%',
    height: WEB_WIDTH / 3 - 60,
    width: (WEB_WIDTH / 3 - 60) * 1.5,
  },
  imageContainer: {
    width: '100%',
    aspectRatio: 1.5,
    // backgroundColor: 'red',
    // width: 200,
    // margin: 2,
  },
  listView: {
    marginBottom: theme.spacing.md,
    // marginTop: theme.spacing.sm,
    paddingHorizontal: theme.spacing.sm,
    // paddingLeft: Dimens.Margin.Normal,
    // paddingRight: Dimens.Margin.Normal,
  },
  rootView: {
    marginBottom: theme.spacing.md,
  },
  noteView: {
    // marginHorizontal: theme.spacing.md,
    // marginBottom: theme.spacing.md,
    fontSize: FontSize.Font14,
    marginTop: theme.spacing.sm,
    // paddingHorizontal: theme.spacing.sm,
  },
});
