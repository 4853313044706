import { Constant } from '@constant';
import { validateUserService } from '@services/user';
// @ts-ignore
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { validateUserFailed, validateUserSuccess } from './validateUserSlice';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* validateUserSaga(payload: any): Generator<
  | CallEffect
  | PutEffect<{
      payload: any;
      type: string;
    }>,
  void
> {
  try {
    const res = yield call(validateUserService, payload);
    console.log('validateUserSaga>>>>', res);
    if (!isEmpty(res)) {
      yield put(validateUserSuccess(res));
    } else {
      // @ts-ignore
      yield put(validateUserFailed(Constant.ERROR_NO_RESPONSE));
    }
  } catch (err) {
    // @ts-ignore
    yield put(validateUserFailed(err));
  }
}
