import { ChevronPickerIcon, LogoIcon, MessageCheckIcon } from '@assets';
import { AppKAView, AppModal, AppModalV2, AppSafeAreaView, AppSingleSelection, AppText } from '@components';
import { navigatePush } from '@routes/navigationUtils';
import { Screens } from '@routes/route';
import { AddressContainerModal } from '@screens/main/SearchAddress/components/AddressContainerModal';
import { AppPostcodeStyle } from '@screens/main/SearchPostcode/components/AppPostcode.style';
import { PostcodeContainerModal } from '@screens/main/SearchPostcode/components/PostcodeContainerModal';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { Font } from '@theme/size';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, TouchableOpacity, ScrollView, Platform } from 'react-native';
import { useDispatch } from 'react-redux';
import { useAppAddress } from './AppAddress.hook';
import { AppAddressStyle } from './AppAddress.style';

export const AppSearch = props => {
  const { title, disabled, error, handleOpenScreen, isRequired, styleTitle, value, style, isBoldTitle, hint, onLayout } = props;
  console.log('AppSearch>>>', value);
  const { t } = useTranslation();
  return (
    <>
      <View style={[AppAddressStyle.container, style]} onLayout={e => {
        if (onLayout) {
          onLayout(e);
        }
      }}>
        <AppText style={[AppAddressStyle.textTitle, styleTitle, isBoldTitle ? { fontWeight: '700' } : {}]}>
          {isRequired && <AppText style={AppAddressStyle.isRequired}>* </AppText>}
          {title}
        </AppText>
        <TouchableOpacity
          disabled={disabled}
          onPress={handleOpenScreen}
          // activeOpacity={0.7}
          style={[AppAddressStyle.viewSelect, { opacity: disabled ? 0.5 : 1 }]}>
          {/* {mode === 'date' ? <CalendarIcon /> : <ClockIcon />} */}
          <AppText style={[AppAddressStyle.textSelect, value ? AppAddressStyle.textAfterSelect : {}]}>
            {value ? value : props.placeHolder ? props.placeHolder : t('SELECT_PLACE_HOLDER_DEFAULT')}
          </AppText>
          <ChevronPickerIcon />
        </TouchableOpacity>
        {!!hint && <AppText style={AppAddressStyle.hint}>{hint}</AppText>}
        {!!error && <AppText style={AppAddressStyle.error}>{error}</AppText>}
      </View>
    </>
  );
};

AppSearch.propTypes = {
  value: PropTypes.any,
  error: PropTypes.any,
  style: PropTypes.any,
  title: PropTypes.string,
  isRequired: PropTypes.bool,
  styleTitle: PropTypes.any,
  disabled: PropTypes.bool,
  handleOpenScreen: PropTypes.func,
  isBoldTitle: PropTypes.bool,
  hint: PropTypes.string,
  placeHolder: PropTypes.string,
  onLayout: PropTypes.func,
};

export const AppAddress = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const { value, setValue } = useAppAddress(props);
  const { title, disabled, error, isRequired, styleTitle, value, style, isBoldTitle } = props;
  const header = t('ADDRESS_HEADER');
  // const visible = true
  const [visible, setVisible] = useState(false);
  const onSelected = (text, item) => {
    if (props.onSelect) {
      props.onSelect(text, item);
    }
    setVisible(false);
  };

  const handleOpenScreen = () => {
    console.log('handleOpenScreen>>address>>>', props);
    setVisible(true);

    // navigatePush(Screens.SEARCH_ADDRESS_SCREEN, {
    //   onSelected: onSelected,
    //   postCode: props.postCode,
    //   searchText: value,
    // });
  };

  const handleBackdropPress = () => {
    setVisible(false);
  };
  return (
    <>
      <AppSearch
        handleOpenScreen={handleOpenScreen}
        value={value}
        style={style}
        styleTitle={styleTitle}
        disabled={disabled}
        title={title}
        error={error}
        isRequired={isRequired}
        isBoldTitle={isBoldTitle}
        placeHolder={props.placeHolder}
        onLayout={props.onLayout}
      />
      <AppModalV2 visible={visible} onBackdropPress={handleBackdropPress}>
        <View
          style={[
            globalStyle.directionColumm,
            {
              minHeight: (Device.height * 1) / 2,
              maxHeight: (Device.height * 2) / 3,
              width: Platform.OS === 'web' ? 810 * 0.9 : Device.width * 0.9,
              // backgroundColor: 'yellow',
              // borderRadius:8,
            },
          ]}>
          {!!header && (
            <View
              style={AppPostcodeStyle.header}
              onLayout={e => {
                console.log(e.nativeEvent.layout.height);
              }}>
              <AppText style={AppPostcodeStyle.headerTxt}>{header}</AppText>
            </View>
          )}
          {/*<View*/}
          {/*  style={[*/}
          {/*    globalStyle.directionColumm,*/}
          {/*    {*/}
          {/*      flex: 1,*/}
          {/*      // borderRadius:8,*/}
          {/*      // backgroundColor: 'yellow',*/}

          {/*    },*/}
          {/*  ]}>*/}
          <AddressContainerModal
            postCode={props.postCode}
            searchText={value}
            onSelect={onSelected}
            dispatch={dispatch}
            header={header}
          />
          {/*</View>*/}
        </View>
      </AppModalV2>
    </>
  );
};

AppAddress.propTypes = {
  value: PropTypes.any,
  error: PropTypes.any,
  style: PropTypes.any,
  title: PropTypes.string,
  isRequired: PropTypes.bool,
  onSelect: PropTypes.func,
  header: PropTypes.any,
  styleTitle: PropTypes.any,
  disabled: PropTypes.bool,
  postCode: PropTypes.string,
  isBoldTitle: PropTypes.bool,
  placeHolder: PropTypes.string,
  onLayout: PropTypes.func,
};

AppAddress.defaultProps = {
  placeHolder: '',
};
