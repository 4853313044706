export const ReportPackageMockData = {
  summary: {
    id: '33048',
    total: 12,
  },
  form: {
    title: {
      en: 'Report Package',
    },
    description: '',
    attributes: [
      {
        name: {
          en: 'Receipt date of the test package',
        },
        type: 'date',
        validation: {
          type: 'javascript',
          javascript: 'return moment().diff(moment(receipt_date)) >= 0;',
        },
        internalId: 'receipt_date',
        errorMessage: {
          en: 'Field is required',
        },
        required: true,
        editable: true,
      },
      {
        name: {
          en: 'Did you receive 222 Bundles?',
        },
        type: 'boolean',
        internalId: 'correct_amount_items',
        options: [
          {
            name: {
              en: 'Yes',
            },
            internalId: true,
          },
          {
            name: {
              en: 'No',
            },
            internalId: false,
          },
        ],
        errorMessage: {
          en: 'Field is required',
        },
        required: true,
        editable: true,
      },
      {
        name: {
          en: 'Enter the deviations',
        },
        type: 'integer',
        internalId: 'deviations',
        errorMessage: {
          en: 'Field is required',
        },
        required: true,
        editable: true,
        condition: {
          react: 'return correct_amount_items === false;',
        },
      },
    ],
  },
};

export const PackageFormMockResponse = {
  status: true,
  message: 'Package report form successfully fetched',
  data: [
    {
      title: 'Report package',
      description: '',
      attributes: [
        {
          name: 'Do you know which day you received this package?',
          type: 'boolean',
          internalId: 'receipt_date_known',
          options: [
            {
              name: 'Yes',
              internalId: true,
              icon: 'check',
            },
            {
              name: 'No',
              internalId: false,
              icon: 'times',
            },
          ],
          errors: {
            required: 'This field is required',
          },
          required: true,
          editable: true,
        },
        {
          name: 'On which date did you receive the package?',
          type: 'date',
          icon: 'calendar',
          validation: {
            type: 'javascript',
            javascript: 'return moment().diff(moment(receipt_date)) >= 0;',
          },
          maxDate: {
            javascript: 'return moment();',
          },
          internalId: 'receipt_date',
          errorMessage: 'This field is required',
          errors: {
            required: 'This field is required',
            validation: 'Date in the future is not allowed.',
          },
          required: true,
          editable: true,
          placeHolder: 'Select date',
          condition: {
            react: 'return {{receipt_date_known}} === true;',
          },
        },
        {
          name: 'Did you receive 1 bundle?',
          type: 'boolean',
          internalId: 'correct_amount_items',
          options: [
            {
              name: 'Yes',
              internalId: true,
              icon: 'check',
            },
            {
              name: 'No',
              internalId: false,
              icon: 'times',
            },
          ],
          errorMessage: 'This field is required',
          errors: {
            required: 'This field is required',
          },
          required: true,
          editable: true,
          condition: {
            react: 'return {{receipt_date_known}} === true && {{receipt_date}};',
          },
        },
        {
          name: 'Tell us what’s wrong',
          type: 'textArea',
          internalId: 'deviations',
          errorMessage: 'This field is required',
          errors: {
            required: 'This field is required',
          },
          required: true,
          editable: true,
          condition: {
            react: 'return correct_amount_items === false;',
          },
        },
      ],
    },
  ],
};
