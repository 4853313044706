import { searchForPostCodeSaga } from '@redux/address/searchForPostCodeSaga';
import { searchForPostCodeRequest } from '@redux/address/searchForPostCodeSlice';
import { searchForAddressSaga } from '@redux/address/searchForAddressSaga';
import { searchForAddressRequest } from '@redux/address/searchForAddressSlice';
import { ForkEffect, takeLatest } from 'redux-saga/effects';

function* addressSagas(): Generator<ForkEffect<never>, void> {
  yield takeLatest(searchForPostCodeRequest.type, searchForPostCodeSaga);
  yield takeLatest(searchForAddressRequest.type, searchForAddressSaga);
}

export default addressSagas;
