import { forgotPasswordService } from '@services/user'

// @ts-ignore
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { forgotPasswordFailed, forgotPasswordSuccess } from './forgotPasswordSlice';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* forgotPasswordSaga(payload: any): Generator<
  | CallEffect
  | PutEffect<{
      payload: any;
      type: string;
    }>,
  void
> {
  try {
    const res = yield call(forgotPasswordService, payload);
    console.log('forgotPasswordSaga>>>>', res);
    if (!isEmpty(res)) {
      yield put(forgotPasswordSuccess(res));
    } else {
      // @ts-ignore
      yield put(forgotPasswordFailed('EMPTY_RESPONSE'));
    }
  } catch (err) {
    // @ts-ignore
    yield put(forgotPasswordFailed(err));
  }
}
