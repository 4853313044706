import { Constant } from '@constant';
import { getFormService } from '@services/form';
// @ts-ignore
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { getFormFailed, getFormSuccess } from './getFormSlice';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* getFormSaga({ payload }) {
  try {
    // @ts-ignore
    const res = yield call(getFormService, payload);
    console.log('getFormSaga>>>>', res);
    if (!isEmpty(res)) {
      yield put(getFormSuccess(res));
    } else {
      // @ts-ignore
      yield put(getFormFailed(Constant.ERROR_NO_RESPONSE));
    }
    return res;
  } catch (err) {
    // @ts-ignore
    yield put(getFormFailed(err));
    return err;
  }
}
