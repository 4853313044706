import React from 'react';

const reportModalRef = React.createRef();

function showReportModal() {
  reportModalRef.current?.open();
}

function hideReportModal() {
  reportModalRef.current?.close();
}

export const reportModal = {
  show: showReportModal,
  hide: hideReportModal,
  ref: reportModalRef,
};
