import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppButton, AppKAView, AppSafeAreaView, AppText } from '@components';
import { FormItemView } from '@components/Form';
import GenericHeader from '@components/GenericHeader';
import { UPLOAD_FILE } from '@constant';
import { FORM } from '@constant/model/RegisterForm';
import { CommonActions, useNavigation } from '@react-navigation/native';
import { goBack, reset } from '@routes/navigationUtils';
import { BOTTOM_TAB, DASHBOARD_TAB, Screens } from '@routes/route';
import { Dashboard } from '@screens/main/Dashboard';
import { useReportBundleDetail } from '@screens/main/report/bundle/detail/BundelDetail.hook';
import { TopBundleView } from '@screens/main/report/bundle/form/components/TopBundle.view';
import { ReportSuccessfulView } from '@screens/main/report/package/form/components/ReportSuccessful.view';
import { TopPackageView } from '@screens/main/report/package/form/components/TopPackage.view';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font, Spacing } from '@theme/size';
import { useLocation } from '@utils/hooks';
import { FormTransform } from '@utils/transform/form';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Text, Platform } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import {ContainerBundle} from "@screens/main/report/bundle/detail/container";

export const ReportBundleDetailScreen = props => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { validateReportData, data } =
    useReportBundleDetail(props);

  return (
    <>
        {validateReportData && data &&<ContainerBundle data={validateReportData} formData={data}/>}
    </>
  );
};
