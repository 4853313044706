import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppKAView, AppSafeAreaView, AppText } from '@components';
import { ADDRESS_TYPE } from '@constant';
import { AddressContainerView } from '@screens/main/SearchAddress/components/AddressContainer.view';
import { useAddressContainerModal } from '@screens/main/SearchAddress/components/AddressContainerModal.hook';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font } from '@theme/size';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text } from 'react-native';
import { useBundleAddressContainerModal } from './BundleAddressContainerModal.hook';
import { BundleAddressContainerModalStyle } from './BundleAddressContainerModal.style';

export const BundleAddressContainerModalView = props => {
  const { t } = useTranslation();
  console.log('AddressContainerModal>>>', props);
  const {
    onSearch,
    data,
    onRefresh,
    isRefreshing,
    loadingMore,
    loading,
    onLoadMore,
    onTextChanged,
    searchText,
    onItemPress,
  } = useBundleAddressContainerModal(props);
  return (
    <AddressContainerView
      data={data}
      loadingMore={loadingMore}
      onTextChanged={onTextChanged}
      headerText={props.header}
      isRefreshing={isRefreshing}
      loading={loading}
      onLoadMore={onLoadMore}
      emptyText={loading ? t('SEARCHING') : t('NO_ADDRESS_FOUND')}
      onRefresh={onRefresh}
      onSearch={onSearch}
      searchText={searchText}
      // descriptionText={t('ADDRESS_DESCRIPTION')}
      onItemPress={onItemPress}
      dispatch={props.dispatch}
      isFullScreen={false}
      postCode={props.postcode}
      descriptionText={props.description}
      onClickHref={props.onClickHref}
      type={ADDRESS_TYPE.bundle}
    />
    // <View />
  );
};

BundleAddressContainerModalView.propTypes = {
  searchText: PropTypes.string,
  onSelect: PropTypes.func,
  dispatch: PropTypes.any.isRequired,
  postCode: PropTypes.string.isRequired,
  header: PropTypes.string,
  description: PropTypes.string,
  onClickHref: PropTypes.func,
  dispatchType: PropTypes.string,
  location: PropTypes.object,
  data: PropTypes.array,
};

BundleAddressContainerModalView.defaultProps = {
  data: [],
};
