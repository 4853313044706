import * as ImagePickerWeb from 'expo-image-picker';

/**
 * An empty component used to manage SplashScreenWeb from Suspense fallback
 */
export const launchImageLibrary = async (props, callBack) => {
    const pickerResult = await ImagePickerWeb.launchImageLibraryAsync({
    ...props,
    });
    callBack(pickerResult);
};

export const launchCamera = async (props, callBack) => {
    const pickerResult = await ImagePickerWeb.launchCameraAsync({
    ...props
    });
  callBack(pickerResult);
};


