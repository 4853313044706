import api from '@services/api-client';
import { stringify } from 'qs';
import withQuery from 'with-query';

export const notificationUrlService = async payload => {
  console.log('notificationUrlService>>>', payload)
  const response = await api.get(`${payload?.url}`)
  console.log('notificationUrlService response>>>', response)
  return response?.data
}

