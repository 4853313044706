import { uploadFileSaga } from '@redux/file/uploadFileSaga';
import { uploadFileRequest } from '@redux/file/uploadFileSlice';
import { uploadFileViaFDBSaga } from '@redux/file/uploadFileViaFDBSaga';
import { uploadFileViaFDBRequest } from '@redux/file/uploadFileViaFDBSlice';
import { viewFileViaFDBSaga } from '@redux/file/viewFileViaFDBSaga';
import { viewFileViaFDBRequest } from '@redux/file/viewFileViaFDBSlice';
import { deleteFileSaga } from '@redux/file/deleteFileSaga';
import { deleteFileRequest } from '@redux/file/deleteFileSlice';
import { getFileSaga } from '@redux/file/getFileSaga';
import { getFileRequest } from '@redux/file/getFileSlice';
import { ForkEffect, takeLatest } from 'redux-saga/effects';

function* fileSagas(): Generator<ForkEffect<never>, void> {
  yield takeLatest(uploadFileRequest.type, uploadFileSaga);
  yield takeLatest(uploadFileViaFDBRequest.type, uploadFileViaFDBSaga);
  yield takeLatest(viewFileViaFDBRequest.type, viewFileViaFDBSaga);
  yield takeLatest(deleteFileRequest.type, deleteFileSaga);
  yield takeLatest(getFileRequest.type, getFileSaga);
}

export default fileSagas;
