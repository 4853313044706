import { CommonImages, LogoBigIcon } from '@assets';
import { AppButton } from '@components';
import { AppText } from '@components/AppText';
import { NOTIFICATION } from '@constant';
import { getNotificationModal } from '@redux/app/appSlice';
import { notificationModal, notificationModalContent } from '@redux/app/selectors';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { Font, FontSize } from '@theme/size';
import { NotificationHelper } from '@utils/common/notification';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, View, Image, TouchableOpacity, ScrollView } from 'react-native';
import { Button } from 'react-native-elements';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import Modal from 'react-native-modal';
import { getStatusBarHeight } from 'react-native-status-bar-height';
import { useDispatch, useSelector } from 'react-redux';
import theme, { globalStyle } from '../../theme';
import {useAppFlashMessage} from "@components/AppFlashMessage/useAppFlashMessage";

export const AppFlashMessage = () => {
  const {messages, setMessages, onSwipe, notificationModalVisible, onItemPress, dispatch} = useAppFlashMessage()

  console.log('AppFlashMessage>>>',messages);
  const renderActions = item => {
    const actions = NotificationHelper.getActionsData(item);
    return (
      <>
        {!!actions && (
          <ScrollView
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={{ flexGrow: 1, marginTop: theme.spacing.sm }}>
            <View style={[globalStyle.directionRow]}>
              {actions?.map(x => {
                // return <AppButton text={x} style={globalStyle.flex1}/>;
                return (
                  <Button
                    title={x?.title}
                    titleStyle={styles.actionButton}
                    type="outline"
                    containerStyle={{ padding: theme.spacing.sm }}
                    buttonStyle={{ borderColor: palette.ROYAL_MAIL_RED }}
                    onPress={() => {
                      dispatch(getNotificationModal(false));
                      setMessages([]);
                      NotificationHelper.openNotificationWithAction(x?.id, item);
                    }}
                  />
                );
              })}
            </View>
          </ScrollView>
        )}
      </>
    );
  };


  const renderItem = (item, index) => {
    return (
      <TouchableOpacity
        key={index}
        onPress={() => {
          // console.log('openNotification>>>>', item?.data);
          // dispatch(getNotificationModal(false));
          // setMessages([]);
          // NotificationHelper.openNotification(item?.data, 100);
          onItemPress(item)
        }}
        style={{
          backgroundColor: '#e5e7e9',
          borderRadius: 5,
          paddingHorizontal: 10,
          paddingVertical: Device.isIos ? 10 : 6,
          marginTop: index === 0 ? getStatusBarHeight() - 20 : 4,
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}>
        <View
          style={{
            // backgroundColor: '#e5e7e9',
            // borderRadius: 5,
            // paddingHorizontal: 10,
            // paddingVertical: Device.isIos ? 10 : 6,
            // marginTop: index === 0 ? getStatusBarHeight() - 20 : 4,
            flexDirection: 'row',
            // alignItems: 'center',
            // width: '100%',
          }}>
          <Image source={CommonImages.FallbackImage} style={{ width: 40, height: 40, borderRadius: 8 }} />
          {/* <AppText style={{ marginLeft: 6, color: '#999999' }}>Mailagents</AppText> */}
          <View style={{ marginLeft: 12, flex: 1 }}>
            <AppText style={{ ...Font.Bold_700, marginVertical: 2 }}>{item?.title}</AppText>
            <AppText style={{}}>{item?.body}</AppText>
          </View>
        </View>

        {renderActions(item)}
      </TouchableOpacity>
    );
  };

  return (
    <Modal
      hasBackdrop={false}
      animationIn="slideInDown"
      animationOut="slideOutUp"
      coverScreen={false}
      propagateSwipe={true}
      useNativeDriver
      swipeDirection="up"
      onSwipeComplete={() => {
        onSwipe()
      }}
      swipeThreshold={Device.isAndroid ? 90 : 70}
      isVisible={notificationModalVisible}
      style={{ justifyContent: 'flex-start',
        // maxHeight: 100,
        alignSelf: 'center', width: Device.width - 30 }}>
      <View>
        {messages?.map((item, index) => {
          return renderItem(item, index);
        })}
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalView: {
    position: 'absolute',
    top: 26,
    bottom: 26,
    left: 26,
    right: 26,
    backgroundColor: 'white',
    borderRadius: 5,
    borderWidth: 2,
    borderColor: 'red',
    padding: 35,
    alignItems: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
  group: {
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 20,
  },
  demoButton: {
    backgroundColor: '#ddd',
    alignSelf: 'auto',
    marginBottom: 9,
    marginHorizontal: 5,
  },
  actionButton: {
    color: palette.ROYAL_MAIL_RED,
    fontWeight: '400',
    fontSize: FontSize.Font16,
    fontFamily: 'arial',
    // margin: theme.spacing.sm,
    // textTransform: 'capitalize',
  },
});
