import { ArrowRightIcon, FailedIcon, LogoIcon, MessageCheckIcon, PassedIcon } from '@assets';
import { AppKAView, AppSafeAreaView, AppText } from '@components';
import GenericHeader from '@components/GenericHeader';
import { navigate } from '@routes/navigationUtils';
import { DASHBOARD_SCREEN } from '@routes/route';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font, Spacing } from '@theme/size';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, FlatList, TouchableOpacity, ScrollView } from 'react-native';
import { useListQuiz } from './ListQuiz.hook';
import { ListQuizStyle } from './ListQuiz.style';

export const ListQuizScreen = props => {
  const { t } = useTranslation();
  const { data, onItemPress } = useListQuiz(props);
  const renderItem = ({ item, index }) => {
    return (
      <View style={[globalStyle.directionRow, ListQuizStyle.viewContainer]}>
        <TouchableOpacity
          onPress={() => {
            // if (item.passed) {
            //   return;
            // }
            onItemPress(item);
          }}
          activeOpacity={0.7}
          style={[ListQuizStyle.viewSelect, globalStyle.directionRow]}>
          <View style={{ marginRight: Spacing.width6 }}>{item?.passed ? <PassedIcon /> : <FailedIcon />}</View>
          <AppText style={[globalStyle.fullWidth, ListQuizStyle.itemText]}>{item?.title}</AppText>
          {item?.passed ? <></> : <ArrowRightIcon />}
        </TouchableOpacity>
      </View>
    );
  };
  return (
    <AppSafeAreaView areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>
      <AppKAView>
        <GenericHeader
          onBackClicked={() => navigate(DASHBOARD_SCREEN)}
          title={t('LIST_QUIZ_TITLE')}
          style={[{ backgroundColor: palette.BACKGROUND_GREY }]}
        />
        <View style={[ListQuizStyle.containerView]}>
          {/*<AppText>{t('LIST_QUIZ_SUBTITLE')}</AppText>*/}
          <FlatList
            ListHeaderComponent={
              <AppText
                style={[
                  globalStyle.alignCenter,
                  globalStyle.justifyCenter,
                  RegisterFormStyles.paddingSpace,
                  RegisterFormStyles.descriptionText,
                  { marginVertical: theme.spacing.md },
                ]}>
                {t('LIST_QUIZ_SUBTITLE')}
              </AppText>
            }
            data={data}
            renderItem={renderItem}
          />
        </View>
      </AppKAView>
    </AppSafeAreaView>
  );
};
