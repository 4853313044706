import { createSlice } from '@reduxjs/toolkit';

export interface IgetListHolidaysState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IgetListHolidaysState = {
  loading: false,
  payload: null,
  error: null,
};

const getListHolidaysSlice = createSlice({
  name: 'getListHolidays',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getListHolidaysRequest(state, action) {
      state.loading = true;
      // @ts-ignore
      // state.payload = null;
      state.error = null;
    },
    getListHolidaysSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    getListHolidaysFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getListHolidaysRequest, getListHolidaysSuccess, getListHolidaysFailed } = getListHolidaysSlice.actions;

export default getListHolidaysSlice.reducer;
