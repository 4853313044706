import { Constant } from '@constant';
import { palette } from '@theme/colors';
import { Spacing } from '@theme/size';
import { StyleSheet } from 'react-native';

export const ReportTabStyle = StyleSheet.create({
  selectedButton: {
    backgroundColor: palette.ROYAL_MAIL_RED,
  },
  button: {
    minHeight: Constant.MAIN_HEIGHT,
    flex: 1,
    borderWidth: 1,
    borderColor: '#DA202A',
    overflow: 'hidden',
  },
  isLast: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    overflow: 'hidden',
  },
  isFirst: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    overflow: 'hidden',
  },
  buttonContainer: {
    // backgroundColor: 'red',
    borderColor: '#DA202A',
    borderRadius: 4,
  },
  linearGradient: {
    flex: 1,
    width: '100%',
    paddingHorizontal: Spacing.width15,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
