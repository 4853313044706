import theme from '@theme';
import { palette } from '@theme/colors';
import { FontSize, Spacing } from '@theme/size';
import { StyleSheet, Platform } from 'react-native';

export const styles = StyleSheet.create({
  areaStyle: { backgroundColor: '#EDEDED' },
  iframe: {borderWidth: 0},
  container: {
    // paddingTop: Spacing.width20,
    flex: 1,
    borderTopRightRadius: Spacing.width20,
    borderTopLeftRadius: Spacing.width20,
    backgroundColor: palette.WHITE,
    paddingBottom: 30,
  },
});

// custom attribute for web here
export const attributesForWeb = {
  height: 900
}
