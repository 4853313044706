import { Font, FontSize, Spacing } from '@theme/size';
import { StyleSheet } from 'react-native';

const AppHeaderStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: Spacing.height57,
    paddingBottom: Spacing.height24,
    paddingHorizontal: Spacing.width15,
    alignItems: 'center',
    backgroundColor: '#EDEDED',
  },
  left: {
    flex: 0.2,
  },
  center: {
    flex: 0.6,
    alignItems: 'center',
    justifyContent: 'center',
  },
  right: {
    flex: 0.2,
  },
  centerText: {
    ...Font.Bold_400,
    fontSize: FontSize.Font20,
    color: '#333333',
  },
});

export default AppHeaderStyles;
