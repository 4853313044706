import { createAction, createSlice } from '@reduxjs/toolkit';

export interface IcreateTicketState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IcreateTicketState = {
  loading: false,
  payload: null,
  error: null,
};

export const createTicketRequest = createAction('createTicket/createTicketRequest', (payload, onSuccess, onFailed) => {
  console.log('prepareAction>>>', payload, onSuccess);
  return {
    payload: payload,
    meta: onSuccess,
    error: onFailed,
  };
});

const createTicketSlice = createSlice({
  name: 'createTicket',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // createTicketRequest(state, action) {
    //   state.loading = true;
    //   // @ts-ignore
    //   state.payload = null;
    //   state.error = null;
    // },
    createTicketSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    createTicketFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { createTicketSuccess, createTicketFailed } = createTicketSlice.actions;

export default createTicketSlice.reducer;
