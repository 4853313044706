import { getFormLoading, getFormSelector } from '@redux/form/selectors';
import { RootState } from '@redux/reducers';
import { activeUserSelector, updateUserLoading, updateUserSelector } from '@redux/user/selectors';
import { createSelector } from '@reduxjs/toolkit';
import {getErrorMessage} from "@utils/api";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// export const getLoading = createSelector([getFormSelector, updateUserSelector], (l1, l2) => l1.loading || l2.loading);
export const getLoading = createSelector([getFormLoading, updateUserLoading], (l1, l2) => l1 || l2);
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const globalSelector = (state: RootState) => state.global;
export const globalError = createSelector(globalSelector, state => state.error);
export const globalErrorMsg = createSelector(globalError, state => getErrorMessage(state));
export const globalLoading = createSelector(globalSelector, state => state.loading);

