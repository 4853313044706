import { AppKAView, AppSafeAreaView, AppText } from '@components';
import GenericHeader from '@components/GenericHeader';
import { TIME_TABLE } from '@constant';
import { navigate } from '@routes/navigationUtils';
import { DASHBOARD_SCREEN } from '@routes/route';
import { globalStyle } from '@theme';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { Font, FontSize, Spacing } from '@theme/size';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {FlatList, RefreshControl, View, Platform, useWindowDimensions} from 'react-native';
import { usePackageTimeline } from './PackageTimeline.hook';
import { PackageTimelineStyle } from './PackageTimeline.style';
import _, {max} from "lodash";

const TimelineItem = ({ item, textWidth, setTextWidth, t, isLast, type }) => {
    const scaleFont = useWindowDimensions().fontScale;

    return (
      <View style={[
          {
              paddingHorizontal: Spacing.width20,
              // backgroundColor: 'yellow'
          },
          // type === TIME_TABLE.BUNDLE
          // ? { marginTop: item?.status === 'today' ? 16 : 16, marginBottom: item?.status === 'today' ? 5 : 0 }
          // : { marginTop: item?.status === 'today' ? 28 : 25, marginBottom: item?.status === 'today' ? 5 : 0 },
      ]}>
          {!_.isEmpty(item?.title) && <AppText
              style={{
                  ...Font.Bold_700,
                  textAlign: 'left',
                  color: item?.status === 'today' ? item?.color : palette.MAIN_BLACK,
                  marginBottom: 10,
                  position: !item?.isFirst ? 'absolute' : undefined,
                  transform: [{ translateY: !item?.isFirst ? -28 * scaleFont : 0 }],
                  paddingHorizontal: !item?.isFirst ? Spacing.width20 : undefined,
              }}>
              {item?.title ? `${t(item?.title)}:` : ''}
          </AppText>}
          <View
              style={[
                  globalStyle.directionRow,
                  PackageTimelineStyle.viewContainer,
                  {
                      // paddingLeft: 60,
                      // backgroundColor: 'red'
                  }
              ]}>

              <View style={{ flexDirection: 'row', flex: 1}}>
                  <AppText
                      // allowFontScaling={false}
                      numberOfLines={2}
                      style={[
                          { flex: 1, textAlign: 'right' },
                          item?.status === 'today' ? { ...Font.Bold_700, color: item?.color } : {},
                      ]}>
                      {item?.sendDateTarget}
                  </AppText>
                  <View
                      style={{
                          alignItems: 'center',
                          // position: 'relative',
                          flexDirection: 'column',
                          paddingHorizontal: 12,
                          width: 40,
                          marginTop: 5,
                          // backgroundColor: 'blue'
                      }}>
                      <View
                          style={
                              item?.status === 'today'
                                  ? { width: 10*scaleFont, height: 10*scaleFont, borderRadius: 1000, backgroundColor: item?.color }
                                  : { width: 10*scaleFont, height: 10*scaleFont, borderRadius: 1000, backgroundColor: item?.color }
                          }
                      />
                      {((type === TIME_TABLE.PACKAGE && !isLast)) && (
                          <View
                              style={{
                                  height: 40* scaleFont,
                                  width: scaleFont*1.5,
                                  backgroundColor: '#C6C6C6',
                                  // marginTop: type === TIME_TABLE.PACKAGE ? 10 : 10 * (scaleFont - 1),
                                  marginTop: 10,
                                  marginBottom:5,

                                  // transform: [{ translateY: item?.status === 'today' ? 16 : 5 }]
                              }}
                          />
                      )}
                  </View>
                  <View style={[{ flex: 1 }]}>
                      <AppText
                          // allowFontScaling={false}
                          numberOfLines={1}
                          style={[{ color: '#33333' }, item?.status === 'today' ? { ...Font.Bold_700, color: item?.color } : {}]}>
                          {item?.packageId}
                      </AppText>
                  </View>
              </View>
          </View>
      </View>

  );
};

export const PackageTimelineScreen = props => {
  const { t } = useTranslation();
  const { data, handleRefresh, refresh, type } = usePackageTimeline(props);
  console.log('TimelineScreen>>>', data)
  const [textWidth, setTextWidth] = useState(0);
  const renderItem = ({ item, index, ...rest }) => {
    return (
      <TimelineItem
        item={item}
        textWidth={textWidth}
        setTextWidth={setTextWidth}
        t={t}
        isLast={index === data.length - 1}
        type={type}
      />
    );
  };
  return (
    <AppSafeAreaView areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>
      <AppKAView>
        <GenericHeader
          onBackClicked={() => navigate(DASHBOARD_SCREEN)}
          title={t('PACKAGE_TIMELINE')}
          style={[{ backgroundColor: palette.BACKGROUND_GREY }]}
        />
        <View style={[PackageTimelineStyle.containerView]}>
          {/*<AppText>{t('LIST_QUIZ_SUBTITLE')}</AppText>*/}
          <FlatList
            data={data}
            keyExtractor={item => {
              return item?.packageId;
            }}
            renderItem={renderItem}
            refreshControl={
              <RefreshControl
                refreshing={refresh}
                onRefresh={handleRefresh}
                tintColor={palette.LOADING_COLOR}
                colors={[palette.LOADING_COLOR]}
                titleColor={palette.LOADING_COLOR}
              />
            }
          />
        </View>
      </AppKAView>
    </AppSafeAreaView>
  );
};
