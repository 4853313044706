import getSettingsSlice from '@redux/user/getSettingsSlice';
import activeUserSlice from '@redux/user/activeUserSlice';
import forgotPasswordSlice from '@redux/user/forgotPasswordSlice';
import getProfileSlice from '@redux/user/getProfileSlice';
import getUserSlice from '@redux/user/getUserSlice';
import loginSlice from '@redux/user/loginSlice';
import logoutSlice from '@redux/user/logoutSlice';
import resetPasswordSlice from '@redux/user/resetPasswordSlice';
import updateProfileSlice from '@redux/user/updateProfileSlice';
import updateUserSlice from '@redux/user/updateUserSlice';
import validateUserSlice from '@redux/user/validateUserSlice';
import unlockSkillSlice from '@redux/user/unlockSkillSlice';
import unsubscribeUserSlice from '@redux/user/unsubscribeUserSlice';
import getBonusPointListSlice from '@redux/user/getBonusPointListSlice';
import listVoucherSlice from '@redux/user/listVoucherSlice';
import redeemVoucherSlice from '@redux/user/redeemVoucherSlice';
import requestChangeEmailSlice from '@redux/user/requestChangeEmailSlice';
import changeEmailSlice from '@redux/user/changeEmailSlice';
import checkUnsubscribeConditionsSlice from '@redux/user/checkUnsubscribeConditionsSlice';
import acceptUserCdmSlice from '@redux/user/acceptUserCdmSlice';

export const userReducer = {
  validateUser: validateUserSlice,
  getSettings: getSettingsSlice,
  getUser: getUserSlice,
  updateUser: updateUserSlice,
  activeUser: activeUserSlice,
  login: loginSlice,
  logout: logoutSlice,
  forgotPassword: forgotPasswordSlice,
  resetPassword: resetPasswordSlice,
  getProfile: getProfileSlice,
  updateProfile: updateProfileSlice,
  unlockSkill: unlockSkillSlice,
  unsubscribeUser: unsubscribeUserSlice,
 getBonusPointList: getBonusPointListSlice,
 listVoucher: listVoucherSlice,
 redeemVoucher: redeemVoucherSlice,
 requestChangeEmail: requestChangeEmailSlice,
 changeEmail: changeEmailSlice,
 checkUnsubscribeConditions: checkUnsubscribeConditionsSlice,
 acceptUserCdm: acceptUserCdmSlice,
};
