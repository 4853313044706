import {createAction, createSlice} from '@reduxjs/toolkit';

export interface IdeleteHolidayState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IdeleteHolidayState = {
  loading: false,
  payload: null,
  error: null,
};

export const deleteHolidayRequest = createAction(
    'deleteHoliday/deleteHolidayRequest',
    (payload, onSuccess, onFailed) => {
      console.log('prepareAction>>>', payload, onSuccess);
      return {
        payload: payload,
        meta: onSuccess,
        error: onFailed,
      };
    },
);

const deleteHolidaySlice = createSlice({
  name: 'deleteHoliday',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // deleteHolidayRequest(state, action) {
    //   state.loading = true;
    //   // @ts-ignore
    //   state.payload = null;
    //   state.error = null;
    // },
    deleteHolidaySuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    deleteHolidayFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { deleteHolidaySuccess, deleteHolidayFailed } = deleteHolidaySlice.actions;

export default deleteHolidaySlice.reducer;
