import api from '@services/api-client';
import { stringify } from 'qs';
import withQuery from 'with-query';

export const createTicketService = async payload => {
  console.log('createTicketService>>>', payload)
  const response = await api.post(`/tickets.json`, payload)
  console.log('createTicketService response>>>', response)
  return response?.data
}

export const getListTicketService = async payload => {
  console.log('getListTicketService>>>', payload)
  const response = await api.get(`/tickets.json?${stringify(payload)}`)
  console.log('getListTicketService response>>>', response)

  return response?.data
}

export const getTicketDetailService = async payload => {
  console.log('getTicketDetailService>>>', payload)
  const response = await api.get(`/tickets/${payload?._id}.json`)
  console.log('getTicketDetailService response>>>', response)

  return response?.data
}

export const getListStatusService = async payload => {
  console.log('getListStatusService>>>', payload)
  const response = await api.get(`/tickets/status.json?${stringify(payload)}`)
  console.log('getListStatusService response>>>', response)

  return response?.data
}

export const getListSubjectService = async payload => {
  console.log('getListSubjectService>>>', payload)
  const response = await api.get(`/tickets/subjects.json?${stringify(payload)}`)
  console.log('getListSubjectService response>>>', response)

  return response?.data
}

export const addCommentService = async payload => {
  console.log('addCommentService>>>', payload)
  const response = await api.post(`/tickets/${payload.ticketId}/comment.json?`, payload)
  console.log('addCommentService response>>>', response)
  return response?.data
}

