import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppKAView, AppSafeAreaView, AppText } from '@components';
import GenericHeader from '@components/GenericHeader';
import { goBack, popToTop } from '@routes/navigationUtils';
import { ReportTab } from '@screens/main/report/Tab';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font } from '@theme/size';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Text } from 'react-native';
import { useReport } from './Report.hook';
import { ReportStyle } from './Report.style';

export const ReportScreen = props => {
  const { t } = useTranslation();
  const {} = useReport(props);
  return (
    <>
      <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
      <AppSafeAreaView areaStyle={[{ backgroundColor: '#EDEDED' }]}>
        <GenericHeader
          onBackClicked={popToTop}
          title={t('REPORT_HOLIDAY_HEADER')}
          style={[{ backgroundColor: '#EDEDED' }]}
        />
        <View style={[globalStyle.directionColumm, globalStyle.flex1, ReportStyle.containerView]}>
          <ReportTab />
        </View>
      </AppSafeAreaView>
    </>
  );
};
