import { VoucherMockData } from '@constant/model/mock/reward';
import { getBonusPointListRequest, refreshBonusPointList } from '@redux/user/getBonusPointListSlice';
import { listVoucherRequest } from '@redux/user/listVoucherSlice';
import { redeemVoucherRequest } from '@redux/user/redeemVoucherSlice';
import { listVoucherCreator } from '@redux/user/selectors';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Linking } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {goBack, navigate, popToTop} from "@routes/navigationUtils";
import {Screens} from "@routes/route";

export const useVoucher = props => {
  const dispatch = useDispatch();
  const listVoucherData = useSelector(listVoucherCreator)?.data;

  const [bonusInfo, setBonusInfo] = useState({});
  const [data, setData] = useState(_.cloneDeep(VoucherMockData.data));
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const onItemPress = item => {};
  const onClickHref = x => {
    console.log('AppNoteView>>>', x);
    Linking.openURL(x);
  };

  const listVoucherApi = () => {
    const payload = {};
    dispatch(
      listVoucherRequest(
        payload,
        rs => {
          setBonusInfo(rs?.data?.bonusInfo);
          // setBonusInfo({
          //   ...rs?.data?.bonusInfo,
          //   description: 'Below, you can find a list of shops that will accept our vouchers. To redeem your voucher, please click on “Redeem Voucher”. You will receive an email with your voucher and instructions on how to use it.',
          // });

          setData(rs?.data?.voucherList);
        },
        err => {},
      ),
    );
  };

  const redeemVoucherApi = () => {
    const payload = {};
    setShowConfirmModal(false);

    dispatch(
      redeemVoucherRequest(
        payload,
        rs => {
          // setShowConfirmModal(false);
          listVoucherApi();
          dispatch(refreshBonusPointList());
          // setShowSuccessModal(true);
          navigate(Screens.SUCCESSFUL_SCREEN, {
            data: rs?.message,
            header: {
              // title: headerTitle,
              backClick: () => {
                goBack();
              },
            },
          });
        },
        err => {
          // setShowConfirmModal(false);
        },
      ),
    );
  };

  useEffect(() => {
    listVoucherApi();
  }, []);

  const onRedeemPress = () => {
    // redeemVoucherApi();
    // if (!listVoucherData?.bonusInfo?.canRedeem) {
    //   return;
    // }

    setShowConfirmModal(true);
  };

  const onYesConfirm = () => {
    redeemVoucherApi();
    // setShowConfirmModal(false);
  };

  const onNoConfirm = () => {
    setShowConfirmModal(false);
    // setShowSuccessModal(true)
  };

  const onRefresh = () => {
    listVoucherApi();
  };

  useEffect(() => {}, [dispatch]);
  return {
    bonusInfo,
    data,
    onItemPress,
    onClickHref,
    onRedeemPress,
    showConfirmModal,
    onYesConfirm,
    onNoConfirm,
    showSuccessModal,
    setShowSuccessModal,
    refreshing,
    onRefresh,
  };
};
