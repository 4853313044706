import theme from '@theme';
import { Device } from '@theme/device';
import { Font, FontSize } from '@theme/size';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  headerContainer: {
    backgroundColor: theme.colors.headerBackground,
    paddingHorizontal: theme.spacing.lg,
    zIndex: 0,
  },
  HeaderShadow: {
    elevation: 3,
    shadowColor: theme.colors.cardShadow,
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.7,
    shadowRadius: 3,
    zIndex: 1,
  },
  backButtonContainer: {
    marginLeft: -9,
    paddingVertical: 10,
    paddingRight: 10,
  },
  // backButtonStyle: {
  //   color: theme.colors.backButtonText,
  //   fontFamily: fonts.boldOS,
  //   fontSize: 18,
  // },
  backButtonIcon: {
    color: theme.colors.primary,
    marginRight: 8,
    fontSize: 24,
  },
  bodyContainer: {
    alignItems: 'center',
    flex: 1,
  },
  mainPageTitle: {
    ...Font.Bold_400,
    fontSize: FontSize.Font20,
    lineHeight: 24,
    textAlign: 'center',
    width: Device.width - 90,
  },
  BodyContent: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    width: '100%',
  },
});

export default styles;
