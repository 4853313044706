import { StyleSheet } from 'react-native'
import theme from "@theme";

export const UnsureDateListStyle = StyleSheet.create({
    accordion: {
        width: '100%',
        marginVertical: 4,
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 12,
        paddingRight: 15,
        borderRadius: 3,
        borderColor: '#C6C6C6',
        backgroundColor: '#E3E3E3',
    },
    accordionItem: {
        flexDirection: 'row',
        backgroundColor: '#FFDB80',
        width: '100%',
        paddingVertical: 12,
        // paddingHorizontal: 15,
        // width: 100,
        borderRadius: 3,
        marginTop: 15,
    },
    accordionItemChild: {
        flexDirection: 'column',
        // width: '100%',
        flex:1,
        paddingVertical: 12,
        paddingLeft: 15,
        // width: 100,
        borderRadius: 3,
        // marginTop: 15,
    },
    editView:{
        // backgroundColor: 'white',
        borderRadius: 3,
        margin:2,
        // flex:1,
        maxHeight:40,
        minWidth: 40,
        // aspectRatio:1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    editViewWeb: {
        // backgroundColor: 'white',
        borderRadius: 3,
        margin: 2,
        marginLeft:theme.spacing.sm,
        // flex: 1,
        maxHeight: 40,
        height: '100%',
        // maxWidth:40,
        aspectRatio: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    editViewWeb: {
        backgroundColor: 'white',
        borderRadius: 3,
        margin: 2,
        marginLeft:theme.spacing.sm,
        // flex: 1,
        maxHeight: 40,
        height: '100%',
        // maxWidth:40,
        aspectRatio: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    editContainer:{
        marginLeft: theme.spacing.sm,
        height:'100%',
        flexDirection: 'column',
        alignItems: 'center',
    },
})
