import { MessageCheckIcon, CrossIcon, WarningIcon } from '@assets';
import { AppAlert, AppText, AppButton } from '@components';
import { ForceUpdateStyles } from '@components/AppForceUpdate/ForceUpdate.style';
import { PERMISSION_MODEL } from '@constant';
import { styles } from '@screens/main/profile/ProfileEdit/ProfileEdit.style';
import { AppHtmlView } from '@screens/main/quiz/QuizFailed/components/AppNote.view';
import theme, {globalStyle, WEB_WIDTH} from '@theme';
import { deviceWidth, Font, FontSize, Spacing } from '@theme/size';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, View, TouchableOpacity, ScrollView } from 'react-native';
import { useNewsModal } from './hook';

export const NewsModal = props => {
  const { show } = props;
  const { handlePressButton, handleHideModal, modalData } = useNewsModal(props);
  const { t } = useTranslation();

  // const { title, desc } = text;

  return (
    <AppAlert
      onDismiss={handleHideModal}
      closeOnTouchOutside
      show={show}
      contentContainerStyle={{
        maxWidth: '100%',
        // borderWidth: 0,
        // borderRadius: 8,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
      }}
      customView={
        <View
          style={[
            // globalStyle.directionColumm,
            {
              borderRadius: 10,
              marginVertical: -8,
              // width: '100%',
                width:  Platform.OS === 'web' ? WEB_WIDTH - theme.spacing.md * 2 : deviceWidth - theme.spacing.md * 2,

              // paddingHorizontal: 0,
              marginHorizontal: 0,
                // backgroundColor: 'green',
              overflow: 'hidden',
            },
          ]}>
          <View
            style={{
              // borderTopRightRadius: 8,
              // borderTopLeftRadius: 8,
              flexDirection: 'column',
              backgroundColor: '#f7f7f7',

              // width: '100%'
            }}>
            <AppHtmlView
              style={{
                fontSize: FontSize.Font14,
                // paddingVertical: 8,
                // textAlign: 'center',
                // padding: theme.spacing.sm,
                paddingHorizontal: theme.spacing.md,
                paddingVertical: 10,
                color: 'black',
                ...Font.Bold_700,
              }}
              text={modalData?.title}
            />
          </View>
          <View
            style={{
              // padding: theme.spacing.md,
              backgroundColor: 'white',
            }}>
            <ScrollView
              style={{
                maxHeight: 300,
                backgroundColor: 'white',

                // padding: theme.spacing.md
                // marginBottom: 10,
              }}>
              <View
                style={[
                  globalStyle.directionColumm,
                  {
                    backgroundColor: 'white',
                    padding: theme.spacing.md,
                  },
                ]}>
                {/*<AppText style={[Font.Bold_700, globalStyle.ce]}>{title}</AppText>*/}

                {/*<AppText style={[Font.Bold_700, globalStyle.ce]}>{title}</AppText>*/}
                <AppHtmlView
                  style={{
                    fontSize: FontSize.Font14,
                    // paddingVertical: 8,
                    // textAlign: 'center',
                    color: '#666666',
                  }}
                  text={modalData?.description}
                />
              </View>
            </ScrollView>
          </View>
        </View>
      }
    />
  );
};
