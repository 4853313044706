import { getFileService } from '@services/file'

// @ts-ignore
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { getFileFailed, getFileSuccess } from './getFileSlice';
import { Constant } from "@constant";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* getFileSaga({ payload }): Generator<
  | CallEffect
  | PutEffect<{
      payload: any;
      type: string;
    }>,
  void
> {
  try {
    const res = yield call(getFileService, payload);
    console.log('getFileSaga>>>>', res);
    if (!isEmpty(res)) {
      yield put(getFileSuccess(res));
    } else {
      // @ts-ignore
      yield put(getFileFailed(Constant.ERROR_NO_RESPONSE));
    }
  } catch (err) {
    // @ts-ignore
    yield put(getFileFailed(err));
  }
}
