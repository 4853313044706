import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import {Screens} from "@routes/route";

export const useReward = props => {
    const dispatch = useDispatch();
    const initialRouteName = props?.route?.params?.tabScreen ?? Screens.VOUCHER;
    const initialParams = props?.route?.params?.params ?? {};

    console.log('useTicket>>>', props?.route?.params, initialRouteName);
    return { initialRouteName, initialParams };
}
