import { LogoIcon, MailIcon, MessageCheckIcon } from '@assets';
import { AppSafeAreaView, AuthFooter, AppButton, AppInput, AppKAView, AppText, AppAlert } from '@components';
import { ForceUpdateStyles } from '@components/AppForceUpdate/ForceUpdate.style';
import theme, { globalStyle } from '@theme';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { Font, FontSize } from '@theme/size';
import { StringHelper } from '@utils/common/string';
import { useKeyboard } from '@utils/hooks';
import { t } from 'i18next';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useLogic } from './ForgotSendEmail.hook';
import { styles } from './ForgotSendEmail.style';

export const ForgotSendEmail = () => {
  const { handleLoginPress, handleSend, modalShow, formik } = useLogic();
  const { t } = useTranslation();
  const KAView = Device.isIos ? AppKAView : Fragment;
  const KAScrollView = Device.isIos ? ScrollView : KeyboardAwareScrollView;
  const keyboardShow = useKeyboard();

  return (
    <AppSafeAreaView>
      <KAView>
        <View style={styles.container}>
          <KAScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={styles.form}>
            <View style={styles.logo}>
              <LogoIcon />
            </View>
            <View>
              <AppText style={styles.signUpDesc}>{t('FORGOT_DESC')}</AppText>
              <AppInput
                // onChangeText={formik.handleChange('username')}
                onChangeText={text => {
                  formik.setFieldValue('username', StringHelper.removeSpace(text));
                }}
                value={formik.values.username}
                error={formik.errors.username}
                placeholder={t('EMAIL')}
                PreIcon={MailIcon}
                style={styles.mb20}
              />
            </View>
          </KAScrollView>
          <AppButton onPress={formik.handleSubmit} text={t('SEND')} />
        </View>
      </KAView>
      {!keyboardShow && (
        <>
          <View style={styles.signUpBox}>
            <AppText style={styles.signUpText}>{t('ALREADY_HAVE_AN_ACCOUNT')}</AppText>
            <AppText onPress={handleLoginPress} style={styles.highlightText}>
              {t('LOGIN')}
            </AppText>
          </View>
          <AuthFooter />
        </>
      )}
      <AppAlert
        show={modalShow}
        closeOnTouchOutside={false}
        contentContainerStyle={{ maxWidth: '92%' }}
        customView={
          <View style={[ForceUpdateStyles.rootView]}>
            <View style={[globalStyle.directionColumm, { marginTop: 24 }]}>
              <View style={[globalStyle.center, { paddingTop: 16 }]}>
                <MessageCheckIcon color={palette.GREEN_ACTIVE} width={45} height={45} />
              </View>
              <View style={[globalStyle.center, { paddingVertical: theme.spacing.lg }]}>
                <AppText style={[Font.Bold_700, globalStyle.center]}>{t('SUBMITTED_SUCCESSFULLY')}</AppText>
                <AppText
                  style={[
                    {
                      fontSize: FontSize.Font15,
                      paddingVertical: 8,
                      marginHorizotal: 16,
                      textAlign: 'center',
                      color: '#666666',
                    },
                  ]}>
                  {t('FORGOT_MODAL_DESC')}
                </AppText>
              </View>
            </View>
          </View>
        }
      />
    </AppSafeAreaView>
  );
};
