/* eslint-disable */
/**************** DO NOT TOUCH *****************/
/* This file gets generated by 'env:*' scripts */
/***********************************************/

export default {
  "API_URL": "https://api.spectos.com/ttm-service",
  "spectos-apikey": "ba2abe97-1969-11ed-a237-0692e6d949ba",
  "ENV": "production"
};
