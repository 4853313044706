import { get_languagesRequest } from '@redux/language/get_languagesSlice';
import api from '@services/api-client';
import i18next from 'i18next';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export const useLanguage = props => {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);

  const get_languagesApi = () => {
    const payload = {};
    dispatch(
      get_languagesRequest(
        payload,
        rs => {
          const appLang = rs?.appLang;
          // console.log('get_languagesRequest>>', appLang);
          if (_.isEmpty(appLang)) {
            return;
          }

          for (const appLangKey in appLang) {
            // console.log('addResourceBundle>>>', appLangKey, appLang[appLangKey]);
            i18next.addResourceBundle(appLangKey, 'translation', appLang[appLangKey], true, true);
          }
          setIsLoaded(true);
        },
        err => {},
      ),
    );
  };
  useEffect(() => {
    get_languagesApi();
  }, []);
  return { isLoaded };
};
