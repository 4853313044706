export const TicketMockData = {
  status: true,
  message: {
    description: 'Success',
  },
  data: {
    _id: '6317119c7b4726093194638c',
    owner: {
      subAccountIds: [],
      accountId: '62f4cd32-6470-43b3-bf06-67c30a01011a',
    },
    resources: [],
    attachments: [
      {
        name: '57009015_1995644750745222_2051888778481500160_n.jpg',
        url: '63171eafb71848e59412141d0a01031a?path=',
        type: 'image/jpeg',
        meta: {
          name: '57009015_1995644750745222_2051888778481500160_n.jpg',
          type: 'image/jpeg',
          size: 3205,
          id: '57009015_1995644750745222_2051888778481500160_n.jpg-att=image/jpeg',
        },
        from: 'upload',
        source: 'to-customer',
        uploadedAt: '2022-09-06T10:19:28.236Z',
        uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
      },
      {
        name: 'ameos.png',
        url: '63171f2fc9084e97853a38fc0a01031a?path=',
        type: 'image/png',
        meta: {
          name: 'ameos.png',
          type: 'image/png',
          tmp_name: '/tmp/phpmUrTkb',
          error: 0,
          size: 13757,
        },
        from: 'upload',
        source: null,
        uploadedAt: '2022-09-06T10:21:36.100Z',
        uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
      },
      {
        name: '20220826_190845_member_list.csv',
        url: '631890597a7848c784512a440a01031a?path=',
        type: 'text/csv',
        meta: {
          name: '20220826_190845_member_list.csv',
          type: 'text/csv',
          tmp_name: '/tmp/phpRkzVz0',
          error: 0,
          size: 70,
        },
        from: 'upload',
        source: null,
        uploadedAt: '2022-09-07T12:36:42.799Z',
        uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
      },
      {
        name: 'Flag_of_England.svg.png',
        url: '631890efef204657bf8b2cd00a01031a?path=',
        type: 'image/png',
        meta: {
          name: 'Flag_of_England.svg.png',
          type: 'image/png',
          tmp_name: '/tmp/phpXZeFbQ',
          error: 0,
          size: 725,
        },
        from: 'upload',
        source: null,
        uploadedAt: '2022-09-07T12:39:13.456Z',
        uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
      },
      {
        name: 'ttm.postman_collection.json',
        url: '6318910cd14c46aebcc02d5e0a01031a?path=',
        type: 'application/json',
        meta: {
          name: 'ttm.postman_collection.json',
          type: 'application/json',
          tmp_name: '/tmp/phpJfdqiW',
          error: 0,
          size: 604454,
        },
        from: 'upload',
        source: null,
        uploadedAt: '2022-09-07T12:39:41.851Z',
        uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
      },
      {
        name: 'FAQ (1).jpeg',
        url: '631891101d284588a38a2d940a01031a?path=',
        type: 'image/jpeg',
        meta: {
          name: 'FAQ (1).jpeg',
          type: 'image/jpeg',
          tmp_name: '/tmp/phpTT5P55',
          error: 0,
          size: 2697,
        },
        from: 'upload',
        source: null,
        uploadedAt: '2022-09-07T12:39:45.751Z',
        uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
      },
      {
        name: 'Flag_of_England.svg.png',
        url: '6318911f31c84fe394c32d5e0a01031a?path=',
        type: 'image/png',
        meta: {
          name: 'Flag_of_England.svg.png',
          type: 'image/png',
          tmp_name: '/tmp/phpRY50FD',
          error: 0,
          size: 725,
        },
        from: 'upload',
        source: null,
        uploadedAt: '2022-09-07T12:40:01.036Z',
        uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
      },
      {
        name: 'ODEG3 (1).jpg',
        url: '631891269fd840c6a7512d5d0a01031a?path=',
        type: 'image/jpeg',
        meta: {
          name: 'ODEG3 (1).jpg',
          type: 'image/jpeg',
          tmp_name: '/tmp/phpxKNu2j',
          error: 0,
          size: 74721,
        },
        from: 'upload',
        source: null,
        uploadedAt: '2022-09-07T12:40:07.810Z',
        uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
      },
    ],
    links: [],
    watchers: ['5e6a28ac-f72c-49f3-9489-0ae20a01011a'],
    watcherGroups: [],
    tags: [],
    teamsFbDone: [],
    additionalProperties: [
      {
        name: 'panelistType',
        title: 'Type',
        resourceId: [],
        value: [],
      },
      {
        name: 'panelistId',
        title: 'Panelist Id',
        resourceId: '',
        value: '62f2101ffca0847e9bec5870',
      },
      {
        name: 'icode',
        title: 'i.Code',
        resourceId: '',
        value: '',
      },
      {
        name: 'linkId',
        title: 'External ID',
        resourceId: '',
        value: '',
      },
      {
        name: 'bundleId',
        title: 'Bundle Id',
        resourceId: '',
        value: '',
      },
      {
        name: 'panelistName',
        title: 'Name',
        resourceId: '',
        value: '',
      },
      {
        name: 'panelistLanguage',
        title: 'Language',
        resourceId: '',
        value: '',
      },
      {
        name: 'panelistEmail',
        title: 'Email',
        resourceId: '',
        value: '',
      },
      {
        name: 'panelistPhone',
        title: 'Phone',
        resourceId: '',
        value: '',
      },
      {
        name: 'panelistAvailability',
        title: 'Availability',
        resourceId: '',
        value: '',
      },
      {
        name: 'panelistSuspensionTime',
        title: 'Suspension Time',
        resourceId: '',
        value: '',
      },
      {
        name: 'panelistBonus',
        title: 'Bonus',
        resourceId: '',
        value: '',
      },
      {
        name: 'username',
        title: 'Username',
        resourceId: '',
        value: '',
      },
      {
        name: 'address',
        title: 'Address',
        resourceId: '',
        value: '',
      },
      {
        name: 'city',
        title: 'City',
        resourceId: '',
        value: '',
      },
      {
        name: 'taskId',
        title: 'Task ID',
        resourceId: '',
        value: '',
      },
      {
        name: 'incrementalID',
        value: 25,
        main: 25,
      },
    ],
    history: [
      {
        name: 'Manual ticket creation',
        changedAt: '2022-09-06T09:23:40.373Z',
        newValue: 'New ticket',
        user: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
      },
      {
        name: 'silentTicket',
        changedAt: '2022-09-06T09:23:40.374Z',
        newValue: false,
        user: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
      },
      {
        name: 'comment-added',
        changedAt: '2022-09-06T09:32:40.768Z',
        value: {
          attachTicket: false,
          attachmentToCustomer: [],
          author: {
            fullname: 'Panelmanagement',
            name: 'Panelmanagement',
            email: 'deployer+panelmanagement@spectos.com',
            id: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
            timezone: 'Europe/Berlin',
            from: 'internal',
          },
          content: 'the second comment from app',
          dataSystemExternal: [],
          emailSubject: '',
          generateLetter: false,
          modernView: false,
          sytemExternalData: [],
          notifyExternalEmails: [],
          notifyParticipants: false,
          sentToCustomer: false,
          signature: false,
          userTags: [],
          type: 'external',
          visibleTo: ['5e6a28ac-f72c-49f3-9489-0ae20a01011a'],
        },
        user: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
        user_type: false,
      },
      {
        name: 'comment-added',
        changedAt: '2022-09-06T09:32:43.137Z',
        value: {
          attachTicket: false,
          attachmentToCustomer: [],
          author: {
            fullname: 'Panelmanagement',
            name: 'Panelmanagement',
            email: 'deployer+panelmanagement@spectos.com',
            id: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
            timezone: 'Europe/Berlin',
            from: 'internal',
          },
          content: 'the second comment from app',
          dataSystemExternal: [],
          emailSubject: '',
          generateLetter: false,
          modernView: false,
          sytemExternalData: [],
          notifyExternalEmails: [],
          notifyParticipants: false,
          sentToCustomer: false,
          signature: false,
          userTags: [],
          type: 'external',
          visibleTo: ['5e6a28ac-f72c-49f3-9489-0ae20a01011a'],
        },
        user: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
        user_type: false,
      },
      {
        name: 'attachments',
        oldValue: [],
        newValue: [
          {
            name: '57009015_1995644750745222_2051888778481500160_n.jpg',
            url: '63171eafb71848e59412141d0a01031a?path=',
            type: 'image/jpeg',
            meta: {
              name: '57009015_1995644750745222_2051888778481500160_n.jpg',
              type: 'image/jpeg',
              size: 3205,
              id: '57009015_1995644750745222_2051888778481500160_n.jpg-att=image/jpeg',
            },
            from: 'upload',
            source: 'to-customer',
            uploadedAt: '2022-09-06T10:19:28.236Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
        ],
        changedAt: '2022-09-06T10:19:28.811Z',
        user: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
        user_type: false,
      },
      {
        name: 'comment-added',
        changedAt: '2022-09-06T10:19:29.162Z',
        value: {
          content:
            '<p>aaa</p><p><br><img class="sp-image-drag" style="height: 91px;width: 149px;" id="link-63171eafb71848e59412141d0a01031a?path=-att=image/jpeg-att=57009015_1995644750745222_2051888778481500160_n.jpg" src="https://rtpm-stage.spectos.com/files/view/63171eafb71848e59412141d0a01031a?path="></p><p>bbbb</p>',
          emailSubject: '[ID#25] New message from mailagent.es',
          author: {
            name: 'Panelmanagement',
            username: null,
            email: 'deployer+panelmanagement@spectos.com',
            timezone: 'Europe/Berlin',
            from: 'internal',
          },
          notifyParticipants: false,
          visibleTo: ['5e6a28ac-f72c-49f3-9489-0ae20a01011a'],
          attachTicket: false,
          attachmentToCustomer: [],
          notifyExternalEmails: [],
          userTags: [],
          sytemExternalData: [],
          dataSystemExternal: [],
          type: 'external',
          generateLetter: false,
          sentToCustomer: false,
          signature: false,
          modernView: false,
        },
        user: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
        user_type: false,
      },
      {
        name: 'attachments',
        oldValue: [
          {
            name: '57009015_1995644750745222_2051888778481500160_n.jpg',
            url: '63171eafb71848e59412141d0a01031a?path=',
            type: 'image/jpeg',
            meta: {
              name: '57009015_1995644750745222_2051888778481500160_n.jpg',
              type: 'image/jpeg',
              size: 3205,
              id: '57009015_1995644750745222_2051888778481500160_n.jpg-att=image/jpeg',
            },
            from: 'upload',
            source: 'to-customer',
            uploadedAt: '2022-09-06T10:19:28.236Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
        ],
        newValue: [
          {
            name: '57009015_1995644750745222_2051888778481500160_n.jpg',
            url: '63171eafb71848e59412141d0a01031a?path=',
            type: 'image/jpeg',
            meta: {
              name: '57009015_1995644750745222_2051888778481500160_n.jpg',
              type: 'image/jpeg',
              size: 3205,
              id: '57009015_1995644750745222_2051888778481500160_n.jpg-att=image/jpeg',
            },
            from: 'upload',
            source: 'to-customer',
            uploadedAt: '2022-09-06T10:19:28.236Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'ameos.png',
            url: '63171f2fc9084e97853a38fc0a01031a?path=',
            type: 'image/png',
            meta: {
              name: 'ameos.png',
              type: 'image/png',
              tmp_name: '/tmp/phpmUrTkb',
              error: 0,
              size: 13757,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-06T10:21:36.100Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
        ],
        changedAt: '2022-09-06T10:21:36.470Z',
        user: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
        user_type: false,
      },
      {
        name: 'attachments',
        oldValue: [
          {
            name: '57009015_1995644750745222_2051888778481500160_n.jpg',
            url: '63171eafb71848e59412141d0a01031a?path=',
            type: 'image/jpeg',
            meta: {
              name: '57009015_1995644750745222_2051888778481500160_n.jpg',
              type: 'image/jpeg',
              size: 3205,
              id: '57009015_1995644750745222_2051888778481500160_n.jpg-att=image/jpeg',
            },
            from: 'upload',
            source: 'to-customer',
            uploadedAt: '2022-09-06T10:19:28.236Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'ameos.png',
            url: '63171f2fc9084e97853a38fc0a01031a?path=',
            type: 'image/png',
            meta: {
              name: 'ameos.png',
              type: 'image/png',
              tmp_name: '/tmp/phpmUrTkb',
              error: 0,
              size: 13757,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-06T10:21:36.100Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
        ],
        newValue: [
          {
            name: '57009015_1995644750745222_2051888778481500160_n.jpg',
            url: '63171eafb71848e59412141d0a01031a?path=',
            type: 'image/jpeg',
            meta: {
              name: '57009015_1995644750745222_2051888778481500160_n.jpg',
              type: 'image/jpeg',
              size: 3205,
              id: '57009015_1995644750745222_2051888778481500160_n.jpg-att=image/jpeg',
            },
            from: 'upload',
            source: 'to-customer',
            uploadedAt: '2022-09-06T10:19:28.236Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'ameos.png',
            url: '63171f2fc9084e97853a38fc0a01031a?path=',
            type: 'image/png',
            meta: {
              name: 'ameos.png',
              type: 'image/png',
              tmp_name: '/tmp/phpmUrTkb',
              error: 0,
              size: 13757,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-06T10:21:36.100Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: '20220826_190845_member_list.csv',
            url: '631890597a7848c784512a440a01031a?path=',
            type: 'text/csv',
            meta: {
              name: '20220826_190845_member_list.csv',
              type: 'text/csv',
              tmp_name: '/tmp/phpRkzVz0',
              error: 0,
              size: 70,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:36:42.799Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
        ],
        changedAt: '2022-09-07T12:36:42.086Z',
        user: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
        user_type: false,
      },
      {
        name: 'comment-added',
        changedAt: '2022-09-07T12:36:59.737Z',
        value: {
          content:
            '<p>qwqe&nbsp;<span id="selectionBoundary_1662554209480_5251013741560251" class="rangySelectionBoundary">\ufeff<a class="spectos-link file-size-70" href="/files/view/631890597a7848c784512a440a01031a?path=&amp;download=true" id="link-631890597a7848c784512a440a01031a?path=filetype=text/csv" target="_blank">20220826_190845_member_list.csv</a>&nbsp;qưewqeeqqe</span><br></p>',
          emailSubject: '[ID#25] New message from mailagent.es',
          author: {
            name: 'Panelmanagement',
            username: null,
            email: 'deployer+panelmanagement@spectos.com',
            timezone: 'Europe/Berlin',
            from: 'internal',
          },
          notifyParticipants: false,
          visibleTo: ['5e6a28ac-f72c-49f3-9489-0ae20a01011a'],
          attachTicket: false,
          attachmentToCustomer: [],
          notifyExternalEmails: [],
          userTags: [],
          sytemExternalData: [],
          dataSystemExternal: [],
          type: 'external',
          generateLetter: false,
          sentToCustomer: false,
          signature: false,
          modernView: false,
        },
        user: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
        user_type: false,
      },
      {
        name: 'attachments',
        oldValue: [
          {
            name: '57009015_1995644750745222_2051888778481500160_n.jpg',
            url: '63171eafb71848e59412141d0a01031a?path=',
            type: 'image/jpeg',
            meta: {
              name: '57009015_1995644750745222_2051888778481500160_n.jpg',
              type: 'image/jpeg',
              size: 3205,
              id: '57009015_1995644750745222_2051888778481500160_n.jpg-att=image/jpeg',
            },
            from: 'upload',
            source: 'to-customer',
            uploadedAt: '2022-09-06T10:19:28.236Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'ameos.png',
            url: '63171f2fc9084e97853a38fc0a01031a?path=',
            type: 'image/png',
            meta: {
              name: 'ameos.png',
              type: 'image/png',
              tmp_name: '/tmp/phpmUrTkb',
              error: 0,
              size: 13757,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-06T10:21:36.100Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: '20220826_190845_member_list.csv',
            url: '631890597a7848c784512a440a01031a?path=',
            type: 'text/csv',
            meta: {
              name: '20220826_190845_member_list.csv',
              type: 'text/csv',
              tmp_name: '/tmp/phpRkzVz0',
              error: 0,
              size: 70,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:36:42.799Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
        ],
        newValue: [
          {
            name: '57009015_1995644750745222_2051888778481500160_n.jpg',
            url: '63171eafb71848e59412141d0a01031a?path=',
            type: 'image/jpeg',
            meta: {
              name: '57009015_1995644750745222_2051888778481500160_n.jpg',
              type: 'image/jpeg',
              size: 3205,
              id: '57009015_1995644750745222_2051888778481500160_n.jpg-att=image/jpeg',
            },
            from: 'upload',
            source: 'to-customer',
            uploadedAt: '2022-09-06T10:19:28.236Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'ameos.png',
            url: '63171f2fc9084e97853a38fc0a01031a?path=',
            type: 'image/png',
            meta: {
              name: 'ameos.png',
              type: 'image/png',
              tmp_name: '/tmp/phpmUrTkb',
              error: 0,
              size: 13757,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-06T10:21:36.100Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: '20220826_190845_member_list.csv',
            url: '631890597a7848c784512a440a01031a?path=',
            type: 'text/csv',
            meta: {
              name: '20220826_190845_member_list.csv',
              type: 'text/csv',
              tmp_name: '/tmp/phpRkzVz0',
              error: 0,
              size: 70,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:36:42.799Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'Flag_of_England.svg.png',
            url: '631890efef204657bf8b2cd00a01031a?path=',
            type: 'image/png',
            meta: {
              name: 'Flag_of_England.svg.png',
              type: 'image/png',
              tmp_name: '/tmp/phpXZeFbQ',
              error: 0,
              size: 725,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:39:13.456Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
        ],
        changedAt: '2022-09-07T12:39:12.880Z',
        user: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
        user_type: false,
      },
      {
        name: 'comment-added',
        changedAt: '2022-09-07T12:39:16.836Z',
        value: {
          content:
            '<p><span><a class="spectos-link file-size-725" href="/files/view/631890efef204657bf8b2cd00a01031a?path=&amp;download=true" id="link-631890efef204657bf8b2cd00a01031a?path=filetype=image/png" target="_blank">Flag_of_England.svg.png</a>&nbsp;sdsaasdad</span><br></p>',
          emailSubject: '[ID#25] New message from mailagent.es',
          author: {
            name: 'Panelmanagement',
            username: null,
            email: 'deployer+panelmanagement@spectos.com',
            timezone: 'Europe/Berlin',
            from: 'internal',
          },
          notifyParticipants: false,
          visibleTo: ['5e6a28ac-f72c-49f3-9489-0ae20a01011a'],
          attachTicket: false,
          attachmentToCustomer: [],
          notifyExternalEmails: [],
          userTags: [],
          sytemExternalData: [],
          dataSystemExternal: [],
          type: 'external',
          generateLetter: false,
          sentToCustomer: false,
          signature: false,
          modernView: false,
        },
        user: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
        user_type: false,
      },
      {
        name: 'attachments',
        oldValue: [
          {
            name: '57009015_1995644750745222_2051888778481500160_n.jpg',
            url: '63171eafb71848e59412141d0a01031a?path=',
            type: 'image/jpeg',
            meta: {
              name: '57009015_1995644750745222_2051888778481500160_n.jpg',
              type: 'image/jpeg',
              size: 3205,
              id: '57009015_1995644750745222_2051888778481500160_n.jpg-att=image/jpeg',
            },
            from: 'upload',
            source: 'to-customer',
            uploadedAt: '2022-09-06T10:19:28.236Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'ameos.png',
            url: '63171f2fc9084e97853a38fc0a01031a?path=',
            type: 'image/png',
            meta: {
              name: 'ameos.png',
              type: 'image/png',
              tmp_name: '/tmp/phpmUrTkb',
              error: 0,
              size: 13757,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-06T10:21:36.100Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: '20220826_190845_member_list.csv',
            url: '631890597a7848c784512a440a01031a?path=',
            type: 'text/csv',
            meta: {
              name: '20220826_190845_member_list.csv',
              type: 'text/csv',
              tmp_name: '/tmp/phpRkzVz0',
              error: 0,
              size: 70,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:36:42.799Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'Flag_of_England.svg.png',
            url: '631890efef204657bf8b2cd00a01031a?path=',
            type: 'image/png',
            meta: {
              name: 'Flag_of_England.svg.png',
              type: 'image/png',
              tmp_name: '/tmp/phpXZeFbQ',
              error: 0,
              size: 725,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:39:13.456Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
        ],
        newValue: [
          {
            name: '57009015_1995644750745222_2051888778481500160_n.jpg',
            url: '63171eafb71848e59412141d0a01031a?path=',
            type: 'image/jpeg',
            meta: {
              name: '57009015_1995644750745222_2051888778481500160_n.jpg',
              type: 'image/jpeg',
              size: 3205,
              id: '57009015_1995644750745222_2051888778481500160_n.jpg-att=image/jpeg',
            },
            from: 'upload',
            source: 'to-customer',
            uploadedAt: '2022-09-06T10:19:28.236Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'ameos.png',
            url: '63171f2fc9084e97853a38fc0a01031a?path=',
            type: 'image/png',
            meta: {
              name: 'ameos.png',
              type: 'image/png',
              tmp_name: '/tmp/phpmUrTkb',
              error: 0,
              size: 13757,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-06T10:21:36.100Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: '20220826_190845_member_list.csv',
            url: '631890597a7848c784512a440a01031a?path=',
            type: 'text/csv',
            meta: {
              name: '20220826_190845_member_list.csv',
              type: 'text/csv',
              tmp_name: '/tmp/phpRkzVz0',
              error: 0,
              size: 70,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:36:42.799Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'Flag_of_England.svg.png',
            url: '631890efef204657bf8b2cd00a01031a?path=',
            type: 'image/png',
            meta: {
              name: 'Flag_of_England.svg.png',
              type: 'image/png',
              tmp_name: '/tmp/phpXZeFbQ',
              error: 0,
              size: 725,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:39:13.456Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'ttm.postman_collection.json',
            url: '6318910cd14c46aebcc02d5e0a01031a?path=',
            type: 'application/json',
            meta: {
              name: 'ttm.postman_collection.json',
              type: 'application/json',
              tmp_name: '/tmp/phpJfdqiW',
              error: 0,
              size: 604454,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:39:41.851Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
        ],
        changedAt: '2022-09-07T12:39:41.171Z',
        user: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
        user_type: false,
      },
      {
        name: 'attachments',
        oldValue: [
          {
            name: '57009015_1995644750745222_2051888778481500160_n.jpg',
            url: '63171eafb71848e59412141d0a01031a?path=',
            type: 'image/jpeg',
            meta: {
              name: '57009015_1995644750745222_2051888778481500160_n.jpg',
              type: 'image/jpeg',
              size: 3205,
              id: '57009015_1995644750745222_2051888778481500160_n.jpg-att=image/jpeg',
            },
            from: 'upload',
            source: 'to-customer',
            uploadedAt: '2022-09-06T10:19:28.236Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'ameos.png',
            url: '63171f2fc9084e97853a38fc0a01031a?path=',
            type: 'image/png',
            meta: {
              name: 'ameos.png',
              type: 'image/png',
              tmp_name: '/tmp/phpmUrTkb',
              error: 0,
              size: 13757,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-06T10:21:36.100Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: '20220826_190845_member_list.csv',
            url: '631890597a7848c784512a440a01031a?path=',
            type: 'text/csv',
            meta: {
              name: '20220826_190845_member_list.csv',
              type: 'text/csv',
              tmp_name: '/tmp/phpRkzVz0',
              error: 0,
              size: 70,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:36:42.799Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'Flag_of_England.svg.png',
            url: '631890efef204657bf8b2cd00a01031a?path=',
            type: 'image/png',
            meta: {
              name: 'Flag_of_England.svg.png',
              type: 'image/png',
              tmp_name: '/tmp/phpXZeFbQ',
              error: 0,
              size: 725,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:39:13.456Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'ttm.postman_collection.json',
            url: '6318910cd14c46aebcc02d5e0a01031a?path=',
            type: 'application/json',
            meta: {
              name: 'ttm.postman_collection.json',
              type: 'application/json',
              tmp_name: '/tmp/phpJfdqiW',
              error: 0,
              size: 604454,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:39:41.851Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
        ],
        newValue: [
          {
            name: '57009015_1995644750745222_2051888778481500160_n.jpg',
            url: '63171eafb71848e59412141d0a01031a?path=',
            type: 'image/jpeg',
            meta: {
              name: '57009015_1995644750745222_2051888778481500160_n.jpg',
              type: 'image/jpeg',
              size: 3205,
              id: '57009015_1995644750745222_2051888778481500160_n.jpg-att=image/jpeg',
            },
            from: 'upload',
            source: 'to-customer',
            uploadedAt: '2022-09-06T10:19:28.236Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'ameos.png',
            url: '63171f2fc9084e97853a38fc0a01031a?path=',
            type: 'image/png',
            meta: {
              name: 'ameos.png',
              type: 'image/png',
              tmp_name: '/tmp/phpmUrTkb',
              error: 0,
              size: 13757,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-06T10:21:36.100Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: '20220826_190845_member_list.csv',
            url: '631890597a7848c784512a440a01031a?path=',
            type: 'text/csv',
            meta: {
              name: '20220826_190845_member_list.csv',
              type: 'text/csv',
              tmp_name: '/tmp/phpRkzVz0',
              error: 0,
              size: 70,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:36:42.799Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'Flag_of_England.svg.png',
            url: '631890efef204657bf8b2cd00a01031a?path=',
            type: 'image/png',
            meta: {
              name: 'Flag_of_England.svg.png',
              type: 'image/png',
              tmp_name: '/tmp/phpXZeFbQ',
              error: 0,
              size: 725,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:39:13.456Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'ttm.postman_collection.json',
            url: '6318910cd14c46aebcc02d5e0a01031a?path=',
            type: 'application/json',
            meta: {
              name: 'ttm.postman_collection.json',
              type: 'application/json',
              tmp_name: '/tmp/phpJfdqiW',
              error: 0,
              size: 604454,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:39:41.851Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'FAQ (1).jpeg',
            url: '631891101d284588a38a2d940a01031a?path=',
            type: 'image/jpeg',
            meta: {
              name: 'FAQ (1).jpeg',
              type: 'image/jpeg',
              tmp_name: '/tmp/phpTT5P55',
              error: 0,
              size: 2697,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:39:45.751Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
        ],
        changedAt: '2022-09-07T12:39:45.121Z',
        user: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
        user_type: false,
      },
      {
        name: 'comment-added',
        changedAt: '2022-09-07T12:39:50.065Z',
        value: {
          content:
            '<p><a class="spectos-link file-size-604454" href="/files/view/6318910cd14c46aebcc02d5e0a01031a?path=&amp;download=true" id="link-6318910cd14c46aebcc02d5e0a01031a?path=filetype=application/json" target="_blank"><span></span></a><a class="spectos-link file-size-2697" href="/files/view/631891101d284588a38a2d940a01031a?path=&amp;download=true" id="link-631891101d284588a38a2d940a01031a?path=filetype=image/jpeg" target="_blank">FAQ (1).jpeg</a>ttm.postman_collection.json</p><p><br></p>',
          emailSubject: '[ID#25] New message from mailagent.es',
          author: {
            name: 'Panelmanagement',
            username: null,
            email: 'deployer+panelmanagement@spectos.com',
            timezone: 'Europe/Berlin',
            from: 'internal',
          },
          notifyParticipants: false,
          visibleTo: ['5e6a28ac-f72c-49f3-9489-0ae20a01011a'],
          attachTicket: false,
          attachmentToCustomer: [],
          notifyExternalEmails: [],
          userTags: [],
          sytemExternalData: [],
          dataSystemExternal: [],
          type: 'external',
          generateLetter: false,
          sentToCustomer: false,
          signature: false,
          modernView: false,
        },
        user: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
        user_type: false,
      },
      {
        name: 'attachments',
        oldValue: [
          {
            name: '57009015_1995644750745222_2051888778481500160_n.jpg',
            url: '63171eafb71848e59412141d0a01031a?path=',
            type: 'image/jpeg',
            meta: {
              name: '57009015_1995644750745222_2051888778481500160_n.jpg',
              type: 'image/jpeg',
              size: 3205,
              id: '57009015_1995644750745222_2051888778481500160_n.jpg-att=image/jpeg',
            },
            from: 'upload',
            source: 'to-customer',
            uploadedAt: '2022-09-06T10:19:28.236Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'ameos.png',
            url: '63171f2fc9084e97853a38fc0a01031a?path=',
            type: 'image/png',
            meta: {
              name: 'ameos.png',
              type: 'image/png',
              tmp_name: '/tmp/phpmUrTkb',
              error: 0,
              size: 13757,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-06T10:21:36.100Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: '20220826_190845_member_list.csv',
            url: '631890597a7848c784512a440a01031a?path=',
            type: 'text/csv',
            meta: {
              name: '20220826_190845_member_list.csv',
              type: 'text/csv',
              tmp_name: '/tmp/phpRkzVz0',
              error: 0,
              size: 70,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:36:42.799Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'Flag_of_England.svg.png',
            url: '631890efef204657bf8b2cd00a01031a?path=',
            type: 'image/png',
            meta: {
              name: 'Flag_of_England.svg.png',
              type: 'image/png',
              tmp_name: '/tmp/phpXZeFbQ',
              error: 0,
              size: 725,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:39:13.456Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'ttm.postman_collection.json',
            url: '6318910cd14c46aebcc02d5e0a01031a?path=',
            type: 'application/json',
            meta: {
              name: 'ttm.postman_collection.json',
              type: 'application/json',
              tmp_name: '/tmp/phpJfdqiW',
              error: 0,
              size: 604454,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:39:41.851Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'FAQ (1).jpeg',
            url: '631891101d284588a38a2d940a01031a?path=',
            type: 'image/jpeg',
            meta: {
              name: 'FAQ (1).jpeg',
              type: 'image/jpeg',
              tmp_name: '/tmp/phpTT5P55',
              error: 0,
              size: 2697,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:39:45.751Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
        ],
        newValue: [
          {
            name: '57009015_1995644750745222_2051888778481500160_n.jpg',
            url: '63171eafb71848e59412141d0a01031a?path=',
            type: 'image/jpeg',
            meta: {
              name: '57009015_1995644750745222_2051888778481500160_n.jpg',
              type: 'image/jpeg',
              size: 3205,
              id: '57009015_1995644750745222_2051888778481500160_n.jpg-att=image/jpeg',
            },
            from: 'upload',
            source: 'to-customer',
            uploadedAt: '2022-09-06T10:19:28.236Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'ameos.png',
            url: '63171f2fc9084e97853a38fc0a01031a?path=',
            type: 'image/png',
            meta: {
              name: 'ameos.png',
              type: 'image/png',
              tmp_name: '/tmp/phpmUrTkb',
              error: 0,
              size: 13757,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-06T10:21:36.100Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: '20220826_190845_member_list.csv',
            url: '631890597a7848c784512a440a01031a?path=',
            type: 'text/csv',
            meta: {
              name: '20220826_190845_member_list.csv',
              type: 'text/csv',
              tmp_name: '/tmp/phpRkzVz0',
              error: 0,
              size: 70,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:36:42.799Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'Flag_of_England.svg.png',
            url: '631890efef204657bf8b2cd00a01031a?path=',
            type: 'image/png',
            meta: {
              name: 'Flag_of_England.svg.png',
              type: 'image/png',
              tmp_name: '/tmp/phpXZeFbQ',
              error: 0,
              size: 725,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:39:13.456Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'ttm.postman_collection.json',
            url: '6318910cd14c46aebcc02d5e0a01031a?path=',
            type: 'application/json',
            meta: {
              name: 'ttm.postman_collection.json',
              type: 'application/json',
              tmp_name: '/tmp/phpJfdqiW',
              error: 0,
              size: 604454,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:39:41.851Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'FAQ (1).jpeg',
            url: '631891101d284588a38a2d940a01031a?path=',
            type: 'image/jpeg',
            meta: {
              name: 'FAQ (1).jpeg',
              type: 'image/jpeg',
              tmp_name: '/tmp/phpTT5P55',
              error: 0,
              size: 2697,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:39:45.751Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'Flag_of_England.svg.png',
            url: '6318911f31c84fe394c32d5e0a01031a?path=',
            type: 'image/png',
            meta: {
              name: 'Flag_of_England.svg.png',
              type: 'image/png',
              tmp_name: '/tmp/phpRY50FD',
              error: 0,
              size: 725,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:40:01.036Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
        ],
        changedAt: '2022-09-07T12:40:00.386Z',
        user: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
        user_type: false,
      },
      {
        name: 'attachments',
        oldValue: [
          {
            name: '57009015_1995644750745222_2051888778481500160_n.jpg',
            url: '63171eafb71848e59412141d0a01031a?path=',
            type: 'image/jpeg',
            meta: {
              name: '57009015_1995644750745222_2051888778481500160_n.jpg',
              type: 'image/jpeg',
              size: 3205,
              id: '57009015_1995644750745222_2051888778481500160_n.jpg-att=image/jpeg',
            },
            from: 'upload',
            source: 'to-customer',
            uploadedAt: '2022-09-06T10:19:28.236Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'ameos.png',
            url: '63171f2fc9084e97853a38fc0a01031a?path=',
            type: 'image/png',
            meta: {
              name: 'ameos.png',
              type: 'image/png',
              tmp_name: '/tmp/phpmUrTkb',
              error: 0,
              size: 13757,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-06T10:21:36.100Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: '20220826_190845_member_list.csv',
            url: '631890597a7848c784512a440a01031a?path=',
            type: 'text/csv',
            meta: {
              name: '20220826_190845_member_list.csv',
              type: 'text/csv',
              tmp_name: '/tmp/phpRkzVz0',
              error: 0,
              size: 70,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:36:42.799Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'Flag_of_England.svg.png',
            url: '631890efef204657bf8b2cd00a01031a?path=',
            type: 'image/png',
            meta: {
              name: 'Flag_of_England.svg.png',
              type: 'image/png',
              tmp_name: '/tmp/phpXZeFbQ',
              error: 0,
              size: 725,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:39:13.456Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'ttm.postman_collection.json',
            url: '6318910cd14c46aebcc02d5e0a01031a?path=',
            type: 'application/json',
            meta: {
              name: 'ttm.postman_collection.json',
              type: 'application/json',
              tmp_name: '/tmp/phpJfdqiW',
              error: 0,
              size: 604454,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:39:41.851Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'FAQ (1).jpeg',
            url: '631891101d284588a38a2d940a01031a?path=',
            type: 'image/jpeg',
            meta: {
              name: 'FAQ (1).jpeg',
              type: 'image/jpeg',
              tmp_name: '/tmp/phpTT5P55',
              error: 0,
              size: 2697,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:39:45.751Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'Flag_of_England.svg.png',
            url: '6318911f31c84fe394c32d5e0a01031a?path=',
            type: 'image/png',
            meta: {
              name: 'Flag_of_England.svg.png',
              type: 'image/png',
              tmp_name: '/tmp/phpRY50FD',
              error: 0,
              size: 725,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:40:01.036Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
        ],
        newValue: [
          {
            name: '57009015_1995644750745222_2051888778481500160_n.jpg',
            url: '63171eafb71848e59412141d0a01031a?path=',
            type: 'image/jpeg',
            meta: {
              name: '57009015_1995644750745222_2051888778481500160_n.jpg',
              type: 'image/jpeg',
              size: 3205,
              id: '57009015_1995644750745222_2051888778481500160_n.jpg-att=image/jpeg',
            },
            from: 'upload',
            source: 'to-customer',
            uploadedAt: '2022-09-06T10:19:28.236Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'ameos.png',
            url: '63171f2fc9084e97853a38fc0a01031a?path=',
            type: 'image/png',
            meta: {
              name: 'ameos.png',
              type: 'image/png',
              tmp_name: '/tmp/phpmUrTkb',
              error: 0,
              size: 13757,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-06T10:21:36.100Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: '20220826_190845_member_list.csv',
            url: '631890597a7848c784512a440a01031a?path=',
            type: 'text/csv',
            meta: {
              name: '20220826_190845_member_list.csv',
              type: 'text/csv',
              tmp_name: '/tmp/phpRkzVz0',
              error: 0,
              size: 70,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:36:42.799Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'Flag_of_England.svg.png',
            url: '631890efef204657bf8b2cd00a01031a?path=',
            type: 'image/png',
            meta: {
              name: 'Flag_of_England.svg.png',
              type: 'image/png',
              tmp_name: '/tmp/phpXZeFbQ',
              error: 0,
              size: 725,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:39:13.456Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'ttm.postman_collection.json',
            url: '6318910cd14c46aebcc02d5e0a01031a?path=',
            type: 'application/json',
            meta: {
              name: 'ttm.postman_collection.json',
              type: 'application/json',
              tmp_name: '/tmp/phpJfdqiW',
              error: 0,
              size: 604454,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:39:41.851Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'FAQ (1).jpeg',
            url: '631891101d284588a38a2d940a01031a?path=',
            type: 'image/jpeg',
            meta: {
              name: 'FAQ (1).jpeg',
              type: 'image/jpeg',
              tmp_name: '/tmp/phpTT5P55',
              error: 0,
              size: 2697,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:39:45.751Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'Flag_of_England.svg.png',
            url: '6318911f31c84fe394c32d5e0a01031a?path=',
            type: 'image/png',
            meta: {
              name: 'Flag_of_England.svg.png',
              type: 'image/png',
              tmp_name: '/tmp/phpRY50FD',
              error: 0,
              size: 725,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:40:01.036Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
          {
            name: 'ODEG3 (1).jpg',
            url: '631891269fd840c6a7512d5d0a01031a?path=',
            type: 'image/jpeg',
            meta: {
              name: 'ODEG3 (1).jpg',
              type: 'image/jpeg',
              tmp_name: '/tmp/phpxKNu2j',
              error: 0,
              size: 74721,
            },
            from: 'upload',
            source: null,
            uploadedAt: '2022-09-07T12:40:07.810Z',
            uploadBy: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
          },
        ],
        changedAt: '2022-09-07T12:40:07.131Z',
        user: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
        user_type: false,
      },
      {
        name: 'comment-added',
        changedAt: '2022-09-07T12:40:08.693Z',
        value: {
          content:
            '<p><span><a class="spectos-link file-size-725" href="/files/view/6318911f31c84fe394c32d5e0a01031a?path=&amp;download=true" id="link-6318911f31c84fe394c32d5e0a01031a?path=filetype=image/png" target="_blank">Flag_of_England.svg.png</a>&nbsp;</span><br></p><p></p><p><span><a class="spectos-link file-size-74721" href="/files/view/631891269fd840c6a7512d5d0a01031a?path=&amp;download=true" id="link-631891269fd840c6a7512d5d0a01031a?path=filetype=image/jpeg" target="_blank">ODEG3 (1).jpg</a></span><br></p><p></p>',
          emailSubject: '[ID#25] New message from mailagent.es',
          author: {
            name: 'Panelmanagement',
            username: null,
            email: 'deployer+panelmanagement@spectos.com',
            timezone: 'Europe/Berlin',
            from: 'internal',
          },
          notifyParticipants: false,
          visibleTo: ['5e6a28ac-f72c-49f3-9489-0ae20a01011a'],
          attachTicket: false,
          attachmentToCustomer: [],
          notifyExternalEmails: [],
          userTags: [],
          sytemExternalData: [],
          dataSystemExternal: [],
          type: 'external',
          generateLetter: false,
          sentToCustomer: false,
          signature: false,
          modernView: false,
        },
        user: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
        user_type: false,
      },
    ],
    additionalDetails: [],
    sharedCustomerUserGroups: [],
    sharedAttachUserGroups: [],
    processTime: [],
    payoutExported: false,
    bounces: [],
    content: 'Abc',
    status: 'open',
    process: 'open',
    hypothesis: '',
    priority: 'medium',
    resolution: null,
    silentTicket: false,
    title: 'Bundle Posting',
    reporter: {
      name: 'Ky Ho1',
      email: 'ho.sy.ky@spectos.com',
      id: '62f2101ffca0847e9bec5870',
    },
    assignee: {
      name: 'Panelmanagement',
      email: 'deployer+panelmanagement@spectos.com',
      id: '5e6a28ac-f72c-49f3-9489-0ae20a01011a',
    },
    ticketType: 'royalmail',
    comments: [
      {
        offer: {
          date: '2022-09-06T04:54:09.529Z',
        },
        notifyExternalEmails: [],
        sytemExternalData: [],
        attachmentToCustomer: [],
        visibleTo: ['5e6a28ac-f72c-49f3-9489-0ae20a01011a'],
        userTags: [],
        ccAdress: [],
        from: [],
        to: [],
        dataSystemExternal: [],
        generalRefData: [],
        _id: '631713b87b472609319463a6',
        attachTicket: false,
        author: {
          name: 'Panelmanagement',
          email: 'deployer+panelmanagement@spectos.com',
          timezone: 'Europe/Berlin',
          from: 'internal',
        },
        content: 'the second comment from app',
        emailSubject: '',
        generateLetter: false,
        notifyParticipants: false,
        sentToCustomer: false,
        signature: false,
        type: 'external',
        createdAt: '2022-09-06T09:32:40.768Z',
        updatedAt: '2022-09-06T09:32:40.768Z',
      },
      {
        offer: {
          date: '2022-09-06T04:54:09.529Z',
        },
        notifyExternalEmails: [],
        sytemExternalData: [],
        attachmentToCustomer: [],
        visibleTo: ['5e6a28ac-f72c-49f3-9489-0ae20a01011a'],
        userTags: [],
        ccAdress: [],
        from: [],
        to: [],
        dataSystemExternal: [],
        generalRefData: [],
        _id: '631713bb7b472609319463aa',
        attachTicket: false,
        author: {
          name: 'Panelmanagement',
          email: 'deployer+panelmanagement@spectos.com',
          timezone: 'Europe/Berlin',
          from: 'external',
        },
        content: 'the second comment from app',
        emailSubject: '',
        generateLetter: false,
        notifyParticipants: false,
        sentToCustomer: false,
        signature: false,
        type: 'external',
        createdAt: '2022-09-06T09:32:43.137Z',
        updatedAt: '2022-09-06T09:32:43.137Z',
      },
      {
        offer: {
          date: '2022-09-06T04:54:09.529Z',
        },
        notifyExternalEmails: [],
        sytemExternalData: [],
        attachmentToCustomer: [],
        visibleTo: ['5e6a28ac-f72c-49f3-9489-0ae20a01011a'],
        userTags: [],
        ccAdress: [],
        from: [],
        to: [],
        dataSystemExternal: [],
        generalRefData: [],
        _id: '63171eb17b472609319463c9',
        content:
          '<p>aaa</p><p><br><img class="sp-image-drag" style="height: 91px;width: 149px;" id="link-63171eafb71848e59412141d0a01031a?path=-att=image/jpeg-att=57009015_1995644750745222_2051888778481500160_n.jpg" src="https://rtpm-stage.spectos.com/files/view/63171eafb71848e59412141d0a01031a?path="></p><p>bbbb</p>',
        emailSubject: '[ID#25] New message from mailagent.es',
        author: {
          name: 'Panelmanagement',
          username: null,
          email: 'deployer+panelmanagement@spectos.com',
          timezone: 'Europe/Berlin',
          from: 'internal',
        },
        notifyParticipants: false,
        attachTicket: false,
        type: 'external',
        generateLetter: false,
        sentToCustomer: false,
        signature: false,
        createdAt: '2022-09-06T10:19:29.162Z',
        updatedAt: '2022-09-06T10:19:29.162Z',
      },
      {
        offer: {
          date: '2022-09-07T11:02:39.320Z',
        },
        notifyExternalEmails: [],
        sytemExternalData: [],
        attachmentToCustomer: [],
        visibleTo: ['5e6a28ac-f72c-49f3-9489-0ae20a01011a'],
        userTags: [],
        ccAdress: [],
        from: [],
        to: [],
        dataSystemExternal: [],
        generalRefData: [],
        _id: '6318906bef00dd52114e1476',
        content:
          '<p>qwqe&nbsp;<span id="selectionBoundary_1662554209480_5251013741560251" class="rangySelectionBoundary">\ufeff<a class="spectos-link file-size-70" href="/files/view/631890597a7848c784512a440a01031a?path=&amp;download=true" id="link-631890597a7848c784512a440a01031a?path=filetype=text/csv" target="_blank">20220826_190845_member_list.csv</a>&nbsp;qưewqeeqqe</span><br></p>',
        emailSubject: '[ID#25] New message from mailagent.es',
        author: {
          name: 'Panelmanagement',
          username: null,
          email: 'deployer+panelmanagement@spectos.com',
          timezone: 'Europe/Berlin',
          from: 'internal',
        },
        notifyParticipants: false,
        attachTicket: false,
        type: 'external',
        generateLetter: false,
        sentToCustomer: false,
        signature: false,
        createdAt: '2022-09-07T12:36:59.738Z',
        updatedAt: '2022-09-07T12:36:59.738Z',
      },
      {
        offer: {
          date: '2022-09-07T11:02:39.320Z',
        },
        notifyExternalEmails: [],
        sytemExternalData: [],
        attachmentToCustomer: [],
        visibleTo: ['5e6a28ac-f72c-49f3-9489-0ae20a01011a'],
        userTags: [],
        ccAdress: [],
        from: [],
        to: [],
        dataSystemExternal: [],
        generalRefData: [],
        _id: '631890f4ef00dd52114e147f',
        content:
          '<p><span><a class="spectos-link file-size-725" href="/files/view/631890efef204657bf8b2cd00a01031a?path=&amp;download=true" id="link-631890efef204657bf8b2cd00a01031a?path=filetype=image/png" target="_blank">Flag_of_England.svg.png</a>&nbsp;sdsaasdad</span><br></p>',
        emailSubject: '[ID#25] New message from mailagent.es',
        author: {
          name: 'Panelmanagement',
          username: null,
          email: 'deployer+panelmanagement@spectos.com',
          timezone: 'Europe/Berlin',
          from: 'internal',
        },
        notifyParticipants: false,
        attachTicket: false,
        type: 'external',
        generateLetter: false,
        sentToCustomer: false,
        signature: false,
        createdAt: '2022-09-07T12:39:16.836Z',
        updatedAt: '2022-09-07T12:39:16.836Z',
      },
      {
        offer: {
          date: '2022-09-07T11:02:39.320Z',
        },
        notifyExternalEmails: [],
        sytemExternalData: [],
        attachmentToCustomer: [],
        visibleTo: ['5e6a28ac-f72c-49f3-9489-0ae20a01011a'],
        userTags: [],
        ccAdress: [],
        from: [],
        to: [],
        dataSystemExternal: [],
        generalRefData: [],
        _id: '63189116ef00dd52114e148c',
        content:
          '<p><a class="spectos-link file-size-604454" href="/files/view/6318910cd14c46aebcc02d5e0a01031a?path=&amp;download=true" id="link-6318910cd14c46aebcc02d5e0a01031a?path=filetype=application/json" target="_blank"><span></span></a><a class="spectos-link file-size-2697" href="/files/view/631891101d284588a38a2d940a01031a?path=&amp;download=true" id="link-631891101d284588a38a2d940a01031a?path=filetype=image/jpeg" target="_blank">FAQ (1).jpeg</a>ttm.postman_collection.json</p><p><br></p>',
        emailSubject: '[ID#25] New message from mailagent.es',
        author: {
          name: 'Panelmanagement',
          username: null,
          email: 'deployer+panelmanagement@spectos.com',
          timezone: 'Europe/Berlin',
          from: 'internal',
        },
        notifyParticipants: false,
        attachTicket: false,
        type: 'external',
        generateLetter: false,
        sentToCustomer: false,
        signature: false,
        createdAt: '2022-09-07T12:39:50.065Z',
        updatedAt: '2022-09-07T12:39:50.065Z',
      },
      {
        offer: {
          date: '2022-09-07T11:02:39.320Z',
        },
        notifyExternalEmails: [],
        sytemExternalData: [],
        attachmentToCustomer: [],
        visibleTo: ['5e6a28ac-f72c-49f3-9489-0ae20a01011a'],
        userTags: [],
        ccAdress: [],
        from: [],
        to: [],
        dataSystemExternal: [],
        generalRefData: [],
        _id: '63189128ef00dd52114e1499',
        content:
          '<p><span><a class="spectos-link file-size-725" href="/files/view/6318911f31c84fe394c32d5e0a01031a?path=&amp;download=true" id="link-6318911f31c84fe394c32d5e0a01031a?path=filetype=image/png" target="_blank">Flag_of_England.svg.png</a>&nbsp;</span><br></p><p></p><p><span><a class="spectos-link file-size-74721" href="/files/view/631891269fd840c6a7512d5d0a01031a?path=&amp;download=true" id="link-631891269fd840c6a7512d5d0a01031a?path=filetype=image/jpeg" target="_blank">ODEG3 (1).jpg</a></span><br></p><p></p>',
        emailSubject: '[ID#25] New message from mailagent.es',
        author: {
          name: 'Panelmanagement',
          username: null,
          email: 'deployer+panelmanagement@spectos.com',
          timezone: 'Europe/Berlin',
          from: 'internal',
        },
        notifyParticipants: false,
        attachTicket: false,
        type: 'external',
        generateLetter: false,
        sentToCustomer: false,
        signature: false,
        createdAt: '2022-09-07T12:40:08.694Z',
        updatedAt: '2022-09-07T12:40:08.694Z',
      },
    ],
    createdAt: '2022-09-06T09:23:40.369Z',
    inactive: false,
    type: 'internal',
    dataSecurity: false,
    updatedAt: '2022-09-07T12:40:08.730Z',
    completedAt: null,
    sort: {
      dateReceivedCDComment: '2122-09-07T12:40:08.745Z',
      totalCDComment: 0,
      status: 1,
      resolution: 1,
      priority: 3,
      priorityHigh: 0,
      blocker: 0,
      date: '2022-09-07T12:40:08.730Z',
    },
    properties: {
      panelistType: [],
      panelistId: '62f2101ffca0847e9bec5870',
      icode: '',
      linkId: '',
      bundleId: '',
      panelistName: '',
      panelistLanguage: '',
      panelistEmail: '',
      panelistPhone: '',
      panelistAvailability: '',
      panelistSuspensionTime: '',
      panelistBonus: '',
      username: '',
      address: '',
      city: '',
      taskId: '',
      ticketIDSearch: '25',
      incrementalID: 25,
      ticketId: 25,
      purpose: [],
    },
    __v: 15,
    attributes: [
      {
        _id: '62fb03ffc0b6516a6e04b7df',
        ticketType: 'royalmail',
        attributes: [
          {
            _id: '62fb03ff2c25796a6e2ae8ca',
            name: 'title',
            title: 'Title',
            type: 'string',
            value: '',
            required: true,
            default: '',
          },
          {
            _id: '62fb03ff2c25796a6e2ae8cb',
            name: 'content',
            title: 'Description',
            type: 'text',
            value: '',
            required: false,
            default: '',
          },
          {
            _id: '62fb03ff2c25796a6e2ae8cc',
            name: 'additionalProperties',
            title: 'Additional Properties',
            type: 'arraylist',
            required: false,
            default: '',
            value: [
              {
                _id: '62fb03ff2c25796a6e2ae8cd',
                name: 'panelistType',
                title: 'Type',
                type: 'multistringlist',
                value: [
                  {
                    value: 'sender',
                    resourceId: 'sender',
                  },
                  {
                    value: 'receiver',
                    resourceId: 'receiver',
                  },
                  {
                    value: 'standBy',
                    resourceId: 'standBy',
                  },
                ],
                required: false,
                default: '',
                setDefaultToRelatedField: ['panelistId'],
              },
              {
                _id: '62fb03ff2c25796a6e2ae8ce',
                name: 'panelistId',
                title: 'Panelist Id',
                type: 'stringlistsuggestion',
                value: [],
                required: false,
                ref: {
                  service: 'fdb',
                  part: 'surveys/propertiesValues/royalmail',
                },
                default: '',
                locale: [],
              },
              {
                _id: '62fb03ff2c25796a6e2ae8cf',
                name: 'icode',
                title: 'i.Code',
                type: 'string',
                value: '',
                required: false,
                default: '',
                triggerRequest: 'lab',
              },
              {
                _id: '62fb03ff2c25796a6e2ae8d0',
                name: 'linkId',
                title: 'External ID',
                type: 'string',
                value: '',
                required: false,
                default: '',
                triggerRequest: 'lab',
              },
              {
                _id: '62fb03ff2c25796a6e2ae8d1',
                name: 'bundleId',
                title: 'Bundle Id',
                type: 'string',
                value: '',
                required: false,
                default: '',
              },
              {
                _id: '62fb03ff2c25796a6e2ae8d2',
                name: 'panelistName',
                title: 'Name',
                type: 'string',
                value: '',
                required: false,
                default: '',
              },
              {
                _id: '62fb03ff2c25796a6e2ae8d3',
                name: 'panelistLanguage',
                title: 'Language',
                type: 'stringlist',
                value: [
                  {
                    value: 'DE',
                    resourceId: 'DE',
                  },
                  {
                    value: 'EN',
                    resourceId: 'EN',
                  },
                  {
                    value: 'ES',
                    resourceId: 'ES',
                  },
                  {
                    value: 'FR',
                    resourceId: 'FR',
                  },
                  {
                    value: 'IT',
                    resourceId: 'IT',
                  },
                ],
                required: false,
                default: '',
              },
              {
                _id: '62fb03ff2c25796a6e2ae8d4',
                name: 'panelistEmail',
                title: 'Email',
                type: 'string',
                value: '',
                required: false,
                default: '',
                triggerRequest: 'lab',
              },
              {
                _id: '62fb03ff2c25796a6e2ae8d5',
                name: 'panelistPhone',
                title: 'Phone',
                type: 'string',
                value: '',
                required: false,
                default: '',
                additionalType: 'phone-number',
              },
              {
                _id: '62fb03ff2c25796a6e2ae8d6',
                name: 'panelistAvailability',
                title: 'Availability',
                type: 'string',
                value: '',
                required: false,
                default: '',
                placeholder: 'hh:mm:ss hh:mm:ss',
                regex: '^([01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9] ([01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$',
              },
              {
                _id: '62fb03ff2c25796a6e2ae8d7',
                name: 'panelistSuspensionTime',
                title: 'Suspension Time',
                type: 'string',
                value: '',
                required: false,
                default: '',
                placeholder: 'dd.mm.yyyy - dd.mm.yyyy',
                regex:
                  '^(0?[1-9]|[12]?[0-9]|3[0-1])(\\.)(0[1-9]|1[0-2])(\\.)(\\d{4}) - (0?[1-9]|[12]?[0-9]|3[0-1])(\\.)(0[1-9]|1[0-2])(\\.)(\\d{4})$',
              },
              {
                _id: '62fb03ff2c25796a6e2ae8d8',
                name: 'panelistBonus',
                title: 'Bonus',
                type: 'string',
                value: '',
                required: false,
                default: '',
              },
              {
                _id: '62fb03ff2c25796a6e2ae8d9',
                name: 'username',
                title: 'Username',
                type: 'string',
                value: '',
                required: false,
                default: '',
              },
              {
                _id: '62fb03ff2c25796a6e2ae8da',
                name: 'address',
                title: 'Address',
                type: 'string',
                value: '',
                required: false,
                default: '',
              },
              {
                _id: '62fb03ff2c25796a6e2ae8db',
                name: 'city',
                title: 'City',
                type: 'string',
                value: '',
                required: false,
                default: '',
              },
            ],
          },
          {
            _id: '62fb03ff2c25796a6e2ae8c6',
            name: 'status',
            title: 'Status',
            type: 'stringlist',
            value: ['open', 'in-progress', 'closed'],
            required: true,
            default: 'open',
          },
          {
            _id: '62fb03ff2c25796a6e2ae8c7',
            name: 'process',
            title: 'Status',
            type: 'stringlist',
            value: null,
            required: true,
            default: 'open',
          },
          {
            _id: '62fb03ff2c25796a6e2ae8c8',
            name: 'priority',
            title: 'Priority',
            type: 'stringlist',
            value: ['low', 'medium', 'high'],
            required: true,
            default: 'medium',
          },
          {
            _id: '62fb03ff2c25796a6e2ae8dc',
            name: 'assignee',
            title: 'Assignee',
            type: 'assignee',
            value: '',
            required: true,
            default: '',
          },
          {
            _id: '62fb03ff2c25796a6e2ae8c9',
            name: 'attachments',
            title: 'Attachments',
            type: 'attachment',
            value: '',
            required: false,
            default: '',
          },
          {
            _id: '62fb03ff2c25796a6e2ae8dd',
            name: 'silentTicket',
            title: 'Ticket type',
            type: 'stringlist',
            value: [true, false],
            required: true,
            default: false,
          },
        ],
        processes: {
          open: ['open'],
          'in-progress': ['in-progress', 'needMoreInformation'],
          closed: ['closed'],
        },
        constants: {
          batch: {
            list: {
              visible: true,
            },
          },
          title: {
            add: {
              width: 'sp-box box-sm-12',
            },
          },
          content: {
            add: {
              width: 'sp-box box-sm-12',
            },
          },
          assignee: {
            add: {
              width: 'sp-box box-sm-12',
            },
          },
          attachments: {
            add: {
              width: 'sp-box box-sm-12',
            },
          },
          status: {
            add: {
              width: 'sp-box box-sm-12 box-md-6',
            },
          },
          process: {
            add: {
              width: 'sp-box box-sm-12 box-md-6',
            },
          },
          priority: {
            add: {
              width: 'sp-box box-sm-12 box-md-6',
            },
          },
          additionalProperties: {
            add: {
              width: 'sp-box box-sm-12',
            },
            panelistType: {
              hasMany: 'panelistId',
              add: {
                width: 'sp-box box-sm-12 box-md-6',
              },
              list: {
                visible: true,
              },
              edit: {
                width: 'sp-box box-sm-12 box-md-6',
                class: 'odd',
                index: 0,
              },
            },
            panelistId: {
              belongTo: 'panelistType',
              add: {
                width: 'sp-box box-sm-12 box-md-6',
                trigger: {
                  service: 'LabApi',
                  query: {
                    module: 'panelists',
                    action: 'getPanelistDetails',
                    id: '',
                  },
                  dependent: [
                    'panelistName',
                    'panelistLanguage',
                    'panelistEmail',
                    'panelistPhone',
                    'panelistAvailability',
                    'panelistSuspensionTime',
                    'panelistBonus',
                    'linkId',
                    'username',
                    'address',
                    'city',
                  ],
                },
              },
              edit: {
                width: 'sp-box box-sm-12 box-md-6',
                class: 'odd',
                index: 1,
                action: 'filterByCustomer',
                trigger: {
                  service: 'LabApi',
                  query: {
                    module: 'panelists',
                    action: 'getPanelistDetails',
                    id: '',
                  },
                  dependent: [
                    'panelistName',
                    'panelistLanguage',
                    'panelistEmail',
                    'panelistPhone',
                    'panelistAvailability',
                    'panelistSuspensionTime',
                    'panelistBonus',
                    'linkId',
                    'username',
                    'address',
                    'city',
                  ],
                },
              },
            },
            icode: {
              add: {
                width: 'sp-box box-sm-12 box-md-6',
              },
              edit: {
                width: 'sp-box box-sm-12 box-md-6',
                class: 'odd',
                index: 2,
              },
            },
            linkId: {
              add: {
                width: 'sp-box box-sm-12 box-md-6',
              },
              edit: {
                width: 'sp-box box-sm-12 box-md-6',
                class: 'even',
                index: 3,
              },
            },
            bundleId: {
              add: {
                width: 'sp-box box-sm-12 box-md-6',
              },
              edit: {
                width: 'sp-box box-sm-12 box-md-6',
                class: 'odd',
                index: 4,
              },
            },
            panelistName: {
              add: {
                width: 'sp-box box-sm-12 box-md-6',
              },
              list: {
                visible: true,
              },
              edit: {
                width: 'sp-box box-sm-12 box-md-6',
                class: 'odd',
                index: 5,
              },
            },
            panelistLanguage: {
              add: {
                width: 'sp-box box-sm-12 box-md-6',
              },
              edit: {
                width: 'sp-box box-sm-12 box-md-6',
                class: 'odd',
                index: 6,
              },
            },
            panelistEmail: {
              add: {
                width: 'sp-box box-sm-12 box-md-6',
              },
              edit: {
                width: 'sp-box box-sm-12 box-md-6',
                class: 'even',
                index: 7,
              },
            },
            panelistPhone: {
              add: {
                width: 'sp-box box-sm-12 box-md-6',
              },
              edit: {
                width: 'sp-box box-sm-12 box-md-6',
                class: 'odd',
                index: 8,
              },
            },
            panelistAvailability: {
              add: {
                width: 'sp-box box-sm-12 box-md-6',
              },
              edit: {
                width: 'sp-box box-sm-12 box-md-6',
                class: 'even',
                index: 9,
              },
            },
            panelistSuspensionTime: {
              add: {
                width: 'sp-box box-sm-12 box-md-6',
              },
              edit: {
                width: 'sp-box box-sm-12 box-md-6',
                class: 'even',
                index: 10,
              },
            },
            panelistBonus: {
              add: {
                width: 'sp-box box-sm-12 box-md-6',
              },
              edit: {
                width: 'sp-box box-sm-12 box-md-6',
                class: 'even',
                index: 11,
              },
            },
            username: {
              add: {
                width: 'sp-box box-sm-12 box-md-6',
              },
              edit: {
                width: 'sp-box box-sm-12 box-md-6',
                class: 'even',
                index: 12,
              },
            },
            address: {
              add: {
                width: 'sp-box box-sm-12 box-md-6',
              },
              edit: {
                width: 'sp-box box-sm-12 box-md-6',
                class: 'even',
                index: 13,
              },
            },
            city: {
              add: {
                width: 'sp-box box-sm-12 box-md-6',
              },
              edit: {
                width: 'sp-box box-sm-12 box-md-6',
                class: 'even',
                index: 14,
              },
            },
          },
          silentTicket: {
            filter: {
              visible: false,
              static: false,
              allowed: true,
            },
            add: {
              visible: true,
              width: 'sp-box box-sm-12 box-md-6',
            },
            list: {
              visible: true,
            },
          },
          multipleSearch: {
            ticketIds: {
              filter: {
                name: 'ticketIds',
                title: 'Tickets filter',
                type: 'multiple_search',
                visible: false,
                static: false,
                allowed: true,
                right: true,
              },
            },
          },
        },
      },
    ],
    labels: [],
    age: 2.298684409722222,
    lead: 2.2986844212962962,
  },
};
