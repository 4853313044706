import _ from 'lodash'
import {checkEvalCondition} from "../form";
export const QRTransform = {
    getTestQROptions : options => {
        if(_.isEmpty(options)){
            return options
        }

        const rs = options.map((x, index) => {
            return {
                ...x,
                scanQR: {
                    react: `return {{scanId}}.substr(4,1) == 'B' && {{scanId}}.substr(6,4) == '${index}000'`
                }
            }
        })
        console.log('getTestQROptions>>>', rs)
        return rs
    },
    getValidQRFromOptions: (options, data) => {
        if(_.isEmpty(options)){
            return null
        }

        let result = null;

        for (const option of options) {
            const scripted = option?.scanQR?.react
            if(_.isEmpty(scripted)){
                continue
            }

            const rs = checkEvalCondition(scripted, data)
            console.log('scripted>>>', rs,data, option, scripted)

            if(rs){
                result = {...option}
                break;
            }
        }

        return result
    },
    checkValidQRFromInput: (scripted, data) => {
        const rs = checkEvalCondition(scripted, data)
        return rs
    }
}