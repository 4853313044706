import { ChevronPickerIcon } from '@assets';
import { AppModal, AppText, AppSingleSelection, AppInput } from '@components';
import { palette } from '@theme/colors';
import React, { useEffect, useRef, useState } from 'react';
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';

const VERTICAL_HEIGHT = 15;
const CONTENT_SIZE = 275.3333435058594;
const ITEM_HEIGHT = 44;
const HEADER_HEIGHT = 43;

/**
 * - Data must be object and include ID and CONTENT
 * - value must be ID
 * - value is also default data
 */
export const AppInputPicker = props => {
  const {
    error,
    style,
    title,
    isRequired,
    header,
    data,
    styleTitle,
    disabled,
    onChangeText,
    value = '',
    isBoldTitle,
    hint = '',
    placeHolder = '',
    onLayout = null,
    ...rest
  } = props;
  const [visible, setVisible] = useState(false);

  const handleOpenModal = () => {
    if (data && data.length > 0) setVisible(true);
  };

  const handleBackdropPress = () => {
    setVisible(false);
  };

  const findIndex = id => {
    return data.findIndex(i => i.id === id);
  };
  return (
    <>
      <View
        style={[styles.container, style]}
        onLayout={e => {
          if (onLayout) {
            onLayout(e);
          }
        }}>
        <AppText style={[styles.textTitle, styleTitle, isBoldTitle ? { fontWeight: '700' } : {}]}>
          {isRequired && <AppText style={styles.isRequired}>* </AppText>}
          {title}
        </AppText>
        <AppInput
          value={String(value)}
          disabled={disabled}
          onChangeText={onChangeText}
          PostIcon={ChevronPickerIcon}
          onPostIconPress={handleOpenModal}
          placeHolder={placeHolder}
          {...rest}
        />
        {!!hint && <AppText style={styles.hint}>{hint}</AppText>}
        {!!error && <AppText style={styles.error}>{error}</AppText>}
      </View>
      <AppModal visible={visible} onBackdropPress={handleBackdropPress}>
        {!!header && (
          <View
            style={styles.header}
            onLayout={e => {
              console.log(e.nativeEvent.layout.height);
            }}>
            <AppText style={styles.headerTxt}>{header}</AppText>
          </View>
        )}
        <ScrollView style={styles.modalContent}>
          <AppSingleSelection
            value={String(value)}
            data={data}
            onSelect={item => {
              onChangeText(String(data[findIndex(item)]?.content));
              handleBackdropPress();
            }}
            isRadio={false}
          />
        </ScrollView>
      </AppModal>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  textTitle: {
    fontWeight: '400',

    marginBottom: 10,
  },
  viewSelect: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#C6C6C6',
    marginHorizontal: 0,
    // backgroundColor: '#D5D5D5',
    paddingHorizontal: 12,
    borderRadius: 4,
  },
  isRequired: {
    color: '#FF5757',
  },
  textAfterSelect: {
    fontWeight: '400',

    color: '#333333',
    // marginLeft: 12,
    // textTransform: 'capitalize',
  },
  textSelect: {
    flex: 1,
    fontWeight: '400',

    color: '#666666',
    // marginLeft: 12,
    // textTransform: 'capitalize',
  },
  modalContent: {
    paddingHorizontal: 16,
  },
  header: {
    backgroundColor: '#F7F7F7',
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    minHeight: 43,
  },
  headerTxt: {
    fontWeight: '700',
    color: '#000000',
    paddingHorizontal: 15,
    paddingVertical: 12,
  },
  hint: {
    fontWeight: '400',
    color: '#999999',
    marginTop: 7,
  },
  error: {
    marginTop: 7,

    color: palette.ROYAL_MAIL_RED,
    fontWeight: '400',
  },
});
