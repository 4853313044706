import { Constant } from '@constant';
import { searchForPostCodeService } from '@services/address';
// @ts-ignore
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { searchForPostCodeFailed, searchForPostCodeSuccess } from './searchForPostCodeSlice';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* searchForPostCodeSaga({ payload, meta, error }): Generator<
  | CallEffect
  | PutEffect<{
      payload: any;
      type: string;
    }>,
  void
> {
  try {
    const res = yield call(searchForPostCodeService, payload);
    console.log('searchForPostCodeSaga>>>>', res);
    if (res?.status) {
      yield put(searchForPostCodeSuccess(res));
      if (meta) {
        yield call(meta, res);
      }
    } else {
      // @ts-ignore
      yield put(searchForPostCodeFailed(Constant.ERROR_NO_RESPONSE));
      if (error) {
        yield call(error, Constant.ERROR_NO_RESPONSE);
      }
    }
  } catch (err) {
    // @ts-ignore
    yield put(searchForPostCodeFailed(err));
    if (error) {
      yield call(error, err);
    }
  }
}
