import { POSTING_LOCATION } from '@constant';
import { getListDispatchPointRequest, getListDispatchPointReset, getMapSettingRequest } from '@redux/app/appSlice';
import { dispatchPointListSelector, mapSettingSelector } from '@redux/app/selectors';
import { getProfileCreator } from '@redux/user/selectors';
import { calcDistance } from '@utils/common/locationUtils';
import { useLocation } from '@utils/hooks';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Geolocation from 'react-native-geolocation-service';
import { useDispatch, useSelector } from 'react-redux';
import {NavigateEvent, NavigationHelper} from "@utils/common/navigation";
import {Screens} from "@routes/route";
import {Platform} from "react-native";

export const useLogic = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const mapViewRef = useRef(null);
  // const { lat, long, loading, watchId } = useLocation(null, true, 1200);
  const { lat, long, loading, handleGetLocation,watchId  } = useLocation(props);

  const mapSettings = useSelector(mapSettingSelector);
  const markers = useSelector(dispatchPointListSelector);
  const getProfileData = useSelector(getProfileCreator)?.message?.data;

  const [selectedTab, setSelectedTab] = useState(mapSettings?.postTypes?.[0]);
  const [region, setRegion] = useState(undefined);
  const [selectedMarker, setSelectedMarker] = useState();

  useEffect(() => {
    // setRegion(getRegionForCoordinates(arr));
    handleGetMapSettings();

    return () => {
      Geolocation.clearWatch(watchId);
    };
  }, []);

  useEffect(() => {
    const unsubscribe = props.navigation?.addListener(NavigateEvent.transitionEnd, e => {
      // Do something
      const currentScreen = NavigationHelper.getCurrentScreen(props?.navigation)
      if(currentScreen === Screens.POSTING_LOCATION){
        handleGetLocation()
      }
    });
    return unsubscribe;
  }, [props.navigation]);

  useEffect(() => {
    regionRef.current = region
  }, [region])

  useEffect(() => {
    setRegion({
      longitudeDelta: 0.005,
      latitude: Number(lat) || Number(getProfileData?.latitude) || Number(mapSettings?.defaultCenter?.latitude),
      longitude: Number(long) || Number(getProfileData?.longitude) || Number(mapSettings?.defaultCenter?.longitude),
      latitudeDelta: 0.005,
    });

    if(Platform.OS === 'web'){
      mapViewRef.current?.animateCamera({
          center:{
            latitude: Number(lat) || Number(getProfileData?.latitude) || Number(mapSettings?.defaultCenter?.latitude),
            longitude: Number(long) || Number(getProfileData?.longitude) || Number(mapSettings?.defaultCenter?.longitude),
          },
      })
    }

  }, [loading, lat, long, mapSettings?.defaultCenter, getProfileData?.latitude, getProfileData?.longitude]);

  useEffect(() => {
    if (!selectedTab) {
      setSelectedTab(mapSettings?.postTypes?.[0]);
    }

    if (mapSettings?.postTypes?.[0] && selectedTab) {
      handleLoadMore(regionRef.current);
    }

    handleChangeTab();
  }, [loading, lat, long, mapSettings?.postTypes, selectedTab]);

  const handleChangeTab = useCallback(() => {
    if (regionRef.current) {
      dispatch(getListDispatchPointReset());
      handleLoadMore(regionRef.current);
      setSelectedMarker(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleLoadMore]);

  const handleMarkerPress = useCallback(async coordinate => {
    console.log({});

    if(Platform.OS === 'web'){
      const camera = await mapViewRef.current.getCamera();
      const currentZoom = camera.zoom
      console.log('handleMarkerPress>>>', coordinate, regionRef.current, camera);
      mapViewRef.current.animateCamera({
        ...camera,
        center:{
          latitude: Number(coordinate.latitude) || 0,
          longitude: Number(coordinate.longitude) || 0,
        }
      },1000)
    }
    else{
      mapViewRef.current.animateToRegion({
        ...coordinate,
        latitude: Number(coordinate.latitude) || 0,
        longitude: Number(coordinate.longitude) || 0,
        longitudeDelta: regionRef.current?.longitudeDelta,
        latitudeDelta: regionRef.current?.latitudeDelta,
        // longitudeDelta: 0.005,
        // latitudeDelta: 0.005,
      });
    }

    setSelectedMarker(coordinate);
  }, []);

  const onItemPress = index => {
    setSelectedTab(index);
  };

  const handleGetMapSettings = () => {
    console.log('handleGetMapSettings');
    dispatch(getMapSettingRequest());
  };

  const generateBodyGetList = regionValue => {
    return {
      types: selectedTab?.key || mapSettings?.postTypes?.[0],
      longitude: Number(regionValue?.longitude),
      latitude: Number(regionValue?.latitude),
      points: [
        {
          latitude: Number(regionValue.latitude) + Number(regionValue.latitudeDelta) / 1.5,
          longitude: Number(regionValue.longitude) - Number(regionValue.longitudeDelta) / 1.5,
        },
        {
          latitude: Number(regionValue.latitude) + Number(regionValue.latitudeDelta) / 1.5,
          longitude: Number(regionValue.longitude) + Number(regionValue.longitudeDelta) / 1.5,
        },
        {
          latitude: Number(regionValue.latitude) - Number(regionValue.latitudeDelta) / 1.5,
          longitude: Number(regionValue.longitude) + Number(regionValue.longitudeDelta) / 1.5,
        },
        {
          latitude: Number(regionValue.latitude) - Number(regionValue.latitudeDelta) / 1.5,
          longitude: Number(regionValue.longitude) - Number(regionValue.longitudeDelta) / 1.5,
        },
      ],
    };
  };

  const regionRef = useRef({
    longitudeDelta: 0.005,
    latitude: Number(lat) || Number(getProfileData?.latitude) || Number(mapSettings?.defaultCenter?.latitude),
    longitude: Number(long) || Number(getProfileData?.longitude) || Number(mapSettings?.defaultCenter?.longitude),
    latitudeDelta: 0.005,
  });

  const distanceToRecall = useCallback(regionValue => {
    return calcDistance({
      latCurrent: regionRef.current.latitude,
      lngCurrent: regionRef.current.longitude,
      latTo: regionValue.latitude,
      LngTo: regionValue.longitude,
    });
  }, []);

  const zoomToRecall = useCallback(regionValue => {
    return (
      Math.round(regionValue.longitudeDelta * 100000) / 100000 !==
        Math.round(regionRef.current.longitudeDelta * 100000) / 100000 ||
      Math.round(regionValue.latitudeDelta * 100000) / 100000 !==
        Math.round(regionRef.current.latitudeDelta * 100000) / 100000
    );
  }, []);

  const handleLoadMore = _.debounce(async regionValue => {
    console.log('handleLoadMore>>>', regionValue)
    if (!selectedTab?.key && !mapSettings?.postTypes?.[0]) return;
    // const { zoom } = await mapViewRef.current.getCamera();
    if(!regionValue?.longitudeDelta){
      return
    }
    let zoom = Math.round(Math.log(360 / regionValue.longitudeDelta) / Math.LN2);
    //distanceToRecall * 1000 is meters
    if (
      distanceToRecall(regionValue) * 1000 >= Math.pow(17 - zoom, 2) * POSTING_LOCATION.DISTANCE_TO_RECALL ||
      // === 0 for first call
      distanceToRecall(regionValue) * 1000 === 0 ||
      // zoom to recall
      zoomToRecall(regionValue)
    ) {
      const payload = generateBodyGetList(regionValue);
      regionRef.current = regionValue;
      dispatch(getListDispatchPointRequest(payload));
    }
  }, 500);

  return {
    selectedTab,
    setSelectedTab,
    onItemPress,
    region,
    mapViewRef,
    setSelectedMarker,
    handleMarkerPress,
    selectedMarker,
    t,
    mapSettings,
    handleLoadMore,
    markers,
    regionRef,
  };
};
