const notification = () => {
  let badgeCount = 0;
  let lastMessageId = '';
  // let navigation = {};
  let route = {};

  const init = (
    // propsNavigation,
    propsRoute,
  ) => {
  };

  const setRoute = propsRoute => {
    route = propsRoute;
  };

  const getRoute = () => route;
  const requestUserPermission = async () => {
    console.log('-----requestUserPermissionNotiFcm');
  };

  const setBadgeCount = count => {
    badgeCount = count;
    // PushNotification.setApplicationIconBadgeNumber(count)
  };

  // const onProfile = (id) => {
  //   NavigationUtils.navigate(ROUTER.AUTHENTICATION.Profile, {id});
  // }

  const handleUserInteractionNotification = async (message, isOpenedApp = false) => {

  };
  const getToken = async () => '';
  const callAPINotify = async () => {
  };

  const handleNotiOnForeground = message => {

  };

  const saveDeviceToken = newFcmToken => {

  };

  return {
    setBadgeCount,
    requestUserPermission,
    init,
    getToken,
    callAPINotify,
    getRoute,
    setRoute,
    handleNotiOnForeground,
  };
};

export const AppNotification = notification();
