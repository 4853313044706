import { saveAnswerQuiz } from '@redux/quizzes/getListQuizSlice';
import { getAnswerQuiz, getQuizCreator } from '@redux/quizzes/selectors';
import { unlockSkillRequest } from '@redux/user/unlockSkillSlice';
import { updateProfileRequest } from '@redux/user/updateProfileSlice';
import { navigatePop, navigatePush } from '@routes/navigationUtils';
import { Screens } from '@routes/route';
import { getInitialFormValue, getInitialFormValueOrigin, getYupStructure, getYupStructureOrigin } from '@utils/form';
import { getContentTitle, mappingStepOrigin } from '@utils/transform/form';
import { QuizTransform } from '@utils/transform/quiz';
import { useFormik } from 'formik';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

export const useQuizDetail = props => {
  const paramsStep = props?.route.params?.step ?? 1;
  const getQuizData = useSelector(getQuizCreator);
  const getAnswerQuizData = useSelector(getAnswerQuiz);

  const quizData = mappingStepOrigin(cloneDeep(getQuizData)?.quiz?.values) ?? [];
  const rawData = cloneDeep(quizData[paramsStep - 1]);
  const headerTitle = getContentTitle(getQuizData?.quiz?.name);
  const isLastStep = paramsStep === quizData.length;
  const isFirstStep = paramsStep === 1;
  const [step, setStep] = useState(paramsStep);
  const [data, setData] = useState([rawData]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: getInitialFormValueOrigin([rawData], { ...getAnswerQuizData[getQuizData?.quiz?.id] }),
    validationSchema: Yup.object().shape(getYupStructureOrigin([rawData], t, true)),
    initialErrors: false,
    onSubmit: values => {
      console.log('onSubmit>>>', values, isLastStep);
      // updateUserApi(values, isLastStep);
      const quizId = getQuizData?.quiz?.id;
      // const questionId =
      const payload = {};
      payload[quizId] = cloneDeep(values);

      dispatch(saveAnswerQuiz({ ...payload }));
      if (isLastStep) {
        onSavePress(values);
      } else {
        onNextPress(values);
      }
    },
  });

  console.log('useQuizDetail>>>', rawData, data);
  useEffect(() => {}, [dispatch]);

  const getRandomInt = max => {
    return Math.floor(Math.random() * max);
  };

  const unlockSkillApi = () => {
    const skill_key = getQuizData?.quiz?.skill_key;
    const payload = {
      skill_key: skill_key,
    };
    // payload[skill_key] = 1;
    dispatch(
      unlockSkillRequest(
        payload,
        rs => {
          navigatePush(Screens.QUIZ_SUCCESSFUL_SCREEN, { data: rs.message });
        },
        err => {},
      ),
    );
  };

  const onSavePress = values => {
    //FIXME: merge values to getAnswerQuizData
    console.log('onSavePress>>>0>>>', values);
    const data = cloneDeep(getAnswerQuizData);
    const formData = getQuizData?.quiz?.values;
    values = QuizTransform.fixQuizAnswer(values, formData);
    const item = formData?.find(x => x._id === getQuizData?.quiz?.id);

    let rs = data[getQuizData?.quiz?.id];
    rs = {
      ...rs,
      ...values,
    };
    data[getQuizData?.quiz?.id] = rs;

    const isCorrect = QuizTransform.checkCorrectAnswer(data, getQuizData?.quiz);
    // const r = getRandomInt(2);
    if (isCorrect) {
      //TODO: call api here
      // navigatePush(Screens.QUIZ_SUCCESSFUL_SCREEN);
      unlockSkillApi();
    } else {
      navigatePush(Screens.QUIZ_FAILED_SCREEN);
    }
  };

  const onNextPress = values => {
    console.log('onNextPress>>>', step);
    //TODO: save data here
    navigatePush(Screens.QUIZ_DETAIL_SCREEN, { step: step + 1 });
  };

  const onBackPress = () => {
    navigatePop();
  };

  const onSwipeLeft = x => {
    console.log('onSwipeLeft>>>', x);
    //TOOD: next screen
    formik.handleSubmit()
  };

  const onSwipeRight = x => {
    console.log('onSwipeRight>>>', x);
    //TODO: goback
    // if(Platform.OS === 'android'){
    //   onBackPress()
    // }

      onBackPress()

  };

  return { isLastStep, onSavePress, onNextPress, step, data, isFirstStep,
    onBackPress,
    formik,
    headerTitle,
    onSwipeLeft,
    onSwipeRight
  };
};
