import { LogoIcon, MailIcon, PadLockIcon } from '@assets';
import { AppSafeAreaView, AppInput, AppRadio, AppText, AuthFooter, AppButton, AppKAView } from '@components';
import { Device } from '@theme/device';
import { StringHelper } from '@utils/common/string';
import { useKeyboard } from '@utils/hooks';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Keyboard, ScrollView, View } from 'react-native';
import { Platform } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useLogic } from './Login.hook';
import { styles as stylesMobile } from './Login.style';
import { stylesWeb } from './Login.style.web';

let styles = Platform.OS === 'web' ? stylesWeb : stylesMobile;

const Login = () => {
  const { rememberMe, handleToggleRememberMe, handleSignUpPress, handleForgotPress, handleLogin, formik } = useLogic();
  const { t } = useTranslation();
  const KAView = Device.isIos ? AppKAView : Fragment;
  const KAScrollView = Device.isIos ? ScrollView : KeyboardAwareScrollView;
  const keyboardShow = useKeyboard();
  return (
    <>
      <AppSafeAreaView>
        <KAView>
          <View style={styles.container}>
            <KAScrollView keyboardShouldPersistTaps="handled" contentContainerStyle={styles.form}>
              <View style={styles.logo}>
                <LogoIcon />
              </View>
              <AppInput
                // onChangeText={formik.handleChange('username')}
                onChangeText={text => {
                  formik.setFieldValue('username', StringHelper.removeSpace(text));
                }}
                value={formik.values.username}
                error={formik.errors.username}
                placeholder={t('EMAIL')}
                PreIcon={MailIcon}
                style={styles.mb20}
              />
              <AppInput
                onChangeText={formik.handleChange('password')}
                value={formik.values.password}
                error={formik.errors.password}
                placeholder={t('PASSWORD')}
                PreIcon={PadLockIcon}
                isPassword
                style={styles.mb10}
              />
              <View style={styles.rememberMe}>
                <AppRadio
                  border={false}
                  data={{ id: 1, content: t('REMEMBER_ME') }}
                  onSelect={handleToggleRememberMe}
                  selected={rememberMe}
                  textStyle={{ color: '#666666' }}
                />
                <AppText onPress={handleForgotPress} style={styles.highlightText}>
                  {t('FORGOT_PASSWORD')}
                </AppText>
              </View>
            </KAScrollView>
            <View style={{ backgroundColor: 'white' }}>
              <AppButton onPress={formik.handleSubmit} text={t('LOGIN')} />
            </View>
          </View>
        </KAView>
      </AppSafeAreaView>
      {!keyboardShow && (
        <>
          <View style={styles.signUpBox}>
            <AppText style={styles.signUpText}>{t('DONT_HAVE_AN_ACCOUNT')}</AppText>
            <AppText style={styles.highlightText} onPress={handleSignUpPress}>
              {t('SIGN_UP')}
            </AppText>
          </View>
          <AuthFooter />
        </>
      )}
    </>
  );
};

export { Login };
