export const MenuMockData = [
  {
    heading: {
      en: 'Personal',
    },
    data: [
      {
        icon: '',
        title: {
          en: 'Update address + other attributes',
        },

        type: 'link',
        content: {
          en: '<p /> or link',
        },
      },
      {
        icon: '',
        title: {
          en: 'Report holidays',
        },
        type: 'link',
        content: {
          en: '<p /> or link',
        },
      },
      {
        icon: '',
        title: {
          en: 'Watch bonus points history',
        },

        type: 'link',
        content: {
          en: '<p /> or link',
        },
      },
      {
        icon: '',
        title: {
          en: 'Request voucher',
        },

        type: 'html',
        content: {
          en: '<p /> or link',
        },
      },
      {
        icon: '',
        title: {
          en: 'Impress',
        },

        type: 'html',
        content: {
          en: '<p /> or link',
        },
      },
      {
        icon: '',
        title: {
          en: 'Data Protection',
        },

        type: 'html',
        content: {
          en: '<p /> or link',
        },
      },
      {
        icon: '',
        title: {
          en: 'Terms & Conditions',
        },

        type: 'html',
        content: {
          en: '<p /> or link',
        },
      },
    ],
  },
  {
    heading: {
      en: 'Reporting',
    },
    data: [
      {
        icon: '',
        title: {
          en: 'Report testletter',
        },

        type: 'html',
        content: {
          en: '<p /> or link',
        },
      },
      {
        icon: '',
        title: {
          en: 'Report receipt of test package',
        },

        type: 'html',
        content: {
          en: '<p /> or link',
        },
      },
      {
        icon: '',
        title: {
          en: 'Report sending of test bundles',
        },

        type: 'html',
        content: {
          en: '<p /> or link',
        },
      },
      {
        icon: '',
        title: {
          en: 'List of open and upcoming surveys',
        },

        type: 'html',
        content: {
          en: '<p /> or link',
        },
      },
    ],
  },
];
