import { AppText } from '@components';
import { palette } from '@theme/colors';
import { FontSize } from '@theme/size';
import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

export const AppButton = React.memo(
  ({ text, color = 'onClick', style, onPress, disabled, ...TouchableOpacityProps }) => {
    return (
      <View style={[styles.container, disabled ? styles.disabled : {}, style]}>
        <TouchableOpacity onPress={onPress} activeOpacity={0.5} disabled={disabled} {...TouchableOpacityProps}>
          <View style={styles.textWrap}>
            <AppText style={styles.text}>{text}</AppText>
          </View>
        </TouchableOpacity>
      </View>
    );
  },
);

const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: palette.ROYAL_MAIL_RED,
    borderRadius: 1000,
  },
  textWrap: {
    minHeight: 48,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
  },
  text: {
    color: '#F5F5F5',
    fontWeight: '400',
    fontSize: FontSize.Font16,
    // textTransform: 'capitalize',
  },
  disabled: {
    opacity: 0.5,
  },
});
