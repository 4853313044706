import theme from '@theme';
import { palette } from '@theme/colors';
import { StyleSheet, Platform } from 'react-native';

export const AddReportStyle = StyleSheet.create({
  containerView: {
    // borderTopRightRadius: theme.spacing.lg,
    // borderTopLeftRadius: theme.spacing.lg,
    backgroundColor: palette.WHITE,
    paddingTop: theme.spacing.lg,
    // paddingHorizontal: theme.spacing.md,
  },
  itemView: {
    zIndex: Platform.OS === 'web' ? 'unset' : undefined,
  },
});
