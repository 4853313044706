export const BundleTimetableTest = [
    {
        "bundleId": "C38617",
        "sendDateTarget": "Jun 29",
        "numberOfTestItems": 1,
        "testFormat": "",
        "color": "#da202a",
        "status": "overdue"
    },
    {
        "bundleId": "C38806",
        "sendDateTarget": "Jun 30",
        "numberOfTestItems": 1,
        "testFormat": "",
        "color": "#da202a",
        "status": "overdue"
    },
    {
        "bundleId": "C30320",
        "sendDateTarget": "Jul 01",
        "numberOfTestItems": 1,
        "testFormat": "Large Letter",
        "color": "#da202a",
        "status": "overdue"
    },
    {
        "bundleId": "C30320",
        "sendDateTarget": "Jul 05",
        "numberOfTestItems": 1,
        "testFormat": "Large Letter",
        "color": "#da202a",
        "status": "overdue"
    },
    {
        "bundleId": "C30320",
        "sendDateTarget": "Jul 06",
        "numberOfTestItems": 1,
        "testFormat": "Large Letter",
        "color": "#da202a",
        "status": "overdue"
    },
    {
        "bundleId": "C38477",
        "sendDateTarget": "Jul 09",
        "numberOfTestItems": 1,
        "testFormat": "",
        "color": "#da202a",
        "status": "overdue"
    },
    {
        "bundleId": "C38477",
        "sendDateTarget": "Jul 09",
        "numberOfTestItems": 1,
        "testFormat": "",
        "color": "#2E72C2",
        "status": "today"
    },
    {
        "bundleId": "C30320",
        "sendDateTarget": "Jul 06",
        "numberOfTestItems": 1,
        "testFormat": "Large Letter",
        "color": "#2E72C2",
        "status": "today"
    },
    {
        "bundleId": "C38477",
        "sendDateTarget": "Jul 09",
        "numberOfTestItems": 1,
        "testFormat": "",
        "color": "#da202a",
        "status": "future"
    },
    {
        "bundleId": "C30320",
        "sendDateTarget": "Jul 06",
        "numberOfTestItems": 1,
        "testFormat": "Large Letter",
        "color": "#da202a",
        "status": "future"
    },
]

export const PackageTimetableTest = [
    {
        "packageId": "P4895",
        "sendDateTarget": "Apr 03",
        "color": "#da202a",
        "status": "overdue",
        "title": "TIMELINE_OVERDUE"
    },
    {
        "packageId": "P5284",
        "sendDateTarget": "Apr 26",
        "color": "#da202a",
        "status": "overdue"
    },
    {
        "packageId": "A2022",
        "sendDateTarget": "Jul 05",
        "color": "#da202a",
        "status": "overdue"
    },
    {
        "packageId": "P4321",
        "sendDateTarget": "Jul 07",
        "color": "#da202a",
        "status": "overdue"
    },
    {
        "packageId": "A2022",
        "sendDateTarget": "Jul 05",
        "color": "#da202a",
        "status": "today"
    },
    {
        "packageId": "P4321",
        "sendDateTarget": "Jul 07",
        "color": "#da202a",
        "status": "today"
    },
    {
        "packageId": "A2022",
        "sendDateTarget": "Jul 05",
        "color": "#da202a",
        "status": "future"
    },
    {
        "packageId": "P4321",
        "sendDateTarget": "Jul 07",
        "color": "#da202a",
        "status": "future"
    }
]