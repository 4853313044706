import { ChevronRightIcon, NoAvatarIcon } from '@assets';
import { AppText } from '@components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, TouchableOpacity, View } from 'react-native';
import { useLogic } from './MenuHeader.hook';
import { styles } from './MenuHeader.style';
import PropTypes from "prop-types";

export const MenuHeader = (props) => {
  const { handleGoToProfileEdit, getProfileData } = useLogic();
  const { t } = useTranslation();
  return (
    <TouchableOpacity activeOpacity={0.7} onPress={handleGoToProfileEdit} style={[styles.container, ...props.style]}>
      <NoAvatarIcon />
      <View style={styles.nameWrap}>
        <AppText style={styles.name}>
          {getProfileData?.firstname} {getProfileData?.lastname}
        </AppText>
        <AppText style={styles.navigateText}>
          {t('EDIT_PROFILE')} {'   '}
          <ChevronRightIcon />
        </AppText>
      </View>
    </TouchableOpacity>
  );
};

MenuHeader.propTypes = {
    style: PropTypes.array,
};

MenuHeader.defaultProps = {
    style: []
};
