import api from '@services/api-client';
import { stringify } from 'qs';
import withQuery from 'with-query';

export const submitPackageService = async payload => {
  console.log('submitPackageService>>>', payload)
  const response = await api.put(`/packages/${payload?.id}`, payload)
  console.log('submitPackageService response>>>', response)
  return response?.data
}

