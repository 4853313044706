import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppButton, AppKAView, AppSafeAreaView, AppText } from '@components';
import { AppForm } from '@components/AppForm';
import { FormItemView } from '@components/Form';
import GenericHeader from '@components/GenericHeader';
import { goBack } from '@routes/navigationUtils';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font, Spacing } from '@theme/size';
import { FormTransform, getContentTitle } from '@utils/transform/form';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Text } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useRFID } from './RFID.hook';
import { RFIDStyle } from './RFID.style';

export const RFIDScreen = props => {
  const { t } = useTranslation();
  const { formik, data, scrollToY,
    scrollViewRef, layoutInfo, setLayoutInfo, userLocation,
    subFormRef,
    messageInfo,
    isSuccess,
    isTransponder
  } = useRFID(props);
  return (
    <AppForm
      title={data?.title}
      description={data?.description}
      scrollViewRef={scrollViewRef}
      scrollToY={scrollToY}
      formik={formik}
      attributes={data?.attributes}
      setLayoutInfo={setLayoutInfo}
      layoutInfo={layoutInfo}
      buttonText={isTransponder ? t('CONFIRM') : t('SUBMIT')}
      userLocation={userLocation}
      subFormRef={subFormRef}
      messageInfo={messageInfo}
      isSuccess={isSuccess}
    />
  );
};
