import {
  hideCameraPermissionModal,
  hideLocationPermissionModal,
  hideNotificationImportantModal
} from '@redux/common/commonSlice';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  notificationImportantModalVisible as notificationImportantModalVisibleSelector
} from "@redux/common/selectors";
import {importantNotificationSelector} from "@redux/app/selectors";
import {navigate} from "@routes/navigationUtils";

export const useNotificationImportantModal = props => {
  const dispatch = useDispatch();
  const notificationImportantModalVisible = useSelector(notificationImportantModalVisibleSelector);
  const importantNotificationData = useSelector(importantNotificationSelector)?.data;

  // const data = useSelector()
  const handlePressButton = () => {
      notificationImportantModalVisible && dispatch(hideNotificationImportantModal());
      const screenKey = importantNotificationData?.internalId
      const data = importantNotificationData?.data
      if(screenKey){
        navigate(screenKey, {data})
      }

  };
  const handleHideModal = () => {
    notificationImportantModalVisible && dispatch(hideNotificationImportantModal());
  };
  return {  handlePressButton, handleHideModal, importantNotificationData };
};
