import { ChevronRightIcon, NoAvatarIcon, PencilIcon } from '@assets';
import { AppSafeAreaView, AppKAView, AppText, AppButton } from '@components';
import GenericHeader from '@components/GenericHeader';
import { goBack } from '@routes/navigationUtils';
import { Spacing } from '@theme/size';
import { getContentTitle } from '@utils/transform/form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, ScrollView, View, TouchableOpacity } from 'react-native';
import { useProfileEdit } from './ProfileEdit.hook';
import { styles } from './ProfileEdit.style';

const ProfileItem = ({ data, onPress }) => {
  return (
    <TouchableOpacity
      key={data?.id}
      style={styles.profileItem}
      onPress={() => {
        if (onPress) {
          onPress(data);
        }
      }}>
      <AppText style={styles.itemText}>{getContentTitle(data?.title)}</AppText>
      <ChevronRightIcon />
    </TouchableOpacity>
  );
};

const ProfileEdit = () => {
  const { data, onItemPress, getProfileData, onLogoutPress } = useProfileEdit();
  const { t } = useTranslation();

  return (
    <>
      <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
      <AppSafeAreaView areaStyle={styles.areaStyle}>
        <GenericHeader onBackClicked={goBack} title={t('EDIT_PROFILE')} style={[{ backgroundColor: '#EDEDED' }]} />
        <View style={styles.container}>
          <View style={styles.avatarWrap}>
            <View style={styles.avatar}>
              <NoAvatarIcon width={Spacing.width64} height={Spacing.width64} />
            </View>
            <AppText style={styles.name}>
              {getProfileData?.firstname} {getProfileData?.lastname}
            </AppText>
          </View>
          <ScrollView contentContainerStyle={styles.scrollView}>
            {data?.map(item => {
              return <ProfileItem data={item} onPress={onItemPress} />;
            })}
          </ScrollView>
          <AppButton
            style={styles.button}
            text={t('LOGOUT')}
            onPress={() => {
              onLogoutPress();
            }}
          />
        </View>
      </AppSafeAreaView>
    </>
  );
};

export { ProfileEdit };
