import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppButton, AppKAView, AppSafeAreaView, AppText } from '@components';
import { FormItemView } from '@components/Form';
import GenericHeader from '@components/GenericHeader';
import { UPLOAD_FILE } from '@constant';
import { goBack } from '@routes/navigationUtils';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font, Spacing } from '@theme/size';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Text } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useCDSSubForm } from './CDSSubForm.hook';
import { CDSSubFormStyle } from './CDSSubForm.style';

export const CDSSubFormScreen = props => {
  const { t } = useTranslation();
  const { data, formik, extraFormValues, scrollViewRef, scrollToEnd } = useCDSSubForm(props);
  const renderForm = () => {
    return (
      <>
        <KeyboardAwareScrollView
          innerRef={ref => {
            if (ref) {
              scrollViewRef.current = ref;
            }
          }}
          extraHeight={150}
          style={[
            {
              backgroundColor: palette.WHITE,
              borderTopRightRadius: theme.spacing.lg,
              borderTopLeftRadius: theme.spacing.lg,
              paddingTop: theme.spacing.md,
            },
          ]}
          showsVerticalScrollIndicator={false}
          keyboardShouldPersistTaps="handled">
          {/*<TopBundleView data={summary} />*/}
          <View style={[globalStyle.directionColumm, RegisterFormStyles.paddingSpace, {}]}>
            {data?.attributes?.map((item, index) => {
              return (
                <FormItemView
                  index={index}
                  item={item}
                  formik={formik}
                  isBoldTitle={true}
                  checkCondition={true}
                  // extraData={{
                  //   location: userLocation,
                  //   subForm: subForm,
                  // }}
                  formData={data?.attributes}
                  extraFormValues={extraFormValues}
                  folderUpload={UPLOAD_FILE.folderUpload.cds}
                />
              );
            })}
          </View>
        </KeyboardAwareScrollView>
        <View
          style={[
            RegisterFormStyles.paddingSpace,
            { marginVertical: theme.spacing.sm, marginBottom: Spacing.height25 },
          ]}>
          <AppButton onPress={formik.handleSubmit} text={t('Submit')} />
        </View>
      </>
    );
  };
  return (
    <>
      <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
      <AppSafeAreaView areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>
        <GenericHeader
          onBackClicked={goBack}
          title={t('Misdelivered Items')}
          // title={headerTitle}
          style={[{ backgroundColor: '#EDEDED' }]}
        />
        <View style={[globalStyle.directionColumm, globalStyle.flex1, RegisterFormStyles.containerView]}>
          {renderForm()}
        </View>
      </AppSafeAreaView>
    </>
  );
};
