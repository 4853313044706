import { Constant } from '@constant';
import { palette } from '@theme/colors';
import { Font, FontSize, Spacing } from '@theme/size';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  areaStyle: { backgroundColor: '#EDEDED' },
  container: {
    paddingHorizontal: Spacing.width15,
    flexGrow: 1,
    borderTopRightRadius: Spacing.width20,
    borderTopLeftRadius: Spacing.width20,
    backgroundColor: palette.WHITE,
    alignItems: 'center',
    paddingTop: Spacing.height15,
  },
});
