export const ReportICodeMockData = {
  summary: {
    id: '33048',
  },
  form: {
    title: {
      en: 'Report Test Items',
    },
    description: '',
    attributes: [
      {
        name: {
          en: 'Do you know the DAY, when you received the letter?',
        },
        type: 'boolean',
        internalId: 'receipt_date_known',
        options: [
          {
            name: {
              en: 'Yes',
            },
            internalId: true,
          },
          {
            name: {
              en: 'No',
            },
            internalId: false,
          },
        ],
        errorMessage: {
          en: 'Field is required',
        },
        required: true,
        editable: true,
      },
      {
        name: {
          en: 'Please enter the receipt date',
        },
        type: 'date',
        internalId: 'receipt_date',
        condition: {
          react: 'return receipt_date_known == true;',
        },
        validation: {
          type: 'javascript',
          javascript: 'return moment().diff(moment(receipt_date)) >= 0;',
        },
        errorMessage: {
          en: 'Field is required',
        },
        required: true,
        editable: true,
      },
      {
        name: {
          en: 'Are you sure about this receipt date?',
        },
        type: 'boolean',
        internalId: 'receipt_date_sure',
        options: [
          {
            name: {
              en: 'Yes',
            },
            internalId: true,
          },
          {
            name: {
              en: 'No',
            },
            internalId: false,
          },
        ],
        condition: {
          react: 'return receipt_date_known == true;',
        },
        errorMessage: {
          en: 'Field is required',
        },
        required: false,
        editable: true,
      },
      {
        name: {
          en: 'Do you know the TIME, when you received the letter?',
        },
        type: 'boolean',
        internalId: 'receipt_time_known',
        options: [
          {
            name: {
              en: 'Yes',
            },
            internalId: true,
          },
          {
            name: {
              en: 'No',
            },
            internalId: false,
          },
        ],
        errorMessage: {
          en: 'Field is required',
        },
        required: true,
        editable: true,
      },
      {
        name: {
          en: 'Please enter the receipt time (15min intervalls)',
        },
        condition: {
          react: 'return receipt_time_known === true;',
        },
        type: 'time',
        internalId: 'receipt_time',
        step: 15,
        validation: {
          type: 'javascript',
          javascript: "return moment().diff(moment(receipt_date + ' ' + receipt_time)) >= 0;",
        },
        errorMessage: {
          en: 'Field is required',
        },
        required: true,
        editable: true,
      },
      {
        name: {
          en: 'Are you sure about this time?',
        },
        condition: {
          react: 'return receipt_time_known === true;',
        },
        type: 'boolean',
        internalId: 'receipt_time_sure',
        options: [
          {
            name: {
              en: 'Yes',
            },
            internalId: true,
          },
          {
            name: {
              en: 'No',
            },
            internalId: false,
          },
        ],
        errorMessage: {
          en: 'Field is required',
        },
        required: true,
        editable: true,
      },
      {
        name: {
          en: 'Is there a stamp date on the letter?',
        },
        type: 'boolean',
        internalId: 'confirm_stamp_date',
        options: [
          {
            name: {
              en: 'Yes',
            },
            internalId: true,
          },
          {
            name: {
              en: 'No',
            },
            internalId: false,
          },
        ],
        errorMessage: {
          en: 'Field is required',
        },
        required: true,
        editable: true,
      },
      {
        name: {
          en: 'Please enter the stamp date',
        },
        condition: {
          react: 'return confirm_stamp_date == true;',
        },
        type: 'date',
        internalId: 'stamp_date',
        validation: {
          type: 'javascript',
          javascript: 'return moment().diff(stamp_date) >= 0;',
        },
        errorMessage: {
          en: 'Field is required',
        },
        required: true,
        editable: true,
      },
      {
        name: {
          en: 'Please tell us the amount of postage that is printed on the item',
        },
        description: {
          en: "Where to find the value <br/> <img src='https://spectos-royalmail-dev.s3.eu-central-1.amazonaws.com/app/form/franking_value.png' />",
        },
        type: 'stringlistsingle',
        internalId: 'franking_value',
        errorMessage: {
          en: 'Field is required',
        },
        required: true,
        editable: true,
        options: [
          {
            internalId: 0.68,
            name: {
              en: '68p',
            },
          },
          {
            internalId: 0.95,
            name: {
              en: '95p',
            },
          },
          {
            internalId: 1.05,
            name: {
              en: '£1.05',
            },
          },
          {
            internalId: 1.45,
            name: {
              en: '£1.45',
            },
          },
          {
            internalId: 3.35,
            name: {
              en: '£3.35',
            },
          },
          {
            internalId: 4.45,
            name: {
              en: '£4.45',
            },
          },
          {
            internalId: 5.35,
            name: {
              en: '£5.35',
            },
          },
          {
            internalId: 6.95,
            name: {
              en: '£6.95',
            },
          },
          {
            internalId: 'other',
            name: {
              en: 'Other',
            },
          },
          {
            internalId: 'cant_read',
            name: {
              en: 'don´t know',
            },
          },
        ],
      },
      {
        name: {
          en: 'Please let us know the amount',
        },
        condition: {
          react: "return franking_value == 'other';",
        },
        type: 'integer',
        internalId: 'amount_postage',
        errorMessage: {
          en: 'Field is required',
        },
        required: true,
        editable: true,
      },
      {
        name: {
          en: 'Please let us know any deviations here',
        },
        condition: {
          react: "return franking_value == 'cant_read';",
        },
        type: 'integer',
        internalId: 'deviations',
        errorMessage: {
          en: 'Field is required',
        },
        required: true,
        editable: true,
      },
    ],
  },
};
