import { UPLOAD_FILE } from '@constant';
import { Config } from '@constant/config';
import { FORM } from '@constant/model/RegisterForm';
import { ReportBundleMockData } from '@constant/model/mock/report/bundle';
import { uploadFileRequest } from '@redux/file/uploadFileSlice';
import { getCreateTicketFormRequest } from '@redux/form/getCreateTicketFormSlice';
import { getReportBundleFormRequest } from '@redux/form/getReportBundleFormSlice';
import { getCreateTicketFormCreator, getReportBundleFormCreator } from '@redux/form/selectors';
import { addHolidayRequest } from '@redux/holiday/addHolidaySlice';
import { getListHolidaysRequest } from '@redux/holiday/getListHolidaysSlice';
import { createTicketRequest } from '@redux/ticket/createTicketSlice';
import { getListSubjectRequest } from '@redux/ticket/getListSubjectSlice';
import {getListTicketRequest, refreshListTicket} from '@redux/ticket/getListTicketSlice';
import {getListSubjectCreator, getListSubjectStandard, getForceRefreshListTicket} from '@redux/ticket/selectors';
import { getInitialFormValue, getInitialFormValueOrigin, getYupStructure, getYupStructureOrigin } from '@utils/form';
import { FormTransform, handleShowError, mappingStep, mappingStepOrigin } from '@utils/transform/form';
import { useFormik } from 'formik';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { lazy } from 'yup';
import {goBack, navigate} from "@routes/navigationUtils";
import {Screens} from "@routes/route";
import {FormikHelper} from "@utils/hooks/formik";

export const useAddTicket = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const getListSubjectData = useSelector(getListSubjectStandard);
  // const getListTicketRequestPayload = useSelector(state => state.getListTicket.requestPayload);
  console.log('useAddTicket>>>');
  const scrollViewRef = useRef(null);
  const [layoutInfo, setLayoutInfo] = useState({});

  const [modalShow, setModalShow] = useState(false);
  // const rawData = [
  //   {
  //     _id: 'ticketSubject',
  //     name: {
  //       en: t('SUBJECT'),
  //     },
  //     type: FORM.FORM_TYPE.stringlistsingle,
  //     popup: true,
  //     required: true,
  //     internalId: 'title',
  //     data: getListSubjectData,
  //     errorMessage: {
  //       en: 'Mandatory field',
  //       es: 'Campo obligatorio',
  //       de: 'Pflichfeld',
  //     },
  //   },
  //   {
  //     _id: 'ticketDescription',
  //     name: {
  //       en: t('DESCRIPTION'),
  //     },
  //     type: FORM.FORM_TYPE.textArea,
  //     required: true,
  //     internalId: 'content',
  //     errorMessage: {
  //       en: 'Mandatory field',
  //       es: 'Campo obligatorio',
  //       de: 'Pflichfeld',
  //     },
  //   },
  //   {
  //     _id: 'ticketAttachment',
  //     name: {
  //       en: t('ATTACHMENTS'),
  //     },
  //     type: FORM.FORM_TYPE.imagePicker,
  //     required: false,
  //     internalId: 'attachments',
  //     errorMessage: {
  //       en: 'Mandatory field',
  //       es: 'Campo obligatorio',
  //       de: 'Pflichfeld',
  //     },
  //   },
  // ];
  const getCreateTicketFormData = useSelector(getCreateTicketFormCreator)?.data;
  // const data = mappingStepOrigin(rawData);
  const [data, setData] = useState(null);

  const formik = FormikHelper.useFormikWrapper({
    initialValues: getInitialFormValue(data, {}),
    // validationSchema: Yup.object().shape(getYupStructureOrigin(data, t, true)),
    validationSchema: () =>
      lazy(vl => {
        const values = _.cloneDeep(formik.values);
        console.log('lazy>>>values>>>', vl, values);
        const ys = getYupStructure(data, t, 'attributes', values);
        console.log('getYupStructure>>>', ys, formik);
        return Yup.object().shape(ys);
      }),
    initialErrors: false,
    enableReinitialize: true,
    onSubmit: async (values, { setErrors, resetForm }) => {
      console.log('onSubmit>>>', values);
      // createTicketApi(values);
      // uploadFileApi(values, files => {
      const payload = _.cloneDeep(values);
      payload.attachments = values?.attachments?.map(x => {
        return {
          ...x,
          response: x.url || x.uri,
        };
      });
      // let payload = await FormTransform.uploadFileFormikFDBApi(
      //   values,
      //   data?.attributes,
      //   formik,
      //   dispatch,
      //   UPLOAD_FILE.folderUpload.ticket,
      // );
      createTicketApi(payload, setErrors, resetForm);
      // });
    },
  }, data?.attributes);
  const forceRefreshListTicket = () => {
    const payload = {
    };
    dispatch(refreshListTicket(payload));
  };

  const getCreateTicketFormApi = () => {
    const payload = {
      // number_of_planned_items: summary?.total,
    };
    dispatch(getCreateTicketFormRequest(payload));
  };

  const getListSubjectApi = () => {
    const payload = {};
    dispatch(getListSubjectRequest(payload));
  };
  const scrollToY = y => {
    // scrollViewRef.current.scroll
    scrollViewRef.current?.scrollTo({
      x: 0,
      y: y,
      animated: true,
    });
  };

  useEffect(() => {
    const x = _.cloneDeep(getCreateTicketFormData);
    const rd = mappingStep(!_.isEmpty(x) ? x[0] : null);
    setData(rd);
    console.log('getReportBundleFormData>>>', rd);
  }, [getCreateTicketFormData]);

  const uploadFileApi = (values, onSuccess) => {
    const payload = {};
    const attachments = values.attachments;
    if (!_.isEmpty(attachments)) {
      const formData = new FormData();
      formData.append('folder', UPLOAD_FILE.folderUpload.ticket);
      attachments.forEach(file => {
        if (!_.isEmpty(file)) {
          formData.append('files[]', {
            ...file,
            name: file?.fileName,
          });
        }
      });
      dispatch(
        uploadFileRequest(
          formData,
          rs => {
            onSuccess(rs?.data);
          },
          err => {},
        ),
      );
    } else {
      onSuccess([]);
    }
  };
  useEffect(() => {
    getListSubjectApi();
    getCreateTicketFormApi();
  }, []);

  const createTicketApi = (payload, setErrors, resetForm) => {
    dispatch(
      createTicketRequest(
        payload,
        rs => {
          //TODO: call list api holiday here
          //TODO: upload image here
          forceRefreshListTicket();
          // formik.values.title = '';
          // formik.values.content = '';
          // formik.values.attachments = [];
          resetForm()
          navigate(Screens.SUCCESSFUL_SCREEN, {
            data: rs?.message,
            header: {
              // title: headerTitle,
              backClick: () => {
                goBack();
              },
            },
          });
        },
        err => {
          handleShowError(err?.message, setErrors);
        },
      ),
    );
  };

  return { data, formik, modalShow, setModalShow, scrollToY, scrollViewRef, layoutInfo, setLayoutInfo };
};
