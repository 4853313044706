import { createSlice } from '@reduxjs/toolkit';

export interface IvalidateUserState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IvalidateUserState = {
  loading: false,
  payload: null,
  error: null,
};

const validateUserSlice = createSlice({
  name: 'validateUser',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    validateUserRequest(state, action) {
      state.loading = true;
      // @ts-ignore
      state.payload = null;
      state.error = null;
    },
    validateUserSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    validateUserFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { validateUserRequest, validateUserSuccess, validateUserFailed } = validateUserSlice.actions;

export default validateUserSlice.reducer;
