import { createSlice } from '@reduxjs/toolkit';

export interface IgetD2DFormState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IgetD2DFormState = {
  loading: false,
  payload: null,
  error: null,
};

const getD2DFormSlice = createSlice({
  name: 'getD2DForm',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getD2DFormRequest(state, action) {
      state.loading = true;
      // @ts-ignore
      state.payload = null;
      state.error = null;
    },
    getD2DFormSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    getD2DFormFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getD2DFormRequest, getD2DFormSuccess, getD2DFormFailed } = getD2DFormSlice.actions;

export default getD2DFormSlice.reducer;
