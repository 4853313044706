import theme from '@theme';
import { palette } from '@theme/colors';
import { Spacing } from '@theme/size';
import { StyleSheet } from 'react-native';

export const ListQuizStyle = StyleSheet.create({
  containerView: {
    borderTopRightRadius: theme.spacing.lg,
    borderTopLeftRadius: theme.spacing.lg,
    backgroundColor: palette.WHITE,
    flex: 1,
    // paddingHorizontal: theme.spacing.md,
  },
  viewContainer: {
    marginHorizontal: theme.spacing.md,
    // backgroundColor: palette.CARROT,
  },
  viewSelect: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#C6C6C6',
    marginHorizontal: 0,
    backgroundColor: '#F7F7F7',
    padding: 12,
    borderRadius: 4,
    width: '100%',
    marginBottom: theme.spacing.md,
    // marginLeft: theme.spacing.md,
    // marginRight: theme.spacing.md,
  },
  viewUnSelect: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#C6C6C6',
    marginHorizontal: 0,
    backgroundColor: '#F4F4F4',
    padding: 12,
    borderRadius: 4,
    width: '100%',
    marginBottom: theme.spacing.md,
  },
  itemText: {
    paddingHorizontal: Spacing.width3,
    color: palette.MAIN_BLACK,
    flex: 1,
  },
});
