import {Platform, StyleSheet} from 'react-native';
import {Device} from "@theme/device";
import {WEB_WIDTH} from "@theme";

export const LoadingStyles = StyleSheet.create({
  loadingContainer: {
    // position: 'absolute',
    // width: '100%',
    // height: '100%',
    backgroundColor: 'rgba(0,0,0,0.7)',
    // zIndex: 1,
    // opacity: 0.5,
    bottom: 0,
    flex: 1,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0
  },
  container: {
    width: '100%',
    // height: '100%',
    flex: 1,
  },
  children: {
    // width: Platform.OS === 'web' ? WEB_WIDTH : Device.width,
    // height: Device.height,
    marginHorizontal: Platform.OS === 'web' ? 'auto' : 0,
    // position: 'relative',
    backgroundColor: 'rgba(0,0,0,0.7)',
    bottom: 0,
    flex: 1,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0
  },
  backdrop: {
    // backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  background: {
    alignItems: 'center',
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0
  },
});
