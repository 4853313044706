// import { formatDate } from '@/utils/dateUtils'
// import { translateText } from '@/assets/translations/i18n';
import { AppText } from '@components';
import { globalStyle } from '@theme';
// import { Colors, Dimens, styles } from '@/constant';
// import { getColorPriority, getColorPriorityWithList } from '@/utils/colorUtils';
// import { getTextPriority } from '@/utils/textUtils';
import { DATE_FORMAT, formatDate, formatDateV1 } from '@utils/common/MomentUtils';
import { getColor, getText } from '@utils/transform/ticket';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, TouchableOpacity } from 'react-native';
import Icon5 from 'react-native-vector-icons/FontAwesome';
import { useTicketItem } from './TicketItem.hook';
import { TicketItemStyles } from './TicketItem.style';

export const StatusView = ({ status, colors, dictStatus }) => {
  const text = getText(status, dictStatus);
  // console.log('getText>>>', text);

  return (
    <View
      style={{
        borderRadius: 2,
        backgroundColor: getColor(status, colors),
      }}>
      <AppText style={[TicketItemStyles.priority]}>
        {/*{getTextPriority(priority)}*/}
        {text}
      </AppText>
    </View>
  );
};
export const TicketItemView = props => {
  const { t } = useTranslation();
  const data = props.data;

  // console.log('TicketItemView>>>>', props)

  return (
    <TouchableOpacity
      style={[
        // styles.fill,
        globalStyle.directionColumm,
        TicketItemStyles.container,
        // { marginBottom: props.isLast ? Dimens.Margin.Normal : 0 },
      ]}
      onPress={() => {
        props.onPress(data);
      }}>
      <View style={[globalStyle.directionRow]}>
        <AppText style={[TicketItemStyles.textTitle]}>
          {'ID: '}
          {`${data?.properties?.incrementalID}  `}
          <View style={[TicketItemStyles.viewLine]} />
          <AppText style={[TicketItemStyles.textSecondTitle]}>{`  ${data?.title}`}</AppText>
        </AppText>
      </View>

      <AppText style={[TicketItemStyles.textNormal]}>{formatDateV1(data?.createdAt, DATE_FORMAT.FullDate)}</AppText>
      <View style={[globalStyle.flex1, globalStyle.directionRow, globalStyle.center]}>
        <StatusView status={data?.resolution ?? data?.process} colors={props.colors} dictStatus={props.dictStatus} />

        <AppText style={[globalStyle.flex1]} />
      </View>
    </TouchableOpacity>
  );
};

TicketItemView.propTypes = {
  data: PropTypes.object.isRequired,
  isLast: PropTypes.bool.isRequired,
  onPress: PropTypes.func.isRequired,
  priority_list: PropTypes.array,
  colors: PropTypes.object,
  dictStatus: PropTypes.object,
};

TicketItemView.defaultProps = {};
