import _ from 'lodash';

export const ViewUtil = {
  scrollToEnd: (scrollView, timeout = 200, animated = false) => {
    if (_.isEmpty(scrollView)) {
      return;
    }
    // view.scrollToEnd()
    setTimeout(() => {
      scrollView?.scrollToEnd({ animated: animated });
      console.log('scrollToEnd>>>', scrollView);
    }, timeout);
  },
};
