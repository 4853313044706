import { getQuizCreator } from '@redux/quizzes/selectors';
import { popToTop } from '@routes/navigationUtils';
import { getContentTitle } from '@utils/transform/form';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {getDashboardRequest} from "@redux/app/appSlice";
import {getListQuizRequest} from "@redux/quizzes/getListQuizSlice";
import {getProfileRequest} from "@redux/user/getProfileSlice";
import {useRoute} from "@react-navigation/native";

export const useQuizSuccessful = navigation => {
  const dispatch = useDispatch();
  const getQuizData = useSelector(getQuizCreator);
  const headerTitle = getContentTitle(getQuizData?.quiz?.name);
  const messageInfo = useRoute().params?.data
  useEffect(() => {
    // dispatch(getDashboardRequest());

    dispatch(getListQuizRequest());
    dispatch(getProfileRequest());
    // setTimeout(() => {
    //   popToTop();
    // }, 7000);
  }, [dispatch]);
  return { headerTitle, messageInfo };
};
