export const UserMockData = {
  id: 0,
  username: 'string',
  firstname: 'string',
  lastname: 'string',
  prefix: 0,
  title: 'string',
  companyname: 'string',
  street: 'string',
  housenr: 'string',
  postalCode: 'string',
  city: 'string',
  region: 'string',
  available_from: 'string',
  available_to: 'string',
  birthday: 'string',
  email_announce: true,
  email: 'string',
  password: 'string',
  phone: 'string',
};
