import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import { DASHBOARD_SCREEN, LIST_QUIZ_SCREEN, Screens, SURVEY_LIST_SCREEN } from '@routes/route';
import { Dashboard, SurveyListScreen } from '@screens';
import { CDSFormScreen } from '@screens/main/CDS/form';
import { D2DOverviewScreen } from '@screens/main/D2D/overview';
import { ListQuizScreen } from '@screens/main/quiz/ListQuiz';
import React from 'react';

const DashboardStack = createStackNavigator();

export const DashboardStackScreen = () => {
  return (
    <DashboardStack.Navigator initialRouteName={DASHBOARD_SCREEN}>
      <DashboardStack.Screen
        name={DASHBOARD_SCREEN}
        component={Dashboard}
        options={{
          headerShown: false,
          ...TransitionPresets.SlideFromRightIOS,
        }}
      />
      <DashboardStack.Screen
        name={LIST_QUIZ_SCREEN}
        component={ListQuizScreen}
        options={{
          headerShown: false,
          ...TransitionPresets.SlideFromRightIOS,
        }}
      />
      <DashboardStack.Screen
        name={SURVEY_LIST_SCREEN}
        component={SurveyListScreen}
        options={{
          headerShown: false,
          ...TransitionPresets.SlideFromRightIOS,
        }}
      />
      <DashboardStack.Screen
        name={Screens.D2D_OVERVIEW}
        component={D2DOverviewScreen}
        options={{
          headerShown: false,
          ...TransitionPresets.SlideFromRightIOS,
        }}
      />
      {/*<DashboardStack.Screen*/}
      {/*  name={Screens.CDS_FORM_SCREEN}*/}
      {/*  component={CDSFormScreen}*/}
      {/*  options={{*/}
      {/*    headerShown: false,*/}
      {/*    ...TransitionPresets.SlideFromRightIOS,*/}
      {/*  }}*/}
      {/*/>*/}
    </DashboardStack.Navigator>
  );
};
