import {createAction, createSlice} from '@reduxjs/toolkit';

export interface IgetListTicketState {
  loading: boolean;
  payload: any;
  error: any;
  requestPayload: any,
  forceRefresh: false,
}

// @ts-ignore
const initialState: IgetListTicketState = {
  loading: false,
  payload: null,
  error: null,
  requestPayload: null,
  forceRefresh: false,
};

export const getListTicketRequest = createAction('getListTicket/getListTicketRequest', (payload, onSuccess, onFailed) => {
  console.log('prepareAction>>>', payload, onSuccess);
  return {
    forceRefresh: false,
    payload: payload,
    meta: onSuccess,
    error: onFailed,
  };
});


const getListTicketSlice = createSlice({
  name: 'getListTicket',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // getListTicketRequest(state, action) {
    //   console.log('getListTicketRequest>>>', action)
    //   state.loading = true;
    //   // @ts-ignore
    //   // state.payload = null;
    //   state.requestPayload = action.payload
    //   state.error = null;
    // },
    refreshListTicket(state, action) {
      // @ts-ignore
      state.forceRefresh = true
    },
    getListTicketSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
      state.forceRefresh = false
    },
    getListTicketFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.forceRefresh = false
    },
  }
});

export const { getListTicketSuccess, getListTicketFailed, refreshListTicket } = getListTicketSlice.actions;

export default getListTicketSlice.reducer;
