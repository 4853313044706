// import { forVerticalIOS } from '@react-navigation/stack/src/TransitionConfigs/CardStyleInterpolators';
import { forFade } from '@react-navigation/stack/src/TransitionConfigs/HeaderStyleInterpolators';
import { TransitionIOSSpec } from '@react-navigation/stack/src/TransitionConfigs/TransitionSpecs';
import { StackCardInterpolatedStyle, StackCardInterpolationProps } from '@react-navigation/stack/src/types';
import { Animated } from 'react-native';

function forVerticalIOS({
  current,
  inverted,
  layouts: { screen },
}: StackCardInterpolationProps): StackCardInterpolatedStyle {
  // @ts-ignore
  const translateY = Animated.multiply(
    current.progress.interpolate({
      inputRange: [0, 1],
      outputRange: [-screen.height, 0],
      extrapolate: 'clamp',
    }),
    inverted,
  );

  return {
    cardStyle: {
      transform: [{ translateY }],
    },
  };
}
// @ts-ignore
export const ModalSlideFromTopIOS = {
  gestureDirection: 'vertical',
  transitionSpec: {
    open: TransitionIOSSpec,
    close: TransitionIOSSpec,
  },
  cardStyleInterpolator: forVerticalIOS,
  headerStyleInterpolator: forFade,
};
