import { data as correos } from './correos';
import { data as royalmail } from './royalmail';

export const AppCustomer = {
    royalmail: 'royalmail',
    correos: 'correos'
}

export const SVGIcons = {
    MailIcon: "MailIcon",
    EyeActiveIcon: "EyeActiveIcon",
    MarkerDashboardIcon: "MarkerDashboardIcon",
    PadLockIcon: "PadLockIcon",
    EyeInactiveIcon: "EyeInactiveIcon",
    NumPadIcon: "NumPadIcon",
    QRIcon: "QRIcon",
    FrameIcon: "FrameIcon",
    RadioIcon: "RadioIcon",
    ActiveRadioIcon: "ActiveRadioIcon",
    CheckIcon: "CheckIcon",
    ActiveCheckIcon: "ActiveCheckIcon",
    ChevronDownIcon: "ChevronDownIcon",
    CalendarIcon: "CalendarIcon",
    ClockIcon: "ClockIcon",
    TickIcon: "ClockIcon",
    CrossIcon: "CrossIcon",
    MarkerQRIcon2: "MarkerQRIcon2",
    ChevronPickerIcon: "ChevronPickerIcon",
    UserIcon: "UserIcon",
    MarkerQRIcon: "MarkerQRIcon",
    LogoIcon: "LogoIcon",
    MessageCheckIcon: "MessageCheckIcon",
    ThankYouIcon: "ThankYouIcon",
    WarningIcon: "WarningIcon",
    QRIcon2: "QRIcon2",
    HomeIcon: "HomeIcon",
    TaskIcon: "TaskIcon",
    FAQIcon: "FAQIcon",
    MenuIcon: "MenuIcon",
    BackIcon: "BackIcon",
    NoAvatarIcon: "NoAvatarIcon",
    ChevronRightIcon: "ChevronRightIcon",
    PencilIcon: "PencilIcon",
    PassedIcon: "PassedIcon",
    FailedIcon: "FailedIcon",
    ArrowRightIcon: "ArrowRightIcon",
    ArrowLeftIcon: "ArrowLeftIcon",
    ArrowBorderIcon: "ArrowBorderIcon",
    ClearIcon: "ClearIcon",
    FilterIcon: "FilterIcon",
    ImageIcon: "ImageIcon",
    CirclePlusIcon: "CirclePlusIcon",
    SentIcon: "SentIcon",
    LogoBigIcon: "LogoBigIcon",
    OverdueIcon: "OverdueIcon",
    AnswerIcon: "AnswerIcon",
    AwaitingIcon: "AwaitingIcon"
}

export const MappingCountry = {
    ES: AppCustomer.correos,
    GB: AppCustomer.royalmail,
}
export const DefaultData = {
    royalmail: royalmail,
    correos: correos
}