import { CrossIcon, LogoIcon, MessageCheckIcon } from '@assets';
import { AppAlert, AppButton, AppDateTimePicker, AppKAView, AppSafeAreaView, AppText } from '@components';
import { ForceUpdateStyles } from '@components/AppForceUpdate/ForceUpdate.style';
import { FormItemView } from '@components/Form';
import GenericHeader from '@components/GenericHeader';
import { goBack } from '@routes/navigationUtils';
import Colors from '@screens/main/report/ListReport/Colors';
import Dimens from '@screens/main/report/ListReport/Dimens';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font, FontSize, Spacing } from '@theme/size';
import { formatDate, getDateByAddedDay } from '@utils/common/MomentUtils';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Text, ScrollView, Platform, TouchableOpacity } from 'react-native';
import { useAddReport } from './AddReport.hook';
import { AddReportStyle } from './AddReport.style';

export const AddReportScreen = props => {
  const { t } = useTranslation();
  const { data, formik, modalShow, setModalShow } = useAddReport(props);
  return (
    <>
      <View style={[globalStyle.directionColumm, globalStyle.flex1, AddReportStyle.containerView]}>
        <ScrollView style={[]} keyboardShouldPersistTaps="handled">
          <View style={[globalStyle.directionColumm, RegisterFormStyles.paddingSpace]}>
            {data?.attributes?.map((item, index) => {
              return <FormItemView index={index} item={item} formik={formik} style={[AddReportStyle.itemView]} />;
            })}
          </View>
        </ScrollView>
        <View
          style={{
            marginBottom: Platform.OS === 'web' ? '20px' : Spacing.height25,
            marginHorizontal: Spacing.height25,
          }}>
          <AppButton text={t('REPORT')} onPress={formik.handleSubmit} />
        </View>
        <AppAlert
          is7Percent
          show={modalShow}
          onDismiss={() => setModalShow(false)}
          customView={
            <View style={[ForceUpdateStyles.rootView]}>
              <TouchableOpacity
                onPress={() => setModalShow(false)}
                style={{
                  position: 'absolute',
                  top: 6,
                  right: 6,
                  backgroundColor: '#c9c9c9',
                  zIndex: 1,
                  padding: 6,
                  borderRadius: 1000,
                }}>
                <CrossIcon color="white" />
              </TouchableOpacity>
              <View style={[globalStyle.directionColumm, { paddingVertical: Spacing.height20 }]}>
                <View style={[globalStyle.center]}>
                  <MessageCheckIcon color={palette.GREEN_ACTIVE} />
                </View>
                <View
                  style={[
                    globalStyle.alignCenter,
                    globalStyle.justifyCenter,
                    globalStyle.alignSelfCenter,
                    globalStyle.directionColumm,
                  ]}>
                  <AppText
                    style={[
                      Font.Bold_700,
                      { textAlign: 'center', margin: theme.spacing.sm, fontSize: FontSize.Font16 },
                    ]}>
                    {t('ADD_HOLIDAY_SUCCESSFUL_TITLE')}
                  </AppText>
                  <AppText
                    style={[
                      {
                        textAlign: 'center',
                        marginHorizontal: theme.spacing.sm,
                        fontSize: FontSize.Font15,
                        color: '#666666',
                      },
                    ]}>
                    {t('ADD_HOLIDAY_SUCCESSFUL_DESCRIPTION')}
                  </AppText>
                </View>
              </View>
            </View>
          }
        />
      </View>
    </>
  );
};
