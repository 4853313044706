import theme from '@theme';
import { FontSize } from '@theme/size';
import { AppSetting } from '@utils/common/setting';
import { StyleSheet } from 'react-native';

export const ReimbursementStyle = StyleSheet.create({
  textNormal: {
    color: '#666666',
    fontSize: FontSize.Font14,
    marginVertical: 8,
    // color: 'green',

    // fontSize: Dimens.FontSize.Small,
    // fontWeight: Dimens.FontWeight.Normal,
  },
  point: {
    paddingHorizontal: theme.spacing.md,
    paddingTop: 4,
    paddingBottom: 4,

    borderRadius: 10,
    borderColor: AppSetting.state?.colors?.ROYAL_MAIL_RED,
    backgroundColor: '#E9E9E9',
  },
  textPoint: {
    color: AppSetting.state?.colors?.MAIN_BLACK,
    fontSize: FontSize.Font14,
    // fontWeight: '700',
  },
});
