import type { RootState } from '@redux/reducers';
import { createSelector } from '@reduxjs/toolkit';
import { getErrorMessage } from '@utils/api';
import {mappingPostcode} from "@utils/transform/address";

export const searchForPostCodeSelector = (state: RootState) => state.searchForPostCode;
export const searchForPostCodeCreator = createSelector(searchForPostCodeSelector, state => state.payload);
export const searchForPostCodeLoading = createSelector(searchForPostCodeSelector, state => state.loading);
export const searchForPostCodeError = createSelector(searchForPostCodeSelector, state => state.error);
export const searchForPostCodeErrorMsg = createSelector(searchForPostCodeError, state => getErrorMessage(state));
export const searchForPostCodeStandard = createSelector(searchForPostCodeSelector, state => mappingPostcode(state?.data));

export const searchForAddressSelector = (state: RootState) => state.searchForAddress;
export const searchForAddressCreator = createSelector(searchForAddressSelector, state => state.payload);
export const searchForAddressLoading = createSelector(searchForAddressSelector, state => state.loading);
export const searchForAddressError = createSelector(searchForAddressSelector, state => state.error);
export const searchForAddressErrorMsg = createSelector(searchForAddressError, state => getErrorMessage(state));

