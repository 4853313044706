import { getDetailDispatchPoint } from '@redux/app/appSlice';
import { dispatchPointSelector } from '@redux/app/selectors';
import { navigate } from '@routes/navigationUtils';
import { PROFILE_EDIT_SCREEN } from '@routes/route';
import { handleShowError } from '@utils/transform/form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export const useLogic = props => {
  const { data, region } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [detail, setDetail] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setDetail(null);
    handleGetDetail();
  }, [data]);

  const handleGetDetail = () => {
    const payload = { id: data?.id, longitude: region?.longitude, latitude: region?.latitude };

    dispatch(
      getDetailDispatchPoint(
        payload,
        res => {
          setDetail(res.data);
          setLoading(false);
        },
        err => {
          console.log({ resfadfsda: err });
          handleShowError(err);
        },
      ),
    );
  };

  const handleGoToProfileEdit = () => {
    navigate(PROFILE_EDIT_SCREEN);
  };
  return { handleGoToProfileEdit, detail, loading, t };
};
