import { CalendarIcon, ClockIcon, LogoIcon, MessageCheckIcon } from '@assets';
import { AppAlert, AppKAView, AppSafeAreaView, AppText } from '@components';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font } from '@theme/size';
import { DATE_FORMAT, formatDate } from '@utils/common/MomentUtils';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, TouchableOpacity } from 'react-native';
import { Calendar, CalendarList, Agenda } from 'react-native-calendars';
import { backgroundColor } from 'react-native-calendars/src/style';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { useAppCalendar } from './AppCalendar.hook';
import { AppCalendarStyle as styles } from './AppCalendar.style';

export const AppCalendarView = props => {
  const { t } = useTranslation();
  const {} = useAppCalendar(props);
  const { value, error, style, title, onPickDate, maxDate, minDate, isRequired, disabled } = props;
  const [visible, setVisible] = useState(false);
  console.log('AppCalendarView>>>', props);
  const handleCancel = () => {
    setVisible(false);
  };

  const handleOpenDatePick = () => {
    setVisible(true);
  };

  const handleConfirm = date => {
    onPickDate(date);
    setVisible(false);
  };

  const initialDate = moment(value || moment()).toDate();
  const selectedDate = formatDate(initialDate, props.fromFormat, props.format);
  console.log('AppCalendarView>>>', selectedDate, props, initialDate);
  const markDate = {};
  markDate[selectedDate] = { selected: true, marked: true };
  const getCorrectValue = value => {
    const rs = formatDate(value, props.format, props.fromFormat);
    console.log('getCorrectValue>>>', value, props, rs);
    return rs;
  };
  return (
    <View style={[styles.container, style]}>
      <AppText style={styles.textTitle}>
        {isRequired && <AppText style={styles.isRequired}>*</AppText>} {title}
      </AppText>
      <View>
        <TouchableOpacity
          disabled={disabled}
          onPress={handleOpenDatePick}
          activeOpacity={0.7}
          style={[styles.viewSelect, { opacity: disabled ? 0.5 : 1 }]}>
          <CalendarIcon />
          <AppText style={[styles.textSelect, value ? styles.textAfterSelect : {}]}>
            {value ? getCorrectValue(value) : t('SELECT_DATE')}
          </AppText>
        </TouchableOpacity>
      </View>
      {!!error && <AppText style={styles.error}>{error}</AppText>}
      {visible && (
        <AppAlert
          isCalendar
          contentContainerStyle={[{ padding: 10, borderRadius: 15 }]}
          show={visible}
          closeOnTouchOutside={() => {
            setVisible(false);
          }}
          onDismiss={() => {
            setVisible(false);
          }}
          customView={
            <Calendar
              // Initially visible month. Default = now
              // initialDate={'2012-03-01'}
              markedDates={{ ...markDate }}
              theme={
                {
                  // backgroundColor: '#ffffff',
                  // calendarBackground: '#ffffff',
                  // textSectionTitleColor: '#b6c1cd',
                  // textSectionTitleDisabledColor: '#d9e1e8',
                  // selectedDayBackgroundColor: '#00adf5',
                  // selectedDayTextColor: 'blue',
                  // todayTextColor: '#00adf5',
                  // // dayTextColor: 'red',
                  // textDisabledColor: '#d9e1e8',
                  // dotColor: '#00adf5',
                  // selectedDotColor: 'red',
                  // arrowColor: 'orange',
                  // disabledArrowColor: '#d9e1e8',
                  // monthTextColor: 'blue',
                  // indicatorColor: 'blue',
                  // textDayFontFamily: 'monospace',
                  // textMonthFontFamily: 'monospace',
                  // textDayHeaderFontFamily: 'monospace',
                  // textDayFontWeight: '300',
                  // textMonthFontWeight: 'bold',
                  // textDayHeaderFontWeight: '300',
                  // textDayFontSize: 16,
                  // textMonthFontSize: 16,
                  // textDayHeaderFontSize: 16,
                }
              }
              initialDate={initialDate}
              // Minimum date that can be selected, dates before minDate will be grayed out. Default = undefined
              // minDate={'2012-05-10'}
              // // Maximum date that can be selected, dates after maxDate will be grayed out. Default = undefined
              // maxDate={'2012-05-30'}
              maxDate={maxDate}
              minDate={minDate}
              // Handler which gets executed on day press. Default = undefined
              onDayPress={day => {
                console.log('onDayPress', day);
                setVisible(false);
                // onPickDate(day);
                onPickDate(new Date(day.year, day.month - 1, day.day));
              }}
              // Handler which gets executed on day long press. Default = undefined
              onDayLongPress={day => {
                console.log('onDayLongPress', day, day.dateString, day.month);
                setVisible(false);
                onPickDate(new Date(day.year, day.month - 1, day.day));
              }}
              // Month format in calendar title. Formatting values: http://arshaw.com/xdate/#Formatting
              monthFormat={'MMM yyyy'}
              // Handler which gets executed when visible month changes in calendar. Default = undefined
              onMonthChange={month => {
                console.log('month changed', month);
              }}
              // Hide month navigation arrows. Default = false
              // hideArrows={true}
              // Replace default arrows with custom ones (direction can be 'left' or 'right')
              renderArrow={direction =>
                direction === 'left' ? (
                  <FontAwesome5 name={'angle-double-left'} />
                ) : (
                  <FontAwesome5 name={'angle-double-right'} />
                )
              }
              // Do not show days of other months in month page. Default = false
              hideExtraDays={true}
              // If hideArrows = false and hideExtraDays = false do not switch month when tapping on greyed out
              // day from another month that is visible in calendar page. Default = false
              // disableMonthChange={true}
              // If firstDay=1 week starts from Monday. Note that dayNames and dayNamesShort should still start from Sunday
              // firstDay={1}
              // Hide day names. Default = false
              // hideDayNames={true}
              // Show week numbers to the left. Default = false
              // showWeekNumbers={true}
              // Handler which gets executed when press arrow icon left. It receive a callback can go back month
              // onPressArrowLeft={subtractMonth => subtractMonth()}
              // Handler which gets executed when press arrow icon right. It receive a callback can go next month
              // onPressArrowRight={addMonth => addMonth()}
              // Disable left arrow. Default = false
              // disableArrowLeft={true}
              // Disable right arrow. Default = false
              // disableArrowRight={true}
              // Disable all touch events for disabled days. can be override with disableTouchEvent in markedDates
              // disableAllTouchEventsForDisabledDays={true}
              // Replace default month and year title with custom one. the function receive a date as parameter
              // renderHeader={date => {
              //   /*Return JSX*/
              // }}
              // Enable the option to swipe between months. Default = false
              enableSwipeMonths={true}
              headerStyle={[{ backgroundColor: '#ECECEC' }]}
            />
          }
        />
      )}
    </View>
  );
};

AppCalendarView.propTypes = {
  format: PropTypes.string,
  fromFormat: PropTypes.string,
  placeHolder: PropTypes.string,
};

AppCalendarView.defaultProps = {
  format: DATE_FORMAT.UIDate,
  fromFormat: DATE_FORMAT.StandardDate,
};
