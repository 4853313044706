import theme from '@theme';
import { Device } from '@theme/device';
import {absoluteFill, StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
  containerView: {
    ...StyleSheet.absoluteFillObject,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    backgroundColor: '#EDEDED',
    width: '100%',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    overflow: 'hidden',

    // paddingHorizontal: theme.spacing.md,
  },
  containerViewWeb: {
    ...StyleSheet.absoluteFill,
    // position: 'absolute',
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    // backgroundColor: '#EDEDED',
    backgroundColor: 'white',

    // width: '100%',
    flex: 1,
    // alignItems: 'center',
    // justifyContent: 'flex-end',
    // overflow: 'hidden',

    // paddingHorizontal: theme.spacing.md,
  },
  areaStyle: { backgroundColor: '#EDEDED' },
  map: {
    ...StyleSheet.absoluteFillObject,
    // borderTopRightRadius: 20,
    // borderTopLeftRadius: 20,
    // width: Device.width,
    // height: Device.height,
    // flex: 1,
    // marginBottom: 52,
    // marginTop:64,
    // marginBottom:64,

  },
  mapWeb:{
    ...StyleSheet.absoluteFillObject,
    // borderTopRightRadius: 20,
    // borderTopLeftRadius: 20,
    // width: Device.width,
    // height: Device.height,
    // flex: 1,
    // marginBottom: 52,
    marginTop:64,
    marginBottom:64,
  }
});
