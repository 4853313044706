import {createAction, createSlice} from '@reduxjs/toolkit';

export interface IaddCommentState {
  loading: boolean;
  payload: any;
  error: any;
}

export const addCommentRequest = createAction('addComment/addCommentRequest', (payload, onSuccess, onFailed) => {
  console.log('prepareAction>>>', payload, onSuccess);
  return {
    payload: payload,
    meta: onSuccess,
    error: onFailed,
  };
});

// @ts-ignore
const initialState: IaddCommentState = {
  loading: false,
  payload: null,
  error: null,
};

const addCommentSlice = createSlice({
  name: 'addComment',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // addCommentRequest(state, action) {
    //   state.loading = true;
    //   // @ts-ignore
    //   state.payload = null;
    //   state.error = null;
    // },
    addCommentSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    addCommentFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { addCommentSuccess, addCommentFailed } = addCommentSlice.actions;

export default addCommentSlice.reducer;
