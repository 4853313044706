import { updateUserSelector } from '@redux/user/selectors';
import { createSelector } from '@reduxjs/toolkit';

const allAuthSelector = state => state.auth;

export const authCreator = createSelector(allAuthSelector, authState => authState.userValidation);
export const authUser = createSelector(allAuthSelector, authState => authState.user);
export const activeUserCreator = createSelector(allAuthSelector, authState => authState.activeUser);
export const registerCompletedSelector = createSelector(allAuthSelector, state => state.isRegisterCompleted);
export const forgotFormSelector = createSelector(allAuthSelector, state => state.forgotForm);
