import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import { useRegisterFormStack } from '@routes/RegisterFormStack/RegisterFormStack.hook';
import { MENU_WEB_VIEW_SCREEN, Screens } from '@routes/route';
import { WebView } from '@screens';
import { SearchAddressScreen } from '@screens/main/SearchAddress';
import { SearchPostcodeScreen } from '@screens/main/SearchPostcode';
import { RegisterCompletedScreen } from '@screens/register/RegisterCompleted';
import { RegisterFormScreen } from '@screens/register/RegisterForm';
import React, { FC } from 'react';

const RegisterFormStack = createStackNavigator();
export const RegisterFormStackScreen: FC = () => {
  const {} = useRegisterFormStack();
  return (
    <RegisterFormStack.Navigator initialRouteName={Screens.REGISTER_FORM_SCREEN}>
      <RegisterFormStack.Screen
        name={Screens.REGISTER_FORM_SCREEN}
        component={RegisterFormScreen}
        options={{
          headerShown: false,
          ...TransitionPresets.SlideFromRightIOS,
        }}
      />
      <RegisterFormStack.Screen
        name={Screens.REGISTER_COMPLETED_SCREEN}
        component={RegisterCompletedScreen}
        options={{
          headerShown: false,
          ...TransitionPresets.SlideFromRightIOS,
        }}
      />
      <RegisterFormStack.Screen
        name={Screens.SEARCH_POSTCODE_SCREEN}
        component={SearchPostcodeScreen}
        options={{
          headerShown: false,
          ...TransitionPresets.SlideFromRightIOS,
        }}
      />
      <RegisterFormStack.Screen
        name={Screens.SEARCH_ADDRESS_SCREEN}
        component={SearchAddressScreen}
        options={{
          headerShown: false,
          ...TransitionPresets.SlideFromRightIOS,
        }}
      />
      <RegisterFormStack.Screen
        name={MENU_WEB_VIEW_SCREEN}
        component={WebView}
        options={{
          headerShown: false,
          ...TransitionPresets.SlideFromRightIOS,
        }}
      />
    </RegisterFormStack.Navigator>
  );
};
