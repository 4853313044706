import { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useAppImagePicker} from "@components/AppImagePicker/AppImagePicker.hook";
import {getCreateTicketFormCreator} from "@redux/form/selectors";
import {mappingStep} from "@utils/transform/form";
import _ from "lodash";
import {FORM} from "@constant/model/RegisterForm";
import {FileTransform} from "@utils/transform/file";
// import {getSettings} from "@utils";
import {ImageTransform} from "@utils/transform/file/image";

export const useSend = props => {
  const getCreateTicketFormData = useSelector(getCreateTicketFormCreator)?.data;
  const settings = null;

  // let item = null;
  // let s3config = null
  // let uploadFileName = ''
  // let folderUpload = ''
  // if(!_.isEmpty(getCreateTicketFormData)){
  //   item = getCreateTicketFormData[0].attributes?.find(x => x.type === FORM.FORM_TYPE.camera)
  //   const uploadPath = FileTransform.fixUploadPath(item?.uploadPath)
  //   folderUpload=!_.isEmpty(uploadPath) ? uploadPath : folderUpload
  //   uploadFileName = FileTransform.fixUploadName(item?.uploadFileName, {...props})
  //   s3config = item?.s3config
  // }
  //
  // s3config = s3config ?? settings?.upload?.s3config
  //
  // console.log('useSend>>>',item, settings?.upload, folderUpload, s3config, uploadFileName)
  const rs = ImageTransform.getImageInfo(getCreateTicketFormData, settings, props)
  return {
    ...rs
  }
};
