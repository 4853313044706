import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppKAView, AppSafeAreaView, AppText } from '@components';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font } from '@theme/size';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { useTopBundle } from './TopBundle.hook';
import { TopBundleStyle } from './TopBundle.style';

export const TopBundleView = props => {
  const { t } = useTranslation();
  const { data } = useTopBundle(props);
  return (
    <View
      areaStyle={[globalStyle.flex1, globalStyle.alignCenter, globalStyle.justifyCenter, { margin: theme.spacing.sm }]}>
      <LinearGradient style={TopBundleStyle.topView} colors={['#FF000E', '#870007']}>
        <View style={[globalStyle.directionColumm]}>
          <View style={[globalStyle.directionRow, globalStyle.alignCenter, { marginBottom: theme.spacing.sm }]}>
            <AppText style={[globalStyle.flex1, TopBundleStyle.textNormal]}>{t('BUNDLE_ID')}</AppText>
            <AppText style={[TopBundleStyle.textTitle]}>{data?.id}</AppText>
          </View>
          <View style={[globalStyle.directionRow, { marginBottom: theme.spacing.sm }]}>
            <AppText style={[globalStyle.flex1, TopBundleStyle.textNormal]}>{t('NUMBER_OF_TEST_LETTERS')}</AppText>
            <AppText style={[TopBundleStyle.textNormal]}>{data?.total}</AppText>
          </View>
          <View style={[globalStyle.directionRow]}>
            <AppText style={[globalStyle.flex1, TopBundleStyle.textNormal]}>{t('TARGET_POST_DATE')}</AppText>
            <AppText style={[TopBundleStyle.textNormal]}>{data?.date}</AppText>
          </View>
        </View>
      </LinearGradient>
    </View>
  );
};

TopBundleView.propTypes = {
  data: PropTypes.object,
};

TopBundleView.defaultProps = {
  data: {}
};
