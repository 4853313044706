import { createAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  userValidation: null,
  user: null,
  forgotForm: null,
  isRegisterCompleted: false,
};

export const getActiveUserRequest = createAction('updateUser/getActiveUserRequest', (payload, onSuccess, onFailed) => {
  console.log('prepareAction>>>', payload, onSuccess);
  return {
    payload: payload,
    meta: onSuccess,
    error: onFailed,
  };
});

export const getLogoutRequest = createAction('getLogout/getLogoutRequest', (payload, onSuccess, onFailed) => {
  console.log('prepareAction>>>', payload, onSuccess);
  return {
    payload: payload,
    meta: onSuccess,
    error: onFailed,
  };
});

export const loginRequest = createAction('login/loginRequest', (payload, onSuccess, onFailed) => {
  console.log('prepareAction>>>', payload, onSuccess);
  return {
    payload: payload,
    meta: onSuccess,
    error: onFailed,
  };
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    getUserValidationSuccess(state, action) {
      state.userValidation = action.payload;
    },
    getUserValidationRequest(state, action) {
      console.log('call getUserValidation saga');
      state.isRegisterCompleted = false;
    },
    getUserValidationFailed(state, action) {
      console.log('call getUserValidation saga');
    },
    resetUserValidation(state, action) {
      console.log('call resetUserValidation reducers');
      state.userValidation = null;
      state.isRegisterCompleted = true;
    },
    getActiveUserSuccess(state, action) {
      console.log({ state, action });
      state.activeUser = action.payload;
    },
    // getActiveUserRequest() {
    //   console.log('call getActiveUser saga');
    // },
    getActiveUserFailed(state, action) {
      console.log('call getActiveUserFailed saga');
    },

    getLoginSuccess(state, action) {
      console.log({ state, action });
      state.user = action.payload;
    },
    getLoginRequest() {
      console.log('call getLoginRequest saga');
    },
    getLoginFailed(state, action) {
      console.log('call getLoginFailed saga');
    },

    getForgotFormSuccess(state, action) {
      state.forgotForm = action.payload;
    },
    getForgotFormRequest() {
      console.log('call getForgotFormRequest saga');
    },
    getForgotFormFailed(state, action) {
      console.log('call getLoginFailed saga');
    },

    getLogoutSuccess(state, action) {
      console.log({ state, action });
      if (state.user?.rememberMe) {
        state.user = {
          email: state.user.email,
        };
      } else {
        state.user = null;
      }
    },
    // getLogoutRequest() {
    //   console.log('call getLogoutRequest saga');
    // },
    getLogoutFailed(state, action) {
      console.log('call getLogoutFailed saga');
      state.user = null;
    },
    getResetUser(state, _) {
      state.user = null;
    },

    // loginRequest(state, action) {
    //   state.loading = true;
    //   // @ts-ignore
    //   // state.payload = null;
    //   state.error = null;
    // },
    loginSuccess(state, action) {
      state.loading = false;
      // state.payload = action.payload;
      state.user = action.payload;
      state.error = null;
    },
    loginFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(loginRequest, (state, action) => {
      // action is inferred correctly here if using TS
      state.loading = true;
      //   // @ts-ignore
      state.user = null;
      state.error = null;
    });
  },
});

export const {
  getLoginSuccess,
  getLoginRequest,
  getLoginFailed,
  getLogoutSuccess,
  // getLogoutRequest,
  getLogoutFailed,
  getUserValidationSuccess,
  getUserValidationRequest,
  resetUserValidation,
  getActiveUserSuccess,
  getActiveUserFailed,
  getUserValidationFailed,
  getForgotFormSuccess,
  getForgotFormRequest,
  getForgotFormFailed,
  getRememberMe,
  getResetUser,
  loginSuccess,
  loginFailed,
} = authSlice.actions;

export default authSlice.reducer;
