import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppEmptyView, AppKAView, AppSafeAreaView, AppText} from '@components';
import GenericHeader from '@components/GenericHeader';
import { goBack } from '@routes/navigationUtils';
import { AppLoadingMore } from '@screens/main/SearchPostcode';
import { useListTicket } from '@screens/main/ticket/ListTicket/ListTicket.hook';
import { ListTicketStyle } from '@screens/main/ticket/ListTicket/ListTicket.style';
import { TicketFilterView } from '@screens/main/ticket/ListTicket/components/TicketFilter.view';
import { TicketItemStyles } from '@screens/main/ticket/ListTicket/components/TicketItem.style';
import { StatusView, TicketItemView } from '@screens/main/ticket/ListTicket/components/TicketItem.view';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font } from '@theme/size';
import { DATE_FORMAT, formatDateV1 } from '@utils/common/MomentUtils';
import { getColor } from '@utils/transform/ticket';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Text, FlatList, TouchableOpacity } from 'react-native';
import { useHistoryPoints } from './HistoryPoints.hook';
import { HistoryPointsStyle } from './HistoryPoints.style';
import _ from 'lodash'
export const HistoryItemView = props => {
  const { t } = useTranslation();
  const data = props.data;

  // console.log('TicketItemView>>>>', props)

  return (
    <View>
      <View style={[HistoryPointsStyle.viewLine]} />
      <TouchableOpacity
        style={[
          // styles.fill,
          globalStyle.directionColumm,
          HistoryPointsStyle.container,
          // { marginBottom: props.isLast ? Dimens.Margin.Normal : 0 },
        ]}
        onPress={() => {
          props.onPress(data);
        }}>
        <View style={[globalStyle.directionRow, globalStyle.center]}>
          <AppText style={[TicketItemStyles.textTitle, globalStyle.flex1, { marginRight: 16 }]}>
            {`${data?.comment}`}
            {/*<View style={[TicketItemStyles.viewLine]} />*/}
            {/*<AppText style={[TicketItemStyles.textSecondTitle]}>{`  ${data?.title}`}</AppText>*/}
          </AppText>
          <View style={[HistoryPointsStyle.point]}>
            <AppText style={[HistoryPointsStyle.textPoint]}>
              {/*{getTextPriority(priority)}*/}
              {data?.points} {t('POINTS')}
            </AppText>
          </View>
        </View>

        <AppText style={[TicketItemStyles.textNormal]}>{formatDateV1(data?.createdAt, DATE_FORMAT.UIDate)}</AppText>
        {/*<View style={[globalStyle.flex1, globalStyle.directionRow, globalStyle.center]}>*/}
        {/*  <StatusView status={data?.resolution ?? data?.process} colors={props.colors} dictStatus={props.dictStatus} />*/}

        {/*  <AppText style={[globalStyle.flex1]} />*/}
        {/*</View>*/}
      </TouchableOpacity>
    </View>
  );
};

export const HistoryPointsScreen = props => {
  const { t } = useTranslation();
  const {
    data,
    allData,
    onRefresh,
    isRefreshing,
    loadingMore,
    loading,
    onLoadMore,
    onItemPress,
    getListStatusData,
    // modalizeRef,
    isShowModal,
    // onItemStatusPress,
    // onFilterPress,
    // listStatus,
    bonusInfo,
  } = useHistoryPoints(props);
  const renderFooterLoading = () => {
    return <AppLoadingMore loadingMore={loadingMore} />;
  };

  const renderEmpty = () => {
    return !loading ? <AppEmptyView emptyText={props.emptyText} /> : <></>;
  };
  const renderItem = ({ item, index }) => {
    return (
      <HistoryItemView
        data={item}
        isLast={index === allData.length - 1}
        isSelected={false}
        colors={getListStatusData?.colors}
        dictStatus={getListStatusData?.value}
        onPress={() => onItemPress(item)}
      />
    );
  };
  return (
    <View style={[globalStyle.directionColumm, globalStyle.flex1, { backgroundColor: palette.WHITE }]}>
      <View style={[{ marginHorizontal: theme.spacing.md, marginTop: theme.spacing.md }]}>
        <View style={[globalStyle.directionRow, { alignItems: 'baseline' }]}>
          <AppText style={[HistoryPointsStyle.textHeader]}>{_.isNil(bonusInfo?.total) ? "N/A": bonusInfo?.total}</AppText>
          <AppText style={[]}> {t('POINTS')}</AppText>
        </View>
        <AppText style={[HistoryPointsStyle.textDescription]}>{bonusInfo?.note}</AppText>
      </View>
      <FlatList
        style={[ListTicketStyle.listView]}
        contentContainerStyle={_.isEmpty(allData) ? { flexGrow: 1 } : {}}
        data={allData}
        renderItem={renderItem}
        onRefresh={() => onRefresh()}
        refreshing={isRefreshing}
        ListEmptyComponent={renderEmpty}
        onEndReached={!loading ? onLoadMore : null}
        onEndReachedThreshold={0.5}
        keyExtractor={(item, index) => index}
        ListFooterComponent={renderFooterLoading}
      />
    </View>
  );
};
