import { Constant } from '@constant';
import { getBonusPointListService } from '@services/user';
// @ts-ignore
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { getBonusPointListFailed, getBonusPointListSuccess } from './getBonusPointListSlice';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* getBonusPointListSaga({ payload, meta, error }): Generator<
  | CallEffect
  | PutEffect<{
      payload: any;
      type: string;
    }>,
  void
> {
  try {
    const res = yield call(getBonusPointListService, payload);
    console.log('getBonusPointListSaga>>>>', res);
    if (res?.status) {
      yield put(getBonusPointListSuccess(res));
      if (meta) {
        yield call(meta, res);
      }
    } else {
      // @ts-ignore
      yield put(getBonusPointListFailed(res));
      if (error) {
        yield call(error, res);
      }
    }
  } catch (err) {
    // @ts-ignore
    yield put(getBonusPointListFailed(err));
    if (error) {
      yield call(error, err);
    }
  }
}
