import { CrossIcon } from '@assets';
import { AppKAView, AppSafeAreaView, AppWebView } from '@components';
import { goBack } from '@routes/navigationUtils';
import React from 'react';
import { StatusBar, TouchableOpacity, View } from 'react-native';
import { useLogic } from './WebViewFullScreen.hook';
import { styles } from './WebViewFullScreen.style';

const WebViewFullScreen = () => {
  const { uri } = useLogic();

  return (
    <>
      <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
      <AppSafeAreaView areaStyle={styles.areaStyle}>
        <AppKAView>
          <View style={styles.container}>
            <View style={{ width: '100%', minHeight: 60, paddingVertical: 10 }}>
              <TouchableOpacity onPress={goBack} style={styles.closeBtn}>
                <CrossIcon />
              </TouchableOpacity>
            </View>
            <AppWebView uri={uri} />
          </View>
        </AppKAView>
      </AppSafeAreaView>
    </>
  );
};

export { WebViewFullScreen };
