import env from '@env';
import { FileTransform } from '@utils/transform/file';
import { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getCreateTicketFormCreator} from "@redux/form/selectors";
// import {getSettings} from "@utils";
import {ImageTransform} from "@utils/transform/file/image";

export const useComment = props => {
  const dispatch = useDispatch();
  // const attachments = [
  //   {
  //     link: '63647a1a2b6443d69a4c42e50a01031a?path=ticket/',
  //     name: 'UK flag',
  //   },
  //   {
  //     link: '63647a1a2b6443d69a4c42e50a01031a?path=ticket/',
  //     name: 'UK flag',
  //   },
  //   {
  //     link: '63647a1a2b6443d69a4c42e50a01031a?path=ticket/',
  //     name: 'UK flag',
  //   },
  // ];

  const attachments = props?.data?.attachments ?? [];
  console.log('useComment>>>', props, attachments);
  const getCreateTicketFormData = useSelector(getCreateTicketFormCreator)?.data;
  const settings = null;
  const rs = ImageTransform.getImageInfo(getCreateTicketFormData, settings, props)


  useEffect(() => {}, [dispatch]);
  return { attachments, ...rs };
};
