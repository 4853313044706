import { Config } from '@constant/config';
import env from '@env';
import api from '@services/api-client';
import { stringify } from 'qs';
import { URL } from 'react-native-url-polyfill';
import withQuery from 'with-query';
import {FileTransform} from "@utils/transform/file";

export const get_languagesService = async payload => {
  console.log('get_languagesService>>>', payload, new URL(env.API_URL));

  // let url = `/fdb-api/app-lang/spectos-mailagent`;
  // if (env.ENV === 'prepro') {
  //   url = `/fdb-api/app-lang/spectos-mailagent`;
  // }
  let url = FileTransform.getFDBUrl('/app-lang/spectos-mailagent');
  const response = await api.get(url, {
    baseURL: new URL(env.API_URL).origin,
    // headers: {
    //   'spectos-apikey': Config.Api.spectosLangKey,
    // },
  });
  console.log('get_languagesService response>>>', response);

  return response?.data;
};
