export const data = {
    hasRegister: true,
    "colors": {
        TORQUISE: '#1abc9c',
        EMERALD: '#2ecc71',
        GREEN_SEA: '#16a085',
        NEPHRITIS: '#27ae60',
        SUN_FLOWER: '#f1c40f',
        ORANGE: '#f39c12',
        CARROT: '#e67e22',
        PUMPKIN: '#d35400',
        PETER_RIVER: '#3498db',
        BELIZE_HOLE: '#2980b9',
        ALIZARIN: '#e74c3c',
        POMEGRANATE: '#c0392b',
        AMETHYST: '#9b59b6',
        WISTERIA: '#8e44ad',
        WET_ASPHALT: '#34495e',
        MIDNIGHT_BLUE: '#2c3e50',
        ASBESTOS: '#7f8c8d',
        CONCRETE: '#95a5a6',
        SILVER: '#bdc3c7',
        CLOUDS: '#ecf0f1',
        WHITE: '#ffffff',
        TRANSPARENT: '#00000000',
        GREY_SHADOW_7: 'rgba(216,216,216,0.7)',
        MAIN_BLACK: '#333333',

        LOADING_COLOR: '#999999',

        ROYAL_MAIL_RED: '#da202a',
        //GreyPalette
        BACKGROUND_GREY: '#EDEDED',
        RECESS_GREY: '#c1c6c8',
        STRONG_GREY: '#53535a',
        DARK_GREY: '#404044',
        BLACK: '#2a2a2d',

        //Mixed Palette
        POSITIVE_BLUE: '#0892cb',
        GO_GREEN: '#62a531',
        DEEP_GREEN: '#088578',
        DYNAMIC_YELLOW: '#fdda24',
        CLEAR_BLUE: '#aadaea',

        GREEN_ACTIVE: '#8BB73B',

        TAB_SELECTED: "#404044",
        TAB_UNSELECTED: "#898989",
        TAB_FAQ_UNSELECTED: "#DA202A",

        REPORT_BANNER_START: "#FF000E",
        REPORT_BANNER_END: "#870007",
        MAP_TAB_ACTIVE_START: "#9b2d32",
        MAP_TAB_ACTIVE_END: "#df010e",
        CHILD_TAB_ACTIVE_START: "#E1000E",
        CHILD_TAB_ACTIVE_END: "#870007CC",

        CHILD_TAB_TEXT_SELECTED: '#ffffff',
        CHILD_TAB_TEXT_UNSELECTED: '#2a2a2d',

        BUTTON_BACKGROUND: "#da202a",
        BUTTON_TEXT: "#F5F5F5",

        DASHBOARD_TEXT: "#ffffff",
        DASHBOARD_BONUS_BANNER: "#CE232C",
        DASHBOARD_IMAGE: "#ffffff",
        "DASHBOARD_MAP": "#ffffff",
        "ERROR_TEXT": "#da202a"
    },
    "spacing": {
        "sm": 8,
        "ms": 10,
        "md": 16,
        "lg": 24
    },
    "icons": {
        "MailIcon": {},
        "EyeActiveIcon": {},
        "MarkerDashboardIcon": {},
        "PadLockIcon": {},
        "EyeInactiveIcon": {},
        "NumPadIcon": {},
        "QRIcon": {},
        "FrameIcon": {},
        "RadioIcon": {},
        "ActiveRadioIcon": {},
        "CheckIcon": {},
        "ActiveCheckIcon": {},
        "ChevronDownIcon": {},
        "CalendarIcon": {},
        "ClockIcon": {},
        "TickIcon": {},
        "CrossIcon": {},
        "MarkerQRIcon2": {},
        "ChevronPickerIcon": {},
        "UserIcon": {},
        "MarkerQRIcon": {},
        "LogoIcon": {},
        "MessageCheckIcon": {},
        "ThankYouIcon": {},
        "WarningIcon": {},
        "QRIcon2": {},
        "HomeIcon": {},
        "TaskIcon": {},
        "FAQIcon": {},
        "MenuIcon": {},
        "BackIcon": {},
        "NoAvatarIcon": {},
        "ChevronRightIcon": {},
        "PencilIcon": {},
        "PassedIcon": {},
        "FailedIcon": {},
        "ArrowRightIcon": {},
        "ArrowLeftIcon": {},
        "ArrowBorderIcon": {},
        "ClearIcon": {},
        "FilterIcon": {},
        "ImageIcon": {},
        "CirclePlusIcon": {},
        "SentIcon": {},
        "LogoBigIcon": {},
        "OverdueIcon": {},
        "AnswerIcon": {},
        "AwaitingIcon": {}
    },
    "images": {
        "RoyalMail": "https://spectos-royalmail-prod.s3.eu-central-1.amazonaws.com/app/icons/inapp/royalMail.png",
        "Splash": "https://spectos-royalmail-prod.s3.eu-central-1.amazonaws.com/app/icons/inapp/splashscreen.png",
        "Branding": "https://spectos-royalmail-prod.s3.eu-central-1.amazonaws.com/app/icons/inapp/branding.png",
        "SplashLayer": "https://spectos-royalmail-prod.s3.eu-central-1.amazonaws.com/app/icons/inapp/splash_layer.png",
        "FallbackImage": "https://spectos-royalmail-prod.s3.eu-central-1.amazonaws.com/app/icons/inapp/fallbackImage.png",
        "ImagePicker": "https://spectos-royalmail-prod.s3.eu-central-1.amazonaws.com/app/icons/inapp/imagePicker.png",
        "ImagePlaceholder": "https://spectos-royalmail-prod.s3.eu-central-1.amazonaws.com/app/icons/inapp/image_placeholder.png",
        "ImageDefault": "https://spectos-royalmail-prod.s3.eu-central-1.amazonaws.com/app/icons/inapp/img_default.png",
        "Marker": "https://spectos-royalmail-prod.s3.eu-central-1.amazonaws.com/app/icons/inapp/marker.png",
        "Marker2": "https://spectos-royalmail-prod.s3.eu-central-1.amazonaws.com/app/icons/inapp/marker2.png"
    }
}