import { useEffect, useState, useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export const useReportTab = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { state, descriptors, navigation } = props;

  const [selectedIndex, setSelectedIndex] = useState(state.index);
  const routes = state.routes;
  console.log('useReportTab>>>', routes);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const listName = routes?.map(route => t(`${route.name}_TITLE`));

  const onItemPress = index => {
    const route = routes[index];
    setSelectedIndex(index);
    const event = navigation.emit({
      type: 'tabPress',
      target: route.key,
      canPreventDefault: true,
    });
    // const isFocused = state.index === index;
    // if (!isFocused && !event.defaultPrevented) {
    navigation.navigate({ name: route.name, merge: true });
    // }
  };
  // useEffect(() => {}, [dispatch]);
  return { selectedIndex, setSelectedIndex, onItemPress, routes, listName };
};
