import { createSlice } from '@reduxjs/toolkit';

export interface IgetMenuformState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IgetMenuformState = {
  loading: false,
  payload: null,
  error: null,
};

const getMenuformSlice = createSlice({
  name: 'getMenuform',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getMenuformRequest(state, action) {
      state.loading = true;
      // @ts-ignore
      state.payload = null;
      state.error = null;
    },
    getMenuformSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    getMenuformFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getMenuformRequest, getMenuformSuccess, getMenuformFailed } = getMenuformSlice.actions;

export default getMenuformSlice.reducer;
