import { hideNewsModal, hideNoInternetModal } from '@redux/common/commonSlice';
import { newsModalSelector, newsModalVisible, noInternetModalVisible } from '@redux/common/selectors';
import { navigate } from '@routes/navigationUtils';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

export const useNewsModal = props => {
  const dispatch = useDispatch();
  const modalVisible = useSelector(newsModalVisible);
  const modalData = useSelector(newsModalSelector);
  console.log('showNewsModal>>>modalVisible>>>', modalVisible);
  console.log('showNewsModal>>>modalData>>>', modalData);

  // const data = useSelector()
  const handlePressButton = () => {
    modalVisible && dispatch(hideNewsModal());
    //TODO: open setting
  };
  const handleHideModal = () => {
    modalVisible && dispatch(hideNewsModal());
  };
  return { handlePressButton, handleHideModal, modalData };
};
