import { CommonActions, useNavigation } from '@react-navigation/native';
import {getLogoutSuccess, resetUserValidation} from '@redux/auth/authSlice';
import { authCreator } from '@redux/auth/selectors';
import { navigatePop, popToTop } from '@routes/navigationUtils';
import { LOGIN_SCREEN, Screens } from '@routes/route';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useUnsubscribeSuccessful = props => {
  const dispatch = useDispatch();
  const userValidation = useSelector(authCreator);
  const navigation = useNavigation();

  // console.log('useRegisterCompleted>>>>');
  const waitingForLogout = () => {
    // popToTop()
    console.log('waitingForSendMail>>>>');
    setTimeout(() => {
      // dispatch(resetUserValidation({}));
      // navigation.dispatch(
      //   CommonActions.reset({
      //     index: 0,
      //     routes: [
      //       {
      //         name: Screens.LOGIN_SCREEN_VERTICAL,
      //       },
      //     ],
      //   }),
      // );
      dispatch(getLogoutSuccess({}))
    }, 6000);
  };
  useEffect(() => {
    waitingForLogout();
  }, [userValidation]);
  return { waitingForLogout };
};
