import messaging from '@react-native-firebase/messaging';
import { getLogoutRequest, getResetUser } from '@redux/auth/authSlice';
import { authCreator } from '@redux/auth/selectors';
import { authUser } from '@redux/auth/selectors';
// @ts-ignore
import {
  modalDeeplinkVisible,
  cameraPermissionModalVisible as cameraPermissionModalVisibleSelector,
  locationPermissionModalVisible as locationPermissionModalVisibleSelector,
  notificationPermissionModalVisible as notificationPermissionModalVisibleSelector,
  notificationImportantModalVisible as notificationImportantModalVisibleSelector,
  noInternetModalVisible,
  newsModalVisible,
  listNotificationModalVisible

} from '@redux/common/selectors';
import { getFormLoading } from '@redux/form/selectors';
import { getLoading, globalErrorMsg, globalLoading } from '@redux/global/selectors';
import { RootState } from '@redux/reducers';
import { updateUserLoading } from '@redux/user/selectors';
import { forceUpdateRequest } from '@redux/version/forceUpdateSlice';
import { forceUpdateCreator, forceUpdateError, forceUpdateSelector } from '@redux/version/selectors';
// import { registerCompletedSelector } from '@redux/user/selectors';
import { isFirstTimeApp } from '@routes/navigationUtils';
import { showErrorMsg } from '@utils/api';
import { isShowForceVersion } from '@utils/common/deviceUtil';
import { useLanguage } from '@utils/hooks/useLanguage';
import { compare } from 'compare-versions';
import { useEffect } from 'react';
import {Alert, AppState, Clipboard, ClipboardStatic} from 'react-native';
import DeviceInfo from 'react-native-device-info';
import SplashScreen from 'react-native-splash-screen';
import { useDispatch, useSelector } from 'react-redux';
import { Platform } from 'react-native';
import {LinkingHelper} from "@utils/common/linking";
import * as NetInfo from "@react-native-community/netinfo";
import {updateNetInfo} from "@redux/app/appSlice";
import {showNoInternetModal} from "@redux/common/commonSlice";
import {useTranslation} from "react-i18next";

export const useRoot = props => {
  const dispatch = useDispatch();
  const forceUpdateData = useSelector(forceUpdateCreator);
  const forceUpdateWithError = useSelector(forceUpdateError);

  // const userValidateData = useSelector(authCreator);
  const build_version = useSelector(state => state?.forceUpdate?.payload?.build_version);
  const isShowVersion = isShowForceVersion(build_version);
  // const isShowVersion = true;

  const isLoading = useSelector(globalLoading);
  const globalWithErrorMsg = useSelector(globalErrorMsg);
  const token = useSelector(authUser)?.accessToken;

  const rememberMe = useSelector(authUser)?.rememberMe;
  const cameraPermissionModalVisible = useSelector(cameraPermissionModalVisibleSelector);
  const locationPermissionModalVisible = useSelector(locationPermissionModalVisibleSelector);
  const notificationPermissionModalVisible = useSelector(notificationPermissionModalVisibleSelector)?.visible;
  const notificationImportantModalVisible = useSelector(notificationImportantModalVisibleSelector);
  const showModalDeeplinkVisible = useSelector(modalDeeplinkVisible);
  const showNoInternetConnection = useSelector(noInternetModalVisible);
  const showNewsModal = useSelector(newsModalVisible);
  const showListNotificationModal = useSelector(listNotificationModalVisible);

  console.log('useRoot>>>modalVisible>>>', showNewsModal);

  const { t } = useTranslation();

  // const isLoading = false
  // const isRegisterForm = !!userValidateData;
  const isRegisterForm = false;

  console.log('forceUpdateData>>>', forceUpdateData, build_version, isShowVersion, isLoading);

  useEffect(() => {
    if (build_version || forceUpdateWithError) {
      if (Platform.OS !== 'web') {
        SplashScreen.hide();
      }
    }
  }, [build_version, forceUpdateWithError]);

  useEffect(() => {
    dispatch(forceUpdateRequest());
  }, [dispatch]);

  useEffect(() => {
    if (globalWithErrorMsg) {
      showErrorMsg(globalWithErrorMsg);
    }
  }, [globalWithErrorMsg]);

  useEffect(() => {
    if (token && !rememberMe) {
      if(LinkingHelper.isForceOpen()){
        return
      }
      dispatch(getLogoutRequest());
      setTimeout(() => {
        dispatch(getResetUser());
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const testDeviceToken = async () => {
    const newFcmToken = await messaging().getToken();
    // alert(newFcmToken, )
    Alert.alert('', newFcmToken, [
      {
        text: 'Copy',
        onPress: () => {
          Clipboard.setString(newFcmToken);
        },
      },
    ]);
  };

  useEffect(() => {
    console.log('useRoot>>>', isFirstTimeApp.current);
    if (isFirstTimeApp.current === null) {
      isFirstTimeApp.current = true;
    }

    if (isFirstTimeApp.current) {
      setTimeout(() => {
        isFirstTimeApp.current = false;
        console.log('isFirstTimeApp>>>', isFirstTimeApp.current);
      }, 4000);
    }

    // testDeviceToken();
  }, []);

  useEffect(() => {
    console.log('useRoot>>>useEffect>>>')
    const subAppState = AppState.addEventListener('change', async nextAppState => {
      // if (Platform.OS === 'ios' && nextAppState === 'active') {
      // eslint-disable-next-line no-undef
      // let newNetInfo = await NativeModules.RNCNetInfo.getCurrentState('wifi');
      if (nextAppState === 'active') {
        const newNetInfo = await NetInfo.fetch();
        console.log('newNetInfo>>>>', newNetInfo);
        dispatch(updateNetInfo(newNetInfo));
        if (!newNetInfo.isConnected) {
          // dispatch(showNoInternetModal())
          showErrorMsg(t('NO_INTERNET_TITLE'));
        }
      }
      // }
    });
    const unsubNetState = NetInfo.addEventListener(state => {
      //your code here
      console.log('NetInfo.addEventListener>>>', state);
      dispatch(updateNetInfo(state));
      if (!state.isConnected) {
        // dispatch(showNoInternetModal());
        showErrorMsg(t('NO_INTERNET_TITLE'));
      }
    });
    return () => {
      if (subAppState) {
        subAppState.remove();
      }
      unsubNetState();
      console.log('useRoot>>>useEffect>>>unsub')
    };
  },[]);

  return {
    forceUpdateData,
    isShowVersion,
    isLoading,
    isRegisterForm,
    cameraPermissionModalVisible,
    locationPermissionModalVisible,
    notificationPermissionModalVisible,
    showModalDeeplinkVisible,
    notificationImportantModalVisible,
    showNoInternetConnection,
    showNewsModal,
    showListNotificationModal,
  };
};
