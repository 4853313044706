import { palette } from '@theme/colors';
import { Font, FontSize, Spacing } from '@theme/size';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    // marginLeft: -Spacing.width9,
  },
  nameWrap: {
    paddingLeft: Spacing.width10,
    flex:1
  },
  name: {
    ...Font.Bold_700,
    fontSize: FontSize.Font16,
    color: '#333333',

    marginBottom: Spacing.height4,
  },
  navigateText: {
    ...Font.Bold_400,
    fontSize: FontSize.Font13,
    color: '#888888',
  },
});
