import { NOTIFICATION } from '@constant';
import { notificationUrlRequest } from '@redux/notification/notificationUrlSlice';
import { store } from '@redux/store';
import { updateProfileRequest } from '@redux/user/updateProfileSlice';
import { palette } from '@theme/colors';
import { getSettingNotification } from '@utils';
import { DATE_FORMAT, getCurentDateTimeText } from '@utils/common/MomentUtils';
import { NotificationHelper } from '@utils/common/notification';
import { handleShowError } from '@utils/transform/form';
import _ from 'lodash';
import { platform } from 'os';
import { Platform } from 'react-native';
import replaceAll from 'string.prototype.replaceall';
import { date } from 'yup';

const bundleActionNotification = [
  {
    id: 'fill_report',
    title: 'Fill report',
    destructive: true,
    foreground: true,
  },
  {
    id: 'not_send_today',
    title: 'I didn’t send today',
    destructive: true,
    foreground: true,
  },
];
export const NotificationActionHelper = {
  getAndroidActions: actions => {
    return actions.map(x => {
      return {
        title: x.title,
        pressAction: {
          id: x.id,
          launchActivity: 'default',
          // launchActivity: 'com.spectos.mailagentnew.MainActivity',
        },
      };
    });
  },
  setCategories: async () => {
    console.log('addAction>>>begin>>');
    //TODO: should get from api to get all category.
    // if (Platform.OS === 'ios') {
    //   const rs = await notifee.setNotificationCategories([
    //     {
    //       id: NOTIFICATION.category.report_action,
    //       actions: [...bundleActionNotification],
    //     },
    //   ]);
    // } else if (Platform.OS === 'android') {
    //   const rs = await notifee.createChannel({
    //     id: NOTIFICATION.category.report_action,
    //     name: NOTIFICATION.category.report_action,
    //     importance: AndroidImportance.HIGH,
    //     asForegroundService: true,
    //   });
    // }
    const actionNotification = getSettingNotification()?.actionNotification;
    await NotificationActionHelper.setCategoriesWithData(actionNotification);

    console.log('addAction>>>end>>');
  },
  setCategoriesWithData: async notificationActions => {
    console.log('setCategoriesWithData>>>', notificationActions);
    if (_.isEmpty(notificationActions)) {
      return;
    }
    // if (Platform.OS === 'ios') {
    //   const rs = await notifee.setNotificationCategories(notificationActions);
    // } else if (Platform.OS === 'android') {
    //   const channels = notificationActions.map(x => {
    //     return {
    //       id: x.id,
    //       name: x.id,
    //       importance: AndroidImportance.HIGH,
    //       // asForegroundService: true,
    //     };
    //   });
    //   const rs = await notifee.createChannels(channels);
    // }
  },
  showNoti: async () => {
    const data = {
      title: 'New post from John',
      body: 'Hey everyone! Check out my new blog post on my website.',
      sound: 'default',
      ios: {
        categoryId: NOTIFICATION.category.report_action,
      },
      android: {
        smallIcon: 'ic_launcher',
        // largeIcon: 'ic_launcher',
        color: palette.ROYAL_MAIL_RED,
        channelId: NOTIFICATION.category.report_action,
        actions: [...NotificationActionHelper.getAndroidActions(bundleActionNotification)],
      },
      data: {
        root: {
          actions: [
            {
              id: 'fill_report',
              title: 'Fill report',
              data: {},
            },
            {
              id: 'not_send_today',
              title: 'I didn’t send today',
              data: {
                message:
                  'You need to send your bundle [bundle id] on [new senddatetarget]", senddate_target should be rescheduled to the next week the same day.',
              },
            },
          ],
        },
      },
    };
    console.log('showNoti>>>', JSON.stringify(data));
    // await notifee.displayNotification(data);
  },
  showNoti2: async () => {
    // await NotificationActionHelper.addAction()
    NotificationActionHelper.showNoti();
  },

  setupOnEvent: () => {
    // notifee.onForegroundEvent(async ({ type, detail }) => {
    //   const remote = detail?.notification?.remote;
    //   // alert(`fg: tp:${type}...rm:${remote} ...action:${detail?.pressAction?.id}..data:${JSON.stringify(detail)}`);
    //   if ([EventType.ACTION_PRESS, EventType.PRESS].includes(type) && detail?.pressAction?.id) {
    //     console.log('onForegroundEvent>>>id: ', detail.pressAction.id, detail);
    //     // NotificationHelper.interactionNotificationWithAction()
    //     NotificationHelper.openNotificationWithAction(detail.pressAction.id, detail?.notification);
    //     await notifee.cancelNotification(detail?.notification?.id);
    //   }
    // });
    //
    // notifee.onBackgroundEvent(async ({ type, detail }) => {
    //   if (type === EventType.ACTION_PRESS && detail.pressAction.id) {
    //     // await updateChat(detail.notification.data.chatId, detail.input);
    //     // await notifee.cancelNotification(detail.notification.id);
    //     console.log('onBackgroundEvent>>>id: ', detail.pressAction.id, detail);
    //     NotificationHelper.openNotificationWithAction(detail.pressAction.id, detail?.notification);
    //     await notifee.cancelNotification(detail?.notification?.id);
    //   }
    // });
  },
  setupInitialNotification: async () => {
    // const initialNotification = await notifee.getInitialNotification();
    //
    // if (initialNotification) {
    //   // console.log('Notification caused application to open', initialNotification.notification);
    //   console.log('Press action used to open the app', initialNotification.pressAction);
    //   const pressAction = initialNotification?.pressAction;
    //   if (pressAction) {
    //     const notification = initialNotification.notification;
    //     NotificationHelper.openNotificationWithAction(pressAction.id, notification);
    //     await notifee.cancelNotification(notification?.id);
    //   }
    // }
  },
  setup: async () => {
    await NotificationActionHelper.setupInitialNotification();
    //TODO: should setupCategories after call getSetting api.
    await NotificationActionHelper.setCategories();
    NotificationActionHelper.setupOnEvent();
  },
  setupOnMessageReceived: message => {
    // alert('so___' + message?.data?.notifee);
    // if (_.isEmpty(message?.data?.notifee)) {
    //   return;
    // }
    //
    // notifee.displayNotification(JSON.parse(message.data.notifee));
  },
  setupOnMessageReceivedV2: message => {
    // alert('so___' + JSON.stringify(message));
    if (_.isEmpty(message?.data?.root)) {
      return;
    }
    const notification = {
      ...NotificationActionHelper.convertToNotifee(message),
    }

    console.log('setupOnMessageReceivedV2>>>', message, notification)
    // notifee.displayNotification(notification);
  },
  convertToNotifee: message => {
    // const actions = message?.data?.root?.actions;
    const root = message?.data?.root;
    let actions = [];
    //NOTE: data.root is json string -> should parse to object
    if (typeof root === 'string') {
      actions = JSON.parse(root)?.actions;
    } else {
      actions = root.actions ?? [];
    }
    let data = {
      ...message.data,
      root: {
        actions: actions,
      },
    };
    // const actions = data?.root?.actions;
    const android = {
      smallIcon: 'ic_launcher',
      // largeIcon: 'ic_launcher',
      color: palette.ROYAL_MAIL_RED,
      channelId: message?.notification?.android?.clickAction,
      actions: NotificationActionHelper.getAndroidActions(actions),
    };
    return {
      // ...message,
      title: message?.notification?.title,
      body: message?.notification?.body,
      data: {
        ...data,
      },
      ios: {
        categoryId: message?.category,
      },
      android: {
        ...android,
      },
    };
  },
  callApiActionUrl: url => {
    if (_.isEmpty(url)) {
      return;
    }
    store.dispatch(
      notificationUrlRequest(
        { url: url },
        rs => {},
        err => {
          handleShowError(err);
        },
      ),
    );
  },
  callApiActionUrlWithData: (actionType, actions) => {
    if (_.isEmpty(actions) || _.isEmpty(actionType)) {
      return;
    }

    const url = actions.find(x => x.id === actionType)?.data?.url;
    if (_.isEmpty(url)) {
      // const now = new date

      return;
    }

    const now = getCurentDateTimeText(DATE_FORMAT.StandardFullDate);
    const url1 = replaceAll(url, '{currentTime}', now);
    const url2 = replaceAll(url1, '%7BcurrentTime%7D', now);
    NotificationActionHelper.callApiActionUrl(url2);
  },
};
