import { getReportBundleFormService } from '@services/form'

// @ts-ignore
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { getReportBundleFormFailed, getReportBundleFormSuccess } from './getReportBundleFormSlice';
import { Constant } from "@constant";
import {FormBundleMockData} from "@constant/model/mock/report/bundle";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* getReportBundleFormSaga({ payload, meta, error }): Generator<
  | CallEffect
  | PutEffect<{
      payload: any;
      type: string;
    }>,
  void
> {
  try {
    const res = yield call(getReportBundleFormService, payload);
    // const res = FormBundleMockData
    console.log('getReportBundleFormSaga>>>>', res);
    if (res?.status) {
      yield put(getReportBundleFormSuccess(res));
        if (meta) {
            yield call(meta, res);
        }
    } else {
      // @ts-ignore
      yield put(getReportBundleFormFailed(res));
        if (error) {
            yield call(error, res);
        }
    }
  } catch (err) {
    // @ts-ignore
    yield put(getReportBundleFormFailed(err));
      if (error) {
          yield call(error, err);
      }
  }
}
