import { MessageCheckIcon, CrossIcon, WarningIcon } from '@assets';
import { AppAlert, AppText, AppButton } from '@components';
import { ForceUpdateStyles } from '@components/AppForceUpdate/ForceUpdate.style';
import { PERMISSION_MODEL } from '@constant';
import theme, { globalStyle } from '@theme';
import { deviceWidth, Font, FontSize } from '@theme/size';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';
import { useNoInternetModal } from './hook';

export const NoInternetModal = props => {
  const { show } = props;
  const { handlePressButton, handleHideModal } = useNoInternetModal(props);
  const { t } = useTranslation();

  // const { title, desc } = text;

  return (
    <AppAlert
      onDismiss={handleHideModal}
      closeOnTouchOutside
      show={show}
      contentContainerStyle={{ maxWidth: '92%' }}
      customView={
        <View style={{ paddingHorizontal: 8 }}>
          <TouchableOpacity
            onPress={handleHideModal}
            style={{
              position: 'absolute',
              top: 6,
              right: 6,
              backgroundColor: '#c9c9c9',
              zIndex: 1,
              padding: 6,
              borderRadius: 1000,
            }}>
            <CrossIcon color="white" />
          </TouchableOpacity>
          <View style={[globalStyle.directionColumm, { marginTop: 24 }]}>
            <View style={[globalStyle.center]}>
              <WarningIcon width={45} height={45} />
            </View>
            <View style={[globalStyle.center, { paddingTop: 16 }]}>
              {/*<AppText style={[Font.Bold_700, globalStyle.ce]}>{title}</AppText>*/}
              <AppText
                style={[
                  {
                    fontSize: FontSize.Font14,
                    // paddingVertical: 8,
                    textAlign: 'center',
                    color: 'black',
                    ...Font.Bold_900,
                  },
                ]}>
                {t('NO_INTERNET_TITLE')}
              </AppText>
            </View>
            <View style={[globalStyle.center, { paddingVertical: 8 }]}>
              {/*<AppText style={[Font.Bold_700, globalStyle.ce]}>{title}</AppText>*/}
              <AppText
                style={[
                  {
                    fontSize: FontSize.Font14,
                    // paddingVertical: 8,
                    textAlign: 'center',
                    color: '#666666',
                  },
                ]}>
                {t('NO_INTERNET_DESCRIPTION')}
              </AppText>
            </View>
          </View>
          <View style={[ForceUpdateStyles.row, { marginVertical: theme.spacing.md }]}>
            <AppButton
              // style={ForceUpdateStyles.navigationButton}
              onPress={handlePressButton}
              text={t('NO_INTERNET_TRY_AGAIN')}
            />
          </View>
        </View>
      }
    />
  );
};
