import { createSlice } from '@reduxjs/toolkit';

export interface IcheckUnsubscribeConditionsState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IcheckUnsubscribeConditionsState = {
  loading: false,
  payload: null,
  error: null,
};

const checkUnsubscribeConditionsSlice = createSlice({
  name: 'checkUnsubscribeConditions',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    checkUnsubscribeConditionsRequest(state, action) {
      state.loading = true;
      // @ts-ignore
      state.payload = null;
      state.error = null;
    },
    checkUnsubscribeConditionsSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    checkUnsubscribeConditionsFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { checkUnsubscribeConditionsRequest, checkUnsubscribeConditionsSuccess, checkUnsubscribeConditionsFailed } = checkUnsubscribeConditionsSlice.actions;

export default checkUnsubscribeConditionsSlice.reducer;
