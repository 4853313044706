import theme from '@theme';
import { palette } from '@theme/colors';
import { FontSize, Spacing } from '@theme/size';
import { StyleSheet } from 'react-native';

export const SendStyle = StyleSheet.create({
  searchContainer: {
    borderWidth: 1,
    borderColor: '#C6C6C6',
    borderRadius: 21,
    minHeight: 42,
    // backgroundColor: 'red',
    // flex: 1,
    // alignItems: 'center',
    // textAlignVertical: 'center',
    justifyContent: 'center',
  },
  imageWrapper: {
    // margin: theme.spacing.sm,
    // padding: 10,
    // flex: 1,
    // height:100,
    // flexDirection: 'row',
    // flexWrap: 'wrap',
  },
  imageBox: {
    flex: 1,
    maxWidth: '32%',
    height: Spacing.width76,
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    // borderStyle: 'dotted',
    // borderWidth: 1,
    marginBottom: Spacing.width10,
    marginRight: Spacing.width10,
  },
  outermostItem: {
    marginRight: 0,
  },
  imageWrap: {
    borderStyle: 'solid',
    borderColor: '#D5D5D5',
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: 4,
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1,
    padding: 10,
    // borderColor: palette.WHITE,
    backgroundColor: palette.WHITE,
    opacity: 0.8,
  },
  header: {
    backgroundColor: '#F7F7F7',
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    minHeight: 43,
  },
  headerTxt: {
    fontWeight: '700',

    color: '#000000',
    paddingHorizontal: 15,
    paddingVertical: 12,
  },
});
