import getOverviewCDSSlice from '@redux/survey/getOverviewCDSSlice';
import getOverviewD2DSlice from '@redux/survey/getOverviewD2DSlice';
import getD2DItemsSlice from '@redux/survey/getD2DItemsSlice';
import saveFeedbackCDSSlice from '@redux/survey/saveFeedbackCDSSlice';
import saveFeedbackD2DSlice from '@redux/survey/saveFeedbackD2DSlice';
import saveFeedbackD2DForDailySlice from '@redux/survey/saveFeedbackD2DForDailySlice';
import getCDSDetailSlice from '@redux/survey/getCDSDetailSlice';
import getListUnsureDateSlice from '@redux/survey/getListUnsureDateSlice';

export const surveyReducer = {
 getOverviewCDS: getOverviewCDSSlice,
 getOverviewD2D: getOverviewD2DSlice,
 getD2DItems: getD2DItemsSlice,
 saveFeedbackCDS: saveFeedbackCDSSlice,
 saveFeedbackD2D: saveFeedbackD2DSlice,
 saveFeedbackD2DForDaily: saveFeedbackD2DForDailySlice,
 getCDSDetail: getCDSDetailSlice,
 getListUnsureDate: getListUnsureDateSlice,
};

