export const ReportBundleMockData = {
  summary: {
    id: '33048',
    total: 12,
    date: '23.06.2022',
  },
  form: [
    {
      title: 'Report Bundle Posting',
      description: '',
      attributes: [
        {
          name: 'When did you post the test items?',
          type: 'datetime',
          internalId: 'posting_datetime',
          validation: {
            type: 'javascript',
            javascript: 'return moment().diff(moment(posting_datetime)) >= 0;',
          },
          maxDate: {
            javascript: 'return moment();',
          },
          errorMessage: 'Field is required',
          errors: {
            required: 'Fied is required',
            validation: 'Datetime in the future is not allowed',
          },
          required: true,
          editable: true,
        },
        {
          name: 'Where did you post the test items?',
          description:
            'Please note: The posting point has to be in your postcode area. The first 1 or 2 letters of the postbox number will indicate the postcode area (e.g. AB10 1).',
          type: 'dispatch_point',
          internalId: 'dispatch_point',
          errorMessage: 'Field is required',
          errors: {
            required: 'Fied is required',
          },
          required: true,
          editable: true,
          options: [
            {
              internalId: 'Postbox',
              name: 'Postbox',
            },
            {
              internalId: 'Delivery Office',
              name: 'Delivery Office',
            },
            {
              internalId: 'Parcel Postbox',
              name: 'Parcel Postbox',
            },
          ],
          subForms: [
            {
              internalId: 'nearest_address',
              type: 'nearest_address',
              editable: true,
              titlePopup: 'Select address',
              descriptionPopup: 'Not in the list? Then please <a>click here</a> to search by postal code.',
            },
            {
              internalId: 'search_postcode',
              type: 'postcode',
              editable: true,
              titlePopup: 'Select address',
              descriptionPopup: 'Not in the list? Then please <a>click here</a> to search by postal code.',
            },
            {
              internalId: 'search_address',
              type: 'address',
              editable: true,
              titlePopup: 'Select address',
            },
            {
              internalId: 'emptying_time',
              type: 'emptying_time',
              editable: false,
            },
          ],
        },
        {
          name: 'Photos of the posting point',
          type: 'camera',
          internalId: 'postbox_image_url',
          errorMessage: 'Field is required',
          errors: {
            required: 'Fied is required',
          },
          required: true,
          editable: true,
          condition: {
            react: 'return posting_location_id ==="";',
          },
        },
        {
          name: 'Can you confirm that these collection times are still up to date or are they different to what you can see on the plate?',
          type: 'stringlistsingle',
          internalId: 'confirm_collection_time',
          options: [
            {
              name: 'Yes, still correct',
              internalId: 'yes',
            },
            {
              name: 'No, they´re different',
              internalId: 'no',
            },
            {
              name: 'Not sure / No times available or visible',
              internalId: 'not_sure',
            },
          ],
          errorMessage: 'Field is required',
          errors: {
            required: 'Fied is required',
          },
          required: true,
          editable: true,
          condition: {
            react: 'return posting_location_id;',
          },
        },
        {
          name: 'Photo of the collection time plate',
          type: 'camera',
          internalId: 'collection_times_image_url',
          errorMessage: 'Field is required',
          errors: {
            required: 'Fied is required',
          },
          required: true,
          editable: true,
          condition: {
            react: 'return (confirm_collection_time == "no" || confirm_collection_time == "not_sure");',
          },
        },
        {
          name: 'Did you post all 123 planned test-items of this bundle planned test-items of this bundle?',
          type: 'boolean',
          internalId: 'confirm_count_items',
          options: [
            {
              name: 'Yes',
              internalId: true,
              icon: 'check',
            },
            {
              name: 'No',
              internalId: false,
              icon: 'times',
            },
          ],
          errorMessage: 'Field is required',
          errors: {
            required: 'Fied is required',
          },
          required: true,
          editable: true,
          condition: {
            react: 'return (!!postbox_image_url || !!collection_times_image_url || confirm_collection_time == "yes");',
          },
        },
        {
          name: 'Please let us know any deviations here',
          condition: {
            react: "return (typeof confirm_count_items != 'undefined' && !confirm_count_items);",
          },
          type: 'textarea',
          internalId: 'deviations',
          errorMessage: 'Field is required',
          errors: {
            required: 'Fied is required',
          },
          required: true,
          editable: true,
        },
      ],
    },
  ],
  address: [
    {
      id: 'IG11 50',
      street: 'THAMES ROAD ',
      house_number: '',
      postal_code: 'IG11 0HN',
      distance_in_meters: '2970.32',
      distance_in_kilometers: '2.97',
      distance_in_yards: '3248.34',
      distance_in_miles: '1.81',
      collection_times: {
        Mon: '17:30:00',
        Tue: '17:30:00',
        Wed: '17:30:00',
        Thu: '17:30:00',
        Fri: '17:30:00',
        Sat: '11:30:00',
      },
    },
    {
      id: 'E6 46',
      street: 'ALPINE WAY LONDON',
      house_number: '',
      postal_code: 'E6 4',
      distance_in_meters: '3626.56',
      distance_in_kilometers: '3.63',
      distance_in_yards: '3966.00',
      distance_in_miles: '2.22',
      collection_times: {
        Mon: '18:30:00',
        Tue: '18:30:00',
        Wed: '18:30:00',
        Thu: '18:30:00',
        Fri: '18:30:00',
        Sat: '11:30:00',
      },
    },
    {
      id: 'RM9 545',
      street: 'CHOATS ROAD BUSINESS BOX DAGENHAM',
      house_number: '',
      postal_code: 'RM9 6RH',
      distance_in_meters: '3694.00',
      distance_in_kilometers: '3.69',
      distance_in_yards: '4039.76',
      distance_in_miles: '2.26',
      collection_times: {
        Mon: '17:15:00',
        Tue: '17:15:00',
        Wed: '17:15:00',
        Thu: '17:15:00',
        Fri: '17:15:00',
        Sat: '11:30:00',
      },
    },
    {
      id: 'DA17 4181',
      street: 'ANDERSON WAY METER BOX ',
      house_number: '',
      postal_code: 'DA17 6BG',
      distance_in_meters: '3876.73',
      distance_in_kilometers: '3.88',
      distance_in_yards: '4239.59',
      distance_in_miles: '2.37',
      collection_times: {
        Mon: '18:00:00',
        Tue: '18:00:00',
        Wed: '18:00:00',
        Thu: '18:00:00',
        Fri: '18:00:00',
        Sat: '11:30:00',
      },
    },
    {
      id: 'DA16 4031',
      street: 'BELLEGROVE ROAD METER BOX ',
      house_number: '',
      postal_code: 'DA16 3RQ',
      distance_in_meters: '3995.94',
      distance_in_kilometers: '4.00',
      distance_in_yards: '4369.96',
      distance_in_miles: '2.44',
      collection_times: {
        Mon: '18:00:00',
        Tue: '18:00:00',
        Wed: '18:00:00',
        Thu: '18:00:00',
        Fri: '18:00:00',
        Sat: '11:30:00',
      },
    },
    {
      id: 'IG11 178',
      street: 'BARKING DELIVERY OFFICE MOVERS LANE',
      house_number: '',
      postal_code: 'IG11 7UJ',
      distance_in_meters: '4166.87',
      distance_in_kilometers: '4.17',
      distance_in_yards: '4556.89',
      distance_in_miles: '2.55',
      collection_times: {
        Mon: '17:30:00',
        Tue: '17:30:00',
        Wed: '17:30:00',
        Thu: '17:30:00',
        Fri: '17:30:00',
        Sat: '12:30:00',
      },
    },
    {
      id: 'SE18 18',
      street: 'WARSPITE INDUSTRIAL ESTATE WARSPITE ROAD',
      house_number: '',
      postal_code: 'SE18 5NX',
      distance_in_meters: '4170.04',
      distance_in_kilometers: '4.17',
      distance_in_yards: '4560.36',
      distance_in_miles: '2.55',
      collection_times: {
        Mon: '18:00:00',
        Tue: '18:00:00',
        Wed: '18:00:00',
        Thu: '18:00:00',
        Fri: '18:00:00',
        Sat: '12:00:00',
      },
    },
    {
      id: 'RM13 455',
      street: 'FAIRVIEW IND ESTATE BUSINESS ',
      house_number: '',
      postal_code: 'RM13 8UD',
      distance_in_meters: '5149.16',
      distance_in_kilometers: '5.15',
      distance_in_yards: '5631.12',
      distance_in_miles: '3.15',
      collection_times: {
        Mon: '18:00:00',
        Tue: '18:00:00',
        Wed: '18:00:00',
        Thu: '18:00:00',
        Sat: '11:30:00',
        Fri: '18:00:00',
      },
    },
    {
      id: 'E16 10',
      street: 'KIERBECK BUSINESS CENTRE NOTRH WOOLWICH ROAD',
      house_number: '',
      postal_code: 'E16 2BG',
      distance_in_meters: '5478.12',
      distance_in_kilometers: '5.48',
      distance_in_yards: '5990.87',
      distance_in_miles: '3.35',
      collection_times: {
        Mon: '18:30:00',
        Tue: '18:30:00',
        Wed: '18:30:00',
        Thu: '18:30:00',
        Fri: '18:30:00',
        Sat: '11:30:00',
      },
    },
    {
      id: 'DA6 4061',
      street: 'MARKET PLACE METER BOX ',
      house_number: '',
      postal_code: 'DA6 7DY',
      distance_in_meters: '5507.11',
      distance_in_kilometers: '5.51',
      distance_in_yards: '6022.57',
      distance_in_miles: '3.36',
      collection_times: {
        Mon: '18:00:00',
        Tue: '18:00:00',
        Wed: '18:00:00',
        Thu: '18:00:00',
        Fri: '18:00:00',
        Sat: '12:00:00',
      },
    },
  ],
  userLocation: {
    longitude: 0.10444991327,
    latitude: 51.49849611888,
  },
};

export const FormBundleMockData = {
  status: true,
  message: 'Bundle posting report form successfully fetched',
  data: [
    {
      title: 'Report Bundle Posting',
      description: '',
      attributes: [
        {
          name: '<span style="color: #999999;">Posting point must be in your home postcode area, otherwise, no points could be collected because this posting is not considered to be final. The first 1 or 2 letters of the postbox number will indicate the postcode area (e.g. AB10 1).</span>',
          type: 'note',
          popup: false,
        },
        {
          name: 'Where did you post the test items?',
          type: 'dispatch_point',
          internalId: 'dispatch_point',
          errorMessage: 'This field is required',
          errors: {
            required: 'This field is required',
          },
          required: true,
          editable: true,
          placeHolder: 'Please select',
          options: [
            {
              internalId: 'Delivery Office',
              name: 'Delivery/Post Office',
            },
            {
              internalId: 'Collection',
              name: 'Collection',
            },
          ],
          hideSubForm: {
            react:
              "return {{dispatch_point}} && ({{dispatch_point}} == 'Parcel Collection' || {{dispatch_point}} == 'Collection');",
          },
          verifyOnMap: true,
          subForms: [
            {
              internalId: 'nearest_address',
              type: 'nearest_address',
              editable: true,
              required: true,
              titlePopup: 'Select address',
              descriptionPopup: 'Not in the list? Tap <a href="nearest_address"> here</a>.',
              title: '',
              condition: {
                react: 'return !!{{enable_nearest_address}}',
              },
            },
            {
              internalId: 'search_postcode',
              type: 'bundle_postcode',
              editable: true,
              titlePopup: 'Select postcode',
              placeHolder: 'Enter postcode or postbox ID',
              descriptionPopup: 'Not in the list? Tap <a> here</a> to search by postcode.',
              required: true,
              minSearch: 3,
              errors: {
                required: 'This field is required',
                validation: 'Please enter a valid postcode or postbox ID',
                minSearch: 'Please enter at least 3 characters',
              },
              condition: {
                react: "return nearest_address === '';",
              },
              validation: {
                type: 'javascript',
                javascript: 'return (!!list_post_position && list_post_position.length>0) || (!!reset_bundle_address);',
              },
              title: '',
            },
            {
              internalId: 'list_post_position',
              name: 'list_post_position',
              type: 'list_post_position',
              popup: false,
              title: 'list_post_position',
            },
            {
              internalId: 'search_address',
              type: 'bundle_address',
              editable: true,
              titlePopup: 'Select address',
              descriptionPopup: 'Not in the list? Tap <a href="search_address">here</a>.',
              required: true,
              errors: {
                required: 'This field is required',
              },
              condition: {
                react:
                  'return !nearest_address && !!search_postcode && !!list_post_position && list_post_position.length > 0;',
              },
            },
            {
              internalId: 'verify_map',
              type: 'verify_map',
            },
            {
              internalId: 'emptying_time',
              type: 'emptying_time',
              editable: false,
              condition: {
                react: 'return !!nearest_address || !!search_address;',
              },
            },
          ],
        },
        {
          name: 'Photos of the posting point',
          type: 'camera',
          internalId: 'posting_location_image_url',
          errorMessage: 'This field is required',
          errors: {
            required: 'This field is required',
          },
          required: true,
          editable: true,
          condition: {
            react: 'return dispatch_point && posting_location_id === "";',
          },
          uploadFileName:
            'bundle_20240531_63060ddf94733b0da58eb1fc_123456_posting_location_image_url_{{posting_location_id}}',
          uploadPath: 'app/bundle/123456',
          s3config: 'TTM',
        },
        {
          name: 'How was the test item collected?',
          type: 'stringlistsingle',
          internalId: 'parcel_collection_collected',
          options: [
            {
              name: 'Handed to the Royal Mail',
              internalId: 'Handed to the Royal Mail',
            },
            {
              name: 'Collected from a safe place',
              internalId: 'Collected from a safe place',
            },
          ],
          popup: false,
          errorMessage: 'This field is required',
          errors: {
            required: 'This field is required',
          },
          required: true,
          editable: true,
          condition: {
            react: 'return {{dispatch_point}} == "Parcel Collection";',
          },
        },
        {
          name: 'Which of the following safe place options did you collect the parcel?',
          type: 'stringlistsingle',
          internalId: 'parcel_collection_safeplace',
          options: [
            {
              name: 'Enclosed porch',
              internalId: 'enclosed_porch',
            },
            {
              name: 'Shed',
              internalId: 'shed',
            },
            {
              name: 'Greenhouse',
              internalId: 'greenhouse',
            },
            {
              name: 'Garage',
              internalId: 'garage',
            },
            {
              name: 'Outbuilding',
              internalId: 'outbuilding',
            },
            {
              name: 'Other',
              internalId: 'other',
            },
          ],
          popup: false,
          errorMessage: 'This field is required',
          errors: {
            required: 'This field is required',
          },
          required: true,
          editable: true,
          condition: {
            react:
              'return {{dispatch_point}} == "Parcel Collection" && {{parcel_collection_collected}} == "Collected from a safe place";',
          },
        },
        {
          name: 'Can you confirm that these collection times are still up to date or are they different from what you can see on the plate?',
          type: 'stringlistsingle',
          internalId: 'confirm_collection_time',
          options: [
            {
              name: 'Yes, still correct',
              internalId: 'yes',
            },
            {
              name: 'No, they’re different',
              internalId: 'no',
            },
            {
              name: 'Not sure/No time available or visible',
              internalId: 'not_sure',
            },
            {
              name: 'I’ve already reported the new collection times',
              internalId: 'already_report_new_collection_time',
            },
          ],
          popup: false,
          errorMessage: 'This field is required',
          errors: {
            required: 'This field is required',
          },
          required: true,
          editable: true,
          condition: {
            react: 'return dispatch_point && posting_location_id;',
          },
        },
        {
          name: 'Photo of the collection timeplate',
          type: 'camera',
          internalId: 'collection_times_image_url',
          errorMessage: 'This field is required',
          errors: {
            required: 'This field is required',
          },
          required: true,
          editable: true,
          condition: {
            react: 'return (confirm_collection_time == "no" || confirm_collection_time == "not_sure");',
          },
          requiredCondition: {
            react: 'return {{confirm_collection_time}} == "no";',
          },
          uploadFileName:
            'bundle_20240531_63060ddf94733b0da58eb1fc_123456_collection_times_image_url_{{posting_location_id}}',
          uploadPath: 'app/bundle/123456',
          s3config: 'TTM',
        },
        {
          name: 'When did you post the test items?',
          type: 'datetime',
          internalId: 'posting_datetime',
          validation: {
            type: 'javascript',
            javascript: 'return moment().diff(moment(posting_datetime)) >= 0;',
          },
          minDate: {
            javascript: "return moment('26/09/2022, 00:00', 'DD-MM-YYYY HH:mm:ss');",
          },
          maxDate: {
            javascript: 'return moment();',
          },
          errorMessage: 'This field is required',
          errors: {
            required: 'This field is required',
            validation: 'The date you are trying to enter is not possible, please double-check.',
          },
          required: true,
          editable: true,
          condition: {
            react:
              'return ((dispatch_point == "Collection") || (posting_location_id === "" && posting_location_image_url.length > 0) || (confirm_collection_time == "yes" || confirm_collection_time == "already_report_new_collection_time") || (confirm_collection_time == "not_sure" || (confirm_collection_time == "no" && collection_times_image_url && collection_times_image_url.length > 0)));',
          },
          placeHolder: 'Select date & time',
        },
        {
          name: '',
          description:
            '<span style="color: #da202a;margin-top:-17px;">This early morning posting time is unusual. Please double-check.</span>',
          type: 'note',
          styleCSS: {
            marginTop: -17,
          },
          required: false,
          condition: {
            react: "return posting_datetime && moment(posting_datetime).format('H') < 6;",
          },
        },
        {
          name: '',
          description:
            '<span style="color: #da202a;margin-top:-17px;">Correct data means a lot to us. Kindly double-check to make sure time is in exact minute.</span>',
          type: 'note',
          styleCSS: {
            marginTop: -17,
          },
          required: false,
          condition: {
            react: "return posting_datetime && moment(posting_datetime).format('m') == 0;",
          },
        },
        {
          name: '<span style="color: #999999;">Please specify the collection time in your collection confirmation email.</span>',
          type: 'note',
          popup: false,
          condition: {
            react: 'return (parcel_collection_collected == "Handed to the Royal Mail" || parcel_collection_safeplace)',
          },
        },
        {
          name: 'When was this parcel collected?',
          type: 'datetime',
          internalId: 'posting_datetime_parcel',
          validation: {
            type: 'javascript',
            javascript: 'return moment().diff(moment(posting_datetime_parcel)) >= 0;',
          },
          minDate: {
            javascript: "return moment().subtract(14, 'days');",
          },
          maxDate: {
            javascript: 'return moment();',
          },
          errorMessage: 'This field is required',
          errors: {
            required: 'This field is required',
            validation: 'The date you are trying to enter is not possible, please double-check.',
          },
          required: true,
          editable: true,
          condition: {
            react: 'return (parcel_collection_collected == "Handed to the Royal Mail" || parcel_collection_safeplace)',
          },
          placeHolder: 'Select date & time',
        },
        {
          name: '',
          description:
            '<span style="color: #da202a;margin-top:-17px;">The time you are posing is early morning and is unusual. Please confirm that you are recording at {{posting_datetime_parcel}}</span>',
          type: 'note',
          styleCSS: {
            marginTop: -17,
          },
          required: false,
          condition: {
            react: "return posting_datetime_parcel && moment(posting_datetime_parcel).format('H') < 6;",
          },
        },
        {
          name: '',
          description:
            '<span style="color: #da202a;margin-top:-17px;">Correct data means a lot to us. Kindly double-check to make sure time is in exact minute.</span>',
          type: 'note',
          styleCSS: {
            marginTop: -17,
          },
          required: false,
          condition: {
            react: "return posting_datetime_parcel && moment(posting_datetime_parcel).format('m') == 0;",
          },
        },
        {
          name: 'Why was it not possible to post the bundle on the planned posting target date?',
          type: 'stringlistsingle',
          internalId: 'reason_different_senddate_target',
          options: [
            {
              name: 'I was absent that day (holiday/sickness/appointment)',
              internalId: 'absent_that_day',
            },
            {
              name: 'The package wasn’t delivered on time',
              internalId: 'sender_pack_not_delivery_on_time',
            },
            {
              name: 'Post office has rejected the items',
              internalId: 'post_office_reject_item',
            },
            {
              name: 'Issue with the test letters (please specify below)',
              internalId: 'issue_with_test_letter',
            },
            {
              name: 'Other (please specify below)',
              internalId: 'other',
            },
          ],
          popup: false,
          errors: {
            required: 'This field is required',
          },
          required: true,
          editable: true,
          condition: {
            react:
              "return (moment({{posting_datetime}}).format('YYYY-MM-DD') != '2024-04-01' && {{posting_datetime}}) || (moment({{posting_datetime_parcel}}).format('YYYY-MM-DD') != '2024-04-01' && {{posting_datetime_parcel}});",
          },
        },
        {
          name: 'Please specify the reason',
          condition: {
            react:
              "return ({{reason_different_senddate_target}} == 'issue_with_test_letter' || {{reason_different_senddate_target}} == 'other');",
          },
          type: 'textArea',
          internalId: 'reason_different_senddate_target_deviations',
          errors: {
            required: 'This field is required',
          },
          required: true,
          editable: true,
        },
        {
          name: "You selected collection but we don't have any FIR number for you. Do you know your FIR code?",
          type: 'boolean',
          internalId: 'fircode_known',
          options: [
            {
              name: 'Yes',
              internalId: true,
              icon: 'check',
            },
            {
              name: 'No',
              internalId: false,
              icon: 'times',
            },
          ],
          errors: {
            required: 'This field is required',
          },
          required: true,
          editable: true,
          condition: {
            react:
              'return {{reason_different_senddate_target_deviations}} || {{reason_different_senddate_target}} && {{reason_different_senddate_target}} != "issue_with_test_letter" && {{reason_different_senddate_target}} != "other";',
          },
        },
        {
          name: 'Please scan your FIR code before proceeding',
          description:
            "Don't know your FIR or don't have one? Please do not select collection, instead post your mail in a postbox, post office or delivery office",
          type: 'scan',
          internalId: 'fircode',
          inputManual: true,
          placeHolder: 'FIR######',
          errors: {
            required: 'This field is required',
            validation: 'FIR code must start with FIR followed by 6 numbers',
          },
          required: true,
          editable: true,
          validation: {
            type: 'javascript',
            javascript: 'return !{{fircode}} || (/^fir\\d{6}$/i).test({{fircode}});',
          },
          condition: {
            react: 'return {{fircode_known}} === true;',
          },
        },
        {
          name: 'Which PPI Product did you use when sending the test items?',
          description: '',
          type: 'stringlistsingle',
          internalId: 'ppi_product',
          errors: {
            required: 'This field is required',
          },
          required: true,
          editable: true,
          condition: {
            react:
              'return {{fircode_known}} === false || {{fircode}} || {{posting_location_image_url}} && {{posting_location_image_url}}.length > 0 || {{collection_times_image_url}} && {{collection_times_image_url}}.length > 0 || {{confirm_collection_time}} && {{confirm_collection_time}} != "no";',
          },
          options: [
            {
              name: 'BPL',
              internalId: 'bpl',
            },
            {
              name: 'STL',
              internalId: 'stl',
            },
          ],
        },
        {
          name: 'Have you added meter frank postage to this bundle?',
          type: 'boolean',
          internalId: 'add_meter_frank_postage',
          options: [
            {
              name: 'Yes',
              internalId: true,
              icon: 'check',
            },
            {
              name: 'No',
              internalId: false,
              icon: 'times',
            },
          ],
          errors: {
            required: 'This field is required',
          },
          required: true,
          editable: true,
          condition: {
            react: 'return false;',
          },
        },
        {
          name: '',
          description:
            '<span style="color: #da202a;">As a meter frank panellist, it is important that all items are sent with your normal meter frank postage mark. Please frank all of your planned bundles, including this one. Once franked and sent, please select \'Yes\' to ensure that you are able to continue with this data submission. If this is not possible, please raise a ticket in the app.</span>',
          type: 'note',
          styleCSS: {
            marginTop: -17,
          },
          required: false,
          condition: {
            react: 'return {{add_meter_frank_postage}} === false;',
          },
        },
      ],
    },
  ],
};
