import { CrossIcon, LogoIcon, MessageCheckIcon } from '@assets';
import { AppSafeAreaView, AppText } from '@components';
import { AppSuccessScreen } from '@components/AppSuccessScreen';
import GenericHeader from '@components/GenericHeader';
import styles from '@components/GenericModal/styles';
import { goBack, popToTop } from '@routes/navigationUtils';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font } from '@theme/size';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, StatusBar, TouchableOpacity } from 'react-native';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import Icon from 'react-native-vector-icons/FontAwesome5';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { useSuccessful } from './Successful.hook';
import { SuccessfulStyles } from './Successful.style';

export const SuccessfulScreen = props => {
  const { messageInfo, backClick, headerTitle } = useSuccessful(props);
  const { t } = useTranslation();
  return (
    <>
      <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
      <AppSafeAreaView areaStyle={[SuccessfulStyles.areaStyle]}>
        <GenericHeader
          title={headerTitle}
          style={[{ backgroundColor: '#EDEDED' }]}
          RightAction={
            <TouchableOpacity onPress={backClick}>
              {/*<CrossIcon size={20}/>*/}
              {/*  <Icon pack="MaterialCommunityIcons" name="close" style={styles.headerIconContent} />*/}
              {/*<FontAwesome5 name={'xmark-large'} />*/}
              <MaterialCommunityIcons name={'close'} style={SuccessfulStyles.headerIconContent} />
            </TouchableOpacity>
          }
        />
        <View style={[SuccessfulStyles.containerView]}>
          <AppSuccessScreen
            alert={messageInfo?.alert}
            icon={messageInfo?.icon}
            description={messageInfo?.description}
          />
        </View>
      </AppSafeAreaView>
    </>
  );
  // return (
  //   <AppSafeAreaView areaStyle={[globalStyle.flex1, globalStyle.alignCenter, globalStyle.justifyCenter]}>
  //     {/*<Icon name="comment-dots" size={40} color={palette.ROYAL_MAIL_RED} />*/}
  //     <MessageCheckIcon color="#8BB73B" />
  //     <View
  //       style={[
  //         globalStyle.alignCenter,
  //         globalStyle.justifyCenter,
  //         globalStyle.alignSelfCenter,
  //         globalStyle.directionColumm,
  //         { paddingHorizontal: 40 },
  //       ]}>
  //       <AppText style={[Font.Bold_700, { textAlign: 'center', marginVertical: 14 }]}>
  //         {t('ACTIVE_ACCOUNT_SUCCESSFUL_TITLE')}
  //       </AppText>
  //       <AppText style={[{ textAlign: 'center', marginHorizontal: theme.spacing.sm, color: '#666666' }]}>
  //         {t('ACTIVE_ACCOUNT_SUCCESSFUL_DESCRIPTION')}
  //       </AppText>
  //     </View>
  //   </AppSafeAreaView>
  // );
};
