import {PERMISSION_MODEL, PERMISSION_TYPE} from '@constant';
import { hideLoading } from '@redux/global/globalSlice';
import { getProfileCreator } from '@redux/user/selectors';
import { requestPermission } from '@utils/common/deviceUtil';
import { LocationType } from '@utils/hooks';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import Geolocation from 'react-native-geolocation-service';
import { useDispatch, useSelector } from 'react-redux';
import {AppState} from "react-native";
import {LocationHelper} from "@utils/common/permission/location";

export const useWatchLocation = () => {
  const getProfileData = useSelector(getProfileCreator)?.message?.data;

  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState(null);
  const [locationType, setLocationType] = useState('');
  const [watchId, setWatchId] = useState(null);
  const [locationEnable, setLocationEnable] = useState(null)

  const watchPosition = () => {
    const id = Geolocation.watchPosition(
      x => {
        setLoading(false);
        setPosition(x);
        console.log('watchPosition>>>position>>>', x);
        setLocationType(LocationType.gps);
      },
      error => {
        setLoading(false);

        console.log(error);
      },
      {
        enableHighAccuracy: true,
        distanceFilter: 0,
        interval: 5000,
        fastestInterval: 2000,
      },
    );
    setWatchId(id)
  };

  useEffect(() => {
    if (!_.isEmpty(getProfileData?.latitude)) {
      setLocationType(LocationType.home);
    }
  }, [getProfileData]);

  // useEffect(() => {
  //   requestLocation();
  //   return () => {
  //     Geolocation.clearWatch();
  //   };
  // }, []);

  useEffect(() => {
    return () => {
      // Geolocation.clearWatch();
      console.log('unsub>>>>clearListenLocation>>>>')
      clearListenLocation()
    };
  }, []);

  useEffect(() => {
    const subAppState = AppState.addEventListener('change', async nextAppState => {
      if (nextAppState === 'active') {
        const enable = await LocationHelper.checkLocationEnable();
        if (!enable) {
          setLocationType(LocationType.home);
          clearListenLocation();
        } else {
          //TODO: update location
          if (_.isEmpty(watchId)) {
            clearListenLocation();
            requestLocation(PERMISSION_TYPE.CHECK);
          }
        }
      }
    });
    return () => {
      if (subAppState) {
        subAppState.remove();
      }
    };
  }, []);

  const requestLocation = async (type = PERMISSION_TYPE.REQUEST) => {
    try {
      setLoading(true);
      await requestPermission(
        () => {
          watchPosition();
        },
        PERMISSION_MODEL.LOCATION,
        type,
      );
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const clearListenLocation = () => {
    console.log('clearListenLocation>>>>', watchId)
    Geolocation.clearWatch(watchId);
    setWatchId(null)
  };
  return {
    lat: position?.coords?.latitude || Number(getProfileData?.latitude),
    long: position?.coords?.longitude || Number(getProfileData?.longitude),
    loading,
    position,
    locationType,
    requestLocation,
    clearListenLocation,
  };
};
