import _ from 'lodash';
import replaceAll from 'string.prototype.replaceall';
import {Device} from "@theme/device";

export const StringHelper = {
  removeSpace: value => {
    if (!value) {
      return value;
    }
    return replaceAll(value, ' ', '');
  },
  getNumber: value => {
    return value.replace(/[^0-9|]/g, '');
    // return value.replace(/[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)/g, '');
  },
  getFloatNumber: value => {
    // return value.replace(/[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)/g, '');
    return value.replace(/[^\d.,-]/g, '');
  },
  getRegexNumber: () => {
    return /[+-]?((\d+\.?\d*)|(\.\d+))/g
  },
  randomId: () => {
    return Math.floor(Math.random() * 100 + Date.now());
  },
  isSupportVariable: expression => {
    if (_.isEmpty(expression)) {
      return false;
    }
    return expression.includes('{{') && expression.includes('}}');
  },
  fixEvalExpressionWithVariable: (expression, values) => {
    if (_.isEmpty(expression) || _.isEmpty(values)) {
      return expression;
    }
    let fixExpression = expression;
    // fixExpression = fixExpression.replaceAll('moment(', 'this.moment(');
    // fixExpression = replaceAll(fixExpression, 'moment(', 'this.moment(');
    if (Device.deviceType === 'web') {
      fixExpression = replaceAll(fixExpression, 'moment(', '_moment(');
      fixExpression = replaceAll(fixExpression, '^moment()', '_moment()');
    } else {
      fixExpression = replaceAll(fixExpression, 'moment(', 'this.moment(');
    }

    let keys = Object.keys(values);
    const lsKeyScript = StringHelper.getKeysFromScript(fixExpression)
    keys = [...new Set([...keys, ...lsKeyScript])];
    for (const valuesKey of keys) {
      fixExpression = replaceAll(fixExpression, `{{${valuesKey}}}`, `values['${valuesKey}']`);
    }

    // console.log('fixEvalExpressionWithVariable>>>', fixExpression)
    return fixExpression;
  },
  fixEvalExpression: (expression, values) => {
    const isSupportVariable = StringHelper.isSupportVariable(expression);
    if (isSupportVariable) {
      return StringHelper.fixEvalExpressionWithVariable(expression, values);
    }
    if (_.isEmpty(expression) || _.isEmpty(values)) {
      return expression;
    }
    // const keys = Object.keys(values)
    let fixExpression = expression;
    // fixExpression = fixExpression.replaceAll('moment(', 'this.moment(');
    if (Device.deviceType === 'web') {
      fixExpression = replaceAll(fixExpression, 'moment(', '_moment(');
      fixExpression = replaceAll(fixExpression, '^moment()', '_moment()');
    } else {
      fixExpression = replaceAll(fixExpression, 'moment(', 'this.moment(');
    }

    const keys = Object.keys(values);
    keys.sort();
    // keys.reverse()
    // console.log('fixEvalExpression>>>', keys);
    // const ls = fixExpression.split(' ');
    // const xxx = []
    for (const valuesKey of keys) {
      const special_char = [' ', ')', ';', ',', '(', '.'];
      for (const ch of special_char) {
        // const rp = `${valuesKey}${ch}`;
        // console.log('rp>>>', rp)
        // fixExpression = fixExpression.replaceAll(`${valuesKey}${ch}`, `this.values['${valuesKey}']${ch}`);
        fixExpression = replaceAll(fixExpression, `${valuesKey}${ch}`, `values['${valuesKey}']${ch}`);
      }
    }
    return fixExpression;
  },
  removeHtmlTag: text => {
    if(_.isEmpty(text)){
      return ""
    }
    const regex = /(<([^>]+)>)/gi;
    const newString = text.replace(regex, "");
    return newString

  },
  includesAll: (value, list_str = []) => {
    if(_.isEmpty(value)){
      return false
    }
    if(_.isEmpty(list_str)){
      return true
    }
    for (const st of list_str) {
      if(!value.includes(st)){
        return false
      }
    }
    return true
  },
  includesAny:(value, list_str = []) => {
    if(_.isEmpty(value)){
      return true
    }

    if(_.isEmpty(list_str)){
      return true
    }
    for (const st of list_str) {
      // console.log('st>>>', value, st, value.includes(st))

      if(value.includes(st)){
        return true
      }
    }
    return false
  },
  getKeysFromScript: script => {
    if (_.isEmpty(script)) {
      return [];
    }
    const pattern = /\{\{(\w+)\}\}/g;
    const rs = script.match(pattern)?.map(x => x.replace('{{', '').replace('}}', ''));
    return rs;
  },
};
