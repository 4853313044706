import { getProfileRequest } from '@redux/user/getProfileSlice';
import { getProfileCreator, getUserCreator } from '@redux/user/selectors';
import { updateProfileRequest } from '@redux/user/updateProfileSlice';
import {goBack, navigate, navigatePop, navigatePush, popToTop} from '@routes/navigationUtils';
import { Screens } from '@routes/route';
import { getErrorMessage, getFlatMsgFromObject, showErrorMsg } from '@utils/api';
import {checkFormValidateExpression, getInitialFormValue, getYupStructure} from '@utils/form';
import { checkEvalCondition, fixFormValues, handleShowError } from '@utils/transform/form';
import { useFormik } from 'formik';
import _ from 'lodash';
import {useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { lazy } from 'yup';
import {Platform} from "react-native";
import {FormikHelper} from "@utils/hooks/formik";
import {FormGroupHelper} from "@utils/form/group";
import {AppNotification} from "@utils";
import {getNotificationPermission} from "@redux/app/appSlice";
import {FORM} from "@constant/model/RegisterForm";
import {NotificationPermissionHelper} from "@utils/common/permission/notification";
import {PERMISSION_MODEL} from "@constant";
import {requestPermission} from "@utils/common/deviceUtil";
import {hideLoading} from "@redux/global/globalSlice";

export const useProfileForm = props => {
  const rawData = props?.route.params?.data;
  const form = props?.route.params?.form;
  const [data, setData] = useState(rawData);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // const getUserData = useSelector(getUserCreator);
  const getProfileData = useSelector(getProfileCreator)?.message?.data;
  const scrollViewRef = useRef(null);
  const [layoutInfo, setLayoutInfo] = useState({});

  const hasPushNotification = () => {
    return !_.isEmpty(data?.attributes) && data?.attributes[0]?.internalId === 'push_notification'
  }

  const getProfileApi = () => {
    const payload = {};
    dispatch(getProfileRequest(payload));
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(hideLoading())
    }, 3000)
  }, [])

  const updateProfileApi = async (values, setErrors) => {
    /*
  payload_info
   */

    const payload = { ...values };
    if(getProfileData.deviceType != Platform.OS){
      payload.deviceType = Platform.OS
    }

    if(hasPushNotification()){
      const fcmToken = await AppNotification.getToken();
      payload.device_token = fcmToken
    }

    const payloadValue = await fixFormValues(payload, values);
    console.log('updateProfileApi>>>', payloadValue);
    // return
    dispatch(
      updateProfileRequest(
        payloadValue,
        rs => {
          // console.log('updateProfilexxx>>>>', rs);
          //TODO: reload api
          if(hasPushNotification()){
            dispatch(getNotificationPermission(values[FORM.FORM_ELEMENT_NAME.push_notification]));
          }
          getProfileApi();
          // navigatePop();
          handleSuccess(rs)
        },
        err => {
          handleShowError(err, setErrors);
        },
      ),
    );
  };

  const handleSuccess = rs => {
    //TODO: reload api dashboard here
    navigate(Screens.SUCCESSFUL_SCREEN, {
      data: rs?.message,
      header: {
        title: data?.title,
        backClick: () => {
          goBack()
          goBack()
        },
      },
    });
    // setIsSuccess(true);
    // setMessageInfo(rs?.message);
    // setTimeout(() => {
    //   popToTop();
    // }, 5000);
  };

  const formik = FormikHelper.useFormikWrapper({
    initialValues: {
      ...getProfileData,
      ...getInitialFormValue(rawData, getProfileData)
    },
    // validationSchema: Yup.object().shape(getYupStructure(rawData, t)),
    validationSchema: () =>
      lazy(values => {
        // console.log('lazy>>>values>>>', values);
        const ys = getYupStructure(rawData, t, 'attributes', values);
        console.log('getYupStructure>>>', ys);
        return Yup.object().shape(ys);
      }),
    initialErrors: false,
    onSubmit: (values, { setErrors, resetForm, setFieldValue }) => {
      console.log('onSubmit>>>', values, getProfileData);
      let rs = checkFormValidateExpression(data?.attributes, values, setErrors);
      console.log('onSubmit>>>checkFormValidateExpression>>>', rs,);
      if (!_.isEmpty(rs)) {
        return;
      }
      rs = FormGroupHelper.getValuesWhenSubmit({...getProfileData} ,values, data, form, setFieldValue)
      updateProfileApi(rs, setErrors);
    },
    // handleChange:((e) => {
    //   console.log('e>>>>', e)
    // })
  }, data?.attributes);
  console.log('useProfileForm>>>', formik, getProfileData);
  const scrollToY = y => {
    // scrollViewRef.current.scroll
    scrollViewRef.current?.scrollTo({
      x: 0,
      y: y,
      animated: true,
    });
  };
  const onSavePress = () => {
    //TODO: call api here
  };

  const forceNotificationSetting = async (value) => {
    if(!!value ){
      const allowNotification = await NotificationPermissionHelper.hasPermission()
      if(allowNotification){
        return
      }
      await requestPermission(() => {
        console.log('requestPermission>>>')
      }, PERMISSION_MODEL.NOTIFICATION)

      //TODO: should reset to current value?
      formik?.setFieldValue(FORM.FORM_ELEMENT_NAME.push_notification, false)
    }
  }

  useEffect(() => {}, [dispatch]);


  useEffect(() => {
    if(hasPushNotification()){
      console.log('formik.values.push_notification>>>', formik?.values?.push_notification)
      const push_notification = formik?.values?.push_notification
      forceNotificationSetting(push_notification)
    }
  }, [formik?.values?.push_notification]);

  return {
    data, formik, onSavePress,
    scrollToY,
    scrollViewRef,
    layoutInfo, setLayoutInfo

  };
};
