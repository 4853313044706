import { FORM } from '@constant/model/RegisterForm';
import { DATE_FORMAT } from '@utils/common/MomentUtils';
import moment from 'moment';

export const DateHelper = {
  hours: [
    {
      id: 1,
      content: '01:00',
    },
    {
      id: 2,
      content: '02:00',
    },
    {
      id: 3,
      content: '03:00',
    },
    {
      id: 4,
      content: '04:00',
    },
    {
      id: 5,
      content: '05:00',
    },
    {
      id: 6,
      content: '06:00',
    },
    {
      id: 7,
      content: '07:00',
    },
    {
      id: 8,
      content: '08:00',
    },
  ],
  toDateValid: (date, format = DATE_FORMAT.StandardDate) => {
    if (date && moment(date).isValid()) return moment(date).toDate();
    return date;
  },
  toTimeValid: (date, format = DATE_FORMAT.Time) => {
    if (date && moment(date).isValid()) return moment(date).toDate();
    if (date) {
      return moment(date, format).toDate();
    }
    return date;
  },
  getDateValue: (value, mode, format) => {
    if (mode === FORM.FORM_TYPE.datetime) {
      value = value ? DateHelper.toDateValid(value, DATE_FORMAT.StandarDatetime) : null;
    } else if (mode === FORM.FORM_TYPE.date) {
      value = value ? DateHelper.toDateValid(value) : null;
    } else {
      value = value ? DateHelper.toTimeValid(value, format) : null;
    }
    return value;
  },
};
