import { Dimensions, StyleSheet } from 'react-native';
import { isIphoneX } from 'react-native-iphone-x-helper';
import { RFPercentage } from 'react-native-responsive-fontsize';
import { isIPhoneXMax } from 'react-native-status-bar-height';
import { Device } from './device';

const BASE_FONT_SIZE = 2;
function ReduceTextFor18_9(number: number) {
  return Math.ceil((Device.width / Device.height) * 100) === 52 ? number - 0.5 : number;
}

function calculateFontsize(current: number, max: number) {
  return current <= max ? current : max;
  // return current;
}

const { width, height } = Dimensions.get('window');
const DW = 375;
const DH = 812;
const vh = height / DH;
const vw = width / DW;
const sizeWidth = (number: number) => number * (width < height ? vw : vh);
const sizeHeight = (number: number) => number * (width > height ? vw : vh);

export const deviceHeight = Device.height;
export const deviceWidth = Device.width;
export const statusHeight = Device.statusHeight;

export const FontSize = {
  Font6: Device.isAndroid ? 6 : calculateFontsize(RFPercentage(BASE_FONT_SIZE + ReduceTextFor18_9(-0.25)), 6),
  Font8: Device.isAndroid ? 8 : calculateFontsize(RFPercentage(BASE_FONT_SIZE + ReduceTextFor18_9(-0.25)), 8),
  Font9: Device.isAndroid ? 11 : calculateFontsize(RFPercentage(BASE_FONT_SIZE + ReduceTextFor18_9(0)), 11),
  Font10: Device.isAndroid ? 12 : calculateFontsize(RFPercentage(BASE_FONT_SIZE + ReduceTextFor18_9(0.25)), 12),
  Font11: Device.isAndroid ? 13 : calculateFontsize(RFPercentage(BASE_FONT_SIZE + ReduceTextFor18_9(0.25)), 13),
  Font12: Device.isAndroid ? 14 : calculateFontsize(RFPercentage(BASE_FONT_SIZE + ReduceTextFor18_9(0.5)), 14),
  Font13: Device.isAndroid ? 15 : calculateFontsize(RFPercentage(BASE_FONT_SIZE + ReduceTextFor18_9(0.5)), 15),
  Font14: Device.isAndroid ? 16 : calculateFontsize(RFPercentage(BASE_FONT_SIZE + ReduceTextFor18_9(0.6)), 16),
  Font15: Device.isAndroid ? 17 : calculateFontsize(RFPercentage(BASE_FONT_SIZE + ReduceTextFor18_9(0.75)), 17),
  Font16: Device.isAndroid ? 17 : calculateFontsize(RFPercentage(BASE_FONT_SIZE + ReduceTextFor18_9(0.75)), 17),
  Font17: Device.isAndroid ? 18 : calculateFontsize(RFPercentage(BASE_FONT_SIZE + ReduceTextFor18_9(0.8)), 18),
  Font18: Device.isAndroid ? 19 : calculateFontsize(RFPercentage(BASE_FONT_SIZE + ReduceTextFor18_9(1)), 19),
  Font19: Device.isAndroid ? 20 : calculateFontsize(RFPercentage(BASE_FONT_SIZE + ReduceTextFor18_9(1.25)), 20),
  Font20: Device.isAndroid ? 21 : calculateFontsize(RFPercentage(BASE_FONT_SIZE + ReduceTextFor18_9(1.5)), 21),
  Font21: Device.isAndroid ? 22 : calculateFontsize(RFPercentage(BASE_FONT_SIZE + ReduceTextFor18_9(1.5)), 22),
  Font23: Device.isAndroid ? 24 : calculateFontsize(RFPercentage(BASE_FONT_SIZE + ReduceTextFor18_9(1.75)), 24),
  Font24: Device.isAndroid ? 25 : calculateFontsize(RFPercentage(BASE_FONT_SIZE + ReduceTextFor18_9(2)), 25),
  Font26: Device.isAndroid ? 27 : calculateFontsize(RFPercentage(BASE_FONT_SIZE + ReduceTextFor18_9(2.5)), 27),
  Font27: Device.isAndroid ? 28 : calculateFontsize(RFPercentage(BASE_FONT_SIZE + ReduceTextFor18_9(2.6)), 28),
  Font29: Device.isAndroid ? 30 : calculateFontsize(RFPercentage(BASE_FONT_SIZE + ReduceTextFor18_9(2.7)), 30),
  Font30: Device.isAndroid ? 32 : calculateFontsize(RFPercentage(BASE_FONT_SIZE + ReduceTextFor18_9(3)), 32),
  Font34: Device.isAndroid ? 35 : calculateFontsize(RFPercentage(BASE_FONT_SIZE + ReduceTextFor18_9(3.5)), 35),
  Font36: Device.isAndroid ? 36 : calculateFontsize(RFPercentage(BASE_FONT_SIZE + ReduceTextFor18_9(3.6)), 36),
  Font40: Device.isAndroid ? 40 : calculateFontsize(RFPercentage(BASE_FONT_SIZE + ReduceTextFor18_9(4)), 40),
  Font45: Device.isAndroid ? 45 : calculateFontsize(RFPercentage(BASE_FONT_SIZE + ReduceTextFor18_9(4.5)), 45),
};

export const Font = StyleSheet.create({
  Bold_300: {
    fontWeight: '300',
    fontFamily: 'arial',
  },
  Bold_400: {
    fontWeight: '400',
    fontFamily: 'arial',
  },
  Bold_500: {
    fontWeight: '500',
    fontFamily: 'arial',
  },
  Bold_600: {
    fontWeight: '600',
    fontFamily: 'arial',
  },
  Bold_700: {
    fontWeight:  Device.isAndroid? undefined:'700',
    fontFamily: Device.isAndroid?'arialbd':"arial",
  },
  Bold_800: {
    fontWeight: '800',
    fontFamily: 'arial',
  },
  Bold_900: {
    fontWeight: '900',
    fontFamily: 'arial',
  },
});

export const Spacing = {
  gap10: '10px',
  gap20: '20px',
  BottomBar: 50 + (Device.isIos ? (isIphoneX() || isIPhoneXMax() ? 34 : 0) : 0),
  width1: Device.isAndroid ? 1 : sizeWidth(1),
  width2: Device.isAndroid ? 2 : sizeWidth(2),
  width3: Device.isAndroid ? 3 : sizeWidth(3),
  width4: Device.isAndroid ? 4 : sizeWidth(4),
  width5: Device.isAndroid ? 5 : sizeWidth(5),
  width6: Device.isAndroid ? 6 : sizeWidth(6),
  width7: Device.isAndroid ? 7 : sizeWidth(7),
  width8: Device.isAndroid ? 8 : sizeWidth(8),
  width9: Device.isAndroid ? 9 : sizeWidth(9),
  width10: Device.isAndroid ? 10 : sizeWidth(10),
  width11: Device.isAndroid ? 11 : sizeWidth(11),
  width12: Device.isAndroid ? 12 : sizeWidth(12),
  width13: Device.isAndroid ? 13 : sizeWidth(13),
  width14: Device.isAndroid ? 14 : sizeWidth(14),
  width15: Device.isAndroid ? 15 : sizeWidth(15),
  width16: Device.isAndroid ? 16 : sizeWidth(16),
  width17: Device.isAndroid ? 17 : sizeWidth(17),
  width18: Device.isAndroid ? 18 : sizeWidth(18),
  width19: Device.isAndroid ? 19 : sizeWidth(19),
  width20: Device.isAndroid ? 20 : sizeWidth(20),
  width21: Device.isAndroid ? 21 : sizeWidth(21),
  width22: Device.isAndroid ? 22 : sizeWidth(22),
  width23: Device.isAndroid ? 23 : sizeWidth(23),
  width24: Device.isAndroid ? 24 : sizeWidth(24),
  width25: Device.isAndroid ? 25 : sizeWidth(25),
  width26: Device.isAndroid ? 26 : sizeWidth(26),
  width27: Device.isAndroid ? 27 : sizeWidth(27),
  width28: Device.isAndroid ? 28 : sizeWidth(28),
  width29: Device.isAndroid ? 29 : sizeWidth(29),
  width30: Device.isAndroid ? 30 : sizeWidth(30),
  width31: Device.isAndroid ? 31 : sizeWidth(31),
  width32: Device.isAndroid ? 32 : sizeWidth(32),
  width33: Device.isAndroid ? 33 : sizeWidth(33),
  width34: Device.isAndroid ? 34 : sizeWidth(34),
  width35: Device.isAndroid ? 35 : sizeWidth(35),
  width36: Device.isAndroid ? 36 : sizeWidth(36),
  width37: Device.isAndroid ? 37 : sizeWidth(37),
  width38: Device.isAndroid ? 38 : sizeWidth(38),
  width39: Device.isAndroid ? 39 : sizeWidth(39),
  width40: Device.isAndroid ? 40 : sizeWidth(40),
  width41: Device.isAndroid ? 41 : sizeWidth(41),
  width42: Device.isAndroid ? 42 : sizeWidth(42),
  width43: Device.isAndroid ? 43 : sizeWidth(43),
  width44: Device.isAndroid ? 44 : sizeWidth(44),
  width45: Device.isAndroid ? 45 : sizeWidth(45),
  width46: Device.isAndroid ? 46 : sizeWidth(46),
  width47: Device.isAndroid ? 47 : sizeWidth(47),
  width48: Device.isAndroid ? 48 : sizeWidth(48),
  width49: Device.isAndroid ? 49 : sizeWidth(49),
  width50: Device.isAndroid ? 50 : sizeWidth(50),
  width51: Device.isAndroid ? 51 : sizeWidth(51),
  width52: Device.isAndroid ? 52 : sizeWidth(52),
  width53: Device.isAndroid ? 53 : sizeWidth(53),
  width54: Device.isAndroid ? 54 : sizeWidth(54),
  width55: Device.isAndroid ? 55 : sizeWidth(55),
  width56: Device.isAndroid ? 56 : sizeWidth(56),
  width57: Device.isAndroid ? 57 : sizeWidth(57),
  width58: Device.isAndroid ? 58 : sizeWidth(58),
  width59: Device.isAndroid ? 59 : sizeWidth(59),
  width60: Device.isAndroid ? 60 : sizeWidth(60),
  width61: Device.isAndroid ? 61 : sizeWidth(61),
  width62: Device.isAndroid ? 62 : sizeWidth(62),
  width63: Device.isAndroid ? 63 : sizeWidth(63),
  width64: Device.isAndroid ? 64 : sizeWidth(64),
  width65: Device.isAndroid ? 65 : sizeWidth(65),
  width66: Device.isAndroid ? 66 : sizeWidth(66),
  width67: Device.isAndroid ? 67 : sizeWidth(67),
  width68: Device.isAndroid ? 68 : sizeWidth(68),
  width69: Device.isAndroid ? 69 : sizeWidth(69),
  width70: Device.isAndroid ? 70 : sizeWidth(70),
  width71: Device.isAndroid ? 71 : sizeWidth(71),
  width72: Device.isAndroid ? 72 : sizeWidth(72),
  width73: Device.isAndroid ? 73 : sizeWidth(73),
  width74: Device.isAndroid ? 74 : sizeWidth(74),
  width75: Device.isAndroid ? 75 : sizeWidth(75),
  width76: Device.isAndroid ? 76 : sizeWidth(76),
  width77: Device.isAndroid ? 77 : sizeWidth(77),
  width78: Device.isAndroid ? 78 : sizeWidth(78),
  width79: Device.isAndroid ? 79 : sizeWidth(79),
  width80: Device.isAndroid ? 80 : sizeWidth(80),
  width81: Device.isAndroid ? 81 : sizeWidth(81),
  width82: Device.isAndroid ? 82 : sizeWidth(82),
  width83: Device.isAndroid ? 83 : sizeWidth(83),
  width84: Device.isAndroid ? 84 : sizeWidth(84),
  width85: Device.isAndroid ? 85 : sizeWidth(85),
  width86: Device.isAndroid ? 86 : sizeWidth(86),
  width87: Device.isAndroid ? 87 : sizeWidth(87),
  width88: Device.isAndroid ? 88 : sizeWidth(88),
  width89: Device.isAndroid ? 89 : sizeWidth(89),
  width90: Device.isAndroid ? 90 : sizeWidth(90),
  width91: Device.isAndroid ? 91 : sizeWidth(91),
  width92: Device.isAndroid ? 92 : sizeWidth(92),
  width93: Device.isAndroid ? 93 : sizeWidth(93),
  width94: Device.isAndroid ? 94 : sizeWidth(94),
  width95: Device.isAndroid ? 95 : sizeWidth(95),
  width96: Device.isAndroid ? 96 : sizeWidth(96),
  width97: Device.isAndroid ? 97 : sizeWidth(97),
  width98: Device.isAndroid ? 98 : sizeWidth(98),
  width99: Device.isAndroid ? 99 : sizeWidth(99),
  width100: Device.isAndroid ? 100 : sizeWidth(100),
  width101: Device.isAndroid ? 101 : sizeWidth(101),
  width102: Device.isAndroid ? 102 : sizeWidth(102),
  width103: Device.isAndroid ? 103 : sizeWidth(103),
  width104: Device.isAndroid ? 104 : sizeWidth(104),
  width105: Device.isAndroid ? 105 : sizeWidth(105),
  width106: Device.isAndroid ? 106 : sizeWidth(106),
  width107: Device.isAndroid ? 107 : sizeWidth(107),
  width108: Device.isAndroid ? 108 : sizeWidth(108),
  width109: Device.isAndroid ? 109 : sizeWidth(109),
  width110: Device.isAndroid ? 110 : sizeWidth(110),
  width111: Device.isAndroid ? 111 : sizeWidth(111),
  width112: Device.isAndroid ? 112 : sizeWidth(112),
  width113: Device.isAndroid ? 113 : sizeWidth(113),
  width114: Device.isAndroid ? 114 : sizeWidth(114),
  width115: Device.isAndroid ? 115 : sizeWidth(115),
  width116: Device.isAndroid ? 116 : sizeWidth(116),
  width117: Device.isAndroid ? 117 : sizeWidth(117),
  width118: Device.isAndroid ? 118 : sizeWidth(118),
  width119: Device.isAndroid ? 119 : sizeWidth(119),
  width120: Device.isAndroid ? 120 : sizeWidth(120),
  width121: Device.isAndroid ? 121 : sizeWidth(121),
  width122: Device.isAndroid ? 122 : sizeWidth(122),
  width123: Device.isAndroid ? 123 : sizeWidth(123),
  width124: Device.isAndroid ? 124 : sizeWidth(124),
  width125: Device.isAndroid ? 125 : sizeWidth(125),
  width126: Device.isAndroid ? 126 : sizeWidth(126),
  width127: Device.isAndroid ? 127 : sizeWidth(127),
  width128: Device.isAndroid ? 128 : sizeWidth(128),
  width129: Device.isAndroid ? 129 : sizeWidth(129),
  width130: Device.isAndroid ? 130 : sizeWidth(130),
  width131: Device.isAndroid ? 131 : sizeWidth(131),
  width132: Device.isAndroid ? 132 : sizeWidth(132),
  width133: Device.isAndroid ? 133 : sizeWidth(133),
  width134: Device.isAndroid ? 134 : sizeWidth(134),
  width135: Device.isAndroid ? 135 : sizeWidth(135),
  width136: Device.isAndroid ? 136 : sizeWidth(136),
  width137: Device.isAndroid ? 137 : sizeWidth(137),
  width138: Device.isAndroid ? 138 : sizeWidth(138),
  width139: Device.isAndroid ? 139 : sizeWidth(139),
  width140: Device.isAndroid ? 140 : sizeWidth(140),
  width141: Device.isAndroid ? 141 : sizeWidth(141),
  width142: Device.isAndroid ? 142 : sizeWidth(142),
  width143: Device.isAndroid ? 143 : sizeWidth(143),
  width144: Device.isAndroid ? 144 : sizeWidth(144),
  width145: Device.isAndroid ? 145 : sizeWidth(145),
  width146: Device.isAndroid ? 146 : sizeWidth(146),
  width147: Device.isAndroid ? 147 : sizeWidth(147),
  width148: Device.isAndroid ? 148 : sizeWidth(148),
  width149: Device.isAndroid ? 149 : sizeWidth(149),
  width150: Device.isAndroid ? 150 : sizeWidth(150),
  width151: Device.isAndroid ? 151 : sizeWidth(151),
  width152: Device.isAndroid ? 152 : sizeWidth(152),
  width153: Device.isAndroid ? 153 : sizeWidth(153),
  width154: Device.isAndroid ? 154 : sizeWidth(154),
  width155: Device.isAndroid ? 155 : sizeWidth(155),
  width156: Device.isAndroid ? 156 : sizeWidth(156),
  width157: Device.isAndroid ? 157 : sizeWidth(157),
  width158: Device.isAndroid ? 158 : sizeWidth(158),
  width159: Device.isAndroid ? 159 : sizeWidth(159),
  width160: Device.isAndroid ? 160 : sizeWidth(160),
  width161: Device.isAndroid ? 161 : sizeWidth(161),
  width162: Device.isAndroid ? 162 : sizeWidth(162),
  width163: Device.isAndroid ? 163 : sizeWidth(163),
  width164: Device.isAndroid ? 164 : sizeWidth(164),
  width165: Device.isAndroid ? 165 : sizeWidth(165),
  width166: Device.isAndroid ? 166 : sizeWidth(166),
  width167: Device.isAndroid ? 167 : sizeWidth(167),
  width168: Device.isAndroid ? 168 : sizeWidth(168),
  width169: Device.isAndroid ? 169 : sizeWidth(169),
  width170: Device.isAndroid ? 170 : sizeWidth(170),
  width171: Device.isAndroid ? 171 : sizeWidth(171),
  width172: Device.isAndroid ? 172 : sizeWidth(172),
  width173: Device.isAndroid ? 173 : sizeWidth(173),
  width174: Device.isAndroid ? 174 : sizeWidth(174),
  width175: Device.isAndroid ? 175 : sizeWidth(175),
  width176: Device.isAndroid ? 176 : sizeWidth(176),
  width177: Device.isAndroid ? 177 : sizeWidth(177),
  width178: Device.isAndroid ? 178 : sizeWidth(178),
  width179: Device.isAndroid ? 179 : sizeWidth(179),
  width180: Device.isAndroid ? 180 : sizeWidth(180),
  width181: Device.isAndroid ? 181 : sizeWidth(181),
  width182: Device.isAndroid ? 182 : sizeWidth(182),
  width183: Device.isAndroid ? 183 : sizeWidth(183),
  width184: Device.isAndroid ? 184 : sizeWidth(184),
  width185: Device.isAndroid ? 185 : sizeWidth(185),
  width186: Device.isAndroid ? 186 : sizeWidth(186),
  width187: Device.isAndroid ? 187 : sizeWidth(187),
  width188: Device.isAndroid ? 188 : sizeWidth(188),
  width189: Device.isAndroid ? 189 : sizeWidth(189),
  width190: Device.isAndroid ? 190 : sizeWidth(190),
  width191: Device.isAndroid ? 191 : sizeWidth(191),
  width192: Device.isAndroid ? 192 : sizeWidth(192),
  width193: Device.isAndroid ? 193 : sizeWidth(193),
  width194: Device.isAndroid ? 194 : sizeWidth(194),
  width195: Device.isAndroid ? 195 : sizeWidth(195),
  width196: Device.isAndroid ? 196 : sizeWidth(196),
  width197: Device.isAndroid ? 197 : sizeWidth(197),
  width198: Device.isAndroid ? 198 : sizeWidth(198),
  width199: Device.isAndroid ? 199 : sizeWidth(199),
  width200: Device.isAndroid ? 200 : sizeWidth(200),
  width207: Device.isAndroid ? 207 : sizeWidth(207),
  width215: Device.isAndroid ? 215 : sizeWidth(215),
  width220: Device.isAndroid ? 220 : sizeWidth(220),
  width225: Device.isAndroid ? 225 : sizeWidth(225),
  width240: Device.isAndroid ? 240 : sizeWidth(240),
  width243: Device.isAndroid ? 243 : sizeWidth(243),
  width262: Device.isAndroid ? 262 : sizeWidth(262),
  width275: Device.isAndroid ? 275 : sizeWidth(275),
  width302: Device.isAndroid ? 302 : sizeWidth(302),
  width303: Device.isAndroid ? 303 : sizeWidth(303),
  width335: Device.isAndroid ? 335 : sizeWidth(335),
  height1: Device.isAndroid ? 1 : sizeHeight(1),
  height2: Device.isAndroid ? 2 : sizeHeight(2),
  height3: Device.isAndroid ? 3 : sizeHeight(3),
  height4: Device.isAndroid ? 4 : sizeHeight(4),
  height5: Device.isAndroid ? 5 : sizeHeight(5),
  height6: Device.isAndroid ? 6 : sizeHeight(6),
  height7: Device.isAndroid ? 7 : sizeHeight(7),
  height8: Device.isAndroid ? 8 : sizeHeight(8),
  height9: Device.isAndroid ? 9 : sizeHeight(9),
  height10: Device.isAndroid ? 10 : sizeHeight(10),
  height11: Device.isAndroid ? 11 : sizeHeight(11),
  height12: Device.isAndroid ? 12 : sizeHeight(12),
  height13: Device.isAndroid ? 13 : sizeHeight(13),
  height14: Device.isAndroid ? 14 : sizeHeight(14),
  height15: Device.isAndroid ? 15 : sizeHeight(15),
  height16: Device.isAndroid ? 16 : sizeHeight(16),
  height17: Device.isAndroid ? 17 : sizeHeight(17),
  height18: Device.isAndroid ? 18 : sizeHeight(18),
  height19: Device.isAndroid ? 19 : sizeHeight(19),
  height20: Device.isAndroid ? 20 : sizeHeight(20),
  height21: Device.isAndroid ? 21 : sizeHeight(21),
  height22: Device.isAndroid ? 22 : sizeHeight(22),
  height23: Device.isAndroid ? 23 : sizeHeight(23),
  height24: Device.isAndroid ? 24 : sizeHeight(24),
  height25: Device.isAndroid ? 25 : sizeHeight(25),
  height26: Device.isAndroid ? 26 : sizeHeight(26),
  height27: Device.isAndroid ? 27 : sizeHeight(27),
  height28: Device.isAndroid ? 28 : sizeHeight(28),
  height29: Device.isAndroid ? 29 : sizeHeight(29),
  height30: Device.isAndroid ? 30 : sizeHeight(30),
  height31: Device.isAndroid ? 31 : sizeHeight(31),
  height32: Device.isAndroid ? 32 : sizeHeight(32),
  height33: Device.isAndroid ? 33 : sizeHeight(33),
  height34: Device.isAndroid ? 34 : sizeHeight(34),
  height35: Device.isAndroid ? 35 : sizeHeight(35),
  height36: Device.isAndroid ? 36 : sizeHeight(36),
  height37: Device.isAndroid ? 37 : sizeHeight(37),
  height38: Device.isAndroid ? 38 : sizeHeight(38),
  height39: Device.isAndroid ? 39 : sizeHeight(39),
  height40: Device.isAndroid ? 40 : sizeHeight(40),
  height41: Device.isAndroid ? 41 : sizeHeight(41),
  height42: Device.isAndroid ? 42 : sizeHeight(42),
  height43: Device.isAndroid ? 43 : sizeHeight(43),
  height44: Device.isAndroid ? 44 : sizeHeight(44),
  height45: Device.isAndroid ? 45 : sizeHeight(45),
  height46: Device.isAndroid ? 46 : sizeHeight(46),
  height47: Device.isAndroid ? 47 : sizeHeight(47),
  height48: Device.isAndroid ? 48 : sizeHeight(48),
  height49: Device.isAndroid ? 49 : sizeHeight(49),
  height50: Device.isAndroid ? 50 : sizeHeight(50),
  height51: Device.isAndroid ? 51 : sizeHeight(51),
  height52: Device.isAndroid ? 52 : sizeHeight(52),
  height53: Device.isAndroid ? 53 : sizeHeight(53),
  height54: Device.isAndroid ? 54 : sizeHeight(54),
  height55: Device.isAndroid ? 55 : sizeHeight(55),
  height56: Device.isAndroid ? 56 : sizeHeight(56),
  height57: Device.isAndroid ? 57 : sizeHeight(57),
  height58: Device.isAndroid ? 58 : sizeHeight(58),
  height59: Device.isAndroid ? 59 : sizeHeight(59),
  height60: Device.isAndroid ? 60 : sizeHeight(60),
  height61: Device.isAndroid ? 61 : sizeHeight(61),
  height62: Device.isAndroid ? 62 : sizeHeight(62),
  height63: Device.isAndroid ? 63 : sizeHeight(63),
  height64: Device.isAndroid ? 64 : sizeHeight(64),
  height65: Device.isAndroid ? 65 : sizeHeight(65),
  height66: Device.isAndroid ? 66 : sizeHeight(66),
  height67: Device.isAndroid ? 67 : sizeHeight(67),
  height68: Device.isAndroid ? 68 : sizeHeight(68),
  height69: Device.isAndroid ? 69 : sizeHeight(69),
  height70: Device.isAndroid ? 70 : sizeHeight(70),
  height71: Device.isAndroid ? 71 : sizeHeight(71),
  height72: Device.isAndroid ? 72 : sizeHeight(72),
  height73: Device.isAndroid ? 73 : sizeHeight(73),
  height74: Device.isAndroid ? 74 : sizeHeight(74),
  height75: Device.isAndroid ? 75 : sizeHeight(75),
  height76: Device.isAndroid ? 76 : sizeHeight(76),
  height77: Device.isAndroid ? 77 : sizeHeight(77),
  height78: Device.isAndroid ? 78 : sizeHeight(78),
  height79: Device.isAndroid ? 79 : sizeHeight(79),
  height80: Device.isAndroid ? 80 : sizeHeight(80),
  height81: Device.isAndroid ? 81 : sizeHeight(81),
  height82: Device.isAndroid ? 82 : sizeHeight(82),
  height83: Device.isAndroid ? 83 : sizeHeight(83),
  height84: Device.isAndroid ? 84 : sizeHeight(84),
  height85: Device.isAndroid ? 85 : sizeHeight(85),
  height86: Device.isAndroid ? 86 : sizeHeight(86),
  height87: Device.isAndroid ? 87 : sizeHeight(87),
  height88: Device.isAndroid ? 88 : sizeHeight(88),
  height89: Device.isAndroid ? 89 : sizeHeight(89),
  height90: Device.isAndroid ? 90 : sizeHeight(90),
  height91: Device.isAndroid ? 91 : sizeHeight(91),
  height92: Device.isAndroid ? 92 : sizeHeight(92),
  height93: Device.isAndroid ? 93 : sizeHeight(93),
  height94: Device.isAndroid ? 94 : sizeHeight(94),
  height95: Device.isAndroid ? 95 : sizeHeight(95),
  height96: Device.isAndroid ? 96 : sizeHeight(96),
  height97: Device.isAndroid ? 97 : sizeHeight(97),
  height98: Device.isAndroid ? 98 : sizeHeight(98),
  height99: Device.isAndroid ? 99 : sizeHeight(99),
  height100: Device.isAndroid ? 100 : sizeHeight(100),
  height101: Device.isAndroid ? 101 : sizeHeight(101),
  height102: Device.isAndroid ? 102 : sizeHeight(102),
  height103: Device.isAndroid ? 103 : sizeHeight(103),
  height104: Device.isAndroid ? 104 : sizeHeight(104),
  height105: Device.isAndroid ? 105 : sizeHeight(105),
  height106: Device.isAndroid ? 106 : sizeHeight(106),
  height107: Device.isAndroid ? 107 : sizeHeight(107),
  height108: Device.isAndroid ? 108 : sizeHeight(108),
  height109: Device.isAndroid ? 109 : sizeHeight(109),
  height110: Device.isAndroid ? 110 : sizeHeight(110),
  height111: Device.isAndroid ? 111 : sizeHeight(111),
  height112: Device.isAndroid ? 112 : sizeHeight(112),
  height113: Device.isAndroid ? 113 : sizeHeight(113),
  height114: Device.isAndroid ? 114 : sizeHeight(114),
  height115: Device.isAndroid ? 115 : sizeHeight(115),
  height116: Device.isAndroid ? 116 : sizeHeight(116),
  height117: Device.isAndroid ? 117 : sizeHeight(117),
  height118: Device.isAndroid ? 118 : sizeHeight(118),
  height119: Device.isAndroid ? 119 : sizeHeight(119),
  height120: Device.isAndroid ? 120 : sizeHeight(120),
  height121: Device.isAndroid ? 121 : sizeHeight(121),
  height122: Device.isAndroid ? 122 : sizeHeight(122),
  height123: Device.isAndroid ? 123 : sizeHeight(123),
  height124: Device.isAndroid ? 124 : sizeHeight(124),
  height125: Device.isAndroid ? 125 : sizeHeight(125),
  height126: Device.isAndroid ? 126 : sizeHeight(126),
  height127: Device.isAndroid ? 127 : sizeHeight(127),
  height128: Device.isAndroid ? 128 : sizeHeight(128),
  height129: Device.isAndroid ? 129 : sizeHeight(129),
  height130: Device.isAndroid ? 130 : sizeHeight(130),
  height131: Device.isAndroid ? 131 : sizeHeight(131),
  height132: Device.isAndroid ? 132 : sizeHeight(132),
  height133: Device.isAndroid ? 133 : sizeHeight(133),
  height134: Device.isAndroid ? 134 : sizeHeight(134),
  height135: Device.isAndroid ? 135 : sizeHeight(135),
  height136: Device.isAndroid ? 136 : sizeHeight(136),
  height137: Device.isAndroid ? 137 : sizeHeight(137),
  height138: Device.isAndroid ? 138 : sizeHeight(138),
  height139: Device.isAndroid ? 139 : sizeHeight(139),
  height140: Device.isAndroid ? 140 : sizeHeight(140),
  height141: Device.isAndroid ? 141 : sizeHeight(141),
  height142: Device.isAndroid ? 142 : sizeHeight(142),
  height143: Device.isAndroid ? 143 : sizeHeight(143),
  height144: Device.isAndroid ? 144 : sizeHeight(144),
  height145: Device.isAndroid ? 145 : sizeHeight(145),
  height146: Device.isAndroid ? 146 : sizeHeight(146),
  height147: Device.isAndroid ? 147 : sizeHeight(147),
  height148: Device.isAndroid ? 148 : sizeHeight(148),
  height149: Device.isAndroid ? 149 : sizeHeight(149),
  height150: Device.isAndroid ? 150 : sizeHeight(150),
  height151: Device.isAndroid ? 151 : sizeHeight(151),
  height152: Device.isAndroid ? 152 : sizeHeight(152),
  height153: Device.isAndroid ? 153 : sizeHeight(153),
  height154: Device.isAndroid ? 154 : sizeHeight(154),
  height155: Device.isAndroid ? 155 : sizeHeight(155),
  height156: Device.isAndroid ? 156 : sizeHeight(156),
  height157: Device.isAndroid ? 157 : sizeHeight(157),
  height158: Device.isAndroid ? 158 : sizeHeight(158),
  height159: Device.isAndroid ? 159 : sizeHeight(159),
  height160: Device.isAndroid ? 160 : sizeHeight(160),
  height161: Device.isAndroid ? 161 : sizeHeight(161),
  height162: Device.isAndroid ? 162 : sizeHeight(162),
  height163: Device.isAndroid ? 163 : sizeHeight(163),
  height164: Device.isAndroid ? 164 : sizeHeight(164),
  height165: Device.isAndroid ? 165 : sizeHeight(165),
  height166: Device.isAndroid ? 166 : sizeHeight(166),
  height167: Device.isAndroid ? 167 : sizeHeight(167),
  height168: Device.isAndroid ? 168 : sizeHeight(168),
  height169: Device.isAndroid ? 169 : sizeHeight(169),
  height170: Device.isAndroid ? 170 : sizeHeight(170),
  height171: Device.isAndroid ? 171 : sizeHeight(171),
  height172: Device.isAndroid ? 172 : sizeHeight(172),
  height173: Device.isAndroid ? 173 : sizeHeight(173),
  height174: Device.isAndroid ? 174 : sizeHeight(174),
  height175: Device.isAndroid ? 175 : sizeHeight(175),
  height176: Device.isAndroid ? 176 : sizeHeight(176),
  height177: Device.isAndroid ? 177 : sizeHeight(177),
  height178: Device.isAndroid ? 178 : sizeHeight(178),
  height179: Device.isAndroid ? 179 : sizeHeight(179),
  height180: Device.isAndroid ? 180 : sizeHeight(180),
  height181: Device.isAndroid ? 181 : sizeHeight(181),
  height182: Device.isAndroid ? 182 : sizeHeight(182),
  height183: Device.isAndroid ? 183 : sizeHeight(183),
  height184: Device.isAndroid ? 184 : sizeHeight(184),
  height185: Device.isAndroid ? 185 : sizeHeight(185),
  height186: Device.isAndroid ? 186 : sizeHeight(186),
  height187: Device.isAndroid ? 187 : sizeHeight(187),
  height188: Device.isAndroid ? 188 : sizeHeight(188),
  height189: Device.isAndroid ? 189 : sizeHeight(189),
  height190: Device.isAndroid ? 190 : sizeHeight(190),
  height191: Device.isAndroid ? 191 : sizeHeight(191),
  height192: Device.isAndroid ? 192 : sizeHeight(192),
  height193: Device.isAndroid ? 193 : sizeHeight(193),
  height194: Device.isAndroid ? 194 : sizeHeight(194),
  height195: Device.isAndroid ? 195 : sizeHeight(195),
  height196: Device.isAndroid ? 196 : sizeHeight(196),
  height197: Device.isAndroid ? 197 : sizeHeight(197),
  height198: Device.isAndroid ? 198 : sizeHeight(198),
  height199: Device.isAndroid ? 199 : sizeHeight(199),
  height200: Device.isAndroid ? 200 : sizeHeight(200),
  height207: Device.isAndroid ? 207 : sizeHeight(207),
  height215: Device.isAndroid ? 215 : sizeHeight(215),
  height220: Device.isAndroid ? 220 : sizeHeight(220),
  height225: Device.isAndroid ? 225 : sizeHeight(225),
  height240: Device.isAndroid ? 240 : sizeHeight(240),
  height250: Device.isAndroid ? 250 : sizeHeight(250),
  height243: Device.isAndroid ? 243 : sizeHeight(243),
  height262: Device.isAndroid ? 262 : sizeHeight(262),
  height275: Device.isAndroid ? 275 : sizeHeight(275),
  height302: Device.isAndroid ? 302 : sizeHeight(302),
  height303: Device.isAndroid ? 303 : sizeHeight(303),
  height308: Device.isAndroid ? 308 : sizeHeight(308),
  height335: Device.isAndroid ? 335 : sizeHeight(335),
  height375: Device.isAndroid ? 375 : sizeHeight(375),
};
