import { FORM } from '@constant/model/RegisterForm';
import { useRoute } from '@react-navigation/native';
import { notificationPermission } from '@redux/app/selectors';
import { getRFIDFormRequest } from '@redux/form/getRFIDFormSlice';
import { getRFIDFormCreator } from '@redux/form/selectors';
import { submitRFIDRequest } from '@redux/rfid/submitRFIDSlice';
import { acceptUserCdmRequest } from '@redux/user/acceptUserCdmSlice';
import { goBack, navigate, popToTop } from '@routes/navigationUtils';
import { Screens } from '@routes/route';
import { getPanelistId } from '@utils';
import { checkFormValidateExpression, getInitialFormValue, getYupStructure } from '@utils/form';
import { useLocation } from '@utils/hooks';
import { fixFormValues, FormTransform, handleShowError, mappingStep } from '@utils/transform/form';
import { useFormik } from 'formik';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { lazy } from 'yup';
import * as Yup from 'yup';
import {hideLoading, showLoading} from "@redux/global/globalSlice";

export const useCDMForm = navigation => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const params = useRoute().params;
  const paramsData = params?.data;
  const step = params.step ?? 0;
  const stepData = paramsData[step];

  const isLastStep = step === paramsData.length - 1;

  const [data, setData] = useState(null);
  // const getProfileData = useSelector(getRFIDFormCreator)?.data;
  const scrollViewRef = useRef(null);
  const subFormRef = useRef();

  const getRFIDFormData = useSelector(getRFIDFormCreator)?.data;

  const [layoutInfo, setLayoutInfo] = useState({});
  const [userLocation, setUserLocation] = useState({});
  const [messageInfo, setMessageInfo] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const getRFIDFormApi = () => {
    const payload = {
      ...stepData,
      // category: replaceAll(paramsData?.category, '-', '_'),
      panelistId: getPanelistId(),
    };
    // dispatch(getRFIDFormRequest(payload));
    dispatch(showLoading());
    dispatch(
        getRFIDFormRequest(
            payload,
            res => {
              dispatch(hideLoading());
            },
            err => {
              dispatch(hideLoading());
            },
        ),
    );
  };

  const formik = useFormik({
    initialValues: {
      ...getInitialFormValue(data, {}),
      posting_location_id: null,
    },
    // validationSchema: Yup.object().shape(getYupStructure(rawData, t)),
    validationSchema: () =>
      lazy(values => {
        // console.log('lazy>>>values>>>', values);
        const ys = getYupStructure(data, t, 'attributes', values);
        console.log('getYupStructure>>>', ys);
        return Yup.object().shape(ys);
      }),
    initialErrors: false,
    enableReinitialize: true,
    onSubmit: async (values, { setErrors, resetForm }) => {
      console.log('onSubmit>>>', values, subFormRef);
      // updateUserApi(values, isLastStep);
      // onSavePress();
      const rs = checkFormValidateExpression(data?.attributes, values, setErrors);
      if (subFormRef?.current != null) {
        const subForm =
          data?.attributes?.find(x => x.internalId === FORM.FORM_ELEMENT_NAME.dispatch_point)?.subForms ?? [];
        console.log('validateForm>>>before>>>', subFormRef?.current);
        const errSubForm = await FormTransform.validateForm(subFormRef?.current, subForm, true);
        console.log('errSubForm>>>', errSubForm);
        if (!_.isEmpty(errSubForm)) {
          return;
        }
      }
      if (!_.isEmpty(rs)) {
        return;
      }
      submitRFIDApi(values, setErrors);
    },
  });

  const scrollToY = y => {
    // scrollViewRef.current.scroll
    scrollViewRef.current?.scrollTo({
      x: 0,
      y: y,
      animated: true,
    });
  };

  const submitRFIDApi = async (values, setErrors) => {
    /*
          payload_info
           */
    // const typeRFID = replaceAll(paramsData.type, 'rfid_', '');
    const payload = {
      ...stepData,
      ...values,
      // category: paramsData.type,
    };

    // delete payload.type
    const payloadValue = await fixFormValues(payload, values);
    console.log('updateProfileApi>>>', payloadValue);
    // return
    dispatch(
      acceptUserCdmRequest(
        payloadValue,
        rs => {
          if (isLastStep) {
            navigate(Screens.SUCCESSFUL_SCREEN, {
              data: rs?.message,
              header: {
                title: data?.title,
                backClick: () => {
                  popToTop();
                },
              },
            });
          } else {
            navigate(Screens.CDM_FORM_SCREEN, { data: paramsData, step: step + 1 });
          }
        },
        err => {
          handleShowError(err, setErrors);
        },
      ),
    );
  };

  useEffect(() => {
    getRFIDFormApi();
  }, []);

  useEffect(() => {
    const x = _.cloneDeep(getRFIDFormData);
    const rd = mappingStep(!_.isEmpty(x) ? x[0] : null);
    setData(rd);
    console.log('getReportBundleFormData>>>', rd);
  }, [getRFIDFormData]);

  // console.log('useRFID>>>', data, getRFIDFormData, paramsData);

  return {
    formik,
    data,
    scrollToY,
    scrollViewRef,
    layoutInfo,
    setLayoutInfo,
    userLocation,
    subFormRef,
    messageInfo,
    isSuccess,
    isLastStep
  };
};
