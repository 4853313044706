import { Constant } from '@constant';
import { getOverviewD2DRequest } from '@redux/survey/getOverviewD2DSlice';
import {
  getOverviewD2DCreator,
  getOverviewD2DSelector,
  getOverviewD2DError,
  getOverviewD2DErrorMsg,
  getOverviewD2DLoading,
} from '@redux/survey/selectors';
import { navigate } from '@routes/navigationUtils';
import { Screens } from '@routes/route';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {hideLoading} from "@redux/global/globalSlice";

export const useD2DOverview = navigation => {
  const dispatch = useDispatch();
  const getOverviewD2DData = useSelector(getOverviewD2DCreator)?.data;

  const getOverviewD2DApi = () => {
    /*
      params:
      {
          "spectos-apikey": "{{spectos-apikey}}",
          "Token": "fbbfc346de1ba3c5ab642b3496aef98974cfcd6d3be93b44c7b7eaa27354b675"
      }
      body:
      {}
       */
    const payload = {
    };
    dispatch(getOverviewD2DRequest(payload));
  };

  const onItemPress = item => {
    // navigate(Screens.)
    switch (item.key) {
      case Constant.d2d.overdue_items:
        navigate(Screens.D2D_OVER_DUE, { data: item });
        break;
      case Constant.d2d.due_items:
        navigate(Screens.D2D_OVER_DUE, { data: item });
        break;
      case Constant.d2d.daily_items:
        navigate(Screens.D2D_DAILY_ITEM, { data: item });
        break;
      case Constant.d2d.more_than_once:
        navigate(Screens.D2D_OVER_DUE, { data: item });
        break;
    }
  };

  useEffect(() => {
    getOverviewD2DApi();
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(hideLoading())
    }, 3000)
  }, [])

  return { getOverviewD2DData, onItemPress };
};
