import { searchForAddressFailed } from '@redux/address/searchForAddressSlice';
import { getListAddressSaga } from '@redux/bundle/getListAddressSaga';

export const Constant = {
  ERROR_NO_RESPONSE: 'ERROR_NO_RESPONSE',
  reducer: {
    auth: 'auth',
    getForm: 'getForm',
    forceUpdate: 'forceUpdate',
    activeUser: 'activeUser',
    updateUser: 'updateUser',
    getUser: 'getUser',
    validateUser: 'validateUser',
    searchForPostCode: 'searchForPostCode',
    searchForAddress: 'searchForAddress',
    getListStatus: 'getListStatus',
    updateProfile: 'updateProfile',
    getProfile: 'getProfile',
    getListPostingLocation: 'getListPostingLocation',
    getListAddress: 'getListAddress',
    saveFeedbackCDS: 'saveFeedbackCDS',
    getListTicket: 'getListTicket',
    getQRForm: 'getQRForm',
    getBonusPointList: 'getBonusPointList',
    getNotificationModal: 'getNotificationModal',
    getDashboard: 'getDashboard',
    getSettings: 'getSettings',
    getReportModal: 'app/getReportModalRequest',
    downloadReimbursement: 'downloadReimbursement',
    getICodeForm: 'getICodeForm',
    getReportBundleForm: 'getReportBundleForm',
    getReportPackageForm: 'getReportPackageForm',
    getRFIDForm: 'getRFIDForm'
  },
  MAIN_HEIGHT: 48,
  START_PAGE: 0,
  SIZE_PAGE: 20,
  menu: {
    report_holidays: 'report_time_off',
    list_tickets: 'list_tickets',
    create_ticket: 'create_enquiry',
    list_open_ticket: 'number_of_open_enquiries',
    unsubscribe: 'unsubscribe',
    report_test_items: 'report_test_items',
    posting_package: 'posting_package',
    report_bundle_dispatch: 'report_bundle_dispatch',
    reward: 'reward',
    //TODO: should update key later
    transponder: 'confirm_transponder_return',
    reimbursement: 'reimbursement',

  },
  dashboard: {
    survey_cds: 'survey_cds',
    survey_d2d: 'survey_d2d',
    recent_tasks: 'recent_tasks',
    notification: 'notification',
    timeline_bundle: 'bundle_timeable',
    timeline_package: 'package_timetable',
    invitations: 'invitations',
  },
  d2d: {
    overdue_items: 'overdue_items',
    due_items: 'due_items',
    daily_items: 'daily_items',
    more_than_once: 'more_than_once',
  },
  rfid_category:{
    transponder: 'not-returned-transponder'
  },
  notification_category:{
    d2d:{
      overdue_items: 'd2d_overdue_items',
      due_items: 'd2d_due_items',
      daily_items: 'd2d_daily_items',
      more_than_once: 'd2d_more_than_once',
    }
  },
  PHONE_CODE: '+44',
};

export const DASHBOARD_ITEM_TYPE = {
  list: 'list',
  item: 'item',
  date: 'date',
};

export const TICKET_STATUS = {
  all: 'select-all',
  open: 'open',
};

export const PERMISSION_TYPE = {
  CHECK: 'CHECK',
  REQUEST: 'REQUEST',
};

export const QR_SCANNER_TYPE = {
  FROM_BOTTOM_TAB: 'FROM_BOTTOM_TAB',
  FROM_SIGN_UP_WITH_NAME: 'FROM_SIGN_UP_WITH_NAME',
  REPORT_TEST_ITEMS: 'REPORT_TEST_ITEMS',
  POSTING_PACKAGE: 'POSTING_PACKAGE',
  REPORT_BUNDLE_DISPATCH: 'REPORT_BUNDLE_DISPATCH',
};

export const REPORT_TYPE = {
  icodes: 'icodes',
  packages: 'packages',
  bundles: 'bundles',
};

export const PERMISSION_MODEL = {
  CAMERA: 'CAMERA',
  LOCATION: 'LOCATION',
  NOTIFICATION: 'NOTIFICATION'
};

export const ADDRESS_TYPE = {
  normal: 'normal',
  bundle: 'bundle',
  nearest: 'nearest',
};

export const SURVEY_STATUS = {
  Today: 'today',
  Answered: 'answered',
  Awaiting_data: 'awaiting_data',
  Unsure_date: 'unsure_date',
  Overdue: 'overdue',
  UnAnswered: 'unanswered',
};

// export const FEEDBACK_TYPE = {
//   unsure_date: 'unsure_date',
//   date: 'date',
// };

export const FEEDBACK = {
  type: {
    unsure_date: 'unsure_date',
    date: 'date',
  },
  status: {
    incomplete: 'incomplete',
    complete: 'complete',
  },
};

export const UPLOAD_FILE = {
  folderUpload: {
    ticket: 'app/ticket',
    bundle: 'app/bundle',
    cds: 'app/survey/cds',
    d2d: 'app/survey/d2d',
  },
};

export const NOTIFICATION = {
  foregroundDuration: 5000,
  category: {
    report_action: 'report_action',
  },
  action: {
    fill_report: 'fill_report',
    not_send_today: 'not_send_today',
  },
};

export const POSTING_LOCATION = {
  //meters
  DISTANCE_TO_RECALL: 100,
  MARKER_AMOUNT: 100,
};

export const ACCOUNT = {
  statusCode: {
    inactive_pending: 'inactive_pending',
  },
};

export const TIME_TABLE = {
  BUNDLE: 'bundle_timeable',
  PACKAGE: 'package_timetable',
};
export const ReportTypeSetting = {
  reportBundle: 'reportBundle',
  reportPackage: 'reportPackage',
  reportIcode: 'reportIcode',
};

export const ReportTypeExtra = {
  outreach: 'outreach',
};

export const DashboardStatus = {
  check_status: 'check_status',
  check_important_notification: 'check_important_notification'
}
