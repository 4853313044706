import { createSlice } from '@reduxjs/toolkit';

export interface IgetCreateTicketFormState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IgetCreateTicketFormState = {
  loading: false,
  payload: null,
  error: null,
};

const getCreateTicketFormSlice = createSlice({
  name: 'getCreateTicketForm',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getCreateTicketFormRequest(state, action) {
      state.loading = true;
      // @ts-ignore
      state.payload = null;
      state.error = null;
    },
    getCreateTicketFormSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    getCreateTicketFormFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getCreateTicketFormRequest, getCreateTicketFormSuccess, getCreateTicketFormFailed } = getCreateTicketFormSlice.actions;

export default getCreateTicketFormSlice.reducer;
