import { palette } from '@theme/colors';
import React from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';

export const AppComponentLoading = props => {
  let { color = palette.LOADING_COLOR, mini = false, borderRadius = 0, style } = props;
  return (
    <View style={[styles.container, { borderRadius: borderRadius }, style]}>
      <ActivityIndicator size="large" color={mini ? palette.LOADING_COLOR : color} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    // width: '100%',
    // height: '100%',
    // zIndex: 99,
  },
});
