import { Constant } from '@constant';
import { Config } from '@constant/config';
import { getLogoutSuccess } from '@redux/auth/authSlice';
import { store } from '@redux/store';
import { createSlice, createAction, createReducer } from '@reduxjs/toolkit';
import axios from 'axios';
import _ from 'lodash';
import { act } from 'react-test-renderer';

// @ts-ignore

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const isIgnore = (typeAction, igores) => {
  if (_.isEmpty(igores)) {
    return false;
  }
  let rs = false;
  console.log('isIgnore>>>', typeAction, igores);
  for (const ignore of igores) {
    // console.log('ignore>>>', ignore, typeAction.startsWith(ignore));

    if (typeAction.startsWith(ignore)) {
      rs = true;
      break;
    }
  }
  return rs;
};

const isRequestAction = action => {
  // const ignore = isIgnore(action.type, Config.Ignore.GlobalLoading);
  // // console.log('isRequestAction>>>', ignore, action.type);
  // if (ignore) {
  //   return false;
  // }
  return action.type.endsWith('Request');
};

const isSuccessAction = action => {
  return action.type.endsWith('Success');
};

const isFailedAction = action => {
  // const ignore = isIgnore(action.type, Config.Ignore.GlobalError);
  // if (ignore) {
  //   return false;
  // }
  return action.type.endsWith('Failed');
};

const initialState = {
  loading: false,
  error: null,
};

export const showLoading = createAction('global/showLoading');
export const hideLoading = createAction('global/hideLoading');

export const reducer = createReducer(initialState, builder => {
  builder
    .addCase(showLoading, state => {
      state.loading = true;
    })
    .addCase(hideLoading, state => {
      state.loading = false;
    });

  builder.addMatcher(isRequestAction, (state, action) => {
    state.error = null;
    const ignore = isIgnore(action.type, Config.Ignore.GlobalLoading);
    if (!ignore) {
      state.loading = true;
    }
  });

  builder.addMatcher(isSuccessAction, (state, action) => {
    state.error = null;
    const ignore = isIgnore(action.type, Config.Ignore.GlobalLoading);
    if (!ignore) {
      state.loading = false;
    }
  });
  // @ts-ignore
  builder.addMatcher(isFailedAction, (state, action) => {
    const ignore = isIgnore(action.type, Config.Ignore.GlobalError);
    if (!ignore) {
      state.error = action?.payload;
    }

    // if (axios.isAxiosError(action?.payload)) {
    //   if (action?.payload?.response?.status === 403) {
    //     // dispatch(getLogoutSuccess({}))
    //     store.dispatch(getLogoutSuccess({}));
    //     // return
    //   }
    // }
    state.loading = false;
  });
});
