import { StyleSheet } from 'react-native'
import theme from "@theme";

export const QuizDetailStyle = StyleSheet.create({
    formItemView: {
        // marginBottom: theme.spacing.md,
        // marginBottom: theme.spacing.lg,
        paddingTop: 10,
    },
})
