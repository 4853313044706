import { CommonImages, CrossIcon, ImageIcon, LogoIcon, MessageCheckIcon } from '@assets';
import { AppInput, AppKAView, AppSafeAreaView, AppText } from '@components';
import { AppNoteStyle } from '@screens/main/quiz/QuizFailed/components/AppNote.style';
import { AppHtmlView, AppNoteView } from '@screens/main/quiz/QuizFailed/components/AppNote.view';
import { UnsubscribeStyle } from '@screens/main/unsub/Unsubscribe/Unsubscribe.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { Font, FontSize } from '@theme/size';
import { DATE_FORMAT, formatDate } from '@utils/common/MomentUtils';
import { AvatarUtil } from '@utils/common/avatar';
import { FileTransform } from '@utils/transform/file';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, ScrollView, FlatList, TouchableOpacity, Image, StyleSheet } from 'react-native';
import { backgroundColor } from 'react-native-calendars/src/style';
import { Avatar } from 'react-native-elements';
import { useComment } from './Comment.hook';
import { CommentStyle } from './Comment.style';
import {AppImage} from "@components/AppImage";
import {OverDueStyle as styles} from "@screens/main/D2D/overDue/OverDue.style";

export const CommentView = React.memo(props => {
  const { t } = useTranslation();
  const {
      attachments,
      s3config,
      uploadFileName,
      folderUpload
  } = useComment(props);
  const { data } = props;
  const isInternal = data?.author?.from === 'internal';
  const avt = AvatarUtil.getNameInitials(data?.author?.name);
  // console.log('CommentView>>>',)
  const getDateField = createdAt => {
    console.log('getDateField>>>', createdAt, moment(createdAt).isValid());
    if (moment(createdAt).isValid()) {
      return (
        <AppText style={[CommentStyle.dateText]}>
          {formatDate(data?.createdAt, 'ddd, DD MMM')} at {formatDate(data?.createdAt, 'HH:mm')}
        </AppText>
      );
    }
    return <AppText style={[CommentStyle.dateText]}>{createdAt}</AppText>;
  };
  const renderExternal = () => {
    return (
      <View style={[{ marginLeft: 2 * theme.spacing.lg, marginRight: theme.spacing.md, marginTop: theme.spacing.md }]}>
        <View
          style={[
            globalStyle.directionRow,
            {
              justifyContent: 'flex-end',
              alignItems: 'center',
            },
          ]}>
          <AppText style={[CommentStyle.avatarText]}>{data?.author?.name}</AppText>
          <Avatar
            containerStyle={{ backgroundColor: '#DFDFDF', marginLeft: theme.spacing.sm }}
            size="small"
            rounded
            title={avt}
            onPress={() => console.log('Works!')}
            activeOpacity={0.7}
          />
        </View>
        <View
          style={[
            globalStyle.directionRow,
            {
              justifyContent: 'flex-end',
              alignItems: 'center',
              paddingVertical: theme.spacing.sm,
            },
          ]}>
          {getDateField(data?.createdAt)}
        </View>

        <View style={[isInternal ? CommentStyle.viewInternal : CommentStyle.viewExternal]}>
          <AppHtmlView text={data?.content} style={CommentStyle.commentText} />
          {/*<RenderHtml source={{ html: data?.content }} baseStyle={CommentStyle.commentText} />*/}
          {/*<AppText>{data?.content}</AppText>*/}
          {/*<HTMLView*/}
          {/*  // source={{ html: data?.content }}*/}
          {/*  value={data?.content}*/}
          {/*  stylesheet={StyleSheet.create({*/}
          {/*    p: {*/}
          {/*      color: '#333333',*/}
          {/*      fontSize: FontSize.Font16,*/}
          {/*      fontWeight: '400',*/}
          {/*      // marginVertical: theme.spacing.sm*/}
          {/*    },*/}
          {/*  })}*/}
          {/*/>*/}
        </View>
        <View style={CommentStyle.imageWrapper}>
          <FlatList
            horizontal={false}
            numColumns={3}
            data={attachments}
            keyExtractor={item => item.id || item}
            renderItem={({ item, index }) => {
              return (
                <View
                  style={[
                    CommentStyle.imageBox,
                    (index + 1) % 3 === 0 ? CommentStyle.outermostItem : {},
                    CommentStyle.imageWrap,
                  ]}>
                  {/*<Image source={{ uri: item.link }} resizeMode="cover" style={CommentStyle.image} />*/}
                  {/*<FastImage*/}
                  {/*  // style={{ width: 200, height: 200 }}*/}
                  {/*  style={CommentStyle.image}*/}
                  {/*  source={item?.uri ? { uri: item.uri } : FileTransform.getImageFdbSource(item.link, false)}*/}
                  {/*  resizeMode={'cover'}*/}
                  {/*/>*/}
                    <AppImage
                        // style={{ width: 200, height: 200 }}
                        style={[CommentStyle.image]}
                        // source={item?.uri ? { uri: item.uri } : FileTransform.getImageFdbSource(item.link, false)}
                        source={item?.uri ? { uri: item.uri } : FileTransform.getImageFdbSourceWithConfig(item.link, s3config)}
                        resizeMode={'cover'}
                        defaultSource={CommonImages.ImageDefault}
                        hideDefaultImage={true}
                    />
                </View>
              );
            }}
          />
        </View>
      </View>
    );
  };

  const renderInternal = () => {
    return (
      <View style={[{ marginLeft: theme.spacing.md, marginRight: theme.spacing.md, marginTop: theme.spacing.md }]}>
        <AppText style={[CommentStyle.avatarText]}>{data?.author?.name}</AppText>
        {/*<AppText*/}
        {/*  style={[*/}
        {/*    CommentStyle.dateText,*/}
        {/*    {*/}
        {/*      paddingVertical: theme.spacing.sm,*/}
        {/*    },*/}
        {/*  ]}>*/}
        {/*  {formatDate(data?.createdAt, 'ddd, DD MMM')} at {formatDate(data?.createdAt, 'HH:mm')}*/}
        {/*</AppText>*/}
        {getDateField(data?.createdAt)}

        <View style={[isInternal ? CommentStyle.viewInternal : CommentStyle.viewExternal]}>
          {/*<RenderHtml source={{ html: data?.content }} baseStyle={CommentStyle.commentText} />*/}
          <AppHtmlView text={data?.content} style={CommentStyle.commentText} />
        </View>
        <View style={CommentStyle.imageWrapper}>
          <FlatList
            horizontal={false}
            numColumns={3}
            data={attachments}
            keyExtractor={item => item.id || item}
            renderItem={({ item, index }) => {
              return (
                <View
                  style={[
                    CommentStyle.imageBox,
                    (index + 1) % 3 === 0 ? CommentStyle.outermostItem : {},
                    CommentStyle.imageWrap,
                  ]}>
                  {/*<Image source={{ uri: item.link }} resizeMode="cover" style={CommentStyle.image} />*/}
                  {/*<FastImage*/}
                  {/*  // style={{ width: 200, height: 200 }}*/}
                  {/*  style={CommentStyle.image}*/}
                  {/*  source={item?.uri ? { uri: item.uri } : FileTransform.getImageFdbSource(item.link, false)}*/}
                  {/*  resizeMode={'cover'}*/}
                  {/*/>*/}
                    <AppImage
                        // style={{ width: 200, height: 200 }}
                        style={[CommentStyle.image]}
                        // source={item?.uri ? { uri: item.uri } : FileTransform.getImageFdbSource(item.link, false)}
                        source={item?.uri ? { uri: item.uri } : FileTransform.getImageFdbSourceWithConfig(item.link, s3config)}
                        resizeMode={'cover'}
                        defaultSource={CommonImages.ImageDefault}
                        hideDefaultImage={true}
                    />
                </View>
              );
            }}
          />
        </View>
      </View>
    );
  };

  return isInternal ? renderInternal() : renderExternal();
  // return renderExternal();
});

CommentView.propTypes = {
  data: PropTypes.object,
};

CommentView.defaultProps = {
  data: {},
};
