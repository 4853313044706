import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppKAView, AppSafeAreaView, AppText } from '@components';
import { ReportTypeExtra } from '@constant';
import { AppNoteView } from '@screens/main/quiz/QuizFailed/components/AppNote.view';
import Colors from '@screens/main/report/ListReport/Colors';
import { ListReportStyle as styles } from '@screens/main/report/ListReport/ListReport.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font, FontSize } from '@theme/size';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, TouchableOpacity, FlatList, RefreshControl } from 'react-native';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { useEmptyingTime } from './EmptyingTime.hook';
import { EmptyingTimeStyle } from './EmptyingTime.style';

const TypeExtraView = ({ type_extra }) => {
  const { t } = useTranslation();

  if (type_extra === ReportTypeExtra.outreach) {
    return (
      <AppNoteView
        titleStyle={{
          fontSize: FontSize.Font15,
          paddingVertical: theme.spacing.sm,
          color: '#999999',
        }}
        title={t('REPORT_TYPE_EXTRA_WARNING_TEXT')}
      />
    );
  }
  return <></>;
};

export const EmptyingTimeView = props => {
  const { t } = useTranslation();
  const { data } = useEmptyingTime(props);
  console.log('EmptyingTimeView>>>', props);

  const renderHeaderView = () => {
    return (
      <View style={styles.historyRowContainer}>
        <View style={[styles.startDateContainer, { backgroundColor: Colors.grayColor5 }]}>
          <AppText style={[styles.textDate, { fontWeight: 'bold' }]}>{t('EMPTYING_TIME')}</AppText>
        </View>
        {/*<View style={[styles.endDateContainer, { backgroundColor: Colors.grayColor5 }]}>*/}
        {/*  <AppText style={[styles.textDate, { fontWeight: 'bold' }]}>{t('END_DATE')}</AppText>*/}
        {/*</View>*/}
        {/*<View style={[styles.deleteContainer, { backgroundColor: Colors.grayColor5 }]} />*/}
      </View>
    );
  };

  const renderItemRow = (item, index) => {
    return (
      <View style={[EmptyingTimeStyle.historyRowContainer, { marginBottom: index === props.value.length - 1 ? 0 : 2 }]}>
        <View style={[EmptyingTimeStyle.startDateContainer, { backgroundColor: '#F5F5F5' }]}>
          <AppText style={styles.textDate}>{item.day}</AppText>
        </View>
        <View style={[EmptyingTimeStyle.endDateContainer, { backgroundColor: '#F5F5F5' }]}>
          <AppText style={styles.textDate}>{item.time}</AppText>
        </View>
      </View>
    );
  };
  if (_.isEmpty(data)) {
    return <></>;
  }
  return (
    <>
      <TypeExtraView type_extra={props.type_extra} />
      <View
        style={[
          {
            width: '100%',
            flex: 1,
            borderRadius: 4,
            borderWidth: 1,
            borderColor: '#D5D5D5',
            backgroundColor: palette.WHITE,
            padding: 2,
            // marginBottom: 15,
            marginTop: 8,
          },
          ...props.style,
          { marginBottom: 16 },
        ]}>
        {renderHeaderView()}
        <FlatList
          showsVerticalScrollIndicator={false}
          data={data}
          keyExtractor={item => item.id}
          renderItem={({ item, index }) => renderItemRow(item, index)}
        />
      </View>
    </>
  );
};

EmptyingTimeView.propTypes = {
  value: PropTypes.array,
  style: PropTypes.any,
  selectedDate: PropTypes.any,
  type_extra: PropTypes.string,
};

EmptyingTimeView.defaultProps = {
  type_extra: '',
};
