import {EyeActiveIcon, EyeInactiveIcon, QRIcon} from '@assets';
import { AppText } from '@components';
import { Constant } from '@constant';
import { AppLoadingMore } from '@screens/main/SearchPostcode';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { FontSize } from '@theme/size';
import React, { useMemo, useState } from 'react';
import { View, TextInput, StyleSheet, Keyboard, TouchableOpacity, Platform } from 'react-native';
import theme, {globalStyle} from "@theme";

export const AppScan = React.memo(props => {
  const {
    placeholder,
    multiline,
    keyboardType,
    onChangeText,
    style,
    maxLength = null,
    error = '',
    handleBlur,
    boxStyle,
    PreIcon,
    PostIcon,
    returnKeyType,
    value,
    placeholderTextColor = '#999999',
    label,
    isRequired,
    inputStyle,
    isPassword,
    onPostIconPress,
    isBoldTitle,
    inputRef,
    disabled,
    // multipleHeight = 246,
    hint = '',
    loading = false,
    onLayout = null,
    autoCapitalize = 'none',
      scanId = null,
    onQRPress,
  } = props;

  const [secureTextEntry, setSecureTextEntry] = useState(isPassword);
  const [height, setHeight] = useState(Constant.MAIN_HEIGHT * 3);

  const multipleContainerStyles = useMemo(
    () => ({
      ...Platform.select({
        ios: {
          height: 'auto',
          minHeight: Constant.MAIN_HEIGHT,
        },
        android: {
          minHeight: height,
        },
        web:{
          paddingTop: 10,
        },
        default: {
          height: 'auto',
          minHeight: Constant.MAIN_HEIGHT * 1.75,
        },
      }),
      paddingVertical: Device.isIos ? 10 : 0,
      paddingRight: 10,
    }),
    [height],
  );

  return (
    <View
      style={[styles.container, style]}
      onLayout={e => {
        if (onLayout) {
          onLayout(e);
        }
      }}>
      {!!label && (
        <AppText style={[styles.label, isBoldTitle ? { fontWeight: '700' } : {}]}>
          {isRequired && <AppText style={styles.isRequired}>* </AppText>}
          {label}
        </AppText>
      )}
      <View style={globalStyle.directionRow}>
        <View
            style={[
              styles.boxInput,
              boxStyle,
              disabled ? { opacity: disabled ? 0.5 : 1 } : {},
              multiline ? multipleContainerStyles : {},
            ]}>
          {PreIcon && (
              <View style={[styles.postIcon, { paddingRight: 0 }]}>
                <PreIcon />
              </View>
          )}
          <TextInput
              ref={inputRef}
              style={[
                styles.input,
                multiline
                    ? {
                      textAlignVertical: 'top',
                      minHeight: Device.isIos ? Constant.MAIN_HEIGHT * 1.5 : Constant.MAIN_HEIGHT * 1.75,
                      height: 'auto',
                      ...styles.multiline,
                    }
                    : {},
                inputStyle,
              ]}
              returnKeyType={returnKeyType || (multiline ? undefined : 'done')}
              keyboardType={keyboardType}
              secureTextEntry={secureTextEntry}
              placeholder={placeholder}
              value={value}
              placeholderTextColor={placeholderTextColor}
              multiline={multiline}
              blurOnSubmit={false}
              onChangeText={onChangeText}
              maxLength={maxLength}
              onSubmitEditing={multiline ? undefined : Keyboard.dismiss}
              onBlur={handleBlur}
              allowFontScaling={true}
              editable={!disabled}
              autoCapitalize={autoCapitalize}
              onContentSizeChange={
                e => {
                  setHeight(
                      e.nativeEvent.contentSize.height > Constant.MAIN_HEIGHT * 1.75
                          ? e.nativeEvent.contentSize.height
                          : Constant.MAIN_HEIGHT * 1.75,
                  );
                } // prints number of lines
              }
          />
          {loading && <AppLoadingMore loadingMore={loading} />}
          {!disabled && !!PostIcon && (
              <TouchableOpacity onPress={onPostIconPress} style={styles.postIcon}>
                <PostIcon />
              </TouchableOpacity>
          )}
          {isPassword && (
              <TouchableOpacity onPress={() => setSecureTextEntry(prev => !prev)} style={styles.postIcon}>
                {secureTextEntry ? <EyeActiveIcon /> : <EyeInactiveIcon />}
              </TouchableOpacity>
          )}
        </View>
          <TouchableOpacity
              style={[styles.viewSelect, { marginLeft: theme.spacing.sm }]}
              onPress={() => {
                if (onQRPress) {
                  onQRPress();
                }
              }}>
            <QRIcon />
          </TouchableOpacity>
      </View>

      {!!hint && <AppText style={styles.hint}>{hint}</AppText>}
      {!!error && <AppText style={styles.error}>{error}</AppText>}
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  boxInput: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    textAlignVertical: 'center',
    justifyContent: 'space-between',
    minHeight: Constant.MAIN_HEIGHT, //42
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#D5D5D5',
  },
  input: {
    height: Platform.OS === 'web' ? '48px' : '100%',
    width: '100%',
    flex: 1,
    fontSize: FontSize.Font15,
    color: '#333333',
    fontWeight: '400',
    paddingLeft: 10,
    //start Web
    backgroundImage: 'none',
    // borderWidth: 1,
    // borderColor: '#D5D5D5',
    borderRadius: 5,
    outlineStyle: Platform.OS === 'web' ? 'none' : undefined,
    // paddingTop: Platform.OS === 'web' ? 10 : undefined,
    //end Web
  },
  label: {
    fontWeight: '400',
    color: '#333333',
    marginBottom: 8,
  },
  hint: {
    fontWeight: '400',
    color: '#999999',
    marginTop: 7,
  },
  isRequired: {
    color: '#FF5757',
  },
  error: {
    marginTop: 7,
    color: palette.ROYAL_MAIL_RED,
    fontWeight: '400',
  },
  multiline: {
    // height: 246,
    // paddingTop: 7,
    // paddingTop: 0,
    // paddingBottom: 0,
  },
  postIcon: {
    paddingHorizontal: 12,
    // height: '100%',
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  viewSelect: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#C6C6C6',
    marginHorizontal: 0,
    minHeight: Constant.MAIN_HEIGHT, //42
    paddingHorizontal: 12,
    borderRadius: 4,
  },
});
