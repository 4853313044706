import { CommonImages } from '@assets';
import { FirstSplashComponent } from '@screens/auth/Splash/components/FirstSplash.view';
import { RegisterCompletedComponent } from '@screens/auth/Splash/components/RegisterCompleted.view';
import { globalStyle } from '@theme';
import { palette } from '@theme/colors';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Animated, View, Text, Image } from 'react-native';
import { SplashStep, useSplash } from './Splash.hook';
import { SplashStyles } from './Splash.style';

export const SplashScreen = props => {
  const { splashStep } = useSplash(props);

  return (
    <>
      {splashStep === SplashStep.first_splash && <FirstSplashComponent />}
      {/*{splashStep === SplashStep.register_completed && <RegisterCompletedComponent />}*/}
    </>
  );
};
