export const FileDownloadUtil = {
  downloadFile: ({ method, uri, headers, fileName }, onSuccess, onError) => {
    let anchor = document.createElement('a');
    document.body.appendChild(anchor);
    fetch(uri, {
      method: method,
      headers: headers,
    })
      .then(response => response.blob())
      .then(blobby => {
        let objectUrl = window.URL.createObjectURL(blobby);

        anchor.href = objectUrl;
        anchor.download = fileName;
        anchor.click();

        window.URL.revokeObjectURL(objectUrl);
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(e => {
        if (onError) {
          onError();
        }
      });
  },
};
