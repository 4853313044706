import { QR_SCANNER_TYPE } from '@constant';
import { navigate } from '@routes/navigationUtils';
import { SCAN_QR_SCREEN } from '@routes/route';
import { requestPermission } from '@utils/common/deviceUtil';
import _ from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {getSettingsDashboard} from "@redux/user/selectors";

export const useMyTabBar = (state, descriptors, navigation) => {
  const settingsDashboard = useSelector(getSettingsDashboard);

  const tabs = useMemo(() => {
    const tempArr = state.routes?.slice(0);
    const qrTab = tempArr[2];
    tempArr?.splice(2, 1);

    const newTabs = _.chunk(tempArr, 2);
    newTabs?.splice(1, 0, qrTab);

    return newTabs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleQRPress = async () => {
    if (!settingsDashboard?.qrCode?.show) {
      return;
    }
    await requestPermission(() => {
      navigate(SCAN_QR_SCREEN, { type: QR_SCANNER_TYPE.FROM_BOTTOM_TAB });
    });
  };

  return { handleQRPress, tabs };
};
