import {StackActions, useNavigation, useRoute} from '@react-navigation/native';
import { LOGIN_SCREEN } from '@routes/route';
// import {authCreator} from "@redux/auth/selectors";
import { useEffect } from 'react';

// import { useDispatch } from 'react-redux';

export const useActiveSuccessful = props => {
  const navigation = useNavigation();
  const messageInfo = useRoute().params?.data

  console.log('useRegisterCompleted>>>>', messageInfo);
  useEffect(() => {
    console.log('waitingForSendMail>>>>');
    setTimeout(() => {
      // dispatch(resetUserValidation({}));
      navigation.dispatch(StackActions.replace(LOGIN_SCREEN));
    }, 5000);
  }, []);
  return { messageInfo };
};
