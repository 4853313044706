import { createAction, createSlice } from '@reduxjs/toolkit';

export interface IunlockSkillState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IunlockSkillState = {
  loading: false,
  payload: null,
  error: null,
};

export const unlockSkillRequest = createAction('unlockSkill/unlockSkillRequest', (payload, onSuccess, onFailed) => {
  console.log('prepareAction>>>', payload, onSuccess);
  return {
    payload: payload,
    meta: onSuccess,
    error: onFailed,
  };
});

const unlockSkillSlice = createSlice({
  name: 'unlockSkill',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // unlockSkillRequest(state, action) {
    //   state.loading = true;
    //   // @ts-ignore
    //   state.payload = null;
    //   state.error = null;
    // },
    unlockSkillSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    unlockSkillFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { unlockSkillSuccess, unlockSkillFailed } = unlockSkillSlice.actions;

export default unlockSkillSlice.reducer;
