import { Constant } from '@constant';
import { FORM, FORM_ELEMENT_NAME } from '@constant/model/RegisterForm';
import { getLogoutSuccess } from '@redux/auth/authSlice';
import { uploadFileRequest } from '@redux/file/uploadFileSlice';
import { uploadFileViaFDBRequest } from '@redux/file/uploadFileViaFDBSlice';
import { store } from '@redux/store';
import { SubForm } from '@screens/main/report/bundle/form/components/DispatchPoint.hook';
import { Device } from '@theme/device';
import {getErrorMessage, getFlatMsgFromObject, showErrorMsg} from '@utils/api';
import { getHashPassword } from '@utils/common/passwordUtils';
import { StringHelper } from '@utils/common/string';
import { checkFormValidateExpression } from '@utils/form';
import axios from 'axios';
import i18next from 'i18next';
import _ from 'lodash';
import { min } from 'lodash/math';
import moment from 'moment';
import replaceAll from 'string.prototype.replaceall';
import {FileTransform} from "@utils/transform/file";

var _moment = moment;

const fetchImageFromUri = async (uri, callback) => {
  fetch(uri)
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.blob();
    })
    .then(response => {
      response.uri = URL.createObjectURL(response);
      callback(response);
    });
};

export const mappingForm = steps => {
  if (_.isEmpty(steps)) {
    return steps;
  }
  const data = _.cloneDeep(steps);
  const keys = Object.keys(data);
  console.log('mappingForm>>>', keys);
  for (const keysKey of keys) {
    // console.log('keysKey>>>', keysKey, keys);
    const value = data[keysKey];
    data[keysKey] = mappingStep(value);
  }
  return data;
};

export const mappingStep = step => {
  // console.log('mappingStep>>', step);
  if (_.isEmpty(step)) {
    return step;
  }
  const attributes = step?.attributes;
  if (_.isEmpty(attributes)) {
    return step;
  }
  // attributes?.map(att => {
  //   const values = att?.values;
  //   if (!_.isEmpty(values)) {
  //     if (Array.isArray(values)) {
  //       const fixValues = values?.map(x => {
  //         return {
  //           ...x,
  //           content: getContentTitle(x?.name),
  //         };
  //       });
  //       att.data = fixValues;
  //     } else if (typeof values === 'object') {
  //       att.data = [];
  //     }
  //   }
  //
  //   att.title = getTitle(att);
  //
  //   return att;
  // });
  step.attributes = mappingStepOrigin(attributes);
  return step;
};

export const mappingStepOrigin = attributes => {
  // console.log('mappingStepOrigin>>>', attributes);
  attributes?.map(att => {
    // if (att.type === FORM.FORM_TYPE.checkbox) {
    //   const options = [
    //     {
    //       id: att?._id ?? att?.internalId,
    //       content: getContentTitle(att?.name),
    //       extraInfo: att?.extraInfo,
    //     },
    //   ];
    //   att.data = options;
    //   att.title = '';
    //   return att
    // }
    if (att.type === FORM.FORM_TYPE.checkbox) {
      att.content = att?.content ?? getContentTitle(att?.name);
      att.title = getContentTitle(att?.name);
      return att;
    }
    const values = att?.options;
    if (!_.isEmpty(values)) {
      if (Array.isArray(values)) {
        const fixValues = values?.map(x => {
          return {
            ...x,
            content: getContentTitle(x?.name),
            id: x?._id ?? x?.internalId,
          };
        });
        att.data = fixValues;
      } else if (typeof values === 'object') {
        att.data = [];
      }
    }

    att.title = getTitle(att);

    return att;
  });
  // console.log('attributes>>>', attributes);
  return attributes;
};

export const getTitle = attribute => {
  if (_.isEmpty(attribute)) {
    return '';
  }
  // const description = attribute?.description;
  // getContentTitle(attribute?.name)
  // let label = attribute?.label;
  // if (_.isEmpty(label)) {
  //   if (_.isEmpty(description)) {
  //     return getContentTitle(attribute?.name);
  //   }
  //   return getContentTitle(description);
  // }
  // return getContentTitle(label);
  const name = attribute?.name;
  let label = attribute?.label;
  if (_.isEmpty(name)) {
    if (_.isEmpty(label)) {
      return getContentTitle(attribute?.description);
    }
    return getContentTitle(label);
  }
  return getContentTitle(name);
};

export const getContentTitle = name => {
  // console.log('getContentTitle>>>', name, _.isEmpty(name), typeof name);
  if (typeof name === 'number') {
    return name;
  }
  if (_.isEmpty(name)) {
    return '';
  }
  if (typeof name === 'string' || name instanceof String) {
    return name;
  } else {
    const language = i18next.language.toLowerCase() ?? 'en';
    return name[language] ?? language.en;
  }
};

export const fixFormValues = async (filterValues, values) => {
  if (filterValues.hasOwnProperty(FORM_ELEMENT_NAME.password)) {
    let password = values[FORM_ELEMENT_NAME.password];
    let hashPassword;
    try {
      hashPassword = await getHashPassword(password);
    } catch (error) {
      showErrorMsg(error);
    }
    filterValues[FORM_ELEMENT_NAME.password] = hashPassword;
    delete filterValues[FORM_ELEMENT_NAME.confirm_password];
  }

  if (filterValues.hasOwnProperty(FORM_ELEMENT_NAME.address)) {
    const tmp_address = values[FORM_ELEMENT_NAME.tmp_address_extra];
    delete filterValues[FORM_ELEMENT_NAME.tmp_address_extra];
    if (!_.isEmpty(tmp_address)) {
      // filterValues.update({ ...tmp_address });
      // Object.assign(filterValues, tmp_address)
      filterValues = {
        ...filterValues,
        ...tmp_address,
      };
    }
  }
  if (filterValues.hasOwnProperty(FORM_ELEMENT_NAME.phonenumber)) {
    const phonenumber = values[FORM_ELEMENT_NAME.phonenumber];
    if (!_.isEmpty(phonenumber)) {
      filterValues[FORM_ELEMENT_NAME.phonenumber] = Constant.PHONE_CODE + phonenumber.replace(Constant.PHONE_CODE, '');
    }
  }

  return filterValues;
};

export const handleShowError = (err, setErrors, fullError) => {
  // if (typeof check === 'string')
  console.log('handleShowError>>>', err, typeof err);
  if (typeof err === 'string') {
    showErrorMsg(err, '');
    return;
  }
  if (axios.isAxiosError(err)) {
    if (err.response?.status === 403) {
      // dispatch(getLogoutSuccess({}))
      store.dispatch(getLogoutSuccess({}));
      const msg = err?.response?.data?.message ?? err?.message;
      showErrorMsg(msg);
      return;
    }
  }
  const msg = err?.description ?? err.message;
  const error = err?.error;
  console.log('handleShowError>>', error);
  if (!_.isEmpty(error) && setErrors) {
    setErrors(error);
  } else {
    const transMsg = getErrorMessage(err)
    showErrorMsg(transMsg, getFlatMsgFromObject(error));
  }
};

export const runEval = expression => {
  // const s = `const evalResult(){${strScript}}`
  // var result = new Function(strScript)();
  // const result = eval("2+2")
  var result = eval('(function() {' + expression + '}())');

  return result;
};

export const fixEvalExpression = (expression, values) => {
  if (_.isEmpty(expression) || _.isEmpty(values)) {
    return expression;
  }
  // const keys = Object.keys(values)
  let fixExpression = expression;
  // fixExpression = fixExpression.replaceAll('moment(', 'this.moment(');
  if (Device.deviceType === 'web') {
    fixExpression = replaceAll(fixExpression, 'moment(', '_moment(');
    fixExpression = replaceAll(fixExpression, '^moment()', '_moment()');
  } else {
    fixExpression = replaceAll(fixExpression, 'moment(', 'this.moment(');
  }

  const keys = Object.keys(values);
  keys.sort();
  // keys.reverse()
  // console.log('fixEvalExpression>>>', keys);
  // const ls = fixExpression.split(' ');
  // const xxx = []
  for (const valuesKey of keys) {
    const special_char = [' ', ')', ';', ',', '(', '.'];
    for (const ch of special_char) {
      // const rp = `${valuesKey}${ch}`;
      // console.log('rp>>>', rp)
      // fixExpression = fixExpression.replaceAll(`${valuesKey}${ch}`, `this.values['${valuesKey}']${ch}`);
      fixExpression = replaceAll(fixExpression, `${valuesKey}${ch}`, `values['${valuesKey}']${ch}`);
    }
  }
  return fixExpression;
};

export const checkEvalCondition = (expression, values) => {
  // console.log('checkEvalCondition>>>', expression, values);
  return checkEvalExpression(expression, values);
};

export const checkEvalExpression = (expression, values) => {
  if (_.isEmpty(expression)) {
    return true;
  }
  if (_.isEmpty(values)) {
    return false;
  }

  //TODO: replace internalId here
  // expression = "return moment().diff(this.values['receipt_date']"
  this.values = _.cloneDeep(values);
  this.moment = moment;
  if (Device.deviceType === 'web') {
    this.moment = _moment;
  }
  const fixExpression = StringHelper.fixEvalExpression(expression, values);
  //NOTE: IMPORTANT! must separate to variable to run eval on android

  const scriptStr = '(function() {' + fixExpression + '}())';
  // console.log('scriptStr>>>>', scriptStr, expression);
  // console.log('check>>>>',this.values['receipt_date'], this.moment().diff(this.moment(this.values['receipt_date'])));

  // return this.moment().diff(this.moment(values['receipt_date']));
  // return this.moment().diff(this.moment(this.values['receipt_date'])) >= 0;
  // eslint-disable-next-line no-eval
  // return true;
  try {
    const result = eval(scriptStr);

    // eslint-disable-next-line no-eval

    // const rs = runEval(test)
    // console.log('checkEvalCondition>>>', fixExpression, expression, result);
    return result;
  } catch (e) {
    console.log('ex>>>', e, scriptStr, values);
    return false;
  }
};

export const EvalTransform = {
  getMinDate: (expression, values) => {
    try {
      const check = checkEvalExpression(expression, values);
      if (typeof check === 'boolean') {
        return null;
      }
      if (moment.isMoment(check)) {
        return check.toDate();
      }
      return check;
    } catch (e) {
      console.log('ex>>>getMinDate>>', e);
      return null;
    }
  },
  getMaxDate: (expression, values) => {
    try {
      // console.log('getMaxDate>>>', expression, values);
      const check = checkEvalExpression(expression, values);
      if (typeof check === 'boolean') {
        return null;
      }
      // console.log('getMaxDate<<check<<<', typeof check, check, moment.isMoment(check));
      if (moment.isMoment(check)) {
        return check.toDate();
      }
      return check;
    } catch (e) {
      console.log('ex>>>getMaxDate>>', e);
      return null;
    }
  },
};

export const FormTransform = {
  getErrorMessage: (formItem, errorType) => {
    const errors = formItem?.errors;
    if (_.isEmpty(formItem) || _.isEmpty(errors)) {
      return i18next.t('FORM_INVALID_FIELD');
    }
    return getContentTitle(errors[errorType]);
  },
  getInternalId: (formItemType, formData) => {
    if (_.isEmpty(formItemType) || _.isEmpty(formData)) {
      return null;
    }
    return formData.find(x => x.type === formItemType)?.internalId;
  },
  setFormikElementValue: (internalId, formik, value) => {
    if (_.isEmpty(internalId) || _.isEmpty(formik)) {
      return;
    }
    formik.setFieldValue(internalId, value);
  },

  getImagesFormik: (formValue, formik, formData) => {
    //TODO: get list of camera, picker

    if (_.isEmpty(formValue) || _.isEmpty(formData)) {
      return {};
    }

    const rs = formData.filter(x => [FORM.FORM_TYPE.camera, FORM.FORM_TYPE.imagePicker].includes(x.type));
    if (_.isEmpty(rs)) {
      return {};
    }
    let attachments = {};
    for (const r of rs) {
      const internalId = r.internalId;
      attachments[internalId] = formValue[internalId];
    }
    return attachments;
  },

  getFirstImageFormik: (formValue, formData) => {
    console.log('getFirstImageFormik>>>', formValue, formData)
    const rs = FormTransform.getImagesFormik(formValue, null, formData);

    const vl = Object.values(rs);
    if (_.isEmpty(vl)) {
      return null;
    }
    const x = vl[0][0]
    if(typeof x === 'string'){
      const cameraItem = formData.find(x => [FORM.FORM_TYPE.camera, FORM.FORM_TYPE.imagePicker].includes(x?.type))
      return {
        url: x,
        s3config: cameraItem?.s3config,
        uploadFileName: cameraItem?.uploadFileName,
        uploadPath: cameraItem?.uploadPath
      }
    }
    return vl[0][0];
  },
  uploadFileApiOptimize: (attachments, dispatch, folderName = 'bundle') => {
    const uploadedFiles = attachments.filter(x => !!x.response);
    //NOTE: should check to every file
    if (!_.isEmpty(uploadedFiles)) {
      return attachments;
    }

    return FormTransform.uploadFileApi(attachments, dispatch, folderName);
  },

  uploadFileFDBApiOptimize: (attachments, dispatch, folderName = 'bundle', s3config = 'TTM') => {
    if(_.isEmpty(attachments)){
      return attachments
    }

    //NOTE: ignore when attachments is list of string because of image had uploaded
    const firstItem = attachments[0]
    if(typeof firstItem === 'string'){
      return attachments.map(x => {
        return {
          url: x
        }
      })
    }
    const uploadedFiles = attachments.filter(x => !!x.url);
    //NOTE: should check to every file
    if (!_.isEmpty(uploadedFiles)) {
      return attachments;
    }

    return FormTransform.uploadFileFDBApi(attachments, dispatch, folderName, s3config);
  },

  uploadFileApi: (attachments, dispatch, folderName = 'bundle') => {
    return new Promise((resolve, rejects) => {
      if (!_.isEmpty(attachments)) {
        const formData = new FormData();
        formData.append('folder', folderName);
        attachments.forEach(file => {
          if (!_.isEmpty(file)) {
            formData.append('files[]', {
              ...file,
              name: file?.fileName,
            });
          }
        });
        dispatch(
          uploadFileRequest(
            formData,
            rs => {
              // onSuccess(rs?.data);
              resolve(rs?.data);
            },
            err => {
              resolve([]);
            },
          ),
        );
      } else {
        resolve([]);
      }
    });
  },
  uploadFileFDBApi: (attachments, dispatch, folderName = 'bundle', s3config = "TTM", uploadName = '') => {
    return new Promise((resolve, rejects) => {
      if (!_.isEmpty(attachments)) {
        const payload = {
          path: folderName,
          s3config: s3config,
          useFileName: true,
        };
        const formData = new FormData();
        formData.append("useFileName", true)
        // formData.append('folder', folderName);
        if (Device.deviceType !== 'web') {
          attachments.forEach(file => {
            if (!_.isEmpty(file)) {
              formData.append('files[]', {
                ...file,
                name: FileTransform.fixFileName(file?.fileName, uploadName),
              });
            }
          });
          payload.formData = formData;
          dispatch(
            uploadFileViaFDBRequest(
              payload,
              rs => {
                // onSuccess(rs?.data);
                console.log('uploadFileViaFDB response>>>', rs);
                resolve(rs?.response?.map(x => {
                  return {
                    ...x,
                    response: x?.url,
                  }
                }));
                },
              err => {
                resolve([]);
              },
            ),
          );
        } else {
          attachments.forEach(file => {
            if (!_.isEmpty(file)) {
              fetchImageFromUri(file.uri, function (_blobFile) {
                // should be generate image name and extension
                formData.append('files[]', _blobFile, FileTransform.fixFileName('image.png', uploadName));
                payload.formData = formData;
                dispatch(
                  uploadFileViaFDBRequest(
                    payload,
                    rs => {
                      if (rs && _.isArray(rs.response)) {
                        rs.response[0].response = rs.response[0].url;
                        resolve([
                          {
                            ...rs.response[0],
                            response: rs.response[0].url,
                          },
                        ]);
                      } else {
                        resolve(rs?.response);
                      }
                    },
                    err => {
                      resolve([]);
                    },
                  ),
                );
              });
            }
          });
        }
      } else {
        resolve([]);
      }
    });
  },
  uploadFileFDBApiV2: (attachments, dispatch, folderName = 'bundle', s3config = "TTM", uploadName = '') => {
    return new Promise((resolve, rejects) => {
      if (!_.isEmpty(attachments)) {
        const formData = new FormData();
        // formData.append('folder', folderName);
        attachments.forEach(file => {
          if (!_.isEmpty(file)) {
            formData.append('files[]', {
              ...file,
              name: FileTransform.fixFileName(file?.fileName, uploadName),
            });
          }
        });
        const payload = {
          path: folderName,
          formData: formData,
          s3config: s3config,
        };

        dispatch(
            uploadFileViaFDBRequest(
                payload,
                rs => {
                  // onSuccess(rs?.data);
                  console.log('uploadFileViaFDB response>>>', rs);
                  resolve(rs?.response?.map(x => {
                    return {
                      ...x,
                      response: x?.url,
                    }
                  }));
                  },
                err => {
                  resolve([]);
                },
            ),
        );
      } else {
        resolve([]);
      }
    });
  },
  uploadFileFormikApi: async (values, formData, formik, dispatch, folderName = 'bundle') => {
    const payload = _.cloneDeep(values);
    const atts = FormTransform.getImagesFormik(values, formik, formData);
    // const attachments = values.attachments;
    if (_.isEmpty(atts)) {
      return payload;
    }

    for (const internalId in atts) {
      // const result = await uploadFileApi(atts[internalId]);
      const result = await FormTransform.uploadFileApiOptimize(atts[internalId], dispatch, folderName);
      payload[internalId] = result?.map(x => x?.response);
    }
    return payload;
  },
  toImagesFBD: (internalId, values) => {
    if (_.isNil(values)) {
      return [];
    }
    return values.map(x => {
      return {
        url:x
      };
    });
  },

  uploadFileFormikFDBApi: async (values, formData, formik, dispatch, folderName = 'bundle', isS3 = true) => {
    const payload = _.cloneDeep(values);
    const atts = FormTransform.getImagesFormik(values, formik, formData);
    console.log('uploadFileFormikFDBApi>>>', atts)
    // const attachments = values.attachments;
    if (_.isEmpty(atts)) {
      return payload;
    }

    for (const internalId in atts) {
      // const result = await uploadFileApi(atts[internalId]);
      const control = formData?.find(x => x?.internalId === internalId)
      const uploadPath = FileTransform.fixUploadPath(control?.uploadPath)
      const mergeFolder = !_.isEmpty(uploadPath) ? uploadPath : folderName
      const result = await FormTransform.uploadFileFDBApiOptimize(atts[internalId], dispatch, mergeFolder, control?.s3config);
      payload[internalId] = result?.map(x => x?.url);
    }
    return payload;
  },
  uploadFileFormikFDBApiV2: async (values, formData, formik, dispatch, s3config = 'TTM') => {
    const payload = _.cloneDeep(values);
    const atts = FormTransform.getImagesFormik(values, formik, formData);
    // const attachments = values.attachments;
    if (_.isEmpty(atts)) {
      return payload;
    }

    for (const internalId in atts) {
      // const result = await uploadFileApi(atts[internalId]);
      const result = await FormTransform.uploadFileFDBApiOptimize(atts[internalId], dispatch, folderName, s3config);
      payload[internalId] = result?.map(x => x?.url);
    }
    return payload;
  },
  getSubFormDispatch: formData => {
    if (_.isEmpty(formData)) {
      return [];
    }

    return formData.find(x => x.type === FORM.FORM_TYPE.dispatch_point)?.subForms ?? [];
  },
  getVerifyOnMap: formData => {
    if (_.isEmpty(formData)) {
      return [];
    }

    return formData.find(x => x.type === FORM.FORM_TYPE.dispatch_point)?.verifyOnMap;
  },
  getEmptyValue: item => {
    if (_.isEmpty(item)) {
      return '';
    }
    switch (item?.type) {
      case FORM.FORM_TYPE.MultipleSelection:
      case FORM.FORM_TYPE.stringlistmultiple:
      case FORM.FORM_TYPE.imagePicker:
      case FORM.FORM_TYPE.camera:
        return [];
      case FORM.FORM_TYPE.checkbox:
        return false;
      // case FORM.FORM_TYPE.boolean:
      //   return null;
    }
    return '';
  },

  resetValueForHiddenField: (formik, data, checkCondition = true, values = null, requiredConditionFieldId = null) => {
    // console.log('resetValueForHiddenField>>>', data, checkCondition, formik.values);
    // return;
    if (_.isEmpty(formik) || _.isEmpty(data) || !checkCondition) {
      return;
    }
    if (_.isEmpty(values)) {
      values = _.cloneDeep(formik.values);
    }

    if (_.isEmpty(values)) {
      return;
    }

    for (const datum of data) {
      if (_.isEmpty(datum?.condition?.react)) {
        continue;
      }
      // values = _.cloneDeep(formik.values);
      const isCondition = checkEvalCondition(datum?.condition?.react, values);
      console.log('resetValueForHiddenField>>>checkEvalCondition>>>', datum?.internalId, isCondition, datum, values);
      if (!isCondition) {
        const defaultValue = FormTransform.getEmptyValue(datum);
        const internalValue = values[datum.internalId];
        const type = datum.type;

        // if (defaultValue !== internalValue && type !== FORM.FORM_TYPE.camera) {
        if (defaultValue !== internalValue) {
          const newValue = FormTransform.getEmptyValue(datum);
          formik?.setFieldValue(datum.internalId, newValue);
          values[datum.internalId] = newValue;
          // formik.values[datum.internalId] = newValue;
          console.log(
            `setNewValue>>>${datum.internalId}>>>${type}>>>_${newValue}_>>>_${defaultValue}>>>${internalValue}`,
            defaultValue !== internalValue,
            defaultValue != internalValue,
          );
        }
      }
      else
      {
        //NOTE: should reset null error when not required scripted and field is show
        const requiredCondition = datum?.requiredCondition?.react
        console.log('requiredCondition>>>', datum.internalId, requiredCondition)
        if(!!requiredCondition){
          const required = FormTransform.getFormRequired(datum, values)
          if(!required){
            // formik?.setFieldTouched(datum.internalId, true);
            formik?.setFieldError(datum.internalId, undefined);
            console.log('required>>>',datum.internalId, required, formik)
          }
        }
      }
    }
  },
  checkCustomCondition: (internalId, values, listExpression, setErrors) => {
    const error = {};
    if (_.isEmpty(listExpression)) {
      return false;
    }

    for (const expression of listExpression) {
      const isExpression = checkEvalCondition(expression?.javascript, values);
      if (!isExpression) {
        error[internalId] = expression.error;
        setErrors(error);
        break;
      }
    }

    if (!_.isEmpty(error)) {
      return false;
    }

    return true;
  },
  // when eval = true will error
  checkPositiveCustomCondition: (
    internalId,
    values,
    listExpression,
    setErrors,
    setFieldError,
    setFieldTouched,
    formik,
  ) => {
    const error = {};
    if (_.isEmpty(listExpression)) {
      return true;
    }

    for (const expression of listExpression) {
      const isExpression = checkEvalCondition(expression?.javascript, values);
      if (isExpression) {
        error[internalId] = expression.error;
        if (setFieldTouched) {
          setFieldTouched(internalId, true);
        }
        if (setFieldError) {
          setFieldError(internalId, expression.error);
          console.log('setFieldError>>>', internalId);
        } else {
          setErrors(error);
        }
        console.log('checkPositiveCustomCondition>>>>', error, expression);
        break;
      }
    }

    if (!_.isEmpty(error)) {
      // const errors =
      // formik.errors = error
      return true;
    }

    return false;
  },
  validateForm: async (formik, formData, forceCheckEval = false) => {
    console.log('validateForm>>>0>>>', formik, formData);
    if (_.isEmpty(formik)) {
      return {};
    }
    if (forceCheckEval) {
      formik.errors = {};
    }

    const vl = await formik.validateForm(formik.values);
    console.log('validateForm>>>1>>>', vl);

    const x = await formik.submitForm();

    console.log('validateForm>>>2>>>', formik, x, vl);
    let err = {
      ...vl,
      ...formik.errors,
    };
    if (forceCheckEval) {
      const err1 = checkFormValidateExpression(formData, formik.values);
      const xxx = {
        ...err1,
        ...err,
      };
      formik.setErrors(xxx);
      return xxx;
    } else {
      if (_.isEmpty(err)) {
        err = checkFormValidateExpression(formData, formik.values, formik.setErrors);
        if (_.isEmpty(err)) {
          return err;
        }
      }
    }

    return err;
  },
  submitWithSubForm: async (formik, subFormRef, formData, subId) => {
    if (subFormRef?.current != null) {
      const subForm = formData?.find(x => x.internalId === subId)?.subForms ?? [];
      // const subForm = SubForm

      console.log('validateForm>>>before>>>', subFormRef?.current);
      const errSubForm = await FormTransform.validateForm(subFormRef?.current, subForm, true);
      console.log('errSubForm>>>', errSubForm);
    }
    await formik.submitForm();
  },
  submitAndScrollWithSubForm: async (formik, formData, layoutInfo, scrollToY, subFormRef, subId) => {
    if (subFormRef?.current != null) {
      const subForm = formData?.find(x => x.internalId === subId)?.subForms ?? [];
      // const subForm = SubForm

      console.log('validateForm>>>before>>>', subFormRef?.current);
      const errSubForm = await FormTransform.validateForm(subFormRef?.current, subForm, true);
      console.log('errSubForm>>>', errSubForm);
    }
    await FormTransform.submitAndScroll(formik, formData, layoutInfo, scrollToY);
  },
  submitAndScroll: async (formik, formData, layoutInfo, scrollToY) => {
    const vl = await formik.validateForm(formik.values);
    const x = await formik.submitForm();

    console.log('submitForm>>>', formik, x, vl);
    let err = {
      ...vl,
      ...formik.errors,
    };
    // if (_.isEmpty(err)) {
      const err2 = checkFormValidateExpression(formData, formik.values);
      console.log('submitAndScroll>>>checkFormValidateExpression>>>',err, formik.values, err2)
      err = {
        ...err,
        ...err2
      }
      if (_.isEmpty(err)) {
        return;
      }
      else{
        formik.setErrors(err)
      }
    // }
    if (_.isEmpty(layoutInfo)) {
      return;
    }
    setTimeout(() => {
      const rs = [];
      for (const x1 in err) {
        rs.push(layoutInfo[x1]);
      }
      console.log('submitAndScroll>>>', err);

      console.log('layoutInfo>>>', layoutInfo, rs)

      const maxY = min(rs);
      if (maxY) {
        scrollToY(maxY);
      }
    }, 300);
  },

  setAppInputValue: (formik, item, value) => {
    if (_.isEmpty(formik) || _.isEmpty(item)) {
      return;
    }
    const internalId = item.internalId;
    if (_.isEmpty(internalId)) {
      return;
    }
    if (internalId?.toLowerCase()?.includes('email')) {
      formik.setFieldValue(internalId, StringHelper.removeSpace(value));
    } else {
      if(item.type === FORM.FORM_TYPE.integer){
        if(_.isNumber(value)){
          formik.setFieldValue(internalId, _.toNumber(value));
          return
        }
      }
      formik.setFieldValue(internalId, value);
    }
  },
  getPlaceHolder: placeHolder => {
    return placeHolder ? placeHolder : i18next.t('SELECT_PLACE_HOLDER_DEFAULT');
  },
  getValuesWhenSubmit: (values,formValues, formStructure, otherStructures) => {
    //TODO: check & reset value when submit
    //1. get field change value from main form
    //2. get list field depend on main field in other forms.
    //3. get list field depend on other fields in other formm

  },
  getFormRequired: (item, values) => {
    if(_.isEmpty(item)){
      return false
    }

    const requiredCondition = item?.requiredCondition
    if(_.isEmpty(requiredCondition)){
      return !!item?.required
    }
    if(_.isEmpty(requiredCondition?.react)){
      return false
    }
    const isCondition = checkEvalCondition(requiredCondition?.react, values);
    return isCondition
  }
};
