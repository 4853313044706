import { palette } from '@theme/colors';
import { Font, FontSize, Spacing } from '@theme/size';
import { StyleSheet, Platform } from 'react-native';

export const styles = StyleSheet.create({
  container: {},
  heading: {
    ...Font.Bold_700,
    color: '#333333',
    fontSize: FontSize.Font15,
    paddingTop: Platform.OS === 'web' ? '20px' : Spacing.height16,
  },
  itemWrap: {},
  menuItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: Platform.OS === 'web' ? '20px' : Spacing.height20,
  },
  itemImg: {
    width: Spacing.width16,
    height: Spacing.width36,
  },
  title: {
    flex: 1,
    ...Font.Bold_400,
    color: '#666666',
    fontSize: FontSize.Font15,
    paddingHorizontal: Platform.OS === 'web' ? '20px' : Spacing.width12,
  },
  divider: {
    height: 1,
    backgroundColor: '#9999994D',
    marginTop: Platform.OS === 'web' ? '20px' : Spacing.height16,
  },
});
