import { CrossIcon, TickIcon } from '@assets';
import { AppText } from '@components';
import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

/**
 * - Selected must be number 0 (No) or 1 (Yes)
 * - Selected is also default data
 */
export const AppYesNo = React.memo(props => {
  const { style, selected, onSelect, isRequired, title, isBoldTitle } = props;

  return (
    <View style={[styles.container, style]}>
      <AppText style={[styles.textTitle, isBoldTitle ? { fontWeight: '700' } : {}]}>
        {isRequired && <AppText style={styles.isRequired}>* </AppText>}
        {title}
      </AppText>
      <TouchableOpacity style={[styles.box, selected === 1 ? styles.active : {}]} onPress={() => onSelect(1)}>
        <TickIcon />
        <AppText style={styles.text}>Yes</AppText>
      </TouchableOpacity>
      <TouchableOpacity
        style={[styles.box, selected === 0 ? styles.active : {}, styles.mt16]}
        onPress={() => onSelect(0)}>
        <CrossIcon />
        <AppText style={styles.text}>No</AppText>
      </TouchableOpacity>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  box: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#C6C6C6',
    padding: 12,
    borderRadius: 4,
  },
  textTitle: {
    fontWeight: '400',

    marginBottom: 10,
  },
  isRequired: {
    color: '#FF5757',
  },
  active: {
    backgroundColor: '#D5D5D5',
  },
  text: {
    fontWeight: '400',
    color: '#333333',
    marginLeft: 10,
  },
  mt16: {
    marginTop: 16,
  },
});
