import { ActiveRadioIcon, RadioIcon } from '@assets';
import { AppText } from '@components';
import { Constant } from '@constant';
import { FlexImageIcon } from '@screens/main/Menu/Components';
import { Device } from '@theme/device';
import { Font, FontSize, Spacing } from '@theme/size';
import { checkRadioId } from '@utils/form';
import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, View, useWindowDimensions } from 'react-native';

/**
 * - (required) selected must be ID
 * - (required) onSelect is callback with ID parameter
 * - (required) data must have ID and CONTENT
 */
export const AppRadio = props => {
  const {
    onSelect,
    selected,
    isRadio = true,
    data,
    border = true,
    styleSelected,
    disabled,
    textStyle,
    onLayout = null,
  } = props;
  const [textLine, setTextLine] = useState(1);
  // console.log('AppRadio>>>', data, selected);
  // const checkId = id => {
  //   if (typeof id === 'boolean') {
  //     return id !== undefined;
  //   }
  //   return !!id;
  // };
  const scaleFont = useWindowDimensions().fontScale;
  return (
    <TouchableOpacity
      onPress={() => onSelect(data.id)}
      style={[
        styles.radioBox,
        selected === data.id && !isRadio && styles.active,
        border && styles.border,
        selected === data?.id ? styleSelected : {},
      ]}
      disabled={disabled}
      activeOpacity={0.7}
      onLayout={e => {
        if (onLayout) {
          onLayout(e);
        }
      }}>
      {isRadio && (
        <View
          style={{
            height: textLine > 1 ? '100%' : 'auto',
            marginRight: 4,
            marginTop: textLine > 1 ? FontSize.Font15 * scaleFont : 0,
          }}>
          {data.icon ? (
            <FlexImageIcon icon={data.icon} style={styles.itemImg} />
          ) : selected === data.id ? (
            <ActiveRadioIcon />
          ) : (
            <RadioIcon />
          )}
        </View>
      )}
      <AppText
        onTextLayout={e => {
          setTextLine(e.nativeEvent.lines.length);
        }}
        style={[styles.text, textStyle]}>
        {data.content}
      </AppText>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  itemImg: {
    width: Spacing.width16,
    minHeight: Spacing.width36,
  },
  radioBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    fontSize: FontSize.Font15,
    // lineHeight: 20,
    fontWeight: '400',
    marginLeft: Spacing.width4,
    paddingRight: 16,
  },
  active: {
    backgroundColor: '#D5D5D5',
  },
  border: {
    borderColor: '#D5D5D5',
    borderWidth: 1,
    minHeight: Constant.MAIN_HEIGHT,
    padding: 10,
    borderRadius: 4,
    marginTop: 15,
  },
});
