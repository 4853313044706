import { createSlice } from '@reduxjs/toolkit';

export interface IviewFileViaFDBState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IviewFileViaFDBState = {
  loading: false,
  payload: null,
  error: null,
};

const viewFileViaFDBSlice = createSlice({
  name: 'viewFileViaFDB',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    viewFileViaFDBRequest(state, action) {
      state.loading = true;
      // @ts-ignore
      state.payload = null;
      state.error = null;
    },
    viewFileViaFDBSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    viewFileViaFDBFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { viewFileViaFDBRequest, viewFileViaFDBSuccess, viewFileViaFDBFailed } = viewFileViaFDBSlice.actions;

export default viewFileViaFDBSlice.reducer;
