import { CommonImages } from '@assets';
import { OverDueStyle as styles } from '@screens/main/D2D/overDue/OverDue.style';
import { globalStyle } from '@theme';
import { palette } from '@theme/colors';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ActivityIndicator, View } from 'react-native';
import FastImage from 'react-native-fast-image';

export const AppImage = props => {
  const { style, source, resizeMode, defaultSource } = props;
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false)
  // FastImage.cacheControl.cacheOnly.
  // console.log('cacheControl>>>');
  return (
    <View style={[globalStyle.center, ...style]}>
      <FastImage
        style={{ width: '100%', height: '100%' }}
        source={source.uri && !isError ? source : defaultSource}
        // source={ source }
        resizeMode={resizeMode}
        defaultSource={!props.hideDefaultImage ? props.defaultSource : {} }
        onLoadStart={() => setLoading(true)}
        onLoadEnd={() => setLoading(false)}
        onError={() => {
          // console.log('onError>>>', defaultSource, source)
          setLoading(false)
          setIsError(true)
        }
        }
      />
      {loading && <ActivityIndicator style={{ position: 'absolute' }} size={'small'} color={palette.LOADING_COLOR} />}
    </View>
  );
};

AppImage.propTypes = {
  style: PropTypes.any,
  source: PropTypes.any,
  resizeMode: PropTypes.string,
  defaultSource: PropTypes.any,
    hideDefaultImage: PropTypes.bool,
};

AppImage.defaultProps = {
    hideDefaultImage: false
};
