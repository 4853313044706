import { createSlice } from '@reduxjs/toolkit';

export interface IgetCDSFormState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IgetCDSFormState = {
  loading: false,
  payload: null,
  error: null,
};

const getCDSFormSlice = createSlice({
  name: 'getCDSForm',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getCDSFormRequest(state, action) {
      state.loading = true;
      // @ts-ignore
      state.payload = null;
      state.error = null;
    },
    getCDSFormSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    getCDSFormFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getCDSFormRequest, getCDSFormSuccess, getCDSFormFailed } = getCDSFormSlice.actions;

export default getCDSFormSlice.reducer;
