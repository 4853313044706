import { QR_SCANNER_TYPE } from '@constant';
import { Config } from '@constant/config';
import { getIsFirstTimeInApp } from '@redux/app/appSlice';
import { getUserValidationRequest } from '@redux/auth/authSlice';
import {isFirstTimeApp, navigate} from '@routes/navigationUtils';
import {SCAN_QR_SCREEN, LOGIN_SCREEN, SIGN_UP_WITH_NAME_SCREEN} from '@routes/route';
import { Device } from '@theme/device';
import { requestPermission } from '@utils/common/deviceUtil';
import { useFormik } from 'formik';
import { t } from 'i18next';
import { useEffect } from 'react';
import {Alert, BackHandler} from 'react-native';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import {NavigationHelper} from "@utils/common/navigation";
import {useNavigation} from "@react-navigation/native";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(t('FIRST_NAME_IS_REQUIRED')),
  lastName: Yup.string().required(t('LAST_NAME_IS_REQUIRED')),
  iCode: Yup.string().required(t('ICODE_IS_REQUIRED')),
});

export const useLogic = () => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      firstName: Config.IsDebug ? Config.Mock.SignUp.firstname : '',
      lastName: Config.IsDebug ? Config.Mock.SignUp.lastname : '',
      iCode: Config.IsDebug ? Config.Mock.SignUp.icode : '',
    },
    onSubmit: async values => {
      const { firstName, lastName, iCode } = values;
      dispatch(
        getUserValidationRequest({
          firstName: firstName,
          lastName: lastName,
          iCode: iCode,
        }),
      );
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    dispatch(getIsFirstTimeInApp());
  }, []);
  const navigation = useNavigation();

  function handleBackButtonClick() {
    console.log('handleBackButtonClick>>>')
    const currentScreen = NavigationHelper.getCurrentScreen(navigation)
    if(currentScreen === SIGN_UP_WITH_NAME_SCREEN + 'first'){
      isFirstTimeApp.current = true;
      BackHandler.exitApp()
      return true
    }
    return false;
  }
  // useEffect(() => {
  //   const backHandler = BackHandler.addEventListener('hardwareBackPress', handleBackButtonClick)
  //   return () => backHandler.remove()
  // }, [])

    const handleQRPress = async () => {
    await requestPermission(() => {
      navigate(SCAN_QR_SCREEN, { type: QR_SCANNER_TYPE.FROM_SIGN_UP_WITH_NAME });
    });
  };

  const handleLoginPress = () => {
    navigate(LOGIN_SCREEN);
  };

  return { handleQRPress, formik, handleLoginPress };
};
