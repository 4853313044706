import { ReportPackageMockData } from '@constant/model/mock/report/package';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useTopPackage = props => {
  const dispatch = useDispatch();
  // const data = ReportPackageMockData.summary
  const data = props.data;
  useEffect(() => {}, [dispatch]);
  return { data };
};
