import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { act } from 'react-test-renderer';

export interface IgetTicketDetailState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IgetTicketDetailState = {
  loading: false,
  payload: null,
  error: null,
};

const getTicketDetailSlice = createSlice({
  name: 'getTicketDetail',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getTicketDetailRequest(state, action) {
      state.loading = true;
      // @ts-ignore
      // state.payload = null;
      state.error = null;
    },
    getTicketDetailSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    getTicketDetailFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    addCommentLocal(state, action) {
      //TODO: save to local
      const info = action.payload;
      const payload = _.cloneDeep(state.payload) ?? {};
      if (!payload?.data) {
        payload.data = {
          _id: info.ticketId,
          comments: [],
        };
      }
      let comments = payload.data.comments ?? [];
      const attachmentsContent = info.attachments
        .map(x => `<a target="_blank" href="${x.name}">${x.name}</a>`)
        ?.join(' ');
      const lsContent = [];
      if (!!info?.comment) {
        lsContent.push(info?.comment);
      }
      if (!!attachmentsContent) {
        lsContent.push(attachmentsContent);
      }
      const content = lsContent.join('</br>');
      const newComment = {
        author: {
          from: 'external',
          name: info?.name,
        },
        content: `<p>${content}</p>`,
        attachments: info.attachments,
        createdAt: new Date().toISOString(),
      };
      comments = [...comments, newComment];
      payload.data.comments = comments;
      state.payload = payload;

      console.log('addCommentLocal>>>', payload, info, lsContent);
    },
  },
});

export const { addCommentLocal, getTicketDetailRequest, getTicketDetailSuccess, getTicketDetailFailed } =
  getTicketDetailSlice.actions;

export default getTicketDetailSlice.reducer;
