import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import { FAQ_SCREEN, MENU_WEB_VIEW_SCREEN } from '@routes/route';
import { FAQ, WebView } from '@screens';
import React from 'react';

const FAQStack = createStackNavigator();

export const FAQStackScreen = () => {
  return (
    <FAQStack.Navigator initialRouteName={FAQ_SCREEN}>
      <FAQStack.Screen
        name={FAQ_SCREEN}
        component={FAQ}
        options={{
          headerShown: false,
          ...TransitionPresets.SlideFromRightIOS,
        }}
      />
      <FAQStack.Screen
        name={MENU_WEB_VIEW_SCREEN}
        component={WebView}
        options={{
          headerShown: false,
          ...TransitionPresets.SlideFromRightIOS,
        }}
      />
    </FAQStack.Navigator>
  );
};
