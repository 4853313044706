import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    minHeight: 60,
    backgroundColor: '#F2F2F2',
    justifyContent: 'space-between',
    marginBottom: Device.isIos ? 10 : 0,
  },
  containerQr: { alignItems: 'center', justifyContent: 'center', width: '15.5%' },
  qrTab: {
    height: 60,
    width: 60,
    position: 'absolute',
    top: -30,
    borderRadius: 30,
    backgroundColor: 'white',
  },
  qr: {
    width: 48,
    height: 48,
    borderRadius: 1000,
    backgroundColor: palette.ROYAL_MAIL_RED,
    position: 'absolute',
    top: -24,
    // borderWidth: 8,
    // borderColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tab: {
    // backgroundColor: 'blue',
    // flex: 1,
    width: '50%',
    // marginRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabText: { color: '#222', textAlign: 'center' },
  isFocused: {
    color: '#673ab7',
  },
});

export default styles;
