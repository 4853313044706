import { StyleSheet } from 'react-native'
import theme from "@theme";
import {palette} from "@theme/colors";

export const ReportStyle = StyleSheet.create({
    containerView: {
        borderTopRightRadius: theme.spacing.lg,
        borderTopLeftRadius: theme.spacing.lg,
        backgroundColor: palette.WHITE,
        paddingTop: theme.spacing.md,
    },
})
