import { AppText, AppBottomSheet, AppInput, AppButton } from '@components';
import { Constant } from '@constant';
import { navigate } from '@routes/navigationUtils';
import { Screens } from '@routes/route';
import { AppHtmlView } from '@screens/main/quiz/QuizFailed/components/AppNote.view';
import theme, { globalStyle } from '@theme';
import { Font } from '@theme/size';
import { reportModal } from '@utils/common';
import { bottomMapModalRef } from '@utils/common/map';
import React, { useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import { Modalize } from 'react-native-modalize';
import { useBottomMapModal } from './hook';
import { styles } from './styles';

export const BottomSheet = props => {
  const { children, adjustToContentHeight, modalRef, modalHeight, contentStyle } = props;
  return (
    <Modalize
      adjustToContentHeight={adjustToContentHeight}
      // modalHeight={modalHeight}
      alwaysOpen={170}
      // snapPoint={170}
      panGestureEnabled={true}
      // handlePosition="inside"
      // height={5}
      // alwaysOpen={true}
      // snapPoint={100}
      // modalTopOffset={20}
      // handlePosition="inside"
      // handleStyle={{ top: 13, width: 40, height: handle ? 6 : 0, backgroundColor: '#bcc0c1' }}
      modalStyle={styles.content_modal}
      ref={modalRef}
      handleStyle={{ opacity: 0.5 }}>
      <View style={contentStyle}>{children}</View>
    </Modalize>
  );
};

export const BottomMapModal = props => {
  const { mapSettings } = useBottomMapModal(props);
  return (
    <BottomSheet adjustToContentHeight modalRef={bottomMapModalRef}>
      {/*<ScrollView contentContainerStyle={styles.scrollView}>*/}
        <View style={styles.containerView}>
          <AppHtmlView
            style={[globalStyle.flex1, { ...Font.Bold_700 }]}
            text={mapSettings?.verifySetting?.message?.instructionTitle}
          />
          <AppHtmlView style={styles.text} text={mapSettings?.verifySetting?.message?.instructionDescription} />
        </View>
      {/*</ScrollView>*/}
    </BottomSheet>
  );
};
