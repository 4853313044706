import { palette } from '@theme/colors';
import { FontSize, Spacing } from '@theme/size';
import { StyleSheet } from 'react-native';
import { Platform } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Spacing.width15,
    flexGrow: 1,
  },
  form: {
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    gap: Platform.OS === 'web' ? Spacing.gap20 : undefined,
  },
  signUpDesc: {
    fontWeight: '400',
    fontSize: FontSize.Font15,
    marginBottom: Platform.OS === 'web' ? '20px' : Spacing.height16,
    textAlign: 'center',
    paddingHorizontal: Spacing.width8,
  },
  highlightText: {
    color: palette.ROYAL_MAIL_RED,
    fontSize: FontSize.Font14,
    marginLeft: Spacing.width4,
    paddingVertical: Platform.OS === 'web' ? '' : Spacing.height10,
  },
  signUpBox: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: Platform.OS === 'web' ? Spacing.height10 : Spacing.height14,
    flexWrap: 'wrap',
  },
  signUpText: {
    color: '#666666',
    fontSize: FontSize.Font14,
  },
  logo: {
    // position: 'absolute',
    // top: '15%',
    // zIndex: 1,
    marginBottom: Platform.OS === 'web' ? Spacing.height18 : Spacing.height38,
  },
});
