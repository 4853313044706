import { MessageCheckIcon, CrossIcon, WarningIcon } from '@assets';
import { AppAlert, AppText, AppButton } from '@components';
import { ForceUpdateStyles } from '@components/AppForceUpdate/ForceUpdate.style';
import { AppHtmlView } from '@screens/main/quiz/QuizFailed/components/AppNote.view';
import theme, { globalStyle } from '@theme';
import { deviceWidth, Font, FontSize } from '@theme/size';
import { AppSetting } from '@utils/common/setting';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity } from 'react-native';
import { useCheckStatusModal } from './CheckStatusModal.hook';

export const CheckStatusModal = props => {
  const { data, show } = props;
  console.log('CheckStatusModal>>>', props);
  const { handlePressButton, handleHideModal } = useCheckStatusModal(props);
  const { t } = useTranslation();

  // const { title, desc } = text;
  const description = data?.description;
  //   const description = "<b>You are currently</b><br/><ul style=\"margin-top:0;\"><li>Once your break ends and space becomes available</li><li>While in rest period.</li></ul>";
  //   const description = "<b>You are currently</b>";

  return (
    <AppAlert
      onDismiss={handleHideModal}
      closeOnTouchOutside
      show={show}
      contentContainerStyle={{ maxWidth: '92%' }}
      customView={
        <View style={{ paddingHorizontal: 8 }}>
          <View
            style={{
              position: 'absolute',
              // top: 6,
              right: 12,
              padding: 6,
            }}>
            <TouchableOpacity
              onPress={handleHideModal}
              style={{
                backgroundColor: '#c9c9c9',
                zIndex: 1,
                padding: 6,
                borderRadius: 1000,
              }}>
              <CrossIcon color="white" />
            </TouchableOpacity>
          </View>

          <View style={[globalStyle.directionColumm, { marginTop: 32 }]}>
            <View style={[globalStyle.center]}>
              <MessageCheckIcon color={AppSetting.state?.colors?.GREEN_ACTIVE} width={54} height={54} />
            </View>
            <View style={[globalStyle.center, { paddingVertical: 16 }]}>
              {/*<AppText style={[Font.Bold_700, globalStyle.ce]}>{title}</AppText>*/}
              <AppHtmlView
                style={{
                  fontSize: FontSize.Font14,
                  paddingVertical: 8,
                  paddingHorizontal: 16,
                  textAlign: 'center',
                  color: '#666666',
                  width: '100%',
                }}
                // text={data?.description}
                tagStyle={{
                  li: {
                    textAlign: 'left',
                  },
                }}
                text={description}
              />
            </View>
          </View>
          <View style={[globalStyle.directionRow]}>
            <View style={{ width: '100%' }} />
          </View>
        </View>
      }
    />
  );
};
