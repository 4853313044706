export default {
  transparent: 'transparent',
  clearColor: 'rgba(0, 0, 0, 0.0)',
  activityColor: 'rgba(59.0, 70.0, 74.0, 1.0)',
  imputCommentBackgroundColor: '#dce1e1',
  whiteColor: '#ffffff',
  whiteColorWithAlpha_08: 'rgba(255,255,255, 0.8)',
  blackColor: 'black',
  redColor: '#e1352e',
  errorText: '#D8000C',
  mainTextColor: '#333333',
  grayColor: '#EEEEEE',
  grayColor1: '#929292',
  grayColor2: '#D0D0D0',
  grayColor4: '#f6f5f5',
  grayColor5: '#e8eaec',
  lightGrayColor: '#e6e6e6',
  yellow: '#fdd31f',
  grayColor3: '#f1f1f1',
  subTextColor: '#999',
  inputTextColor: '#666666',
  borderInputColor: '#cccccc',
  blueTextColor: '#77baff',
  loginButtonColor: '#78e4df',
  iconTextFieldColor: '#adadad',
  itemGridIconColor: '#adadad',
  itemGridTextColor: '#333333',
  itemGridBackgroundColor: '#e6e6e6',
  itemGridBackgroundSelectedColor: '#436f9d',
  loginButtonColorWithAlpha: 'rgba(120,228,223, 0.9)',
  loginButtonColorError: '#d1cecf',
  loginButtonColorErrorRgba: 'rgba(209, 206, 207, 0.8)',
  registerButtonColor: '#78e4df',
  bgLoginTextInputColor: '#f2f8f7',
  bgLoginTextInputColorWithAlpha: 'rgba(242,248,247, 0.7)',
  placeHolderTextColor: '#666666',
  errorColor: '#be1037',
  textColor: '#424242',
  iconColor: '#7F7F7F',
  overlayColor: 'rgba(0, 0, 0, 0.8)',
  blackOverlay: 'rgba(0, 0, 0, 0.5)',
  lightBlueColor: '#0f6bff',
  fbColor: '#4267B2',
  googleColor: '#ff3333',
  footerBackgroundColor: '#308785',
  snackbarBackgroundColor: 'rgba(255, 255, 255, 0.8)',
  mdGrey100: '#F5F5F5',
  mdGrey500: '#9E9E9E',
  mdBlue600: '#1E88E5',
  Font_Button_Light_Grey: '#bfbfbf',
  Font_Buttons: '#919191',
  Font_Buttons_Darkest_Grey: '#595959',
  spectosLogoColor: 'rgb(255, 206, 57)',
  borderColor: '#D0D0D0',
  androidNavigationButtons: 'white',
  // Item list
  segmentBorderColor: '#668fba',
  tabBarInactiveColor: '#9AABC1',
  tabBarActiveColor: '#204876',
  segmentTextColor: '#333333',
  checkBoxColor: '#656565',
  checkBoxSelectedColor: 'rgb(35, 110, 157)',
  activeStatusColor: '#3db829',
  favoritesStarColor: '#eaab48',
  ItemListSubtitleColor: '#888888',
  ItemListBackgroundColor: '#f7f7f7',
  bottomTabBackgroundColor: '#e4362f',
  lineColor: '#e9e9e9',
  batteryColor: '#fec11a',
  // battery color
  batteryRedColor: '#e6524b',
  batteryOrangeColor: '#feba47',
  batteryGreenColor: '#079915',
  // primary color
  greyBackgroundColor: 'rgb(220, 227, 230)',
  // Blue color
  Dark_Blue: '#42576D',
  Blue: '#475768',
  Light_Blue: '#A3ABB3',

  // tracker status color
  trackerStatusActive: '#079915',
  trackerStatusIdle: '#7a929b',
  trackerStatusEnd: '#fdbd31',
  trackerStatusDefect: '#d4d4d4',
  trackerStatusLost: '#f1635f',

  // job color
  backgroundGrey: '#F7F7F7',
};
