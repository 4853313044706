import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppKAView, AppSafeAreaView, AppText } from '@components';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font } from '@theme/size';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { useTopPackage } from './TopPackage.hook';
import { TopPackageStyle } from './TopPackage.style';

export const TopPackageView = props => {
  const { t } = useTranslation();
  const { data } = useTopPackage(props);
  return (
    <View
      areaStyle={[globalStyle.flex1, globalStyle.alignCenter, globalStyle.justifyCenter, { margin: theme.spacing.sm }]}>
      <LinearGradient style={TopPackageStyle.topView} colors={['#FF000E', '#870007']}>
        <View style={[globalStyle.directionColumm]}>
          <View style={[globalStyle.directionRow, globalStyle.alignCenter, { marginBottom: theme.spacing.sm }]}>
            <AppText style={[globalStyle.flex1, TopPackageStyle.textNormal]}>{t('PACKAGE_ID')}</AppText>
            <AppText style={[TopPackageStyle.textTitle]}>{data?.id}</AppText>
          </View>
          <View style={[globalStyle.directionRow]}>
            <AppText style={[globalStyle.flex1, TopPackageStyle.textNormal]}>{t('NUMBER_OF_BUNDLES')}</AppText>
            <AppText style={[TopPackageStyle.textNormal]}>{data?.total}</AppText>
          </View>
        </View>
      </LinearGradient>
    </View>
  );
};

TopPackageView.propTypes = { data: PropTypes.object };

TopPackageView.defaultProps = { data: {} };
