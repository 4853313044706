import {createAction, createSlice} from '@reduxjs/toolkit';

export interface Iget_languagesState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: Iget_languagesState = {
  loading: false,
  payload: null,
  error: null,
};

export const get_languagesRequest = createAction('get_languages/get_languagesRequest', (payload, onSuccess, onFailed) => {
  console.log('prepareAction>>>', payload, onSuccess);
  return {
    payload: payload,
    meta: onSuccess,
    error: onFailed,
  };
});

const get_languagesSlice = createSlice({
  name: 'get_languages',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    get_languagesRequest(state, action) {
      state.loading = true;
      // @ts-ignore
      state.payload = null;
      state.error = null;
    },
    get_languagesSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    get_languagesFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { get_languagesSuccess, get_languagesFailed } = get_languagesSlice.actions;

export default get_languagesSlice.reducer;
