export const SuccessfulUnsubTest = {
    message: {
        description: 'Thank you for participating as a mail agent. If you still have questions, please feel free to contact us at support@mailagents.uk. If you would like to join again, please re-register',
        "icon": "https://spectos-royalmail-dev.s3.eu-central-1.amazonaws.com/app/icons/success_screen/icon_thank_you.svg",
        alert: ''
    }
}

export const SuccessfulRedeemTest = {
    message: {
        "icon": "https://spectos-royalmail-dev.s3.eu-central-1.amazonaws.com/app/icons/success_screen/icon_tick.svg",
        "description": "Got it. We’ve sent you an email with your voucher code.",
        "buttonText": "buttonText",
        "itemStyle": []
    }
}
