import {createAction, createSlice} from '@reduxjs/toolkit';

export interface IsubmitBundleState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IsubmitBundleState = {
  loading: false,
  payload: null,
  error: null,
};

export const submitBundleRequest = createAction('submitBundle/submitBundleRequest', (payload, onSuccess, onFailed) => {
  console.log('prepareAction>>>', payload, onSuccess);
  return {
    payload: payload,
    meta: onSuccess,
    error: onFailed,
  };
});

const submitBundleSlice = createSlice({
  name: 'submitBundle',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // submitBundleRequest(state, action) {
    //   state.loading = true;
    //   // @ts-ignore
    //   state.payload = null;
    //   state.error = null;
    // },
    submitBundleSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    submitBundleFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { submitBundleSuccess, submitBundleFailed } = submitBundleSlice.actions;

export default submitBundleSlice.reducer;
