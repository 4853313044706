import theme from '@theme';
import { FontSize } from '@theme/size';
import { StyleSheet } from 'react-native';

export const UnsubscribeStyle = StyleSheet.create({
  htmlView: {
    color: '#333333',
    fontFamily: 'arial',
    fontSize: FontSize.Font15,
    lineHeight: FontSize.Font15 + 9,
    marginHorizontal: theme.spacing.md,
    marginTop: theme.spacing.md,
  },
});
