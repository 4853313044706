import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppInput, AppKAView, AppSafeAreaView, AppText } from '@components';
import { FORM, FORM_ELEMENT_NAME } from '@constant/model/RegisterForm';
import { AppLoadingMore } from '@screens/main/SearchPostcode';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font } from '@theme/size';
import { getErrorForm } from '@utils/form';
import { FormTransform, getContentTitle } from '@utils/transform/form';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {View, Text, TouchableOpacity, TextInput, Platform} from 'react-native';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { useBundlePostCode } from './BundlePostCode.hook';
import { BundlePostCodeStyle } from './BundlePostCode.style';

export const BundlePostCodeView = props => {
  const { loading, data, onTextChanged, onSearchPress } = useBundlePostCode(props);
  const {
    style,
    isRequired,
    multiline,
    label,
    disabled,
    placeholder,
    keyboardType,
    value,
    handleBlur,
    isPassword,
    error,
    isBoldTitle,
    hint,
    PreIcon,
      onLayout,
  } = props;
  console.log('BundlePostCodeView>>>error>>', error);
  return (
    // <View style={[globalStyle.directionRow, style]}>
    <View onLayout={e => {
        if (onLayout) {
            onLayout(e);
        }
    }}>
      <View
        style={[
          BundlePostCodeStyle.boxInput,
          // boxStyle,
          disabled ? { opacity: disabled ? 0.5 : 1 } : {},
          ...style,
          // multiline ? multipleContainerStyles : {},
        ]}>
        <TextInput
          style={[BundlePostCodeStyle.input]}
          isRequired={isRequired}
          multiline={multiline}
          label={label}
          disabled={disabled}
          placeholder={placeholder}
          keyboardType={keyboardType}
          value={value}
          handleBlur={handleBlur}
          isPassword={isPassword}
          error={error}
          isBoldTitle={isBoldTitle}
          hint={hint}
          PreIcon={PreIcon}
          loading={loading}
          // autoCapitalize={'characters'}
          onChangeText={text => {
            //NOTE: must uppercase
            // const newText = text?.toUpperCase();
              const newText = text
            onTextChanged(newText);
            if (props.onChangeText) {
              props.onChangeText(newText);
            }
          }}
          placeholderTextColor={'#999999'}
        />
        {loading && <AppLoadingMore loadingMore={loading} />}

        <View style={{ margin: 2 }}>
          <TouchableOpacity
            style={[
              {
                borderRadius: 4,
                backgroundColor: '#D9D9D9',
                // height: '100%',
                  height: Platform.OS === 'web' ? '48px' : '100%',
                  aspectRatio: 1,
                // margin:2,
              },
              globalStyle.center,
            ]}
            onPress={onSearchPress}>
            <FontAwesome5 name="search" size={15} color={'white'} />
          </TouchableOpacity>
        </View>
      </View>
      {!!hint && <AppText style={BundlePostCodeStyle.hint}>{hint}</AppText>}
      {!!error && <AppText style={BundlePostCodeStyle.error}>{error}</AppText>}
    </View>
  );
};

BundlePostCodeView.propTypes = {
  onChangeText: PropTypes.func,
  onLoadedData: PropTypes.func,
  location: PropTypes.any,
  dispatchType: PropTypes.string,
    posting_datetime: PropTypes.string,
    metaItem: PropTypes.any,
    onLayout: PropTypes.func,
};

BundlePostCodeView.defaultProps = {};
