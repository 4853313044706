export const FORM_ELEMENT_NAME = {
  confirm_password: 'passwordconfirmation',
  tmp_address_extra: '_tmp_address',
  address: 'address_summary',
  postcode: 'postcode',
  password: 'password',
  phonenumber: 'phonenumber',
  emptying_time: 'emptying_time',
  nearest_address: 'nearest_address',
  posting_location_id: 'posting_location_id',
  tmp_photo: '_tmp_photo',
  dispatch_point: 'dispatch_point',
  // search_address:
};

export const RegisterFormModel = {
  count_steps: 4,
  show_summary_page: false,
  description: 'Successfully get form',
  steps: {
    1: {
      title: 'Firstname and Lastname',
      step: '1',
      description: '',
      attributes: [
        {
          id: 'formID',
          name: 'form',
          type: 'hidden',
          formID: '1',
        },
        {
          id: 'firstname',
          name: 'firstname',
          type: 'text',
          required: 'Y',
          description: 'some text',
          label: {
            ES: 'spanish label',
            DE: 'german label',
            EN: 'english label',
          },
          error_msg: {
            ES: 'spanish error',
            DE: 'german error',
            EN: 'english error',
          },
          regex: '',
          maxlength: 0,
          default: '',
          conditions: '',
        },
        {
          id: 'lastname',
          name: 'lastname',
          type: 'text',
          required: 'Y',
          description: 'some text',
          label: {
            ES: 'spanish label',
            DE: 'german label',
            EN: 'english label',
          },
          error_msg: {
            ES: 'spanish error',
            DE: 'german error',
            EN: 'english error',
          },
          regex: '',
          maxlength: 0,
          default: '',
          conditions: '',
        },
      ],
    },
    2: {
      title: 'Username and Password',
      step: '2',
      description: '',
      attributes: [
        {
          id: 'formID',
          name: 'form',
          type: 'hidden',
          formID: '1',
        },
        {
          id: 'username',
          name: 'username',
          type: 'text',
          required: 'Y',
          description: 'some text',
          label: {
            ES: 'spanish label',
            DE: 'german label',
            EN: 'english label',
          },
          error_msg: {
            ES: 'spanish error',
            DE: 'german error',
            EN: 'english error',
          },
          regex: '',
          maxlength: 0,
          default: '',
          conditions: '',
        },
        {
          id: 'password',
          name: 'password',
          type: 'password',
          required: 'Y',
          description: 'some text',
          label: {
            ES: 'spanish label',
            DE: 'german label',
            EN: 'english label',
          },
          error_msg: {
            ES: 'spanish error',
            DE: 'german error',
            EN: 'english error',
          },
          regex: '',
          maxlength: 0,
          default: '',
          conditions: '',
        },
      ],
    },
    3: {
      title: 'Step 3 something',
      step: '3',
      description: '',
      attributes: [
        {
          id: 'formID',
          name: 'form',
          type: 'hidden',
          formID: '1',
        },
        {
          id: 'step3_1',
          name: 'step3_1',
          type: 'text',
          required: 'Y',
          description: 'some text',
          label: {
            ES: 'spanish label',
            DE: 'german label',
            EN: 'english label',
          },
          error_msg: {
            ES: 'spanish error',
            DE: 'german error',
            EN: 'english error',
          },
          regex: '',
          maxlength: 0,
          default: '',
          conditions: '',
        },
        {
          id: 'step3_2',
          name: 'step3_2',
          type: 'password',
          required: 'Y',
          description: 'some text',
          label: {
            ES: 'spanish label',
            DE: 'german label',
            EN: 'english label',
          },
          error_msg: {
            ES: 'spanish error',
            DE: 'german error',
            EN: 'english error',
          },
          regex: '',
          maxlength: 0,
          default: '',
          conditions: '',
        },
      ],
    },
    4: {
      title: 'Step 4 something',
      step: '4',
      last_step: true,
      description: '',
      attributes: [
        {
          id: 'formID',
          name: 'form',
          type: 'hidden',
          formID: '1',
        },
        {
          id: 'step4_1',
          name: 'step4_1',
          type: 'text',
          required: 'Y',
          description: 'some text',
          label: {
            ES: 'spanish label',
            DE: 'german label',
            EN: 'english label',
          },
          error_msg: {
            ES: 'spanish error',
            DE: 'german error',
            EN: 'english error',
          },
          regex: '',
          maxlength: 0,
          default: '',
          conditions: '',
        },
        {
          id: 'step4_2',
          name: 'step4_2',
          type: 'password',
          required: 'Y',
          description: 'some text',
          label: {
            ES: 'spanish label',
            DE: 'german label',
            EN: 'english label',
          },
          error_msg: {
            ES: 'spanish error',
            DE: 'german error',
            EN: 'english error',
          },
          regex: '',
          maxlength: 0,
          default: '',
          conditions: '',
        },
      ],
    },
  },
};

export const AttributeModel = {
  id: 0,
  username: 'string',
  firstname: 'string',
  lastname: 'string',
  prefix: 0,
  title: 'string',
  companyname: 'string',
  street: 'string',
  housenr: 'string',
  postalCode: 'string',
  city: 'string',
  region: 'string',
  available_from: 'string',
  available_to: 'string',
  birthday: 'string',
  email_announce: true,
  email: 'string',
  password: 'string',
  phone: 'string',
};

export const IMAGE_PICKER_TYPE = {
  camera: 'camera',
  gallery: 'gallery',
  all: 'all',
};

export const FORM = {
  FORM_TYPE: {
    hidden: 'hidden',
    input: 'input',
    dropdown: 'dropdown',
    text: 'text',
    MultipleSelection: 'MultipleSelection',
    singlechoice: 'singlechoice',
    SingleSelection: 'SingleSelection',
    password: 'password',
    tel: 'tel',
    email: 'email',
    birtday: 'birtday',
    birthday: 'birthday',
    date: 'date',
    stringlistmultiple: 'stringlistmultiple',
    stringlistsingle: 'stringlistsingle',
    boolean: 'boolean',
    language: 'language',
    prefix: 'prefix',
    integer: 'integer',
    string: 'string',
    postcode: 'postcode',
    address: 'address',
    checkbox: 'checkbox',
    calendar: 'calendar',
    inputPicker: 'inputPicker',
    textArea: 'textArea',
    textarea: 'textarea',
    imagePicker: 'imagePicker',
    time: 'time',
    dispatch_point: 'dispatch_point',
    camera: 'camera',
    bundle_postcode: 'bundle_postcode',
    bundle_address: 'bundle_address',
    emptying_time: 'emptying_time',
    nearest_address: 'nearest_address',
    datetime: 'datetime',
    note: 'note',
    misdelivered_item_form: 'misdelivered_item_form',
    toggleSwitch: 'toggleSwitch',
    scan: 'scan',
    verify_map: 'verify_map'
  },
  FORM_ELEMENT_NAME: {
    confirm_password: 'passwordconfirmation',
    tmp_address_extra: '_tmp_address',
    address: 'address_summary',
    postcode: 'postcode',
    password: 'password',
    phonenumber: 'phonenumber',
    emptying_time: 'emptying_time',
    nearest_address: 'nearest_address',
    posting_location_id: 'posting_location_id',
    tmp_photo: '_tmp_photo',
    dispatch_point: 'dispatch_point',
    number_of_same_details: 'number_of_same_details',
    misdelivered_items: 'misdelivered_items',
    misdelivered_item_form: 'misdelivered_item_form',
    collection: 'Collection',
    email: 'email',
    push_notification: 'push_notification',
    // search_address:
    posting_datetime: 'posting_datetime',
    address_selected_extra: 'address_selected_extra'

  },
  EXTRA_FORM_ELEMENT_NAME: {
    visible_bundle_address: 'visible_bundle_address',
    list_post_position: 'list_post_position',
    reset_bundle_address: 'reset_bundle_address',

  },
  FORM_ERROR_TYPE: {
    required: 'required',
    validation: 'validation',
  },
};
