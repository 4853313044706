import _ from 'lodash';
import * as Yup from 'yup';
import { array } from 'yup';

export const Required = (schema, msg = '') => {
  if (_.isEmpty(msg)) {
    return schema.required();
  }
  return schema.required(msg);
};

export const Max = (schema, maxValue, msg = '') => {
  if (_.isEmpty(msg)) {
    return schema.max(maxValue);
  }
  return schema.max(maxValue, msg);
};

export const Maxlength = (schema, maxValue, msg = '') => {
  if (maxValue === null || maxValue === undefined) {
    return schema;
  }
  if (maxValue <= 0) {
    return schema;
  }
  if (_.isEmpty(msg)) {
    return schema.max(maxValue);
  }
  return schema.max(maxValue, msg);
};

export const Minlength = (schema, maxValue, msg = '') => {
  if (maxValue === null || maxValue === undefined) {
    return schema;
  }
  if (maxValue <= 0) {
    return schema;
  }
  if (_.isEmpty(msg)) {
    return schema.min(maxValue);
  }
  return schema.min(maxValue, msg);
};

export const Min = (schema, value, msg = '') => {
  if (_.isEmpty(msg)) {
    return schema.min(value);
  }
  return schema.min(value, msg);
};

export const Matches = (schema, regex, msg = '') => {
  if (_.isEmpty(regex) && typeof regex === 'string') {
    return schema;
  }
  if (typeof regex === 'string') {
    // if (regex.startsWith('"') && regex.endsWith('"')) {
    //   regex = regex.slice(1, regex.length - 1);
    // }
    regex = RegExp(regex);
  }
  if (_.isEmpty(msg)) {
    return schema.matches(regex, 'Invalid value. Please try again.');
  }

  console.log('Matches>>>', regex, schema);
  return schema.matches(regex, msg);
};

export const Equal = (schema, equalName, msg = '') => {
  if (_.isEmpty(msg)) {
    return schema.oneOf([Yup.ref(equalName)]);
  }
  return schema.oneOf([Yup.ref(equalName)], msg);
};

// @ts-ignore
export const OneOf = (schema, listName: array, msg = '') => {
  if (_.isEmpty(msg)) {
    return schema.oneOf(listName);
  }
  return schema.oneOf(listName, msg);
};
