import {createAction, createSlice} from '@reduxjs/toolkit';

export interface IgetRFIDFormState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IgetRFIDFormState = {
  loading: false,
  payload: null,
  error: null,
};

export const getRFIDFormRequest = createAction(
    'getRFIDForm/getRFIDFormRequest',
    (payload, onSuccess, onFailed) => {
      console.log('prepareAction>>>', payload, onSuccess);
      return {
        payload: payload,
        meta: onSuccess,
        error: onFailed,
      };
    },
);

const getRFIDFormSlice = createSlice({
  name: 'getRFIDForm',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // getRFIDFormRequest(state, action) {
    //   state.loading = true;
    //   // @ts-ignore
    //   state.payload = null;
    //   state.error = null;
    // },
    getRFIDFormSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    getRFIDFormFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getRFIDFormSuccess, getRFIDFormFailed } = getRFIDFormSlice.actions;

export default getRFIDFormSlice.reducer;
