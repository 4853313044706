import { useHeaderHeight } from '@react-navigation/elements';
import { globalStyle } from '@theme';
import { Device } from '@theme/device';
import React from 'react';
import { KeyboardAvoidingView } from 'react-native';

export const AppKAView = props => {
  const { children, style, keyboardVerticalOffset, behavior = 'padding' } = props;
  const height = useHeaderHeight();

  return (
    <KeyboardAvoidingView
      behavior={behavior}
      style={[globalStyle.flex1, style]}
      keyboardVerticalOffset={keyboardVerticalOffset || (Device.isAndroid ? height + 64 : height + 24)}>
      {children}
    </KeyboardAvoidingView>
  );
};

export const AppKAViewV2 = props => {
  const { children, style } = props;
  if (Device.isIos) {
    return (
      <KeyboardAvoidingView behavior="padding" style={[globalStyle.flex1, style]}>
        {children}
      </KeyboardAvoidingView>
    );
  }
  return <>{children}</>;
};
