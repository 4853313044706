import { LogoBigIcon } from '@assets';
import { AppText } from '@components/AppText';
import { globalStyle } from '@theme';
import { Font } from '@theme/size';
import { t } from 'i18next';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

export const AppEmptyView = ({ emptyText = '', emptyDesc = '' }) => {
  const { t } = useTranslation();
  return (
    <View style={[globalStyle.fullWidth, globalStyle.center, { height: '100%' }]}>
      <LogoBigIcon />
      <AppText
        style={{
          ...Font.Bold_700,
          paddingVertical: 8,
        }}>
        {emptyText || t('NO_DATA')}
      </AppText>
      {/*<AppText*/}
      {/*  style={{*/}
      {/*    textAlign: 'center',*/}
      {/*    color: '#999999',*/}
      {/*  }}>*/}
      {/*  {emptyDesc || t('NO_DATA_DESC')}*/}
      {/*</AppText>*/}
    </View>
  );
};
