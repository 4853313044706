import { Constant, DASHBOARD_ITEM_TYPE, TICKET_STATUS } from '@constant';
import { useFocusEffect } from '@react-navigation/native';
import {getDashboardRequest, getReportModalRequest, hideDashboardLoading} from '@redux/app/appSlice';
import {
  bonusInfo as bonusInfoSelector,
  dashboardLoadingSelector,
  bonusPointInfo,
  dashboard as dashboardSelector, checkStatusOfDashboardSelector,
} from '@redux/app/selectors';
import { deviceToken as deviceTokenSelector } from '@redux/app/selectors';
import {showNewsModal} from '@redux/common/commonSlice';
import {showListNotificationModal} from '@redux/common/commonSlice';
import { globalLoading } from '@redux/global/selectors';
import { getProfileRequest } from '@redux/user/getProfileSlice';
import { getProfileCreator, getSettingsDashboard } from '@redux/user/selectors';
import { updateProfileRequest } from '@redux/user/updateProfileSlice';
import { navigate } from '@routes/navigationUtils';
import { Screens, SURVEY_LIST_SCREEN } from '@routes/route';
import { Device } from '@theme/device';
import { AppNotification, mappingDashboard } from '@utils';
import { updateDeviceToken } from '@utils/common/updateDevicaTokenUtil';
import { handleShowError } from '@utils/transform/form';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {hideLoading} from "@redux/global/globalSlice";
import {DashboardHelper} from "@utils/common/dashboard";

export const useLogic = () => {
  const dispatch = useDispatch();
  const dashboard = useSelector(dashboardSelector);
  const dashboardLoading = useSelector(dashboardLoadingSelector);
  const loading = useSelector(globalLoading);
  const point = useSelector(getProfileCreator)?.message?.data?.bonuspoints ?? 0;
  const deviceToken = useSelector(getProfileCreator)?.message?.data?.device_token;
  const fcmToken = useSelector(deviceTokenSelector);
  const bonusInfo = useSelector(bonusPointInfo);
  const [refresh, setRefresh] = useState(false);
  const [show, setShow] = useState(false);

  const checkStatusOfDashboard = useSelector(checkStatusOfDashboardSelector);
  const settingsDashboard = useSelector(getSettingsDashboard);

  const mapDashboard = mappingDashboard(dashboard);
  const notificationItem = mapDashboard?.find(item => item?.type === 'notification') ?? {
    internalId: Constant.dashboard.notification,
    show: true,
  };

  // useFocusEffect(
  //   useCallback(
  //     _.debounce(() => {
  //       dispatch(getDashboardRequest());
  //     }, 500),
  //     [],
  //   ),
  // );

  console.log('bonusInfo>>>', { refresh }, dashboard);
  console.log('useLogic>>>checkStatusOfDashboard>>>', checkStatusOfDashboard);

  useEffect(() => {
    if(!dashboardLoading){
      dispatch(getDashboardRequest());
      dispatch(getReportModalRequest({ id: 5 }));
      handleGetFcmToken();
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      dispatch(hideLoading())
      dispatch(hideDashboardLoading())
    }, 6000)
  }, [])

  useEffect(() => {
    if (refresh) {
      dispatch(getDashboardRequest());
      dispatch(getReportModalRequest({ id: 5 }));
      handleGetFcmToken();
      setRefresh(false);
      setTimeout(
        () => {
          setRefresh(false);
        },
        Device.isAndroid ? 1500 : 0,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const handleGetFcmToken = async () => {
    if (deviceToken) return;

    const token = await AppNotification.getToken();

    console.log('device token, handleGetFcmToken');
    updateDeviceToken(dispatch, token || fcmToken);
  };

  const handleRefresh = () => {
    setRefresh(true);
  };

  const onItemPress = item => {
    console.log('onItemPress>>>', item);

    if (item.internalId === Constant.dashboard.notification) {
      navigate(Screens.NOTIFICATIONS, {
        data: item.data,
      });

      return;
    }

    if (item.internalId === 'quizzes') {
      navigate(Screens.LIST_QUIZ_SCREEN);
      return;
    }

    if ([Constant.dashboard.timeline_package].includes(item.internalId)) {
      navigate(Screens.PACKAGE_TIMELINE, { type: item.internalId });
      return;
    }
    if ([Constant.dashboard.timeline_bundle].includes(item.internalId)) {
      navigate(Screens.BUNDLE_TIMELINE, { type: item.internalId });
      return;
    }

    if (item.internalId === Constant.dashboard.survey_cds) {
      navigate(SURVEY_LIST_SCREEN, { isFromDashboard: true });
      return;
    }

    if (item.internalId === Constant.dashboard.survey_d2d) {
      navigate(Screens.D2D_OVERVIEW);
      return;
    }

    if (item.internalId === Constant.menu.list_open_ticket) {
      navigate(Screens.TICKET_SCREEN, {
        tabScreen: Screens.LIST_TICKET_SCREEN,
        params: { status: TICKET_STATUS.open },
      });
      return;
    }

    if (item.internalId === Constant.dashboard.recent_tasks) {
      navigate(Screens.REWARD, {
        tabScreen: Screens.HISTORY_POINTS,
        // params: { status: TICKET_STATUS.open },
      });
      return;
    }

    if (item.internalId === Constant.dashboard.invitations) {
      navigate(Screens.CDM_FORM_SCREEN, {
        data: item?.data,
        step: 0,
        // params: { status: TICKET_STATUS.open },
      });
      return;
    }

    if (item.show_popup) {
      // const description =
      //   'Description:\n' +
      //   'The Panelmanagement team needs the possibility to publish instant quick messages to panellists which are displayed within the dashboard for every panellist within a panel\n' +
      //   '\n' +
      //   'AC:\n' +
      //   '\n' +
      //   ' Get API and show the quick message in a new widget. if it´s too long just cut it and tab on widget to open a popup with the full message. Popup can be scroll to show full text.\n' +
      //   'the instant news always comes first';
      if (item.type === 'item') {
        const description = item?.data;
        dispatch(
          showNewsModal({
            title: item?.name,
            description: description,
          }),
        );
      }
      if (item.type === 'list') {
        // const filterData = DashboardHelper.getListData(item.data, notificationItem?.data?.statistics)

        const filterData = DashboardHelper.getFillAmountData(item.data, dashboard)
        console.log('filterData>>>', filterData)
        dispatch(
          showListNotificationModal({
            data: filterData,
          }),
        );
      }

    }
  };

  const handleMarkerPress = () => {
    if (!settingsDashboard?.mapIcon?.show) {
      return;
    }
    navigate(Screens.POSTING_LOCATION);
  };

  const handleNavigateToReward = () => {
    if (bonusInfo?.internalId === Constant.dashboard.recent_tasks) {
      navigate(Screens.REWARD, {
        tabScreen: Screens.VOUCHER,
      });
    }
  };

  const onPressStatus = () => {
    setShow(true)
  }

  return {
    dashboard: mapDashboard,
    onItemPress,
    handleRefresh,
    refresh,
    point,
    loading,
    // fakeLoading,
    bonusInfo,
    handleMarkerPress,
    handleNavigateToReward,
    dashboardLoading,
    settingsDashboard,
    checkStatusOfDashboard,
    onPressStatus,
    show, setShow,
    //checkStatusOfDashboard,
    //onPressStatus,
    //show, setShow,
    notificationItem,
  };
};
