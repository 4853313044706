import { getListHolidaysSaga } from '@redux/holiday/getListHolidaysSaga';
import { getListHolidaysRequest } from '@redux/holiday/getListHolidaysSlice';
import { addHolidaySaga } from '@redux/holiday/addHolidaySaga';
import { addHolidayRequest } from '@redux/holiday/addHolidaySlice';
import { deleteHolidaySaga } from '@redux/holiday/deleteHolidaySaga';
import { deleteHolidayRequest } from '@redux/holiday/deleteHolidaySlice';
import { ForkEffect, takeLatest } from 'redux-saga/effects';

function* holidaySagas(): Generator<ForkEffect<never>, void> {
  yield takeLatest(getListHolidaysRequest.type, getListHolidaysSaga);
  yield takeLatest(addHolidayRequest.type, addHolidaySaga);
  yield takeLatest(deleteHolidayRequest.type, deleteHolidaySaga);
}

export default holidaySagas;
