import { palette } from '@theme/colors';
import { FontSize } from '@theme/size';
import { StyleSheet } from 'react-native';

export const AppHtmlRadioStyle = StyleSheet.create({
  container: {
    width: '100%',
  },
  textTitle: {
    fontWeight: '700',
    marginBottom: 10,
  },
  radioBox: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#D5D5D5',
    paddingVertical: 12,
    paddingHorizontal: 8,
    borderRadius: 4,
    marginBottom: 4,
  },
  text: {
    fontWeight: '400',
    color: '#333333',
    marginLeft: 10,
    fontSize: FontSize.Font15,
  },
  hint: {
    fontWeight: '400',
    color: '#999999',
    marginTop: 7,
  },
  error: {
    color: palette.ROYAL_MAIL_RED,
    fontWeight: '400',
    marginTop: 7,
  },
  isRequired: {
    color: '#FF5757',
  },
});
