import api from '@services/api-client';
import { stringify } from 'qs';
import withQuery from 'with-query';

export const submitBundleService = async payload => {
  console.log('submitBundleService>>>', payload)
  const response = await api.put(`/bundles/${payload?.id}`, payload)
  console.log('submitBundleService response>>>', response)
  return response?.data
}

export const getListPostingLocationService = async payload => {
  console.log('getListPostingLocationService>>>', payload)
  const response = await api.post(`/posting-locations`, payload)
  console.log('getListPostingLocationService response>>>', response)
  return response?.data
}

export const getListAddressService = async payload => {
  console.log('getListAddressService>>>', payload)
  const response = await api.post(`/posting-locations`, payload)
  console.log('getListAddressService response>>>', response)
  return response?.data
}

