import { AppText } from '@components';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { FontSize } from '@theme/size';
import React, { useEffect, useRef } from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import { CodeField, Cursor, useBlurOnFulfill, useClearByFocusCell } from 'react-native-confirmation-code-field';

const renderCell = ({ index, symbol, isFocused }, cellCount, getCellOnLayoutHandler) => (
  <View
    style={[
      styles.digitWrap,
      isFocused && { borderBottomColor: '#999999' },
      {
        width: Platform.OS === 'web' ? 810 / (cellCount * 1.5) : Device.width / (cellCount * 1.5),
        marginHorizontal: Platform.OS === 'web' ? 'auto' : 0,
      },
    ]}
    onLayout={getCellOnLayoutHandler(index)}>
    <AppText allowFontScaling={false} style={styles.digitText} key={index}>
      {symbol || (isFocused ? <Cursor delay={500} /> : null)}
    </AppText>
  </View>
);

export const AppDigit = React.memo(props => {
  const { style, error = '', hint = '', cellCount = 7, keyboardType, onChangeText, value = '' } = props;

  const blurOnFulfillRef = useBlurOnFulfill({ value, cellCount: cellCount });
  const [propsFocusCell, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue: onChangeText,
  });
  const codeFieldRef = useRef(null);
  useEffect(() => {
    if (Platform.OS === 'web' && codeFieldRef.current) {
      codeFieldRef.current.focus();
    }
  }, []);

  // useEffect(() => {
  //   ref.current.focus();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <View style={[styles.container, style]}>
      <CodeField
        pointerEvents="box-only"
        ref={codeFieldRef}
        // Use `caretHidden={false}` when users can't paste a text value, because context menu doesn't appear
        caretHidden
        selectTextOnFocus={false}
        contextMenuHidden={true}
        value={value}
        onChangeText={onChangeText}
        cellCount={cellCount}
        rootStyle={styles.rootStyle}
        keyboardType={keyboardType}
        textContentType="oneTimeCode"
        renderCell={options => renderCell(options, cellCount, getCellOnLayoutHandler)}
        autoFocus
        maxFontSizeMultiplier={1}
        {...propsFocusCell}
      />
      {!!hint && <AppText style={styles.hint}>{hint}</AppText>}
      {!!error && <AppText style={styles.error}>{error}</AppText>}
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  rootStyle: {
    marginTop: 20,
    width: '100%',
    flex: 1,
  },
  digitWrap: {
    // width: Spacing.width47,
    borderBottomWidth: 2,
    borderColor: '#CECECE',
    minHeight: 60,
  },
  digitText: {
    textAlign: 'center',
    fontSize: FontSize.Font36,
    textTransform: 'uppercase',
  },
  label: {
    fontWeight: '400',
    color: '#333333',
    marginBottom: 8,
  },
  hint: {
    fontWeight: '400',
    color: '#999999',
    marginTop: 7,
  },
  isRequired: {
    color: '#FF5757',
  },
  error: {
    marginTop: 7,

    color: palette.ROYAL_MAIL_RED,
    fontWeight: '400',
  },
});
