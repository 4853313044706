import { AppForm } from '@components/AppForm';
import { useHowTo } from '@screens/main/HowTo/form/HowTo.hook';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HowToStyle } from './HowTo.style';

export const HowToScreen = props => {
  const { t } = useTranslation();
  const { formik, data, scrollToY, scrollViewRef, layoutInfo, setLayoutInfo } = useHowTo(props);
  return (
    <AppForm
      title={data?.title}
      description={data?.description}
      scrollViewRef={scrollViewRef}
      scrollToY={scrollToY}
      formik={formik}
      attributes={data?.attributes}
      setLayoutInfo={setLayoutInfo}
      layoutInfo={layoutInfo}
      buttonText={''}
      itemStyle={[{ paddingHorizontal: 0 }]}
    />
  );
};
