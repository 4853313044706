import { Constant } from '@constant';
import theme from '@theme';
import { palette } from '@theme/colors';
import { deviceWidth, Font, FontSize, Spacing } from '@theme/size';
import { StyleSheet, Platform } from 'react-native';

export const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    alignItems: 'center',
    paddingHorizontal: Spacing.width15,
    paddingTop: Platform.OS === 'web' ? '20px' : Spacing.height15,
    // paddingBottom: Platform.OS === 'web' ? '40px' : Spacing.height40,
  },
  reportItem: {
    flex: Platform.OS === 'web' ? 'initial' : 1,
    width: '100%',
  },
  titleReport: {
    ...Font.Bold_700,
    fontSize: FontSize.Font17,
    color: '#333333',
    marginVertical: Platform.OS === 'web' ? '20px' : Spacing.height16,
    marginTop: Platform.OS === 'web' ? '0px' : Spacing.height16,
  },
  box: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#C6C6C6',
    // paddingHorizontal: 12,
    minHeight: Constant.MAIN_HEIGHT,
    borderRadius: 4,
    width: '100%',
    marginBottom: Platform.OS === 'web' ? '20px' : Spacing.height16,
  },
  text: {
    fontWeight: '400',
    color: '#333333',
    // paddingHorizontal: Spacing.width12,
    paddingVertical: theme.spacing.sm,
  },
  containerView: {
    flex: 1,
    // marginTop: theme.spacing.md,
    // marginBottom: theme.spacing.md,
    // marginBottom: Platform.OS === 'web' ? '40px' : Spacing.height60,

    paddingBottom: Platform.OS === 'web' ? '40px' : theme.spacing.md,
    paddingHorizontal: Spacing.width15,
    paddingTop: Platform.OS === 'web' ? '20px' : Spacing.height15,
  },
  content_modal: {
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 6 },
    shadowOpacity: 0.45,
    shadowRadius: 16,
  },
});
