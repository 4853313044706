import { Constant } from '@constant';
import { palette } from '@theme/colors';
import { StyleSheet } from 'react-native';

export const AppCalendarStyle = StyleSheet.create({
  container: {
    width: '100%',
  },
  textTitle: {
    fontWeight: '700',
    marginBottom: 10,
  },
  viewSelect: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#C6C6C6',
    marginHorizontal: 0,
    // backgroundColor: '#D5D5D5',
    paddingHorizontal: 12,
    borderRadius: 4,
    minHeight: Constant.MAIN_HEIGHT,
  },
  isRequired: {
    color: '#FF5757',
  },
  textAfterSelect: {
    // color: 'red',
    fontWeight: '400',

    color: '#333333',
    marginLeft: 12,
    // textTransform: 'capitalize',
  },
  textSelect: {
    fontWeight: '400',
    color: '#666666',
    marginLeft: 12,
    // textTransform: 'capitalize',
  },
  error: {
    marginTop: 7,

    color: palette.ROYAL_MAIL_RED,
    fontWeight: '400',
  },
});
