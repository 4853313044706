import { ReportICodeMockData } from '@constant/model/mock/report/iCode';
import { ReportPackageMockData } from '@constant/model/mock/report/package';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {ReportBundleMockData} from "@constant/model/mock/report/bundle";

export const useTopBundle = props => {
  const dispatch = useDispatch();
  // const data = ReportBundleMockData.summary;
  const data = props?.data
  useEffect(() => {}, [dispatch]);
  return { data };
};
