// import { Colors } from '@components/AppAddress/Colors';
// import { Dimens } from '@components/AppAddress/Dimens';
import { Constant } from '@constant';
import theme from '@theme';
import { StyleSheet } from 'react-native';

export const SearchPostcodeStyle = StyleSheet.create({
  viewSelected: {
    minHeight: Constant.MAIN_HEIGHT,
    backgroundColor: '#F7F7F7',
    justifyContent: 'center',
    borderRadius: 4,
    // borderWidth: 1,
    // borderColor: '#D5D5D5',
    // width: '100%',
    paddingLeft: theme.spacing.ms,
    paddingRight: theme.spacing.ms,
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
  },
  viewUnSelected: {
    minHeight: Constant.MAIN_HEIGHT,
    justifyContent: 'center',
    backgroundColor: '#F7F7F7',
    borderRadius: 4,
    // borderWidth: 1,
    // width: '100%',
    // borderColor: '#D5D5D5',
    paddingLeft: theme.spacing.ms,
    paddingRight: theme.spacing.ms,
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
  },
  textView: {
    // color: Colors.greyText,
    // fontSize: Dimens.FontSize.Normal,
    // fontWeight: Dimens.FontWeight.Normal,
  },
  listView: {
    marginHorizontal: theme.spacing.md,
    // padding: theme.spacing.md,
    // height: 55 * 6,
    // flexGrow: 0,
    // paddingLeft: Dimens.Margin.Normal,
    // paddingRight: Dimens.Margin.Normal,
  },
  emptyText: {
    color: '#95A5A6',
    // fontSize: Dimens.FontSize.Big,
    // fontWeight: Dimens.FontWeight.Normal,
    marginTop: 80,
    // marginLeft: Dimens.Margin.Extra_Long,
    // marginRight: Dimens.Margin.Extra_Long,
  },
});
