import { getFAQRequest } from '@redux/form/getFAQSlice';
import { getFAQCreator, getFAQSelector, getFAQError, getFAQErrorMsg, getFAQLoading } from '@redux/form/selectors';
import { navigate } from '@routes/navigationUtils';
import {MENU_WEB_VIEW_SCREEN, WEB_VIEW_SCREEN_FULLSCREEN} from '@routes/route';
import { getContentTitle } from '@utils/transform/form';
import {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useFAQ = () => {
  const dispatch = useDispatch();
  const getFAQData = useSelector(getFAQCreator)?.data;
  const data = getFAQData;
  const [loading, setLoading] = useState(false);

  const getFAQApi = () => {
    const payload = {};
    dispatch(getFAQRequest(payload));
  };

  const onItemPress = item => {
    // getQuizApi(item?.id);
    const uri = getContentTitle(item?.content);
    navigate(MENU_WEB_VIEW_SCREEN, { uri: uri, header: getContentTitle(item?.title) });
  };

  const onRefresh = () => {
    getFAQApi()
  };

  useEffect(() => {
    getFAQApi();
  }, []);
  return { data, onItemPress, onRefresh, loading };
};
