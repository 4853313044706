import { CommonActions, useNavigation } from '@react-navigation/native';
import { resetUserValidation } from '@redux/auth/authSlice';
import { authCreator } from '@redux/auth/selectors';
import { registerUserCompleted } from '@redux/user/updateUserSlice';
import { navigate, navigatePop, popToTop } from '@routes/navigationUtils';
import { LOGIN_SCREEN, Screens } from '@routes/route';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import SplashScreen from 'react-native-splash-screen';
import { useDispatch, useSelector } from 'react-redux';
import { Platform } from 'react-native';

export const useRegisterCompleted = props => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  console.log('useRegisterCompleted>>>>');
  const waitingForSendMail = () => {
    // popToTop()
    if (Platform.OS !== 'web') {
      SplashScreen.hide();
    }
    console.log('waitingForSendMail>>>>');
    setTimeout(() => {
      // dispatch(resetUserValidation({}));
      // navigate(Screens.LOGIN_SCREEN);
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [
            {
              name: LOGIN_SCREEN,
            },
          ],
        }),
      );
    }, 5000);
  };
  useEffect(() => {
    waitingForSendMail();
  }, []);
  return { waitingForSendMail };
};
