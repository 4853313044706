import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IupdateUserState {
  loading: boolean;
  payload: any;
  error: any;
  isRegisterCompleted: boolean,
}

export const updateUserRequest = createAction('updateUser/updateUserRequest', (payload, onSuccess, onFailed) => {
  console.log('prepareAction>>>', payload, onSuccess);
  return {
    payload: payload,
    meta: onSuccess,
    error: onFailed,
  };
});

// @ts-ignore
const initialState: IupdateUserState = {
  loading: false,
  payload: null,
  error: null,
  isRegisterCompleted: false,
};

const updateUserSlice = createSlice({
  name: 'updateUser',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    updateUserSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    registerUserCompleted(state, action){
      state.isRegisterCompleted = true
    },
    updateUserFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.isRegisterCompleted = false;
    },
  },
  extraReducers: {
    [updateUserRequest.type]: (state, action) => {
      console.log('updateUserSlice>>>updateUserRequest<<<<', action);
      state.loading = true;
      // @ts-ignore
      state.payload = null;
      state.error = null;
      state.isRegisterCompleted = false;
    },
  },
});

export const { updateUserSuccess, updateUserFailed, registerUserCompleted } = updateUserSlice.actions;

export default updateUserSlice.reducer;
