import { palette } from '@theme/colors';
import { Spacing } from '@theme/size';
import { StyleSheet } from 'react-native';

export const MisDeliveredStyle = StyleSheet.create({
  container: {
    flex: 1,
  },
  wrapper: {
    // paddingLeft: 10,
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  box: {
    flex: 1,
    maxWidth: '32%',
    height: Spacing.width76,
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    // borderStyle: 'dotted',
    // borderWidth: 1,
    marginBottom: Spacing.width10,
    marginRight: Spacing.width10,
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#D5D5D5',
    overflow: "hidden",
    // backgroundColor: 'red'
  },
  shadow: {
    borderWidth: 0,
  },
  imageWrap: {
    borderStyle: 'solid',
    borderColor: '#D5D5D5',
    // backgroundColor: '#98C9F5',

  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1,
    padding: 5,
    backgroundColor: '#FFFFFF90',
  },
  outermostItem: {
    marginRight: 0,
    // backgroundColor: '#98C9F5',
  },
  title: {
    fontWeight: '400',
    color: '#333333',
    marginBottom: 8,
  },
  isRequired: {
    color: '#FF5757',
  },
  hint: {
    fontWeight: '400',
    color: '#999999',
    marginTop: 7,
  },
  error: {
    marginTop: 7,

    color: palette.ROYAL_MAIL_RED,
    fontWeight: '400',
  },
});
