import { AppRadio, AppText } from '@components';
import { AppHtmlView } from '@screens/main/quiz/QuizFailed/components/AppNote.view';
import { palette } from '@theme/colors';
import { FontSize } from '@theme/size';
import { checkRadioId } from '@utils/form';
import React from 'react';
import { StyleSheet, View } from 'react-native';

/**
 * - Data must be object and include ID and CONTENT
 * - Selected must be ID
 * - Selected is also default data
 */
export const AppSingleSelection = React.memo(props => {
  const {
    style,
    data = [],
    selected,
    onSelect,
    isRequired,
    title,
    error,
    isRadio = true,
    styleTitle,
    isBoldTitle,
    hint = '',
    onLayout = null,
  } = props;

  return (
    <View
      style={[styles.container, style]}
      onLayout={e => {
        if (onLayout) {
          onLayout(e);
        }
      }}>
      {!!title && (
        <AppText style={[styles.textTitle, styleTitle, isBoldTitle ? { fontWeight: '700' } : {}]}>
          {isRequired && <AppText style={styles.isRequired}>* </AppText>}
          {title}
        </AppText>
      )}
      {data?.map((item, index) => {
        return (
          <AppRadio
            key={index}
            data={item}
            onSelect={onSelect}
            selected={selected}
            isRadio={isRadio}
            // styleSelected={{ backgroundColor: selected ? '#F4F4F4' : 'transparent' }}
            styleSelected={{ backgroundColor: selected === item.id ? '#F4F4F4' : 'transparent' }}
          />
        );
      })}
      {/*{!!hint && <AppText style={styles.hint}>{hint}</AppText>}*/}
      {!!hint && <AppHtmlView style={styles.hint} text={hint} />}
      {!!error && <AppText style={styles.error}>{error}</AppText>}
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    width: '100%',
    // paddingTop: 10,
  },
  textTitle: {
    fontWeight: '400',
  },
  isRequired: {
    color: '#FF5757',
  },
  hint: {
    fontWeight: '400',
    color: '#999999',
    marginTop: 7,
    fontSize: FontSize.Font15,
  },
  error: {
    marginTop: 7,
    color: palette.ROYAL_MAIL_RED,
    fontWeight: '400',
  },
});
