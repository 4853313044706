import { getCreateTicketFormService } from '@services/form'

// @ts-ignore
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { getCreateTicketFormFailed, getCreateTicketFormSuccess } from './getCreateTicketFormSlice';
import { Constant } from "@constant";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* getCreateTicketFormSaga({ payload }): Generator<
  | CallEffect
  | PutEffect<{
      payload: any;
      type: string;
    }>,
  void
> {
  try {
    const res = yield call(getCreateTicketFormService, payload);
    console.log('getCreateTicketFormSaga>>>>', res);
    if (res?.status) {
      yield put(getCreateTicketFormSuccess(res));
    } else {
      // @ts-ignore
      yield put(getCreateTicketFormFailed(res));
    }
  } catch (err) {
    // @ts-ignore
    yield put(getCreateTicketFormFailed(err));
  }
}
