import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import { useAuthStack } from '@routes/AuthStack/AuthStack.hook';
import { ModalSlideFromTopIOS } from '@routes/Transaction';
import {
  FORGOT_CHANGE_PASS,
  FORGOT_SEND_EMAIL,
  LOGIN_SCREEN,
  Screens,
  SIGN_UP_WITH_NAME_SCREEN,
  SCAN_QR_SCREEN,
  FORGOT_SUCCESS,
  MENU_WEB_VIEW_SCREEN,
  DIGIT,
} from '@routes/route';
import {
  ForgotChangePass,
  ForgotSendEmail,
  Login,
  SignUpWithName,
  ScanQR,
  SplashScreen,
  ForgotSuccess,
  Digit,
} from '@screens';
import { WebView } from '@screens';
import { ActiveSuccessfulScreen } from '@screens/auth/ActiveSuccessful';
import { SuccessfulScreen } from '@screens/auth/Successful';
import { HowToScreen } from '@screens/main/HowTo/form';
import { SearchAddressScreen } from '@screens/main/SearchAddress';
import { SearchPostcodeScreen } from '@screens/main/SearchPostcode';
import { RegisterCompletedScreen } from '@screens/register/RegisterCompleted';
import { RegisterFormScreen } from '@screens/register/RegisterForm';
import { useDeepLink } from '@utils/hooks';
import React, { FC } from 'react';
import {ScanCodeScreen} from "@screens/auth/ScanCode/pickerScan/ScanCode.view";
import {InputScanCodeScreen} from "@screens/auth/ScanCode/inputScan/InputScanCode.view";

const AuthStack = createStackNavigator();

export const AuthStackScreen: FC = () => {
  const { initialRouteName, loginAnimation } = useAuthStack({});
  return (
    <AuthStack.Navigator initialRouteName={initialRouteName}>
      <AuthStack.Screen
        name={Screens.SPLASH_SCREEN}
        component={SplashScreen}
        options={{
          headerShown: false,
          // ...TransitionPresets.SlideFromRightIOS,
          // ...TransitionPresets.,
        }}
      />
      <AuthStack.Screen
        name={LOGIN_SCREEN}
        component={Login}
        options={{
          headerShown: false,
          ...loginAnimation,
        }}
      />
      <AuthStack.Screen
        name={Screens.LOGIN_SCREEN_VERTICAL}
        component={Login}
        options={{
          headerShown: false,
          ...TransitionPresets.SlideFromRightIOS,
        }}
      />
      <AuthStack.Screen
        name={SIGN_UP_WITH_NAME_SCREEN + 'first'}
        component={SignUpWithName}
        options={{
          headerShown: false,
          ...loginAnimation,
        }}
      />
      <AuthStack.Screen
        name={SIGN_UP_WITH_NAME_SCREEN}
        component={SignUpWithName}
        options={{
          headerShown: false,
          ...TransitionPresets.SlideFromRightIOS,
        }}
      />
      <AuthStack.Screen
        name={SCAN_QR_SCREEN}
        component={ScanQR}
        options={{
          headerShown: false,
          ...TransitionPresets.SlideFromRightIOS,
        }}
      />
        <AuthStack.Screen
            name={Screens.SCAN_CODE_SCREEN}
            component={ScanCodeScreen}
            options={{
                headerShown: false,
                ...TransitionPresets.ModalFadeTransition,
            }}
        />
        <AuthStack.Screen
            name={Screens.INPUT_SCAN_CODE_SCREEN}
            component={InputScanCodeScreen}
            options={{
                headerShown: false,
                ...TransitionPresets.ModalFadeTransition,
            }}
        />
      <AuthStack.Screen
        name={FORGOT_SEND_EMAIL}
        component={ForgotSendEmail}
        options={{
          headerShown: false,
          ...TransitionPresets.SlideFromRightIOS,
        }}
      />
      <AuthStack.Screen
        name={Screens.ACTIVE_SUCCESSFUL_SCREEN}
        component={ActiveSuccessfulScreen}
        options={{
          headerShown: false,
          ...TransitionPresets.SlideFromRightIOS,
        }}
      />
      <AuthStack.Screen
        name={Screens.SUCCESSFUL_SCREEN}
        component={SuccessfulScreen}
        options={{
          headerShown: false,
          ...TransitionPresets.ModalFadeTransition,
        }}
      />
      <AuthStack.Screen
        name={FORGOT_CHANGE_PASS}
        component={ForgotChangePass}
        options={{
          headerShown: false,
          ...TransitionPresets.SlideFromRightIOS,
        }}
      />
      <AuthStack.Screen
        name={FORGOT_SUCCESS}
        component={ForgotSuccess}
        options={{
          headerShown: false,
          ...TransitionPresets.SlideFromRightIOS,
        }}
      />
      <AuthStack.Screen
        name={Screens.REGISTER_FORM_SCREEN}
        component={RegisterFormScreen}
        options={{
          headerShown: false,
          ...TransitionPresets.SlideFromRightIOS,
        }}
      />
      <AuthStack.Screen
        name={Screens.REGISTER_COMPLETED_SCREEN}
        component={RegisterCompletedScreen}
        options={{
          headerShown: false,
          ...TransitionPresets.SlideFromRightIOS,
        }}
      />
      <AuthStack.Screen
        name={Screens.SEARCH_POSTCODE_SCREEN}
        component={SearchPostcodeScreen}
        options={{
          headerShown: false,
          ...TransitionPresets.SlideFromRightIOS,
        }}
      />
      <AuthStack.Screen
        name={Screens.SEARCH_ADDRESS_SCREEN}
        component={SearchAddressScreen}
        options={{
          headerShown: false,
          ...TransitionPresets.SlideFromRightIOS,
        }}
      />
      <AuthStack.Screen
        name={MENU_WEB_VIEW_SCREEN}
        component={WebView}
        options={{
          headerShown: false,
          ...TransitionPresets.SlideFromRightIOS,
        }}
      />
      <AuthStack.Screen
        name={Screens.HOW_TO_FORM}
        component={HowToScreen}
        options={{
          headerShown: false,
          ...TransitionPresets.SlideFromRightIOS,
        }}
      />
      <AuthStack.Screen
        name={DIGIT}
        component={Digit}
        options={{
          headerShown: false,
          ...TransitionPresets.SlideFromRightIOS,
        }}
      />
    </AuthStack.Navigator>
  );
};
