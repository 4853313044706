import { SlideFromRightIOS } from '@react-navigation/stack/src/TransitionConfigs/TransitionPresets';
import { registerCompletedSelector } from '@redux/auth/selectors';
import { ModalSlideFromTopIOS } from '@routes/Transaction';
import { isFirstTimeApp } from '@routes/navigationUtils';
import { Screens } from '@routes/route';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

export const useAuthStack = props => {
  // const isRegisterCompleted = useSelector(registerCompletedSelector);
  const initialRouteName = isFirstTimeApp.current ? Screens.SPLASH_SCREEN : Screens.LOGIN_SCREEN;
  // const loginAnimation = !isRegisterCompleted
  //   ? {
  //       ...ModalSlideFromTopIOS,
  //     }
  //   : { ...SlideFromRightIOS };
  // const loginAnimation = { ...SlideFromRightIOS };
  const loginAnimation = { ...ModalSlideFromTopIOS };

  console.log('useAuthStack>>>', isFirstTimeApp, isFirstTimeApp.current, initialRouteName);

  return { initialRouteName, loginAnimation };
};
