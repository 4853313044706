import { createAction, createSlice } from '@reduxjs/toolkit';

export interface IuploadFileViaFDBState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IuploadFileViaFDBState = {
  loading: false,
  payload: null,
  error: null,
};

export const uploadFileViaFDBRequest = createAction(
  'uploadFileViaFDB/uploadFileViaFDBRequest',
  (payload, onSuccess, onFailed) => {
    console.log('prepareAction>>>', payload, onSuccess);
    return {
      payload: payload,
      meta: onSuccess,
      error: onFailed,
    };
  },
);

const uploadFileViaFDBSlice = createSlice({
  name: 'uploadFileViaFDB',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // uploadFileViaFDBRequest(state, action) {
    //   state.loading = true;
    //   // @ts-ignore
    //   state.payload = null;
    //   state.error = null;
    // },
    uploadFileViaFDBSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    uploadFileViaFDBFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { uploadFileViaFDBSuccess, uploadFileViaFDBFailed } = uploadFileViaFDBSlice.actions;

export default uploadFileViaFDBSlice.reducer;
