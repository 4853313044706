import { createSlice } from '@reduxjs/toolkit';

export interface IloginState {
  loading: boolean;
  payload: any;
  error: any;
}

// @ts-ignore
const initialState: IloginState = {
  loading: false,
  payload: null,
  error: null,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loginRequest(state, action) {
      state.loading = true;
      // @ts-ignore
      state.payload = null;
      state.error = null;
    },
    loginSuccess(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.error = null;
    },
    loginFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { loginRequest, loginSuccess, loginFailed } = loginSlice.actions;

export default loginSlice.reducer;
