import { Constant } from '@constant';
import { Config } from '@constant/config';
import { ReportModalMockData } from '@constant/model/mock/form/reportModal';
import { reportModal as reportModalSelector } from '@redux/app/selectors';
import { getReturnTransponderFormRequest } from '@redux/form/getReturnTransponderFormSlice';
import { navigate, navigatePush, navigateReset } from '@routes/navigationUtils';
import { DIGIT, Screens } from '@routes/route';
import { getPanelistId, mappingReportModal, reportModal } from '@utils';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useReportModal = props => {
  // const reportModalData = useSelector(reportModalSelector);

  const dispatch = useDispatch();
  const getReturnTransponderFormApi = async () => {
    dispatch(
      getReturnTransponderFormRequest(
        {
          panelistId: getPanelistId(),
        },
        res => {
          // navigate(Screens.)
          navigate(Screens.TRANSPONDER_FROM_SCREEN);
        },
        err => {},
      ),
    );
  };
  const reportModalData = Config.IsDebug ? ReportModalMockData.form.data : useSelector(reportModalSelector);
  const onReportPress = () => {
    reportModal.hide();
    navigatePush(Screens.REPORT_SCREEN);
  };

  const onItemPress = item => {
    reportModal.hide();
    switch (item.internalId) {
      case Constant.menu.create_ticket:
        navigate(Screens.TICKET_SCREEN, { tabScreen: Screens.ADD_TICKET_SCREEN });
        return;
      case Constant.menu.report_holidays:
        navigate(Screens.REPORT_SCREEN);
        return;
      case Constant.menu.report_test_items:
        navigate(DIGIT, { cellCount: 7, type: Constant.menu.report_test_items });
        return;
      case Constant.menu.posting_package:
        navigate(DIGIT, { cellCount: 5, type: Constant.menu.posting_package });
        return;
      case Constant.menu.report_bundle_dispatch:
        navigate(DIGIT, { cellCount: 6, type: Constant.menu.report_bundle_dispatch });
        return;
      case Constant.menu.transponder:
        // navigate(Screens.UNSUBSCRIBE_SUCCESSFUL_SCREEN);
        getReturnTransponderFormApi();
        break;
    }
  };
  return { onReportPress, onItemPress, data: mappingReportModal(reportModalData) };
};
