import { PERMISSION_MODEL, PERMISSION_TYPE } from '@constant';
import { showCameraPermissionModal, showLocationPermissionModal } from '@redux/common/commonSlice';
import { store } from '@redux/store';
import { Device } from '@theme/device';
import { PermissionUtil } from '@utils/common/permission';
import { compare } from 'compare-versions';
import _ from 'lodash';
import { Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';

export const isShowForceVersion = build_version => {
  console.log('isAllowForceVersion>>>', build_version);
  // return true
  if (_.isEmpty(build_version)) {
    return false;
  }
  const isAllowVersion = compare(DeviceInfo.getVersion(), build_version, '<');
  console.log('isAllowForceVersion2>>>', isAllowVersion, DeviceInfo.getVersion(), build_version);

  return isAllowVersion;
};

export const requestPermission = async (callback, permission_model = PERMISSION_MODEL.CAMERA, permission_type = PERMISSION_TYPE.REQUEST) => {
  const rs = await PermissionUtil.requestPermission(callback, permission_model, permission_type);
  return rs
};

export const isAllowSyncAppVersion = profileData => {
  //NOTE: web platform will be not allow syncAppVersion
  if (['android', 'ios'].includes(Platform.OS)) {
    const appVersion = profileData?.app_version;
    const currentAppVersion = DeviceInfo.getVersion();
    return appVersion !== currentAppVersion;
  }

  return false;
};
