import theme, {WEB_WIDTH} from '@theme';
import { Device } from '@theme/device';
import { Modal } from '@ui-kitten/components';
import React from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import { Overlay } from 'react-native-elements';

export const AppModal = props => {
  const { style = {}, visible = false, children, modalRef, onBackdropPress, containerStyle = [] } = props;

  return (
    <Modal
      ref={modalRef}
      style={(style, { justifyContent: 'center' })}
      visible={visible}
      backdropStyle={styles.backdrop}
      onBackdropPress={onBackdropPress}>
      <View style={[styles.children, ...containerStyle]}>{children}</View>
    </Modal>
  );
};

export const AppModalV2 = props => {
  const { style = {}, visible = false, children, modalRef, onBackdropPress, containerStyle = [] } = props;

  return (
    <Overlay
      isVisible={visible}
      onBackdropPress={onBackdropPress}
      overlayStyle={[{ borderRadius: 8, padding: 0, paddingBottom: theme.spacing.sm }, style]}>
      {children}
    </Overlay>
  );
};

const styles = StyleSheet.create({
  container: {
    width: Platform.OS === 'web' ? WEB_WIDTH : '100%',
    marginHorizontal: Platform.OS === 'web' ? 'auto' : 0,
    //width: '100%',
    // height: '100%',
    flex: 1,
  },
  children: {
    width: Platform.OS === 'web' ? WEB_WIDTH * 0.92 : Device.width * 0.92,
    marginHorizontal: Platform.OS === 'web' ? 'auto' : 0,
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    maxHeight: 336,
  },
  backdrop: {
    width: Platform.OS === 'web' ? WEB_WIDTH : 0,
    marginHorizontal: Platform.OS === 'web' ? 'auto' : 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
});
