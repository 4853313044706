import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppButton, AppKAView, AppKAViewV2, AppSafeAreaView, AppText } from '@components';
import { AppSuccessScreen } from '@components/AppSuccessScreen';
import { FormItemView } from '@components/Form';
import GenericHeader from '@components/GenericHeader';
import { UPLOAD_FILE } from '@constant';
import { CommonActions, useNavigation } from '@react-navigation/native';
import { goBack } from '@routes/navigationUtils';
import { BOTTOM_TAB } from '@routes/route';
import { useReportPackageDetail } from '@screens/main/report/package/detail/PackageDetail.hook';
import { ReportSuccessfulView } from '@screens/main/report/package/form/components/ReportSuccessful.view';
import { TopPackageView } from '@screens/main/report/package/form/components/TopPackage.view';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font, Spacing } from '@theme/size';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Text, ScrollView, Platform } from 'react-native';
import { backgroundColor } from 'react-native-calendars/src/style';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

export const ReportPackageDetailScreen = props => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { formik, data, summary, isSuccess, point, headerTitle, messageInfo } = useReportPackageDetail(props);
  const renderForm = () => {
    return (
      <>
        <KeyboardAwareScrollView
          style={[
            {
              backgroundColor: palette.WHITE,
              borderTopRightRadius: theme.spacing.lg,
              borderTopLeftRadius: theme.spacing.lg,
            },
          ]}
          keyboardShouldPersistTaps="handled">
          <TopPackageView data={summary} />
          <View style={[globalStyle.directionColumm, RegisterFormStyles.paddingSpace]}>
            {data?.attributes?.map((item, index) => {
              return (
                <FormItemView
                    key={`${item?.internalId}_${index}`}
                    style={[{ zIndex: Platform.OS === 'web' ? 'unset' : undefined}]}
                  index={index}
                  item={item}
                  formik={formik}
                  isBoldTitle={true}
                  checkCondition={true}
                  formData={data?.attributes}
                  folderUpload={UPLOAD_FILE.folderUpload.bundle}
                />
              );
            })}
          </View>
        </KeyboardAwareScrollView>
        <View
          style={[
            RegisterFormStyles.paddingSpace,
            { marginVertical: theme.spacing.sm, marginBottom: Platform.OS === 'web' ? '20px' : Spacing.height25 },
          ]}>
          <AppButton onPress={formik.handleSubmit} text={t('Report')} />
        </View>
      </>
    );
  };
  return (
    <>
      <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
      <AppSafeAreaView areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>
        <GenericHeader
          // onBackClicked={goBack}
          // title={t('POSTING_PACKAGE')}
          title={headerTitle}
          style={[{ backgroundColor: '#EDEDED' }]}
        />
        <View style={[globalStyle.directionColumm, RegisterFormStyles.containerView]}>
          {isSuccess ? (
            // <ReportSuccessfulView point={point} />
            <AppSuccessScreen
              alert={messageInfo?.alert}
              icon={messageInfo?.icon}
              description={messageInfo?.description}
            />
          ) : (
            renderForm()
          )}
        </View>
      </AppSafeAreaView>
    </>
  );
};
