import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export const useAppAddress = props => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(props.value);
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);
  useEffect(() => {}, [dispatch]);
  return {value, setValue};
};
