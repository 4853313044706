import { AppKAView, AppSafeAreaView, AppText } from '@components';
import GenericHeader from '@components/GenericHeader';
import { goBack } from '@routes/navigationUtils';
import { Details, Footer, MarkerComponent } from '@screens/main/PostingLocation/Components';
import { TabBar } from '@screens/main/PostingLocation/Components/Tab';
import { RegisterFormStyles } from '@screens/register/RegisterForm/RegisterForm.style';
import { globalStyle } from '@theme';
import { Device } from '@theme/device';
import { Font } from '@theme/size';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View } from 'react-native';
import MapView, { Circle, PROVIDER_GOOGLE } from 'react-native-maps';
import { useVerifyMap } from './hook';
import { styles } from './styles';
import {BottomMapModal} from "@screens/main/VerifyMap/components/BottomMapModal";

export const VerifyMapScreen = props => {
  const { mapSettings, mapViewRef, lat, setLat, long, setLong, region, dataParams, selectedTab } = useVerifyMap(props);
  const { t } = useTranslation();
  return (
    <>
      <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
      <AppSafeAreaView areaStyle={styles.areaStyle}>
        <GenericHeader
          title={t('Report Bundle Dispatch')}
          style={[{ backgroundColor: '#EDEDED' }]}
          onBackClicked={() => {
            goBack();
          }}
        />
        <AppKAView>
          <View style={[styles.containerView]}>
            {/* TODO add content view here */}
            {region && (
              <MapView
                ref={mapViewRef}
                provider={PROVIDER_GOOGLE}
                style={styles.map}
                onPress={e => {
                  if (e.nativeEvent.action !== 'marker-press') {
                    // setSelectedMarker(undefined);
                    // pressed the map
                  } else {
                    // pressed a marker
                  }
                }}
                // onPoiClick={() => {
                //   // setSelectedMarker(undefined);
                // }}
                region={region}
                // onRegionChange={regionValue => {
                //     handleLoadMore(regionValue);
                // }}
                // initialRegion={region}
                // customMapStyle={mapStyle}
                // minZoomLevel={0} // default => 0
                // maxZoomLevel={17}
                rotateEnabled={false}
                // zoomEnabled={true}
                followsUserLocation={true}
                zoomControlEnabled={true}
                onUserLocationChange={e => {
                  // console.log('onUserLocationChange>>>', e?.coordinate);
                  //   console.log('onUserLocationChange>>>', e);
                  console.log('onUserLocationChange>>>', e?.nativeEvent?.coordinate);
                  // const coord = e?.nativeEvent?.coordinate;
                  // setLat(coord?.latitude);
                  // setLong(coord?.longitude);
                }}
                showsUserLocation>
                {/*<MapView.Circle*/}
                {/*  // key={(loc.latitude + loc.longitude).toString()}*/}
                {/*  // center={{*/}
                {/*  //     latitude: loc.latitude,*/}
                {/*  //     longitude: loc.longitude*/}
                {/*  // }}*/}
                {/*  radius={100}*/}
                {/*  strokeWidth={1}*/}
                {/*  strokeColor={'#1a66ff'}*/}
                {/*  fillColor={'rgba(230,238,255,0.5)'}*/}
                {/*  // onRegionChangeComplete = { regionChangeHandler }*/}
                {/*/>*/}
                <Circle
                  center={{
                    latitude: Number(dataParams?.address_selected_extra?.latitude),
                    longitude: Number(dataParams?.address_selected_extra?.longitude),
                  }}
                  radius={100}
                  strokeWidth={2}
                  strokeColor="#FFFFFF"
                  fillColor="#CFCFCF99"
                />
                <MarkerComponent
                    // key={
                    //   Device.isAndroid
                    //       ? selectedMarker?.latitude === marker?.latitude &&
                    //       selectedMarker?.longitude === marker?.longitude
                    //       ? '012j3k12n3h91823912hj'
                    //       : marker.id || index
                    //       : marker.id || index
                    // }
                    marker={{
                      latitude: Number(dataParams?.address_selected_extra?.latitude),
                      longitude: Number(dataParams?.address_selected_extra?.longitude),
                    }}
                    handleMarkerPress={() => {}}
                    // isSelected={
                    //   selectedMarker?.latitude === marker?.latitude &&
                    //   selectedMarker?.longitude === marker?.longitude
                    // }
                    selectedTab={selectedTab}
                />
              </MapView>
            )}
            {/*<View style={styles.bottomView}>*/}
            {/*  <AppText style={[globalStyle.flex1, { ...Font.Bold_700 }]}>*/}
            {/*    {mapSettings?.verifySetting?.message?.instructionTitle}*/}
            {/*  </AppText>*/}
            {/*  <AppText style={styles.text}>{mapSettings?.verifySetting?.message?.instructionDescription}</AppText>*/}
            {/*</View>*/}
          </View>
          <BottomMapModal />
        </AppKAView>
      </AppSafeAreaView>
    </>
  );
};
