import { AppAlert, ReportModal, PermissionModal, AppText } from '@components';
import { AppFlashMessage } from '@components/AppFlashMessage';
import { ForceUpdateView } from '@components/AppForceUpdate/ForceUpdate.view';
import {LoadingModal, LoadingView} from '@components/Loading/Loading.view';
import { DeeplinkModal } from '@components/modal/DeeplinkModal';
import { PERMISSION_MODEL } from '@constant';
import { Config } from '@constant/config';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { NavigationContainer } from '@react-navigation/native';
import { RootStackScreen } from '@routes/index';
import { navigationRef } from '@routes/navigationUtils';
import { useRoot } from '@routes/useRoot';
import { globalStyle } from '@theme';
import { palette } from '@theme/colors';
import { Device } from '@theme/device';
import { Layout } from '@ui-kitten/components';
import { LinkingHelper } from '@utils/common/linking';
// import { AppNotification } from '@utils';
// import { AppNotification } from '@utils/hooks';
import { useLanguage } from '@utils/hooks/useLanguage';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBar, View, Platform } from 'react-native';
import FlashMessage from 'react-native-flash-message';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import {GlobalModal} from "@components/GlobalModal";
import {GlobalSuccessModal} from "@components/GlobalSuccessModal";
import {NotificationImportantModal} from "@components/NotificationImportantModal/NotificationImportantModal.view";
import {NoInternetModal} from "@components/NoInternetModal";
import {NewsModal} from "@components/modal/NewsModal";
import {ListNotificationModal} from "@components/modal/ListNotificationModal";

const config = {
  screens: {
    MAIN: {
      screens: {
        REPORT_ICODE_DETAIL_SCREEN: 'icode/:id',
        REPORT_BUNDLE_DETAIL_SCREEN: 'bundle/:id',
        REPORT_PACKAGE_DETAIL_SCREEN: 'package/:id',
      },
    },
    // AUTH: {
    //   LOGIN_SCREEN: 'appRegister',
    //   SIGN_UP_WITH_NAME_SCREEN: 'login'
    // }
  },
};

const linking = {
  prefixes: ['http://192.168.101.28:19007', 'mailagents://'],
  config,
};

export const RootScreen = props => {
  const {
    isShowVersion,
    isLoading,
    isRegisterForm,
    cameraPermissionModalVisible,
    showModalDeeplinkVisible,
    locationPermissionModalVisible,
    notificationPermissionModalVisible,
    notificationImportantModalVisible,
    showNoInternetConnection,
    showNewsModal,
    showListNotificationModal,
  } = useRoot();
  const { isLoaded } = useLanguage();
  // const { isLoaded, isLoadedImage, isLoadedSetting } = useSetting();
  const { t } = useTranslation();
  const getTypeModal = () => {
    if(cameraPermissionModalVisible){
      return PERMISSION_MODEL.CAMERA
    }
    if(locationPermissionModalVisible){
      return PERMISSION_MODEL.LOCATION
    }

    if(notificationPermissionModalVisible){
      return PERMISSION_MODEL.NOTIFICATION
    }
    return PERMISSION_MODEL.CAMERA
  }

  const isSupportLinking = LinkingHelper.isForceOpen();

  // let { init } = AppNotification;
  console.log('RootScreen.web>>>>', props, isSupportLinking);

  // (async function clearData() {
  //   await AsyncStorage.clear();
  // })();
  return (
    <SafeAreaProvider>
      <NavigationContainer linking={isSupportLinking ? linking : null} ref={navigationRef}>
        <StatusBar barStyle="dark-content" backgroundColor={palette.WHITE} />
        {Device.deviceType === 'web' && <View style={[globalStyle.webBg]} />}
        <Layout style={[globalStyle.flex1, globalStyle.justifyCenter, globalStyle.width]}>
          {isLoaded && (
            <RootStackScreen
              // key={`key_${isLoaded}`}
              isLoaded={isLoaded}
              isRegisterForm={isRegisterForm}
            />
          )}
          {Config.IsDebug || (isShowVersion && <ForceUpdateView key={`key_${isLoaded}`} />)}
          {isLoading && <LoadingModal />}
          <AppAlert />
          <FlashMessage position="top" />
          <AppFlashMessage />
          <ReportModal />
          <PermissionModal
              show={cameraPermissionModalVisible || locationPermissionModalVisible || notificationPermissionModalVisible}
              type={getTypeModal()}
          />
          {showModalDeeplinkVisible && <DeeplinkModal show={showModalDeeplinkVisible} />}
          <NotificationImportantModal show={notificationImportantModalVisible} />
          <NoInternetModal show={showNoInternetConnection} />
          <GlobalModal />
          <GlobalSuccessModal />
          {showNewsModal && <NewsModal show={showNewsModal} />}
          {showListNotificationModal && <ListNotificationModal show={showListNotificationModal} />}
        </Layout>
      </NavigationContainer>
    </SafeAreaProvider>
  );
};
