import { LogoIcon, MessageCheckIcon } from '@assets';
import { AppSafeAreaView, AppText, AuthSuccessScreen } from '@components';
import { AppSuccessScreen } from '@components/AppSuccessScreen';
import GenericHeader from '@components/GenericHeader';
import theme, { globalStyle, spacing } from '@theme';
import { palette } from '@theme/colors';
import { Font } from '@theme/size';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text } from 'react-native';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import Icon from 'react-native-vector-icons/FontAwesome5';
import { useRegisterCompleted } from './RegisterCompleted.hook';
import { RegisterCompletedStyles } from './RegisterCompleted.style';

export const RegisterCompletedScreen = props => {
  const { waitingForSendMai, messageInfo, onClose } = useRegisterCompleted(props);
  const { t } = useTranslation();
  // return <AuthSuccessScreen title={t('REGISTER_COMPLETED_TITLE')} desc={t('REGISTER_COMPLETED_DESCRIPTION')} />;

  return (
    <AppSafeAreaView areaStyle={[{ backgroundColor: palette.BACKGROUND_GREY }]}>
      <GenericHeader
        onBackClicked={() => {
          // goBack();
          onClose();
        }}
        // title={getContentTitle(data?.title)}
        style={[{ backgroundColor: palette.BACKGROUND_GREY }]}
      />
      <AppSuccessScreen alert={messageInfo?.alert} icon={messageInfo?.icon} description={messageInfo?.description} />
    </AppSafeAreaView>
  );
  // return (
  //   <AppSafeAreaView areaStyle={[globalStyle.flex1, globalStyle.alignCenter, globalStyle.justifyCenter]}>
  //     {/*<Icon name="comment-dots" size={40} color={palette.ROYAL_MAIL_RED} />*/}
  //     <MessageCheckIcon />
  //     <View
  //       style={[
  //         globalStyle.alignCenter,
  //         globalStyle.justifyCenter,
  //         globalStyle.alignSelfCenter,
  //         globalStyle.directionColumm,
  //       ]}>
  //       <Text style={[Font.Bold_700, { textAlign: 'center', margin: theme.spacing.sm }]}>
  //         {t('REGISTER_COMPLETED:REGISTER_COMPLETED_TITLE')}
  //       </Text>
  //       <AppText style={[{ textAlign: 'center', marginHorizontal: theme.spacing.sm }]}>{t('REGISTER_COMPLETED:REGISTER_COMPLETED_DESCRIPTION')}</AppText>
  //     </View>
  //   </AppSafeAreaView>
  // );
};
