import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import { MENU_SCREEN, MENU_WEB_VIEW_SCREEN, PROFILE_EDIT_SCREEN } from '@routes/route';
import { Menu, ProfileEdit, WebView } from '@screens';
import React from 'react';

const MenuStack = createStackNavigator();

export const MenuStackScreen = () => {
  return (
    <MenuStack.Navigator initialRouteName={MENU_SCREEN}>
      <MenuStack.Screen
        name={MENU_SCREEN}
        component={Menu}
        options={{
          headerShown: false,
          ...TransitionPresets.SlideFromRightIOS,
        }}
      />
      <MenuStack.Screen
        name={MENU_WEB_VIEW_SCREEN}
        component={WebView}
        options={{
          headerShown: false,
          ...TransitionPresets.SlideFromRightIOS,
        }}
      />
    </MenuStack.Navigator>
  );
};
