import { WarningIcon } from '@assets';
import { AppButton, AppModal, AppText } from '@components';
import { ForceUpdateStyles } from '@components/AppForceUpdate/ForceUpdate.style';
import { AppNoteView } from '@screens/main/quiz/QuizFailed/components/AppNote.view';
import { VoucherStyle } from '@screens/main/reward/Voucher/Voucher.style';
import theme, { globalStyle } from '@theme';
import { FontSize } from '@theme/size';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

export const AppConfirmModal = props => {
  const { t } = useTranslation();
  return (
    <AppModal
      // onDismiss={handleHideModal}
      closeOnTouchOutside
      visible={props.showModal}
      style={[{ padding: theme.spacing.md }]}
      containerStyle={[{ borderRadius: 8 }]}
      // contentContainerStyle={{ maxWidth: '92%' }}
    >
      <View>
        <View style={[globalStyle.directionColumm, { marginTop: 24 }]}>
          <View style={[globalStyle.center]}>
            <WarningIcon width={45} height={45} />
          </View>
          <View style={[globalStyle.center, { paddingVertical: theme.spacing.md }]}>
            {/*<AppText style={[Font.Bold_700, globalStyle.center]}>{t('DEEPLINK_MODAL_TITLE')}</AppText>*/}
            {/*<AppText*/}
            {/*  style={[*/}
            {/*    {*/}
            {/*      fontSize: FontSize.Font15,*/}
            {/*      paddingVertical: theme.spacing.sm,*/}
            {/*      textAlign: 'center',*/}
            {/*      color: '#666666',*/}
            {/*      maxWidth: '85%',*/}
            {/*    },*/}
            {/*  ]}>*/}
            {/*  /!*{t('HOLIDAY_DELETE_MODAL_CONTENT')}*!/*/}
            {/*  {props.textContent}*/}
            {/*</AppText>*/}
            <AppNoteView
              style={[
                {
                  fontSize: FontSize.Font15,
                  paddingVertical: theme.spacing.sm,
                  textAlign: 'center',
                  color: '#666666',
                  maxWidth: '85%',
                },
              ]}
              titleStyle={{
                textAlign: 'center',
              }}
              title={props.textContent}
            />
          </View>
        </View>
        <View style={[ForceUpdateStyles.row, { margin: theme.spacing.md }]}>
          <AppButton
            onPress={() => {
              // onGoToStorePress();
              props.onNoModalPress();
            }}
            text={t('NO')}
            style={[{ flex: 1, backgroundColor: '#C1C6C8', marginRight: theme.spacing.md }]}
          />
          <AppButton
            onPress={() => {
              props.onYesModalPress();
            }}
            text={t('YES')}
            style={[{ flex: 1 }]}
          />
        </View>
      </View>
    </AppModal>
  );
};

AppConfirmModal.propTypes = {
  onYesModalPress: PropTypes.func,
  onNoModalPress: PropTypes.func,
  showModal: PropTypes.bool,
  textContent: PropTypes.string,
};
AppConfirmModal.defaultProps = {};
