import getListHolidaysSlice from '@redux/holiday/getListHolidaysSlice';
import addHolidaySlice from '@redux/holiday/addHolidaySlice';
import deleteHolidaySlice from '@redux/holiday/deleteHolidaySlice';

export const holidayReducer = {
 getListHolidays: getListHolidaysSlice,
 addHoliday: addHolidaySlice,
 deleteHoliday: deleteHolidaySlice,
};

