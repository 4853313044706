import { palette } from '@theme/colors';
import { FontSize, Spacing } from '@theme/size';
import { Platform, StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Spacing.width15,
    flex: 1,
  },
  form: {
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    gap: Platform.OS === 'web' ? Spacing.gap20 : undefined,
  },
  logo: {
    marginBottom: Platform.OS === 'web' ? Spacing.height18 : Spacing.height38,
  },
  qrCode: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: Platform.OS === 'web' ? Spacing.gap20 : Spacing.height35,
    marginBottom: Platform.OS === 'web' ? Spacing.gap20 : undefined,
    flexWrap: 'wrap',
  },
  divider: {
    width: '100%',
    height: Spacing.width1,
    backgroundColor: '#DDDDDD',
    marginTop: Spacing.height10,
  },
  qrCodeText: {
    color: '#666666',
    fontSize: FontSize.Font14,
    marginRight: Spacing.width8,
  },
  highlightText: {
    color: palette.ROYAL_MAIL_RED,
    fontSize: FontSize.Font14,
    marginLeft: Spacing.width4,
    paddingVertical: Platform.OS === 'web' ? '' : Spacing.height10,
  },
  signUpBox: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: Platform.OS === 'web' ? Spacing.height10 : Spacing.height14,
    flexWrap: 'wrap',
  },
  signUpText: {
    color: '#666666',
    fontSize: FontSize.Font14,
  },
  mb20: {
    marginBottom: Platform.OS === 'web' ? '' : Spacing.height20,
  },
});
